export { GeneticCardDto } from './models/genetic-card.dto';
export { GeneticTestResultDto } from './models/genetic-test-result.dto';
export { GeneticTestDto } from './models/genetic-test.dto';
export { GeneticTestFilterDto } from './models/genetic-test.filter.dto';
export { GeneticCardFindParameterDto } from './models/parameters/genetic-card.find-parameter.dto';
export { GeneticTestResultCreateParameterDto } from './models/parameters/genetic-test-result.create-parameter.dto';
export { GeneticTestCreateParameterDto } from './models/parameters/genetic-test.create-parameter.dto';
export { GeneticTestDeleteBulkParameter } from './models/parameters/genetic-test.delete-bulk.parameter.dto';
export { GeneticTestFindParameterDto } from './models/parameters/genetic-test.find-parameter.dto';
export { GeneticTestUpdateParameterDto } from './models/parameters/genetic-test.update-parameter.dto';
export { GeneticTestClient } from './services/genetic-test.client';
