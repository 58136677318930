import { Injectable } from '@angular/core';
import { LocalStorageService } from '@nmn-core/application-storages';
import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { shareReplay, Subject } from 'rxjs';
import { defaultDarkThemeAlias, defaultLightThemeAlias } from '../constants';
import { ApplicationThemeModel } from '../models/application-theme.model';

const themeRootQuerySelector = '#nmn-body';

const themes = [
	new ApplicationThemeModel(defaultLightThemeAlias, 'application.themes.apixmedLightTheme.displayText', 'application.themes.apixmedLightTheme.description', 'light'),
	new ApplicationThemeModel(defaultDarkThemeAlias, 'application.themes.apixmedDarkTheme.displayText', 'application.themes.apixmedDarkTheme.description', 'dark')
];

@Injectable()
export class ApplicationThemeService {

	private themeRootRef: Element;

	private readonly currentApplicationThemeSubject$ = new Subject<ApplicationThemeModel>();
	public readonly currentApplicationTheme$ = this.currentApplicationThemeSubject$.asObservable().pipe(shareReplay(1));

	public get availableThemes(): Array<ApplicationThemeModel> {
		return themes;
	}

	constructor(
		private readonly localStorage: LocalStorageService
	) {
		this.currentApplicationTheme$.subscribe({ next: this.applyTheme.bind(this) });
		this.initializeApplicationTheme();
	}

	public setTheme(themeAlias: string): void {
		let theme = this.availableThemes.find(item => item.alias === themeAlias);

		if (isValueDefined(theme)) {
			this.localStorage.setTheme(theme.alias);
			this.currentApplicationThemeSubject$.next(theme);
		}
	}

	public setDefaultTheme(): void {
		if (isArrayDefinedAndNotEmpty(this.availableThemes)) {
			let defaultTheme = this.availableThemes.find(item => item.alias === this.getThemeAliasByBrowser());

			if (isValueDefined(defaultTheme)) {
				this.currentApplicationThemeSubject$.next(defaultTheme);
			} else {
				this.currentApplicationThemeSubject$.next(this.availableThemes[0]);
			}
		}
	}

	private initializeApplicationTheme(): void {
		let themeFromLocalStorage = this.availableThemes.find(item => item.alias === this.localStorage.getTheme());

		if (isValueDefined(themeFromLocalStorage)) {
			this.setTheme(themeFromLocalStorage.alias);
		} else {
			this.setDefaultTheme();
		}
	}

	private getThemeAliasByBrowser(): string {
		try {
			return window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)')?.matches
				? defaultDarkThemeAlias
				: defaultLightThemeAlias;
		} catch {
			return defaultLightThemeAlias;
		}
	}

	private applyTheme(theme: ApplicationThemeModel): void {
		let themeClass = theme?.alias;

		if (isStringDefinedAndNotEmpty(themeClass)) {
			if (!isValueDefined(this.themeRootRef)) {
				this.themeRootRef = document.querySelector(themeRootQuerySelector);
			}

			if (isValueDefined(this.themeRootRef)) {
				if (isStringDefinedAndNotEmpty(themeClass)) {
					if (!isValueDefined(this.themeRootRef)) {
						this.themeRootRef = document.querySelector(themeRootQuerySelector);
					}

					if (isValueDefined(this.themeRootRef)) {
						const newThemeRootClasses = [];
						this.themeRootRef.classList
							?.forEach(oldThemeRootClass => {
								if (!isValueDefined(this.availableThemes.find(item => item.alias === oldThemeRootClass))) {
									newThemeRootClasses.push(oldThemeRootClass);
								}
							});

						newThemeRootClasses.push(themeClass);
						this.themeRootRef.removeAttribute('class');
						newThemeRootClasses.forEach(newThemeRootClass => this.themeRootRef.classList.add(newThemeRootClass));
					}
				}
			}
		}
	}

}
