import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { GeneComboboxDto } from '../models/gene-combobox.dto';
import { GeneFilterDto } from '../models/gene-info-filter.dto';

export abstract class GeneClient {

	public abstract getBulkAsComboboxes(filter: GeneFilterDto): Observable<Result<Array<GeneComboboxDto>, Failure>>;

}
