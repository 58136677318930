import { Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { LocalStorageService } from '../../../../../core/application-storages';
import { FakeDatabase } from '../../../../../modules/fakes/databases/fake.database';
import { UserSubscriptionFakeClientConfiguration } from '../configurations/user-subscription.fake-client.configuration';
import { UserSubscriptionFakeClient } from '../services/user-subscription.fake-client';

export class UserSubscriptionFakeClientBuilder {

	private configuration: UserSubscriptionFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(configuration: UserSubscriptionFakeClientConfiguration): UserSubscriptionFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): UserSubscriptionFakeClient {
		return new UserSubscriptionFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase),
			this.injector.get(LocalStorageService)
		);
	}

}
