import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApplicationRegionService } from '@nmn-background/application-region';
import { isArrayDefinedAndNotEmpty, isNumberDefined, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { DiseaseFilterModel, DiseaseQueryHandlerService } from '@nmn-domain/diseases';
import { GenderFilterModel, GenderQueryHandlerService } from '@nmn-domain/genders';
import { MedicationFilterModel, MedicationQueryHandlerService } from '@nmn-domain/medications';
import { PopulationFilterModel, PopulationQueryHandlerService } from '@nmn-domain/populations';
import {
	TreatmentCheckerAnamnesisFormInModel, TreatmentCheckerFormInModel, TreatmentCheckerFormInModelResolver, TreatmentCheckerReportAnamnesisModel,
	TreatmentCheckerReportLifestyleModel, TreatmentCheckerTreatmentInfoFormInModel, TreatmentCheckerYourDataFormInModel
} from '@nmn-domain/treatment-checker';
import { QueryParameterValueForChecker } from '@nmn-middleware/navigation';
import { forkJoin, map, Observable, of, take } from 'rxjs';

@Injectable()
export class TreatmentCheckerFormInModelDefaultResolver extends TreatmentCheckerFormInModelResolver {

	constructor(
		private readonly applicationRegionService: ApplicationRegionService,
		private readonly populationQueryHandlerService: PopulationQueryHandlerService,
		private readonly genderQueryHandlerService: GenderQueryHandlerService,
		private readonly diseaseQueryHandlerService: DiseaseQueryHandlerService,
		private readonly medicationQueryHandlerService: MedicationQueryHandlerService
	) {
		super();
	}

	public resolve(
		activatedRoute: ActivatedRouteSnapshot,
		_: RouterStateSnapshot
	): Observable<TreatmentCheckerFormInModel> | TreatmentCheckerFormInModel {
		const ageParam = activatedRoute.queryParamMap.get(QueryParameterValueForChecker.Age);
		const age = isNumberDefined(ageParam) ? Number(ageParam) : undefined;

		const weightParam = activatedRoute.queryParamMap.get(QueryParameterValueForChecker.Weight);
		const weight = isNumberDefined(weightParam) ? Number(weightParam) : undefined;

		const heightParam = activatedRoute.queryParamMap.get(QueryParameterValueForChecker.Height);
		const height = isNumberDefined(heightParam) ? Number(heightParam) : undefined;

		const populationIdParam = activatedRoute.queryParamMap.get(QueryParameterValueForChecker.PopulationId);
		const populationObs$ = isStringDefinedAndNotEmpty(populationIdParam) ?
			this.populationQueryHandlerService
				.getAsComboboxes(PopulationFilterModel.createForPredefinedList([populationIdParam]))
				.pipe(map(result => result.successOrDefault(() => undefined))) :
			of(undefined);

		const genderIdParam = activatedRoute.queryParamMap.get(QueryParameterValueForChecker.GenderId);
		const genderObs$ = isStringDefinedAndNotEmpty(genderIdParam) ?
			this.genderQueryHandlerService
				.getAsDescribedComboboxes(GenderFilterModel.createForPredefinedList([genderIdParam]))
				.pipe(map(result => result.successOrDefault(() => undefined))) :
			of(undefined);

		const lifestyleCodeParam = activatedRoute.queryParamMap.get(QueryParameterValueForChecker.Lifestyle);
		const lifestyle = TreatmentCheckerReportLifestyleModel.createByCode(lifestyleCodeParam);

		const anamnesisCodeParam = activatedRoute.queryParamMap.get(QueryParameterValueForChecker.Anamnesis);
		const anamnesis = TreatmentCheckerReportAnamnesisModel.createByCode(anamnesisCodeParam);

		const diseaseIdsParam = activatedRoute.queryParamMap.get(QueryParameterValueForChecker.DiseaseIds);
		const diseaseIds = isStringDefinedAndNotEmpty(diseaseIdsParam) ? JSON.parse(diseaseIdsParam) : undefined;
		const diseasesObs$ = isArrayDefinedAndNotEmpty(diseaseIds) ?
			this.diseaseQueryHandlerService
				.getAsDescribedComboboxes(DiseaseFilterModel.createForPredefinedList(diseaseIds))
				.pipe(map(result => result.successOrDefault(() => undefined))) :
			of(undefined);

		const medicationIdsParam = activatedRoute.queryParamMap.get(QueryParameterValueForChecker.MedicationIds);
		const medicationIds = isStringDefinedAndNotEmpty(medicationIdsParam) ? JSON.parse(medicationIdsParam) : undefined;
		const medicationsObs$ = isArrayDefinedAndNotEmpty(medicationIds) ?
			this.medicationQueryHandlerService
				.getAsDescribedComboboxes(MedicationFilterModel.createForPredefinedList(medicationIds))
				.pipe(map(result => result.successOrDefault(() => undefined))) :
			of(undefined);

		return forkJoin([populationObs$, this.applicationRegionService.currentApplicationRegion$.pipe(take(1)), genderObs$, diseasesObs$, medicationsObs$])
			.pipe(
				map(results => {
					const populations = results[0];
					const populationByRegion = results[1];
					const genders = results[2];
					const diseases = results[3];
					const medications = results[4];

					const yourDataFormInModel = TreatmentCheckerYourDataFormInModel.createForPredefinedData(
						isArrayDefinedAndNotEmpty(diseases) ? diseases : [],
						isArrayDefinedAndNotEmpty(medications) ? medications : []
					);

					const treatmentInfoFormInModel = TreatmentCheckerTreatmentInfoFormInModel.createForPredefinedData(
						isArrayDefinedAndNotEmpty(populations) ? populations[0] : isValueDefined(populationByRegion?.defaultPopulation) ? populationByRegion.defaultPopulation : undefined,
						isArrayDefinedAndNotEmpty(genders) ? genders[0] : undefined,
						age,
						weight,
						height,
						lifestyle?.isPregnant,
						lifestyle?.isActiveLifestyle,
						lifestyle?.isAlcohol,
						lifestyle?.isOnHealthyDiet,
						lifestyle?.isSmoking,
						lifestyle?.isUnderHighLevelOfStress,
						lifestyle?.isUsingNutritionalSupplements
					);

					const anamnesisFormInModel = TreatmentCheckerAnamnesisFormInModel.createForPredefinedData(
						anamnesis?.hasHealthIssuesWithLiver,
						anamnesis?.hasHealthIssuesWithKidney,
						anamnesis?.hasHealthIssuesWithHeart,
						anamnesis?.hasHealthIssuesWithStomach,
						anamnesis?.hasHealthIssuesWithNervousSystem,
						anamnesis?.hasHealthIssuesWithImmuneSystem,
						anamnesis?.hasHealthIssuesWithEndocrine,
						anamnesis?.hasHealthIssuesWithCancer,
						anamnesis?.hasHealthIssuesWithEye,
						anamnesis?.hasHealthIssuesWithEar,
						anamnesis?.hasHealthIssuesWithMuscles,
						anamnesis?.hasHealthIssuesWithGeneticConditions
					);

					return TreatmentCheckerFormInModel.createForPredefinedData(
						anamnesisFormInModel,
						treatmentInfoFormInModel,
						yourDataFormInModel
					);
				})
			);
	}

}
