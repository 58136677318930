import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { PagedCollectionDto, PageOptionsDto } from '../../shared';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { GeneticFileParseRequestFilterDto } from '../models/genetic-file-parse-request-filter.dto';
import { GeneticFileParseRequestDto } from '../models/genetic-file-parse-request.dto';
import { GeneticFileParseRequestCreateParameterDto } from '../models/parameters/genetic-file-parse-request.create-paramter.dto';
import { GeneticFileParseRequestFindParameterDto } from '../models/parameters/genetic-file-parse-request.find-parameter.dto';
import { GeneticFileParseRequestUpdateParameterDto } from '../models/parameters/genetic-file-parse-request.update-paramter.dto';

export abstract class GeneticFileParseRequestClient {

	public abstract getBulk(
		filterParameter: PageOptionsDto<GeneticFileParseRequestFilterDto>
	): Observable<Result<PagedCollectionDto<GeneticFileParseRequestDto, GeneticFileParseRequestFilterDto>, Failure>>;

	public abstract get(
		parameter: GeneticFileParseRequestFindParameterDto
	): Observable<Result<GeneticFileParseRequestDto, Failure>>;

	public abstract create(
		parameter: GeneticFileParseRequestCreateParameterDto
	): Observable<Result<GeneticFileParseRequestDto, Failure>>;

	public abstract update(
		findParameter: GeneticFileParseRequestFindParameterDto,
		parameter: GeneticFileParseRequestUpdateParameterDto
	): Observable<EmptyResult<Failure>>;

}
