import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PatientTreatmentCheckerFormInModel } from '../models/patient-treatment-checker-form/patient-treatment-checker-form.in-model';

export abstract class PatientTreatmentCheckerFormInModelResolver implements Resolve<PatientTreatmentCheckerFormInModel> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	): Observable<PatientTreatmentCheckerFormInModel> | PatientTreatmentCheckerFormInModel;

}
