import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PopulationComboboxModel, PopulationFilterModel, PopulationQueryHandlerService, PopulationResolver } from '@nmn-domain/populations';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PopulationViaQueryHandlerResolver extends PopulationResolver {

	constructor(
		private readonly populationQueryHandlerService: PopulationQueryHandlerService
	) {
		super();
	}

	public resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<Array<PopulationComboboxModel>> | Array<PopulationComboboxModel> {
		return this.populationQueryHandlerService
			.getAsComboboxes(PopulationFilterModel.createEmpty())
			.pipe(map(result => result.successOrDefault(() => [])));
	}

}
