import { Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { AllergyIntoleranceVerificationStatusFakeClientConfiguration } from '../configurations/allergy-intolerance-verification-status.fake-client.configuration';
import { AllergyIntoleranceVerificationStatusFakeClient } from '../services/allergy-intolerance-verification-status.fake-client';

export class AllergyIntoleranceVerificationStatusFakeClientBuilder {

	private configuration: AllergyIntoleranceVerificationStatusFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(
		configuration: AllergyIntoleranceVerificationStatusFakeClientConfiguration
	): AllergyIntoleranceVerificationStatusFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): AllergyIntoleranceVerificationStatusFakeClient {
		return new AllergyIntoleranceVerificationStatusFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
