import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { TimezoneComboboxDto } from '../models/timezone-combobox.dto';
import { TimezoneFilterDto } from '../models/timezone-filter.dto';

export abstract class TimezoneClient {

	public abstract getBulkAsComboboxes(filter: TimezoneFilterDto): Observable<Result<Array<TimezoneComboboxDto>, Failure>>;

}
