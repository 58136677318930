import { LoginModel } from '@nmn-domain/accounts/login/models/login.model';
import { StorageService } from '../entity-storages/storage.service';
import { UserConfigurationStorageService } from './user-configuration-storage.service';

export const updateSubscriptionsAfterLogin = (
	loginModel: LoginModel,
	storage: StorageService,
	userConfigurationStorage: UserConfigurationStorageService
) => {
	storage.defineUserProfile(loginModel.user);
	storage.defineTokenInfo(loginModel.tokenInfo);
	storage.defineUserFileStorageProfile(loginModel.userFileStorageProfile);
	userConfigurationStorage.defineUserConfiguration(loginModel.userConfiguration);
};
