import { Injector, Provider } from '@angular/core';
import { VaccineClient } from '@nmn-communication/vaccines';
import { vaccineClientFactory } from './factories/vaccine.client.factory';

export const vaccineProviders: Array<Provider> = [
	{
		provide: VaccineClient,
		useFactory: vaccineClientFactory,
		deps: [Injector]
	}
];
