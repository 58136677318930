export class FacebookAuthResponse {

	public readonly accessToken: string;

	private constructor(
		accessToken: string
	) {
		this.accessToken = accessToken;
	}

	public static createFromFacebookAuthResponseObj(authResponseObj: any): FacebookAuthResponse {
		return new FacebookAuthResponse(authResponseObj?.accessToken);
	}

}
