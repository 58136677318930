import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../../shared/builders/http-client.builder';
import { LoginHttpClientConfiguration } from '../configurations/login.http-client.configuration';
import { LoginHttpClient } from '../services/login.http-client';

export class LoginHttpClientBuilder extends HttpClientBuilder<LoginHttpClient, LoginHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): LoginHttpClient {
		return new LoginHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient)
		);
	}

}
