import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {
	AllergyIntoleranceVerificationStatusesResolver,
	AllergyIntoleranceVerificationStatusFilterModel,
	AllergyIntoleranceVerificationStatusQueryHandlerService
} from '@nmn-domain/allergy-intolerance-verification-statuses';
import { DescribedComboboxModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AllergyIntoleranceVerificationStatusesViaQueryHandlerResolver extends AllergyIntoleranceVerificationStatusesResolver {

	constructor(
		private readonly allergyIntoleranceVerificationStatusQueryHandlerService: AllergyIntoleranceVerificationStatusQueryHandlerService
	) {
		super();
	}

	public resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<Array<DescribedComboboxModel>> | Array<DescribedComboboxModel> {
		return this.allergyIntoleranceVerificationStatusQueryHandlerService
			.getAsDescribedComboboxes(AllergyIntoleranceVerificationStatusFilterModel.createEmpty())
			.pipe(map(result => result.successOrDefault(() => [])));
	}

}
