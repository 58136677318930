import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { GeneVariantComboboxModel } from '@nmn-domain/gene-variants';
import { GeneticTestResultModel } from '../genetic-test-result.model';

export class GeneticTestResultFormInModel {

	public readonly haplotype?: GeneVariantComboboxModel;
	public readonly diplotype?: GeneVariantComboboxModel;
	public readonly pharmacogenomicTestResultCustom?: string;

	public get isCustom(): boolean {
		return isStringDefinedAndNotEmpty(this.pharmacogenomicTestResultCustom);
	}

	private constructor(
		haplotype: GeneVariantComboboxModel | undefined,
		diplotype: GeneVariantComboboxModel | undefined,
		pharmacogenomicTestResultCustom: string | undefined
	) {
		this.haplotype = haplotype;
		this.diplotype = diplotype;
		this.pharmacogenomicTestResultCustom = pharmacogenomicTestResultCustom;
	}

	public static createFromExistingModel = (model: GeneticTestResultModel): GeneticTestResultFormInModel => {
		// TODO: Guard.isDefined(model);

		return new GeneticTestResultFormInModel(model.haplotype, model.diplotype, model.pharmacogenomicTestResultCustom);
	}

	public static createEmpty = (): GeneticTestResultFormInModel => {
		return new GeneticTestResultFormInModel(undefined, undefined, undefined);
	}

}
