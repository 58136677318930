import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserProfileResolver } from '@nmn-domain/accounts';
import { UserProfileModel } from '@nmn-domain/accounts/user-accounts/models/user-profile.model';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageService } from '../../../../../services';

@Injectable()
export class UserProfileViaStorageResolver extends UserProfileResolver implements OnDestroy {

	private readonly destroyed$ = new Subject<undefined>();
	private cachedUserProfile: UserProfileModel = undefined;

	constructor(
		private readonly storageService: StorageService
	) {
		super();

		this.storageService.userProfile$
			.pipe(takeUntil(this.destroyed$))
			.subscribe(value => { this.cachedUserProfile = value; });
	}

	public resolve(_: ActivatedRouteSnapshot, __: RouterStateSnapshot): Observable<UserProfileModel> | Promise<UserProfileModel> | UserProfileModel {
		return this.cachedUserProfile;
	}

	public ngOnDestroy(): void {
		this.destroyed$.next(undefined);
		this.destroyed$.complete();
	}

}
