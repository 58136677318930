export class ValueRestrictionOptional<TValue> {

	public readonly min: TValue | undefined;
	public readonly max: TValue | undefined;

	private constructor(
		min: TValue | undefined,
		max: TValue | undefined
	) {
		this.min = min;
		this.max = max;
	}

	public static clone<TValue>(obj: ValueRestrictionOptional<TValue>): ValueRestrictionOptional<TValue> {
		// TODO: Check with Guard.NotNull(obj)
		return new ValueRestrictionOptional<TValue>(obj.min, obj.max);
	}

	public static initUndefined<TValue>(): ValueRestrictionOptional<TValue> {
		return new ValueRestrictionOptional<TValue>(undefined, undefined);
	}

	public static init<TValue>(min: TValue, max: TValue): ValueRestrictionOptional<TValue> {
		// TODO: Check with Guard.NotNull()
		return new ValueRestrictionOptional<TValue>(min, max);
	}

	public static initMin<TValue>(min: TValue): ValueRestrictionOptional<TValue> {
		// TODO: Check with Guard.NotNull(min)
		return new ValueRestrictionOptional<TValue>(min, undefined);
	}

	public static initMax<TValue>(max: TValue): ValueRestrictionOptional<TValue> {
		// TODO: Check with Guard.NotNull(max)
		return new ValueRestrictionOptional<TValue>(undefined, max);
	}

	public static initNumber(min: number, max: number): ValueRestrictionOptional<number> {
		return ValueRestrictionOptional.init(min, max);
	}

	public static initMinNumber(min: number): ValueRestrictionOptional<number> {
		return ValueRestrictionOptional.initMin(min);
	}

	public static initMaxNumber(min: number): ValueRestrictionOptional<number> {
		return ValueRestrictionOptional.initMax(min);
	}

	public static initDate(min: Date, max: Date): ValueRestrictionOptional<Date> {
		return ValueRestrictionOptional.init(min, max);
	}

	public static initMinDate(min: Date): ValueRestrictionOptional<Date> {
		return ValueRestrictionOptional.initMin(min);
	}

	public static initMaxDate(min: Date): ValueRestrictionOptional<Date> {
		return ValueRestrictionOptional.initMax(min);
	}

}
