import { isValueDefined } from '@nmn-core/utils';

export enum DocumentIconType {

	Undefined = 'undefined',

	Bill = 'bill',
	DrugInstruction = 'drugInstruction',
	Hospital = 'hospital',
	Insurance = 'insurance',
	LabResults = 'labResults',
	Photo = 'photo',
	Observation = 'observation',
	Prescription = 'prescription',

	Genetic = 'genetic',

	Other = 'other'

}

export const mapDocumentIconTypeFromString = (stringValue: string): DocumentIconType => {
	const enumValue = Object
		.keys(DocumentIconType)
		.find(x => DocumentIconType[x] === stringValue);

	return isValueDefined(enumValue) ? stringValue as DocumentIconType : DocumentIconType.Undefined;
};
