import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { VaccineDescribedComboboxDto } from '../models/vaccine-described-combobox.dto';
import { VaccineFilterDto } from '../models/vaccine-filter.dto';

export abstract class VaccineClient {

	public abstract getBulkAsComboboxes(filter: VaccineFilterDto): Observable<Result<Array<VaccineDescribedComboboxDto>, Failure>>;

}
