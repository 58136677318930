import { isValueDefined } from '@nmn-core/utils';

export class Maybe<T> {

	private value: T | undefined;
	private isValueDefined: boolean;

	private constructor(
		value: T | undefined
	) {
		this.value = value;
		this.isValueDefined = isValueDefined(value);
	}

	public static some<T>(value: T): Maybe<T> {
		if (isValueDefined(value)) {
			throw Error('Provided value must be defined.');
		}

		return new Maybe(value);
	}

	public static none<T>(): Maybe<T> {
		return new Maybe<T>(undefined);
	}

	public static fromValue<T>(value: T | undefined): Maybe<T> {
		return isValueDefined(value) ? Maybe.some(value) : Maybe.none();
	}

	public getOrElse(defaultValue: T): T {
		return this.isValueDefined ? this.value : defaultValue;
	}

	public map<R>(func: (wrapped: T) => R): Maybe<R> {
		return this.isValueDefined ? Maybe.fromValue(func(this.value)) : Maybe.none<R>();
	}

	public flatMap<TResult>(func: (wrapped: T) => Maybe<TResult>): Maybe<TResult> {
		return this.isValueDefined ? func(this.value) : Maybe.none<TResult>();
	}

	public combine<TValue, Result>(maybe: Maybe<TValue>, select: (a: T, b: TValue) => Result): Maybe<Result> {
		return this.flatMap(a => maybe.map(b => select(a, b)));
	}

}
