import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FacebookCustomIconComponent } from './components/facebook-custom-icon/facebook-custom-icon.component';
import { FacebookIconBlueComponent } from './components/facebook-icon-blue/facebook-icon-blue.component';

@NgModule({
	exports: [
		FacebookIconBlueComponent,
		FacebookCustomIconComponent
	],
	declarations: [
		FacebookIconBlueComponent,
		FacebookCustomIconComponent
	],
	imports: [
		CommonModule
	]
})
export class FacebookIconModule { }
