import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { DescribedComboboxModel, FailureModel } from '../../shared';
import { AllergyIntoleranceStatusFilterModel } from '../models/allergy-intolerance-status-filter.model';

export abstract class AllergyIntoleranceStatusQueryHandlerService {

	public abstract getAsDescribedComboboxes(query: AllergyIntoleranceStatusFilterModel): Observable<Result<Array<DescribedComboboxModel>, FailureModel>>;

}
