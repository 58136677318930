import { Failure } from '@nmn-communication/shared';
import { EmptyResult } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { ConfirmEmailParameter } from '../models/confirm-email.parameter';
import { InitiateEmailConfirmationParameter } from '../models/initiate-email-confirmation.parameter';

export abstract class EmailConfirmationClient {

	public abstract confirmEmail(parameter: ConfirmEmailParameter): Observable<EmptyResult<Failure>>;

	public abstract initiateEmailConfirmation(parameter: InitiateEmailConfirmationParameter): Observable<EmptyResult<Failure>>;

}
