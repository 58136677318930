import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { DescribedComboboxModel } from '../../shared';

export class MedicationAdmRouteFilterModel {

	public readonly ids?: Array<string>;

	public readonly ignoreIds?: Array<string>;

	public readonly searchPattern?: string;

	constructor(
		ids: Array<string> | undefined,
		ignoreIds: Array<string> | undefined,
		searchPattern: string | undefined
	) {
		this.ids = ids;
		this.ignoreIds = ignoreIds;
		this.searchPattern = searchPattern;
	}

	public static createEmpty(): MedicationAdmRouteFilterModel {
		return new MedicationAdmRouteFilterModel(undefined, undefined, undefined);
	}

	public static createForMedicationAdmRouteAutocomplete(
		searchPattern: string,
		selectedItem: DescribedComboboxModel
	): MedicationAdmRouteFilterModel {
		return new MedicationAdmRouteFilterModel(
			undefined,
			isStringDefinedAndNotEmpty(selectedItem?.id) ? [selectedItem.id] : undefined,
			searchPattern
		);
	}

	public static createForMedicationAdmRoutesAutocomplete(
		searchPattern: string,
		ignoreIds: Array<string>
	): MedicationAdmRouteFilterModel {
		return new MedicationAdmRouteFilterModel(undefined, ignoreIds, searchPattern);
	}

}
