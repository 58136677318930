import { Result } from '@nmn-core/shared';
import { LoginModel } from '@nmn-domain/accounts/login/models/login.model';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';

export abstract class CurrentUserProfileQueryHandlerService {

	// Please use 'ServiceStorage' insted of this method.
	public abstract get(): Observable<Result<LoginModel, FailureModel>>;

}
