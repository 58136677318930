import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { RegionComboboxDto } from '../models/region-combobox.dto';
import { RegionFilterDto } from '../models/region-filter.dto';

export abstract class RegionClient {

	public abstract getBulkAsComboboxes(filter: RegionFilterDto): Observable<Result<Array<RegionComboboxDto>, Failure>>;

}
