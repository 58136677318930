import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
	DocumentClient, DocumentCreateParameter, DocumentDeleteAttachmentParameter, DocumentDeleteBulkParameter, DocumentDescribedComboboxDto,
	DocumentDto, DocumentFilterDto, DocumentFindParameter, DocumentUpdateParameter
} from '@nmn-communication/documents';
import { Failure, PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { constructHttpParams } from '@nmn-core/utils';
import {
	createEmptyResultFromResponse, createFailedEmptyResultFromErrorResponse, createFailedResultFromErrorResponse, createResultFromResponse
} from 'app/modules/shared/error-handling/utils/http-client-default-handler.utils';
import { Observable } from 'rxjs';
import { DocumentHttpClientConfiguration } from '../configurations/document.http-client.configuration';

export class DocumentHttpClient extends DocumentClient {

	constructor(
		private readonly configuration: DocumentHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public getBulkAsComboboxes(
		findParameter: DocumentFilterDto
	): Observable<Result<Array<DocumentDescribedComboboxDto>, Failure>> {
		const url = `${this.configuration.baseUrl}/api/patients/${findParameter.patientIds[0]}/documents/combobox`;
		const params = constructHttpParams(findParameter);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<Array<DocumentDescribedComboboxDto>>(url, { params, headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public getBulk(
		findParameter: PageOptionsDto<DocumentFilterDto>
	): Observable<Result<PagedCollectionDto<DocumentDto, DocumentFilterDto>, Failure>> {
		const url = `${this.configuration.baseUrl}/api/patients/${findParameter.filter.patientIds[0]}/documents`;
		const params = constructHttpParams(findParameter);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<PagedCollectionDto<DocumentDto, DocumentFilterDto>>(url, { params, headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public get(
		findParameter: DocumentFindParameter
	): Observable<Result<DocumentDto, Failure>> {
		const url = `${this.configuration.baseUrl}/api/patients/${findParameter.patientId}/documents/${findParameter.id}`;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<DocumentDto>(url, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public create(
		createParameter: DocumentCreateParameter
	): Observable<Result<string, Failure>> {
		const url = `${this.configuration.baseUrl}/api/patients/${createParameter.patientId}/documents`;
		const payload = createParameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post<string>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public delete(
		findParameter: DocumentFindParameter
	): Observable<EmptyResult<Failure>> {
		const url = `${this.configuration.baseUrl}/api/patients/${findParameter.patientId}/documents/${findParameter.id}`;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.delete(url, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

	public deleteBulk(
		findParameter: DocumentDeleteBulkParameter
	): Observable<EmptyResult<Failure>> {
		const url = `${this.configuration.baseUrl}/api/patients/${findParameter.patientId}/documents`;
		const params = constructHttpParams(findParameter);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.delete(url, { headers, params })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

	public deleteAttachment(
		findParameter: DocumentFindParameter,
		deleteParameter: DocumentDeleteAttachmentParameter
	): Observable<EmptyResult<Failure>> {
		const url = `${this.configuration.baseUrl}/api/patients/${findParameter.patientId}/documents/${findParameter.id}/attachments/${deleteParameter.attachmentId}`;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.delete(url, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

	public update(
		findParameter: DocumentFindParameter,
		updateParameter: DocumentUpdateParameter
	): Observable<EmptyResult<Failure>> {
		const url = `${this.configuration.baseUrl}/api/patients/${findParameter.patientId}/documents/${findParameter.id}`;
		const payload = updateParameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put(url, payload, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

}
