import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TreatmentCheckerFormConfiguration, TreatmentCheckerFormConfigurationResolver } from '@nmn-domain/treatment-checker';
import { Observable } from 'rxjs';

@Injectable()
export class TreatmentCheckerFormConfigurationDefaultResolver extends TreatmentCheckerFormConfigurationResolver {

	public resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<TreatmentCheckerFormConfiguration> | TreatmentCheckerFormConfiguration {
		return {
			treatmentInfoFormConfiguration: {
				restrictions: treatmentInfoFormConfigurationRestrictions
			},
			anamnesisFormConfiguration: {
				restrictions: {}
			},
			yourDataFormConfiguration: {
				restrictions: {}
			}
		};
	}

}

const treatmentInfoFormConfigurationRestrictions = {
	age: {
		min: 0,
		max: 150
	},
	height: {
		min: 0,
		max: 250
	},
	weight: {
		min: 0,
		max: 200
	}
};
