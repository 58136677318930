import { Injectable } from '@angular/core';
import { HealthIssueClient } from '@nmn-communication/health-issues';
import { Result } from '@nmn-core/shared';
import { HealthIssueCommandHandlerService, HealthIssueCreateCommand, HealthIssueDeleteCommand, HealthIssueUpdateCommand } from '@nmn-domain/health-issues';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommandResult, EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapCreateCommandToParameter, mapUpdateCommandToParameter } from '../factories/health-issue-command.factory';

@Injectable()
export class HealthIssueCommandHandlerViaClientService extends HealthIssueCommandHandlerService {

	constructor(
		private readonly client: HealthIssueClient
	) {
		super();
	}

	public handleCreateCommand(command: HealthIssueCreateCommand): Observable<Result<CommandResult<HealthIssueCreateCommand, string>, FailureModel>> {
		return this.client
			.create(mapCreateCommandToParameter(command))
			.pipe(
				map(result => result.map(success => new CommandResult(command, success), mapFailureToFailureModel))
			);
	}

	public handleUpdateCommand(command: HealthIssueUpdateCommand): Observable<Result<EmptyCommandResult<HealthIssueUpdateCommand>, FailureModel>> {
		return this.client
			.update(
				{ id: command.id, patientId: command.patientId },
				mapUpdateCommandToParameter(command)
			)
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

	public handleDeleteCommand(command: HealthIssueDeleteCommand): Observable<Result<EmptyCommandResult<HealthIssueDeleteCommand>, FailureModel>> {
		return this.client
			.delete({ id: command.id, patientId: command.patientId })
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

}
