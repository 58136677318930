import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { HealthIssueSeverityHttpClientConfiguration } from '../configurations/health-issue-severity.http-client.configuration';

export class HealthIssueSeverityHttpClientConfigurationBuilder extends HttpClientConfigurationBuilder<HealthIssueSeverityHttpClientConfiguration> {

	public build(): HealthIssueSeverityHttpClientConfiguration {
		return new HealthIssueSeverityHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
