import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { DescribedComboboxModel, FailureModel } from '../../shared';
import { AllergyIntoleranceVerificationStatusFilterModel } from '../models/allergy-intolerance-verification-status-filter.model';

export abstract class AllergyIntoleranceVerificationStatusQueryHandlerService {

	public abstract getAsDescribedComboboxes(query: AllergyIntoleranceVerificationStatusFilterModel): Observable<Result<Array<DescribedComboboxModel>, FailureModel>>;

}
