import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { ServerConfigurationDto } from '../models/server-configuration.dto';

export abstract class ServerConfigurationClient {

	public abstract get(): Observable<Result<ServerConfigurationDto, Failure>>;

}
