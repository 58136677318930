import { isValueDefined } from '@nmn-core/utils';

export class NotificationDialogInModel {

	public readonly message: string;
	public readonly title: string;
	public readonly confirmBtnTitle: string;

	private constructor(
		message: string,
		title: string,
		confirmBtnTitle: string,
	) {
		// TODO: Guards
		this.message = message;
		this.title = title;
		this.confirmBtnTitle = confirmBtnTitle;
	}

	public static createDefault(
		message?: string,
		title?: string,
		confirmBtnTitle?: string
	): NotificationDialogInModel {
		return new NotificationDialogInModel(
			isValueDefined(message) ? message : 'shared.notifications.dialog.defaultParagraph',
			isValueDefined(title) ? title : 'shared.notifications.dialog.title',
			isValueDefined(confirmBtnTitle) ? confirmBtnTitle : 'shared.notifications.dialog.closeBtn');
	}

}
