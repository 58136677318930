import { Provider } from '@angular/core';
import { DoctorEncounterCommandHandlerService, DoctorEncounterQueryHandlerService, DoctorEncounterResolver, DoctorEncounterStatusesResolver } from '@nmn-domain/doctor-encounters';
import { DoctorEncounterStatusesViaEnumResolver } from './resolvers/doctor-encounter-status-via-enum.resolver';
import { DoctorEncounterViaQueryHandlerResolver } from './resolvers/doctor-encounter-via-query-handler.resolver';
import { DoctorEncounterCommandHandlerViaClientService } from './services/doctor-encounter.command-handler-via-client.service';
import { DoctorEncounterQueryHandlerViaClientService } from './services/doctor-encounter.query-handler-via-client.service';

export const doctorEncounterProviders: Array<Provider> = [
	{
		provide: DoctorEncounterQueryHandlerService,
		useClass: DoctorEncounterQueryHandlerViaClientService
	},
	{
		provide: DoctorEncounterCommandHandlerService,
		useClass: DoctorEncounterCommandHandlerViaClientService
	},
	{
		provide: DoctorEncounterResolver,
		useClass: DoctorEncounterViaQueryHandlerResolver
	},
	{
		provide: DoctorEncounterStatusesResolver,
		useClass: DoctorEncounterStatusesViaEnumResolver
	}
];
