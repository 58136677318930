import { Injector, Provider } from '@angular/core';
import { DocumentClient } from '@nmn-communication/documents';
import { documentClientFactory } from './factories/document.factory';

export const documentProviders: Array<Provider> = [
	{
		provide: DocumentClient,
		useFactory: documentClientFactory,
		deps: [Injector]
	}
];
