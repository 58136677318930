import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { VaccinationFormConfiguration } from '../models/vaccination-form/vaccination-form.configuration';

export abstract class VaccinationFormConfigurationResolver implements Resolve<VaccinationFormConfiguration> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		routerState: RouterStateSnapshot
	): Observable<VaccinationFormConfiguration> | Promise<VaccinationFormConfiguration> | VaccinationFormConfiguration;

}
