import { HttpErrorResponse } from '@angular/common/http';
import { Failure } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { isFunctionDefined, isValueDefined } from '@nmn-core/utils';
import { ObservableInput, of, OperatorFunction } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const createResultFromResponse = <TResultDto>() =>
	map<TResultDto, Result<TResultDto, Failure>>(
		dto => isValueDefined(dto) ?
			Result.success<TResultDto, Failure>(dto) :
			Result.failure<TResultDto, Failure>(Failure.createUndefined('response is undefined'))
	);

export const createResultFromResponseViaProjection = <T extends { id: string }, TResultDto>(
	project: (value: T) => TResultDto
) => map<T, Result<TResultDto, Failure>>(
	dto => {
		const projectResult = isFunctionDefined(project) && isValueDefined(dto) ? project(dto) : undefined;

		return isValueDefined(projectResult) ?
			Result.success<TResultDto, Failure>(projectResult) :
			Result.failure<TResultDto, Failure>(Failure.createUndefined('response is undefined'));
	}
);

export const createResultFromResponseViaIdStringProjection = <T extends { id: string }>() =>
	createResultFromResponseViaProjection<T, string>(item => item.id);

export const createFailedResultFromErrorResponse = <TResultDto>() =>
	catchError<Result<TResultDto, Failure>, ObservableInput<Result<TResultDto, Failure>>>((
		httpErrorResponse: HttpErrorResponse
	) => {
		const failureModel = Failure.create(httpErrorResponse.error);

		return of(Result.failure<TResultDto, Failure>(failureModel));
	});

export const createEmptyResultFromResponse =
	<TResultDto>(): OperatorFunction<TResultDto, EmptyResult<Failure>> =>
		map<TResultDto, EmptyResult<Failure>>(_ => EmptyResult.success<Failure>());

export const createEmptyResultFromEmptyResponse =
	<TResultDto>(): OperatorFunction<TResultDto, EmptyResult<Failure>> =>
		map<TResultDto, EmptyResult<Failure>>(_ => EmptyResult.success<Failure>());

export const createFailedEmptyResultFromErrorResponse = () =>
	catchError<EmptyResult<Failure>, ObservableInput<EmptyResult<Failure>>>((
		httpErrorResponse: HttpErrorResponse
	) => {
		const failureModel = Failure.create(httpErrorResponse.error);

		return of(EmptyResult.failure<Failure>(failureModel));
	});
