import { VaccineDescribedComboboxDto, VaccineFilterDto } from '@nmn-communication/vaccines';
import { VaccineDescribedComboboxModel, VaccineFilterModel } from '@nmn-domain/vaccines';

export const mapFilterModelToDto = (model: VaccineFilterModel): VaccineFilterDto => (
	{
		...model
	}
);

export const mapFilterDtoToModel = (dto: VaccineFilterDto): VaccineFilterModel => (
	new VaccineFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern,
		dto.fromDiseaseIds,
		dto.ignoreFromDiseaseIds
	)
);

export const mapVaccineDescribedComboboxDtoToModel = (dto: VaccineDescribedComboboxDto): VaccineDescribedComboboxModel => (
	new VaccineDescribedComboboxModel(
		dto.id,
		dto.displayText,
		dto.description,
		dto.countOfDoses,
		dto.manufacturer,
		dto.type,
		dto.forDiseases
	)
);
