export class FailureControlDirectoryModel {

	public readonly ctrlName: string;
	public readonly severity: PropertySeverityType;
	public readonly failureHint: string;
	public readonly index?: number;

	constructor(
		ctrlName: string,
		severity: PropertySeverityType,
		failureHint: string,
		index?: number | undefined
	) {
		// TODO: Guard
		this.ctrlName = ctrlName;
		this.severity = severity;
		this.failureHint = failureHint;
		this.index = index;
	}

}

export enum PropertySeverityType {

	Undefined = 'undefined',
	Info = 'info',
	Warning = 'warning',
	Error = 'error'

}
