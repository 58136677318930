import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FailureModel } from '../../shared/failures/failure.model';
import { MedicationBodySiteDescribedComboboxModel } from '../models/medication-body-site-described-combobox.model';
import { MedicationBodySiteFilterModel } from '../models/medication-body-site-filter.model';
export abstract class MedicationBodySiteQueryHandlerService {

	public abstract getAsDescribedComboboxes(
		query: MedicationBodySiteFilterModel
	): Observable<Result<Array<MedicationBodySiteDescribedComboboxModel>, FailureModel>>;

}
