import { Injectable } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { CurrentUserConfigurationCommandHandlerService, CurrentUserConfigurationUpdateCommand } from '@nmn-domain/accounts';
import { FailureModel } from '@nmn-domain/shared';
import { FailureLocalizationModel } from '@nmn-domain/shared/failures/failure-localization-parameters.model';
import { FailureWrapperModel } from '@nmn-domain/shared/failures/failure-wrapper.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FailureHandlingService, StorageService, UserConfigurationStorageService } from '../../../../services';

@Injectable({ providedIn: 'root' })
export class UserConfigurationService {

	private singleQueryHandler$ = new Subject<undefined>();

	constructor(
		private readonly failureHandlingService: FailureHandlingService,
		private readonly storage: StorageService,
		private readonly userConfigurationStorage: UserConfigurationStorageService,
		private readonly currentUserConfigurationCommandService: CurrentUserConfigurationCommandHandlerService) {
	}

	public notifyOnChanges(): void {
		if (isValueDefined(this.singleQueryHandler$)) {
			this.singleQueryHandler$.next(undefined);
			this.singleQueryHandler$.complete();
		}
		this.initializeSingleQueryHandler();
		this.currentUserConfigurationCommandService
			.update(CurrentUserConfigurationUpdateCommand.createFromExistingConfiguration(this.userConfigurationStorage.userConfiguration, this.storage.activePatientAlias))
			.pipe(takeUntil(this.singleQueryHandler$))
			.subscribe(
				_ => { },
				error => { this.failureHandlingService.handleFailure(FailureModel.createForSubscribtionIssue(FailureWrapperModel.createFromValue(error), FailureLocalizationModel.createFromValue(userConfigurationLocalizationPattern))); }
			);
		this.userConfigurationStorage.defineUserConfiguration(this.userConfigurationStorage.userConfiguration);
	}

	private initializeSingleQueryHandler(): void {
		this.singleQueryHandler$ = new Subject<undefined>();
	}

}

export const userConfigurationLocalizationPattern = 'modules.accounts.userConfiguration';
