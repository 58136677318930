import { Injector } from '@angular/core';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { isValueDefined } from '@nmn-core/utils';
import { MedicationRateQuantityTypeFakeClientConfiguration } from '../configurations/medication-rate-quantity-type.fake-client.configuration';
import { MedicationRateQuantityTypeFakeClient } from '../services/medication-rate-quantity-type.fake-client';

export class MedicationRateQuantityTypeFakeClientBuilder {

	private configuration: MedicationRateQuantityTypeFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(
		configuration: MedicationRateQuantityTypeFakeClientConfiguration
	): MedicationRateQuantityTypeFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): MedicationRateQuantityTypeFakeClient {
		return new MedicationRateQuantityTypeFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
