import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FailureModel } from '../../shared/failures/failure.model';
import { MedicationAdmRouteDescribedComboboxModel } from '../models/medication-adm-route-described-combobox.model';
import { MedicationAdmRouteFilterModel } from '../models/medication-adm-route-filter.model';
export abstract class MedicationAdmRouteQueryHandlerService {

	public abstract getAsDescribedComboboxes(
		query: MedicationAdmRouteFilterModel
	): Observable<Result<Array<MedicationAdmRouteDescribedComboboxModel>, FailureModel>>;

}
