import { Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { PopulationFakeClientConfiguration } from '../configurations/population.fake-client.configuration';
import { PopulationFakeClient } from '../services/population.fake-client';

export class PopulationFakeClientBuilder {

	private configuration: PopulationFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(configuration: PopulationFakeClientConfiguration): PopulationFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): PopulationFakeClient {
		return new PopulationFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase)
		);
	}

}
