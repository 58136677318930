import { Injector } from '@angular/core';
import { DocumentTypeClient } from '@nmn-communication/document-types';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { DocumentTypeFakeClientBuilder } from '../builders/document-type.fake-client.builder';
import { DocumentTypeFakeClientConfigurationBuilder } from '../builders/document-type.fake-client.configuration.builder';
import { DocumentTypeHttpClientBuilder } from '../builders/document-type.http-client.builder';
import { DocumentTypeHttpClientConfigurationBuilder } from '../builders/document-type.http-client.configuration.builder';
import { DocumentTypeFakeClient } from '../services/document-type.fake-client';
import { DocumentTypeHttpClient } from '../services/document-type.http-client';

export const documentTypeClientFactory = (injector: Injector): DocumentTypeClient => {
	const useFake = environment?.api?.resources?.documentTypes?.useFake ||
		!isValueDefined(environment?.api?.resources?.documentTypes?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		documentTypeFakeClientFactory(injector) :
		documentTypeHttpClientFactory(injector);
};

export const documentTypeFakeClientFactory = (
	injector: Injector
): DocumentTypeFakeClient => {
	const configuration = new DocumentTypeFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.documentTypes.serverDelayMs)
		.build();

	return new DocumentTypeFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const documentTypeHttpClientFactory = (
	injector: Injector
): DocumentTypeHttpClient => {
	const configuration = new DocumentTypeHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.documentTypes?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.documentTypes.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.documentTypes?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new DocumentTypeHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
