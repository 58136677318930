export const timeZoneToRegionIdMapping = {
	'Europe/Andorra': 'Andorra',
	'Asia/Dubai': 'United Arab Emirates',
	'Asia/Kabul': 'Afghanistan',
	'Europe/Tirane': 'Albania',
	'Asia/Yerevan': 'Armenia',
	'Antarctica/Casey': 'Antarctica',
	'Antarctica/Davis': 'Antarctica',
	'Antarctica/Mawson': 'Antarctica',
	'Antarctica/Palmer': 'Antarctica',
	'Antarctica/Rothera': 'Antarctica',
	'Antarctica/Troll': 'Antarctica',
	'Antarctica/Vostok': 'Antarctica',
	'America/Argentina/Buenos_Aires': 'Argentina',
	'America/Argentina/Cordoba': 'Argentina',
	'America/Argentina/Salta': 'Argentina',
	'America/Argentina/Jujuy': 'Argentina',
	'America/Argentina/Tucuman': 'Argentina',
	'America/Argentina/Catamarca': 'Argentina',
	'America/Argentina/La_Rioja': 'Argentina',
	'America/Argentina/San_Juan': 'Argentina',
	'America/Argentina/Mendoza': 'Argentina',
	'America/Argentina/San_Luis': 'Argentina',
	'America/Argentina/Rio_Gallegos': 'Argentina',
	'America/Argentina/Ushuaia': 'Argentina',
	'Pacific/Pago_Pago': 'Samoa (American)',

	// Austria
	'Europe/Vienna': 'Austria',

	// Australia
	'Australia/Lord_Howe': 'AUS',
	'Antarctica/Macquarie': 'AUS',
	'Australia/Hobart': 'AUS',
	'Australia/Melbourne': 'AUS',
	'Australia/Sydney': 'AUS',
	'Australia/Broken_Hill': 'AUS',
	'Australia/Brisbane': 'AUS',
	'Australia/Lindeman': 'AUS',
	'Australia/Adelaide': 'AUS',
	'Australia/Darwin': 'AUS',
	'Australia/Perth': 'AUS',
	'Australia/Eucla': 'AUS',

	'Asia/Baku': 'Azerbaijan',
	'America/Barbados': 'Barbados',
	'Asia/Dhaka': 'Bangladesh',

	// Belgium
	'Europe/Brussels': 'Belgium',

	'Europe/Sofia': 'Bulgaria',
	'Atlantic/Bermuda': 'Bermuda',
	'Asia/Brunei': 'Brunei',
	'America/La_Paz': 'Bolivia',
	'America/Noronha': 'Brazil',
	'America/Belem': 'Brazil',
	'America/Fortaleza': 'Brazil',
	'America/Recife': 'Brazil',
	'America/Araguaina': 'Brazil',
	'America/Maceio': 'Brazil',
	'America/Bahia': 'Brazil',
	'America/Sao_Paulo': 'Brazil',
	'America/Campo_Grande': 'Brazil',
	'America/Cuiaba': 'Brazil',
	'America/Santarem': 'Brazil',
	'America/Porto_Velho': 'Brazil',
	'America/Boa_Vista': 'Brazil',
	'America/Manaus': 'Brazil',
	'America/Eirunepe': 'Brazil',
	'America/Rio_Branco': 'Brazil',
	'Asia/Thimphu': 'Bhutan',

	// Belarus
	'Europe/Minsk': 'Belarus',

	'America/Belize': 'Belize',

	// Canada
	'America/St_Johns': 'CAN',
	'America/Halifax': 'CAN',
	'America/Glace_Bay': 'CAN',
	'America/Moncton': 'CAN',
	'America/Goose_Bay': 'CAN',
	'America/Toronto': 'CAN',
	'America/Nipigon': 'CAN',
	'America/Thunder_Bay': 'CAN',
	'America/Iqaluit': 'CAN',
	'America/Pangnirtung': 'CAN',
	'America/Winnipeg': 'CAN',
	'America/Rainy_River': 'CAN',
	'America/Resolute': 'CAN',
	'America/Rankin_Inlet': 'CAN',
	'America/Regina': 'CAN',
	'America/Swift_Current': 'CAN',
	'America/Edmonton': 'CAN',
	'America/Cambridge_Bay': 'CAN',
	'America/Yellowknife': 'CAN',
	'America/Inuvik': 'CAN',
	'America/Dawson_Creek': 'CAN',
	'America/Fort_Nelson': 'CAN',
	'America/Whitehorse': 'CAN',
	'America/Dawson': 'CAN',
	'America/Vancouver': 'CAN',

	'Indian/Cocos': 'Cocos (Keeling) Islands',

	// Switzerland
	'Europe/Zurich': 'Switzerland',

	'Africa/Abidjan': 'Côte d\'Ivoire',
	'Pacific/Rarotonga': 'Cook Islands',
	'America/Santiago': 'Chile',
	'America/Punta_Arenas': 'Chile',
	'Pacific/Easter': 'Chile',

	// China
	'Asia/Shanghai': 'China',
	'Asia/Urumqi': 'China',

	'America/Bogota': 'Colombia',
	'America/Costa_Rica': 'Costa Rica',
	'America/Havana': 'Cuba',
	'Atlantic/Cape_Verde': 'Cape Verde',
	'Indian/Christmas': 'Christmas Island',
	'Asia/Nicosia': 'Cyprus',
	'Asia/Famagusta': 'Cyprus',

	// Czech Republic
	'Europe/Prague': 'Czech Republic',

	// Germany
	'Europe/Berlin': 'DEU',
	'Europe/Busingen': 'DEU',

	// Denmark
	'Europe/Copenhagen': 'Denmark',

	'America/Santo_Domingo': 'Dominican Republic',
	'Africa/Algiers': 'Algeria',
	'America/Guayaquil': 'Ecuador',
	'Pacific/Galapagos': 'Ecuador',

	// Estonia
	'Europe/Tallinn': 'Estonia',

	'Africa/Cairo': 'Egypt',
	'Africa/El_Aaiun': 'Western Sahara',

	// Spain
	'Europe/Madrid': 'Spain',
	'Africa/Ceuta': 'Spain',
	'Atlantic/Canary': 'Spain',

	// Finland
	'Europe/Helsinki': 'FIN',

	'Pacific/Fiji': 'Fiji',
	'Atlantic/Stanley': 'Falkland Islands',
	'Pacific/Chuuk': 'Micronesia',
	'Pacific/Pohnpei': 'Micronesia',
	'Pacific/Kosrae': 'Micronesia',

	// Faroe Islands
	'Atlantic/Faroe': 'Faroe Islands',

	// France
	'Europe/Paris': 'France',

	// Great Britain (UK)
	'Europe/London': 'GBR',

	'Asia/Tbilisi': 'Georgia',
	'America/Cayenne': 'French Guiana',
	'Europe/Gibraltar': 'Gibraltar',
	'America/Nuuk': 'Greenland',
	'America/Danmarkshavn': 'Greenland',
	'America/Scoresbysund': 'Greenland',
	'America/Thule': 'Greenland',

	// Greece
	'Europe/Athens': 'Greece',

	'Atlantic/South_Georgia': 'South Georgia & the South Sandwich Islands',
	'America/Guatemala': 'Guatemala',
	'Pacific/Guam': 'Guam',
	'Africa/Bissau': 'Guinea-Bissau',
	'America/Guyana': 'Guyana',
	'Asia/Hong_Kong': 'Hong Kong',
	'America/Tegucigalpa': 'Honduras',
	'America/Port-au-Prince': 'Haiti',

	// Hungary
	'Europe/Budapest': 'Hungary',

	'Asia/Jakarta': 'Indonesia',
	'Asia/Pontianak': 'Indonesia',
	'Asia/Makassar': 'Indonesia',
	'Asia/Jayapura': 'Indonesia',

	// Ireland
	'Europe/Dublin': 'Ireland',

	'Asia/Jerusalem': 'Israel',

	// India
	'Asia/Kolkata': 'India',

	'Indian/Chagos': 'British Indian Ocean Territory',
	'Asia/Baghdad': 'Iraq',
	'Asia/Tehran': 'Iran',
	'Atlantic/Reykjavik': 'Iceland',

	// Italy
	'Europe/Rome': 'Italy',

	'America/Jamaica': 'Jamaica',
	'Asia/Amman': 'Jordan',

	// Japan
	'Asia/Tokyo': 'JPN',

	'Africa/Nairobi': 'Kenya',
	'Asia/Bishkek': 'Kyrgyzstan',
	'Pacific/Tarawa': 'Kiribati',
	'Pacific/Kanton': 'Kiribati',
	'Pacific/Kiritimati': 'Kiribati',
	'Asia/Pyongyang': 'Korea (North)',

	// Korea (South)
	'Asia/Seoul': 'Korea',

	'Asia/Almaty': 'Kazakhstan',
	'Asia/Qyzylorda': 'Kazakhstan',
	'Asia/Qostanay': 'Kazakhstan',
	'Asia/Aqtobe': 'Kazakhstan',
	'Asia/Aqtau': 'Kazakhstan',
	'Asia/Atyrau': 'Kazakhstan',
	'Asia/Oral': 'Kazakhstan',
	'Asia/Beirut': 'Lebanon',
	'Asia/Colombo': 'Sri Lanka',
	'Africa/Monrovia': 'Liberia',

	// Lithuania
	'Europe/Vilnius': 'Lithuania',

	// Luxembourg
	'Europe/Luxembourg': 'Luxembourg',

	// Latvia
	'Europe/Riga': 'Latvia',

	'Africa/Tripoli': 'Libya',

	// Morocco
	'Africa/Casablanca': 'MAR',

	// Monaco
	'Europe/Monaco': 'Monaco',

	// Moldova
	'Europe/Chisinau': 'Moldova',

	'Pacific/Majuro': 'Marshall Islands',
	'Pacific/Kwajalein': 'Marshall Islands',
	'Asia/Yangon': 'Myanmar (Burma)',
	'Asia/Ulaanbaatar': 'Mongolia',
	'Asia/Hovd': 'Mongolia',
	'Asia/Choibalsan': 'Mongolia',
	'Asia/Macau': 'Macau',
	'America/Martinique': 'Martinique',
	'Europe/Malta': 'Malta',
	'Indian/Mauritius': 'Mauritius',
	'Indian/Maldives': 'Maldives',
	'America/Mexico_City': 'Mexico',
	'America/Cancun': 'Mexico',
	'America/Merida': 'Mexico',
	'America/Monterrey': 'Mexico',
	'America/Matamoros': 'Mexico',
	'America/Mazatlan': 'Mexico',
	'America/Chihuahua': 'Mexico',
	'America/Ojinaga': 'Mexico',
	'America/Hermosillo': 'Mexico',
	'America/Tijuana': 'Mexico',
	'America/Bahia_Banderas': 'Mexico',
	'Asia/Kuala_Lumpur': 'Malaysia',
	'Asia/Kuching': 'Malaysia',
	'Africa/Maputo': 'Mozambique',
	'Africa/Windhoek': 'Namibia',
	'Pacific/Noumea': 'New Caledonia',
	'Pacific/Norfolk': 'Norfolk Island',
	'Africa/Lagos': 'Nigeria',
	'America/Managua': 'Nicaragua',

	// Netherlands
	'Europe/Amsterdam': 'NLD',

	// Norway
	'Europe/Oslo': 'Norway',

	'Asia/Kathmandu': 'Nepal',
	'Pacific/Nauru': 'Nauru',
	'Pacific/Niue': 'Niue',

	// New Zealand
	'Pacific/Auckland': 'NZL',
	'Pacific/Chatham': 'NZL',

	'America/Panama': 'Panama',
	'America/Lima': 'Peru',
	'Pacific/Tahiti': 'French Polynesia',
	'Pacific/Marquesas': 'French Polynesia',
	'Pacific/Gambier': 'French Polynesia',
	'Pacific/Port_Moresby': 'Papua New Guinea',
	'Pacific/Bougainville': 'Papua New Guinea',
	'Asia/Manila': 'Philippines',
	'Asia/Karachi': 'Pakistan',

	// Poland
	'Europe/Warsaw': 'POL',

	'America/Miquelon': 'St Pierre & Miquelon',
	'Pacific/Pitcairn': 'Pitcairn',
	'America/Puerto_Rico': 'Puerto Rico',
	'Asia/Gaza': 'Palestine',
	'Asia/Hebron': 'Palestine',

	// Portugal
	'Europe/Lisbon': 'Portugal',
	'Atlantic/Madeira': 'Portugal',
	'Atlantic/Azores': 'Portugal',

	'Pacific/Palau': 'Palau',
	'America/Asuncion': 'Paraguay',
	'Asia/Qatar': 'Qatar',
	'Indian/Reunion': 'Réunion',
	'Europe/Bucharest': 'Romania',

	// Serbia
	'Europe/Belgrade': 'Serbia',

	'Europe/Kaliningrad': 'Russia',
	'Europe/Moscow': 'Russia',
	'Europe/Kirov': 'Russia',
	'Europe/Volgograd': 'Russia',
	'Europe/Astrakhan': 'Russia',
	'Europe/Saratov': 'Russia',
	'Europe/Ulyanovsk': 'Russia',
	'Europe/Samara': 'Russia',
	'Asia/Yekaterinburg': 'Russia',
	'Asia/Omsk': 'Russia',
	'Asia/Novosibirsk': 'Russia',
	'Asia/Barnaul': 'Russia',
	'Asia/Tomsk': 'Russia',
	'Asia/Novokuznetsk': 'Russia',
	'Asia/Krasnoyarsk': 'Russia',
	'Asia/Irkutsk': 'Russia',
	'Asia/Chita': 'Russia',
	'Asia/Yakutsk': 'Russia',
	'Asia/Khandyga': 'Russia',
	'Asia/Vladivostok': 'Russia',
	'Asia/Ust-Nera': 'Russia',
	'Asia/Magadan': 'Russia',
	'Asia/Sakhalin': 'Russia',
	'Asia/Srednekolymsk': 'Russia',
	'Asia/Kamchatka': 'Russia',
	'Asia/Anadyr': 'Russia',
	'Asia/Riyadh': 'Saudi Arabia',
	'Pacific/Guadalcanal': 'Solomon Islands',
	'Indian/Mahe': 'Seychelles',
	'Africa/Khartoum': 'Sudan',

	// Sweden
	'Europe/Stockholm': 'SWE',

	'Asia/Singapore': 'Singapore',
	'America/Paramaribo': 'Suriname',
	'Africa/Juba': 'South Sudan',
	'Africa/Sao_Tome': 'Sao Tome & Principe',
	'America/El_Salvador': 'El Salvador',
	'Asia/Damascus': 'Syria',
	'America/Grand_Turk': 'Turks & Caicos Is',
	'Africa/Ndjamena': 'Chad',
	'Indian/Kerguelen': 'French Southern & Antarctic Lands',
	'Asia/Bangkok': 'Thailand',
	'Asia/Dushanbe': 'Tajikistan',
	'Pacific/Fakaofo': 'Tokelau',
	'Asia/Dili': 'East Timor',
	'Asia/Ashgabat': 'Turkmenistan',

	// Tunisia
	'Africa/Tunis': 'Tunisia',

	'Pacific/Tongatapu': 'Tonga',

	// Turkey
	'Europe/Istanbul': 'Turkey',

	'Pacific/Funafuti': 'Tuvalu',
	'Asia/Taipei': 'Taiwan',

	// Ukraine
	'Europe/Kiev': 'UKR',
	'Europe/Simferopol': 'UKR',
	'Europe/Uzhgorod': 'UKR',
	'Europe/Zaporozhye': 'UKR',
	'Europe/Kyiv': 'UKR',

	'Pacific/Wake': 'US minor outlying islands',

	// United States of America
	'America/New_York': 'USA',
	'America/Detroit': 'USA',
	'America/Kentucky/Louisville': 'USA',
	'America/Kentucky/Monticello': 'USA',
	'America/Indiana/Indianapolis': 'USA',
	'America/Indiana/Vincennes': 'USA',
	'America/Indiana/Winamac': 'USA',
	'America/Indiana/Marengo': 'USA',
	'America/Indiana/Petersburg': 'USA',
	'America/Indiana/Vevay': 'USA',
	'America/Chicago': 'USA',
	'America/Indiana/Tell_City': 'USA',
	'America/Indiana/Knox': 'USA',
	'America/Menominee': 'USA',
	'America/North_Dakota/Center': 'USA',
	'America/North_Dakota/New_Salem': 'USA',
	'America/North_Dakota/Beulah': 'USA',
	'America/Denver': 'USA',
	'America/Boise': 'USA',
	'America/Phoenix': 'USA',
	'America/Los_Angeles': 'USA',
	'America/Anchorage': 'USA',
	'America/Juneau': 'USA',
	'America/Sitka': 'USA',
	'America/Metlakatla': 'USA',
	'America/Yakutat': 'USA',
	'America/Nome': 'USA',
	'America/Adak': 'USA',
	'Pacific/Honolulu': 'USA',

	'America/Montevideo': 'Uruguay',
	'Asia/Samarkand': 'Uzbekistan',
	'Asia/Tashkent': 'Uzbekistan',
	'America/Caracas': 'Venezuela',
	'Asia/Ho_Chi_Minh': 'Vietnam',
	'Pacific/Efate': 'Vanuatu',
	'Pacific/Wallis': 'Wallis & Futuna',
	'Pacific/Apia': 'Samoa (western)',
	'Africa/Johannesburg': 'South Africa',
	'America/Antigua': 'Antigua & Barbuda',
	'America/Anguilla': 'Anguilla',
	'Africa/Luanda': 'Angola',
	'Antarctica/McMurdo': 'Antarctica',
	'Antarctica/DumontDUrville': 'Antarctica',
	'Antarctica/Syowa': 'Antarctica',
	'America/Aruba': 'Aruba',
	'Europe/Mariehamn': 'Åland Islands',
	'Europe/Sarajevo': 'Bosnia & Herzegovina',
	'Africa/Ouagadougou': 'Burkina Faso',
	'Asia/Bahrain': 'Bahrain',
	'Africa/Bujumbura': 'Burundi',
	'Africa/Porto-Novo': 'Benin',
	'America/St_Barthelemy': 'St Barthelemy',
	'America/Kralendijk': 'Caribbean NL',
	'America/Nassau': 'Bahamas',
	'Africa/Gaborone': 'Botswana',

	// Canada
	'America/Blanc-Sablon': 'CAN',
	'America/Atikokan': 'CAN',
	'America/Creston': 'CAN',

	'Africa/Kinshasa': 'Congo (Dem. Rep.)',
	'Africa/Lubumbashi': 'Congo (Dem. Rep.)',
	'Africa/Bangui': 'Central African Rep.',
	'Africa/Brazzaville': 'Congo (Rep.)',
	'Africa/Douala': 'Cameroon',
	'America/Curacao': 'Curaçao',
	'Africa/Djibouti': 'Djibouti',
	'America/Dominica': 'Dominica',
	'Africa/Asmara': 'Eritrea',
	'Africa/Addis_Ababa': 'Ethiopia',
	'Africa/Libreville': 'Gabon',
	'America/Grenada': 'Grenada',
	'Europe/Guernsey': 'Guernsey',
	'Africa/Accra': 'Ghana',
	'Africa/Banjul': 'Gambia',
	'Africa/Conakry': 'Guinea',
	'America/Guadeloupe': 'Guadeloupe',
	'Africa/Malabo': 'Equatorial Guinea',

	// Croatia
	'Europe/Zagreb': 'Croatia',

	'Europe/Isle_of_Man': 'Isle of Man',
	'Europe/Jersey': 'Jersey',
	'Asia/Phnom_Penh': 'Cambodia',
	'Indian/Comoro': 'Comoros',
	'America/St_Kitts': 'St Kitts & Nevis',
	'Asia/Kuwait': 'Kuwait',
	'America/Cayman': 'Cayman Islands',
	'Asia/Vientiane': 'Laos',
	'America/St_Lucia': 'St Lucia',

	// Liechtenstein
	'Europe/Vaduz': 'Liechtenstein',

	'Africa/Maseru': 'Lesotho',
	'Europe/Podgorica': 'Montenegro',
	'America/Marigot': 'St Martin (French)',
	'Indian/Antananarivo': 'Madagascar',
	'Europe/Skopje': 'North Macedonia',
	'Africa/Bamako': 'Mali',
	'Pacific/Saipan': 'Northern Mariana Islands',
	'Africa/Nouakchott': 'Mauritania',
	'America/Montserrat': 'Montserrat',
	'Africa/Blantyre': 'Malawi',
	'Africa/Niamey': 'Niger',
	'Asia/Muscat': 'Oman',
	'Africa/Kigali': 'Rwanda',
	'Atlantic/St_Helena': 'St Helena',

	// Slovenia
	'Europe/Ljubljana': 'Slovenia',

	'Arctic/Longyearbyen': 'Svalbard & Jan Mayen',
	'Europe/Bratislava': 'Slovakia',
	'Africa/Freetown': 'Sierra Leone',
	'Europe/San_Marino': 'San Marino',
	'Africa/Dakar': 'Senegal',
	'Africa/Mogadishu': 'Somalia',
	'America/Lower_Princes': 'St Maarten (Dutch)',
	'Africa/Mbabane': 'Eswatini (Swaziland)',
	'Africa/Lome': 'Togo',
	'America/Port_of_Spain': 'Trinidad & Tobago',
	'Africa/Dar_es_Salaam': 'Tanzania',
	'Africa/Kampala': 'Uganda',
	'Pacific/Midway': 'US minor outlying islands',
	'Europe/Vatican': 'Vatican City',
	'America/St_Vincent': 'St Vincent',
	'America/Tortola': 'Virgin Islands (UK)',
	'America/St_Thomas': 'Virgin Islands (US)',
	'Asia/Aden': 'Yemen',
	'Indian/Mayotte': 'Mayotte',
	'Africa/Lusaka': 'Zambia',
	'Africa/Harare': 'Zimbabwe'
}
