import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DocumentTypeDescribedComboboxModel } from '../models/document-type-described-combobox.model';

export abstract class DocumentTypesResolver implements Resolve<Array<DocumentTypeDescribedComboboxModel>> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	): Observable<Array<DocumentTypeDescribedComboboxModel>> | Array<DocumentTypeDescribedComboboxModel>;

}
