export class UserProfileFormOutModel {

	public readonly isEmailNotificationsEnabled: boolean;
	public readonly isPreferedBrowserTimezone: boolean;
	public readonly pictureId: string;
	public readonly locale?: string;
	public readonly timezone?: string;
	public readonly activePatientAlias: string;

	constructor(
		isEmailNotificationsEnabled: boolean,
		isPreferedBrowserTimezone: boolean,
		pictureId: string | undefined,
		locale: string | undefined,
		timezone: string | undefined,
		activePatientAlias: string | undefined
	) {
		this.isEmailNotificationsEnabled = isEmailNotificationsEnabled;
		this.isPreferedBrowserTimezone = isPreferedBrowserTimezone;
		this.pictureId = pictureId;
		this.locale = locale;
		this.timezone = timezone;
		this.activePatientAlias = activePatientAlias;
	}

}
