import { DoctorEncounterFormOutModel } from '../../../doctor-encounters';
import { DocumentFormOutModel } from '../../../documents';
import { TakenMedicationFormOutModel } from '../../../taken-medications';
import { HealthIssueStatus } from '../health-issue-status.enum';

export class HealthIssueFormOutModel {

	public readonly patientId: string;
	public readonly name?: string;
	public readonly comment?: string;
	public readonly dateStart?: Date;
	public readonly dateEnd?: Date;
	public readonly status: HealthIssueStatus;
	public readonly severityId: string;
	public readonly healthComplaintIds: Array<string>;
	public readonly encounterIds: Array<string>;
	public readonly doctorEncounters: Array<DoctorEncounterFormOutModel>;
	public readonly takenMedicationIds: Array<string>;
	public readonly takenMedications: Array<TakenMedicationFormOutModel>;
	public readonly documentIds: Array<string>;
	public readonly documents: Array<DocumentFormOutModel>;

	constructor(
		patientId: string,
		name: string | undefined,
		comment: string | undefined,
		dateStart: Date | undefined,
		dateEnd: Date | undefined,
		status: HealthIssueStatus,
		severityId: string,
		healthComplaintIds: Array<string>,
		encounterIds: Array<string>,
		doctorEncounters: Array<DoctorEncounterFormOutModel>,
		takenMedicationIds: Array<string>,
		takenMedications: Array<TakenMedicationFormOutModel>,
		documentIds: Array<string>,
		documents: Array<DocumentFormOutModel>
	) {
		// TODO: Guard check on defined
		this.patientId = patientId;
		this.name = name;
		this.comment = comment;
		this.dateStart = dateStart;
		this.dateEnd = dateEnd;
		this.status = status;
		this.severityId = severityId;
		this.healthComplaintIds = healthComplaintIds;
		this.encounterIds = encounterIds;
		this.doctorEncounters = doctorEncounters;
		this.takenMedicationIds = takenMedicationIds;
		this.takenMedications = takenMedications;
		this.documentIds = documentIds;
		this.documents = documents;
	}

}
