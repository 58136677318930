export class CompleteResetPasswordFormInModel {

	public readonly newPassword?: string;
	public readonly confirmNewPassword?: string;

	private constructor(
		newPassword: string | undefined,
		confirmNewPassword: string | undefined
	) {
		this.newPassword = newPassword;
		this.confirmNewPassword = confirmNewPassword;
	}

	public static createEmpty = (): CompleteResetPasswordFormInModel => {
		return new CompleteResetPasswordFormInModel(undefined, undefined);
	}

}
