import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MedicationAdmRouteFilterModel, MedicationAdmRouteQueryHandlerService, MedicationAdmRoutesResolver } from '@nmn-domain/medication-adm-routes';
import { DescribedComboboxModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MedicationAdmRoutesViaQueryHandlerResolver extends MedicationAdmRoutesResolver {

	constructor(
		private readonly queryHandlerService: MedicationAdmRouteQueryHandlerService
	) {
		super();
	}

	public resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<Array<DescribedComboboxModel>> | Array<DescribedComboboxModel> {
		return this.queryHandlerService
			.getAsDescribedComboboxes(MedicationAdmRouteFilterModel.createEmpty())
			.pipe(map(result => result.successOrDefault(() => [])));
	}

}
