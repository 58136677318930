import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { MedicationComboboxDto } from '../models/medication-combobox.dto';
import { MedicationFilterDto } from '../models/medication-filter.dto';

export abstract class MedicationClient {

	public abstract getBulkAsComboboxes(filter: MedicationFilterDto): Observable<Result<Array<MedicationComboboxDto>, Failure>>;

}
