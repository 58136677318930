import { Provider } from '@angular/core';
import { UserSubscriptionCommandHandlerService, UserSubscriptionQueryHandlerService } from '@nmn-domain/accounts';
import { UserSubscriptionCommandHandlerViaClientService } from './services/user-subscription.command-handler-via-client.service';
import { UserSubscriptionQueryHandlerViaClientService } from './services/user-subscription.query-handler-via-client.service';

export const accountsUserSubscriptionProviders: Array<Provider> = [
	{
		provide: UserSubscriptionCommandHandlerService,
		useClass: UserSubscriptionCommandHandlerViaClientService
	},
	{
		provide: UserSubscriptionQueryHandlerService,
		useClass: UserSubscriptionQueryHandlerViaClientService
	}
];
