import { ValueRestrictionOptional } from '../../../../../modules/core/configurations/restrictions/value-restriction-optional';

export interface TakenMedicationFormConfiguration {

	restrictions: {
		medicationDosage: {
			value: ValueRestrictionOptional<number>;
		};
		medicationRate: {
			value: ValueRestrictionOptional<number>;
		};
		comment: {
			controlRowsCount: number | undefined
		};
		takenPeriod: {
			from: ValueRestrictionOptional<Date>;
			to: ValueRestrictionOptional<Date>;
		};
		healthIssue: {
			isEnabled: boolean;
		};
		doctorEncounters: {
			isEnabled: boolean;
		};
		documents: {
			isEnabled: boolean;
			isCreatingEnabled: boolean;
		};
	};

}

export const defaultTakenMedicationFormConfiguration: TakenMedicationFormConfiguration = {
	restrictions: {
		medicationDosage: {
			value: ValueRestrictionOptional.initNumber(0, Number.MAX_SAFE_INTEGER)
		},
		medicationRate: {
			value: ValueRestrictionOptional.initNumber(0, Number.MAX_SAFE_INTEGER)
		},
		comment: { controlRowsCount: 5 },
		takenPeriod: {
			from: ValueRestrictionOptional
				.initDate(
					new Date('1900-01-01T00:00:00Z'),
					new Date('2100-01-01T00:00:00Z')
				),
			to: ValueRestrictionOptional
				.initDate(
					new Date('1900-01-01T00:00:00Z'),
					new Date('2100-01-01T00:00:00Z')
				)
		},
		healthIssue: {
			isEnabled: true
		},
		doctorEncounters: {
			isEnabled: true
		},
		documents: {
			isEnabled: true,
			isCreatingEnabled: true
		}
	}
};

export const forRelatedToHealthIssueTakenMedicationFormConfiguration: TakenMedicationFormConfiguration = {
	restrictions: {
		...defaultTakenMedicationFormConfiguration.restrictions,
		healthIssue: {
			isEnabled: false
		},
		documents: {
			isEnabled: true,
			isCreatingEnabled: false
		}
	}
};

export const forRelatedToDoctorEncounterTakenMedicationFormConfiguration: TakenMedicationFormConfiguration = {
	restrictions: {
		...defaultTakenMedicationFormConfiguration.restrictions,
		doctorEncounters: {
			isEnabled: false
		},
		documents: {
			isEnabled: true,
			isCreatingEnabled: false
		}
	}
};
