import { Result } from '@nmn-core/shared';
import { EmptyCommandResult, FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { CompleteResetPasswordCommand } from '../models/commands/complete-reset-password.command';
import { InitiateResetPasswordCommand } from '../models/commands/initiate-reset-password.command';

export abstract class ResetPasswordCommandHandlerService {

	public abstract completeResetPassword(
		command: CompleteResetPasswordCommand
	): Observable<Result<EmptyCommandResult<CompleteResetPasswordCommand>, FailureModel>>;

	public abstract initiateResetPassword(
		command: InitiateResetPasswordCommand
	): Observable<Result<EmptyCommandResult<InitiateResetPasswordCommand>, FailureModel>>;

}
