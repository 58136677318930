import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { CommandResult, EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { FailureModel } from '../../shared/failures/failure.model';
import { PatientCreateCommand } from '../models/commands/patient.create.command';
import { PatientDeleteCommand } from '../models/commands/patient.delete.command';
import { PatientRevokeDeleteCommand } from '../models/commands/patient.revoke-delete.command';
import { PatientUpdateCommand } from '../models/commands/patient.update.command';

export abstract class PatientCommandHandlerService {

	public abstract create(
		command: PatientCreateCommand
	): Observable<Result<CommandResult<PatientCreateCommand, string>, FailureModel>>;

	public abstract update(
		command: PatientUpdateCommand
	): Observable<Result<EmptyCommandResult<PatientUpdateCommand>, FailureModel>>;

	public abstract delete(
		command: PatientDeleteCommand
	): Observable<Result<EmptyCommandResult<PatientDeleteCommand>, FailureModel>>;

	public abstract revokeDelete(
		command: PatientRevokeDeleteCommand
	): Observable<Result<EmptyCommandResult<PatientRevokeDeleteCommand>, FailureModel>>;

}
