import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FailureModel } from '../../shared/failures/failure.model';
import { VaccinationCardGetQuery } from '../models/queries/vaccination-card.get.query';
import { VaccinationGetQuery } from '../models/queries/vaccination.get.query';
import { VaccinationCardModel } from '../models/vaccination-card.model';
import { VaccinationModel } from '../models/vaccination.model';

export abstract class VaccinationQueryHandlerService {

	public abstract handleVaccinationCardGetQuery(
		query: VaccinationCardGetQuery
	): Observable<Result<VaccinationCardModel, FailureModel>>;

	public abstract handleGetQuery(
		query: VaccinationGetQuery
	): Observable<Result<VaccinationModel, FailureModel>>;

}
