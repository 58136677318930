import { Injector } from '@angular/core';
import { GeneticTestClient } from '@nmn-communication/genetic-tests';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { GeneticTestFakeClientBuilder } from '../builders/genetic-test.fake-client.builder';
import { GeneticTestFakeClientConfigurationBuilder } from '../builders/genetic-test.fake-client.configuration.builder';
import { GeneticTestHttpClientBuilder } from '../builders/genetic-test.http-client.builder';
import { GeneticTestHttpClientConfigurationBuilder } from '../builders/genetic-test.http-client.configuration.builder';
import { GeneticTestFakeClient } from '../services/genetic-test.fake-client';
import { GeneticTestHttpClient } from '../services/genetic-test.http-client';

export const geneticTestClientFactory = (injector: Injector): GeneticTestClient => {
	const useFake = environment?.api?.resources?.geneticTests?.useFake ||
		!isValueDefined(environment?.api?.resources?.geneticTests?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ? geneticTestFakeClientFactory(injector) : geneticTestHttpClientFactory(injector);
};

export const geneticTestFakeClientFactory = (injector: Injector): GeneticTestFakeClient => {
	const configuration = new GeneticTestFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.geneticTests.serverDelayMs)
		.build();

	return new GeneticTestFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const geneticTestHttpClientFactory = (injector: Injector): GeneticTestHttpClient => {
	const configuration = new GeneticTestHttpClientConfigurationBuilder()
		.withBaseUrl(environment?.api?.resources?.geneticTests?.baseUrl ?? environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.geneticTests.resourceRelativePath)
		.withResourceTypeVersion(environment?.api?.resources?.geneticTests?.resourceTypeVersion ?? environment?.api?.defaultResourceTypeVersion)
		.build();

	return new GeneticTestHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
