import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { LoginCommandHandlerService } from '@nmn-domain/accounts';
import { FailureModel, FailureSeverity } from '@nmn-domain/shared';
import { FailureLocalizationModel } from '@nmn-domain/shared/failures/failure-localization-parameters.model';
import { FailureWrapperModel } from '@nmn-domain/shared/failures/failure-wrapper.model';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, first, switchMap } from 'rxjs/operators';
import { CookieStorageService } from '../../../core/application-storages';
import { FailureHandlingService, MonitoringService, StorageService } from '../../../services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	private readonly localizationKeyPattern = 'modules.shared.interceptors.authInterceptor';

	private readonly isRenewInProgress$ = new BehaviorSubject<boolean>(false);
	private loginCommandHandlerService: LoginCommandHandlerService;

	private readonly authExclusions = [
		'assets',
		'api/notifications/subscriptions',
		'api/configuration/frontend',
		'api/tags',
		'api/compendiums',
		'api/files',
		'api/userAccounts/internal/signIn',
		'api/userAccounts/internal/signUp',
		'api/userAccounts/internal/regular/attach',
		'api/userAccounts/external/google/signInOrSignUp',
		'api/userAccounts/external/google/attach',
		'api/userAccounts/external/google/signIn',
		'api/userAccounts/external/google/signUp',
		'api/userAccounts/refreshToken',
		'api/userAccounts/changePassword',
		'api/userAccounts/createResetPasswordToken',
		'api/userAccounts/resetPassword',
		'api/userAccounts/emailConfirmation/confirm',
		'api/userAccounts/emailConfirmation/send'
	];

	constructor(
		private readonly storageService: StorageService,
		private readonly cookieStorage: CookieStorageService,
		private readonly injector: Injector,
		private readonly monitoringService: MonitoringService,
		private readonly failureHandlingService: FailureHandlingService
	) {
	}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next
			.handle(this.addAuthHeader(request))
			.pipe(
				catchError(
					(error: HttpErrorResponse) => {
						if (error instanceof HttpErrorResponse) {
							if (error.status === 401 || error.status === 403) {
								if (this.isRenewInProgress$.value) {
									return this.isRenewInProgress$
										.pipe(
											filter(item => !item),
											first(),
											switchMap(
												() => next
													.handle(this.addAuthHeader(request))
													.pipe(catchError(this.handleFailedRequestAfterRefreshToken))
											)
										);
								}

								this.isRenewInProgress$.next(true);

								if (!isValueDefined(this.loginCommandHandlerService)) {
									this.loginCommandHandlerService = this.injector.get(LoginCommandHandlerService);
								}

								return this.loginCommandHandlerService
									.refreshToken()
									.pipe(
										first(),
										switchMap(() => {
											this.isRenewInProgress$.next(false);

											return next
												.handle(this.addAuthHeader(request))
												.pipe(catchError(this.handleFailedRequestAfterRefreshToken));
										})
									);
							}
						}

						return throwError(() => error);
					}
				)
			);
	}

	private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
		let result = request;

		if (this.storageService.isLoggedIn && !this.isAuthExclusion(request)) {
			const userId = this.storageService.userId;

			this.cookieStorage
				.getUserTokenType(userId)
				.consume(
					tokenTypeValue => {
						this.cookieStorage
							.getUserAccessToken(userId)
							.consume(
								accessTokenValue => {
									result = request.clone({
										setHeaders: { Authorization: `${tokenTypeValue} ${accessTokenValue}` }
									});
								},
								failure => {
									this.monitoringService.logException(failure as any, 'Unexpected error: cannot get access token for authorized user.');
								}
							);
					},
					failure => {
						this.monitoringService.logException(failure as any, 'Unexpected error: cannot get token type for authorized user.');
					}
				);
		}

		return result;
	}

	private isAuthExclusion(request: HttpRequest<any>): boolean {
		return this.authExclusions.some(item => request.url.indexOf(item) > -1);
	}

	private readonly handleFailedRequestAfterRefreshToken = (error: HttpErrorResponse) => {
		if (error instanceof HttpErrorResponse) {
			if ((error.status === 401 || error.status === 403) && error.url.includes("refreshToken")) {
				this.failureHandlingService.handleFailure(
					FailureModel.createLocaliableFailure(FailureWrapperModel.createFromValue(error), FailureLocalizationModel.createFromValue(`${this.localizationKeyPattern}.failureHints.sessionExpired`), FailureSeverity.Critical)
				);
				this.storageService.logout();
			}
		}

		return throwError(() => error);
	};

}
