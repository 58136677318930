export class UserSubscriptionGetCommand {

	public readonly userId: string;

	private constructor(
		userId: string
	) {

		// TODO: Guard check on defined
		this.userId = userId;
	}

	public static create(
		userId?: string
	): UserSubscriptionGetCommand {
		return new UserSubscriptionGetCommand(
			userId
		);
	}

}
