import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneticTestModel } from '../models/genetic-test.model';

export abstract class GeneticTestResolver implements Resolve<GeneticTestModel> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		routerState: RouterStateSnapshot
	): Observable<GeneticTestModel> | Promise<GeneticTestModel> | GeneticTestModel;

}
