import { isValueDefined } from '@nmn-core/utils';

export enum TreatmentCheckerReportCautionSeverity {

	Undefined = 'undefined',

	SuperInfo = 'superInfo',

	Error = 'error',
	Warning = 'warning',
	Info = 'info'

}

export const mapTreatmentCheckerReportCautionSeverityFromString = (
	stringValue: string
): TreatmentCheckerReportCautionSeverity => {
	const enumValue = Object
		.keys(TreatmentCheckerReportCautionSeverity)
		.find(x => TreatmentCheckerReportCautionSeverity[x] === stringValue);

	return isValueDefined(enumValue) ?
		stringValue as TreatmentCheckerReportCautionSeverity :
		TreatmentCheckerReportCautionSeverity.Undefined;
};
