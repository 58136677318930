import { Injector } from '@angular/core';
import { RegistrationClient } from '@nmn-communication/accounts';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { RegistrationFakeClientBuilder } from '../builders/registration.fake-client.builder';
import { RegistrationFakeClientConfigurationBuilder } from '../builders/registration.fake-client.configuration.builder';
import { RegistrationHttpClientBuilder } from '../builders/registration.http-client.builder';
import { RegistrationHttpClientConfigurationBuilder } from '../builders/registration.http-client.configuration.builder';
import { RegistrationFakeClient } from '../services/registration.fake-client';
import { RegistrationHttpClient } from '../services/registration.http-client';

export const registrationClientFactory = (
	injector: Injector
): RegistrationClient => {
	const useFake = environment?.api?.resources?.accounts?.registration?.useFake ||
		!isValueDefined(environment?.api?.resources?.accounts?.registration?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		RegistrationFakeClientFactory(injector) :
		RegistrationHttpClientFactory(injector);
};

export const RegistrationFakeClientFactory = (
	injector: Injector
): RegistrationFakeClient => {
	const configuration = new RegistrationFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.accounts.registration.serverDelayMs)
		.build();

	return new RegistrationFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const RegistrationHttpClientFactory = (
	injector: Injector
): RegistrationHttpClient => {
	const configuration = new RegistrationHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.accounts?.registration?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.accounts.registration.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.accounts?.registration?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new RegistrationHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
