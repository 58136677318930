import { Injector, Provider } from '@angular/core';
import { PatientClient } from '@nmn-communication/patients';
import { patientClientFactory } from './factories/patient.client.factory';

export const patientProviders: Array<Provider> = [
	{
		provide: PatientClient,
		useFactory: patientClientFactory,
		deps: [Injector]
	}
];
