import { Injector } from '@angular/core';
import { MedicationAdmRouteClient } from '@nmn-communication/medication-adm-routes';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { MedicationAdmRouteFakeClientBuilder } from '../builders/medication-adm-route.fake-client.builder';
import { MedicationAdmRouteFakeClientConfigurationBuilder } from '../builders/medication-adm-route.fake-client.configuration.builder';
import { MedicationAdmRouteHttpClientBuilder } from '../builders/medication-adm-route.http-client.builder';
import { MedicationAdmRouteHttpClientConfigurationBuilder } from '../builders/medication-adm-route.http-client.configuration.builder';
import { MedicationAdmRouteFakeClient } from '../services/medication-adm-route.fake-client';
import { MedicationAdmRouteHttpClient } from '../services/medication-adm-route.http-client';

export const MedicationAdmRouteClientFactory = (injector: Injector): MedicationAdmRouteClient => {
	const useFake = environment?.api?.resources?.medicationAdmRoutes?.useFake ||
		!isValueDefined(environment?.api?.resources?.medicationAdmRoutes?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		MedicationAdmRouteFakeClientFactory(injector) :
		MedicationAdmRouteHttpClientFactory(injector);
};

export const MedicationAdmRouteFakeClientFactory = (
	injector: Injector
): MedicationAdmRouteFakeClient => {
	const configuration = new MedicationAdmRouteFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.medicationAdmRoutes.serverDelayMs)
		.build();

	return new MedicationAdmRouteFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const MedicationAdmRouteHttpClientFactory = (
	injector: Injector
): MedicationAdmRouteHttpClient => {
	const configuration = new MedicationAdmRouteHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.medicationAdmRoutes?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.medicationAdmRoutes.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.medicationAdmRoutes?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new MedicationAdmRouteHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
