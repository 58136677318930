export class TreatmentCheckerReportDeleteCommand {

	public readonly id: string;

	constructor(
		id: string
	) {
		// TODO: Guard
		this.id = id;
	}

}
