import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PatientTreatmentCheckerFormConfiguration, PatientTreatmentCheckerFormConfigurationResolver } from '@nmn-domain/treatment-checker';
import { Observable } from 'rxjs';

@Injectable()
export class PatientTreatmentCheckerFormConfigurationDefaultResolver extends PatientTreatmentCheckerFormConfigurationResolver {

	public resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<PatientTreatmentCheckerFormConfiguration> | PatientTreatmentCheckerFormConfiguration {
		return {
			restrictions: {}
		};
	}

}
