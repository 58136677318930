import { Injector, Provider } from '@angular/core';
import { CurrentUserConfigurationClient } from '@nmn-communication/accounts';
import { userConfigurationClientFactory } from './factories/user-configuration.client.factory';

export const accountsUserConfigurationProviders: Array<Provider> = [
	{
		provide: CurrentUserConfigurationClient,
		useFactory: userConfigurationClientFactory,
		deps: [Injector]
	}
];
