import { GeneVariantComboboxDto, GeneVariantFilterDto } from '@nmn-communication/gene-variants';
import { GeneVariantComboboxModel, GeneVariantFilterModel } from '@nmn-domain/gene-variants';
import { mapGeneComboboxDtoToModel } from '../../genes/factories/gene.factory';
import { mapPharmacogenomicTestResultComboboxDtoToModel } from '../../pharmacogenomic-test-results/factories/pharmacogenomic-test-result.factory';

export const mapFilterModelToDto = (model: GeneVariantFilterModel): GeneVariantFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern,
		geneId: model.geneId,
		allele1: model.allele1
	}
);

export const mapFilterDtoToModel = (dto: GeneVariantFilterDto): GeneVariantFilterModel => (
	new GeneVariantFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern,
		dto.geneId,
		dto.allele1
	)
);

export const mapGeneVariantComboboxDtoToModel = (dto: GeneVariantComboboxDto): GeneVariantComboboxModel => (
	new GeneVariantComboboxModel(
		dto.id,
		dto.displayText,
		dto.description,
		mapGeneComboboxDtoToModel(dto.gene),
		dto.allele1,
		dto.allele2,
		mapPharmacogenomicTestResultComboboxDtoToModel(dto.pharmacogenomicTestResult)
	)
);
