import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { Subject } from 'rxjs';
import { ApplicationTitleModel } from '../models/application-title.model';

@Injectable()
export class ApplicationTitleService {

	private readonly currentApplicationTitleSubject$ = new Subject<string>();
	public readonly currentApplicationTitle$ = this.currentApplicationTitleSubject$.asObservable();

	private currentApplicationTitleValue: ApplicationTitleModel = ApplicationTitleModel.default;

	public get currentApplicationTitle(): string {
		return this.currentApplicationTitleValue.translate(this.translocoService);
	}

	public get currentApplicationTitleWithHiddenParameters(): string {
		return this.currentApplicationTitleValue.translateWithHiddenParameters(this.translocoService);
	}

	constructor(
		private readonly activeRoute: ActivatedRoute,
		private readonly title: Title,
		private readonly translocoService: TranslocoService
	) {
		this.currentApplicationTitle$.subscribe();
		this.updateApplicationTitle();
	}

	public updateApplicationTitle(): void {
		const newApplicationTitle = this.getTitleFromRouteSnapshot(this.activeRoute.snapshot);
		this.currentApplicationTitleValue = isValueDefined(newApplicationTitle) ? newApplicationTitle : ApplicationTitleModel.default;
		const currentApplicationTitle = this.currentApplicationTitle;
		this.title.setTitle(currentApplicationTitle);
		this.currentApplicationTitleSubject$.next(currentApplicationTitle);
	}

	public updateApplicationTitleByEvent(event: any): void {
		if (event instanceof NavigationEnd || isStringDefinedAndNotEmpty(event)) {
			this.updateApplicationTitle();
		}
	}

	private getTitleFromRouteSnapshot(snapshot: ActivatedRouteSnapshot): ApplicationTitleModel | undefined {
		if (!isValueDefined(snapshot)) {
			return undefined;
		}

		let pageTitle = this.getTitleFromRouteSnapshot(snapshot?.firstChild);

		if (!isValueDefined(pageTitle) && snapshot.data?.hasOwnProperty('title')) {
			pageTitle = ApplicationTitleModel.createByContext(snapshot.data);
		}

		return pageTitle;
	};

}

