import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
	LoginDto, UserProfileAttachFacebookAuthUpdateParameter, UserProfileAttachGoogleAuthUpdateParameter, UserProfileAttachRegularAuthUpdateParameter, UserProfileClient,
	UserProfileDeleteParameter, UserProfileFindParameter, UserProfilePasswordUpdateParameter, UserProfileRevokeDeleteParameter, UserProfileUpdateParameter
} from '@nmn-communication/accounts';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { createFailedResultFromErrorResponse, createResultFromResponse } from '../../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { UserProfileHttpClientConfiguration } from '../configurations/user-profile.http-client.configuration';

export class UserProfileHttpClient extends UserProfileClient {

	constructor(
		private readonly configuration: UserProfileHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public get(parameter: UserProfileFindParameter): Observable<Result<LoginDto, Failure>> {
		const url = `${this.configuration.baseUrl}/api/users/${parameter.id}`;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<LoginDto>(url, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public getCurrent(): Observable<Result<LoginDto, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<LoginDto>(url, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public updateCurrent(parameter: UserProfileUpdateParameter): Observable<Result<LoginDto, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`;
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put<LoginDto>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public changePasswordCurrent(parameter: UserProfilePasswordUpdateParameter): Observable<Result<LoginDto, Failure>> {
		const url = `${this.configuration.baseUrl}/api/userAccounts/changePassword`;
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put<LoginDto>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public deleteCurrent(parameter: UserProfileDeleteParameter): Observable<Result<LoginDto, Failure>> {
		const url = `${this.configuration.baseUrl}/api/userAccounts/deactivate/request`;
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put<LoginDto>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public revokeDeleteCurrent(parameter: UserProfileRevokeDeleteParameter): Observable<Result<LoginDto, Failure>> {
		const url = `${this.configuration.baseUrl}/api/userAccounts/deactivate/revoke`;
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put<LoginDto>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public attachRegularAuthFlow(parameter: UserProfileAttachRegularAuthUpdateParameter): Observable<Result<LoginDto, Failure>> {
		const url = `${this.configuration.baseUrl}/api/userAccounts/internal/regular/attach`;
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post<LoginDto>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public attachGoogleAuthFlow(parameter: UserProfileAttachGoogleAuthUpdateParameter): Observable<Result<LoginDto, Failure>> {
		const url = `${this.configuration.baseUrl}/api/userAccounts/external/google/attach`;
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post<LoginDto>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public attachFacebookAuthFlow(parameter: UserProfileAttachFacebookAuthUpdateParameter): Observable<Result<LoginDto, Failure>> {
		const url = `${this.configuration.baseUrl}/api/userAccounts/external/facebook/attach`;
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post<LoginDto>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

}
