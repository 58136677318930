import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { DiseaseComboboxModel } from '@nmn-domain/diseases';
import { VaccineDescribedComboboxModel } from '@nmn-domain/vaccines';

export class VaccinationModel {

	public readonly id: string;
	public readonly patientId: string;
	public readonly vaccinationDate: Date;
	public readonly vaccine: VaccineDescribedComboboxModel;
	public readonly vaccinatedFromDiseases: Array<DiseaseComboboxModel>;
	public readonly comment: string | undefined;
	public readonly numberInSeriesOfDoses: number;
	public readonly memberStateOfVaccination: string | undefined;
	public readonly certificateIssuer: string | undefined;
	public readonly certificateUid: string | undefined;
	public readonly expirationDate: Date | undefined;

	public get isExpirationDateDefined(): boolean {
		return isValueDefined(this.expirationDate);
	}

	public get vaccinationYear(): number {
		return this.vaccinationDate.getFullYear();
	}

	public get forDiseasesDisplayText(): string {
		return isArrayDefinedAndNotEmpty(this.vaccinatedFromDiseases) ?
			this.vaccinatedFromDiseases
				.map(diseaseCombobox => diseaseCombobox.displayText)
				.join(', ') :
			undefined;
	}

	public get forDiseasesCount(): number | undefined {
		return isArrayDefinedAndNotEmpty(this.vaccinatedFromDiseases) ? this.vaccinatedFromDiseases.length : 0;
	}

	public get mainDiseaseDisplayText(): string {
		return isArrayDefinedAndNotEmpty(this.vaccinatedFromDiseases) ?
			this.vaccinatedFromDiseases[0].displayText :
			undefined;
	}

	public get subDiseaseDisplayText(): string {
		return isArrayDefinedAndNotEmpty(this.vaccinatedFromDiseases) && this.vaccinatedFromDiseases.length >= 2 ?
			this.vaccinatedFromDiseases[1].displayText :
			undefined;
	}

	public get isCommentDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.comment);
	}

	public get isMemberStateOfVaccinationDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.memberStateOfVaccination);
	}

	public get isCertificateDefined(): boolean {
		return this.isCertificateIssuerDefined || this.isCertificateUidDefined;
	}

	public get isCertificateIssuerDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.certificateIssuer);
	}

	public get isCertificateUidDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.certificateUid);
	}

	constructor(
		id: string,
		patientId: string,
		vaccinationDate: Date,
		vaccine: VaccineDescribedComboboxModel,
		vaccinatedFromDiseases: Array<DiseaseComboboxModel>,
		comment: string | undefined,
		numberInSeriesOfDoses: number,
		memberStateOfVaccination: string | undefined,
		certificateIssuer: string | undefined,
		certificateUid: string | undefined,
		expirationDate: Date | undefined
	) {

		// TODO: Guard check on defined

		this.id = id;
		this.patientId = patientId;
		this.vaccinationDate = vaccinationDate;
		this.vaccine = vaccine;
		this.vaccinatedFromDiseases = vaccinatedFromDiseases;
		this.comment = comment;
		this.numberInSeriesOfDoses = numberInSeriesOfDoses;
		this.memberStateOfVaccination = memberStateOfVaccination;
		this.certificateIssuer = certificateIssuer;
		this.certificateUid = certificateUid;
		this.expirationDate = expirationDate;
	}

}
