import { TimezoneComboboxDto, TimezoneFilterDto } from '@nmn-communication/timezones';
import { TimezoneComboboxModel, TimezoneFilterModel } from '@nmn-domain/timezones';

export const mapFilterModelToDto = (model: TimezoneFilterModel): TimezoneFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern
	}
);

export const mapFilterDtoToModel = (dto: TimezoneFilterDto): TimezoneFilterModel => (
	new TimezoneFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern
	)
);

export const mapComboboxDtoToComboboxModel = (dto: TimezoneComboboxDto): TimezoneComboboxModel => (
	new TimezoneComboboxModel(
		dto.id,
		dto.displayText,
		dto.standardName,
		dto.daylightName,
		dto.supportsDST,
		dto.utcStandardOffsetMinutes,
		dto.utcDaylightOffsetMinutes
	)
);
