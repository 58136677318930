export class RegisterAccountFormInModel {

	public readonly email?: string;
	public readonly password?: string;
	public readonly isEmailNotificationsEnabled?: boolean;
	public readonly picture?: string;
	public readonly locale?: string;
	public readonly timezone?: string;

	private constructor(
		email: string | undefined,
		password: string | undefined,
		isEmailNotificationsEnabled: boolean | undefined,
		picture: string | undefined,
		locale: string | undefined,
		timezone: string | undefined
	) {
		this.email = email;
		this.password = password;
		this.isEmailNotificationsEnabled = isEmailNotificationsEnabled;
		this.picture = picture;
		this.locale = locale;
		this.timezone = timezone;
	}

	public static createEmpty = (): RegisterAccountFormInModel => {
		return new RegisterAccountFormInModel(
			undefined,
			undefined,
			true,
			undefined,
			undefined,
			undefined);
	}

}
