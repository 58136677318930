import { Injectable } from '@angular/core';
import { isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { FailureModel } from '@nmn-domain/shared';
import { FailureLocalizationModel } from '@nmn-domain/shared/failures/failure-localization-parameters.model';
import { FailureWrapperModel } from '@nmn-domain/shared/failures/failure-wrapper.model';
import { CookieService } from 'ngx-cookie-service';
import { EmptyResult, Result } from '../../shared';
import { RemoveCookieBehaviorSettingsModel, SetCookieBehaviorSettingsModel } from '../models/cookie-behavior-settings.model';

@Injectable()
export class CookieStorageRepositoryService {

	private readonly TOKEN_TYPE = `${window.location.host}_[token-type]`;
	private readonly ACCESS_TOKEN = `${window.location.host}_[access-token]`;
	private readonly REFRESH_TOKEN = `${window.location.host}_[refresh-token]`;

	constructor(
		private readonly cookieService: CookieService
	) { }

	public setTokens(userId: string, tokenType: string, accessToken: string, refreshToken: string): void {
		const behaviorSettings = SetCookieBehaviorSettingsModel
			.createWithBuilder()
			.withLifetimeExpiresAfterDays(30)
			.build();

		this.set(userId + this.TOKEN_TYPE, tokenType, behaviorSettings);
		this.set(userId + this.ACCESS_TOKEN, accessToken, behaviorSettings);
		this.set(userId + this.REFRESH_TOKEN, refreshToken, behaviorSettings);
	}

	public checkAccessToken(userId: string): boolean {
		return this
			.check(userId + this.ACCESS_TOKEN)
			.mapOnSuccess(success => success)
			.successOrDefault(() => false);
	}

	public checkRefreshToken(userId: string): boolean {
		return this
			.check(userId + this.REFRESH_TOKEN)
			.mapOnSuccess(success => success)
			.successOrDefault(() => false);
	}

	public getTokenType(userId: string): Result<string, FailureModel> {
		return this.get(userId + this.TOKEN_TYPE);
	}

	public getAccessToken(userId: string): Result<string, FailureModel> {
		return this.get(userId + this.ACCESS_TOKEN);
	}

	public getRefreshToken(userId: string): Result<string, FailureModel> {
		return this.get(userId + this.REFRESH_TOKEN);
	}

	public removeTokens(userId: string): void {
		const behaviorSettings = RemoveCookieBehaviorSettingsModel
			.createWithBuilder()
			.build();
		this.remove(userId + this.TOKEN_TYPE, behaviorSettings);
		this.remove(userId + this.ACCESS_TOKEN, behaviorSettings);
		this.remove(userId + this.REFRESH_TOKEN, behaviorSettings);
	}


	public check(key: string): Result<boolean, FailureModel> {
		try {
			if (!isStringDefinedAndNotEmpty(key)) {
				return Result.failure(FailureModel.createForArgumentException('key', 'isStringDefinedAndNotEmpty'));
			}

			return Result.success(this.cookieService.check(key));
		}
		catch (error) {
			return Result.failure(FailureModel.createForGeneralException(FailureWrapperModel.createFromValue(error), FailureLocalizationModel.createEmpty()));
		}
	}

	public get<TPayload>(key: string): Result<TPayload, FailureModel> {
		try {
			if (!isStringDefinedAndNotEmpty(key)) {
				return Result.failure(FailureModel.createForArgumentException('key', 'isStringDefinedAndNotEmpty'));
			}

			const serializedPayload = this.cookieService.get(key);

			if (!isStringDefinedAndNotEmpty(serializedPayload)) {
				return Result.failure(FailureModel.createForNotFoundException('serializedPayload'));
			}

			const payload: TPayload = this.deSerializePayload(serializedPayload);

			if (!isValueDefined(payload)) {
				return Result.failure(FailureModel.createForArgumentException('payload', 'isValueDefined'));
			}

			return Result.success(payload);
		}
		catch (error) {
			return Result.failure(FailureModel.createForGeneralException(FailureWrapperModel.createFromValue(error), FailureLocalizationModel.createEmpty()));
		}
	}

	public remove(key: string, behaviorSettings: RemoveCookieBehaviorSettingsModel): EmptyResult<FailureModel> {
		try {
			if (!isStringDefinedAndNotEmpty(key)) {
				return EmptyResult.failure(FailureModel.createForArgumentException('key', 'isStringDefinedAndNotEmpty'));
			}
			if (!isValueDefined(behaviorSettings)) {
				return EmptyResult.failure(FailureModel.createForArgumentException('behaviorSettings', 'isValueDefined'));
			}

			this.cookieService.delete(
				key,
				behaviorSettings.path,
				behaviorSettings.domain,
				behaviorSettings.secure,
				behaviorSettings.sameSite
			);

			return EmptyResult.success();
		}
		catch (error) {
			return EmptyResult.failure(FailureModel.createForGeneralException(FailureWrapperModel.createFromValue(error), FailureLocalizationModel.createEmpty()));
		}
	}

	public removeAll(behaviorSettings: RemoveCookieBehaviorSettingsModel): EmptyResult<FailureModel> {
		try {
			if (!isValueDefined(behaviorSettings)) {
				return EmptyResult.failure(FailureModel.createForArgumentException('behaviorSettings', 'isValueDefined'));
			}

			this.cookieService.deleteAll(
				behaviorSettings.path,
				behaviorSettings.domain,
				behaviorSettings.secure,
				behaviorSettings.sameSite
			);

			return EmptyResult.success();
		}
		catch (error) {
			return EmptyResult.failure(FailureModel.createForGeneralException(FailureWrapperModel.createFromValue(error), FailureLocalizationModel.createEmpty()));
		}
	}
	public set<TPayload>(key: string, payload: TPayload, behaviorSettings: SetCookieBehaviorSettingsModel): EmptyResult<FailureModel> {
		try {
			if (!isStringDefinedAndNotEmpty(key)) {
				return EmptyResult.failure(FailureModel.createForArgumentException('key', 'isStringDefinedAndNotEmpty'));
			}
			if (!isValueDefined(payload)) {
				return EmptyResult.failure(FailureModel.createForArgumentException('payload', 'isValueDefined'));
			}
			if (!isValueDefined(behaviorSettings)) {
				return EmptyResult.failure(FailureModel.createForArgumentException('behaviorSettings', 'isValueDefined'));
			}

			this.cookieService.set(
				key,
				this.serializePayload(payload),
				behaviorSettings.expires,
				behaviorSettings.path,
				behaviorSettings.domain,
				behaviorSettings.secure,
				behaviorSettings.sameSite
			);

			return EmptyResult.success();
		}
		catch (error) {
			return EmptyResult.failure(FailureModel.createForGeneralException(FailureWrapperModel.createFromValue(error), FailureLocalizationModel.createEmpty()));
		}
	}

	private serializePayload<TPayload>(payload: TPayload): string {
		return JSON.stringify(payload);
	}

	private deSerializePayload<TPayload>(serializedPayload: string): TPayload {
		return JSON.parse(serializedPayload);
	}

}
