import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleIconComponent } from './components/google-icon.component';

@NgModule({
	exports: [
		GoogleIconComponent
	],
	declarations: [
		GoogleIconComponent
	],
	imports: [
		CommonModule
	]
})
export class GoogleIconModule { }
