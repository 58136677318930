import { Result } from '@nmn-core/shared';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { DiseaseComboboxModel } from '../models/disease-combobox.model';
import { DiseaseFilterModel } from '../models/disease-filter.model';

export abstract class DiseaseQueryHandlerService {

	public abstract getAsDescribedComboboxes(query: DiseaseFilterModel): Observable<Result<Array<DiseaseComboboxModel>, FailureModel>>;

}
