import { Injector } from '@angular/core';
import { MedicationBodySiteClient } from '@nmn-communication/medication-body-sites';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { MedicationBodySiteFakeClientBuilder } from '../builders/medication-body-site.fake-client.builder';
import { MedicationBodySiteFakeClientConfigurationBuilder } from '../builders/medication-body-site.fake-client.configuration.builder';
import { MedicationBodySiteHttpClientBuilder } from '../builders/medication-body-site.http-client.builder';
import { MedicationBodySiteHttpClientConfigurationBuilder } from '../builders/medication-body-site.http-client.configuration.builder';
import { MedicationBodySiteFakeClient } from '../services/medication-body-site.fake-client';
import { MedicationBodySiteHttpClient } from '../services/medication-body-site.http-client';

export const MedicationBodySiteClientFactory = (injector: Injector): MedicationBodySiteClient => {
	const useFake = environment?.api?.resources?.medicationBodySites?.useFake ||
		!isValueDefined(environment?.api?.resources?.medicationBodySites?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		MedicationBodySiteFakeClientFactory(injector) :
		MedicationBodySiteHttpClientFactory(injector);
};

export const MedicationBodySiteFakeClientFactory = (
	injector: Injector
): MedicationBodySiteFakeClient => {
	const configuration = new MedicationBodySiteFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.medicationBodySites.serverDelayMs)
		.build();

	return new MedicationBodySiteFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const MedicationBodySiteHttpClientFactory = (
	injector: Injector
): MedicationBodySiteHttpClient => {
	const configuration = new MedicationBodySiteHttpClientConfigurationBuilder()
		.withBaseUrl(environment?.api?.resources?.medicationBodySites?.baseUrl ?? environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.medicationBodySites.resourceRelativePath)
		.withResourceTypeVersion(environment?.api?.resources?.medicationBodySites?.resourceTypeVersion ?? environment?.api?.defaultResourceTypeVersion)
		.build();

	return new MedicationBodySiteHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
