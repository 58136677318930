import { isArrayDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { DiseaseComboboxModel } from '@nmn-domain/diseases/models/disease-combobox.model';
import { ComboboxModel } from '@nmn-domain/shared';
import { TreatmentCheckerDiseaseTreatmentGuidelineModel } from './treatment-checker-disease-treatment-guideline.model';
import { TreatmentCheckerPersonalizedMedicineGuidelineModel } from './treatment-checker-personalized-medicine-guideline.model';
import { mapTreatmentCheckerReportDiseaseReportFrequencyTypeFromNumberValue, TreatmentCheckerReportDiseaseReportFrequencyType } from './treatment-checker-report-disease-report.frequency-type.enum';
import { mapTreatmentCheckerReportDiseaseReportHeritabilityTypeFromNumberValue, TreatmentCheckerReportDiseaseReportHeritabilityType } from './treatment-checker-report-disease-report.heritability-type.enum';
import { TreatmentCheckerReportDiseaseReportInfectiousType } from './treatment-checker-report-disease-report.infectious-type.enum';

export class TreatmentCheckerReportDiseaseReportModel {

	public readonly disease: DiseaseComboboxModel;
	public readonly infectiousType: TreatmentCheckerReportDiseaseReportInfectiousType;
	// if isHeritable, then rate is always defined
	public readonly isHeritable?: boolean;
	public readonly heritabilityRate?: number;
	public readonly frequency?: number;
	public readonly isGeneticTestingAvailable: boolean;
	public readonly comorbidities: Array<ComboboxModel>;
	public readonly synonyms: Array<ComboboxModel>;
	public readonly affectedOrgans: Array<ComboboxModel>;
	public readonly personalizedMedicineGuidelines: Array<TreatmentCheckerPersonalizedMedicineGuidelineModel>;
	public readonly diseaseTreatmentGuidelines: Array<TreatmentCheckerDiseaseTreatmentGuidelineModel>;

	public get heritabilityType(): TreatmentCheckerReportDiseaseReportHeritabilityType {
		return mapTreatmentCheckerReportDiseaseReportHeritabilityTypeFromNumberValue(this.heritabilityRate);
	}

	public get isHeritabilityDefined(): boolean {
		return isValueDefined(this.isHeritable) && isValueDefined(this.heritabilityRate);
	}

	public get frequencyType(): TreatmentCheckerReportDiseaseReportFrequencyType {
		return mapTreatmentCheckerReportDiseaseReportFrequencyTypeFromNumberValue(this.frequency);
	}

	public get isFrequencyDefined(): boolean {
		return isValueDefined(this.frequency);
	}

	public get isComorbiditiesDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.comorbidities);
	}

	public get isSynonymsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.synonyms);
	}

	public get isAffectedOrgansDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.affectedOrgans);
	}

	public get isGeneticGuidelinesDefined(): boolean {
		return this.isPersonalizedMedicineGuidelinesDefined;
	}

	public get isPersonalizedMedicineGuidelinesDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.personalizedMedicineGuidelines);
	}

	public get isTreatmentGuidelinesDefined(): boolean {
		return this.isDiseaseTreatmentGuidelinesDefined;
	}

	public get isDiseaseTreatmentGuidelinesDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.diseaseTreatmentGuidelines);
	}

	constructor(
		disease: DiseaseComboboxModel,
		infectiousType: TreatmentCheckerReportDiseaseReportInfectiousType,
		isHeritable: boolean | undefined,
		heritabilityRate: number | undefined,
		frequency: number | undefined,
		isGeneticTestingAvailable: boolean,
		comorbidities: Array<ComboboxModel>,
		synonyms: Array<ComboboxModel>,
		affectedOrgans: Array<ComboboxModel>,
		personalizedMedicineGuidelines: Array<TreatmentCheckerPersonalizedMedicineGuidelineModel>,
		diseaseTreatmentGuidelines: Array<TreatmentCheckerDiseaseTreatmentGuidelineModel>
	) {
		// TODO: Guard check on defined

		this.disease = disease;
		this.infectiousType = infectiousType;
		this.isHeritable = isHeritable;
		this.heritabilityRate = heritabilityRate;
		this.frequency = frequency;
		this.isGeneticTestingAvailable = isGeneticTestingAvailable;
		this.comorbidities = comorbidities;
		this.synonyms = synonyms;
		this.affectedOrgans = affectedOrgans;
		this.personalizedMedicineGuidelines = personalizedMedicineGuidelines;
		this.diseaseTreatmentGuidelines = diseaseTreatmentGuidelines;
	}

}
