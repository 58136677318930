export class TakenMedicationDeleteBulkCommand {

	public ids: Array<string>;
	public patientId: string;

	public constructor(
		ids: Array<string>,
		patientId: string
	) {

		// TODO: add null check
		this.ids = ids;
		this.patientId = patientId;

	}

}
