import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { MedicationRateQuantityTypeHttpClientConfiguration } from '../configurations/medication-rate-quantity-type.http-client.configuration';

export class MedicationRateQuantityTypeHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<MedicationRateQuantityTypeHttpClientConfiguration> {

	public build(): MedicationRateQuantityTypeHttpClientConfiguration {
		return new MedicationRateQuantityTypeHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
