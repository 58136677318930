import { Injectable } from '@angular/core';
import { UserProfileClient } from '@nmn-communication/accounts';
import { Result } from '@nmn-core/shared';
import { LoginModel } from '@nmn-domain/accounts/login/models/login.model';
import { CurrentUserProfileQueryHandlerService } from '@nmn-domain/accounts/user-accounts/services/current-user-profile.query-handler.service';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../../../../domain/implementations/shared/factories/failure-handling.factory';
import { mapLoginDtoToModel } from '../../login/factories/login.factory';

@Injectable()
export class CurrentUserProfileQueryHandlerViaClientService extends CurrentUserProfileQueryHandlerService {

	constructor(
		private readonly client: UserProfileClient
	) {
		super();
	}

	public get(): Observable<Result<LoginModel, FailureModel>> {
		return this.client
			.getCurrent()
			.pipe(map(result => result.map(success => mapLoginDtoToModel(success), mapFailureToFailureModel)));
	}

}
