import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { ComboboxModel } from '@nmn-domain/shared';

export class PopulationFilterModel {

	public readonly ids?: Array<string>;
	public readonly ignoreIds?: Array<string>;
	public readonly searchPattern?: string;

	constructor(
		ids: Array<string> | undefined,
		ignoreIds: Array<string> | undefined,
		searchPattern: string | undefined
	) {
		this.ids = ids;
		this.ignoreIds = ignoreIds;
		this.searchPattern = searchPattern;
	}

	public static createEmpty(): PopulationFilterModel {
		return new PopulationFilterModel(undefined, undefined, undefined);
	}

	public static createForPredefinedList(populationIds: Array<string>): PopulationFilterModel {
		return new PopulationFilterModel(
			populationIds,
			undefined,
			undefined
		);
	}

	public static createForAutocomplete(
		searchPattern: string,
		selectedItem: ComboboxModel
	): PopulationFilterModel {
		return new PopulationFilterModel(
			undefined,
			isStringDefinedAndNotEmpty(selectedItem?.id) ? [selectedItem.id] : undefined,
			searchPattern
		);
	}

}
