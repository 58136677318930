import { isValueDefined } from '@nmn-core/utils';
import { NmnMatTabGroupUserConfiguration } from '../component-configurations/nmn-mat-tab-group-user.configuation';

export class VaccinationCardPageConfiguration {

	public readonly vaccinationMatGroup: NmnMatTabGroupUserConfiguration;

	protected constructor(
		vaccinationMatGroup: NmnMatTabGroupUserConfiguration
	) {
		this.vaccinationMatGroup = vaccinationMatGroup;
	}

	public static createWithParameters(
		config?: VaccinationCardPageConfiguration
	): VaccinationCardPageConfiguration {
		if (!isValueDefined(config)) {
			return VaccinationCardPageConfiguration.createDefault();
		}

		return new VaccinationCardPageConfiguration(
			isValueDefined(config.vaccinationMatGroup) ?
				NmnMatTabGroupUserConfiguration.createWithParameters(config.vaccinationMatGroup) :
				NmnMatTabGroupUserConfiguration.createDefault()
		);
	}

	public static createDefault(
	): VaccinationCardPageConfiguration {
		return new VaccinationCardPageConfiguration(
			NmnMatTabGroupUserConfiguration.createDefault()
		);
	}

}
