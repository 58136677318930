import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/auth.interceptor';
import { CacheControlInterceptor } from './services/cache-control.interceptor';
import { HttpErrorInterceptor } from './services/http-error.interceptor';
import { LanguageInterceptor } from './services/language.interceptor';
import { ProfilingInterceptor } from './services/profiling.interceptor';
import { RegionInterceptor } from './services/region.interceptor';

export const interceptorProviders = [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: CacheControlInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: AuthInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: LanguageInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: RegionInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: HttpErrorInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: ProfilingInterceptor,
		multi: true
	}
];
