import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { FailureModel } from '../../shared';
import { SendMessageCommand } from '../models/commands/send-message.command';

export abstract class ContactsCommandHandlerService {

	public abstract handleSendMessageCommand(command: SendMessageCommand): Observable<Result<EmptyCommandResult<SendMessageCommand>, FailureModel>>;

}
