import {
	DocumentClient,
	DocumentCreateParameter,
	DocumentDeleteAttachmentParameter,
	DocumentDeleteBulkParameter,
	DocumentDescribedComboboxDto,
	DocumentDto,
	DocumentFilterDto,
	DocumentFindParameter,
	DocumentUpdateParameter
} from '@nmn-communication/documents';
import { Failure, PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { wrapActionCallAsFakeClientResponse, wrapFuncCallAsFakeClientResponse } from '../../../../modules/fakes/databases/fake.utils';
import { DocumentFakeClientConfiguration } from '../configurations/document.fake-client.configuration';

export class DocumentFakeClient extends DocumentClient {

	constructor(
		private readonly configuration: DocumentFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulkAsComboboxes(
		filter: DocumentFilterDto
	): Observable<Result<Array<DocumentDescribedComboboxDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.documentsTable.getComboboxesPagedCollection(filter),
			this.configuration.serverDelayMs
		);
	}

	public getBulk(
		parameter: PageOptionsDto<DocumentFilterDto>
	): Observable<Result<PagedCollectionDto<DocumentDto, DocumentFilterDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.documentsTable.getPagedCollection(parameter),
			this.configuration.serverDelayMs
		);
	}

	public get(parameter: DocumentFindParameter): Observable<Result<DocumentDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.documentsTable.find(parameter),
			this.configuration.serverDelayMs
		);
	}

	public create(parameter: DocumentCreateParameter): Observable<Result<string, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.documentsTable.create(parameter),
			this.configuration.serverDelayMs
		);
	}

	public delete(parameter: DocumentFindParameter): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.documentsTable.delete(parameter);
			},
			this.configuration.serverDelayMs
		);
	}

	public deleteBulk(parameter: DocumentDeleteBulkParameter): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.documentsTable.deleteBulk(parameter);
			},
			this.configuration.serverDelayMs
		);
	}

	public deleteAttachment(
		findParameter: DocumentFindParameter,
		deleteParameter: DocumentDeleteAttachmentParameter
	): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.documentsTable.deleteAttachment(findParameter, deleteParameter);
			},
			this.configuration.serverDelayMs
		);
	}

	public update(
		findParameter: DocumentFindParameter,
		updateParameter: DocumentUpdateParameter
	): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.documentsTable.update(findParameter, updateParameter);
			},
			this.configuration.serverDelayMs
		);
	}

}
