import { Provider } from '@angular/core';
import { GeneticFileParseRequestCommandHandlerService, GeneticFileParseRequestQueryHandlerService } from '@nmn-domain/genetic-file-parse-requests';
import { GeneticFileParseRequestCommandHandlerViaClientService } from './services/genetic-file-parse-request.command-handler-via-client.service';
import { GeneticFileParseRequestQueryHandlerViaClientService } from './services/genetic-file-parse-request.query-handler-via-client.service';

export const geneticFileParseRequestProviders: Array<Provider> = [
	{
		provide: GeneticFileParseRequestCommandHandlerService,
		useClass: GeneticFileParseRequestCommandHandlerViaClientService
	},
	{
		provide: GeneticFileParseRequestQueryHandlerService,
		useClass: GeneticFileParseRequestQueryHandlerViaClientService
	}
];
