import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
	HealthIssueClient,
	HealthIssueComboboxDto,
	HealthIssueCreateParameterDto,
	HealthIssueDto,
	HealthIssueFilterDto,
	HealthIssueFindParameterDto,
	HealthIssueUpdateParameterDto
} from '@nmn-communication/health-issues';
import { Failure, PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { constructHttpParams, injectParameters } from '@nmn-core/utils';
import { Observable } from 'rxjs';
import {
	createEmptyResultFromResponse,
	createFailedEmptyResultFromErrorResponse,
	createFailedResultFromErrorResponse,
	createResultFromResponse
} from '../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { HealthIssueHttpClientConfiguration } from '../configurations/health-issue.http-client.configuration';

export class HealthIssueHttpClient extends HealthIssueClient {

	constructor(
		private readonly configuration: HealthIssueHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public getBulkAsComboboxes(
		findParameter: HealthIssueFilterDto
	): Observable<Result<Array<HealthIssueComboboxDto>, Failure>> {
		const parameters = {
			patientId: findParameter.patientId
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/combobox`,
			parameters);
		const params = constructHttpParams(findParameter);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<Array<HealthIssueComboboxDto>>(url, { params, headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public getBulk(
		findParameter: PageOptionsDto<HealthIssueFilterDto>
	): Observable<Result<PagedCollectionDto<HealthIssueDto, HealthIssueFilterDto>, Failure>> {
		const parameters = {
			patientId: findParameter.filter?.patientId
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`,
			parameters);
		const params = constructHttpParams(findParameter);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<PagedCollectionDto<HealthIssueDto, HealthIssueFilterDto>>(url, { params, headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);

	}

	public get(parameter: HealthIssueFindParameterDto): Observable<Result<HealthIssueDto, Failure>> {
		const parameters = {
			patientId: parameter.patientId,
			key: parameter.id
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/{key}`,
			parameters);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<HealthIssueDto>(url, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);

	}

	public create(
		createParameter: HealthIssueCreateParameterDto
	): Observable<Result<string, Failure>> {
		const parameters = {
			patientId: createParameter.patientId
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`,
			parameters);
		const payload = createParameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post<string>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public update(
		findParameter: HealthIssueFindParameterDto,
		updateParameter: HealthIssueUpdateParameterDto
	): Observable<EmptyResult<Failure>> {
		const parameters = {
			patientId: findParameter.patientId,
			key: findParameter.id
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/{key}`,
			parameters);
		const payload = updateParameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put(url, payload, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

	public delete(
		updateParameter: HealthIssueFindParameterDto
	): Observable<EmptyResult<Failure>> {
		const parameters = {
			patientId: updateParameter.patientId,
			key: updateParameter.id
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/{key}`,
			parameters);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.delete(url, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

}
