import { isValueDefined } from '@nmn-core/utils';
import { HttpClientConfiguration } from '../configurations/http-client.configuration';

export abstract class HttpClientBuilder<THttpClient, THttpClientConfiguration extends HttpClientConfiguration> {

	private clientConfiguration: THttpClientConfiguration;

	protected get configuration(): THttpClientConfiguration {
		return this.clientConfiguration;
	}

	public withConfiguration(
		configuration: THttpClientConfiguration
	): HttpClientBuilder<THttpClient, THttpClientConfiguration> {
		if (isValueDefined(configuration)) {
			this.clientConfiguration = configuration;
		}

		return this;
	}

	public abstract build(): THttpClient;

}
