import { diffDateInMs, isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined, toGB, toKB, toMB } from '@nmn-core/utils';
import { DoctorEncounterComboboxModel } from '@nmn-domain/doctor-encounters';
import { DocumentTypeDescribedComboboxModel } from '@nmn-domain/document-types';
import { UploadedFileModel } from '@nmn-domain/file-uploader';
import { HealthIssueComboboxModel } from '@nmn-domain/health-issues';
import { DescribedComboboxModel } from '@nmn-domain/shared';
import { TakenMedicationDescribedComboboxModel } from '@nmn-domain/taken-medications';

export class DocumentModel {

	public readonly id: string;
	public readonly patientId: string;
	public readonly name: string;
	public readonly description: string;
	public readonly type: DocumentTypeDescribedComboboxModel;
	public readonly attachments: Array<DocumentAttachmentModel>;
	public readonly relatedEncounters: Array<DoctorEncounterComboboxModel>;
	public readonly relatedTakenMedications: Array<TakenMedicationDescribedComboboxModel>;
	public readonly relatedHealthIssues: Array<HealthIssueComboboxModel>;
	public readonly createdOn: Date;
	public readonly updatedOn: Date | undefined;
	public readonly size: number;

	public get limitedDescription(): string {
		return isStringDefinedAndNotEmpty(this.description) ?
			this.description.length > 1010 ?
				`${this.description.substring(0, 1000)}...` :
				this.description :
			'';
	}

	public get relationsCount(): number {
		return this.relatedEncounters.length +
			this.relatedTakenMedications.length +
			this.relatedHealthIssues.length;
	}

	public get relations(): Array<DescribedComboboxModel> {
		return [
			...this.relatedEncounters,
			...this.relatedTakenMedications,
			...this.relatedHealthIssues
		];
	}

	public get lastModifiedOn(): Date {
		return isValueDefined(this.updatedOn) && diffDateInMs(this.updatedOn, this.createdOn) > 0 ?
			this.createdOn :
			this.updatedOn;
	}

	public get isDescriptionDefined(): boolean {
		return isValueDefined(this.description);
	}

	public get isAttachmentsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.attachments);
	}

	public get isRelatedEncountersDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.relatedEncounters);
	}

	public get isRelatedTakenMedicationsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.relatedTakenMedications);
	}

	public get isRelatedHealthIssuesDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.relatedHealthIssues);
	}

	public get isUpdatedOnDefined(): boolean {
		return isValueDefined(this.updatedOn);
	}

	public get sizeInGB(): number {
		return toGB(this.size);
	}

	public get sizeInMB(): number {
		return toMB(this.size);
	}

	public get sizeInKB(): number {
		return toKB(this.size);
	}

	constructor(
		id: string,
		patientId: string,
		name: string,
		description: string,
		type: DocumentTypeDescribedComboboxModel,
		attachments: Array<DocumentAttachmentModel>,
		relatedEncountersRecords: Array<DoctorEncounterComboboxModel>,
		relatedTakenMedications: Array<TakenMedicationDescribedComboboxModel>,
		relatedHealthIssues: Array<HealthIssueComboboxModel>,
		createdOn: Date,
		updatedOn: Date | undefined,
		size: number
	) {
		// TODO: GUARD add checks

		this.id = id;
		this.patientId = patientId;
		this.name = name;
		this.description = description;
		this.type = type;
		this.attachments = attachments;
		this.relatedEncounters = relatedEncountersRecords;
		this.relatedTakenMedications = relatedTakenMedications;
		this.relatedHealthIssues = relatedHealthIssues;
		this.createdOn = createdOn;
		this.updatedOn = updatedOn;
		this.size = size;
	}

}

export class DocumentAttachmentModel {

	public readonly id: string;
	public readonly file: UploadedFileModel;
	public readonly isFinished: boolean;

	constructor(
		id: string,
		file: UploadedFileModel,
		isFinished: boolean
	) {
		this.id = id;
		this.file = file;
		this.isFinished = isFinished;
	}

}
