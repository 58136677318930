import { isArrayDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { DoctorEncounterComboboxModel } from '@nmn-domain/doctor-encounters';
import { DocumentDescribedComboboxModel } from '@nmn-domain/documents';
import { HealthIssueComboboxModel } from '@nmn-domain/health-issues';
import { MedicationAdmRouteDescribedComboboxModel } from '@nmn-domain/medication-adm-routes';
import { MedicationAdmStatusDescribedComboboxModel, MedicationAdmStatusType } from '@nmn-domain/medication-adm-statuses';
import { MedicationBodySiteDescribedComboboxModel } from '@nmn-domain/medication-body-sites';
import { MedicationComboboxModel } from '@nmn-domain/medications';
import { DescribedComboboxModel } from '@nmn-domain/shared';
import { TypeOfMedicationComboboxModel } from '@nmn-domain/types-of-medication';

export class TakenMedicationModel {

	public readonly id: string;
	public readonly from: Date | undefined;
	public readonly to: Date | undefined;
	public readonly comment: string | undefined;
	public readonly dosageValue: number | undefined;
	public readonly rateValue: number | undefined;
	public readonly patientId: string;
	public readonly encounter: DoctorEncounterComboboxModel | undefined;
	public readonly healthIssue: HealthIssueComboboxModel | undefined;
	public readonly medication: MedicationComboboxModel;
	public readonly medicationAdmRoute: MedicationAdmRouteDescribedComboboxModel | undefined;
	public readonly medicationAdmStatus: MedicationAdmStatusDescribedComboboxModel;
	public readonly medicationDosage: DescribedComboboxModel | undefined;
	public readonly medicationBodySite: MedicationBodySiteDescribedComboboxModel | undefined;
	public readonly medicationRateQuantityType: DescribedComboboxModel | undefined;
	public readonly isIncompatibleWithAlcohol: boolean;
	public readonly isEfficacyUnproven: boolean;
	public readonly isIncompatibleWithOtherTakenMedication: boolean;
	public readonly incompatibleTakenMedications: Array<string>;
	public readonly documents: Array<DocumentDescribedComboboxModel>;
	public readonly typeOfMedication: TypeOfMedicationComboboxModel;

	public get isToFromDateDefined(): boolean {
		return isValueDefined(this.to) && isValueDefined(this.from);
	}

	public get isOnlyToDateDefined(): boolean {
		return isValueDefined(this.to) && !isValueDefined(this.from);
	}

	public get isOnlyFromDateDefined(): boolean {
		return !isValueDefined(this.to) && isValueDefined(this.from);
	}

	public get isFromToNotDefined(): boolean {
		return !isValueDefined(this.to) && !isValueDefined(this.from);
	}

	public get isEncounterDefined(): boolean {
		return isValueDefined(this.encounter);
	}

	public get isHealthIssueDefined(): boolean {
		return isValueDefined(this.healthIssue);
	}

	public get isDocumentsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.documents);
	}

	public get isCommentDefined(): boolean {
		return isValueDefined(this.comment);
	}

	public get isDosageDefined(): boolean {
		return isValueDefined(this.dosageValue) && isValueDefined(this.medicationDosage);
	}

	public get isRateDefined(): boolean {
		return isValueDefined(this.rateValue) && isValueDefined(this.medicationDosage);
	}

	public get isIncompatibleTakenMedicationsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.incompatibleTakenMedications);
	}

	public get isActiveAdmStatus(): boolean {
		return this.medicationAdmStatus.type === MedicationAdmStatusType.Active;
	}

	public get isSuspendedAdmStatus(): boolean {
		return this.medicationAdmStatus.type === MedicationAdmStatusType.Suspended;
	}

	public get isCompletedAdmStatus(): boolean {
		return this.medicationAdmStatus.type === MedicationAdmStatusType.Completed ||
			this.medicationAdmStatus.type === MedicationAdmStatusType.Failed;
	}

	public get hasCautions(): boolean {
		return this.isIncompatibleWithAlcohol ||
			this.isEfficacyUnproven;
	}

	public get isAdmRouteDefined(): boolean {
		return isValueDefined(this.medicationAdmRoute);
	}

	public get isBodySiteDefined(): boolean {
		return isValueDefined(this.medicationBodySite);
	}

	public get isAdmStatusDefined(): boolean {
		return isValueDefined(this.medicationAdmStatus);
	}

	public get isTypeOfMedicationDefined(): boolean {
		return isValueDefined(this.typeOfMedication);
	}

	constructor(
		id: string,
		from: Date | undefined,
		to: Date | undefined,
		comment: string | undefined,
		dosageValue: number,
		rateValue: number | undefined,
		patientId: string | undefined,
		encounter: DoctorEncounterComboboxModel | undefined,
		healthIssue: HealthIssueComboboxModel | undefined,
		medication: MedicationComboboxModel,
		medicationAdmRoute: MedicationAdmRouteDescribedComboboxModel | undefined,
		medicationAdmStatus: MedicationAdmStatusDescribedComboboxModel,
		medicationDosage: DescribedComboboxModel | undefined,
		medicationBodySite: MedicationBodySiteDescribedComboboxModel | undefined,
		medicationRateQuantityType: DescribedComboboxModel | undefined,
		isIncompatibleWithAlcohol: boolean,
		isEfficacyUnproven: boolean,
		isIncompatibleWithOtherTakenMedication: boolean,
		incompatibleTakenMedications: Array<string>,
		documents: Array<DocumentDescribedComboboxModel>,
		typeOfMedication: TypeOfMedicationComboboxModel
	) {

		// TODO: Guard check on defined

		this.id = id;
		this.from = from;
		this.to = to;
		this.comment = comment;
		this.dosageValue = dosageValue;
		this.rateValue = rateValue;
		this.patientId = patientId;
		this.encounter = encounter;
		this.healthIssue = healthIssue;
		this.medication = medication;
		this.medicationAdmRoute = medicationAdmRoute;
		this.medicationAdmStatus = medicationAdmStatus;
		this.medicationDosage = medicationDosage;
		this.medicationBodySite = medicationBodySite;
		this.medicationRateQuantityType = medicationRateQuantityType;
		this.isIncompatibleWithAlcohol = isIncompatibleWithAlcohol;
		this.isEfficacyUnproven = isEfficacyUnproven;
		this.isIncompatibleWithOtherTakenMedication = isIncompatibleWithOtherTakenMedication;
		this.incompatibleTakenMedications = incompatibleTakenMedications;
		this.documents = documents;
		this.typeOfMedication = typeOfMedication;
	}

	public incompatibleTakenMedicationsAsString(): string {
		return this.incompatibleTakenMedications.join(', ');
	}

}
