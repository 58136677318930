import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { PagedCollectionModel, PageOptionsQuery } from '../../shared';
import { FailureModel } from '../../shared/failures/failure.model';
import { TakenMedicationGetQuery } from '../models/queries/taken-medication.get.query';
import { TakenMedicationDescribedComboboxModel } from '../models/taken-medication-described-combobox.model';
import { TakenMedicationFilterModel } from '../models/taken-medication-filter.model';
import { TakenMedicationModel } from '../models/taken-medication.model';

export abstract class TakenMedicationQueryHandlerService {

	public abstract getAsDescribedComboboxes(
		query: TakenMedicationFilterModel
	): Observable<Result<Array<TakenMedicationDescribedComboboxModel>, FailureModel>>;

	public abstract getPagedCollection(
		query: PageOptionsQuery<TakenMedicationFilterModel>
	): Observable<Result<PagedCollectionModel<TakenMedicationModel, TakenMedicationFilterModel>, FailureModel>>;

	public abstract get(
		query: TakenMedicationGetQuery
	): Observable<Result<TakenMedicationModel, FailureModel>>;

}
