import {
	AllergyIntoleranceDto, PatientAnamnesisParameter, PatientCreateParameter, PatientDeleteParameter, PatientDto, PatientFilterDto, PatientFindParameter,
	PatientLifestyleParameter, PatientProfileDocumentsDto, PatientProfileDto, PatientProfileGeneticTestCardDto, PatientProfileInformationDto,
	PatientProfileLabTestCheckerDto, PatientProfileMedicalCardDto, PatientRevokeDeleteParameter, PatientUpdateParameter
} from '@nmn-communication/patients';
import { PatientAnamnesisDto } from '@nmn-communication/patients/models/patient-anamnesis.dto';
import { PatientLifestyleDto } from '@nmn-communication/patients/models/patient-lifestyle..dto';
import { PagedCollectionDto } from '@nmn-communication/shared';
import { InformationBlock } from '@nmn-core/shared';
import { isValueDefined, mapDateInServerFormatToDate, mapDateInServerFormatToDateSafely, mapDateToServerFormat, mapDateToServerFormatSafely } from '@nmn-core/utils';
import {
	AllergyIntoleranceModel, DocumentsInformationBlockModel, EmergencyCardInformationBlockModel, GeneticTestCardInformationBlockModel,
	LabTestCheckerInformationBlockModel, MedicalCardInformationBlockModel, PatientAnamnesisModel, PatientCreateCommand, PatientDeleteCommand, PatientFilterModel,
	PatientGetQuery, PatientInformationBlockModel, PatientLifestyleModel, PatientModel, PatientProfileInformationBlockModel, PatientRevokeDeleteCommand, PatientUpdateCommand
} from '@nmn-domain/patients';
import { PagedCollectionModel } from '@nmn-domain/shared';
import { mapDiseaseComboboxDtoToModel } from '../../diseases/factories/disease.factory';
import { mapUploadedFileDtoToModel } from '../../file-uploader/factories/file-uploader.factory';
import { mapMedicationComboboxDtoToModel } from '../../medications/factories/medication.factory';
import { mapPopulationComboboxDtoToModel } from '../../populations/factories/population.factory';
import { mapComboboxDtoToComboboxModel, mapDescribedComboboxDtoToDescribedComboboxModel } from '../../shared/factories/combobox.factory';
import { mapPagedCollectionDtoToModel } from '../../shared/factories/pagination.factory';

export const mapDtoToModel = (dto: PatientDto): PatientModel => (
	new PatientModel(
		dto.id,
		dto.alias,
		dto.userId,
		mapDateInServerFormatToDateSafely(dto.createdOn),
		mapDateInServerFormatToDate(dto.updatedOn),
		dto.givenName,
		dto.additionalName,
		dto.familyName,
		dto.languages?.map(mapComboboxDtoToComboboxModel),
		mapComboboxDtoToComboboxModel(dto.preferredLanguage),
		isValueDefined(dto.picture) ? mapUploadedFileDtoToModel(dto.picture) : undefined,
		mapPopulationComboboxDtoToModel(dto.population),
		mapDescribedComboboxDtoToDescribedComboboxModel(dto.gender),
		mapDateInServerFormatToDateSafely(dto.birthday),
		isValueDefined(dto.bloodType) ? mapDescribedComboboxDtoToDescribedComboboxModel(dto.bloodType) : undefined,
		dto.height,
		dto.weight,
		dto.email,
		dto.phoneNumber,
		dto.address,
		dto.allergyIntolerances?.map(mapAllergyIntoleranceDtoToModel),
		dto.seriousPathologies?.map(mapDiseaseComboboxDtoToModel),
		dto.familyDiseases?.map(mapDiseaseComboboxDtoToModel),
		dto.regularlyTakenMedications?.map(mapMedicationComboboxDtoToModel),
		dto.doctors,
		dto.emergencyContacts,
		dto.insurances,
		mapPatientAnamnesisDtoToModel(dto.anamnesis),
		mapPatientLifestyleDtoToModel(dto.lifestyle)
	)
);

export const mapDtosToModels = (dtos: Array<PatientDto>): Array<PatientModel> => (
	dtos.map(mapDtoToModel)
);

export const mapPatientAnamnesisDtoToModel = (dto: PatientAnamnesisDto): PatientAnamnesisModel => (
	new PatientAnamnesisModel(
		dto?.hasHealthIssuesWithLiver,
		dto?.hasHealthIssuesWithKidney,
		dto?.hasHealthIssuesWithHeart,
		dto?.hasHealthIssuesWithStomach,
		dto?.hasHealthIssuesWithNervousSystem,
		dto?.hasHealthIssuesWithImmuneSystem,
		dto?.hasHealthIssuesWithEndocrine,
		dto?.hasHealthIssuesWithCancer,
		dto?.hasHealthIssuesWithEye,
		dto?.hasHealthIssuesWithEar,
		dto?.hasHealthIssuesWithMuscles,
		dto?.hasHealthIssuesWithGeneticConditions
	)
);

export const mapPatientLifestyleDtoToModel = (dto: PatientLifestyleDto): PatientLifestyleModel => (
	new PatientLifestyleModel(
		dto?.isPregnant,
		dto?.isActiveLifestyle,
		dto?.isAlcohol,
		dto?.isOnHealthyDiet,
		dto?.isSmoking,
		dto?.isUnderHighLevelOfStress,
		dto?.isUsingNutritionalSupplements
	)
);

export const mapAllergyIntoleranceDtoToModel = (dto: AllergyIntoleranceDto): AllergyIntoleranceModel => (
	new AllergyIntoleranceModel(
		mapDescribedComboboxDtoToDescribedComboboxModel(dto.allergy),
		isValueDefined(dto.criticality) ? mapDescribedComboboxDtoToDescribedComboboxModel(dto.criticality) : undefined,
		isValueDefined(dto.status) ? mapDescribedComboboxDtoToDescribedComboboxModel(dto.status) : undefined,
		isValueDefined(dto.verificationStatus) ? mapDescribedComboboxDtoToDescribedComboboxModel(dto.verificationStatus) : undefined,
		isValueDefined(dto.lastOccurrence) ? mapDateInServerFormatToDate(dto.lastOccurrence) : undefined
	)
);

export const mapProfileDtoToModel = (dto: PatientProfileDto): PatientProfileInformationBlockModel => (
	new PatientProfileInformationBlockModel(
		dto.fillingUpPercentage,
		mapProfileDocumentsDtoToModel(dto.documents),
		mapProfileEmergencyCardDtoToModel(dto.emergencyCard),
		mapProfileGeneticTestCardDtoToModel(dto.geneticTestCard),
		mapProfileLabTestCheckerDtoToModel(dto.labTestChecker),
		mapProfileMedicalCardDtoToModel(dto.medicalCard),
		mapPatientProfileInformationDtoToModel(dto.patient)
	)
);

export const mapProfileDocumentsDtoToModel = (dto: PatientProfileDocumentsDto): DocumentsInformationBlockModel => (
	new DocumentsInformationBlockModel(
		dto.fillingUpPercentage,
		dto.count,
		dto.lastAddedDisplayText,
		mapDateInServerFormatToDate(dto.lastAddedDate)
	)
);

export const mapProfileGeneticTestCardDtoToModel = (dto: PatientProfileGeneticTestCardDto): GeneticTestCardInformationBlockModel => (
	new GeneticTestCardInformationBlockModel(
		dto.fillingUpPercentage,
		dto.count
	)
);

export const mapProfileEmergencyCardDtoToModel = (dto: InformationBlock): EmergencyCardInformationBlockModel => (
	new EmergencyCardInformationBlockModel(
		dto.fillingUpPercentage
	)
);

export const mapProfileLabTestCheckerDtoToModel = (dto: PatientProfileLabTestCheckerDto): LabTestCheckerInformationBlockModel => (
	new LabTestCheckerInformationBlockModel(
		dto.fillingUpPercentage,
		dto.count,
		mapDateInServerFormatToDate(dto.lastCheckUpDate)
	)
);

export const mapProfileMedicalCardDtoToModel = (dto: PatientProfileMedicalCardDto): MedicalCardInformationBlockModel => (
	new MedicalCardInformationBlockModel(
		dto.fillingUpPercentage,
		mapDateInServerFormatToDate(dto.firstRecordDate),
		mapDateInServerFormatToDate(dto.lastModificationDate),
		dto.medicationsCount,
		isValueDefined(dto.lastHealthIssue) ? mapDescribedComboboxDtoToDescribedComboboxModel(dto.lastHealthIssue) : undefined,
		isValueDefined(dto.lastEncounter) ? mapDescribedComboboxDtoToDescribedComboboxModel(dto.lastEncounter) : undefined,
		dto.healthIssuesCount,
		dto.encountersCount
	)
);

export const mapPatientProfileInformationDtoToModel = (dto: PatientProfileInformationDto): PatientInformationBlockModel => (
	new PatientInformationBlockModel(
		dto.id,
		dto.alias,
		dto.userId,
		mapDateInServerFormatToDateSafely(dto.createdOn),
		mapDateInServerFormatToDate(dto.updatedOn),
		dto.givenName,
		dto.additionalName,
		dto.familyName,
		dto.languages.map(mapComboboxDtoToComboboxModel),
		mapComboboxDtoToComboboxModel(dto.preferredLanguage),
		isValueDefined(dto.picture) ? mapUploadedFileDtoToModel(dto.picture) : undefined,
		mapPopulationComboboxDtoToModel(dto.population),
		mapDescribedComboboxDtoToDescribedComboboxModel(dto.gender),
		mapDateInServerFormatToDateSafely(dto.birthday),
		isValueDefined(dto.bloodType) ? mapDescribedComboboxDtoToDescribedComboboxModel(dto.bloodType) : undefined,
		dto.height,
		dto.weight,
		dto.email,
		dto.phoneNumber,
		dto.address,
		dto.allergyIntolerances?.map(mapAllergyIntoleranceDtoToModel),
		dto.seriousPathologies?.map(mapDiseaseComboboxDtoToModel),
		dto.familyDiseases?.map(mapDiseaseComboboxDtoToModel),
		dto.regularlyTakenMedications?.map(mapMedicationComboboxDtoToModel),
		dto.doctors,
		dto.emergencyContacts,
		dto.insurances,
		mapPatientAnamnesisDtoToModel(dto.anamnesis),
		mapPatientLifestyleDtoToModel(dto.lifestyle),
		dto.fillingUpPercentage
	)
);

export const mapGetQueryToParameter = (query: PatientGetQuery): PatientFindParameter => (
	{
		alias: query.alias
	}
);

export const mapFilterModelToDto = (model: PatientFilterModel): PatientFilterDto => (
	{
		...model
	}
);

export const mapFilterDtoToModel = (dto: PatientFilterDto): PatientFilterModel => (
	new PatientFilterModel(
		dto.ids,
		dto.searchPattern
	)
);

export const mapPatientPagedCollectionDtoToModel = (
	pagedCollectionDto: PagedCollectionDto<PatientDto, PatientFilterDto>
): PagedCollectionModel<PatientModel, PatientFilterModel> =>
	mapPagedCollectionDtoToModel(pagedCollectionDto, mapDtoToModel, mapFilterDtoToModel);

export const mapCreateCommandToParameter = (command: PatientCreateCommand): PatientCreateParameter => (
	{
		additionalName: command.additionalName,
		address: command.address,
		languageIds: command.languageIds,
		preferredLanguageId: command.preferredLanguageId,
		allergyIntolerances: command.allergyIntolerances.map(mapAllergyIntoleranceParameter),
		birthday: mapDateToServerFormatSafely(command.birthday),
		bloodTypeId: command.bloodTypeId,
		doctors: command.doctors,
		email: command.email,
		emergencyContacts: command.emergencyContacts,
		familyDiseaseIds: command.familyDiseaseIds,
		regularlyTakenMedicationIds: command.regularlyTakenMedicationIds,
		familyName: command.familyName,
		populationId: command.populationId,
		genderId: command.genderId,
		givenName: command.givenName,
		height: command.height,
		insurances: command.insurances,
		phoneNumber: command.phoneNumber,
		pictureId: command.pictureId,
		seriousPathologyIds: command.seriousPathologyIds,
		weight: command.weight,
		anamnesis: mapPatientAnamnesisModelToParameter(command.anamnesis),
		lifestyle: mapPatientLifestyleModelToParameter(command.lifestyle)
	}
);

export const mapAllergyIntoleranceParameter = (
	parameter: {
		allergyId: string;
		criticalityId: string;
		statusId: string;
		verificationStatusId: string;
		lastOccurrence?: Date;
	}
): {
	allergyId: string,
	criticalityId: string,
	statusId: string,
	verificationStatusId: string,
	lastOccurrence?: string
} => (
	{
		allergyId: parameter.allergyId,
		criticalityId: parameter.criticalityId,
		statusId: parameter.statusId,
		verificationStatusId: parameter.verificationStatusId,
		lastOccurrence: mapDateToServerFormat(parameter.lastOccurrence)
	}
);

export const mapUpdateCommandToFindParameter = (command: PatientUpdateCommand): PatientFindParameter => (
	{
		alias: command.id
	}
);

export const mapUpdateCommandToParameter = (command: PatientUpdateCommand): PatientUpdateParameter => (
	{
		additionalName: command.additionalName,
		address: command.address,
		languageIds: command.languageIds,
		preferredLanguageId: command.preferredLanguageId,
		allergyIntolerances: command.allergyIntolerances.map(mapAllergyIntoleranceParameter),
		birthday: mapDateToServerFormatSafely(command.birthday),
		bloodTypeId: command.bloodTypeId,
		doctors: command.doctors,
		email: command.email,
		emergencyContacts: command.emergencyContacts,
		familyDiseaseIds: command.familyDiseaseIds,
		regularlyTakenMedicationIds: command.regularlyTakenMedicationIds,
		familyName: command.familyName,
		populationId: command.populationId,
		genderId: command.genderId,
		givenName: command.givenName,
		height: command.height,
		insurances: command.insurances,
		phoneNumber: command.phoneNumber,
		pictureId: command.pictureId,
		seriousPathologyIds: command.seriousPathologyIds,
		weight: command.weight,
		anamnesis: mapPatientAnamnesisModelToParameter(command.anamnesis),
		lifestyle: mapPatientLifestyleModelToParameter(command.lifestyle)
	}
);

export const mapPatientAnamnesisModelToParameter = (model: PatientAnamnesisModel): PatientAnamnesisParameter => (
	{
		...model
	}
);

export const mapPatientLifestyleModelToParameter = (model: PatientLifestyleModel): PatientLifestyleParameter => (
	{
		...model
	}
);


export const mapPatientDeleteCommandToDeleteParameter = (command: PatientDeleteCommand): PatientDeleteParameter => (
	{
		alias: command.id,
		password: command.password
	}
);

export const mapPatientRevokeDeleteCommandToDeleteParameter = (command: PatientRevokeDeleteCommand): PatientRevokeDeleteParameter => (
	{
		alias: command.id,
		password: command.password
	}
);
