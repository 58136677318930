import { PopulationComboboxModel } from '@nmn-domain/populations';
import { ComboboxModel } from '@nmn-domain/shared';

export class RegionComboboxModel extends ComboboxModel {

	public readonly regionGroup: ComboboxModel;
	public readonly defaultPopulation: PopulationComboboxModel;

	constructor(
		id: string,
		displayText: string,
		regionGroup: ComboboxModel,
		defaultPopulation: PopulationComboboxModel
	) {
		super(id, displayText);

		// TOOD: Guard is defined
		this.regionGroup = regionGroup;
		this.defaultPopulation = defaultPopulation;
	}

	public static createDisplayOptionFn(): (option: RegionComboboxModel) => string | undefined {
		return option => option.displayText;
	}

}
