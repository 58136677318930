import { Injectable } from '@angular/core';
import { AllergyClient } from '@nmn-communication/allergies';
import { Result } from '@nmn-core/shared';
import { AllergyFilterModel, AllergyQueryHandlerService } from '@nmn-domain/allergies';
import { DescribedComboboxModel, FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapDescribedComboboxDtoToDescribedComboboxModel } from '../../shared/factories/combobox.factory';
import { mapDtoArrayResultToModelArrayResult } from '../../shared/factories/pagination.factory';
import { mapFilterModelToDto } from '../factories/allergy.factory';

@Injectable()
export class AllergyQueryHandlerViaClientService extends AllergyQueryHandlerService {

	constructor(
		private readonly client: AllergyClient
	) {
		super();
	}

	public getAsDescribedComboboxes(
		filter: AllergyFilterModel
	): Observable<Result<Array<DescribedComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapDescribedComboboxDtoToDescribedComboboxModel))
			);
	}

}
