import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { MedicationAdmStatusHttpClientConfiguration } from '../configurations/medication-adm-status.http-client.configuration';
import { MedicationAdmStatusHttpClient } from '../services/medication-adm-status.http-client';

export class MedicationAdmStatusHttpClientBuilder
	extends HttpClientBuilder<MedicationAdmStatusHttpClient, MedicationAdmStatusHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): MedicationAdmStatusHttpClient {
		return new MedicationAdmStatusHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
