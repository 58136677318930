import { Injectable } from '@angular/core';
import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { LocalStorageRepositoryService } from '../repositories/local-storage.repository.service';

@Injectable()
export class LocalStorageService {

	private readonly storageKeys = {
		appGDPR: 'app-gdpr',
		appLanguage: 'app-language',
		appLastUserId: 'app-last-user-id',
		appRegionId: 'app-region',
		appTheme: 'app-theme',
		inCartSubscription: `in-cart-subscription`
	};

	constructor(
		private readonly localStorageRepository: LocalStorageRepositoryService
	) { }

	public setGDPRAccept(): void {
		this.localStorageRepository.set(this.storageKeys.appGDPR, { isGDPRAccepted: true });
	}

	public getIsGDPRAccepted(): boolean {
		return this.localStorageRepository
			.getPayload<{ isGDPRAccepted: boolean }>(this.storageKeys.appGDPR)
			.mapOnSuccess(success => success.isGDPRAccepted)
			.successOrDefault(() => false);
	}

	public setLanguage(language: string): void {
		if (isStringDefinedAndNotEmpty(language)) {
			this.localStorageRepository.set(this.storageKeys.appLanguage, { language });
		}
	}

	public getLanguage(): string | undefined {
		return this.localStorageRepository
			.getPayload<{ language: string }>(this.storageKeys.appLanguage)
			.mapOnSuccess(success => success.language)
			.successOrUndefined();
	}

	public setLastUserId(userId: string): void {
		if (isStringDefinedAndNotEmpty(userId)) {
			this.localStorageRepository.set(this.storageKeys.appLastUserId, { userId });
		}
	}

	public getLastUserId(): string | undefined {
		return this.localStorageRepository
			.getPayload<{ userId: string }>(this.storageKeys.appLastUserId)
			.mapOnSuccess(success => success.userId)
			.successOrUndefined();
	}

	public setSubscriptionAndPricingPlanDecision(pricingPlan: string, subscription: string) {
		this.localStorageRepository.set(this.storageKeys.inCartSubscription, { pricingPlan, subscription });
	}

	public removeSubscriptionAndPricingPlanDecision(): void {
		this.localStorageRepository.remove(this.storageKeys.inCartSubscription);
	}

	public getSubscriptionAndPricingPlanDecision(): { pricingPlan: string, subscription: string } | undefined {
		return this.localStorageRepository
			.getPayload<{ pricingPlan: string, subscription: string }>(this.storageKeys.inCartSubscription)
			.successOrUndefined();
	}

	public setRegionId(regionId: string): void {
		if (isStringDefinedAndNotEmpty(regionId)) {
			this.localStorageRepository.set(this.storageKeys.appRegionId, { regionId });
		}
	}

	public getRegionId(): string | undefined {
		return this.localStorageRepository
			.getPayload<{ regionId: string }>(this.storageKeys.appRegionId)
			.mapOnSuccess(success => success.regionId)
			.successOrUndefined();
	}

	public setTheme(theme: string): void {
		if (isStringDefinedAndNotEmpty(theme)) {
			this.localStorageRepository.set(this.storageKeys.appTheme, { theme });
		}
	}

	public getTheme(): string | undefined {
		return this.localStorageRepository
			.getPayload<{ theme: string }>(this.storageKeys.appTheme)
			.mapOnSuccess(success => success.theme)
			.successOrUndefined();
	}

}
