import { TranslocoService } from '@ngneat/transloco';
import { GeneComboboxDto } from '@nmn-communication/genes';
import { isValueDefined } from '@nmn-core/utils';
import { setTranslation } from '../../utils/localize';
import { FakeDescribedComboboxDto } from './fake-described-combobox.dto';

export interface FakeGeneComboboxDto extends FakeDescribedComboboxDto<string> {

	fullName?: string;

}

export const mapFakeGeneComboboxDtoToGeneComboboxDto = (translocoService: TranslocoService, fakeComboboxDto: FakeGeneComboboxDto): GeneComboboxDto => {
	if (!isValueDefined(fakeComboboxDto)) {
		return undefined;
	}

	return {
		id: fakeComboboxDto.id,
		displayText: setTranslation(translocoService, fakeComboboxDto.displayText),
		description: setTranslation(translocoService, fakeComboboxDto.description),
		fullName: fakeComboboxDto.fullName
	};
};
