import { Injector, Provider } from '@angular/core';
import { HealthIssueClient } from '@nmn-communication/health-issues';
import { healthIssueClientFactory } from './factories/health-issue.client.factory';

export const healthIssueProviders: Array<Provider> = [
	{
		provide: HealthIssueClient,
		useFactory: healthIssueClientFactory,
		deps: [Injector]
	}
];
