import { Failure } from '@nmn-communication/shared';
import { VaccineClient, VaccineDescribedComboboxDto, VaccineFilterDto } from '@nmn-communication/vaccines';
import { Result } from '@nmn-core/shared';
import { FakeDatabase } from 'app/modules/fakes/databases/fake.database';
import { wrapFuncCallAsFakeClientResponse } from 'app/modules/fakes/databases/fake.utils';
import { Observable } from 'rxjs';
import { VaccineFakeClientConfiguration } from '../configurations/vaccine.fake-client.configuration';

export class VaccineFakeClient extends VaccineClient {

	constructor(
		private readonly configuration: VaccineFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulkAsComboboxes(filter: VaccineFilterDto): Observable<Result<Array<VaccineDescribedComboboxDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.vaccinesTable.getComboboxesCollection(filter),
			this.configuration.serverDelayMs
		);
	}

}
