import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { GeneticTestFormConfiguration, GeneticTestFormConfigurationResolver } from '@nmn-domain/genetic-tests';
import { Observable } from 'rxjs';
import { ValueRestrictionOptional } from '../../../../modules/core/configurations/restrictions/value-restriction-optional';

export class GeneticTestFormConfigurationViaConstResolver implements GeneticTestFormConfigurationResolver {

	public resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<GeneticTestFormConfiguration> | Promise<GeneticTestFormConfiguration> | GeneticTestFormConfiguration {
		return defaultGeneticTestFormConfiguration;
	}

}

const defaultGeneticTestFormConfiguration: GeneticTestFormConfiguration = {
	restrictions: {
		date: ValueRestrictionOptional.initDate(new Date('1900-01-01T00:00:00Z'), new Date('2100-01-01T00:00:00Z')),
		comment: { controlRowsCount: 3 }
	}
};
