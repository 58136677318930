import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { DocumentTypeHttpClientConfiguration } from '../configurations/document-type.http-client.configuration';

export class DocumentTypeHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<DocumentTypeHttpClientConfiguration> {

	public build(): DocumentTypeHttpClientConfiguration {
		return new DocumentTypeHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
