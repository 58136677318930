import { Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { DocumentFakeClientConfiguration } from '../configurations/document.fake-client.configuration';
import { DocumentFakeClient } from '../services/document.fake-client';

export class DocumentFakeClientBuilder {

	private configuration: DocumentFakeClientConfiguration;

	constructor(private readonly injector: Injector) { }

	public withConfiguration(
		configuration: DocumentFakeClientConfiguration
	): DocumentFakeClientBuilder {

		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;

	}

	public build(): DocumentFakeClient {

		return new DocumentFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));

	}

}
