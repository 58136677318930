import { Injector } from '@angular/core';
import { AllergyIntoleranceVerificationStatusClient } from '@nmn-communication/allergy-intolerance-verification-statuses';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { AllergyIntoleranceVerificationStatusFakeClientBuilder } from '../builders/allergy-intolerance-verification-status.fake-client.builder';
import { AllergyIntoleranceVerificationStatusFakeClientConfigurationBuilder } from '../builders/allergy-intolerance-verification-status.fake-client.configuration.builder';
import { AllergyIntoleranceVerificationStatusHttpClientBuilder } from '../builders/allergy-intolerance-verification-status.http-client.builder';
import { AllergyIntoleranceVerificationStatusHttpClientConfigurationBuilder } from '../builders/allergy-intolerance-verification-status.http-client.configuration.builder';
import { AllergyIntoleranceVerificationStatusFakeClient } from '../services/allergy-intolerance-verification-status.fake-client';
import { AllergyIntoleranceVerificationStatusHttpClient } from '../services/allergy-intolerance-verification-status.http-client';

export const allergyIntoleranceVerificationStatusClientFactory = (
	injector: Injector
): AllergyIntoleranceVerificationStatusClient => {
	const useFake = environment?.api?.resources?.allergyIntoleranceVerificationStatuses?.useFake ||
		!isValueDefined(environment?.api?.resources?.allergyIntoleranceVerificationStatuses?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		allergyIntoleranceVerificationStatusFakeClientFactory(injector) :
		allergyIntoleranceVerificationStatusHttpClientFactory(injector);
};

export const allergyIntoleranceVerificationStatusFakeClientFactory = (
	injector: Injector
): AllergyIntoleranceVerificationStatusFakeClient => {
	const configuration = new AllergyIntoleranceVerificationStatusFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.allergyIntoleranceVerificationStatuses.serverDelayMs)
		.build();

	return new AllergyIntoleranceVerificationStatusFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const allergyIntoleranceVerificationStatusHttpClientFactory = (
	injector: Injector
): AllergyIntoleranceVerificationStatusHttpClient => {
	const configuration = new AllergyIntoleranceVerificationStatusHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.allergyIntoleranceVerificationStatuses?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.allergyIntoleranceVerificationStatuses.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.allergyIntoleranceVerificationStatuses?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new AllergyIntoleranceVerificationStatusHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
