import { TranslocoService } from '@ngneat/transloco';
import {
	GeneticCardDto, GeneticCardFindParameterDto, GeneticTestCreateParameterDto, GeneticTestDeleteBulkParameter,
	GeneticTestDto, GeneticTestFilterDto, GeneticTestFindParameterDto, GeneticTestResultCreateParameterDto, GeneticTestUpdateParameterDto
} from '@nmn-communication/genetic-tests';
import { Guid, isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { PagedCollectionDto, PageOptionsDto } from '../../core/clients';
import { FakeDatabase } from '../databases/fake.database';
import { getPagedCollectionWithoutItemFilter } from '../databases/fake.utils';
import { FakeLocalizableEntity } from '../models/fake-localizable-entity';
import { setLocalizableEntity, setTranslation } from '../utils/localize';

export class GeneticTestFakeTable {

	private readonly database: FakeDatabase;
	// TODO: fix this later (created to insert from file import)
	public readonly data: Array<GeneticTestFakeRecord>;

	constructor(
		database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.database = database;
		this.data = [...initialData];
	}

	public getGeneticCard(parameter: GeneticCardFindParameterDto): GeneticCardDto {
		const patientId = this.database.patientsTable.get({ alias: parameter.patientId })?.id;
		const geneticTests = this.data
			.filter(record => record.patientId === patientId)
			.map(this.mapGeneticTestFromRecordToDto.bind(this));

		return {
			patientId,
			geneticTests,
			geneticFileParseRequest: this.database.geneticFileParseRequestsTable
				.get({
					id: '10000000-0000-0000-0000-000000000001',
					patientId: patientId!,
					isActive: true
				})
		};
	}

	public get(findParameter: GeneticTestFindParameterDto): GeneticTestDto {
		const record = this.data.find((item: GeneticTestFakeRecord) => findPredicate(item, findParameter));

		return isValueDefined(record) ? this.mapGeneticTestFromRecordToDto(record!) : undefined!;
	}

	public getPagedCollection(pageOptions: PageOptionsDto<GeneticTestFilterDto>): PagedCollectionDto<GeneticTestDto, GeneticTestFilterDto> {
		return getPagedCollectionWithoutItemFilter(
			this.translocoService,
			this.data,
			pageOptions,
			this.mapGeneticTestFromRecordToDto.bind(this),
			filterPredicate,
			compareFn
		);
	}

	public create(parameter: GeneticTestCreateParameterDto): string {
		const record = this.mapFromCreateParameterToRecord(this.translocoService, parameter);
		this.data.push(record);

		return record.id;
	}

	public update(findParameter: GeneticTestFindParameterDto, updateParameter: GeneticTestUpdateParameterDto): void {
		const record = this.data.find((item: GeneticTestFakeRecord) => findPredicate(item, findParameter));

		if (!isValueDefined(record)) {
			throw new Error('Genetic Test to update was not found');
		}

		this.applyUpdateParameter(this.translocoService, record!, updateParameter);
	}

	public delete(findParameter: GeneticTestFindParameterDto): void {
		const index = this.data.findIndex((item: GeneticTestFakeRecord) => findPredicate(item, findParameter));

		if (index >= 0) {
			this.data.splice(index, 1);
		}
	}

	public deleteBulk(parameter: GeneticTestDeleteBulkParameter): void {
		for (const id of parameter.ids) {
			this.delete({ id, patientId: parameter.patientId });
		}
	}

	private mapGeneticTestFromRecordToDto(record: GeneticTestFakeRecord): GeneticTestDto {
		return {
			id: record.id,
			patientId: record.patientId,
			date: record.date,
			gene: this.database.genesTable.findAsCombobox({ id: record.geneId }),
			geneticTestResults: record.geneticTestResults.map(
				item => {
					return {
						haplotype: this.database.geneVariantsTable.findAsCombobox({ id: item.haplotypeId }),
						diplotype: isStringDefinedAndNotEmpty(item.diplotypeId) ? this.database.geneVariantsTable.findAsCombobox({ id: item.diplotypeId }) : undefined,
						pharmacogenomicTestResultCustom: item.pharmacogenomicTestResultCustom
					};
				}
			),
			comment: setTranslation(this.translocoService, record.comment),
			certificateIssuer: setTranslation(this.translocoService, record.certificateIssuer),
			certificateUid: record.certificateUid,
			createdOn: record.createdOn,
			updatedOn: record.updatedOn,
			geneticTestCreationType: record.geneticTestCreationType
		};
	}

	private mapFromCreateParameterToRecord(translocoService: TranslocoService, parameter: GeneticTestCreateParameterDto): GeneticTestFakeRecord {
		return {
			id: Guid.newGuid(),
			patientId: parameter.patientId,
			date: parameter.date,
			geneId: parameter.geneId,
			geneticTestResults: parameter.geneticTestResults.map(item => this.createGeneticTestResultByParameter(item)),
			comment: setLocalizableEntity(parameter.comment, translocoService.getActiveLang()),
			certificateIssuer: setLocalizableEntity(parameter.certificateIssuer, translocoService.getActiveLang()),
			certificateUid: parameter.certificateUid,
			createdOn: (new Date()).toISOString(),
			updatedOn: undefined,
			geneticTestCreationType: 'manual'
		};
	}

	private applyUpdateParameter(translocoService: TranslocoService, record: GeneticTestFakeRecord, updateParameter: GeneticTestUpdateParameterDto): void {
		const operationDateTime = (new Date()).toISOString();

		record.patientId = updateParameter.patientId;
		record.date = updateParameter.date;
		record.geneId = updateParameter.geneId;
		record.geneticTestResults = updateParameter.geneticTestResults.map(item => this.createGeneticTestResultByParameter(item));
		record.comment = setLocalizableEntity(updateParameter.comment, translocoService.getActiveLang());
		record.certificateIssuer = setLocalizableEntity(updateParameter.certificateIssuer, translocoService.getActiveLang());
		record.certificateUid = updateParameter.certificateUid;
		record.updatedOn = operationDateTime;
	}

	private createGeneticTestResultByParameter(parameter: GeneticTestResultCreateParameterDto): any {
		return {
			haplotypeId: isStringDefinedAndNotEmpty(parameter.diplotypeId) ? this.getHaplotypeIdByDiplotypeId(parameter.diplotypeId) : parameter.haplotypeId,
			diplotypeId: parameter.diplotypeId,
			pharmacogenomicTestResultCustom: parameter.pharmacogenomicTestResultCustom
		};
	}

	private getHaplotypeIdByDiplotypeId(diplotypeId: string): string {
		const diplotype = this.database.geneVariantsTable.getAsCombobox({ id: diplotypeId });

		return `${diplotype.gene.id}${diplotype.allele1}`;
	}

}

const filterPredicate = (item: GeneticTestDto, filter: GeneticTestFilterDto): boolean => {
	let result = true;

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result &&
			(
				item.date.indexOf(filter.searchPattern!) >= 0 ||
				item.createdOn.indexOf(filter.searchPattern!) >= 0 ||
				item.updatedOn?.indexOf(filter.searchPattern!) >= 0 ||
				item.gene?.displayText?.toLowerCase()?.indexOf(filter.searchPattern!.toLowerCase()) >= 0 ||
				item.gene?.description?.toLowerCase()?.indexOf(filter.searchPattern!.toLowerCase()) >= 0
			);
	}

	if (result && isArrayDefinedAndNotEmpty(filter.ignoreIds)) {
		result = result && filter.ignoreIds!.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isArrayDefinedAndNotEmpty(filter.geneticTestCreationTypes)) {
		result = result && filter.geneticTestCreationTypes.indexOf(item.geneticTestCreationType) > -1;
	}

	result = result && isValueDefined(filter.patientId);

	return result;
};

/* eslint-disable  */
/* eslint-disable complexity */
const compareFn = (item1: GeneticTestDto, item2: GeneticTestDto, sorting: string): number => {
	if (sorting === 'id asc') {
		return item1.id > item2.id ? 1 : item1.id < item2.id ? -1 : 0;
	} else if (sorting === 'id desc') {
		return item1.id < item2.id ? 1 : item1.id > item2.id ? -1 : 0;
	} else if (sorting === 'gene asc') {
		return item1.gene.displayText > item2.gene.displayText ? 1 :
			item1.gene.displayText < item2.gene.displayText ? -1 : 0;
	} else if (sorting === 'gene desc') {
		return item1.gene.displayText < item2.gene.displayText ? 1 :
			item1.gene.displayText > item2.gene.displayText ? -1 : 0;
	} else if (sorting === 'date asc') {
		return item1.date > item2.date ? 1 : item1.date < item2.date ? -1 : 0;
	} else if (sorting === 'date desc') {
		return item1.date < item2.date ? 1 : item1.date > item2.date ? -1 : 0;
	} else if (sorting === 'lastModifiedOn asc') {
		const lastModifiedOnItem1 = isValueDefined(item1.updatedOn) && item1.updatedOn > item1.createdOn ?
			item1.updatedOn : item1.createdOn;
		const lastModifiedOnItem2 = isValueDefined(item2.updatedOn) && item2.updatedOn > item2.createdOn ?
			item2.updatedOn : item2.createdOn;

		return lastModifiedOnItem1 > lastModifiedOnItem2 ? 1 : lastModifiedOnItem1 < lastModifiedOnItem2 ? -1 : 0;
	} else if (sorting === 'lastModifiedOn desc') {
		const lastModifiedOnItem1 = isValueDefined(item1.updatedOn) && item1.updatedOn > item1.createdOn ?
			item1.updatedOn : item1.createdOn;
		const lastModifiedOnItem2 = isValueDefined(item2.updatedOn) && item2.updatedOn > item2.createdOn ?
			item2.updatedOn : item2.createdOn;

		return lastModifiedOnItem1 < lastModifiedOnItem2 ? 1 : lastModifiedOnItem1 > lastModifiedOnItem2 ? -1 : 0;
	}

	return 0;
};
/* eslint-enable complexity */
/* eslint-enable */

const findPredicate = (item: GeneticTestFakeRecord, findParameter: GeneticTestFindParameterDto): boolean => item.id === findParameter.id;

interface GeneticTestFakeRecord {

	id: string;
	patientId: string;
	date: string;
	geneId: string;
	geneticTestResults: Array<{
		haplotypeId?: string;
		diplotypeId?: string;
		pharmacogenomicTestResultCustom?: string;
	}>;
	comment?: FakeLocalizableEntity;
	certificateIssuer?: FakeLocalizableEntity;
	certificateUid?: string;
	createdOn: string;
	updatedOn?: string;
	geneticTestCreationType: string;

}

// GeneticTestFakeRecord (initial data) has id mask 00000000-0000-0000-0400-************
const initialData: Array<GeneticTestFakeRecord> = [
	{
		id: '00000000-0000-0000-0400-000000000001',
		patientId: '00000000-0000-0000-0002-000000000001',
		date: '2019-02-02T00:00:00',
		geneId: 'CYP2C19',
		geneticTestResults: [{ haplotypeId: 'CYP2C19*2', diplotypeId: 'CYP2C19*2*2' }],
		comment: null,
		certificateIssuer: {
			en: 'Ministry of Health, Welfare and Sports of the Netherlands',
			uk: 'Міністерство охорони здоров\'я, соціального забезпечення та спорту Нідерландів'
		},
		certificateUid: 'EU:NE:FJSJBVLDK83H8E0D90001000',
		createdOn: '2021-02-02T12:00:00',
		updatedOn: undefined,
		geneticTestCreationType: 'manual'
	},
	{
		id: '00000000-0000-0000-0400-000000000002',
		patientId: '00000000-0000-0000-0002-000000000001',
		date: '2018-02-02T00:00:00',
		geneId: 'CYP2C9',
		geneticTestResults: [
			{ haplotypeId: 'CYP2C9*9', diplotypeId: 'CYP2C9*9*10' },
			{ haplotypeId: 'CYP2C9*2' }
		],
		comment: null,
		certificateIssuer: {
			en: 'Ministry of Health, Welfare and Sports of the Netherlands',
			uk: 'Міністерство охорони здоров\'я, соціального забезпечення та спорту Нідерландів'
		},
		certificateUid: 'EU:NE:FJSJBVLDK83H8E0D12345010',
		createdOn: '2021-03-02T12:00:00',
		updatedOn: '2022-01-03T12:00:00',
		geneticTestCreationType: 'manual'
	},
	{
		id: '00000000-0000-0000-0400-000000000003',
		patientId: '00000000-0000-0000-0002-000000000001',
		date: '2016-02-02T00:00:00',
		geneId: 'CYP2D6',
		geneticTestResults: [{ haplotypeId: 'CYP2D6*10' }],
		comment: {
			en: 'Decreased function',
			uk: 'Зменшена функція'
		},
		certificateIssuer: {
			en: 'Ministry of Health, Welfare and Sports of the Netherlands',
			uk: 'Міністерство охорони здоров\'я, соціального забезпечення та спорту Нідерландів'
		},
		certificateUid: 'EU:NE:FJSJBVLDK83H8E0D12345001',
		createdOn: '2021-05-02T12:00:00',
		updatedOn: undefined,
		geneticTestCreationType: 'manual'
	},
	{
		id: '00000000-0000-0000-0400-000000000004',
		patientId: '00000000-0000-0000-0002-000000000001',
		date: '2016-02-02T00:00:00',
		geneId: 'SLCO1B1',
		geneticTestResults: [{ haplotypeId: 'SLCO1B1*20', pharmacogenomicTestResultCustom: 'Increased Function (custom)' }],
		comment: null,
		certificateIssuer: {
			en: 'Ministry of Health, Welfare and Sports of the Netherlands',
			uk: 'Міністерство охорони здоров\'я, соціального забезпечення та спорту Нідерландів'
		},
		certificateUid: 'EU:NE:FJSJBVLDK83H8E0D12345001',
		createdOn: '2021-05-02T12:00:00',
		updatedOn: undefined,
		geneticTestCreationType: 'manual'
	},
	{
		id: '00000000-0000-0000-0400-000000000005',
		patientId: '00000000-0000-0000-0002-000000000001',
		date: '2016-02-02T00:00:00',
		geneId: 'SPATS2L',
		geneticTestResults: [{ haplotypeId: 'SPATS2LT', diplotypeId: 'SPATS2LTT' }],
		comment: null,
		certificateIssuer: {
			en: 'Ministry of Health, Welfare and Sports of the Netherlands',
			uk: 'Міністерство охорони здоров\'я, соціального забезпечення та спорту Нідерландів'
		},
		certificateUid: 'EU:NE:FJSJBVLDK83H8E0D12345001',
		createdOn: '2023-05-02T12:00:00',
		updatedOn: undefined,
		geneticTestCreationType: 'manual'
	}
];
