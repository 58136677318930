import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { LoginDto } from '../../login/models/login.dto';
import { UserProfileAttachFacebookAuthUpdateParameter } from '../models/user-profile-attach-facebook-auth.update-parameter';
import { UserProfileAttachGoogleAuthUpdateParameter } from '../models/user-profile-attach-google-auth.update-parameter';
import { UserProfileAttachRegularAuthUpdateParameter } from '../models/user-profile-attach-regular-auth.update.parameter';
import { UserProfilePasswordUpdateParameter } from '../models/user-profile-password.update-parameter';
import { UserProfileDeleteParameter } from '../models/user-profile.delete-parameter';
import { UserProfileFindParameter } from '../models/user-profile.find-parameter';
import { UserProfileRevokeDeleteParameter } from '../models/user-profile.revoke-delete-parameter';
import { UserProfileUpdateParameter } from '../models/user-profile.update-parameter';

export abstract class UserProfileClient {

	public abstract get(parameter: UserProfileFindParameter): Observable<Result<LoginDto, Failure>>;

	public abstract getCurrent(): Observable<Result<LoginDto, Failure>>;

	public abstract updateCurrent(parameter: UserProfileUpdateParameter): Observable<Result<LoginDto, Failure>>;

	public abstract changePasswordCurrent(parameter: UserProfilePasswordUpdateParameter): Observable<Result<LoginDto, Failure>>;

	public abstract deleteCurrent(parameter: UserProfileDeleteParameter): Observable<Result<LoginDto, Failure>>;

	public abstract revokeDeleteCurrent(parameter: UserProfileRevokeDeleteParameter): Observable<Result<LoginDto, Failure>>;

	public abstract attachRegularAuthFlow(parameter: UserProfileAttachRegularAuthUpdateParameter): Observable<Result<LoginDto, Failure>>;

	public abstract attachGoogleAuthFlow(parameter: UserProfileAttachGoogleAuthUpdateParameter): Observable<Result<LoginDto, Failure>>;

	public abstract attachFacebookAuthFlow(parameter: UserProfileAttachFacebookAuthUpdateParameter): Observable<Result<LoginDto, Failure>>;

}
