import { DoctorEncounterCreateParameterDto, DoctorEncounterUpdateParameterDto } from '@nmn-communication/doctor-encounters';
import { isValueDefined, mapDateToServerFormat } from '@nmn-core/utils';
import { DoctorEncounterCreateCommand, DoctorEncounterUpdateCommand } from '@nmn-domain/doctor-encounters';
import { mapModelToDto as mapClinicDtoToModel } from '../../clinics/factories/clinic.factory';
import { mapModelToDto as mapDoctorDtoToModel } from '../../doctors/factories/doctor.factory';
import { mapCreateCommandToParameter as mapDocumentCreateCommandToParameter } from '../../documents/factories/document.factory';
import { mapCreateCommandToCreateParameter as mapTakenMedicationCreateCommandToParameter } from '../../taken-medications/factories/taken-medication.factory';

export const mapCreateCommandToParameter = (command: DoctorEncounterCreateCommand): DoctorEncounterCreateParameterDto => (
	{
		patientId: command.patientId,
		diseaseIds: command.diseaseIds,
		date: mapDateToServerFormat(command.date),
		status: command.status,
		comment: command.comment,
		doctor: isValueDefined(command.doctor) ? mapDoctorDtoToModel(command.doctor) : undefined,
		clinic: isValueDefined(command.clinic) ? mapClinicDtoToModel(command.clinic) : undefined,
		healthIssueId: command.healthIssueId,
		takenMedicationIds: command.takenMedicationIds,
		takenMedications: command.takenMedications.map(mapTakenMedicationCreateCommandToParameter),
		documentIds: command.documentIds,
		documents: command.documents.map(mapDocumentCreateCommandToParameter)
	}
);

export const mapUpdateCommandToParameter = (command: DoctorEncounterUpdateCommand): DoctorEncounterUpdateParameterDto => (
	{
		patientId: command.patientId,
		diseaseIds: command.diseaseIds,
		date: mapDateToServerFormat(command.date),
		status: command.status,
		comment: command.comment,
		doctor: isValueDefined(command.doctor) ? mapDoctorDtoToModel(command.doctor) : undefined,
		clinic: isValueDefined(command.clinic) ? mapClinicDtoToModel(command.clinic) : undefined,
		healthIssueId: command.healthIssueId,
		takenMedicationIds: command.takenMedicationIds,
		takenMedications: command.takenMedications.map(mapTakenMedicationCreateCommandToParameter),
		documentIds: command.documentIds,
		documents: command.documents.map(mapDocumentCreateCommandToParameter)
	}
);
