import { ContactsDto, SendMessageParameterDto } from '@nmn-communication/contacts';
import { ContactsModel, MainContactsModel, OtherContactsModel, SendMessageCommand, SocialMainContactsModel, SocialOtherContactsModel, СommonMainContactsModel } from '@nmn-domain/contacts';

export const mapSendMessageCommandToParameter = (parameter: SendMessageCommand): SendMessageParameterDto => (
	{
		contactEmail: parameter.contactEmail,
		fullName: parameter.fullName,
		message: parameter.message
	}
);

export const mapContactsDtoToModel = (dto: ContactsDto): ContactsModel => (
	new ContactsModel(
		new MainContactsModel(
			new СommonMainContactsModel(
				dto.main.common.email,
				dto.main.common.mobile
			),
			new SocialMainContactsModel(
				dto.main.social.twitter,
				dto.main.social.facebook,
				dto.main.social.medium,
				dto.main.social.linkedin,
				dto.main.social.telegram
			)
		),
		new OtherContactsModel(
			new SocialOtherContactsModel(
				dto.other.social.twitter,
				dto.other.social.facebook,
				dto.other.social.medium,
				dto.other.social.linkedin,
				dto.other.social.youtube,
				dto.other.social.telegram
			)
		)
	)
);
