import { Result } from '@nmn-core/shared';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { CommandResult, EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { DocumentCreateCommand } from '../models/commands/document.create.command';
import { DocumentDeleteAttachmentCommand } from '../models/commands/document.delete-attachment.command';
import { DocumentDeleteBulkCommand } from '../models/commands/document.delete-bulk.command';
import { DocumentDeleteCommand } from '../models/commands/document.delete.command';
import { DocumentUpdateCommand } from '../models/commands/document.update.command';

export abstract class DocumentsCommandHandlerService {

	public abstract handleCreateCommand(
		command: DocumentCreateCommand
	): Observable<Result<CommandResult<DocumentCreateCommand, string>, FailureModel>>;

	public abstract handleDeleteBulkCommand(
		command: DocumentDeleteBulkCommand
	): Observable<Result<EmptyCommandResult<DocumentDeleteBulkCommand>, FailureModel>>;

	public abstract handleDeleteCommand(
		command: DocumentDeleteCommand
	): Observable<Result<EmptyCommandResult<DocumentDeleteCommand>, FailureModel>>;

	public abstract handleDeleteAttachmentCommand(
		command: DocumentDeleteAttachmentCommand
	): Observable<Result<EmptyCommandResult<DocumentDeleteAttachmentCommand>, FailureModel>>;

	public abstract handleUpdateCommand(
		command: DocumentUpdateCommand
	): Observable<Result<EmptyCommandResult<DocumentUpdateCommand>, FailureModel>>;

}
