import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { applicationBehaviorProviders } from './application-behavior/providers';

@NgModule({
	declarations: [],
	providers: [
		...applicationBehaviorProviders
	],
	imports: [
		CommonModule
	]
})
export class MiddlewareModule { }
