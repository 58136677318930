import { AbstractControl, NgForm, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipList } from '@angular/material/chips';
import { ComboboxModel, FailureFormDirectoryModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { isStringDefinedAndNotEmpty } from './string.utils';
import { isValueDefined } from './value.utils';

export const resetControlIfValueNotInComboboxOptions = (control: AbstractControl, oprions: Array<ComboboxModel>): void => {
	resetControlIfValueNotInOptions<ComboboxModel>(control, oprions, (controlValue, option) => controlValue === option.id);
};

export const resetControlIfValueNotInOptions = <TOption>(
	control: AbstractControl,
	oprions: Array<TOption>,
	valueComparer: (controlValue: any, option: TOption) => boolean
): void => {
	const isResetRequired = isValueDefined(control.value) &&
		oprions.findIndex(option => valueComparer(control.value, option)) < 0;

	if (isResetRequired) {
		control.reset();
	}
};

export const filterOptions = <TComboboxModel extends ComboboxModel>(options: Array<TComboboxModel>, searchPattern: string): Array<TComboboxModel> => {
	const searchPatternNormalized = isStringDefinedAndNotEmpty(searchPattern) ? searchPattern.toLowerCase() : '';

	return options.filter(option => option.displayText.toLowerCase().indexOf(searchPatternNormalized) >= 0);
};

export const displayComboboxOptionFn = <TComboboxModel extends ComboboxModel>(option?: TComboboxModel): string | undefined =>
	isValueDefined(option) ? option.displayText : undefined;

export const mapToSearchPattern = (value: ComboboxModel | string): string =>
	value instanceof ComboboxModel ? value.displayText : value;

export const selectOptionOnAutocompleteSelected = <TComboboxModel extends ComboboxModel>(
	event: MatAutocompleteSelectedEvent,
	formGroup: UntypedFormGroup,
	controlPath: string,
	availableOptions: Array<TComboboxModel>
): void => {
	const value = event?.option?.value;

	if (isValueDefined(value) && availableOptions.findIndex(item => item.id === value.id) >= 0) {
		formGroup.get(controlPath).setValue(value.id);
	}
};

export const createAutocompleteOptionsObservable = <TComboboxModel extends ComboboxModel>(
	formGroup: UntypedFormGroup,
	controlPath: string,
	options: Array<TComboboxModel>
): Observable<Array<TComboboxModel>> => {
	const initialSearchPattern = isStringDefinedAndNotEmpty(formGroup?.get(controlPath)?.value?.displayText)
		? formGroup?.get(controlPath)?.value?.displayText
		: isStringDefinedAndNotEmpty(formGroup?.get(controlPath)?.value) ? formGroup?.get(controlPath)?.value : '';

	return formGroup
		.get(controlPath).valueChanges
		.pipe(
			startWith(initialSearchPattern),
			map(mapToSearchPattern),
			map(searchPattern => filterOptions(options, searchPattern))
		);
};

export const setChipListErrorState = (
	form: NgForm,
	chipList: MatChipList,
	searchPatternCtrl: AbstractControl,
	formCtrl: AbstractControl,
	formCtrlName: string,
	formFailureDirections: FailureFormDirectoryModel
): void => {
	const shouldAct = form?.submitted ||
		!formCtrl.pristine || formCtrl.touched ||
		!searchPatternCtrl.pristine || searchPatternCtrl.touched;

	if (shouldAct) {
		const errorState = !formCtrl.valid;

		if (
			!isValueDefined(chipList) &&
			!isValueDefined(formFailureDirections) &&
			formFailureDirections.isCtrlPresent(formCtrlName)
		) {
			chipList.errorState = errorState || !formCtrl.valid;
		}

		chipList.errorState = errorState;
	}
};
