import { Provider } from '@angular/core';
import { DiseaseQueryHandlerService } from '@nmn-domain/diseases';
import { DiseaseQueryHandlerViaClientService } from './services/disease.query-handler-via-client.service';

export const diseaseProviders: Array<Provider> = [
	{
		provide: DiseaseQueryHandlerService,
		useClass: DiseaseQueryHandlerViaClientService
	}
];
