import { InformationBlockModel } from '../../../../../modules/core/models/shared/information-block.model';

export class DocumentsInformationBlockModel extends InformationBlockModel {

	public readonly count: number;
	public readonly lastAddedDisplayText: string;
	public readonly lastAddedDate: Date;

	constructor(
		fillingUpPercentage: number,
		count: number,
		lastAddedDisplayText: string,
		lastAddedDate: Date
	) {

		super(fillingUpPercentage);

		// TODO: add Guard check

		this.count = count;
		this.lastAddedDisplayText = lastAddedDisplayText;
		this.lastAddedDate = lastAddedDate;

	}

}
