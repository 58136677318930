import { ComboboxModel } from '@nmn-domain/shared';

export class PopulationComboboxModel extends ComboboxModel {

	constructor(
		id: string,
		displayText: string
	) {
		super(id, displayText);
	}

	public static createDisplayOptionFn(): (option: PopulationComboboxModel) => string | undefined {
		return option => option.displayText;
	}

}
