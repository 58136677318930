import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { applicationStoragesProviders } from './application-storages/providers';

@NgModule({
	declarations: [],
	providers: [
		...applicationStoragesProviders
	],
	imports: [
		CommonModule
	]
})
export class CoreModule { }
