import { Provider } from '@angular/core';
import { GeneQueryHandlerService } from '@nmn-domain/genes';
import { GeneQueryHandlerViaClientService } from './services/gene.query-handler-via-client.service';

export const geneProviders: Array<Provider> = [
	{
		provide: GeneQueryHandlerService,
		useClass: GeneQueryHandlerViaClientService
	}
];
