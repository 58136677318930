import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { PagedCollectionDto, PageOptionsDto } from '../../shared';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { DoctorEncounterComboboxDto } from '../models/doctor-encounter-combobox.dto';
import { DoctorEncounterFilterDto } from '../models/doctor-encounter-filter.dto';
import { DoctorEncounterDto } from '../models/doctor-encounter.dto';
import { DoctorEncounterCreateParameterDto } from '../models/parameters/doctor-encounter.create-parameter.dto';
import { DoctorEncounterFindParameterDto } from '../models/parameters/doctor-encounter.find-parameter.dto';
import { DoctorEncounterUpdateParameterDto } from '../models/parameters/doctor-encounter.update-parameter.dto';

export abstract class DoctorEncounterClient {

	public abstract getBulkAsComboboxes(
		filter: DoctorEncounterFilterDto
	): Observable<Result<Array<DoctorEncounterComboboxDto>, Failure>>;

	public abstract getBulk(
		parameter: PageOptionsDto<DoctorEncounterFilterDto>
	): Observable<Result<PagedCollectionDto<DoctorEncounterDto, DoctorEncounterFilterDto>, Failure>>;

	public abstract get(
		parameter: DoctorEncounterFindParameterDto
	): Observable<Result<DoctorEncounterDto, Failure>>;

	public abstract create(
		parameter: DoctorEncounterCreateParameterDto
	): Observable<Result<string, Failure>>;

	public abstract update(
		findParameter: DoctorEncounterFindParameterDto,
		updateParameter: DoctorEncounterUpdateParameterDto
	): Observable<EmptyResult<Failure>>;

	public abstract delete(
		parameter: DoctorEncounterFindParameterDto
	): Observable<EmptyResult<Failure>>;

}
