import { Injectable } from '@angular/core';
import { MedicationClient } from '@nmn-communication/medications';
import { Result } from '@nmn-core/shared';
import { MedicationComboboxModel, MedicationFilterModel, MedicationQueryHandlerService } from '@nmn-domain/medications';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapDtoArrayResultToModelArrayResult } from '../../shared/factories/pagination.factory';
import { mapFilterModelToDto, mapMedicationComboboxDtoToModel } from '../factories/medication.factory';

@Injectable()
export class MedicationQueryHandlerViaClientService extends MedicationQueryHandlerService {

	constructor(
		private readonly client: MedicationClient
	) {
		super();
	}

	public getAsDescribedComboboxes(filter: MedicationFilterModel): Observable<Result<Array<MedicationComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapMedicationComboboxDtoToModel))
			);
	}

}
