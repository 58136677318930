import { Injector } from '@angular/core';
import { AzureStorageClientFactory } from '@nmn-communication/azure-file-storage';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { AzureStorageClientFakeFactory } from './azure-storage-client.fake-factory';
import { AzureStorageClientHttpFactory } from './azure-storage-client.http-factory';

export const azureStorageHttpClientFactoryFactory = (
	injector: Injector
): AzureStorageClientFactory => {
	const useFake = environment?.api?.resources?.azureStorage?.useFake ||
		!isValueDefined(environment?.api?.resources?.azureStorage?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		azureStorageFakeFactoryFactory(injector) :
		azureStorageHttpFactoryFactory(injector);
};

export const azureStorageFakeFactoryFactory = (
	_: Injector
): AzureStorageClientFakeFactory => {

	return new AzureStorageClientFakeFactory();
};

export const azureStorageHttpFactoryFactory = (
	_: Injector
): AzureStorageClientHttpFactory => {
	return new AzureStorageClientHttpFactory();
};
