export class UserProfileAttachGoogleAuthCommand {

	public idToken: string;

	constructor(
		idToken: string
	) {
		this.idToken = idToken;
	}

}
