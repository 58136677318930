import { isArrayDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { Permission } from '../../permissions/models/permission.enum';

export class TokenInfoModel {

	public readonly accessToken: string;
	public readonly identityToken: string;
	public readonly refreshToken: string;
	public readonly tokenType: string;

	constructor(
		accessToken: string,
		identityToken: string,
		refreshToken: string,
		tokenType: string
	) {
		// TODO: Guard check on defined
		this.accessToken = accessToken;
		this.identityToken = identityToken;
		this.refreshToken = refreshToken;
		this.tokenType = tokenType;
	}

	public hasPermission(permission: Permission): boolean {
		if (!isValueDefined(permission) || permission === Permission.Undefined) {
			return false;
		}

		// TODO: implement check permission via accessToken value
		return true;
	}

	public hasPermissions(permissions: Array<Permission>): boolean {
		if (!isArrayDefinedAndNotEmpty(permissions)) {
			return true;
		}

		return permissions.every(this.hasPermission.bind(this));
	}

}
