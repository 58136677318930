import { GeneticTestResultFormOutModel } from './genetic-test-result-form.out-model';

export class GeneticTestFormOutModel {

	public readonly patientId: string;
	public readonly date: Date;
	public readonly geneId: string;
	public readonly geneticTestResults: Array<GeneticTestResultFormOutModel>;
	public readonly comment: string | undefined;
	public readonly certificateIssuer: string | undefined;
	public readonly certificateUid: string | undefined;

	constructor(
		patientId: string,
		date: Date,
		geneId: string,
		geneticTestResults: Array<GeneticTestResultFormOutModel>,
		comment: string | undefined,
		certificateIssuer: string | undefined,
		certificateUid: string | undefined
	) {
		//TODO: Guard check on defined

		this.patientId = patientId;
		this.date = date;
		this.geneId = geneId;
		this.geneticTestResults = geneticTestResults;
		this.comment = comment;
		this.certificateIssuer = certificateIssuer;
		this.certificateUid = certificateUid;
	}

}
