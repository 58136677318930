import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FailureModel } from '../../shared/failures/failure.model';
import { TypeOfMedicationComboboxModel } from '../models/type-of-medication-combobox.model';
import { TypeOfMedicationFilterModel } from '../models/type-of-medication-filter.model';

export abstract class TypeOfMedicationQueryHandlerService {

	public abstract getAsDescribedComboboxes(
		query: TypeOfMedicationFilterModel
	): Observable<Result<Array<TypeOfMedicationComboboxModel>, FailureModel>>;

}
