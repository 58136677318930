import { environment } from 'environments/environment';

export class LoginFormInModel {

	public readonly login?: string;
	public readonly password?: string;
	public readonly isPersistent?: boolean;

	private constructor(
		login: string | undefined,
		password: string | undefined,
		isPersistent: boolean | undefined
	) {
		this.login = login;
		this.password = password;
		this.isPersistent = isPersistent;
	}

	public static createEmpty = (): LoginFormInModel => {
		if (!environment.production) {
			return new LoginFormInModel(
				'john.james.hopkins@apixmed.com',
				'P@ssw0rd',
				true
			);
		}

		return new LoginFormInModel(
			undefined,
			undefined,
			false
		);
	}

}
