import { isValueDefined } from '@nmn-core/utils';

export class NmnMatTabGroupUserConfiguration {

	public selectedIndex?: string | undefined;

	constructor(
		selectedIndex: string | undefined
	) {
		this.selectedIndex = selectedIndex;
	}

	public static createWithParameters(
		config?: NmnMatTabGroupUserConfiguration
	): NmnMatTabGroupUserConfiguration {
		if (!isValueDefined(config)) {
			return new NmnMatTabGroupUserConfiguration(
				config?.selectedIndex
			);
		}

		return new NmnMatTabGroupUserConfiguration(
			config?.selectedIndex
		);
	}

	public static createDefault(): NmnMatTabGroupUserConfiguration {
		return new NmnMatTabGroupUserConfiguration(
			undefined);
	}

}

export const userConfigurationIsExpandedDefault = false;
