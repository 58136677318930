import { DatePipe } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';
import { isValueDefined } from '@nmn-core/utils';
import { MedicationType } from '../../medications/models/medication-type.enum';
import { DescribedComboboxModel } from '../../shared/comboboxes/described-combobox.model';

export class TakenMedicationDescribedComboboxModel extends DescribedComboboxModel {

	public readonly from?: Date;
	public readonly to?: Date;
	public readonly medicationType: MedicationType;

	public get isPeriodStartDefined(): boolean {
		return isValueDefined(this.from);
	}

	public get isPeriodEndDefined(): boolean {
		return isValueDefined(this.to);
	}

	constructor(
		id: string,
		displayText: string,
		description: string,
		from: Date | undefined,
		to: Date | undefined,
		medicationType: MedicationType
	) {
		super(id, displayText, description);

		// TODO: Guard check on defined
		this.from = from;
		this.to = to;
		this.medicationType = medicationType;
	}

	public static createDisplayOptionFn(
		translocoService: TranslocoService
	): (option: TakenMedicationDescribedComboboxModel) => string | undefined {
		return (option: TakenMedicationDescribedComboboxModel): string | undefined => {
			if (!isValueDefined(option)) {
				return undefined;
			}

			if (!option.isPeriodStartDefined && !option.isPeriodEndDefined) {
				return translocoService?.translate(
					'inlineViews.takenMedication.value_displayText',
					{
						displayText: option.displayText
					}
				);
			}

			const datePipe = new DatePipe(translocoService.getActiveLang());

			if (option.isPeriodStartDefined && !option.isPeriodEndDefined) {
				return translocoService?.translate(
					'inlineViews.takenMedication.value_displayText_from',
					{
						displayText: option.displayText,
						from: datePipe.transform(option.from, 'yyyy-MM-dd', undefined, 'en-US')
					}
				);
			}

			if (!option.isPeriodStartDefined && option.isPeriodEndDefined) {
				return translocoService?.translate(
					'inlineViews.takenMedication.value_displayText_to',
					{
						displayText: option.displayText,
						to: datePipe.transform(option.to, 'yyyy-MM-dd', undefined, 'en-US')
					}
				);
			}

			if (option.isPeriodStartDefined && option.isPeriodEndDefined) {
				return translocoService?.translate(
					'inlineViews.takenMedication.value_displayText_from_to',
					{
						displayText: option.displayText,
						from: datePipe.transform(option.from, 'yyyy-MM-dd', undefined, 'en-US'),
						to: datePipe.transform(option.to, 'yyyy-MM-dd', undefined, 'en-US')
					}
				);
			}

			return undefined;
		};
	}

}
