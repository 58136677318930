import { DocumentIconType } from '../../document-types/models/document-icon-type.enum';
import { DescribedComboboxModel } from '../../shared/comboboxes/described-combobox.model';

export class DocumentDescribedComboboxModel extends DescribedComboboxModel {

	public readonly type: DocumentIconType;

	constructor(
		id: string,
		displayText: string,
		description: string,
		type: DocumentIconType
	) {
		super(id, displayText, description);

		// TODO: Guard check on defined
		this.type = type;
	}

	public static createDisplayOptionFn(): (option: DocumentDescribedComboboxModel) => string | undefined {
		return option => option.displayText;
	}

}
