import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PatientModel } from '../models/patient.model';

export abstract class ActivePatientsResolver implements Resolve<Array<PatientModel>> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		routerState: RouterStateSnapshot
	): Observable<Array<PatientModel>> | Promise<Array<PatientModel>> | Array<PatientModel>;

}
