import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { DiseaseFilterModel, DiseaseQueryHandlerService } from '@nmn-domain/diseases';
import { MedicationFilterModel, MedicationQueryHandlerService } from '@nmn-domain/medications';
import { PatientTreatmentCheckerFormInModel, PatientTreatmentCheckerFormInModelResolver } from '@nmn-domain/treatment-checker';
import { QueryParameterValueForChecker, RouteParameter } from '@nmn-middleware/navigation';
import { forkJoin, map, Observable, of } from 'rxjs';

@Injectable()
export class PatientTreatmentCheckerFormInModelDefaultResolver extends PatientTreatmentCheckerFormInModelResolver {

	constructor(
		private readonly diseaseQueryHandlerService: DiseaseQueryHandlerService,
		private readonly medicationQueryHandlerService: MedicationQueryHandlerService
	) {
		super();
	}

	public resolve(
		activatedRoute: ActivatedRouteSnapshot,
		_: RouterStateSnapshot
	): Observable<PatientTreatmentCheckerFormInModel> | PatientTreatmentCheckerFormInModel {
		const patientId = activatedRoute.paramMap.get(RouteParameter.PatientAlias);

		if (!isStringDefinedAndNotEmpty(patientId)) {
			return undefined;
		}

		const diseaseIdsParam = activatedRoute.queryParamMap.get(QueryParameterValueForChecker.DiseaseIds);
		const diseaseIds = isStringDefinedAndNotEmpty(diseaseIdsParam) ? JSON.parse(diseaseIdsParam) : undefined;
		const diseasesObs$ = isArrayDefinedAndNotEmpty(diseaseIds) ?
			this.diseaseQueryHandlerService
				.getAsDescribedComboboxes(DiseaseFilterModel.createForPredefinedList(diseaseIds))
				.pipe(map(result => result.successOrDefault(() => undefined))) :
			of(undefined);

		const medicationIdsParam = activatedRoute.queryParamMap.get(QueryParameterValueForChecker.MedicationIds);
		const medicationIds = isStringDefinedAndNotEmpty(medicationIdsParam) ? JSON.parse(medicationIdsParam) : undefined;
		const medicationsObs$ = isArrayDefinedAndNotEmpty(medicationIds) ?
			this.medicationQueryHandlerService
				.getAsDescribedComboboxes(MedicationFilterModel.createForPredefinedList(medicationIds))
				.pipe(map(result => result.successOrDefault(() => undefined))) :
			of(undefined);

		return forkJoin([diseasesObs$, medicationsObs$])
			.pipe(
				map(results => {
					const diseases = results[0];
					const medications = results[1];

					return PatientTreatmentCheckerFormInModel.createForPredefinedData(patientId, diseases, medications);
				})
			);
	}

}
