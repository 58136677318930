import { isArrayDefinedAndNotEmpty } from '@nmn-core/utils';
import { PatientModel } from '../patient.model';

export class PatientContactsFormInModel {

	public readonly familyDoctorFullName: string;
	public readonly familyDoctorContacts: string;
	public readonly emergencyContactFullName: string;
	public readonly emergencyContactData: string;

	private constructor(
		familyDoctorFullName: string | undefined,
		familyDoctorContacts: string | undefined,
		emergencyContactFullName: string | undefined,
		emergencyContactPhoneNumber: string | undefined
	) {
		this.familyDoctorFullName = familyDoctorFullName;
		this.familyDoctorContacts = familyDoctorContacts;
		this.emergencyContactFullName = emergencyContactFullName;
		this.emergencyContactData = emergencyContactPhoneNumber;
	}

	public static createEmpty = (): PatientContactsFormInModel => {
		return new PatientContactsFormInModel(undefined, undefined, undefined, undefined);
	}

	public static createByPatient = (patient: PatientModel): PatientContactsFormInModel => {
		const doctor = isArrayDefinedAndNotEmpty(patient?.doctors) ?
			patient.doctors[0] :
			undefined;
		const emergencyContact = isArrayDefinedAndNotEmpty(patient?.emergencyContacts) ?
			patient.emergencyContacts[0] :
			undefined;

		return new PatientContactsFormInModel(
			doctor?.fullName,
			doctor?.contacts,
			emergencyContact?.fullName,
			emergencyContact?.data);
	}

}
