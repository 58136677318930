import { TranslocoService } from '@ngneat/transloco';
import {
	CompleteResetPasswordParameter, ConfirmEmailParameter, LoginDto, LoginParameter, LoginViaFacebookParameter, LoginViaGoogleParameter, RefreshTokenParameter, RegisterAccountParameter, TokenInfoDto,
	UserProfileAttachRegularAuthUpdateParameter, UserProfileDeleteParameter, UserProfileDto, UserProfileFindParameter, UserProfilePasswordUpdateParameter, UserProfileUpdateParameter
} from '@nmn-communication/accounts';
import { UploadedFileDto } from '@nmn-communication/file-uploader';
import { Guid, isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { FileType } from '@nmn-domain/file-uploader';
import { FakeDatabase } from '../databases/fake.database';
import { FakeLocalizableEntity } from '../models/fake-localizable-entity';
import { setLocalizableEntity, setTranslation } from '../utils/localize';

export class UserFakeTable {

	private readonly database: FakeDatabase;
	private readonly data: Array<UserFakeRecord>;

	constructor(
		database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.database = database;
		this.data = [...initialData];
	}

	public get(parameter: UserProfileFindParameter): LoginDto {
		const record = this.data.find((item: UserFakeRecord) => findPredicate(item, parameter));

		if (!isValueDefined(record)) {
			throw new Error('User was not found.');
		}

		return {
			tokenInfo: {
				accessToken: 'accessToken',
				identityToken: 'identityToken',
				refreshToken: 'refreshToken',
				tokenType: 'Bearer'
			},
			user: this.mapUserRecordToUserProfileDto(record)
		};
	}

	public getCurrent(userId: string): LoginDto {
		return this.get({ id: userId });
	}

	public updateCurrent(userId: string, parameter: UserProfileUpdateParameter): LoginDto {
		const record = this.data.find((item: UserFakeRecord) => findPredicate(item, { id: userId }));

		if (!isValueDefined(record)) {
			throw new Error('User was not found.');
		}

		applyUpdateParameter(this.translocoService.getActiveLang(), record, parameter);

		return this.get({ id: record.id });
	}

	public patchPasswordCurrent(userId: string, parameter: UserProfilePasswordUpdateParameter): LoginDto {
		if (parameter.newPassword !== parameter.confirmNewPassword) {
			throw new Error('Confirm password is wrong.');
		}

		const record = this.data.find((item: UserFakeRecord) => findPredicate(item, { id: userId }));

		if (!isValueDefined(record)) {
			throw new Error('User was not found.');
		}

		if (record.password !== parameter.oldPassword) {
			throw new Error('Old password is not correct.');
		}

		record.password = parameter.newPassword;

		return this.get({ id: record.id });
	}

	public getIdByEmail(email: string, withConfirmedEmail: boolean): string {
		const record = this.data.find((item: UserFakeRecord) => item.email === email);

		if (!isValueDefined(record)) {
			throw new Error('User with provided email was not found.');
		}

		if (withConfirmedEmail && !record.isEmailConfirmed) {
			throw new Error('Email is not confirmed.');
		}

		return record.id;
	}

	public login(parameter: LoginParameter): LoginDto {
		const record = this.data.find(
			(item: UserFakeRecord) =>
				item.username === parameter.login &&
				item.password === parameter.password &&
				item.isEmailConfirmed);

		if (!isValueDefined(record)) {
			throw new Error('Username or password is not correct.');
		}

		if (!record.isEmailConfirmed) {
			throw new Error('Email is not confirmed.');
		}

		return this.get({ id: record.id });
	}

	public loginViaGoogle(userId: string, parameter: LoginViaGoogleParameter): LoginDto {
		console.log('Fake login via Google', parameter);
		const record = this.data.find((item: UserFakeRecord) => (!isStringDefinedAndNotEmpty(userId) || item.id === userId) && item.isEmailConfirmed);

		if (!isValueDefined(record)) {
			throw new Error('Username or password is not correct.');
		}

		if (!record.isEmailConfirmed) {
			throw new Error('Email is not confirmed.');
		}

		return this.get({ id: record.id });
	}

	public loginViaFacebook(userId: string, parameter: LoginViaFacebookParameter): LoginDto {
		console.log('Fake login via Facebook', parameter);
		const record = this.data.find((item: UserFakeRecord) => (!isStringDefinedAndNotEmpty(userId) || item.id === userId) && item.isEmailConfirmed);

		if (!isValueDefined(record)) {
			throw new Error('Username or password is not correct.');
		}

		if (!record.isEmailConfirmed) {
			throw new Error('Email is not confirmed.');
		}

		return this.get({ id: record.id });
	}

	public refreshToken(_: RefreshTokenParameter): TokenInfoDto {
		return {
			accessToken: 'accessToken',
			identityToken: 'identityToken',
			refreshToken: 'refreshToken',
			tokenType: 'Bearer'
		};
	}

	public create(parameter: RegisterAccountParameter): string {
		if (parameter.password !== parameter.confirmPassword) {
			throw new Error('Confirm password is wrong.');
		}

		if (isValueDefined(this.data.find((item: UserFakeRecord) => item.email === parameter.email))) {
			throw new Error('User with this e-mail is already registered.');
		}

		const record = mapRegisterAccountParameterToUserRecord(parameter, this.translocoService.getActiveLang());

		return record.id;
	}

	public confirmEmail(parameter: ConfirmEmailParameter): void {
		const record = this.data.find(
			(item: UserFakeRecord) =>
				findPredicate(item, mapConfirmEmailParameterToUserProfileFindParameter(parameter)));

		if (!isValueDefined(record)) {
			throw new Error('User is not found.');
		}

		record.isEmailConfirmed = true;
	}

	public applyResetPassword(parameter: CompleteResetPasswordParameter): void {
		if (parameter.newPassword !== parameter.confirmNewPassword) {
			throw new Error('Confirm password is wrong.');
		}

		const record = this.data.find(
			(item: UserFakeRecord) =>
				findPredicate(item, { id: parameter.userId }));

		if (!isValueDefined(record)) {
			throw new Error('User is not found.');
		}

		if (!record.isEmailConfirmed) {
			throw new Error('Email is not confirmed. Confirm email and then try again.');
		}

		record.password = parameter.newPassword;
	}

	public delete(userId: string, parameter: UserProfileDeleteParameter): LoginDto {
		const record = this.data.find((item: UserFakeRecord) => findPredicate(item, { id: userId }));

		if (!isValueDefined(record)) {
			throw new Error('User was not found.');
		}

		if (record.password !== parameter.password) {
			throw new Error('Password is not correct.');
		}

		record.isDeletionRequested = true;
		record.deletionRequestedOn = (new Date()).toISOString();

		return this.get({ id: record.id });
	}

	public revokeDeletion(userId: string, parameter: UserProfileDeleteParameter): LoginDto {
		const record = this.data.find((item: UserFakeRecord) => findPredicate(item, { id: userId }));

		if (!isValueDefined(record)) {
			throw new Error('User was not found.');
		}

		if (record.password !== parameter.password) {
			throw new Error('Password is not correct.');
		}

		record.isDeletionRequested = false;
		record.deletionRequestedOn = undefined;

		return this.get({ id: record.id });
	}

	public attachRegularAuth(userId: string, parameter: UserProfileAttachRegularAuthUpdateParameter): LoginDto {
		const record = this.data.find((item: UserFakeRecord) => findPredicate(item, { id: userId }));

		if (!isValueDefined(record)) {
			throw new Error('User was not found.');
		}

		if (parameter.password !== parameter.confirmPassword) {
			throw new Error('Password is not correct.');
		}

		record.password = parameter.password;
		record.isRegularAuthAttached = true;

		return this.get({ id: record.id });
	}

	public attachGoogleAuth(userId: string): LoginDto {
		const record = this.data.find((item: UserFakeRecord) => findPredicate(item, { id: userId }));

		if (!isValueDefined(record)) {
			throw new Error('User was not found.');
		}

		record.isGoogleAuthAttached = true;

		return this.get({ id: record.id });
	}

	public attachFacebookAuth(userId: string): LoginDto {
		const record = this.data.find((item: UserFakeRecord) => findPredicate(item, { id: userId }));

		if (!isValueDefined(record)) {
			throw new Error('User was not found.');
		}

		record.isFacebookAuthAttached = true;

		return this.get({ id: record.id });
	}

	private mapUserRecordToUserProfileDto(record: UserFakeRecord): UserProfileDto {
		const relatedPatients = this.database.patientsTable.getShortPatientProfiles(record.id);
		const activePatient = isArrayDefinedAndNotEmpty(relatedPatients) ?
			relatedPatients.find(x => x.alias === record.activePatientAlias || x.id === record.activePatientAlias) :
			undefined;

		return {
			id: record.id,
			email: record.email,
			isEmailNotificationsEnabled: record.isEmailNotificationsEnabled,
			isPreferedBrowserTimezone: record.isPreferedBrowserTimezone,
			locale: setTranslation(this.translocoService, record.locale),
			picture: record.picture,
			timeZone: record.timezone ?? 'FLE Standard Time',
			createdOn: record.createdOn,
			activePatientId: activePatient?.id,
			isDeletionRequested: record.isDeletionRequested,
			deletionRequestedOn: record.deletionRequestedOn,
			activePatientAlias: activePatient?.alias,
			patients: relatedPatients,
			isRegularAuthAttached: record.isRegularAuthAttached,
			isGoogleAuthAttached: record.isGoogleAuthAttached,
			isFacebookAuthAttached: record.isFacebookAuthAttached,
			usedStorageCapacity: 300 * 1024 * 1024,
			userConfiguration: this.database.userConfigurationFakeTable.get(record.id),
			userSubscription: this.database.userSubscriptionFakeTable.getUserSubscription(record.id)
		};
	}

}

const findPredicate = (item: UserFakeRecord, findParameter: UserProfileFindParameter): boolean =>
	item.id === findParameter.id;

const mapRegisterAccountParameterToUserRecord = (
	parameter: RegisterAccountParameter,
	language: string
): UserFakeRecord => (
	{
		id: Guid.newGuid(),
		username: parameter.email,
		password: parameter.password,
		email: parameter.email,
		isEmailConfirmed: false,
		isEmailNotificationsEnabled: parameter.isEmailNotificationsEnabled,
		isPreferedBrowserTimezone: true,
		locale: setLocalizableEntity(parameter.locale, language),
		picture: isValueDefined(parameter.pictureId) ?
			{
				id: '00000001-0000-0000-0000-000000000001',
				createdOn: (new Date()).toISOString(),
				updatedOn: undefined,
				downloadUrl: 'https://fakeimg.pl/200x200',
				fileName: 'fake-img',
				lengthInBytes: 1,
				fileExtension: undefined,
				fileType: FileType.UserAvatar
			} :
			undefined,
		timezone: isStringDefinedAndNotEmpty(parameter.timezone) ? parameter.timezone : 'GMT+01:00',
		createdOn: (new Date()).toISOString(),
		isDeletionRequested: false,
		deletionRequestedOn: undefined,
		activePatientAlias: undefined,
		isRegularAuthAttached: true,
		isGoogleAuthAttached: false,
		isFacebookAuthAttached: false
	}
);

const mapConfirmEmailParameterToUserProfileFindParameter = (
	parameter: ConfirmEmailParameter
): UserProfileFindParameter => (
	{
		id: parameter.userId
	}
);

const applyUpdateParameter = (
	language: string,
	record: UserFakeRecord,
	updateParameter: UserProfileUpdateParameter
): void => {
	if (isValueDefined(updateParameter.isEmailNotificationsEnabled)) {
		record.isEmailNotificationsEnabled = updateParameter.isEmailNotificationsEnabled;
	}

	if (isValueDefined(updateParameter.isPreferedBrowserTimezone)) {
		record.isPreferedBrowserTimezone = updateParameter.isPreferedBrowserTimezone;
	}

	if (isStringDefinedAndNotEmpty(updateParameter.locale)) {
		record.locale = setLocalizableEntity(updateParameter.locale, language);
	}

	if (isStringDefinedAndNotEmpty(updateParameter.timezone)) {
		record.timezone = updateParameter.timezone;
	}

	if (isStringDefinedAndNotEmpty(updateParameter.activePatientAlias)) {
		record.activePatientAlias = updateParameter.activePatientAlias;
	}

	// properties that can be nullified

	if (isValueDefined(updateParameter)) {
		if (isValueDefined(updateParameter.pictureId)) {
			record.picture = {
				id: updateParameter.pictureId,
				createdOn: (new Date()).toISOString(),
				updatedOn: undefined,
				downloadUrl: 'https://fakeimg.pl/200x200',
				fileName: 'fake-img',
				lengthInBytes: 1,
				fileExtension: undefined,
				fileType: FileType.UserAvatar
			};
		} else {
			record.picture = undefined;
		}
	}
};

interface UserFakeRecord {
	id: string;
	username: string;
	password: string;
	email: string;
	isEmailConfirmed: boolean;
	isEmailNotificationsEnabled: boolean;
	isPreferedBrowserTimezone: boolean;
	picture?: UploadedFileDto;
	locale?: FakeLocalizableEntity;
	timezone?: string;
	createdOn: string;
	isDeletionRequested: boolean;
	deletionRequestedOn?: string;
	activePatientAlias: string | undefined;
	isRegularAuthAttached: boolean;
	isGoogleAuthAttached: boolean;
	isFacebookAuthAttached: boolean;
}

// UserFakeRecord (initial data) has id mask 00000000-0000-0000-0001-************
const initialData: Array<UserFakeRecord> = [
	{
		id: '00000000-0000-0000-0001-000000000001',
		username: 'john.james.hopkins@apixmed.com',
		password: 'P@ssw0rd',
		email: 'john.james.hopkins@apixmed.com',
		isEmailConfirmed: true,
		isEmailNotificationsEnabled: true,
		isPreferedBrowserTimezone: true,
		locale: {
			en: 'en',
			uk: 'uk'
		},
		picture: undefined,
		timezone: 'FLE Standard Time',
		createdOn: '2020-12-01',
		isDeletionRequested: false,
		deletionRequestedOn: undefined,
		activePatientAlias: 'john.hopkins',
		isRegularAuthAttached: true,
		isGoogleAuthAttached: false,
		isFacebookAuthAttached: false
	},
	{
		id: '00000000-0000-0000-0001-000000000002',
		username: 'unconfirmed.user@email.com',
		password: 'P@ssw0rd',
		email: 'unconfirmed.user@email.com',
		isEmailConfirmed: false,
		isEmailNotificationsEnabled: true,
		isPreferedBrowserTimezone: true,
		locale: {
			en: 'en',
			uk: 'uk'
		},
		picture: undefined,
		timezone: 'FLE Standard Time',
		createdOn: '2020-12-01',
		isDeletionRequested: false,
		deletionRequestedOn: undefined,
		activePatientAlias: 'unconfirmed.user',
		isRegularAuthAttached: true,
		isGoogleAuthAttached: false,
		isFacebookAuthAttached: false
	}
];
