import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { PagedCollectionDto, PageOptionsDto } from '../../shared';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { GeneticCardDto } from '../models/genetic-card.dto';
import { GeneticTestDto } from '../models/genetic-test.dto';
import { GeneticTestFilterDto } from '../models/genetic-test.filter.dto';
import { GeneticCardFindParameterDto } from '../models/parameters/genetic-card.find-parameter.dto';
import { GeneticTestCreateParameterDto } from '../models/parameters/genetic-test.create-parameter.dto';
import { GeneticTestDeleteBulkParameter } from '../models/parameters/genetic-test.delete-bulk.parameter.dto';
import { GeneticTestFindParameterDto } from '../models/parameters/genetic-test.find-parameter.dto';
import { GeneticTestUpdateParameterDto } from '../models/parameters/genetic-test.update-parameter.dto';

export abstract class GeneticTestClient {

	public abstract getGeneticCard(parameter: GeneticCardFindParameterDto): Observable<Result<GeneticCardDto, Failure>>;

	public abstract get(parameter: GeneticTestFindParameterDto): Observable<Result<GeneticTestDto, Failure>>;

	public abstract getBulk(parameter: PageOptionsDto<GeneticTestFilterDto>): Observable<Result<PagedCollectionDto<GeneticTestDto, GeneticTestFilterDto>, Failure>>;

	public abstract create(parameter: GeneticTestCreateParameterDto): Observable<Result<string, Failure>>;

	public abstract update(findParameter: GeneticTestFindParameterDto, updateParameter: GeneticTestUpdateParameterDto): Observable<EmptyResult<Failure>>;

	public abstract delete(parameter: GeneticTestFindParameterDto): Observable<EmptyResult<Failure>>;

	public abstract deleteBulk(parameter: GeneticTestDeleteBulkParameter): Observable<EmptyResult<Failure>>;

}
