import { TranslocoService } from '@ngneat/transloco';
import { DoctorEncounterCreateParameterDto } from '@nmn-communication/doctor-encounters';
import { DocumentCreateParameter } from '@nmn-communication/documents';
import {
	HealthIssueComboboxDto, HealthIssueCreateParameterDto, HealthIssueDto, HealthIssueFilterDto,
	HealthIssueFindParameterDto, HealthIssueUpdateParameterDto
} from '@nmn-communication/health-issues';
import { TakenMedicationCreateParameterDto } from '@nmn-communication/taken-medications';
import { Guid, isArrayDefined, isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { HealthIssueStatus } from '@nmn-domain/health-issues';
import { PagedCollectionDto, PageOptionsDto } from '../../core/clients';
import { FakeDatabase } from '../databases/fake.database';
import { getPagedCollection, getPagedCollectionWithoutDtoFilter } from '../databases/fake.utils';

export class HealthIssueFakeTable {

	private readonly database: FakeDatabase;
	private readonly data: Array<HealthIssueFakeRecord>;

	constructor(
		database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.database = database;
		this.data = [...initialData];
	}

	public getAsCombobox(parameter: HealthIssueFindParameterDto): HealthIssueComboboxDto {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record was not found');
		}

		return record;
	}

	public findAsCombobox(parameter: HealthIssueFindParameterDto): HealthIssueComboboxDto {
		const record = this.data
			.find((item: HealthIssueFakeRecord) => findPredicate(item, parameter));

		return isValueDefined(record) ? this.mapFromRecordToDescribedComboboxDto(record) : undefined;
	}

	public getComboboxesPagedCollection(filter: HealthIssueFilterDto): Array<HealthIssueComboboxDto> {
		return getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			filterPredicateForRecord,
			this.mapFromRecordToDescribedComboboxDto.bind(this),
			compareAsComboboxesFn
		).items;
	}

	public getPagedCollection(
		pageOptions: PageOptionsDto<HealthIssueFilterDto>
	): PagedCollectionDto<HealthIssueDto, HealthIssueFilterDto> {
		return getPagedCollection(
			this.translocoService,
			this.data,
			pageOptions,
			filterPredicateForRecord,
			this.mapFromRecordToDto.bind(this),
			filterPredicateForDto,
			compareFn
		);
	}

	public find(findParameter: HealthIssueFindParameterDto): HealthIssueDto {
		const record = this.data
			.find((item: HealthIssueFakeRecord) => findPredicate(item, findParameter));

		return this.mapFromRecordToDto(record);
	}

	public create(parameter: HealthIssueCreateParameterDto): string {
		const record = mapFromCreateParameterToRecord(parameter);
		this.data.push(record);
		this.createAndAttachRelations(record, parameter.encounters, parameter.takenMedications, parameter.documents);

		if (isArrayDefinedAndNotEmpty(parameter.documentIds)) {
			parameter.documentIds.forEach(documentId => {
				this.database.documentsTable.attachHealthIssue({ id: documentId, patientId: record.patientId }, record.id);
			});
		}

		return record.id;
	}

	private createAndAttachRelations(
		record: HealthIssueFakeRecord,
		doctorEncounters: Array<DoctorEncounterCreateParameterDto>,
		takenMedications: Array<TakenMedicationCreateParameterDto>,
		documents: Array<DocumentCreateParameter>
	): void {
		for (const doctorEncounter of doctorEncounters) {
			doctorEncounter.healthIssueId = record.id;
			const doctorEncounterId = this.database.doctorEncounterTable.create(doctorEncounter);
			if (isArrayDefinedAndNotEmpty(record.doctorEncounterIds)) {
				record.doctorEncounterIds.push(doctorEncounterId);
			} else {
				record.doctorEncounterIds = [doctorEncounterId];
			}
		}

		for (const takenMedication of takenMedications) {
			takenMedication.healthIssueId = record.id;
			const takenMedicationId = this.database.takenMedicationsTable.create(takenMedication);
			if (isArrayDefinedAndNotEmpty(record.takenMedicationIds)) {
				record.takenMedicationIds.push(takenMedicationId);
			} else {
				record.takenMedicationIds = [takenMedicationId];
			}
		}

		for (const document of documents) {
			if (isArrayDefinedAndNotEmpty(document.relatedHealthIssueIds)) {
				document.relatedHealthIssueIds.push(record.id);
			} else {
				document.relatedHealthIssueIds = [record.id];
			}

			this.database.documentsTable.create(document);
		}
	}

	public update(
		findParameter: HealthIssueFindParameterDto,
		updateParameter: HealthIssueUpdateParameterDto
	): void {
		const record = this.data
			.find((item: HealthIssueFakeRecord) => findPredicate(item, findParameter));

		if (!isValueDefined(record)) {
			throw new Error('Record to update was not found');
		}

		applyUpdateParameter(record, updateParameter);
		this.createAndAttachRelations(
			record,
			updateParameter.encounters,
			updateParameter.takenMedications,
			updateParameter.documents
		);

		if (isArrayDefinedAndNotEmpty(updateParameter.documentIds)) {
			updateParameter.documentIds.forEach(documentId => {
				this.database.documentsTable.attachHealthIssue({ id: documentId, patientId: record.patientId }, record.id);
			});
		}

		this.database.documentsTable.detachHealthIssueForAll(record.id, updateParameter.documentIds);
	}

	public delete(findParameter: HealthIssueFindParameterDto): void {
		const index = this.data
			.findIndex((item: HealthIssueFakeRecord) => findPredicate(item, findParameter));

		if (index >= 0) {
			this.data.splice(index, 1);
		}

		this.database.documentsTable.detachHealthIssueForAll(findParameter.id, undefined);
	}

	public attachDoctorEncounter(findParameter: HealthIssueFindParameterDto, id: string): void {
		const record = this.data.find((item: HealthIssueFakeRecord) => findPredicate(item, findParameter));

		if (!isValueDefined(record)) {
			throw new Error('Record to update was not found');
		}

		if (!record.doctorEncounterIds.some(item => item === id)) {
			record.doctorEncounterIds.push(id);
		}
	}

	public detachDoctorEncounterForAll(id: string, patientId: string, excludeIds: Array<string>): void {
		const records = this.data.filter((item: HealthIssueFakeRecord) =>
			filterPredicateForRecord(
				item,
				{ doctorEncounterIds: [id], patientId, ignoreIds: excludeIds },
				this.translocoService
			)
		);
		records.forEach(record => {
			const index = record.doctorEncounterIds.findIndex(item => item === id);

			if (index >= 0) {
				record.doctorEncounterIds.splice(index, 1);
			}
		});
	}

	public attachTakenMedication(findParameter: HealthIssueFindParameterDto, takenMedicationId: string): void {
		const record = this.data.find((item: HealthIssueFakeRecord) => findPredicate(item, findParameter));

		if (!isValueDefined(record)) {
			throw new Error('Record to update was not found');
		}

		if (!record.takenMedicationIds.some(id => id === takenMedicationId)) {
			record.takenMedicationIds.push(takenMedicationId);
		}
	}

	public detachTakenMedicationForAll(takenMedicationId: string, patientId: string, excludeDocumentIds: Array<string>): void {
		const records = this.data.filter((item: HealthIssueFakeRecord) =>
			filterPredicateForRecord(
				item,
				{ takenMedicationIds: [takenMedicationId], patientId, ignoreIds: excludeDocumentIds },
				this.translocoService
			)
		);
		records.forEach(record => {
			const index = record.takenMedicationIds.findIndex(item => item === takenMedicationId);

			if (index >= 0) {
				record.takenMedicationIds.splice(index, 1);
			}
		});
	}

	private mapFromRecordToDescribedComboboxDto(
		record: HealthIssueFakeRecord
	): HealthIssueComboboxDto {
		if (!isValueDefined(record)) {
			return undefined;
		}

		return {
			id: record.id,
			displayText: record.name,
			description: record.comment,
			severity: this.database.healthIssueSeverityTable.findAsCombobox({ id: record.severityId }),
			status: record.status,
			dateEnd: record.dateEnd,
			dateStart: record.dateStart
		};
	}

	private mapFromRecordToDto(record: HealthIssueFakeRecord): HealthIssueDto {
		return {
			id: record.id,
			dateStart: record.dateStart,
			dateEnd: record.dateEnd,
			name: record.name,
			comment: record.comment,
			status: record.status,
			severity: this.database.healthIssueSeverityTable.findAsCombobox({ id: record.severityId }),
			patientId: record.patientId,
			healthComplaints: this.database.healthComplaintTable.getComboboxesPagedCollection({ ids: record.healthComplaintIds }),
			encounters: this.database.doctorEncounterTable.getComboboxesPagedCollection({ ids: record.doctorEncounterIds, patientId: record.patientId }),
			takenMedications: this.database.takenMedicationsTable.getComboboxesPagedCollection({ ids: record.takenMedicationIds }),
			documents: this.database.documentsTable.getComboboxesPagedCollection({ healthIssueIds: [record.id] })
		};
	}

}

const mapFromCreateParameterToRecord = (
	parameter: HealthIssueCreateParameterDto
): HealthIssueFakeRecord => (
	{
		id: Guid.newGuid(),
		doctorEncounterIds: parameter.encounterIds,
		patientId: parameter.patientId,
		severityId: parameter.severityId,
		status: parameter.status,
		takenMedicationIds: parameter.takenMedicationIds,
		comment: parameter.comment,
		dateEnd: parameter.dateEnd,
		dateStart: parameter.dateStart,
		name: isStringDefinedAndNotEmpty(parameter.name) ? parameter.name : 'Autogenerated name',
		healthComplaintIds: parameter.healthComplaintIds
	}
);

const applyUpdateParameter = (
	record: HealthIssueFakeRecord,
	updateParameter: HealthIssueUpdateParameterDto
): void => {
	record.dateStart = updateParameter.dateStart;
	record.dateEnd = updateParameter.dateEnd;
	record.comment = updateParameter.comment;
	record.name = updateParameter.name;
	record.severityId = updateParameter.severityId;
	record.status = updateParameter.status;
	record.patientId = updateParameter.patientId;
	record.doctorEncounterIds = updateParameter.encounterIds;
	record.takenMedicationIds = updateParameter.takenMedicationIds;
};

const filterPredicateForRecord = (
	item: HealthIssueFakeRecord,
	filter: HealthIssueFilterDto,
	_: TranslocoService
): boolean => {
	let result = true;

	if (result && isArrayDefined(filter.ids)) {
		result = result && filter.ids.some(id => item.id === id);
	}

	if (result && isArrayDefined(filter.ignoreIds)) {
		result = result && filter.ignoreIds.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isStringDefinedAndNotEmpty(filter.patientId)) {
		// TODO: implement to support patient aliases too
		// result = result && filter.patientId === item.patientId;
	}

	if (result && isArrayDefined(filter.doctorEncounterIds)) {
		result = result &&
			filter.doctorEncounterIds.some(id => item.doctorEncounterIds.some(relationId => relationId === id));
	}

	if (result && isArrayDefined(filter.takenMedicationIds)) {
		result = result &&
			filter.takenMedicationIds.some(id => item.takenMedicationIds.some(relationId => relationId === id));
	}

	return result;
};

// eslint-disable-next-line complexity
const filterPredicateForDto = (
	item: HealthIssueDto,
	filter: HealthIssueFilterDto
): boolean => {
	let result = true;

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result &&
			(
				item.name?.indexOf(filter.searchPattern) >= 0 ||
				item.comment?.indexOf(filter.searchPattern) >= 0 ||
				item.dateStart?.indexOf(filter.searchPattern) >= 0 ||
				item.dateEnd?.indexOf(filter.searchPattern) >= 0
			);
	}

	if (result && isArrayDefinedAndNotEmpty(filter.ignoreIds)) {
		result = result && filter.ignoreIds.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isStringDefinedAndNotEmpty(filter.patientId)) {
		result = result && filter.patientId === item.patientId;
	}

	return result;
};

const findPredicate = (
	item: HealthIssueFakeRecord,
	findParameter: HealthIssueFindParameterDto
): boolean => {
	if (isValueDefined(findParameter)) {
		return isValueDefined(findParameter.takenMedicationId) ?
			item.takenMedicationIds?.indexOf(findParameter.takenMedicationId) > -1 :
			isValueDefined(findParameter.doctorEncounterId) ?
				item.doctorEncounterIds?.indexOf(findParameter.doctorEncounterId) > -1 :
				item.id === findParameter.id;
	}

	return true;
};

/* eslint-disable  */
/* eslint-disable complexity */
const compareAsComboboxesFn = (
	item1: HealthIssueComboboxDto,
	item2: HealthIssueComboboxDto,
	sorting: string
): number => {
	if (sorting === 'displayText asc') {
		return item1.displayText > item2.displayText ? 1 : item1.displayText < item2.displayText ? -1 : 0;
	} else if (sorting === 'displayText desc') {
		return item1.displayText < item2.displayText ? 1 : item1.displayText > item2.displayText ? -1 : 0;
	}

	return 0;
};

const compareFn = (item1: HealthIssueDto, item2: HealthIssueDto, sorting: string): number => {
	if (sorting === 'name asc') {

		return item1.name > item2.name ? 1 : item1.name < item2.name ? -1 : 0;
	} else if (sorting === 'dateStart asc') {

		return item1.dateStart > item2.dateStart ? 1 : item1.dateStart < item2.dateStart ? -1 : 0;
	} else if (sorting === 'dateEnd desc') {

		return item1.dateEnd < item2.dateEnd ? 1 : item1.dateEnd > item2.dateEnd ? -1 : 0;
	} else if (sorting === 'period asc') {
		if (!isStringDefinedAndNotEmpty(item1.dateStart) && isStringDefinedAndNotEmpty(item2.dateStart)) {
			return 1;
		} else if (isStringDefinedAndNotEmpty(item1.dateEnd) && !isStringDefinedAndNotEmpty(item2.dateEnd)) {
			return -1;
		}

		return item1.dateStart > item2.dateStart ? 1 : item1.dateStart < item2.dateStart ? -1 : 0;
	} else if (sorting === 'period desc') {
		if (!isStringDefinedAndNotEmpty(item1.dateEnd) && isStringDefinedAndNotEmpty(item2.dateEnd)) {
			return -1;
		} else if (isStringDefinedAndNotEmpty(item1.dateEnd) && !isStringDefinedAndNotEmpty(item2.dateEnd)) {
			return 1;
		}

		return item1.dateStart < item2.dateStart ? 1 : item1.dateStart > item2.dateStart ? -1 : 0;
	}

	return 0;
};
/* eslint-enable complexity */
/* eslint-enable */

interface HealthIssueFakeRecord {

	id: string;
	name?: string;
	comment?: string;
	dateStart?: string;
	dateEnd?: string;
	status: string;
	patientId: string;
	severityId: number;
	healthComplaintIds: Array<string>;
	doctorEncounterIds: Array<string>;
	takenMedicationIds: Array<string>;

}

const initialData: Array<HealthIssueFakeRecord> = [
	{
		id: 'health-issue-fake-record-1',
		name: 'Pneumonia and low headache',
		comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
		dateStart: '2022-10-15',
		dateEnd: '2022-10-25',
		status: HealthIssueStatus.Resolved,
		patientId: '00000000-0000-0000-0002-000000000001',
		severityId: 1,
		healthComplaintIds: ['headache', 'pneumonia'],
		doctorEncounterIds: ['doctor-encounter-fake-record-1'],
		takenMedicationIds: ['00000000-0000-0000-0201-100000000001']
	}
];

// eslint-disable-next-line max-lines
