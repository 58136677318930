import { isValueDefined } from '@nmn-core/utils';
import { NmnMatExpansionListUserConfiguration } from '../component-configurations/nmn-mat-expansion-list-user.configuration';

export class AccountsPageConfiguration {

	public readonly userAccountsMatExpansion: NmnMatExpansionListUserConfiguration;

	protected constructor(
		userAccountsMatExpansion: NmnMatExpansionListUserConfiguration
	) {

		// TODO: Guard check on defined
		this.userAccountsMatExpansion = userAccountsMatExpansion;
	}

	public static createWithParameters(
		config?: AccountsPageConfiguration
	): AccountsPageConfiguration {
		if (!isValueDefined(config)) {
			return AccountsPageConfiguration.createDefault();
		}

		return new AccountsPageConfiguration(
			isValueDefined(config.userAccountsMatExpansion) ?
				NmnMatExpansionListUserConfiguration.createWithParameters(config.userAccountsMatExpansion) :
				NmnMatExpansionListUserConfiguration.createDefault()
		);
	}

	public static createDefault(
	): AccountsPageConfiguration {
		return new AccountsPageConfiguration(
			NmnMatExpansionListUserConfiguration.createDefault()
		);
	}

}
