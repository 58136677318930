import { isValueDefined } from '@nmn-core/utils';

export enum MedicationAdmRouteType {

	Undefined = 'undefined'

}

export const mapMedicationAdmRouteTypeFromString = (stringValue: string): MedicationAdmRouteType => {
	const enumValue = Object
		.keys(MedicationAdmRouteType)
		.find(x => MedicationAdmRouteType[x] === stringValue);

	return isValueDefined(enumValue) ? stringValue as MedicationAdmRouteType : MedicationAdmRouteType.Undefined;
};
