export class FailureLocalizationModel {

	public readonly localizationKey?: string;
	public readonly injectParamers?: any

	private constructor(localizationKey: string, injectParamers: any) {
		this.localizationKey = localizationKey;
		this.injectParamers = injectParamers;
	}

	public static createFromValue(localizationKey: string, injectParamers?: any
	): FailureLocalizationModel {
		return new FailureLocalizationModel(localizationKey, injectParamers);
	}

	public static createEmpty(
	): FailureLocalizationModel {
		return new FailureLocalizationModel(undefined, undefined);
	}

}
