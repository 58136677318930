export class GeneticCardGetQuery {

	public patientId: string;

	private constructor(
		patientId: string
	) {

		// TODO: Guard add defined check

		this.patientId = patientId;
	}

	public static createByPatientId(patientId: string): GeneticCardGetQuery {
		return new GeneticCardGetQuery(patientId);
	}

}
