export const isValueDefined = (value: any | undefined): boolean => {
	return value !== null && value !== undefined;
};

export const isEmptyString = (str: any): boolean => {
	return isValueDefined(str) && typeof str === 'string' &&
		(
			str === '' ||
			// eslint-disable-next-line @typescript-eslint/quotes
			str === ""
		);
};

export const isNumberDefined = (value: any): boolean => {
	try {
		return isValueDefined(value) && value.toString() !== '' && !isNaN(Number(value.toString()));
	} catch {
		return false;
	}
};

export const isFunctionDefined = (func: any): boolean => {
	return isValueDefined(func) && typeof func === 'function';
};

export const isArrayDefined = (arr: Array<any> | undefined): boolean => {
	return isValueDefined(arr) && isValueDefined(arr!.length);
};

export const isArrayDefinedAndNotEmpty = (arr: Array<any> | undefined): boolean => {
	return isArrayDefined(arr) && arr!.length > 0;
};

export const firstOrUndefined = <T>(arr: Array<T> | undefined): T | undefined => {
	return isArrayDefinedAndNotEmpty(arr) ? arr[0] : undefined;
};

export const lastOrUndefined = <T>(arr: Array<T> | undefined): T | undefined => {
	return isArrayDefinedAndNotEmpty(arr) ? arr[arr.length - 1] : undefined;
};

export const findItemById = <T>(id: string | number, items: Array<T>, idKey = 'id', propKey?: string): T => {
	let result: T;

	if (isValueDefined(id) && isArrayDefined(items)) {
		result = items
			.find(item => item[idKey] === id);

		if (isValueDefined(propKey) && result.hasOwnProperty(propKey)) {
			result = result[propKey];
		}
	}

	return result;
};

export const findItemsById = <T>(id: string, items: Array<T>, idKey = 'id'): Array<T> => {
	let result: Array<T>;

	if (isValueDefined(id) && isArrayDefined(items)) {
		result = items
			.filter(item => item[idKey] === id);
	}

	return result;
};

export const onlyUnique = <T>(
	value: T,
	index: number,
	array: Array<T>,
	compareFn: (value1: T, value2: T) => boolean = (value1: T, value2: T) => value1 === value2
): boolean => {
	return isValueDefined(value) && isArrayDefined(array) && array.findIndex(item => compareFn(value, item)) === index;
};

export const onlyDuplicates = <T>(
	value: T,
	index: number,
	array: Array<T>,
	compareFn: (value1: T, value2: T) => boolean = (value1: T, value2: T) => value1 === value2
): boolean => {
	return isValueDefined(value) && isArrayDefined(array) && array.findIndex(item => compareFn(value, item)) !== index;
};

export const deepCopy = (obj): any => {
	let copy;

	// Handle the 3 simple types, and null or undefined
	if (!isValueDefined(obj) || typeof obj !== 'object') {
		return obj;
	}

	// Handle Date
	if (obj instanceof Date) {
		copy = new Date();
		copy.setTime(obj.getTime());

		return copy;
	}

	// Handle Array
	if (obj instanceof Array) {
		copy = [];
		for (let i = 0, len = obj.length; i < len; i++) {
			copy[i] = deepCopy(obj[i]);
		}

		return copy;
	}

	// Handle Object
	if (obj instanceof Object) {
		copy = {};

		// eslint-disable-next-line no-restricted-syntax
		for (const attr in obj) {
			if (obj.hasOwnProperty(attr)) {
				copy[attr] = deepCopy(obj[attr]);
			}
		}

		return copy;
	}

	throw new Error('Copy object failed (object type is not supported).');
};

export const mergeMaps = <K, V>(...maps: Map<K, V>[]): any => {
	return isArrayDefinedAndNotEmpty(maps) ?
		new Map(maps.filter(isValueDefined).reduce((accumulator, item) => [...Array.from(accumulator.entries()), ...Array.from(item.entries())], [])) :
		new Map<K, V>();
}

export const booleanToNumber = (value: boolean | undefined): number | undefined => {
	return isValueDefined(value) ? value ? 1 : 0 : undefined;
};

export const numberToBoolean = (value: number | undefined): boolean | undefined => {
	return isNumberDefined(value) && (value === 1 || value === 0) ? value === 1 ? true : false : undefined;
};
