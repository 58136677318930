import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneticCardModel } from '../models/genetic-card.model';

export abstract class CurrentPatientGeneticCardResolver implements Resolve<GeneticCardModel> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		routerState: RouterStateSnapshot
	): Observable<GeneticCardModel> | Promise<GeneticCardModel> | GeneticCardModel;

}
