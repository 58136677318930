import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
	GeneticCardDto, GeneticCardFindParameterDto, GeneticTestClient, GeneticTestCreateParameterDto, GeneticTestDeleteBulkParameter,
	GeneticTestDto, GeneticTestFilterDto, GeneticTestFindParameterDto, GeneticTestUpdateParameterDto
} from '@nmn-communication/genetic-tests';
import { Failure, PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { constructHttpParams, injectParameters } from '@nmn-core/utils';
import { Observable } from 'rxjs';
import {
	createEmptyResultFromResponse,
	createFailedEmptyResultFromErrorResponse,
	createFailedResultFromErrorResponse,
	createResultFromResponse,
	createResultFromResponseViaIdStringProjection
} from '../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { GeneticTestHttpClientConfiguration } from '../configurations/genetic-test.http-client.configuration';

export class GeneticTestHttpClient extends GeneticTestClient {

	constructor(
		private readonly configuration: GeneticTestHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public getGeneticCard(parameter: GeneticCardFindParameterDto): Observable<Result<GeneticCardDto, Failure>> {
		const parameters = {
			patientId: parameter.patientId
		};
		const url = injectParameters(`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/geneticCard`, parameters);

		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<GeneticCardDto>(url, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public get(parameter: GeneticTestFindParameterDto): Observable<Result<GeneticTestDto, Failure>> {
		const parameters = {
			patientId: parameter.patientId,
			id: parameter.id
		};
		const url = injectParameters(`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/${parameters.id}`, parameters);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<GeneticTestDto>(url, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public getBulk(findParameter: PageOptionsDto<GeneticTestFilterDto>): Observable<Result<PagedCollectionDto<GeneticTestDto, GeneticTestFilterDto>, Failure>> {
		const parameters = {
			patientId: findParameter.filter?.patientId
		};
		const url = injectParameters(`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`, parameters);

		const params = constructHttpParams(findParameter);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<PagedCollectionDto<GeneticTestDto, GeneticTestFilterDto>>(url, { params, headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public create(parameter: GeneticTestCreateParameterDto): Observable<Result<string, Failure>> {
		const parameters = {
			patientId: parameter.patientId
		};
		const url = injectParameters(`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`, parameters);
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post<GeneticTestDto>(url, payload, { headers })
			.pipe(
				createResultFromResponseViaIdStringProjection(),
				createFailedResultFromErrorResponse()
			);
	}

	public update(findParameter: GeneticTestFindParameterDto, updateParameter: GeneticTestUpdateParameterDto): Observable<EmptyResult<Failure>> {
		const parameters = {
			patientId: findParameter.patientId,
			id: findParameter.id
		};
		const url = injectParameters(`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/${parameters.id}`, parameters);
		const payload = updateParameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put<GeneticTestDto>(url, payload, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

	public delete(parameter: GeneticTestFindParameterDto): Observable<EmptyResult<Failure>> {
		const parameters = {
			patientId: parameter.patientId,
			id: parameter.id
		};
		const url = injectParameters(`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/${parameters.id}`, parameters);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.delete<GeneticTestDto>(url, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

	public deleteBulk(findParameter: GeneticTestDeleteBulkParameter): Observable<EmptyResult<Failure>> {
		const parameters = {
			patientId: findParameter.patientId
		};
		const url = injectParameters(`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`, parameters);

		const params = constructHttpParams(findParameter);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.delete(url, { headers, params })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

}
