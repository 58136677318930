import { RegisterAccountParameter } from '@nmn-communication/accounts';
import { RegisterAccountCommand } from '@nmn-domain/accounts/registration/models/commands/register-account.command';

export const mapRegisterAccountCommandToParameter = (
	command: RegisterAccountCommand
): RegisterAccountParameter => (
	{
		email: command.email,
		password: command.password,
		confirmPassword: command.confirmPassword,
		isEmailNotificationsEnabled: command.isEmailNotificationsEnabled,
		pictureId: command.pictureId,
		locale: command.locale,
		timezone: command.timezone
	}
);
