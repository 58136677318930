import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { TimezoneHttpClientConfiguration } from '../configurations/timezone.http-client.configuration';
import { TimezoneHttpClient } from '../services/timezone.http-client';

export class TimezoneHttpClientBuilder extends HttpClientBuilder<TimezoneHttpClient, TimezoneHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): TimezoneHttpClient {
		return new TimezoneHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient)
		);
	}

}
