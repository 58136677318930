import { TranslocoService } from '@ngneat/transloco';
import { UserConfigurationDto, UserConfigurationUpdateParameter } from '@nmn-communication/accounts';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../databases/fake.database';

export class UserConfigurationFakeTable {

	private readonly database: FakeDatabase;
	private readonly data: Array<UserConfigurationFakeRecord>;

	constructor(
		database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.database = database;
		this.data = [...initialData];
	}

	public get(userId: string): UserConfigurationDto {
		const record = this.data.filter((item: UserConfigurationFakeRecord) => findPredicate(item, userId));

		if (!isValueDefined(record) || record.length !== 1) {
			throw new Error('User configuration was not found.');
		}

		return mapUserRecordToUserConfigurationDto(record[0]);
	}

	public update(userId: string, parameter: UserConfigurationUpdateParameter): void {
		const record = this.data.filter((item: UserConfigurationFakeRecord) => findPredicate(item, userId));

		if (!isValueDefined(record) || record.length !== 1) {
			throw new Error('User configuration was not found.');
		}

		applyUpdateParameter(record[0], parameter);
	}

}

const mapUserRecordToUserConfigurationDto = (record: UserConfigurationFakeRecord): UserConfigurationDto => {
	return {
		patientAlias: record.patientAlias,
		settings: record.settings
	};
};

const findPredicate = (item: UserConfigurationFakeRecord, userId: string): boolean =>
	item.userId === userId;

const applyUpdateParameter = (
	record: UserConfigurationFakeRecord,
	updateParameter: UserConfigurationUpdateParameter
): void => {
	record.settings = updateParameter.settings;
};

interface UserConfigurationFakeRecord {

	userId: string;
	settings: string;
	patientAlias?: string;

}

const initialData: Array<UserConfigurationFakeRecord> = [
	{
		userId: '00000000-0000-0000-0001-000000000001',
		settings: '{ }'
	},
	{
		userId: '00000000-0000-0000-0001-000000000002',
		settings: '{ }'
	}
];

// eslint-disable-next-line max-lines
