import { Injector, Provider } from '@angular/core';
import { GeneVariantClient } from '@nmn-communication/gene-variants';
import { geneVariantClientFactory } from './factories/gene-variant.client.factory';

export const geneVariantProviders: Array<Provider> = [
	{
		provide: GeneVariantClient,
		useFactory: geneVariantClientFactory,
		deps: [Injector]
	}
];
