import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { isValueDefined } from '@nmn-core/utils';
import { NavigationResource } from '@nmn-middleware/navigation';
import { Observable, catchError, combineLatest, filter, map, of, switchMap, timeout } from 'rxjs';
import { StorageService } from '../../../services';

@Injectable({ providedIn: 'root' })
export class AuthorizedGuard implements CanActivate, CanActivateChild {

	constructor(
		private readonly router: Router,
		private readonly storageService: StorageService
	) { }

	public canActivate(): Observable<UrlTree | boolean> {
		return this.storageService.isUserLoggedIn$.pipe(
			switchMap(isLoggedIn => {
				if (isLoggedIn) {
					return combineLatest([this.storageService.isUserInfoLoading$, this.storageService.userProfile$])
						.pipe(
							filter(([isUserInfoLoading, userProfile]) => !isUserInfoLoading && isValueDefined(userProfile)),
							map(() => true)
						);
				}

				return of(this.router.createUrlTree([NavigationResource.Login]));
			}),
			catchError(() => of(this.router.createUrlTree([NavigationResource.Login]))));
	}

	public canActivateChild(): Observable<UrlTree | boolean> | boolean | UrlTree {
		return this.canActivate();
	}

}
