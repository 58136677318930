import { isArrayDefined, isValueDefined } from '@nmn-core/utils';

export class GeneticFileUploadConfiguration {

	public readonly allowedExtensions: Array<string>;
	public readonly maxSize: number;

	public constructor(
		allowedExtensions: Array<string>,
		maxSize: number
	) {
		isArrayDefined(allowedExtensions) ?
			this.allowedExtensions = allowedExtensions :
			this.allowedExtensions = geneticFileUploadAllowedExtensionsDefault;
		isValueDefined(maxSize) ?
			this.maxSize = maxSize :
			this.maxSize = geneticFileUploadMaxSizeDefault;
	}

	public static createDefault(): GeneticFileUploadConfiguration {
		return new GeneticFileUploadConfiguration(
			geneticFileUploadAllowedExtensionsDefault,
			geneticFileUploadMaxSizeDefault);
	}

}

export const geneticFiles = ['.vcf'];
export const archives = ['.rar', '.zip', '.gz', '.7z'];

export const geneticFileUploadAllowedExtensionsDefault = [
	...geneticFiles,
	...archives];

export const geneticFileUploadMaxSizeDefault = 1 * 1024 * 1024 * 1024;
