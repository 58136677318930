import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { DescribedComboboxModel } from '../../shared';
import { FailureModel } from '../../shared/failures/failure.model';
import { MedicationRateQuantityTypeFilterModel } from '../index';

export abstract class MedicationRateQuantityTypeQueryHandlerService {

	public abstract getAsDescribedComboboxes(
		query: MedicationRateQuantityTypeFilterModel
	): Observable<Result<Array<DescribedComboboxModel>, FailureModel>>;

}
