import { isValueDefined } from '@nmn-core/utils';

export class NotificationConfiguration {

	public readonly isEnabledSuccessNotifications: boolean;
	public readonly isEnabledCancelNotifications: boolean;
	public readonly isEnabledClearNotifications: boolean;
	public readonly isEnabledResetNotifications: boolean;

	constructor(
		isEnabledSuccessNotifications: boolean,
		isEnabledCancelNotifications: boolean,
		isEnabledClearNotifications: boolean,
		isEnabledResetNotifications: boolean
	) {
		this.isEnabledSuccessNotifications = isEnabledSuccessNotifications;
		this.isEnabledCancelNotifications = isEnabledCancelNotifications;
		this.isEnabledClearNotifications = isEnabledClearNotifications;
		this.isEnabledResetNotifications = isEnabledResetNotifications;
	}

	public static createWithParameters(
		config: NotificationConfiguration
	): NotificationConfiguration {
		if (!isValueDefined(config)) {
			return NotificationConfiguration.createDefault();
		}

		return new NotificationConfiguration(
			isValueDefined(config?.isEnabledSuccessNotifications) ?
				config.isEnabledSuccessNotifications :
				notificationConfigurationIsEnabledSuccessNotificationsDefault,
			isValueDefined(config?.isEnabledCancelNotifications) ?
				config.isEnabledCancelNotifications :
				notificationConfigurationIsEnabledCancelNotificationsDefault,
			isValueDefined(config?.isEnabledClearNotifications) ?
				config.isEnabledClearNotifications :
				notificationConfigurationIsEnabledClearNotificationsDefault,
			isValueDefined(config?.isEnabledResetNotifications) ?
				config.isEnabledResetNotifications :
				notificationConfigurationIsEnabledResetNotificationsDefault,
		);
	}

	public static createDefault(
	): NotificationConfiguration {
		return new NotificationConfiguration(
			notificationConfigurationIsEnabledSuccessNotificationsDefault,
			notificationConfigurationIsEnabledCancelNotificationsDefault,
			notificationConfigurationIsEnabledClearNotificationsDefault,
			notificationConfigurationIsEnabledResetNotificationsDefault);
	}

}

export const notificationConfigurationIsEnabledSuccessNotificationsDefault = true;
export const notificationConfigurationIsEnabledCancelNotificationsDefault = true;
export const notificationConfigurationIsEnabledClearNotificationsDefault = true;
export const notificationConfigurationIsEnabledResetNotificationsDefault = true;
