import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { GeneticTestGetQuery, GeneticTestModel, GeneticTestQueryHandlerService, GeneticTestResolver } from '@nmn-domain/genetic-tests';
import { RouteParameter } from '@nmn-middleware/navigation';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class GeneticTestViaQueryHandlerResolver extends GeneticTestResolver {

	constructor(
		private readonly geneticTestQueryHandlerService: GeneticTestQueryHandlerService
	) {
		super();
	}

	public resolve(
		activatedRoute: ActivatedRouteSnapshot,
		_: RouterStateSnapshot
	): Observable<GeneticTestModel> | Promise<GeneticTestModel> | GeneticTestModel {
		const geneticTestId = activatedRoute.paramMap.get(RouteParameter.GeneticTestId);
		const patientAlias = activatedRoute.paramMap.get(RouteParameter.PatientAlias);

		if (!isStringDefinedAndNotEmpty(geneticTestId)) {
			return undefined;
		}

		return this.geneticTestQueryHandlerService
			.handleGetQuery(GeneticTestGetQuery.createId(geneticTestId, patientAlias))
			.pipe(
				map(result => result.successOrDefault(() => undefined))
			);
	}

}
