import { Injector, Provider } from '@angular/core';
import { ServerConfigurationClient } from '@nmn-communication/server-configuration';
import { serverConfigurationClientFactory } from './factories/server-configuration.client.factory';

export const serverConfigurationProviders: Array<Provider> = [
	{
		provide: ServerConfigurationClient,
		useFactory: serverConfigurationClientFactory,
		deps: [Injector]
	}
];
