import { HttpParameterCodec, HttpParams } from '@angular/common/http';
import { isStringDefinedAndNotEmpty } from './string.utils';
import { isValueDefined } from './value.utils';

export const constructHttpParams = (request: any, encoder?: HttpParameterCodec): HttpParams => {
	const params = new HttpParams(encoder ? { encoder } : {});

	return addObjectToHttpParams(params, request, '');
};

const addObjectToHttpParams = (params: HttpParams, value: any, param: string): HttpParams => {
	let result = params;

	if (value instanceof Date) {
		result = result.append(param, value.toDateString());

		return result;
	}

	if (value instanceof Array) {
		value.forEach((element: any, index: number) => {
			result = addObjectToHttpParams(result, element, `${param}[${index}]`);
		});

		return result;
	}

	const keys = Object.keys(value);

	if (keys.length === 0 || typeof value === 'string') {
		result = result.append(param, value.toString());

		return result;
	}

	keys.forEach(key => {
		if (isStringDefinedAndNotEmpty(key) && isValueDefined(value[key])) {
			const paramNew = isStringDefinedAndNotEmpty(param)
				? `${param}.${key}`
				: key;
			result = addObjectToHttpParams(result, value[key], paramNew);
		}
	});

	return result;
};
