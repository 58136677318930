import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TreatmentCheckerReportModel } from '../models/treatment-checker-report.model';

export abstract class TreatmentCheckerReportResolver implements Resolve<TreatmentCheckerReportModel> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	): Observable<TreatmentCheckerReportModel> | TreatmentCheckerReportModel;

}
