import { Injector } from '@angular/core';
import { LocalStorageService } from '../../../../../core/application-storages';
import { FakeDatabase } from '../../../../../modules/fakes/databases/fake.database';
import { isValueDefined } from '@nmn-core/utils';
import { UserConfigurationFakeClientConfiguration } from '../configurations/user-configuration.fake-client.configuration';
import { CurrentUserConfigurationFakeClient } from '../services/current-user-configuration.fake-client';

export class UserConfigurationFakeClientBuilder {

	private configuration: UserConfigurationFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(configuration: UserConfigurationFakeClientConfiguration): UserConfigurationFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): CurrentUserConfigurationFakeClient {
		return new CurrentUserConfigurationFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase),
			this.injector.get(LocalStorageService)
		);
	}

}
