import { HealthIssueComboboxDto } from '@nmn-communication/health-issues';
import { mapDateInServerFormatToDate } from '@nmn-core/utils';
import { HealthIssueComboboxModel, mapHealthIssueStatusFromString } from '@nmn-domain/health-issues';
import { mapHealthIssueSeverityComboboxDtoToModel } from '../../health-issue-severities/factories/health-issue-severity.factory';

export const mapHealthIssueComboboxDtoToModel = (dto: HealthIssueComboboxDto): HealthIssueComboboxModel => (
	new HealthIssueComboboxModel(
		dto.id,
		dto.displayText,
		dto.description,
		mapDateInServerFormatToDate(dto.dateStart),
		mapDateInServerFormatToDate(dto.dateEnd),
		mapHealthIssueSeverityComboboxDtoToModel(dto.severity),
		mapHealthIssueStatusFromString(dto.status)
	)
);
