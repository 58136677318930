import { isValueDefined } from '@nmn-core/utils';

export enum MedicationAdmStatusType {

	Undefined = 'undefined',
	Active = 'active',
	Suspended = 'suspended',
	Completed = 'completed',
	Failed = 'failed'

}

export const mapMedicationAdmStatusTypeFromString = (stringValue: string): MedicationAdmStatusType => {
	const enumValue = Object
		.keys(MedicationAdmStatusType)
		.find(x => MedicationAdmStatusType[x] === stringValue);

	return isValueDefined(enumValue) ? stringValue as MedicationAdmStatusType : MedicationAdmStatusType.Undefined;
};
