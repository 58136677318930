import { MedicationRateQuantityTypeFilterDto } from '@nmn-communication/medication-rate-quantity-types';
import { MedicationRateQuantityTypeFilterModel } from '@nmn-domain/medication-rate-quantity-types';

export const mapFilterModelToDto = (model: MedicationRateQuantityTypeFilterModel): MedicationRateQuantityTypeFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern
	}
);

export const mapFilterDtoToModel = (dto: MedicationRateQuantityTypeFilterDto): MedicationRateQuantityTypeFilterModel => (
	new MedicationRateQuantityTypeFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern
	)
);
