import { isArrayDefined, isValueDefined } from '@nmn-core/utils';

export class DocumentUploadConfiguration {

	public readonly allowedExtensions: Array<string>;
	public readonly maxSize: number;

	public constructor(
		allowedExtensions: Array<string>,
		maxSize: number
	) {
		isArrayDefined(allowedExtensions) ?
			this.allowedExtensions = allowedExtensions :
			this.allowedExtensions = documentUploadAllowedExtensionsDefault;
		isValueDefined(maxSize) ?
			this.maxSize = maxSize :
			this.maxSize = documentUploadMaxSizeDefault;
	}

	public static createDefault(): DocumentUploadConfiguration {
		return new DocumentUploadConfiguration(
			documentUploadAllowedExtensionsDefault,
			documentUploadMaxSizeDefault);
	}

}

export const textFiles = ['.csv', '.tsv', '.txt'];
export const documentFiles = ['.doc', '.docx', '.pdf'];
export const tablesheetsFiles = ['.xls', '.xlsx', '.xlsm'];
export const imageFiles = ['.png', '.jpg', '.jpeg'];

export const documentUploadAllowedExtensionsDefault = [
	...textFiles,
	...documentFiles,
	...tablesheetsFiles,
	...imageFiles];
export const documentUploadMaxSizeDefault = 200 * 1024 * 1024;
