import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { HealthComplaintHttpClientConfiguration } from '../configurations/health-complaint.http-client.configuration';
import { HealthComplaintHttpClient } from '../services/health-complaint.http-client';

export class HealthComplaintHttpClientBuilder
	extends HttpClientBuilder<HealthComplaintHttpClient, HealthComplaintHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): HealthComplaintHttpClient {
		return new HealthComplaintHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
