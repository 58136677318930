import { ConfirmEmailParameter, EmailConfirmationClient, InitiateEmailConfirmationParameter } from '@nmn-communication/accounts';
import { Failure } from '@nmn-communication/shared';
import { EmptyResult } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../../modules/fakes/databases/fake.database';
import { wrapActionCallAsFakeClientResponse } from '../../../../../modules/fakes/databases/fake.utils';
import { EmailConfirmationFakeClientConfiguration } from '../configurations/email-confirmation.fake-client.configuration';

export class EmailConfirmationFakeClient extends EmailConfirmationClient {

	constructor(
		private readonly configuration: EmailConfirmationFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public confirmEmail(parameter: ConfirmEmailParameter): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.emailConfirmationTokenTable.use(parameter);
				this.fakeDatabase.usersTable.confirmEmail(parameter);
			},
			this.configuration.serverDelayMs
		);
	}

	public initiateEmailConfirmation(parameter: InitiateEmailConfirmationParameter): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				const userId = this.fakeDatabase.usersTable.getIdByEmail(parameter?.email, false);
				this.fakeDatabase.emailConfirmationTokenTable.create(userId);
			},
			this.configuration.serverDelayMs
		);
	}

}
