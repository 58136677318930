import { Result } from '@nmn-core/shared';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { PopulationComboboxModel } from '../models/population-combobox.model';
import { PopulationFilterModel } from '../models/population-filter.model';

export abstract class PopulationQueryHandlerService {

	public abstract getAsComboboxes(query: PopulationFilterModel): Observable<Result<Array<PopulationComboboxModel>, FailureModel>>;

}
