import { HealthIssueDto, HealthIssueFilterDto } from '@nmn-communication/health-issues';
import { PagedCollectionDto } from '@nmn-communication/shared';
import { mapDateInServerFormatToDate } from '@nmn-core/utils';
import { HealthIssueFilterModel, HealthIssueModel, mapHealthIssueStatusFromString } from '@nmn-domain/health-issues';
import { PagedCollectionModel } from '@nmn-domain/shared';
import { mapDoctorEncounterComboboxDtoToModel } from '../../doctor-encounters/factories/doctor-encounter-combobox.factory';
import { mapDocumentDescribedComboboxDtoToModel } from '../../documents/factories/document.factory';
import { mapHealthComplaintComboboxDtoToModel } from '../../health-complaints/factories/health-complaint.factory';
import { mapHealthIssueSeverityComboboxDtoToModel } from '../../health-issue-severities/factories/health-issue-severity.factory';
import { mapPagedCollectionDtoToModel } from '../../shared/factories/pagination.factory';
import { mapTakenMedicationComboboxDtoToModel } from '../../taken-medications/factories/taken-medication-combobox.factory';

export const mapDtoToModel = (dto: HealthIssueDto): HealthIssueModel => (
	new HealthIssueModel(
		dto.id,
		dto.name,
		dto.comment,
		mapDateInServerFormatToDate(dto.dateStart),
		mapDateInServerFormatToDate(dto.dateEnd),
		mapHealthIssueStatusFromString(dto.status),
		dto.patientId,
		mapHealthIssueSeverityComboboxDtoToModel(dto.severity),
		dto.healthComplaints.map(mapHealthComplaintComboboxDtoToModel),
		dto.encounters.map(mapDoctorEncounterComboboxDtoToModel),
		dto.takenMedications.map(mapTakenMedicationComboboxDtoToModel),
		dto.documents.map(mapDocumentDescribedComboboxDtoToModel)
	)
);

export const mapHealthIssuePagedCollectionDtoToModel = (
	pagedCollectionDto: PagedCollectionDto<HealthIssueDto, HealthIssueFilterDto>
): PagedCollectionModel<HealthIssueModel, HealthIssueFilterModel> =>
	mapPagedCollectionDtoToModel(pagedCollectionDto, mapDtoToModel, mapFilterDtoToModel);

export const mapFilterModelToDto = (model: HealthIssueFilterModel): HealthIssueFilterDto => (
	{
		patientId: model.patientId,
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern,
		statuses: model.statuses,
		severityIds: model.severityIds?.map(Number)
	}
);

export const mapFilterDtoToModel = (dto: HealthIssueFilterDto): HealthIssueFilterModel => (
	new HealthIssueFilterModel(
		dto.patientId,
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern,
		dto.statuses?.map(mapHealthIssueStatusFromString),
		dto.severityIds?.map(Number)
	)
);
