import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { GeneticTestHttpClientConfiguration } from '../configurations/genetic-test.http-client.configuration';
import { GeneticTestHttpClient } from '../services/genetic-test.http-client';

export class GeneticTestHttpClientBuilder extends HttpClientBuilder<GeneticTestHttpClient, GeneticTestHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): GeneticTestHttpClient {
		return new GeneticTestHttpClient(this.configuration, this.injector.get<HttpClient>(HttpClient));
	}

}
