import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContactsClient, ContactsDto, SendMessageParameterDto } from '@nmn-communication/contacts';
import { Failure } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { createEmptyResultFromResponse, createFailedEmptyResultFromErrorResponse } from 'app/modules/shared/error-handling/utils/http-client-default-handler.utils';
import { Observable, of } from 'rxjs';
import { ContactsHttpClientConfiguration } from '../configurations/contacts.http-client.configuration';

@Injectable()
export class ContactsHttpClient extends ContactsClient {

	constructor(
		private readonly configuration: ContactsHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public sendMessage(parameter: SendMessageParameterDto): Observable<EmptyResult<Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`;
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post<SendMessageParameterDto>(url, payload, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

	public getContacts(): Observable<Result<ContactsDto, Failure>> {
		// TODO: NOT IMPLEMENTED

		return of(Result.success<ContactsDto, Failure>(contactsData));
	}

}

export const contactsData: ContactsDto = {
	main: {
		common: {
			email: 'support@apixmed.com',
			mobile: '+31 630 09 88 99'
		},
		social: {
			twitter: 'https://twitter.com/apixmed',
			facebook: 'https://www.facebook.com/groups/apixmed',
			medium: 'https://medium.com/@team.apixmed',
			linkedin: 'https://www.linkedin.com/company/apixmed',
			telegram: 'https://t.me/apixmed'
		}
	},
	other: {
		social: {
			twitter: 'https://twitter.com/apixmed',
			facebook: 'https://www.facebook.com/groups/apixmed',
			medium: 'https://medium.com/@team.apixmed',
			linkedin: 'https://www.linkedin.com/company/apixmed',
			youtube: 'https://www.youtube.com/@Apixmed',
			telegram: 'https://t.me/apixmed'
		}
	}
};
