import { TranslocoService } from '@ngneat/transloco';
import {
	DoctorEncounterComboboxDto, DoctorEncounterCreateParameterDto, DoctorEncounterDto, DoctorEncounterFilterDto, DoctorEncounterFindParameterDto, DoctorEncounterUpdateParameterDto
} from '@nmn-communication/doctor-encounters';
import { DocumentCreateParameter } from '@nmn-communication/documents';
import { PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import { TakenMedicationCreateParameterDto } from '@nmn-communication/taken-medications';
import { Guid, isArrayDefined, isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { DoctorEncounterStatus } from '@nmn-domain/doctor-encounters';
import { FakeDatabase } from '../databases/fake.database';
import { getPagedCollection, getPagedCollectionWithoutDtoFilter } from '../databases/fake.utils';
import { FakeLocalizableEntity } from '../models/fake-localizable-entity';
import { setTranslation } from '../utils/localize';

export class DoctorEncounterFakeTable {

	private readonly database: FakeDatabase;
	private readonly data: Array<DoctorEncounterFakeRecord>;

	constructor(
		database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.database = database;
		this.data = [...initialData];
	}

	public getAsCombobox(parameter: DoctorEncounterFindParameterDto): DoctorEncounterComboboxDto {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record was not found');
		}

		return record;
	}

	public findAsCombobox(parameter: DoctorEncounterFindParameterDto): DoctorEncounterComboboxDto {
		const record = this.data
			.find((item: DoctorEncounterFakeRecord) => findPredicate(item, parameter));

		return this.mapFromRecordToDescribedComboboxDto(record);
	}

	public getComboboxesPagedCollection(filter: DoctorEncounterFilterDto): Array<DoctorEncounterComboboxDto> {
		return getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			filterPredicateForRecord,
			this.mapFromRecordToDescribedComboboxDto.bind(this),
			compareAsComboboxesFn
		).items;
	}

	public getPagedCollection(
		pageOptions: PageOptionsDto<DoctorEncounterFilterDto>
	): PagedCollectionDto<DoctorEncounterDto, DoctorEncounterFilterDto> {
		return getPagedCollection(
			this.translocoService,
			this.data,
			pageOptions,
			filterPredicateForRecord,
			this.mapFromRecordToDto.bind(this),
			filterPredicateForDto,
			compareFn
		);
	}

	public find(findParameter: DoctorEncounterFindParameterDto): DoctorEncounterDto {
		const record = this.data
			.find((item: DoctorEncounterFakeRecord) => findPredicate(item, findParameter));

		return this.mapFromRecordToDto(record);
	}

	public create(parameter: DoctorEncounterCreateParameterDto): string {
		const record = mapFromCreateParameterToRecord(parameter);
		this.data.push(record);
		this.createAndAttachRelations(record, parameter.takenMedications, parameter.documents);

		if (isArrayDefinedAndNotEmpty(parameter.documentIds)) {
			parameter.documentIds.forEach(documentId => {
				this.database.documentsTable.attachEncounter({ id: documentId, patientId: record.patientId }, record.id);
			});
		}

		return record.id;
	}

	private createAndAttachRelations(
		record: DoctorEncounterFakeRecord,
		takenMedications: Array<TakenMedicationCreateParameterDto>,
		documents: Array<DocumentCreateParameter>
	): void {
		for (const takenMedication of takenMedications) {
			takenMedication.encounterId = record.id;
			const takenMedicationId = this.database.takenMedicationsTable.create(takenMedication);
			if (isArrayDefinedAndNotEmpty(record.takenMedicationIds)) {
				record.takenMedicationIds.push(takenMedicationId);
			} else {
				record.takenMedicationIds = [takenMedicationId];
			}
		}

		for (const document of documents) {
			if (isArrayDefinedAndNotEmpty(document.relatedEncounterIds)) {
				document.relatedEncounterIds.push(record.id);
			} else {
				document.relatedEncounterIds = [record.id];
			}

			this.database.documentsTable.create(document);
		}
	}

	public update(
		findParameter: DoctorEncounterFindParameterDto,
		updateParameter: DoctorEncounterUpdateParameterDto
	): void {
		const record = this.data
			.find((item: DoctorEncounterFakeRecord) => findPredicate(item, findParameter));

		if (!isValueDefined(record)) {
			throw new Error('Record to update was not found');
		}

		applyUpdateParameter(record, updateParameter);
		this.createAndAttachRelations(
			record,
			updateParameter.takenMedications,
			updateParameter.documents
		);

		if (isValueDefined(updateParameter.healthIssueId)) {
			this.database.healthIssueTable.attachDoctorEncounter({ id: updateParameter.healthIssueId, patientId: record.patientId }, record.id);
		}
		if (isArrayDefinedAndNotEmpty(updateParameter.documentIds)) {
			updateParameter.documentIds.forEach(documentId => {
				this.database.documentsTable.attachEncounter({ id: documentId, patientId: record.patientId }, record.id);
			});
		}

		this.database.healthIssueTable.detachDoctorEncounterForAll(
			record.id,
			record.patientId,
			isValueDefined(updateParameter.healthIssueId) ? [updateParameter.healthIssueId] : undefined
		);
		this.database.documentsTable.detachDoctorEncounterForAll(record.id, updateParameter.documentIds);
	}

	public delete(findParameter: DoctorEncounterFindParameterDto): void {
		const index = this.data
			.findIndex((item: DoctorEncounterFakeRecord) => findPredicate(item, findParameter));

		if (index >= 0) {
			this.data.splice(index, 1);
		}

		this.database.healthIssueTable.detachDoctorEncounterForAll(findParameter.id, findParameter.patientId, undefined);
		this.database.documentsTable.detachDoctorEncounterForAll(findParameter.id, undefined);
	}

	public attachTakenMedication(findParameter: DoctorEncounterFindParameterDto, takenMedicationId: string): void {
		const record = this.data.find((item: DoctorEncounterFakeRecord) => findPredicate(item, findParameter));

		if (!isValueDefined(record)) {
			throw new Error('Record to update was not found');
		}

		if (!record.takenMedicationIds.some(id => id === takenMedicationId)) {
			record.takenMedicationIds.push(takenMedicationId);
		}
	}

	public detachTakenMedicationForAll(takenMedicationId: string, patientId: string, excludeDocumentIds: Array<string>): void {
		const records = this.data.filter((item: DoctorEncounterFakeRecord) =>
			filterPredicateForRecord(
				item,
				{ takenMedicationIds: [takenMedicationId], patientId, ignoreIds: excludeDocumentIds },
				this.translocoService
			)
		);
		records.forEach(record => {
			const index = record.takenMedicationIds.findIndex(item => item === takenMedicationId);

			if (index >= 0) {
				record.takenMedicationIds.splice(index, 1);
			}
		});
	}

	private mapFromRecordToDescribedComboboxDto(
		record: DoctorEncounterFakeRecord
	): DoctorEncounterComboboxDto {
		if (!isValueDefined(record)) {
			return undefined;
		}

		const diseases = this.database.diseasesTable.getComboboxesPagedCollection({ ids: record.diseaseIds });

		return {
			id: record.id,
			displayText: isArrayDefinedAndNotEmpty(diseases) ? diseases[0].displayText : 'Autogenerated',
			description: setTranslation(this.translocoService, record.comment),
			diseases,
			status: record.status,
			date: record.date
		};
	}

	private mapFromRecordToDto(record: DoctorEncounterFakeRecord): DoctorEncounterDto {
		return {
			id: record.id,
			patientId: record.patientId,
			diseases: this.database.diseasesTable.getComboboxesPagedCollection({ ids: record.diseaseIds }),
			date: record.date,
			comment: setTranslation(this.translocoService, record.comment),
			status: record.status,
			doctor: isValueDefined(record.doctor) ?
				{
					fullName: setTranslation(this.translocoService, record.doctor?.fullName),
					contacts: record.doctor?.contacts
				} :
				undefined,
			clinic: isValueDefined(record.clinic) ?
				{
					name: setTranslation(this.translocoService, record.clinic?.name),
					contacts: record.clinic?.contacts,
					address: setTranslation(this.translocoService, record.clinic?.address)
				} :
				undefined,
			healthIssue: this.database.healthIssueTable.findAsCombobox({ doctorEncounterId: record.id, patientId: record.patientId }),
			takenMedications: this.database.takenMedicationsTable.getComboboxesPagedCollection({ ids: record.takenMedicationIds }),
			documents: this.database.documentsTable.getComboboxesPagedCollection({ encounterIds: [record.id] }),
			createdOn: record.createdOn,
			updatedOn: record.updatedOn
		};
	}

}

const mapFromCreateParameterToRecord = (
	parameter: DoctorEncounterCreateParameterDto
): DoctorEncounterFakeRecord => (
	{
		id: Guid.newGuid(),
		patientId: parameter.patientId,
		diseaseIds: parameter.diseaseIds,
		comment: { en: parameter.comment, uk: parameter.comment },
		date: parameter.date,
		status: parameter.status,
		doctor: isValueDefined(parameter.doctor) ?
			{
				fullName: { en: parameter.doctor?.fullName, uk: parameter.doctor?.fullName },
				contacts: parameter.doctor?.contacts
			} :
			undefined,
		clinic: isValueDefined(parameter.clinic) ?
			{
				name: { en: parameter.clinic?.name, uk: parameter.clinic?.name },
				contacts: parameter.clinic?.contacts,
				address: { en: parameter.clinic?.address, uk: parameter.clinic?.address }
			} :
			undefined,
		takenMedicationIds: parameter.takenMedicationIds,
		createdOn: (new Date()).toISOString(),
		updatedOn: undefined
	}
);

const applyUpdateParameter = (
	record: DoctorEncounterFakeRecord,
	updateParameter: DoctorEncounterUpdateParameterDto
): void => {
	record.patientId = updateParameter.patientId;
	record.diseaseIds = updateParameter.diseaseIds;
	record.comment = { en: updateParameter.comment, uk: updateParameter.comment };
	record.date = updateParameter.date;
	record.status = updateParameter.status;
	record.doctor = isValueDefined(updateParameter.doctor) ?
		{
			fullName: { en: updateParameter.doctor?.fullName, uk: updateParameter.doctor?.fullName },
			contacts: updateParameter.doctor?.contacts
		} :
		undefined;
	record.clinic = isValueDefined(record.clinic) ?
		{
			name: { en: updateParameter.clinic?.name, uk: updateParameter.clinic?.name },
			contacts: updateParameter.clinic?.contacts,
			address: { en: updateParameter.clinic?.address, uk: updateParameter.clinic?.address }
		} :
		undefined;
	record.takenMedicationIds = updateParameter.takenMedicationIds;
	record.updatedOn = (new Date()).toISOString();
};

const findPredicate = (
	item: DoctorEncounterFakeRecord,
	findParameter: DoctorEncounterFindParameterDto
): boolean => {
	if (isValueDefined(findParameter)) {
		return isValueDefined(findParameter.takenMedicationId) ?
			item.takenMedicationIds?.indexOf(findParameter.takenMedicationId) > -1 :
			item.id === findParameter.id;
	}

	return true;
};

const filterPredicateForRecord = (
	item: DoctorEncounterFakeRecord,
	filter: DoctorEncounterFilterDto,
	_: TranslocoService
): boolean => {
	let result = true;

	if (result && isArrayDefined(filter.ids)) {
		result = result && filter.ids.some(id => item.id === id);
	}

	if (result && isArrayDefined(filter.ignoreIds)) {
		result = result && filter.ignoreIds.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isStringDefinedAndNotEmpty(filter.patientId)) {
		// TODO: implement to support patient aliases too
		// result = result && filter.patientId === item.patientId;
	}

	if (result && isArrayDefined(filter.takenMedicationIds)) {
		result = result &&
			filter.takenMedicationIds.some(id => item.takenMedicationIds.some(relationId => relationId === id));
	}

	if (result && isArrayDefined(filter.healthIssueIds)) {
		// todo: implement
	}

	return result;
};

// eslint-disable-next-line complexity
const filterPredicateForDto = (
	item: DoctorEncounterDto,
	filter: DoctorEncounterFilterDto
): boolean => {
	let result = true;

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result &&
			(
				isValueDefined(item.diseases.find(disease => disease.displayText.indexOf(filter.searchPattern))) ||
				item.comment?.indexOf(filter.searchPattern) >= 0 ||
				item.date?.indexOf(filter.searchPattern) >= 0
			);
	}

	if (result && isArrayDefinedAndNotEmpty(filter.ignoreIds)) {
		result = result && filter.ignoreIds.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isStringDefinedAndNotEmpty(filter.patientId)) {
		result = result && filter.patientId === item.patientId;
	}

	return result;
};

/* eslint-disable  */
/* eslint-disable complexity */
const compareAsComboboxesFn = (
	item1: DoctorEncounterComboboxDto,
	item2: DoctorEncounterComboboxDto,
	sorting: string
): number => {
	if (sorting === 'displayText asc') {
		return item1.displayText > item2.displayText ? 1 : item1.displayText < item2.displayText ? -1 : 0;
	} else if (sorting === 'displayText desc') {
		return item1.displayText < item2.displayText ? 1 : item1.displayText > item2.displayText ? -1 : 0;
	}

	return 0;
};

const compareFn = (item1: DoctorEncounterDto, item2: DoctorEncounterDto, sorting: string): number => {
	if (sorting === 'date asc') {

		return item1.date > item2.date ? 1 : item1.date < item2.date ? -1 : 0;
	} else if (sorting === 'date desc') {

		return item1.date < item2.date ? 1 : item1.date > item2.date ? -1 : 0;
	}

	return 0;
};
/* eslint-enable complexity */
/* eslint-enable */

interface DoctorEncounterFakeRecord {

	id: string;
	patientId: string;
	diseaseIds: Array<string>;
	comment?: FakeLocalizableEntity;
	date?: string;
	status: string;
	doctor?: {
		fullName?: FakeLocalizableEntity,
		contacts?: string
	};
	clinic?: {
		name?: FakeLocalizableEntity,
		contacts?: string,
		address?: FakeLocalizableEntity
	};
	takenMedicationIds: Array<string>;
	createdOn: string;
	updatedOn?: string;

}

// DoctorEncounterFakeRecord (initial data) has id mask 00000000-0000-0000-0301-************
const initialData: Array<DoctorEncounterFakeRecord> = [
	{
		id: 'doctor-encounter-fake-record-2',
		patientId: '00000000-0000-0000-0002-000000000001',
		diseaseIds: ['pneumonia'],
		comment: {
			en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
			uk: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
		},
		date: '2022-11-15',
		status: DoctorEncounterStatus.InProgress,
		doctor: {
			fullName: {
				en: 'Dr. Selena Nickson',
				uk: 'Доктор Селена Ніксон'
			},
			contacts: '+1 222 333 4445'
		},
		clinic: {
			name: {
				en: 'Mayo Clinic',
				uk: 'Клініка Майо'
			},
			contacts: '+1 222 333 5555',
			address: {
				en: '200 First Street Southwest Rochester, MN 55905-0001 United States',
				uk: 'Перша вул, буд. 200, Південно-західний Рочестер, Штат Міннесота 55905-0001 США'
			}
		},
		takenMedicationIds: ['00000000-0000-0000-0201-100000000001'],
		createdOn: '2022-11-16',
		updatedOn: '2022-11-17'
	},
	{
		id: 'doctor-encounter-fake-record-1',
		patientId: '00000000-0000-0000-0002-000000000001',
		diseaseIds: ['anxiety disorder', 'abdominal pain'],
		comment: {
			en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
			uk: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
		},
		date: undefined,
		status: DoctorEncounterStatus.Finished,
		doctor: {
			fullName: {
				en: 'Dr. Selena Nickson',
				uk: 'Доктор Селена Ніксон'
			},
			contacts: undefined
		},
		clinic: {
			name: {
				en: 'Mayo Clinic',
				uk: 'Клініка Майо'
			},
			contacts: undefined,
			address: {
				en: undefined,
				uk: undefined
			}
		},
		takenMedicationIds: ['00000000-0000-0000-0201-100000000002'],
		createdOn: '2021-07-12',
		updatedOn: '2021-11-17'
	},
	{
		id: '00000000-0000-0000-0301-100000000001',
		patientId: '00000000-0000-0000-0002-000000000001',
		diseaseIds: ['sprained-ankle'],
		date: '2021-02-02T12:00:00',
		status: DoctorEncounterStatus.Finished,
		comment: {
			en: 'The initial treatment is described as PRICE (Protect, Rest, Ice, Compression, and Elevation), together with avoiding HARM (Heat, Alcohol, Running, and Massage).\nPut a cold on the damaged region and avoid it.\n\nAdvil: for pain relief no often that 1 tablet in 8 hours',
			uk: 'Початкове лікування описується як PRICE (захист, відпочинок, лід, стиснення та підйом), разом із уникненням ШКОДИ (тепло, алкоголь, біг та масаж).\nПокладіть холод на пошкоджену ділянку та уникайте цього.\n\ nAdvil: для знеболення не частіше, ніж 1 таблетка на 8 годин'
		},
		doctor: {
			fullName: {
				en: 'Dr. Gregory House',
				uk: 'Доктор Грегорі Хаус'
			},
			contacts: '+1 222 333 4444'
		},
		clinic: {
			name: {
				en: 'St. Mary\'s General Hospital',
				uk: 'Загальна лікарня Сент-Меріс'
			},
			contacts: '+1 222 333 5555',
			address: {
				en: '350 Boulevard, Passaic, NJ 07055, United States',
				uk: 'Пассаїк буль. 350, Штат Нью-Йорк 07055, США'
			}
		},
		takenMedicationIds: ['00000000-0000-0000-0201-100000000001'],
		createdOn: '2021-02-02T12:00:00',
		updatedOn: '2021-02-20T12:00:00'
	},
	{
		id: '00000000-0000-0000-0301-100000000002',
		patientId: '00000000-0000-0000-0002-000000000001',
		diseaseIds: ['food-poisoning'],
		date: '2020-09-12T12:00:00',
		status: DoctorEncounterStatus.Finished,
		comment: {
			en: 'Levofloxacin: One tablet per day\nImodium: 1-2 tablets to decrease diarrhea symptoms. Drink enough water',
			uk: 'Левофлоксацин: одна таблетка на день\nІмодіум: 1-2 таблетки для зменшення симптомів діареї. Пийте достатньо води'
		},
		doctor: {
			fullName: {
				en: 'Dr. Gregory House',
				uk: 'Доктор Грегорі Хаус'
			},
			contacts: '+1 222 333 4444'
		},
		clinic: {
			name: {
				en: 'St. Mary\'s General Hospital',
				uk: 'Загальна лікарня Сент-Меріс'
			},
			contacts: '+1 222 333 5555',
			address: {
				en: '350 Boulevard, Passaic, NJ 07055, United States',
				uk: 'Пассаїк буль. 350, Штат Нью-Йорк 07055, США'
			}
		},
		takenMedicationIds: ['00000000-0000-0000-0201-200000000002', '00000000-0000-0000-0201-200000000001'],
		createdOn: '2020-09-12T12:00:00',
		updatedOn: '2020-09-15T12:00:00'
	},
	{
		id: '00000000-0000-0000-0301-100000000003',
		patientId: '00000000-0000-0000-0002-000000000001',
		diseaseIds: ['herpes-simplex'],
		date: '2020-04-11T12:00:00',
		status: DoctorEncounterStatus.Finished,
		comment: {
			en: 'Zovirax: Two times per day with 12-hours interval',
			uk: 'Зовіракс: 2 рази на добу з інтервалом 12 годин'
		},
		doctor: {
			fullName: {
				en: 'Dr. Selena Nickson',
				uk: 'Доктор Селена Ніксон'
			},
			contacts: '+1 222 333 4445'
		},
		clinic: {
			name: {
				en: 'Mayo Clinic',
				uk: 'Клініка Майо'
			},
			contacts: '+1 222 333 5555',
			address: {
				en: '200 First Street Southwest Rochester, MN 55905-0001 United States',
				uk: 'Перша вул, буд. 200, Південно-західний Рочестер, Штат Міннесота 55905-0001 США'
			}
		},
		takenMedicationIds: ['00000000-0000-0000-0201-300000000001'],
		createdOn: '2020-04-12T12:00:00',
		updatedOn: undefined
	},
	{
		id: '00000000-0000-0000-0301-100000000004',
		patientId: '00000000-0000-0000-0002-000000000001',
		diseaseIds: ['osteoarthritis'],
		date: '2018-12-04T12:00:00',
		status: DoctorEncounterStatus.Finished,
		comment: {
			en: 'Keep active lifestyles but limit any joint damage.\n\nCelecoxib: 200 mg once per day ot 100 mg two times per day\nAcetaminophen: Pain reduction, two 650-mg pills every 8 hours, no more than six pills (3,900 mg) daily.\nAspirin: Alternative for acetaminophen.',
			uk: 'Дотримуйтеся активного способу життя, але обмежте пошкодження суглобів.\n\nЦелекоксиб: 200 мг один раз на день або 100 мг два рази на день\nАцетамінофен: зменшення болю, дві таблетки по 650 мг кожні 8 годин, не більше шести таблеток (3900 мг) щодня.\nАспірин: Альтернатива ацетамінофену.'
		},
		doctor: {
			fullName: {
				en: 'Dr. Selena Nickson',
				uk: 'Доктор Селена Ніксон'
			},
			contacts: '+1 222 333 4445'
		},
		clinic: {
			name: {
				en: 'Mayo Clinic',
				uk: 'Клініка Майо'
			},
			contacts: '+1 222 333 5555',
			address: {
				en: '200 First Street Southwest Rochester, MN 55905-0001 United States',
				uk: 'Перша вул, буд. 200, Південно-західний Рочестер, Штат Міннесота 55905-0001 США'
			}
		},
		takenMedicationIds: [
			'00000000-0000-0000-0201-400000000003',
			'00000000-0000-0000-0201-400000000002',
			'00000000-0000-0000-0201-400000000001'
		],
		createdOn: '2018-12-04T12:00:00',
		updatedOn: undefined
	},
	{
		id: '00000000-0000-0000-0301-100000000005',
		patientId: '00000000-0000-0000-0002-000000000001',
		diseaseIds: ['gastroesophageal-reflux-disease'],
		date: '2018-01-09T12:00:00',
		status: DoctorEncounterStatus.Finished,
		comment: {
			en: 'Schedule a check-up  if there is  sudden or progressive worsening of symptoms.\nDischarged to home.\nAdd Levo 750 mg PO q48h—patient given written prescription. \nInsurance issues precluded obtaining Levo 750 mg prescription, attempt to obtain insurance coverage for medication started by clinic staff.\n\nDexilant: One per day with or without school\nMylanta: Heartburn symptoms reduction',
			uk: 'Призначте обстеження, якщо спостерігається раптове або прогресуюче погіршення симптомів.\nВиписаний додому.\nДодайте Levo 750 мг перорально кожні 48 годин — пацієнт отримує письмове призначення. \nПроблеми зі страхуванням перешкоджали отримати рецепт Levo 750 мг, спробувати отримати страхове покриття для ліків, розпочатих персоналом клініки.\n\nДексилан: один на день із навчанням або без нього\nМиланта: зменшення симптомів печії'
		},
		doctor: {
			fullName: {
				en: 'Dr. Selena Nickson',
				uk: 'Доктор Селена Ніксон'
			},
			contacts: '+1 222 333 4445'
		},
		clinic: {
			name: {
				en: 'Mayo Clinic',
				uk: 'Клініка Майо'
			},
			contacts: '+1 222 333 5555',
			address: {
				en: '200 First Street Southwest Rochester, MN 55905-0001 United States',
				uk: 'Перша вул, буд. 200, Південно-західний Рочестер, Штат Міннесота 55905-0001 США'
			}
		},
		takenMedicationIds: ['00000000-0000-0000-0201-500000000002', '00000000-0000-0000-0201-500000000001'],
		createdOn: '2018-04-12T12:00:00',
		updatedOn: undefined
	},
	{
		id: '00000000-0000-0000-0301-100000000006',
		patientId: '00000000-0000-0000-0002-000000000001',
		diseaseIds: ['bronchial-asthma'],
		date: '2015-03-19T12:00:00',
		status: DoctorEncounterStatus.Finished,
		comment: {
			en: 'See a doctor or nurse if symptoms are not fully controlled\n\nAlbuterol: Three or four times per day\nSerevent Diskus: 1 inhalation (50 mcg) twice daily, approximately 12 hours apart',
			uk: 'Зверніться до лікаря або медсестри, якщо симптоми не повністю контролюються\n\nАльбутерол: три або чотири рази на день\nСеревент Дискус: 1 інгаляція (50 мкг) двічі на день з інтервалом приблизно 12 годин'
		},
		doctor: {
			fullName: {
				en: 'Dr. Selena Nickson',
				uk: 'Доктор Селена Ніксон'
			},
			contacts: '+1 222 333 4445'
		},
		clinic: {
			name: {
				en: 'Mayo Clinic',
				uk: 'Клініка Майо'
			},
			contacts: '+1 222 333 5555',
			address: {
				en: '200 First Street Southwest Rochester, MN 55905-0001 United States',
				uk: 'Перша вул, буд. 200, Південно-західний Рочестер, Штат Міннесота 55905-0001 США'
			}
		},
		takenMedicationIds: ['00000000-0000-0000-0201-600000000002', '00000000-0000-0000-0201-600000000001'],
		createdOn: '2015-03-19T12:00:00',
		updatedOn: undefined
	}
];

// eslint-disable-next-line max-lines
