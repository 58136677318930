import { Result } from '@nmn-core/shared';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { CommandResult, EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { HealthIssueCreateCommand } from '../models/commands/health-issue.create.command';
import { HealthIssueDeleteCommand } from '../models/commands/health-issue.delete.command';
import { HealthIssueUpdateCommand } from '../models/commands/health-issue.update.command';

export abstract class HealthIssueCommandHandlerService {

	public abstract handleCreateCommand(command: HealthIssueCreateCommand): Observable<Result<CommandResult<HealthIssueCreateCommand, string>, FailureModel>>;

	public abstract handleDeleteCommand(command: HealthIssueDeleteCommand): Observable<Result<EmptyCommandResult<HealthIssueDeleteCommand>, FailureModel>>;

	public abstract handleUpdateCommand(command: HealthIssueUpdateCommand): Observable<Result<EmptyCommandResult<HealthIssueUpdateCommand>, FailureModel>>;

}
