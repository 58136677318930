export class TakenMedicationFilterModel {

	public readonly ids?: Array<string>;
	public readonly ignoreIds?: Array<string>;
	public readonly searchPattern?: string;
	public readonly patientIds?: Array<string>;
	public readonly onlyDetachedOrAttachedToMedicalHistoryRecordIds?: Array<string>;
	public readonly isIncompatibleWithOtherTakenMedication?: boolean;
	public readonly isEfficacyUnproven?: boolean;
	public readonly isIncompatibleWithAlcohol?: boolean;

	constructor(
		ids: Array<string> | undefined,
		ignoreIds: Array<string> | undefined,
		searchPattern: string | undefined,
		patientIds: Array<string> | undefined,
		onlyDetachedOrAttachedToMedicalHistoryRecordIds: Array<string> | undefined,
		isIncompatibleWithOtherTakenMedication: boolean | undefined,
		isEfficacyUnproven: boolean | undefined,
		isIncompatibleWithAlcohol: boolean | undefined
	) {
		this.ids = ids;
		this.ignoreIds = ignoreIds;
		this.searchPattern = searchPattern;
		this.patientIds = patientIds;
		this.onlyDetachedOrAttachedToMedicalHistoryRecordIds = onlyDetachedOrAttachedToMedicalHistoryRecordIds;
		this.isIncompatibleWithOtherTakenMedication = isIncompatibleWithOtherTakenMedication;
		this.isEfficacyUnproven = isEfficacyUnproven;
		this.isIncompatibleWithAlcohol = isIncompatibleWithAlcohol;
	}

	public static createForTakenMedicationAutocomplete(
		patientId: string,
		searchPattern: string,
		ignoreIds: Array<string>
	): TakenMedicationFilterModel {
		return new TakenMedicationFilterModel(
			undefined,
			ignoreIds,
			searchPattern,
			[patientId],
			undefined,
			undefined,
			undefined,
			undefined);
	}

	public static createForTakenMedicationsAutocomplete(
		patientId: string,
		searchPattern: string,
		ignoreIds: Array<string>
	): TakenMedicationFilterModel {
		return new TakenMedicationFilterModel(
			undefined,
			ignoreIds,
			searchPattern,
			[patientId],
			undefined,
			undefined,
			undefined,
			undefined);
	}

}
