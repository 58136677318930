import { DocumentCreateCommand } from '@nmn-domain/documents';
import { TakenMedicationFormOutModel } from '../form/taken-medication-form.out-model';

export class TakenMedicationCreateCommand {

	public readonly from: Date | undefined;
	public readonly to: Date | undefined;
	public readonly comment: string | undefined;
	public readonly dosageValue: number | undefined;
	public readonly rateValue: number | undefined;
	public readonly patientId: string;
	public readonly medicationId: string;
	public readonly encounterId: string | undefined;
	public readonly healthIssueId: string | undefined;
	public readonly medicationAdmStatusId: string;
	public readonly medicationDosageId: string | undefined;
	public readonly medicationAdmRouteId: string | undefined;
	public readonly medicationBodySiteId: string | undefined;
	public readonly medicationRateQuantityTypeId: string | undefined;
	public readonly documentIds: Array<string>;
	public readonly documents: Array<DocumentCreateCommand>;
	public readonly typeOfMedicationId: string;

	private constructor(
		from: Date | undefined,
		to: Date | undefined,
		comment: string | undefined,
		dosageValue: number | undefined,
		rateValue: number | undefined,
		patientId: string,
		medicationId: string,
		encounterId: string | undefined,
		healthIssueId: string | undefined,
		medicationAdmStatusId: string,
		medicationDosageId: string | undefined,
		medicationAdmRouteId: string | undefined,
		medicationBodySiteId: string | undefined,
		medicationRateQuantityTypeId: string | undefined,
		documentIds: Array<string>,
		documents: Array<DocumentCreateCommand>,
		typeOfMedicationId: string
	) {
		this.from = from;
		this.to = to;
		this.comment = comment;
		this.dosageValue = dosageValue;
		this.rateValue = rateValue;
		this.patientId = patientId;
		this.medicationId = medicationId;
		this.encounterId = encounterId;
		this.healthIssueId = healthIssueId;
		this.medicationAdmStatusId = medicationAdmStatusId;
		this.medicationDosageId = medicationDosageId;
		this.medicationAdmRouteId = medicationAdmRouteId;
		this.medicationBodySiteId = medicationBodySiteId;
		this.medicationRateQuantityTypeId = medicationRateQuantityTypeId;
		this.documentIds = documentIds;
		this.documents = documents;
		this.typeOfMedicationId = typeOfMedicationId;
	}

	public static createByFormOutModel(formOutModel: TakenMedicationFormOutModel): TakenMedicationCreateCommand {
		return new TakenMedicationCreateCommand(
			formOutModel.takingPeriod.from,
			formOutModel.takingPeriod.to,
			formOutModel.comment,
			formOutModel.dosage.value,
			formOutModel.rate.value,
			formOutModel.patientId,
			formOutModel.medicationId,
			formOutModel.encounterId,
			formOutModel.healthIssueId,
			formOutModel.medicationAdmStatusId,
			formOutModel.dosage.typeId,
			formOutModel.medicationAdmRouteId,
			formOutModel.medicationBodySiteId,
			formOutModel.rate.typeId,
			formOutModel.documentIds,
			formOutModel.documents.map(DocumentCreateCommand.createByFormOutModel),
			formOutModel.typeOfMedicationId
		);
	}

}
