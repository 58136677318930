import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { DocumentTypeDescribedComboboxDto } from '../models/document-type-described-combobox.dto';
import { DocumentTypeFilterDto } from '../models/document-type-filter.dto';

export abstract class DocumentTypeClient {

	public abstract getBulkAsComboboxes(
		filter: DocumentTypeFilterDto
	): Observable<Result<Array<DocumentTypeDescribedComboboxDto>, Failure>>;

}
