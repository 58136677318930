import { Injector } from '@angular/core';
import { VaccineClient } from '@nmn-communication/vaccines';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { VaccineFakeClientBuilder } from '../builders/vaccine.fake-client.builder';
import { VaccineFakeClientConfigurationBuilder } from '../builders/vaccine.fake-client.configuration.builder';
import { VaccineHttpClientBuilder } from '../builders/vaccine.http-client.builder';
import { VaccineHttpClientConfigurationBuilder } from '../builders/vaccine.http-client.configuration.builder';
import { VaccineFakeClient } from '../services/vaccine.fake-client';
import { VaccineHttpClient } from '../services/vaccine.http-client';

export const vaccineClientFactory = (injector: Injector): VaccineClient => {
	const useFake = environment?.api?.resources?.vaccines?.useFake ||
		!isValueDefined(environment?.api?.resources?.vaccines?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		vaccineFakeClientFactory(injector) :
		vaccineHttpClientFactory(injector);
};

export const vaccineFakeClientFactory = (injector: Injector): VaccineFakeClient => {
	const configuration = new VaccineFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.vaccines.serverDelayMs)
		.build();

	return new VaccineFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const vaccineHttpClientFactory = (injector: Injector): VaccineHttpClient => {
	const configuration = new VaccineHttpClientConfigurationBuilder()
		.withBaseUrl(environment?.api?.resources?.vaccines?.baseUrl ?? environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.vaccines.resourceRelativePath)
		.withResourceTypeVersion(environment?.api?.resources?.vaccines?.resourceTypeVersion ?? environment?.api?.defaultResourceTypeVersion)
		.build();

	return new VaccineHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
