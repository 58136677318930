import { CurrentUserConfigurationClient, UserConfigurationDto, UserConfigurationUpdateParameter } from '@nmn-communication/accounts';
import { Failure } from '@nmn-communication/shared';
import { LocalStorageService } from '@nmn-core/application-storages';
import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../../modules/fakes/databases/fake.database';
import { wrapActionCallAsFakeClientResponse, wrapFuncCallAsFakeClientResponse } from '../../../../../modules/fakes/databases/fake.utils';
import { UserConfigurationFakeClientConfiguration } from '../configurations/user-configuration.fake-client.configuration';

export class CurrentUserConfigurationFakeClient extends CurrentUserConfigurationClient {

	constructor(
		private readonly configuration: UserConfigurationFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase,
		private readonly localStorage: LocalStorageService
	) {
		super();
	}

	public get(): Observable<Result<UserConfigurationDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.userConfigurationFakeTable.get(this.localStorage.getLastUserId()),
			this.configuration.serverDelayMs
		);
	}

	public update(parameter: UserConfigurationUpdateParameter): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.userConfigurationFakeTable.update(this.localStorage.getLastUserId(), parameter);
			},
			this.configuration.serverDelayMs
		);
	}

}
