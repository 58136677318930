import { isValueDefined } from './value.utils';

export const scrollToElement = (element: HTMLElement): void => {
	if (isValueDefined(element)) {
		element
			.scrollIntoView(
				{
					behavior: 'smooth',
					block: 'start',
					inline: 'nearest'
				});
	}
};
