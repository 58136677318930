import { AfterViewInit, Directive, EventEmitter, Input, OnDestroy } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { debounceConfigChanges } from '@nmn-core/utils';
import { NmnMatExpansionListUserConfiguration, NmnMatTabGroupUserConfiguration, NmnMatTableUserConfiguration } from '@nmn-domain/accounts';
import { FailureModel } from '@nmn-domain/shared';
import { FailureLocalizationModel } from '@nmn-domain/shared/failures/failure-localization-parameters.model';
import { FailureWrapperModel } from '@nmn-domain/shared/failures/failure-wrapper.model';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { FailureHandlingService } from '../../../../services';
import { UserConfigurationService } from './user-configuration.service';

@Directive({
	selector: '[nmnUserConfigurable]'
})
export class UserConfigurationDirective implements AfterViewInit, OnDestroy {

	// Is used for passing component service reference that would be changed.
	@Input() public nmnUserConfigurable: any;
	// Is used for passing atomic configuration of concrete component.
	@Input() public nmnUserConfigurationAtomic: any;

	private readonly localizationPattern: string = 'modules.accounts.userConfiguration';
	private readonly destroyed$ = new Subject<undefined>();

	constructor(
		private readonly failureHandlingService: FailureHandlingService,
		private readonly userConfigurationService: UserConfigurationService
	) { }

	public ngAfterViewInit(): void {
		const service = this.nmnUserConfigurable;
		const config = this.nmnUserConfigurationAtomic;

		if (service instanceof MatExpansionPanel && config instanceof NmnMatExpansionListUserConfiguration) {
			this.manageMatExpansionPanel(service, config);
		}

		if (service instanceof MatSort && config instanceof NmnMatTableUserConfiguration) {
			this.manageMatSort(service, config);
		}

		if (service instanceof MatPaginator && config instanceof NmnMatTableUserConfiguration) {
			this.manageMatPaginator(service, config);
		}

		if (service instanceof MatTabGroup && config instanceof NmnMatTabGroupUserConfiguration) {
			this.manageMatTabGroup(service, config);
		}

		// handle the behavior of other components.
	}

	public ngOnDestroy(): void {
		this.destroyed$.next(undefined);
		this.destroyed$.complete();
	}

	private manageMatExpansionPanel(service: MatExpansionPanel, config: NmnMatExpansionListUserConfiguration): void {
		this.manageEvent(service.expandedChange, (result: boolean) => { config.isExpanded = result; });
	}

	private manageMatSort(service: MatSort, config: NmnMatTableUserConfiguration): void {
		this.manageEvent(service.sortChange, (result: Sort) => { config.sortingColumn = result.active; config.sortingDirection = result.direction; });
	}

	private manageMatPaginator(service: MatPaginator, config: NmnMatTableUserConfiguration): void {
		this.manageEvent(service.page, (result: PageEvent) => { config.pageSize = result.pageSize; config.pageIndex = result.pageIndex; });
	}

	private manageMatTabGroup(service: MatTabGroup, config: NmnMatTabGroupUserConfiguration): void {
		this.manageEvent(service.selectedTabChange, (result: MatTabChangeEvent) => { config.selectedIndex = result.index.toString(); });
	}

	private manageEvent<T>(event: EventEmitter<T>, configChangesAction: Function): void {
		event
			.pipe(
				tap(result => { configChangesAction(result); }),
				debounceConfigChanges(),
				takeUntil(this.destroyed$)
			)
			.subscribe({
				next: _ => { this.userConfigurationService.notifyOnChanges(); },
				error: error => { this.failureHandlingService.handleFailure(FailureModel.createForSubscribtionIssue(FailureWrapperModel.createFromValue(error), FailureLocalizationModel.createFromValue(this.localizationPattern))); }
			});
	}

}
