import { Injectable } from '@angular/core';
import { ServerConfigurationClient } from '@nmn-communication/server-configuration';
import { Result } from '@nmn-core/shared';
import { ServerConfigurationGetQuery, ServerConfigurationModel, ServerConfigurationQueryHandlerService } from '@nmn-domain/server-configuration';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapDtoToModel } from '../factories/server-configuration.factory';

@Injectable()
export class ServerConfigurationQueryHandlerViaClientService extends ServerConfigurationQueryHandlerService {

	constructor(
		private readonly client: ServerConfigurationClient
	) {
		super();
	}

	public get(_: ServerConfigurationGetQuery): Observable<Result<ServerConfigurationModel, FailureModel>> {
		return this.client
			.get()
			.pipe(
				map(result => result.map(mapDtoToModel, mapFailureToFailureModel))
			);
	}

}
