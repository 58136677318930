export class VaccinationDeleteCommand {

	public readonly id: string;
	public readonly patientId: string;

	public constructor(
		id: string,
		patientId: string
	) {
		// TODO: Guard add null check

		this.id = id;
		this.patientId = patientId;
	}

}
