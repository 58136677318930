import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TreatmentCheckerFormConfiguration } from '../models/treatment-checker-form/treatment-checker-form.configuration';

export abstract class TreatmentCheckerFormConfigurationResolver implements Resolve<TreatmentCheckerFormConfiguration> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	): Observable<TreatmentCheckerFormConfiguration> | TreatmentCheckerFormConfiguration;

}
