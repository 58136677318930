import { UserConfigurationModel } from '../user-configuration.model';

export class CurrentUserConfigurationUpdateCommand {

	public readonly settings: string;
	public readonly patientAlias?: string;

	private constructor(
		settings: string,
		patientAlias?: string
	) {

		// TODO: Guard check on defined
		this.settings = settings;
		this.patientAlias = patientAlias;
	}

	public static createFromExistingConfiguration(
		configurationModel: UserConfigurationModel,
		patientAlias?: string
	): CurrentUserConfigurationUpdateCommand {
		return new CurrentUserConfigurationUpdateCommand(
			JSON.stringify(configurationModel),
			patientAlias
		);
	}

}
