import { Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../../modules/fakes/databases/fake.database';
import { EmailConfirmationFakeClientConfiguration } from '../configurations/email-confirmation.fake-client.configuration';
import { EmailConfirmationFakeClient } from '../services/email-confirmation.fake-client';

export class EmailConfirmationFakeClientBuilder {

	private configuration: EmailConfirmationFakeClientConfiguration;

	constructor(private readonly injector: Injector) { }

	public withConfiguration(
		configuration: EmailConfirmationFakeClientConfiguration
	): EmailConfirmationFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): EmailConfirmationFakeClient {
		return new EmailConfirmationFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
