export class ComboboxModel {

	public readonly id: string;
	public readonly displayText: string;

	constructor(
		id: string,
		displayText: string
	) {
		// TODO: check on null
		this.id = id;
		this.displayText = displayText;
	}

}
