import { Injectable } from '@angular/core';
import { filterTrue, isFunctionDefined, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { notProvided } from '@nmn-domain/server-configuration/constants';
import { MonitoringService } from 'app/services';
import { BehaviorSubject } from 'rxjs';
import { FacebookAuthResponse } from '../models/facebook-auth-response';

declare const FB: any;

// @see https://developers.facebook.com/products/facebook-login/
@Injectable({ providedIn: 'root' })
export class FacebookAuthService {

	private readonly isFbApiReadySubject$ = new BehaviorSubject<boolean>(false);

	public clientId: string;
	public sdkNonce: string;

	public get isClientIdDefined(): boolean { return isStringDefinedAndNotEmpty(this.clientId) && this.clientId !== notProvided; }
	public get isSdkNonceDefined(): boolean { return isStringDefinedAndNotEmpty(this.sdkNonce) && this.sdkNonce !== notProvided; }

	private get fbApi() { return FB; }

	constructor(
		private readonly monitoringService: MonitoringService
	) { }

	public initialize(facebookClientId: string, facebookSdkNonce: string): void {
		this.clientId = facebookClientId;
		this.sdkNonce = facebookSdkNonce;
		this.loadFacebookSdkScript();
	}

	public initiateAuthViaEmailFlow(handleAuthResponseCallback: (authResponse: FacebookAuthResponse) => void): void {
		if (this.isClientIdDefined) {
			this.isFbApiReadySubject$
				.pipe(filterTrue())
				.subscribe({
					next: _ => {
						this.fbApi.login(
							(result: any) => {
								if (isFunctionDefined(handleAuthResponseCallback) && result?.status === 'success') {
									handleAuthResponseCallback(FacebookAuthResponse.createFromFacebookAuthResponseObj(result?.authResponse));
								}
							},
							{ scope: 'email' }
						);
					},
					error: error => { this.monitoringService.logException(error); }
				});
		}
	}

	private loadFacebookSdkScript(): void {
		if (this.isClientIdDefined) {
			const sdkScript = document.createElement('script');

			sdkScript.src = `https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v16.0&appId=${this.clientId}&autoLogAppEvents=1`;
			sdkScript.async = true;
			sdkScript.defer = true;
			sdkScript.crossOrigin = 'anonymous';
			sdkScript.onload = () => {
				if (isValueDefined((window as any).FB)) {
					this.isFbApiReadySubject$.next(true);
				}
			};

			if (this.isSdkNonceDefined) {
				sdkScript.nonce = this.sdkNonce;
			}

			document.body.appendChild(sdkScript);
		}
	}

}
