import { ComboboxModel } from '@nmn-domain/shared';
import { SourceModel } from '@nmn-domain/sources';

export class TreatmentCheckerPersonalizedMedicineGuidelineModel extends ComboboxModel {

	public readonly sources: Array<SourceModel>;

	constructor(
		id: string,
		displayText: string,
		sources: Array<SourceModel>
	) {
		super(id, displayText);

		// TODO: check on defined
		this.sources = sources;
	}
}
