import { Provider } from '@angular/core';
import { HealthIssueCommandHandlerService, HealthIssueQueryHandlerService, HealthIssueResolver, HealthIssueStatusesResolver } from '@nmn-domain/health-issues';
import { HealthIssueStatusesViaEnumResolver } from './resolvers/health-issue-status-via-enum.resolver';
import { HealthIssueViaQueryHandlerResolver } from './resolvers/health-issue-via-query-handler.resolver';
import { HealthIssueCommandHandlerViaClientService } from './services/health-issue.command-handler-via-client.service';
import { HealthIssueQueryHandlerViaClientService } from './services/health-issue.query-handler-via-client.service';

export const healthIssueProviders: Array<Provider> = [
	{
		provide: HealthIssueQueryHandlerService,
		useClass: HealthIssueQueryHandlerViaClientService
	},
	{
		provide: HealthIssueCommandHandlerService,
		useClass: HealthIssueCommandHandlerViaClientService
	},
	{
		provide: HealthIssueResolver,
		useClass: HealthIssueViaQueryHandlerResolver
	},
	{
		provide: HealthIssueStatusesResolver,
		useClass: HealthIssueStatusesViaEnumResolver
	}
];
