import { UserProfileModel } from '@nmn-domain/accounts/user-accounts/models/user-profile.model';
import { UploadedFileModel } from '@nmn-domain/file-uploader';
import { PopulationComboboxModel } from '@nmn-domain/populations';
import { ComboboxModel, DescribedComboboxModel } from '@nmn-domain/shared';
import { PatientModel } from '../patient.model';

export class PatientGeneralInfoFormInModel {

	public readonly picture: UploadedFileModel;
	public readonly givenName: string;
	public readonly additionalName: string;
	public readonly familyName: string;
	public readonly population: PopulationComboboxModel;
	public readonly gender: DescribedComboboxModel;
	public readonly isPregnant?: boolean;
	public readonly birthday: Date;
	public readonly email: string;
	public readonly phoneNumber: string;
	public readonly address: string;
	public readonly languages: Array<ComboboxModel>;
	public readonly preferredLanguage: ComboboxModel;

	private constructor(
		picture: UploadedFileModel | undefined,
		givenName: string | undefined,
		additionalName: string | undefined,
		familyName: string | undefined,
		population: PopulationComboboxModel | undefined,
		gender: DescribedComboboxModel | undefined,
		isPregnant: boolean | undefined,
		birthday: Date | undefined,
		email: string | undefined,
		phoneNumber: string | undefined,
		address: string | undefined,
		languages: Array<ComboboxModel>,
		preferredLanguage: ComboboxModel | undefined
	) {
		this.picture = picture;
		this.givenName = givenName;
		this.additionalName = additionalName;
		this.familyName = familyName;
		this.population = population;
		this.gender = gender;
		this.isPregnant = isPregnant;
		this.birthday = birthday;
		this.email = email;
		this.phoneNumber = phoneNumber;
		this.address = address;
		this.languages = languages;
		this.preferredLanguage = preferredLanguage;
	}

	public static createEmpty = (): PatientGeneralInfoFormInModel => {
		return new PatientGeneralInfoFormInModel(
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			[],
			undefined
		);
	}

	public static createByUserProfile = (userProfile: UserProfileModel): PatientGeneralInfoFormInModel => {
		return new PatientGeneralInfoFormInModel(
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			userProfile?.email,
			undefined,
			undefined,
			[],
			undefined
		);
	}

	public static createByPatient = (patient: PatientModel): PatientGeneralInfoFormInModel => {
		return new PatientGeneralInfoFormInModel(
			patient?.picture,
			patient?.givenName,
			patient?.additionalName,
			patient?.familyName,
			patient?.population,
			patient?.gender,
			patient?.lifestyle?.isPregnant,
			patient?.birthday,
			patient?.email,
			patient?.phoneNumber,
			patient?.address,
			patient?.languages ?? [],
			patient?.preferredLanguage
		);
	}

}
