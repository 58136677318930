import { Injectable } from '@angular/core';
import { RegionClient } from '@nmn-communication/regions';
import { Result } from '@nmn-core/shared';
import { RegionComboboxModel, RegionFilterModel, RegionQueryHandlerService } from '@nmn-domain/regions';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapDtoArrayResultToModelArrayResult } from '../../shared/factories/pagination.factory';
import { mapRegionComboboxDtoToModel, mapRegionFilterModelToDto } from '../factories/region.factory';

@Injectable()
export class RegionQueryHandlerViaClientService extends RegionQueryHandlerService {

	constructor(
		private readonly client: RegionClient
	) {
		super();
	}

	public getAsComboboxes(filter: RegionFilterModel): Observable<Result<Array<RegionComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapRegionFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapRegionComboboxDtoToModel))
			);
	}

}
