import { Injector, Provider } from '@angular/core';
import { HealthComplaintClient } from '@nmn-communication/health-complaints';
import { healthComplaintClientFactory } from './factories/health-complaint.client.factory';

export const healthComplaintProviders: Array<Provider> = [
	{
		provide: HealthComplaintClient,
		useFactory: healthComplaintClientFactory,
		deps: [Injector]
	}
];
