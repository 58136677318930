import { BlockBlobClient } from '@azure/storage-blob';
import { AzureStorageClient, AzureStorageCreateParameterDto, AzureStorageInitedUploadDto } from '@nmn-communication/azure-file-storage';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class AzureStorageHttpClient extends AzureStorageClient {

	constructor(
		protected readonly blockBlobClient: BlockBlobClient
	) {
		super();

	}

	public initBlobUpload(
		parameter: AzureStorageCreateParameterDto
	): Observable<Result<AzureStorageInitedUploadDto, Failure>> {
		return from(
			this.blockBlobClient.uploadData(
				parameter.file,
				{
					abortSignal: parameter.abortSignal,
					blobHTTPHeaders: {
						blobContentType: parameter.file.type
					},
					onProgress: parameter.onProgress?.bind(this)
				}
			)
		)
			.pipe(
				map(
					blockBlobHeaders => {
						const dto: AzureStorageInitedUploadDto = {
							data: blockBlobHeaders
						};

						return Result.success(dto);
					},
					error => {
						return Result.failure(Failure.createUndefined(error));
					}
				)
			);
	}

}
