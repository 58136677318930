export class RegisterAccountCommand {

	public readonly email: string;
	public readonly password: string;
	public readonly confirmPassword: string;
	public readonly isEmailNotificationsEnabled: boolean;
	public readonly pictureId?: string;
	public readonly locale?: string;
	public readonly timezone?: string;

	constructor(
		email: string,
		password: string,
		confirmPassword: string,
		isEmailNotificationsEnabled: boolean,
		pictureId: string | undefined,
		locale: string | undefined,
		timezone: string | undefined
	) {
		//TODO: Guard.NotNull

		this.email = email;
		this.password = password;
		this.confirmPassword = confirmPassword;
		this.isEmailNotificationsEnabled = isEmailNotificationsEnabled;
		this.pictureId = pictureId;
		this.locale = locale;
		this.timezone = timezone;
	}

}
