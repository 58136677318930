import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginClient, LoginDto, LoginParameter, LoginViaFacebookParameter, LoginViaGoogleParameter, RefreshTokenParameter, TokenInfoDto } from '@nmn-communication/accounts';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { createFailedResultFromErrorResponse, createResultFromResponse } from '../../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { LoginHttpClientConfiguration } from '../configurations/login.http-client.configuration';

export class LoginHttpClient extends LoginClient {

	constructor(
		private readonly configuration: LoginHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public login(parameter: LoginParameter): Observable<Result<LoginDto, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/internal/signIn`;
		const payload = {
			email: parameter.login,
			password: parameter.password,
			isPersistent: parameter.isPersistent
		};
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post<LoginDto>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public loginViaGoogle(parameter: LoginViaGoogleParameter): Observable<Result<LoginDto, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/external/google/signInOrSignUp`;
		const payload = {
			idToken: parameter.idToken
		};
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post<LoginDto>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public loginViaFacebook(parameter: LoginViaFacebookParameter): Observable<Result<LoginDto, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/external/facebook/signInOrSignUp`;
		const payload = {
			accessToken: parameter.accessToken
		};
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post<LoginDto>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public refreshToken(parameter: RefreshTokenParameter): Observable<Result<TokenInfoDto, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/refreshToken`;
		const payload = {
			refreshToken: parameter.refreshToken
		};
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post<TokenInfoDto>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

}
