import { PatientAnamnesisModel } from '../patient-anamnesis.model';
import { PatientLifestyleModel } from '../patient-lifestyle.model';

export class PatientCreateCommand {

	public readonly pictureId?: string;
	public readonly givenName: string;
	public readonly additionalName: string;
	public readonly familyName: string;
	public readonly populationId: string;
	public readonly genderId: string;
	public readonly birthday: Date;
	public readonly phoneNumber: string;
	public readonly email: string;
	public readonly address: string;
	public readonly languageIds: Array<string>;
	public readonly preferredLanguageId: string;

	public readonly bloodTypeId: string;
	public readonly height: number;
	public readonly weight: number;

	public readonly allergyIntolerances: Array<{
		allergyId: string;
		criticalityId: string;
		statusId: string;
		verificationStatusId: string;
		lastOccurrence?: Date;
	}>;
	public readonly seriousPathologyIds: Array<string>;
	public readonly familyDiseaseIds: Array<string>;
	public readonly regularlyTakenMedicationIds: Array<string>;

	public readonly doctors: Array<{ fullName: string; contacts: string; }>;
	public readonly emergencyContacts: Array<{ fullName: string; data: string; }>;

	public readonly insurances: Array<{ company: string; code: string; }>;

	public readonly anamnesis: PatientAnamnesisModel;
	public readonly lifestyle: PatientLifestyleModel;

	constructor(
		pictureId: string | undefined,
		givenName: string,
		additionalName: string | undefined,
		familyName: string,
		populationId: string,
		genderId: string,
		birthday: Date,
		phoneNumber: string | undefined,
		email: string,
		address: string | undefined,
		languageIds: Array<string>,
		preferredLanguageId: string,
		bloodTypeId: string | undefined,
		height: number | undefined,
		weight: number | undefined,
		allergyIntolerances: Array<{
			allergyId: string;
			criticalityId: string;
			statusId: string;
			verificationStatusId: string;
			lastOccurrence?: Date;
		}>,
		seriousPathologyIds: Array<string>,
		familyDiseaseIds: Array<string>,
		regularlyTakenMedicationIds: Array<string>,
		doctors: Array<{ fullName: string; contacts: string; }>,
		emergencyContacts: Array<{ fullName: string; data: string; }>,
		insurances: Array<{ company: string; code: string; }>,
		anamnesis: PatientAnamnesisModel,
		lifestyle: PatientLifestyleModel
	) {
		// TODO: add Guard checks

		this.pictureId = pictureId;
		this.givenName = givenName;
		this.additionalName = additionalName;
		this.familyName = familyName;
		this.populationId = populationId;
		this.genderId = genderId;
		this.birthday = birthday;
		this.phoneNumber = phoneNumber;
		this.email = email;
		this.address = address;
		this.languageIds = languageIds;
		this.preferredLanguageId = preferredLanguageId;

		this.bloodTypeId = bloodTypeId;
		this.height = height;
		this.weight = weight;

		this.allergyIntolerances = allergyIntolerances;
		this.seriousPathologyIds = seriousPathologyIds;
		this.familyDiseaseIds = familyDiseaseIds;
		this.regularlyTakenMedicationIds = regularlyTakenMedicationIds;

		this.doctors = doctors;
		this.emergencyContacts = emergencyContacts;
		this.insurances = insurances;

		this.anamnesis = anamnesis;
		this.lifestyle = lifestyle;
	}

}
