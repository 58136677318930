import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { HttpClientConfiguration } from '../configurations/http-client.configuration';

export abstract class HttpClientConfigurationBuilder<THttpClientConfiguration extends HttpClientConfiguration> {

	/* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
	private _baseUrl = '';
	private _resourceRelativePath = '';
	private _resourceTypeVersion = 'v1';
	/* eslint-enable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

	protected get baseUrl(): string {
		return this._baseUrl;
	}

	protected get resourceRelativePath(): string {
		return this._resourceRelativePath;
	}

	protected get resourceTypeVersion(): string {
		return this._resourceTypeVersion;
	}

	public withBaseUrl(
		baseUrl: string
	): HttpClientConfigurationBuilder<THttpClientConfiguration> {
		if (isStringDefinedAndNotEmpty(baseUrl)) {
			this._baseUrl = baseUrl;
		}

		return this;
	}

	public withResourceRelativePath(
		resourceRelativePath: string
	): HttpClientConfigurationBuilder<THttpClientConfiguration> {
		if (isStringDefinedAndNotEmpty(resourceRelativePath)) {
			this._resourceRelativePath = resourceRelativePath;
		}

		return this;
	}

	public withResourceTypeVersion(
		resourceTypeVersion: string
	): HttpClientConfigurationBuilder<THttpClientConfiguration> {
		if (isStringDefinedAndNotEmpty(resourceTypeVersion)) {
			this._resourceTypeVersion = resourceTypeVersion;
		}

		return this;
	}

	public abstract build(): THttpClientConfiguration;

}
