import { ComboboxDto, DescribedComboboxDto } from '@nmn-communication/shared';
import { ComboboxModel, DescribedComboboxModel } from '@nmn-domain/shared';

export const mapComboboxDtoTKeyToComboboxModel = <TKey>(dto: ComboboxDto<TKey>, createKey: (key: TKey) => string): ComboboxModel => (
	new ComboboxModel(
		createKey(dto.id),
		dto.displayText
	)
);

export const mapComboboxDtoToComboboxModel = (dto: ComboboxDto<string>): ComboboxModel =>
	mapComboboxDtoTKeyToComboboxModel(dto, key => key);

export const mapComboboxWithNumberKeyDtoToComboboxModel = (dto: ComboboxDto<number>): ComboboxModel =>
	mapComboboxDtoTKeyToComboboxModel(dto, key => key.toString());

export const mapDescribedComboboxDtoTKeyToDescribedComboboxModel = <TKey>(dto: DescribedComboboxDto<TKey>, createKey: (key: TKey) => string): DescribedComboboxModel => (
	new DescribedComboboxModel(
		createKey(dto.id),
		dto.displayText,
		dto.description
	)
);

export const mapDescribedComboboxDtoToDescribedComboboxModel = (dto: DescribedComboboxDto<string>): DescribedComboboxModel =>
	mapDescribedComboboxDtoTKeyToDescribedComboboxModel(dto, key => key);

export const mapDescribedComboboxWithNumberKeyDtoToDescribedComboboxModel = (dto: DescribedComboboxDto<number>): DescribedComboboxModel =>
	mapDescribedComboboxDtoTKeyToDescribedComboboxModel(dto, key => key.toString());
