import { TranslocoService } from '@ngneat/transloco';
import { GeneVariantComboboxDto } from '@nmn-communication/gene-variants';
import { GeneComboboxDto } from '@nmn-communication/genes';
import { isValueDefined } from '@nmn-core/utils';
import { setTranslation } from '../../utils/localize';
import { FakeLocalizableEntity } from '../fake-localizable-entity';
import { FakeDescribedComboboxDto } from './fake-described-combobox.dto';

export interface FakeGeneVariantComboboxDto extends FakeDescribedComboboxDto<string> {

	gene: GeneComboboxDto;
	allele1: string;
	allele2?: string;
	pharmacogenomicTestResult: {
		id: string;
		displayText: FakeLocalizableEntity;
		description?: FakeLocalizableEntity;
		category: string;
	};

}

export const mapFakeGeneVariantComboboxToComboboxDto = (translocoService: TranslocoService, fakeComboboxDto: FakeGeneVariantComboboxDto): GeneVariantComboboxDto => {
	if (!isValueDefined(fakeComboboxDto)) {
		return undefined;
	}

	return {
		id: fakeComboboxDto.id,
		displayText: setTranslation(translocoService, fakeComboboxDto.displayText),
		description: setTranslation(translocoService, fakeComboboxDto.description),
		gene: fakeComboboxDto.gene,
		allele1: fakeComboboxDto.allele1,
		allele2: fakeComboboxDto.allele2,
		pharmacogenomicTestResult: {
			id: fakeComboboxDto.pharmacogenomicTestResult.id,
			displayText: setTranslation(translocoService, fakeComboboxDto.pharmacogenomicTestResult.displayText),
			description: setTranslation(translocoService, fakeComboboxDto.pharmacogenomicTestResult.description),
			category: fakeComboboxDto.pharmacogenomicTestResult.category
		}
	};
};
