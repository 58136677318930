import { Provider } from '@angular/core';
import {
	CurrentPatientGeneticCardResolver, GeneticTestCommandHandlerService, GeneticTestFormConfigurationResolver, GeneticTestQueryHandlerService, GeneticTestResolver
} from '@nmn-domain/genetic-tests';
import { CurrentPatientGeneticCardViaQueryHandlerResolver } from './resolvers/current-patient-genetic-card.via-query-handler.resolver';
import { GeneticTestFormConfigurationViaConstResolver } from './resolvers/genetic-test-form-configuration.from-const.resolver';
import { GeneticTestViaQueryHandlerResolver } from './resolvers/genetic-test.via-query-handler.resolver';
import { GeneticTestCommandHandlerViaClientService } from './services/genetic-test.command-handler-via-client.service';
import { GeneticTestQueryHandlerViaClientService } from './services/genetic-test.query-handler-via-client.service';

export const geneticTestProviders: Array<Provider> = [
	{
		provide: GeneticTestCommandHandlerService,
		useClass: GeneticTestCommandHandlerViaClientService
	},
	{
		provide: GeneticTestQueryHandlerService,
		useClass: GeneticTestQueryHandlerViaClientService
	},
	{
		provide: CurrentPatientGeneticCardResolver,
		useClass: CurrentPatientGeneticCardViaQueryHandlerResolver
	},
	{
		provide: GeneticTestFormConfigurationResolver,
		useClass: GeneticTestFormConfigurationViaConstResolver
	},
	{
		provide: GeneticTestResolver,
		useClass: GeneticTestViaQueryHandlerResolver
	}
];
