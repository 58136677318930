import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Failure, PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import {
	PatientTreatmentCheckerAnalyzeParameterDto, TreatmentCheckerAnalyzeParameterDto, TreatmentCheckerClient, TreatmentCheckerReportDeleteBulkParameterDto,
	TreatmentCheckerReportDto, TreatmentCheckerReportFilterDto, TreatmentCheckerReportFindParameterDto, TreatmentCheckerReportShortDto
} from '@nmn-communication/treatment-checker';
import { EmptyResult, Result } from '@nmn-core/shared';
import { constructHttpParams, isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { Observable } from 'rxjs';
import {
	createEmptyResultFromEmptyResponse, createFailedEmptyResultFromErrorResponse, createFailedResultFromErrorResponse, createResultFromResponse
} from '../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { TreatmentCheckerHttpClientConfiguration } from '../configurations/treatment-checker-http-client.configuration';

export class TreatmentCheckerHttpClient extends TreatmentCheckerClient {

	constructor(
		private readonly configuration: TreatmentCheckerHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public checkTreatmentForPatient(parameter: PatientTreatmentCheckerAnalyzeParameterDto): Observable<Result<string, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/reports/patients/${parameter.patientId}`;
		const payload = {
			healthIssueIds: parameter.healthIssueIds,
			doctorEncounterIds: parameter.doctorEncounterIds,
			takenMedicationIds: parameter.takenMedicationIds,
			diseaseIds: parameter.diseaseIds,
			medicationIds: parameter.medicationIds
		};
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post<string>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public checkTreatmentForAnonymous(parameter: TreatmentCheckerAnalyzeParameterDto): Observable<Result<string, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/reports`;
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post<string>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public getReportsPage(
		parameter: PageOptionsDto<TreatmentCheckerReportFilterDto>
	): Observable<Result<PagedCollectionDto<TreatmentCheckerReportShortDto, TreatmentCheckerReportFilterDto>, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/reports`;
		const params = constructHttpParams(parameter);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<PagedCollectionDto<TreatmentCheckerReportShortDto, TreatmentCheckerReportFilterDto>>(url, { params, headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public getReport(parameter: TreatmentCheckerReportFindParameterDto): Observable<Result<TreatmentCheckerReportDto, Failure>> {
		const url = isStringDefinedAndNotEmpty(parameter.organizationId) ?
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/reports/organization/${parameter.organizationId}/${parameter.id}` :
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/reports/${parameter.id}`;

		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<TreatmentCheckerReportDto>(url, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public delete(parameter: TreatmentCheckerReportFindParameterDto): Observable<EmptyResult<Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/reports/${parameter.id}`;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.delete(url, { headers })
			.pipe(
				createEmptyResultFromEmptyResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

	public deleteBulk(parameter: TreatmentCheckerReportDeleteBulkParameterDto): Observable<EmptyResult<Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/reports`;
		const params = constructHttpParams(parameter);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.delete(url, { headers, params })
			.pipe(
				createEmptyResultFromEmptyResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

}
