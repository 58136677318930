import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
	DoctorEncounterClient, DoctorEncounterComboboxDto, DoctorEncounterCreateParameterDto, DoctorEncounterDto,
	DoctorEncounterFilterDto, DoctorEncounterFindParameterDto, DoctorEncounterUpdateParameterDto
} from '@nmn-communication/doctor-encounters';
import { Failure, PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { constructHttpParams, injectParameters } from '@nmn-core/utils';
import { Observable } from 'rxjs';
import {
	createEmptyResultFromResponse, createFailedEmptyResultFromErrorResponse, createFailedResultFromErrorResponse, createResultFromResponse
} from '../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { DoctorEncounterHttpClientConfiguration } from '../configurations/doctor-encounter.http-client.configuration';

export class DoctorEncounterHttpClient extends DoctorEncounterClient {

	constructor(
		private readonly configuration: DoctorEncounterHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public getBulkAsComboboxes(
		findParameter: DoctorEncounterFilterDto
	): Observable<Result<Array<DoctorEncounterComboboxDto>, Failure>> {
		const parameters = {
			patientId: findParameter.patientId
		};
		const url = injectParameters(`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/combobox`, parameters);
		const params = constructHttpParams(findParameter);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<Array<DoctorEncounterComboboxDto>>(url, { params, headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public getBulk(
		findParameter: PageOptionsDto<DoctorEncounterFilterDto>
	): Observable<Result<PagedCollectionDto<DoctorEncounterDto, DoctorEncounterFilterDto>, Failure>> {
		const parameters = {
			patientId: findParameter.filter?.patientId
		};
		const url = injectParameters(`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`, parameters);
		const params = constructHttpParams(findParameter);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<PagedCollectionDto<DoctorEncounterDto, DoctorEncounterFilterDto>>(url, { params, headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public get(parameter: DoctorEncounterFindParameterDto): Observable<Result<DoctorEncounterDto, Failure>> {
		const parameters = {
			patientId: parameter.patientId,
			key: parameter.id
		};
		const url = injectParameters(`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/{key}`, parameters);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<DoctorEncounterDto>(url, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public create(createParameter: DoctorEncounterCreateParameterDto): Observable<Result<string, Failure>> {
		const parameters = {
			patientId: createParameter.patientId
		};
		const url = injectParameters(`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`, parameters);
		const payload = createParameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post<string>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public update(
		findParameter: DoctorEncounterFindParameterDto,
		updateParameter: DoctorEncounterUpdateParameterDto
	): Observable<EmptyResult<Failure>> {
		const parameters = {
			patientId: findParameter.patientId,
			key: findParameter.id
		};
		const url = injectParameters(`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/{key}`, parameters);
		const payload = updateParameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put(url, payload, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

	public delete(
		updateParameter: DoctorEncounterFindParameterDto
	): Observable<EmptyResult<Failure>> {
		const parameters = {
			patientId: updateParameter.patientId,
			key: updateParameter.id
		};
		const url = injectParameters(`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/{key}`, parameters);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.delete(url, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

}
