import { isValueDefined } from '@nmn-core/utils';
import { NmnMatTableUserConfiguration } from '../component-configurations/nmn-mat-table-user.configuration';

export class GeneticTestsPageConfiguration {

	public readonly geneticTestsTableUserConfiguration: NmnMatTableUserConfiguration;

	protected constructor(
		geneticTestsTableUserConfiguration: NmnMatTableUserConfiguration
	) {
		this.geneticTestsTableUserConfiguration = geneticTestsTableUserConfiguration;
	}

	public static createWithParameters(
		config?: GeneticTestsPageConfiguration
	): GeneticTestsPageConfiguration {
		if (!isValueDefined(config)) {
			GeneticTestsPageConfiguration.createDefault();
		}

		return new GeneticTestsPageConfiguration(
			isValueDefined(config?.geneticTestsTableUserConfiguration) ?
				NmnMatTableUserConfiguration.createWithParameters(config!.geneticTestsTableUserConfiguration!) :
				NmnMatTableUserConfiguration.createDefault()
		);
	}

	public static createDefault(
	): GeneticTestsPageConfiguration {
		return new GeneticTestsPageConfiguration(
			NmnMatTableUserConfiguration.createWithParameters(
				new NmnMatTableUserConfiguration(undefined!, undefined!, undefined!, 'desc', 'date'))
		);
	}

}
