import { Injectable } from '@angular/core';
import { UserConfigurationModel } from '@nmn-domain/accounts/user-configuration/models/user-configuration.model';
import { Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserConfigurationStorageService {

	private readonly userConfigurationSubject$ = new Subject<UserConfigurationModel>();

	private userConfigurationValue: UserConfigurationModel;

	public readonly userConfiguration$ = this.userConfigurationSubject$
		.asObservable()
		.pipe(shareReplay(1));

	public get userConfiguration(): UserConfigurationModel {
		return this.userConfigurationValue;
	}

	constructor() {
		this.userConfigurationValue = UserConfigurationModel.createDefault();

		// Do not remove these subscriptions - they are used to keep subject alife
		this.userConfiguration$.subscribe();
	}

	public defineUserConfiguration(userConfigurationValue: UserConfigurationModel): void {
		// TODO: Guard check on defined
		this.userConfigurationValue = userConfigurationValue;
		this.userConfigurationSubject$.next(this.userConfigurationValue);
	}

}
