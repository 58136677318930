export class GsiButtonConfiguration {

	public readonly type: 'standard' | 'icon';
	public readonly theme?: 'outline' | 'filled_blue' | 'filled_black';
	public readonly size?: 'large' | 'medium' | 'small';
	public readonly text?: 'signin_with' | 'signup_with' | 'continue_with' | 'signin';
	public readonly shape?: 'rectangular' | 'pill' | 'circle' | 'square';
	public readonly logo_alignment?: 'left' | 'center';
	public readonly width?: number;
	public readonly locale?: string;

	constructor(
		type: 'standard' | 'icon',
		theme: 'outline' | 'filled_blue' | 'filled_black' | undefined,
		size: 'large' | 'medium' | 'small' | undefined,
		text: 'signin_with' | 'signup_with' | 'continue_with' | 'signin' | undefined,
		shape: 'rectangular' | 'pill' | 'circle' | 'square' | undefined,
		logo_alignment: 'left' | 'center' | undefined,
		width: number | undefined,
		locale: string | undefined,
	) {
		this.type = type;
		this.theme = theme;
		this.size = size;
		this.text = text;
		this.shape = shape;
		this.logo_alignment = logo_alignment;
		this.width = width;
		this.locale = locale;
	}

	public static createDefault(
		width: number | undefined = undefined,
		locale: string | undefined = undefined
	): GsiButtonConfiguration {
		return new GsiButtonConfiguration(
			'standard',
			'outline',
			'large',
			'continue_with',
			'rectangular',
			'center',
			width,
			locale
		);
	}

}
