import { Failure } from '@nmn-communication/shared';
import {
	VaccinationCardDto, VaccinationCardFindParameterDto, VaccinationClient, VaccinationCreateParameterDto,
	VaccinationDto, VaccinationFindParameterDto, VaccinationUpdateParameterDto
} from '@nmn-communication/vaccination';
import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { wrapActionCallAsFakeClientResponse, wrapFuncCallAsFakeClientResponse } from '../../../../modules/fakes/databases/fake.utils';
import { VaccinationFakeClientConfiguration } from '../configurations/vaccination.fake-client.configuration';

export class VaccinationFakeClient extends VaccinationClient {

	constructor(
		private readonly configuration: VaccinationFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getVaccinationCard(parameter: VaccinationCardFindParameterDto): Observable<Result<VaccinationCardDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.vaccinationTable.getVaccinationCard(parameter),
			this.configuration.serverDelayMs
		);
	}

	public get(parameter: VaccinationFindParameterDto): Observable<Result<VaccinationDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.vaccinationTable.get(parameter),
			this.configuration.serverDelayMs
		);
	}

	public create(parameter: VaccinationCreateParameterDto): Observable<Result<string, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.vaccinationTable.create(parameter),
			this.configuration.serverDelayMs
		);
	}

	public update(findParameter: VaccinationFindParameterDto, updateParameter: VaccinationUpdateParameterDto): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => { this.fakeDatabase.vaccinationTable.update(findParameter, updateParameter); },
			this.configuration.serverDelayMs
		);
	}

	public delete(parameter: VaccinationFindParameterDto): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => { this.fakeDatabase.vaccinationTable.delete(parameter); },
			this.configuration.serverDelayMs
		);
	}
}
