import { Injectable } from '@angular/core';
import { AllergyIntoleranceCriticalityClient } from '@nmn-communication/allergy-intolerance-criticalities';
import { Result } from '@nmn-core/shared';
import { AllergyIntoleranceCriticalityFilterModel, AllergyIntoleranceCriticalityQueryHandlerService } from '@nmn-domain/allergy-intolerance-criticalities';
import { DescribedComboboxModel, FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapDescribedComboboxDtoToDescribedComboboxModel } from '../../shared/factories/combobox.factory';
import { mapDtoArrayResultToModelArrayResult } from '../../shared/factories/pagination.factory';
import { mapFilterModelToDto } from '../factories/allergy-intolerance-criticality.factory';

@Injectable()
export class AllergyIntoleranceCriticalityQueryHandlerViaClientService extends AllergyIntoleranceCriticalityQueryHandlerService {

	constructor(
		private readonly client: AllergyIntoleranceCriticalityClient
	) {
		super();
	}

	public getAsDescribedComboboxes(filter: AllergyIntoleranceCriticalityFilterModel): Observable<Result<Array<DescribedComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapDescribedComboboxDtoToDescribedComboboxModel))
			);
	}

}
