export class PatientInsuranceFormFailure {

	public readonly isValidationFailure: boolean;

	private constructor(
		isValidationFailure: boolean
	) {
		this.isValidationFailure = isValidationFailure;
	}

	public static createAsValidationFailure(): PatientInsuranceFormFailure {
		return new PatientInsuranceFormFailure(true);
	}

}
