import { Injector } from '@angular/core';
import { DoctorEncounterClient } from '@nmn-communication/doctor-encounters';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { DoctorEncounterFakeClientBuilder } from '../builders/doctor-encounter.fake-client.builder';
import { DoctorEncounterFakeClientConfigurationBuilder } from '../builders/doctor-encounter.fake-client.configuration.builder';
import { DoctorEncounterHttpClientBuilder } from '../builders/doctor-encounter.http-client.builder';
import { DoctorEncounterHttpClientConfigurationBuilder } from '../builders/doctor-encounter.http-client.configuration.builder';
import { DoctorEncounterFakeClient } from '../services/doctor-encounter.fake-client';
import { DoctorEncounterHttpClient } from '../services/doctor-encounter.http-client';

export const doctorEncounterClientFactory = (injector: Injector): DoctorEncounterClient => {
	const useFake = environment?.api?.resources?.doctorEncounters?.useFake === true ||
		!isValueDefined(environment?.api?.resources?.doctorEncounters?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		doctorEncounterFakeClientFactory(injector) :
		doctorEncounterHttpClientFactory(injector);
};

export const doctorEncounterFakeClientFactory = (
	injector: Injector
): DoctorEncounterFakeClient => {
	const configuration = new DoctorEncounterFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.doctorEncounters.serverDelayMs)
		.build();

	return new DoctorEncounterFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const doctorEncounterHttpClientFactory = (
	injector: Injector
): DoctorEncounterHttpClient => {
	const configuration = new DoctorEncounterHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.doctorEncounters?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.doctorEncounters.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.doctorEncounters?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new DoctorEncounterHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
