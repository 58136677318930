import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { CommandResult, EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { FailureModel } from '../../shared/failures/failure.model';
import { GeneticFileParseRequestCancelCommand } from '../models/commands/genetic-file-parse-request.cancel.command';
import { GeneticFileParseRequestCreateCommand } from '../models/commands/genetic-file-parse-request.create.command';
import { GeneticFileParseRequestModel } from '../models/genetic-file-parse-request.model';

export abstract class GeneticFileParseRequestCommandHandlerService {

	public abstract handleCreateCommand(
		command: GeneticFileParseRequestCreateCommand
	): Observable<Result<CommandResult<GeneticFileParseRequestCreateCommand, GeneticFileParseRequestModel>, FailureModel>>;

	public abstract handleCancelCommand(
		command: GeneticFileParseRequestCancelCommand
	): Observable<Result<EmptyCommandResult<GeneticFileParseRequestCancelCommand>, FailureModel>>;

}
