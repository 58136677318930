import { HashMap } from '@ngneat/transloco';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';

export class NotificationModel {

	// TODO: #258 Obsolete. Just remove.
	public readonly message: string;

	public readonly localizationPattern: string;
	public readonly localizationString?: string;
	public readonly notificationType: NotificationType;
	public readonly notificationViewType: NotificationViewType;

	public readonly notificationAppearanceSettings: NotificationSnackbarAppearanceSettings;
	public readonly notificationDialogAppearanceSettings: NotificationDialogAppearanceSettings;

	public readonly localizationKeyParams: HashMap;

	private constructor(
		message: string,
		localizationPattern: string,
		localizationString: string | undefined,
		notificationType: NotificationType,
		notificationViewType: NotificationViewType,
		notificationAppearanceSettings: NotificationSnackbarAppearanceSettings,
		notificationDialogAppearanceSettings: NotificationDialogAppearanceSettings,
		localizationKeyParams?: HashMap
	) {
		this.message = message;
		this.localizationPattern = localizationPattern;
		this.localizationString = localizationString;
		this.notificationType = notificationType;
		this.notificationViewType = notificationViewType;
		this.notificationAppearanceSettings = notificationAppearanceSettings;
		this.notificationDialogAppearanceSettings = notificationDialogAppearanceSettings;
		this.localizationKeyParams = localizationKeyParams;
	}

	public static createForCustomAction(
		localizationPattern: string,
		localizationString: string,
		notificationViewType?: NotificationViewType,
		notificationAppearanceSettings?: NotificationSnackbarAppearanceSettings,
		notificationDialogAppearanceSettings?: NotificationDialogAppearanceSettings,
		localizationKeyParams?: HashMap
	): NotificationModel {
		return new NotificationModel(
			undefined,
			localizationPattern,
			localizationString,
			NotificationType.CustomAction,
			isValueDefined(notificationViewType) ?
				notificationViewType :
				NotificationViewType.Snackbar,
			isValueDefined(notificationAppearanceSettings) ?
				notificationAppearanceSettings :
				NotificationSnackbarAppearanceSettings.createDefault(),
			isValueDefined(notificationDialogAppearanceSettings) ?
				notificationDialogAppearanceSettings :
				NotificationDialogAppearanceSettings.createDefault(),
			localizationKeyParams
		);
	}

	public static createForSuccessAction(
		localizationPattern: string,
		notificationViewType?: NotificationViewType,
		notificationAppearanceSettings?: NotificationSnackbarAppearanceSettings,
		notificationDialogAppearanceSettings?: NotificationDialogAppearanceSettings,
		localizationKeyParams?: HashMap
	): NotificationModel {
		return new NotificationModel(
			undefined,
			localizationPattern,
			undefined,
			NotificationType.SuccessAction,
			isValueDefined(notificationViewType) ?
				notificationViewType :
				NotificationViewType.Snackbar,
			isValueDefined(notificationAppearanceSettings) ?
				notificationAppearanceSettings :
				NotificationSnackbarAppearanceSettings.createDefault(),
			isValueDefined(notificationDialogAppearanceSettings) ?
				notificationDialogAppearanceSettings :
				NotificationDialogAppearanceSettings.createDefault(),
			localizationKeyParams
		);
	}

	public static createForResetAction(
		localizationPattern: string,
		notificationViewType?: NotificationViewType,
		notificationAppearanceSettings?: NotificationSnackbarAppearanceSettings,
		notificationDialogAppearanceSettings?: NotificationDialogAppearanceSettings,
		localizationKeyParams?: HashMap
	): NotificationModel {
		return new NotificationModel(
			undefined,
			localizationPattern,
			undefined,
			NotificationType.ResetAction,
			isValueDefined(notificationViewType) ?
				notificationViewType :
				NotificationViewType.Snackbar,
			isValueDefined(notificationAppearanceSettings) ?
				notificationAppearanceSettings :
				NotificationSnackbarAppearanceSettings.createDefault(),
			isValueDefined(notificationDialogAppearanceSettings) ?
				notificationDialogAppearanceSettings :
				NotificationDialogAppearanceSettings.createDefault(),
			localizationKeyParams
		);
	}

	public static createForClearAction(
		localizationPattern: string,
		notificationViewType?: NotificationViewType,
		notificationAppearanceSettings?: NotificationSnackbarAppearanceSettings,
		notificationDialogAppearanceSettings?: NotificationDialogAppearanceSettings,
		localizationKeyParams?: HashMap
	): NotificationModel {
		return new NotificationModel(
			undefined,
			localizationPattern,
			undefined,
			NotificationType.ClearAction,
			isValueDefined(notificationViewType) ?
				notificationViewType :
				NotificationViewType.Snackbar,
			isValueDefined(notificationAppearanceSettings) ?
				notificationAppearanceSettings :
				NotificationSnackbarAppearanceSettings.createDefault(),
			isValueDefined(notificationDialogAppearanceSettings) ?
				notificationDialogAppearanceSettings :
				NotificationDialogAppearanceSettings.createDefault(),
			localizationKeyParams
		);
	}

	public static createForCancelAction(
		localizationPattern: string,
		notificationViewType?: NotificationViewType,
		notificationAppearanceSettings?: NotificationSnackbarAppearanceSettings,
		notificationDialogAppearanceSettings?: NotificationDialogAppearanceSettings,
		localizationKeyParams?: HashMap
	): NotificationModel {
		return new NotificationModel(
			undefined,
			localizationPattern,
			undefined,
			NotificationType.CancelAction,
			isValueDefined(notificationViewType) ?
				notificationViewType :
				NotificationViewType.Snackbar,
			isValueDefined(notificationAppearanceSettings) ?
				notificationAppearanceSettings :
				NotificationSnackbarAppearanceSettings.createDefault(),
			isValueDefined(notificationDialogAppearanceSettings) ?
				notificationDialogAppearanceSettings :
				NotificationDialogAppearanceSettings.createDefault(),
			localizationKeyParams
		);
	}

	public static createForSuccessDeletion(
		localizationPattern: string,
		notificationViewType?: NotificationViewType,
		notificationAppearanceSettings?: NotificationSnackbarAppearanceSettings,
		notificationDialogAppearanceSettings?: NotificationDialogAppearanceSettings,
		localizationKeyParams?: HashMap
	): NotificationModel {
		return new NotificationModel(
			undefined,
			localizationPattern,
			undefined,
			NotificationType.SuccessDeletion,
			isValueDefined(notificationViewType) ?
				notificationViewType :
				NotificationViewType.Snackbar,
			isValueDefined(notificationAppearanceSettings) ?
				notificationAppearanceSettings :
				NotificationSnackbarAppearanceSettings.createDefault(),
			isValueDefined(notificationDialogAppearanceSettings) ?
				notificationDialogAppearanceSettings :
				NotificationDialogAppearanceSettings.createDefault(),
			localizationKeyParams
		);
	}

	// TODO: obsolete
	public static create(message: string): NotificationModel {
		return new NotificationModel(
			message,
			undefined,
			undefined,
			NotificationType.Undefined,
			NotificationViewType.Snackbar,
			NotificationSnackbarAppearanceSettings.createDefault(),
			NotificationDialogAppearanceSettings.createDefault());
	}

	// TODO: obsolete
	public static createForOperationSuccess(): NotificationModel {
		return new NotificationModel(
			'Operation success',
			undefined,
			undefined,
			NotificationType.Undefined,
			NotificationViewType.Snackbar,
			NotificationSnackbarAppearanceSettings.createDefault(),
			NotificationDialogAppearanceSettings.createDefault());
	}

	// TODO: obsolete
	public static createForOperationFailed(): NotificationModel {
		return new NotificationModel(
			'Operation failed',
			undefined,
			undefined,
			NotificationType.Undefined,
			NotificationViewType.Snackbar,
			NotificationSnackbarAppearanceSettings.createDefault(),
			NotificationDialogAppearanceSettings.createDefault());
	}

}

export class NotificationSnackbarAppearanceSettings {

	public readonly duration: number;
	public readonly horizontalPosition: NotificationSnackBarHorizontalPosition;
	public readonly verticalPosition: NotificationSnackBarVerticalPosition;

	constructor(
		duration: number,
		horizontalPosition: NotificationSnackBarHorizontalPosition,
		verticalPosition: NotificationSnackBarVerticalPosition
	) {
		this.duration = duration;
		this.horizontalPosition = horizontalPosition;
		this.verticalPosition = verticalPosition;
	}

	public static createDefault(
	): NotificationSnackbarAppearanceSettings {
		return new NotificationSnackbarAppearanceSettings(
			environment.snackbarSettings.durationMs,
			mapNotificationSnackBarHorizontalPositionFromString(environment.snackbarSettings.defaultHorizontalPosition),
			mapNotificationSnackBarVerticalPositionFromString(environment.snackbarSettings.defaultVerticalPosition)
		);
	}

	public static createViaLongDuration(
	): NotificationSnackbarAppearanceSettings {
		return new NotificationSnackbarAppearanceSettings(
			environment.snackbarSettings.longDurationMs,
			mapNotificationSnackBarHorizontalPositionFromString(environment.snackbarSettings.defaultHorizontalPosition),
			mapNotificationSnackBarVerticalPositionFromString(environment.snackbarSettings.defaultVerticalPosition)
		);
	}

	public static createViaDuration(
		duration: number
	): NotificationSnackbarAppearanceSettings {

		if (!isValueDefined(duration)) {
			return NotificationSnackbarAppearanceSettings.createDefault();
		}

		return new NotificationSnackbarAppearanceSettings(
			duration,
			mapNotificationSnackBarHorizontalPositionFromString(environment.snackbarSettings.defaultHorizontalPosition),
			mapNotificationSnackBarVerticalPositionFromString(environment.snackbarSettings.defaultVerticalPosition)
		);
	}

	public static create(
		duration: number,
		horizontalPosition: NotificationSnackBarHorizontalPosition,
		verticalPosition: NotificationSnackBarVerticalPosition
	): NotificationSnackbarAppearanceSettings {

		if (!isValueDefined(duration)
			|| isValueDefined(horizontalPosition)
			|| !isValueDefined(verticalPosition)) {
			return NotificationSnackbarAppearanceSettings.createDefault();
		}

		return new NotificationSnackbarAppearanceSettings(
			duration,
			horizontalPosition,
			verticalPosition
		);
	}

}

export enum NotificationViewType {

	Snackbar = 'Snackbar',
	Dialog = 'dialog'

}

export enum NotificationSnackBarHorizontalPosition {

	Start = 'start',
	Center = 'center',
	End = 'end',
	Left = 'left',
	Right = 'right'

}

export class NotificationDialogAppearanceSettings {

	public readonly titleLocalizationKey: string;
	public readonly closeBtnLocalizationKey: string;
	public readonly height: number;
	public readonly width: number;

	constructor(
		titleLocalizationKey: string,
		closeBtnLocalizationKey: string,
		height: number,
		width: number
	) {
		this.titleLocalizationKey = titleLocalizationKey;
		this.closeBtnLocalizationKey = closeBtnLocalizationKey;
		this.height = height;
		this.width = width;
	}

	public static createDefault(
	): NotificationDialogAppearanceSettings {
		return new NotificationDialogAppearanceSettings(
			'',
			'',
			400,
			600);
	}

}

export enum NotificationSnackBarVerticalPosition {

	End = 'end',
	Top = 'top'

}

export enum NotificationType {

	// TODO: #258 Obsolete. remove undefined type.
	Undefined = 'undefined',
	CustomAction = 'customAction',
	SuccessAction = 'successAction',
	SuccessDeletion = 'successDeletion',
	ResetAction = 'resetAction',
	ClearAction = 'clearAction',
	CancelAction = 'cancelAction'

}

export const mapNotificationSnackBarHorizontalPositionFromString = (
	stringValue: string
): NotificationSnackBarHorizontalPosition => {
	const enumValue = Object
		.keys(NotificationSnackBarHorizontalPosition)
		.find(x => NotificationSnackBarHorizontalPosition[x] === stringValue);

	return isValueDefined(enumValue) ?
		stringValue as NotificationSnackBarHorizontalPosition :
		NotificationSnackBarHorizontalPosition.End;
};

export const mapNotificationSnackBarVerticalPositionFromString = (
	stringValue: string
): NotificationSnackBarVerticalPosition => {
	const enumValue = Object
		.keys(NotificationSnackBarVerticalPosition)
		.find(x => NotificationSnackBarVerticalPosition[x] === stringValue);

	return isValueDefined(enumValue) ?
		stringValue as NotificationSnackBarVerticalPosition :
		NotificationSnackBarVerticalPosition.Top;
};
