import { FileType, UploadedFileExtensionComboboxModel } from '@nmn-domain/file-uploader';

export class AzureStorageInModel {

	public readonly blobId: string;
	public readonly blobUrl: string;
	public readonly file: File | Blob;
	public readonly name: string;
	public readonly extension: UploadedFileExtensionComboboxModel;
	public readonly sizeInBytes: number;
	public readonly createdOn: Date;
	public readonly updatedOn: Date;
	public readonly fileType: FileType;

	constructor(
		blobId: string,
		blobUrl: string,
		file: File | Blob,
		name: string,
		extension: UploadedFileExtensionComboboxModel,
		sizeInBytes: number,
		createdOn: Date,
		updatedOn: Date,
		fileType: FileType
	) {
		this.blobId = blobId;
		this.blobUrl = blobUrl;
		this.file = file;
		this.name = name;
		this.extension = extension;
		this.sizeInBytes = sizeInBytes;
		this.createdOn = createdOn;
		this.updatedOn = updatedOn;
		this.fileType = fileType;
	}

}
