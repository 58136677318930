import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { DescribedComboboxDto } from '../../shared';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { MedicationRateQuantityTypeFilterDto } from '../models/medication-rate-quantity-type-filter.dto';

export abstract class MedicationRateQuantityTypeClient {

	public abstract getBulkAsComboboxes(
		filter: MedicationRateQuantityTypeFilterDto
	): Observable<Result<Array<DescribedComboboxDto<string>>, Failure>>;

}
