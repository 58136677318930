import { ErrorDto } from './error.dto';

// TODO: we might need to review all the failure handling concept at all
export class Failure {

	public readonly error: ErrorDto;
	public readonly value: any;
	public readonly fullLocalizationKey: string;

	private constructor(
		error: ErrorDto,
		value: any,
		fullLocalizationKey: string
	) {
		this.error = error;
		this.value = value;
		this.fullLocalizationKey = fullLocalizationKey;
	}

	public static create(error: ErrorDto): Failure {
		return new Failure(error, undefined, undefined);
	}

	public static createUndefined(value: any): Failure {
		return new Failure(undefined, value, undefined);
	}

	public static createForErrorWithDefinedMessage(fullLocalizationKey: string): Failure {
		return new Failure(undefined, undefined, fullLocalizationKey);
	}

}
