import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FailuresDialogInModel } from '../../models/failures-dialog/failures-dialog-view.in-model';

@Component({
	selector: 'app-failures-dialog',
	templateUrl: './failures-dialog.component.html',
	styleUrls: ['./failures-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FailuresDialogComponent {

	public dialogSettings: FailuresDialogInModel;

	constructor(
		@Inject(MAT_DIALOG_DATA) data: FailuresDialogInModel,
		public dialogRef: MatDialogRef<FailuresDialogComponent>
	) {
		this.dialogSettings = FailuresDialogInModel.mergeWithDefaultSafety(data);
	}

	public confirm(): void {
		this.dialogRef.close();
	}

}
