import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { AllergyIntoleranceStatusHttpClientConfiguration } from '../configurations/allergy-intolerance-status.http-client.configuration';

export class AllergyIntoleranceStatusHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<AllergyIntoleranceStatusHttpClientConfiguration> {

	public build(): AllergyIntoleranceStatusHttpClientConfiguration {
		return new AllergyIntoleranceStatusHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
