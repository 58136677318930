export class EmptyCommandResult<TCommand> {

	public readonly command: TCommand;

	constructor(command: TCommand) {

		// TODO: check on null
		this.command = command;

	}

}

export class CommandResult<TCommand, TResultModel> extends EmptyCommandResult<TCommand>{

	public readonly result: TResultModel;

	constructor(command: TCommand, result: TResultModel) {

		super(command);

		// TODO: check on null
		this.result = result;

	}

}
