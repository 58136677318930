import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { DiseaseComboboxModel } from '@nmn-domain/diseases';
import { UploadedFileModel, UploadedImageModel } from '@nmn-domain/file-uploader';
import { MedicationComboboxModel } from '@nmn-domain/medications';
import { PopulationComboboxModel } from '@nmn-domain/populations';
import { ComboboxModel, DescribedComboboxModel } from '@nmn-domain/shared';
import { AllergyIntoleranceModel } from './allergy-intolerance.model';
import { PatientAnamnesisModel } from './patient-anamnesis.model';
import { PatientLifestyleModel } from './patient-lifestyle.model';

export class PatientModel {

	public readonly id: string;
	public readonly alias: string;
	public readonly userId: string;
	public readonly createdOn: Date;
	public readonly updatedOn: Date;

	public readonly givenName: string;
	public readonly additionalName: string;
	public readonly familyName: string;
	public readonly languages: Array<ComboboxModel>;
	public readonly preferredLanguage: ComboboxModel;

	public readonly picture: UploadedFileModel;
	public readonly population: PopulationComboboxModel;
	public readonly gender: DescribedComboboxModel;
	public readonly birthday: Date;
	public readonly bloodType: DescribedComboboxModel;
	public readonly height: number;
	public readonly weight: number;

	public readonly email: string;
	public readonly phoneNumber: string;
	public readonly address: string;

	public readonly allergyIntolerances: Array<AllergyIntoleranceModel>;
	public readonly seriousPathologies: Array<DiseaseComboboxModel>;
	public readonly familyDiseases: Array<DiseaseComboboxModel>;
	public readonly regularlyTakenMedications: Array<MedicationComboboxModel>;
	public readonly doctors: Array<{ fullName: string; contacts: string; }>;
	public readonly emergencyContacts: Array<{ fullName: string; data: string; }>;
	public readonly insurances: Array<{ company: string; code: string; }>;

	public readonly anamnesis: PatientAnamnesisModel;
	public readonly lifestyle: PatientLifestyleModel;

	public get name(): string {
		return `${this.givenName} ${this.familyName}`;
	}

	public get fullName(): string {
		return isStringDefinedAndNotEmpty(this.additionalName) ?
			`${this.givenName} ${this.additionalName} ${this.familyName}` :
			this.name;
	}

	public get age(): number {
		const today = new Date();
		let age = today.getFullYear() - this.birthday.getFullYear();
		const m = today.getMonth() - this.birthday.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < this.birthday.getDate())) {
			age--;
		}

		return age;
	}

	constructor(
		id: string,
		alias: string,
		userId: string,
		createdOn: Date,
		updatedOn: Date,
		givenName: string,
		additionalName: string,
		familyName: string,
		languages: Array<ComboboxModel>,
		preferredLanguage: ComboboxModel,
		picture: UploadedImageModel,
		population: PopulationComboboxModel,
		gender: DescribedComboboxModel,
		birthday: Date,
		bloodType: DescribedComboboxModel,
		height: number,
		weight: number,
		email: string,
		phoneNumber: string,
		address: string,
		allergyIntolerances: Array<AllergyIntoleranceModel>,
		seriousPathologies: Array<DiseaseComboboxModel>,
		familyDiseases: Array<DiseaseComboboxModel>,
		regularlyTakenMedications: Array<MedicationComboboxModel>,
		doctors: Array<{ fullName: string; contacts: string; }>,
		emergencyContacts: Array<{ fullName: string; data: string }>,
		insurances: Array<{ company: string; code: string; }>,
		anamnesis: PatientAnamnesisModel,
		lifestyle: PatientLifestyleModel
	) {
		// TODO: add Guard checks

		this.id = id;
		this.alias = alias;
		this.userId = userId;
		this.createdOn = createdOn;
		this.updatedOn = updatedOn;

		this.givenName = givenName;
		this.additionalName = additionalName;
		this.familyName = familyName;
		this.languages = languages;
		this.preferredLanguage = preferredLanguage;

		this.picture = picture;
		this.population = population;
		this.gender = gender;
		this.birthday = birthday;
		this.bloodType = bloodType;
		this.height = height;
		this.weight = weight;

		this.email = email;
		this.phoneNumber = phoneNumber;
		this.address = address;

		this.allergyIntolerances = allergyIntolerances;
		this.seriousPathologies = seriousPathologies;
		this.familyDiseases = familyDiseases;
		this.regularlyTakenMedications = regularlyTakenMedications;
		this.doctors = doctors;
		this.emergencyContacts = emergencyContacts;
		this.insurances = insurances;

		this.anamnesis = anamnesis;
		this.lifestyle = lifestyle;
	}

}
