import { Injector, Provider } from '@angular/core';
import { TreatmentCheckerClient } from '@nmn-communication/treatment-checker';
import { treatmentCheckerClientFactory } from './factories/treatment-checker-client.factory';

export const treatmentCheckerProviders: Array<Provider> = [
	{
		provide: TreatmentCheckerClient,
		useFactory: treatmentCheckerClientFactory,
		deps: [Injector]
	}
];
