import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneticFileParseRequestFormConfiguration } from '../models/genetic-file-parse-request-form/genetic-file-parse-request-form.configuration';

export abstract class GeneticFileParseRequestFormConfigurationResolver implements
	Resolve<GeneticFileParseRequestFormConfiguration> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		routerState: RouterStateSnapshot
	): Observable<GeneticFileParseRequestFormConfiguration> |
		Promise<GeneticFileParseRequestFormConfiguration> |
		GeneticFileParseRequestFormConfiguration;

}
