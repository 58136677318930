import { Result } from '@nmn-core/shared';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { CommandResult, EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { DoctorEncounterCreateCommand } from '../models/commands/doctor-encounter.create.command';
import { DoctorEncounterDeleteCommand } from '../models/commands/doctor-encounter.delete.command';
import { DoctorEncounterUpdateCommand } from '../models/commands/doctor-encounter.update.command';

export abstract class DoctorEncounterCommandHandlerService {

	public abstract handleCreateCommand(
		command: DoctorEncounterCreateCommand
	): Observable<Result<CommandResult<DoctorEncounterCreateCommand, string>, FailureModel>>;

	public abstract handleDeleteCommand(
		command: DoctorEncounterDeleteCommand
	): Observable<Result<EmptyCommandResult<DoctorEncounterDeleteCommand>, FailureModel>>;

	public abstract handleUpdateCommand(
		command: DoctorEncounterUpdateCommand
	): Observable<Result<EmptyCommandResult<DoctorEncounterUpdateCommand>, FailureModel>>;

}
