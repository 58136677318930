import { TranslocoService } from '@ngneat/transloco';
import { convertParametersStringToObject, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';

export class ApplicationTitleModel {

	public static get default(): ApplicationTitleModel {
		return applicationTitleDefault;
	}

	public readonly isTranslatable: boolean;
	public readonly value?: string;
	public readonly translationKey?: string;
	public readonly translationParameters?: any;

	private constructor(
		isTranslatable: boolean,
		value: string | undefined,
		translationKey: string | undefined,
		translationParameters: any | undefined
	) {
		this.isTranslatable = isTranslatable;
		this.value = value;
		this.translationKey = translationKey;
		this.translationParameters = translationParameters
	}

	public static createByContext(translationContext: any): ApplicationTitleModel | undefined {
		if (!isValueDefined(translationContext) || !translationContext.hasOwnProperty('title') || !isStringDefinedAndNotEmpty(translationContext.title)) {
			return undefined;
		}

		if (!isTranslatableString(translationContext.title)) {
			return ApplicationTitleModel.createAsNonTranslatable(translationContext.title);
		}

		const pureTranslatable = convertToPureString(translationContext.title);

		if (!isPureTranslatableStringWithParameters(pureTranslatable)) {
			return ApplicationTitleModel.createAsTranslatable(pureTranslatable, {});
		}

		const params = convertParametersStringToObject(getParametersPart(pureTranslatable), translationContext);

		return ApplicationTitleModel.createAsTranslatable(`${getKeyBasePart(pureTranslatable)}_${Object.keys(params).join('_')}`, params);
	}

	public static createAsNonTranslatable(titleValue: string | undefined = undefined): ApplicationTitleModel {
		return new ApplicationTitleModel(false, titleValue, undefined, undefined);
	}

	public static createAsTranslatable(translationKey: string, translationParameters: any): ApplicationTitleModel {
		// TODO: Guard check on defined translationKey

		return new ApplicationTitleModel(true, undefined, translationKey, translationParameters);
	}

	public translate(translocoService: TranslocoService): string {
		try {
			const titleDefault = translocoService.translate(titleDefaultKey);

			if (titleDefaultKey !== titleDefault) {
				const value = this.isTranslatable ? translocoService?.translate(this.translationKey, this.translationParameters ?? {}) : this.value;

				return isStringDefinedAndNotEmpty(value) && value !== this.translationKey ? value : titleDefault;
			}

			return titleFallback;
		} catch {
			return titleFallback;
		}
	}

	public translateWithHiddenParameters(translocoService: TranslocoService): string {
		try {
			const title = this.isTranslatable
				? ApplicationTitleModel.createAsTranslatable(
					this.translationKey,
					Object.assign({}, ...Object.keys(this.translationParameters ?? {}).map((objKey) => ({ [objKey]: objKey })))
				)
				: ApplicationTitleModel.createAsNonTranslatable(this.value);

			return title.translate(translocoService);
		} catch {
			return titleFallback;
		}
	}

}

const titleFallback = 'Apixmed';
const titleDefaultKey = 'application.title.default';
const applicationTitleDefault = ApplicationTitleModel.createAsTranslatable(titleDefaultKey, {});

const isTranslatableString = (titleValue: string): boolean => {
	return isStringDefinedAndNotEmpty(titleValue) && titleValue.startsWith('{{') && titleValue.endsWith('}}');
}

const convertToPureString = (translatableString: string): string => {
	return isTranslatableString(translatableString) ? translatableString.slice(2, -2).trim() : translatableString;
}

const isPureTranslatableStringWithParameters = (pureTranslatableString: string): boolean => {
	return isStringDefinedAndNotEmpty(pureTranslatableString)
		&& !pureTranslatableString.startsWith('{')
		&& pureTranslatableString.includes('{')
		&& pureTranslatableString.endsWith('}')
		&& pureTranslatableString.split('{').length === 2;
}

const getKeyBasePart = (pureTranslatableString: string): string => {
	return pureTranslatableString.split('{')[0].trim();
}

const getParametersPart = (pureTranslatableString: string): string => {
	return pureTranslatableString.split('{')[1].slice(0, -1).trim();
}
