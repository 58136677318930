import { Provider } from '@angular/core';
import { AllergyIntoleranceStatusesResolver, AllergyIntoleranceStatusQueryHandlerService } from '@nmn-domain/allergy-intolerance-statuses';
import { AllergyIntoleranceStatusesViaQueryHandlerResolver } from './resolvers/allergy-intolerance-statuses.via-query-handler.resolver';
import { AllergyIntoleranceStatusQueryHandlerViaClientService } from './services/allergy-intolerance-status.query-handler-via-client.service';

export const allergyIntoleranceStatusProviders: Array<Provider> = [
	{
		provide: AllergyIntoleranceStatusQueryHandlerService,
		useClass: AllergyIntoleranceStatusQueryHandlerViaClientService
	},
	{
		provide: AllergyIntoleranceStatusesResolver,
		useClass: AllergyIntoleranceStatusesViaQueryHandlerResolver
	}
];
