import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MonitoringService } from '../../../services';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

	constructor(
		private readonly monitoringService: MonitoringService
	) { }

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next
			.handle(request)
			.pipe(
				catchError((error: HttpErrorResponse) => {
					this.monitoringService.logException(error);
					if (error.status === 401) {
						// 401 handled by auth.interceptor
						// console.log('401 in error handler');

						return throwError(() => error);
					}

					if (error.error instanceof Error) {
						// A client-side or network error occurred.
						console.error('An error occurred:', error.error.message);
					} else {
						if (error.status <= 500) {
							return throwError(() => error);
						}

						// The backend returned an unsuccessful response code.
						console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
					}

					return EMPTY;
				})
			);
	}

}
