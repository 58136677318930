export { detailExpandAnimation } from './animation.utils';
export { getCurrentBrowser, getCurrentBrowserTimezone, getCurrentBrowserTimezoneOffset } from './browser.utils';
export { toGB, toKB, toMB } from './bytes.utils';
export { lightnessToOppositeColorSurjection, stringToHexColorSurjection, stringToHslColorSurjection } from './color.utils';
export {
	ageFromDateOfBirthday, diffDateInDays, diffDateInMs, mapDateInServerFormatToDate, mapDateInServerFormatToDateSafely,
	mapDateToServerFormat, mapDateToServerFormatSafely
} from './date.utils';
export { scrollToElement } from './element.utils';
export {
	createAutocompleteOptionsObservable, displayComboboxOptionFn, filterOptions, mapToSearchPattern, resetControlIfValueNotInComboboxOptions,
	resetControlIfValueNotInOptions, selectOptionOnAutocompleteSelected, setChipListErrorState
} from './form.utils';
export { Guid } from './guid.utils';
export { accumulateHashCode, getFieldHashCode, getFieldHashCodeCollection } from './hashcode-utils';
export { constructHttpParams } from './http.utils';
export { trackers } from './ng.utils';
export { debounceAutocomplete, debounceConfigChanges, debounceForSyncAutocomplete, debounceInput, debounceSearch, debounceUserInfoRefresh, filterDefined, filterTrue } from './rxjs.utils';
export { convertParametersStringToObject, getPropertyValueFromContext, injectParameters, isStringDefinedAndNotEmpty } from './string.utils';
export { getFailureLocalizationKey, getSuccessLocalizationKey, tryTranslate } from './translate.utils';
export {
	booleanToNumber, deepCopy, findItemById, findItemsById, firstOrUndefined, isArrayDefined, isArrayDefinedAndNotEmpty, isEmptyString, isFunctionDefined,
	isNumberDefined, isValueDefined, lastOrUndefined, mergeMaps, numberToBoolean, onlyDuplicates, onlyUnique
} from './value.utils';
