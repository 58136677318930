import { Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { GeneFakeClientConfiguration } from '../configurations/gene.fake-client.configuration';
import { GeneFakeClient } from '../services/gene.fake-client';

export class GeneFakeClientBuilder {

	private configuration: GeneFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(configuration: GeneFakeClientConfiguration): GeneFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): GeneFakeClient {
		return new GeneFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
