import { isValueDefined } from '@nmn-core/utils';
import { NmnMatTableUserConfiguration } from '../component-configurations/nmn-mat-table-user.configuration';

export class TreatmentCheckerPageConfiguration {

	public readonly diseaseCheckerResultsTableUserConfiguration: NmnMatTableUserConfiguration;

	protected constructor(
		diseaseCheckerResultsTableUserConfiguration: NmnMatTableUserConfiguration
	) {
		this.diseaseCheckerResultsTableUserConfiguration = diseaseCheckerResultsTableUserConfiguration;
	}

	public static createWithParameters(config?: TreatmentCheckerPageConfiguration): TreatmentCheckerPageConfiguration {
		if (!isValueDefined(config)) {
			return TreatmentCheckerPageConfiguration.createDefault();
		}

		return new TreatmentCheckerPageConfiguration(
			isValueDefined(config?.diseaseCheckerResultsTableUserConfiguration) ?
				NmnMatTableUserConfiguration.createWithParameters(config.diseaseCheckerResultsTableUserConfiguration) :
				NmnMatTableUserConfiguration.createDefault()
		);
	}

	public static createDefault(
	): TreatmentCheckerPageConfiguration {
		return new TreatmentCheckerPageConfiguration(
			NmnMatTableUserConfiguration.createWithParameters(
				new NmnMatTableUserConfiguration(undefined, undefined, undefined, 'desc', 'lastModifiedOn')
			)
		);
	}

}
