import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneClient, GeneComboboxDto, GeneFilterDto } from '@nmn-communication/genes';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { constructHttpParams } from '@nmn-core/utils';
import { Observable } from 'rxjs';
import { createFailedResultFromErrorResponse, createResultFromResponse } from '../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { GeneHttpClientConfiguration } from '../configurations/gene.http-client.configuration';

export class GeneHttpClient extends GeneClient {

	constructor(
		private readonly configuration: GeneHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public getBulkAsComboboxes(filter: GeneFilterDto): Observable<Result<Array<GeneComboboxDto>, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`;
		const params = constructHttpParams(filter);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<Array<GeneComboboxDto>>(url, { params, headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

}
