import { Injector } from '@angular/core';
import { AllergyIntoleranceStatusClient } from '@nmn-communication/allergy-intolerance-statuses';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { AllergyIntoleranceStatusFakeClientBuilder } from '../builders/allergy-intolerance-status.fake-client.builder';
import { AllergyIntoleranceStatusFakeClientConfigurationBuilder } from '../builders/allergy-intolerance-status.fake-client.configuration.builder';
import { AllergyIntoleranceStatusHttpClientBuilder } from '../builders/allergy-intolerance-status.http-client.builder';
import { AllergyIntoleranceStatusHttpClientConfigurationBuilder } from '../builders/allergy-intolerance-status.http-client.configuration.builder';
import { AllergyIntoleranceStatusFakeClient } from '../services/allergy-intolerance-status.fake-client';
import { AllergyIntoleranceStatusHttpClient } from '../services/allergy-intolerance-status.http-client';

export const allergyIntoleranceStatusClientFactory = (injector: Injector): AllergyIntoleranceStatusClient => {
	const useFake = environment?.api?.resources?.allergyIntoleranceStatuses?.useFake ||
		!isValueDefined(environment?.api?.resources?.allergyIntoleranceStatuses?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		allergyIntoleranceStatusFakeClientFactory(injector) :
		allergyIntoleranceStatusHttpClientFactory(injector);
};

export const allergyIntoleranceStatusFakeClientFactory = (
	injector: Injector
): AllergyIntoleranceStatusFakeClient => {
	const configuration = new AllergyIntoleranceStatusFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.allergyIntoleranceStatuses.serverDelayMs)
		.build();

	return new AllergyIntoleranceStatusFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const allergyIntoleranceStatusHttpClientFactory = (
	injector: Injector
): AllergyIntoleranceStatusHttpClient => {
	const configuration = new AllergyIntoleranceStatusHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.allergyIntoleranceStatuses?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.allergyIntoleranceStatuses.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.allergyIntoleranceStatuses?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new AllergyIntoleranceStatusHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
