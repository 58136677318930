import { Provider } from '@angular/core';
import { MedicationAdmRouteQueryHandlerService, MedicationAdmRoutesResolver } from '@nmn-domain/medication-adm-routes';
import { MedicationAdmRouteQueryHandlerViaClientService, MedicationAdmRoutesViaQueryHandlerResolver } from './index';

export const medicationAdmRouteProviders: Array<Provider> = [
	{
		provide: MedicationAdmRouteQueryHandlerService,
		useClass: MedicationAdmRouteQueryHandlerViaClientService
	},
	{
		provide: MedicationAdmRoutesResolver,
		useClass: MedicationAdmRoutesViaQueryHandlerResolver
	}
];
