import { Injectable } from '@angular/core';
import { TypeOfMedicationClient } from '@nmn-communication/types-of-medication';
import { Result } from '@nmn-core/shared';
import { FailureModel } from '@nmn-domain/shared';
import { TypeOfMedicationComboboxModel, TypeOfMedicationFilterModel, TypeOfMedicationQueryHandlerService } from '@nmn-domain/types-of-medication';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapDtoArrayResultToModelArrayResult } from '../../shared/factories/pagination.factory';
import { mapFilterModelToDto, mapTypeOfMedicationComboboxDtoToModel } from '../factories/type-of-medication.factory';

@Injectable()
export class TypeOfMedicationQueryHandlerViaClientService extends TypeOfMedicationQueryHandlerService {

	constructor(
		private readonly client: TypeOfMedicationClient
	) {
		super();
	}

	public getAsDescribedComboboxes(filter: TypeOfMedicationFilterModel): Observable<Result<Array<TypeOfMedicationComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapTypeOfMedicationComboboxDtoToModel))
			);
	}

}
