import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';
import { GdprPanelComponent } from './components/gdpr-panel/gdpr-panel.component';

@NgModule({
	exports: [
		GdprPanelComponent
	],
	declarations: [
		GdprPanelComponent
	],
	imports: [
		CommonModule,

		MatButtonModule,

		FlexLayoutModule,
		TranslocoModule
	]
})
export class GdprPanelModule { }
