import { DatePipe } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';
import { isValueDefined } from '@nmn-core/utils';
import { HealthIssueSeverityComboboxModel } from '../../health-issue-severities';
import { DescribedComboboxModel } from '../../shared/comboboxes/described-combobox.model';
import { HealthIssueStatus } from './health-issue-status.enum';

export class HealthIssueComboboxModel extends DescribedComboboxModel {

	public readonly dateStart?: Date;
	public readonly dateEnd?: Date;
	public readonly severity: HealthIssueSeverityComboboxModel;
	public readonly status: HealthIssueStatus;

	public get isPeriodStartDefined(): boolean {
		return isValueDefined(this.dateStart);
	}

	public get isPeriodEndDefined(): boolean {
		return isValueDefined(this.dateEnd);
	}

	constructor(
		id: string,
		displayText: string,
		description: string,
		dateStart: Date | undefined,
		dateEnd: Date | undefined,
		severity: HealthIssueSeverityComboboxModel,
		status: HealthIssueStatus
	) {
		super(id, displayText, description);

		// TODO: Guard check on defined
		this.dateStart = dateStart;
		this.dateEnd = dateEnd;
		this.severity = severity;
		this.status = status;
	}

	public static createDisplayOptionFn(translocoService: TranslocoService): (option: HealthIssueComboboxModel) => string | undefined {
		return (option: HealthIssueComboboxModel): string | undefined => {
			if (!isValueDefined(option)) {
				return undefined;
			}

			if (!option.isPeriodStartDefined && !option.isPeriodEndDefined) {
				return translocoService?.translate(
					'inlineViews.healthIssue.value_displayText',
					{
						displayText: option.displayText
					}
				);
			}

			const datePipe = new DatePipe(translocoService.getActiveLang());

			if (option.isPeriodStartDefined && !option.isPeriodEndDefined) {
				return translocoService?.translate(
					'inlineViews.healthIssue.value_displayText_from',
					{
						displayText: option.displayText,
						from: datePipe.transform(option.dateStart, 'yyyy-MM-dd', undefined, 'en-US')
					}
				);
			}

			if (!option.isPeriodStartDefined && option.isPeriodEndDefined) {
				return translocoService?.translate(
					'inlineViews.healthIssue.value_displayText_to',
					{
						displayText: option.displayText,
						to: datePipe.transform(option.dateEnd, 'yyyy-MM-dd', undefined, 'en-US')
					}
				);
			}

			if (option.isPeriodStartDefined && option.isPeriodEndDefined) {
				return translocoService?.translate(
					'inlineViews.healthIssue.value_displayText_from_to',
					{
						displayText: option.displayText,
						from: datePipe.transform(option.dateStart, 'yyyy-MM-dd', undefined, 'en-US'),
						to: datePipe.transform(option.dateEnd, 'yyyy-MM-dd', undefined, 'en-US')
					}
				);
			}

			return undefined;
		};
	}

}
