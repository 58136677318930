import { Provider } from '@angular/core';
import { LanguageQueryHandlerService, LanguagesResolver } from '@nmn-domain/languages';
import { LanguagesViaQueryHandlerResolver } from './resolvers/languages.via-query-handler.resolver';
import { LanguageQueryHandlerViaClientService } from './services/language.query-handler-via-client.service';

export const languageProviders: Array<Provider> = [
	{
		provide: LanguageQueryHandlerService,
		useClass: LanguageQueryHandlerViaClientService
	},
	{
		provide: LanguagesResolver,
		useClass: LanguagesViaQueryHandlerResolver
	}
];
