import { isValueDefined } from '@nmn-core/utils';

export enum TreatmentCheckerReportMedicationPgxLevelTestingAnnotation {

	Undefined = 'undefined',

	DosingInfo = 'dosingInfo',
	AlternateDrug = 'alternateDrug',
	PrescribingInfo = 'prescribingInfo',
	Pediatric = 'pediatric',
	FdaBiomarker = 'fdaBiomarker',
	CancerGenome = 'cancerGenome'

}

export const mapTreatmentCheckerReportMedicationPgxLevelTestingAnnotationFromString = (
	stringValue: string
): TreatmentCheckerReportMedicationPgxLevelTestingAnnotation => {
	const enumValue = Object
		.keys(TreatmentCheckerReportMedicationPgxLevelTestingAnnotation)
		.find(x => TreatmentCheckerReportMedicationPgxLevelTestingAnnotation[x] === stringValue);

	return isValueDefined(enumValue) ?
		stringValue as TreatmentCheckerReportMedicationPgxLevelTestingAnnotation :
		TreatmentCheckerReportMedicationPgxLevelTestingAnnotation.Undefined;
};
