import { MedicationAdmRouteDescribedComboboxDto, MedicationAdmRouteFilterDto } from '@nmn-communication/medication-adm-routes';
import { mapMedicationAdmRouteTypeFromString, MedicationAdmRouteDescribedComboboxModel, MedicationAdmRouteFilterModel } from '@nmn-domain/medication-adm-routes';

export const mapFilterModelToDto = (model: MedicationAdmRouteFilterModel): MedicationAdmRouteFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern
	}
);

export const mapFilterDtoToModel = (dto: MedicationAdmRouteFilterDto): MedicationAdmRouteFilterModel => (
	new MedicationAdmRouteFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern)
);

export const mapMedicationAdmRouteDescribedComboboxDtoToModel = (dto: MedicationAdmRouteDescribedComboboxDto): MedicationAdmRouteDescribedComboboxModel => (
	new MedicationAdmRouteDescribedComboboxModel(
		dto.id,
		dto.displayText,
		dto.description,
		mapMedicationAdmRouteTypeFromString(dto.type)
	)
);
