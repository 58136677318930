import { BloodTypeFilterDto } from '@nmn-communication/blood-types';
import { BloodTypeFilterModel } from '@nmn-domain/blood-types';

export const mapFilterModelToDto = (model: BloodTypeFilterModel): BloodTypeFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern
	}
);

export const mapFilterDtoToModel = (dto: BloodTypeFilterDto): BloodTypeFilterModel => (
	new BloodTypeFilterModel(
		dto.ids?.map(item => item.toString()),
		dto.ignoreIds?.map(item => item.toString()),
		dto.searchPattern
	)
);
