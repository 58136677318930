import {
	GeneticCardDto, GeneticCardFindParameterDto, GeneticTestCreateParameterDto, GeneticTestDto, GeneticTestFilterDto,
	GeneticTestFindParameterDto, GeneticTestResultCreateParameterDto, GeneticTestResultDto, GeneticTestUpdateParameterDto
} from '@nmn-communication/genetic-tests';
import { PagedCollectionDto } from '@nmn-communication/shared';
import { isArrayDefinedAndNotEmpty, isValueDefined, mapDateInServerFormatToDate, mapDateInServerFormatToDateSafely, mapDateToServerFormatSafely } from '@nmn-core/utils';
import {
	GeneticCardGetQuery, GeneticCardModel, GeneticTestCreateCommand, GeneticTestFilterModel,
	GeneticTestGetQuery, GeneticTestModel, GeneticTestResultCommand, GeneticTestResultModel,
	GeneticTestUpdateCommand, mapGeneticTestCreationTypeEnumFromString, mapGeneticTestCreationTypeEnumsFromStringArray
} from '@nmn-domain/genetic-tests';
import { PagedCollectionModel } from '@nmn-domain/shared';
import { mapGeneVariantComboboxDtoToModel } from '../../gene-variants/factories/gene-variant.factory';
import { mapGeneComboboxDtoToModel } from '../../genes/factories/gene.factory';
import { mapGeneticFileParseRequestDtoToModel } from '../../genetic-file-parse-requests/factories/genetic-file-parse-request.factory';
import { mapPagedCollectionDtoToModel } from '../../shared/factories/pagination.factory';

export const mapGeneticCardDtoToModel = (dto: GeneticCardDto): GeneticCardModel => (
	new GeneticCardModel(
		dto.patientId,
		dto.geneticTests?.map(mapGeneticTestDtoToModel),
		isValueDefined(dto.geneticFileParseRequest) ? mapGeneticFileParseRequestDtoToModel(dto.geneticFileParseRequest) : undefined
	)
);

export const mapGeneticTestDtoToModel = (dto: GeneticTestDto): GeneticTestModel => (
	new GeneticTestModel(
		dto.id,
		dto.patientId,
		mapDateInServerFormatToDateSafely(dto.date),
		mapGeneComboboxDtoToModel(dto.gene),
		isArrayDefinedAndNotEmpty(dto.geneticTestResults) ? dto.geneticTestResults.map(mapGeneticTestResultDtoToModel) : [],
		dto.comment,
		dto.certificateIssuer,
		dto.certificateUid,
		mapDateInServerFormatToDateSafely(dto.createdOn),
		mapDateInServerFormatToDate(dto.updatedOn),
		mapGeneticTestCreationTypeEnumFromString(dto.geneticTestCreationType)
	)
);

export const mapGeneticTestResultDtoToModel = (dto: GeneticTestResultDto): GeneticTestResultModel => (
	new GeneticTestResultModel(
		isValueDefined(dto.haplotype) ? mapGeneVariantComboboxDtoToModel(dto.haplotype) : undefined,
		isValueDefined(dto.diplotype) ? mapGeneVariantComboboxDtoToModel(dto.diplotype) : undefined,
		dto.pharmacogenomicTestResultCustom
	)
);

export const mapGeneticCardGetQueryToParameter = (query: GeneticCardGetQuery): GeneticCardFindParameterDto => (
	{
		patientId: query.patientId
	}
);

export const mapGeneticTestGetQueryToParameter = (query: GeneticTestGetQuery): GeneticTestFindParameterDto => (
	{
		id: query.id,
		patientId: query.patientId
	}
);

export const mapgeneticTestResultCommandToParameter = (command: GeneticTestResultCommand): GeneticTestResultCreateParameterDto => (
	{
		variantId: command.variantId,
		haplotypeId: command.haplotypeId,
		diplotypeId: command.diplotypeId,
		pharmacogenomicTestResultCustom: command.pharmacogenomicTestResultCustom,
	}
);

export const mapCreateCommandToCreateParameter = (command: GeneticTestCreateCommand): GeneticTestCreateParameterDto => (
	{
		patientId: command.patientId,
		date: mapDateToServerFormatSafely(command.date),
		geneId: command.geneId,
		geneticTestResults: command.geneticTestResults.map(mapgeneticTestResultCommandToParameter),
		comment: command.comment,
		certificateIssuer: command.certificateIssuer,
		certificateUid: command.certificateUid
	}
);

export const mapUpdateCommandToUpdateParameter = (command: GeneticTestUpdateCommand): GeneticTestUpdateParameterDto => (
	{
		patientId: command.patientId,
		date: mapDateToServerFormatSafely(command.date),
		geneId: command.geneId,
		geneticTestResults: command.geneticTestResults.map(mapgeneticTestResultCommandToParameter),
		comment: command.comment,
		certificateIssuer: command.certificateIssuer,
		certificateUid: command.certificateUid
	}
);

export const mapFilterModelToDto = (model: GeneticTestFilterModel): GeneticTestFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		patientId: model.patientId,
		searchPattern: model.searchPattern,
		geneticTestCreationTypes: model.geneticTestCreationTypes,
		ignoreGeneticTestCreationTypes: model.ignoreGeneticTestCreationTypes
	}
);

export const mapGeneticTestPagedCollectionDtoToModel = (
	pagedCollectionDto: PagedCollectionDto<GeneticTestDto, GeneticTestFilterDto>
): PagedCollectionModel<GeneticTestModel, GeneticTestFilterModel> =>
	mapPagedCollectionDtoToModel(pagedCollectionDto, mapGeneticTestDtoToModel, mapFilterDtoToModel);

export const mapFilterDtoToModel = (dto: GeneticTestFilterDto): GeneticTestFilterModel => (
	new GeneticTestFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.patientId,
		dto.searchPattern,
		mapGeneticTestCreationTypeEnumsFromStringArray(dto.geneticTestCreationTypes),
		mapGeneticTestCreationTypeEnumsFromStringArray(dto.ignoreGeneticTestCreationTypes)
	)
);
