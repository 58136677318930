import { DocumentFormOutModel } from '../document-form/document-form.out-model';

export class DocumentUpdateCommand {

	public readonly id: string;
	public readonly patientId: string;
	public readonly name: string;
	public readonly description: string;
	public readonly typeId: string;
	public readonly attachmentIds: Array<string>;
	public readonly relatedEncounterIds: Array<string>;
	public readonly relatedTakenMedicationIds: Array<string>;
	public readonly relatedHealthIssueIds: Array<string>;

	private constructor(
		id: string,
		patientId: string,
		name: string,
		description: string,
		typeId: string,
		attachmentIds: Array<string>,
		relatedEncounterIds: Array<string>,
		relatedTakenMedicationIds: Array<string>,
		relatedHealthIssueIds: Array<string>
	) {

		// TODO: add null check

		this.id = id;
		this.patientId = patientId;
		this.name = name;
		this.description = description;
		this.typeId = typeId;
		this.attachmentIds = attachmentIds;
		this.relatedEncounterIds = relatedEncounterIds;
		this.relatedTakenMedicationIds = relatedTakenMedicationIds;
		this.relatedHealthIssueIds = relatedHealthIssueIds;

	}

	public static CreateByFormOutModel(
		id: string,
		formOutModel: DocumentFormOutModel
	): DocumentUpdateCommand {
		return new DocumentUpdateCommand(
			id,
			formOutModel.patientId,
			formOutModel.name,
			formOutModel.description,
			formOutModel.typeId,
			formOutModel.attachments,
			formOutModel.relatedEncounterIds,
			formOutModel.relatedTakenMedicationIds,
			formOutModel.relatedHealthIssueIds
		);
	}

}
