import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { GeneVariantHttpClientConfiguration } from '../configurations/gene-variant.http-client.configuration';

export class GeneVariantHttpClientConfigurationBuilder extends HttpClientConfigurationBuilder<GeneVariantHttpClientConfiguration> {

	public build(): GeneVariantHttpClientConfiguration {
		return new GeneVariantHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion
		);
	}

}
