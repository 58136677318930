import { Result } from '@nmn-core/shared';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { VaccineDescribedComboboxModel } from '../models/vaccine-described-combobox.model';
import { VaccineFilterModel } from '../models/vaccine-filter.model';

export abstract class VaccineQueryHandlerService {

	public abstract getAsDescribedComboboxes(query: VaccineFilterModel): Observable<Result<Array<VaccineDescribedComboboxModel>, FailureModel>>;

}
