import {
	FileUploaderClient,
	UploadedFileDto,
	UploadFileCancelParameter,
	UploadFileCompleteParameter,
	UploadFileParameter
} from '@nmn-communication/file-uploader';
import { Failure } from '@nmn-communication/shared';
import { LocalStorageService } from '@nmn-core/application-storages';
import { EmptyResult, Result } from '@nmn-core/shared';
import { toKB } from '@nmn-core/utils';
import { wrapActionCallAsFakeClientResponse } from 'app/modules/fakes/databases/fake.utils';
import { Observable, of } from 'rxjs';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { FileUploaderFakeClientConfiguration } from '../configurations/file-uploader-fake-client.configuration';

export class FileUploaderFakeClient extends FileUploaderClient {

	constructor(
		private readonly configuration: FileUploaderFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase,
		private readonly localStorage: LocalStorageService
	) {
		super();
	}

	private size: number;
	private type: string;

	public initiateUploadFile(
		parameter: UploadFileParameter
	): Observable<Result<UploadedFileDto, Failure>> {
		wrapActionCallAsFakeClientResponse(
			// TODO: remove usage of localstorage in fakes
			() => { this.fakeDatabase.userSubscriptionFakeTable.incrementTreatmentCheckerUsed(this.localStorage.getLastUserId()); },
			{ min: 0, max: 0 }
		);
		const size = Math.round(toKB(parameter.file.size));
		this.size = size;
		this.type = parameter.fileType.toString();
		switch (parameter.fileType) {
			default: {
				const result: UploadedFileDto = {
					id: '00000001-0000-0000-0000-000000000001',
					downloadUrl: 'https://fakeimg.pl/200x200',
					fileName: 'fake-img',
					fileExtension: {
						id: '.file',
						displayText: undefined,
						description: undefined,
						fileBlobIconType: undefined
					},
					lengthInBytes: size,
					updatedOn: undefined,
					createdOn: new Date().toString(),
					fileType: parameter.fileType.toString()
				};

				return of(Result.success<UploadedFileDto, Failure>(result));
			}
		}
	}

	public completeUploadFile(
		_: UploadFileCompleteParameter
	): Observable<Result<UploadedFileDto, Failure>> {
		return of(Result.success<UploadedFileDto, Failure>(this.createUploadedFileDto()));
	}

	public cancelUploadFile(_: UploadFileCancelParameter): Observable<EmptyResult<Failure>> {
		return of(EmptyResult.success<Failure>());
	}

	private createUploadedFileDto(): UploadedFileDto {
		return {
			id: '00000001-0000-0000-0000-000000000001',
			downloadUrl: 'https://fakeimg.pl/200x200',
			fileName: 'fake-img',
			fileExtension: {
				id: '.file',
				displayText: undefined,
				description: undefined,
				fileBlobIconType: undefined
			},
			lengthInBytes: this.size,
			updatedOn: undefined,
			createdOn: new Date().toString(),
			fileType: this.type
		};
	}
}
