
import { TranslocoService } from '@ngneat/transloco';
import { VaccineDescribedComboboxDto } from '@nmn-communication/vaccines';
import { isValueDefined } from '@nmn-core/utils';
import { setTranslation } from '../../utils/localize';
import { FakeLocalizableEntity } from '../fake-localizable-entity';
import { FakeDescribedComboboxDto } from './fake-described-combobox.dto';

export interface FakeVaccineDescribedComboboxDto extends FakeDescribedComboboxDto<string> {

	countOfDoses: number;
	manufacturer?: FakeLocalizableEntity;
	type: string;
	forDiseases: Array<string>;

}

export const mapFakeVaccineDescriptedComboboxDtoToVaccinesDescriptedComboboxDto = (
	translocoService: TranslocoService,
	fakeDescribedComboboxDto: FakeVaccineDescribedComboboxDto
): VaccineDescribedComboboxDto => {
	if (!isValueDefined(fakeDescribedComboboxDto)) {
		return undefined;
	}

	return {
		id: fakeDescribedComboboxDto.id,
		displayText: setTranslation(translocoService, fakeDescribedComboboxDto.displayText),
		description: setTranslation(translocoService, fakeDescribedComboboxDto.description),
		countOfDoses: fakeDescribedComboboxDto.countOfDoses,
		manufacturer: setTranslation(translocoService, fakeDescribedComboboxDto.manufacturer),
		type: fakeDescribedComboboxDto.type,
		forDiseases: fakeDescribedComboboxDto.forDiseases
	};
};
