import { isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { FailureControlDirectoryModel, PropertySeverityType } from './failure-control-directory.model';
import { FailureDialogDirectoryModel } from './failure-dialog-directory.model';
import { FailureFormDirectoryModel } from './failure-form-directory.model';
import { FailureLocalizationModel } from './failure-localization-parameters.model';
import { FailureTabDirectoryModel } from './failure-tab-directory.model';
import { FailureTextDirectoryModel } from './failure-text-directory.model';
import { FailureWrapperModel } from './failure-wrapper.model';

// TODO: #300 lookbehind assertions not supported in Javascript.
// Probably issue with version in package.json and there is no problem.
// const regularFailureCodeRetrieveExpressionPattern = '(?<=\[)[0-9]{1,}(?=\])';
const regularFailureCodeRetrieveExpressionPattern = '[0-9]{1,}(?=\])';
const indexVar = '[indexVar]';

// TODO: Refactoring required
export class FailureModel {

	private readonly resources: Array<FailureResourceModel>;
	private readonly traceIdentifier?: string;

	public readonly failureWrapperModel: FailureWrapperModel;
	public readonly condition: FailureCondition;
	public readonly failureLocalizationModel: FailureLocalizationModel;

	private readonly _metadata: Array<any>;

	public get metadata(): ReadonlyArray<any> {
		return this._metadata;
	}

	private constructor(
		resources: Array<FailureResourceModel>,
		condition: FailureCondition,
		failureWrapperModel: FailureWrapperModel,
		failureLocalizationModel: FailureLocalizationModel,
		traceIdentifier: string,
		metadata: Array<any> = []
	) {
		this.resources = resources;
		this.condition = condition;
		this.traceIdentifier = traceIdentifier;
		this.failureWrapperModel = failureWrapperModel;
		this.failureLocalizationModel = failureLocalizationModel;
		this._metadata = metadata;
	}

	public static createForBackendIssue(
		resources: Array<FailureResourceModel>,
		failureWrapperModel: FailureWrapperModel,
	): FailureModel {
		return new FailureModel(
			resources,
			FailureCondition.createForMapping(FailureMappingStatus.SuccessMapping, FailureSeverity.Error),
			failureWrapperModel,
			undefined,
			failureWrapperModel.tryGetTraceIdentifier()
		);
	}

	public static createLocaliableFailure(
		failureWrapperModel: FailureWrapperModel,
		failureLocalizationModel: FailureLocalizationModel,
		failureSeverity?: FailureSeverity
	): FailureModel {

		let severity = failureSeverity;
		if (!isValueDefined(severity)) {
			severity = FailureSeverity.Error;
		}
		return new FailureModel(
			undefined,
			FailureCondition.createForSource(FailureSource.Unknown, severity),
			failureWrapperModel,
			failureLocalizationModel,
			failureWrapperModel.tryGetTraceIdentifier()
		);
	}

	public static createFileUploadIssue(
		failureWrapperModel: FailureWrapperModel,
		failureLocalizationModel: FailureLocalizationModel,
		metadata: Array<any> = []
	): FailureModel {
		return new FailureModel(
			undefined,
			FailureCondition.createForSource(FailureSource.FileUpload, FailureSeverity.Critical),
			failureWrapperModel,
			failureLocalizationModel,
			failureWrapperModel.tryGetTraceIdentifier(),
			metadata
		);
	}

	public static createFailureLocalizedViaExistingFailure(
		failureModel: FailureModel,
		localization: FailureLocalizationModel,
		failureSeverity?: FailureSeverity
	): FailureModel {

		let severity = failureSeverity;
		if (!isValueDefined(severity)) {
			severity = failureModel.condition.severity;
		}
		return new FailureModel(
			failureModel.resources,
			FailureCondition.createForSource(failureModel.condition.source, severity),
			failureModel.failureWrapperModel,
			localization,
			failureModel.traceIdentifier,
		);
	}

	public static createDeleteResourceIssue(
		existingFailure: FailureModel,
		failureLocalizationModel: FailureLocalizationModel
	): FailureModel {
		return new FailureModel(
			existingFailure.resources,
			FailureCondition.createForSource(FailureSource.DeleteResourceIssue, FailureSeverity.Error),
			existingFailure.failureWrapperModel,
			failureLocalizationModel,
			existingFailure.traceIdentifier,
			existingFailure._metadata
		);
	}

	public static createForUndefinedIssue(
		failureWrapperModel: FailureWrapperModel,
		failureLocalizationModel: FailureLocalizationModel
	): FailureModel {
		return new FailureModel(
			undefined,
			FailureCondition.createForSource(FailureSource.Unknown, FailureSeverity.Error),
			failureWrapperModel,
			failureLocalizationModel,
			failureWrapperModel.tryGetTraceIdentifier()
		);
	}

	public static createForInvalidForm(
		failureLocalizationModel: FailureLocalizationModel
	): FailureModel {
		return new FailureModel(
			undefined,
			FailureCondition.createForSource(FailureSource.InvalidForm, FailureSeverity.Error),
			FailureWrapperModel.createEmpty(),
			failureLocalizationModel,
			undefined
		);
	}

	public static createForBackendIssueEmptyModel(
		failureWrapperModel: FailureWrapperModel,
		failureLocalizationModel: FailureLocalizationModel
	): FailureModel {
		return new FailureModel(
			undefined,
			FailureCondition.createForMapping(FailureMappingStatus.FailedMappingEmptyModel, FailureSeverity.Error),
			failureWrapperModel,
			failureLocalizationModel,
			failureWrapperModel.tryGetTraceIdentifier()
		);
	}

	public static createForNavigationIssue(
		failureWrapperModel: FailureWrapperModel,
		failureLocalizationModel: FailureLocalizationModel
	): FailureModel {
		return new FailureModel(
			undefined,
			FailureCondition.createForSource(FailureSource.NavigationIssue, FailureSeverity.Error),
			failureWrapperModel,
			failureLocalizationModel,
			failureWrapperModel.tryGetTraceIdentifier()
		);
	}

	public static createForNotFoundIssue(failureLocalizationModel: FailureLocalizationModel): FailureModel {
		return new FailureModel(
			undefined,
			FailureCondition.createForSource(FailureSource.PatientDataNotFoundIssue, FailureSeverity.Error),
			undefined,
			failureLocalizationModel,
			undefined
		);
	}

	public static createForNotFoundPatientIssue(failureLocalizationModel: FailureLocalizationModel): FailureModel {
		return new FailureModel(
			undefined,
			FailureCondition.createForSource(FailureSource.PatientNotFoundIssue, FailureSeverity.Critical),
			undefined,
			failureLocalizationModel,
			undefined
		);
	}

	public static createForSubscribtionIssue(
		failureWrapperModel: FailureWrapperModel,
		failureLocalizationModel: FailureLocalizationModel
	): FailureModel {
		return new FailureModel(
			undefined,
			FailureCondition.createForSource(FailureSource.SubscribtionIssue, FailureSeverity.Error),
			failureWrapperModel,
			failureLocalizationModel,
			failureWrapperModel.tryGetTraceIdentifier()
		);
	}

	public static createForGeneralException(
		failureWrapperModel: FailureWrapperModel,
		failureLocalizationModel: FailureLocalizationModel
	): FailureModel {
		return new FailureModel(
			undefined,
			FailureCondition.createForSource(FailureSource.Exception, FailureSeverity.Error),
			failureWrapperModel,
			failureLocalizationModel,
			failureWrapperModel.tryGetTraceIdentifier()
		);
	}

	public static createForArgumentException(argumentName: string, reason: string): FailureModel {
		return new FailureModel(
			undefined,
			FailureCondition.createForSource(FailureSource.ArgumentException, FailureSeverity.Error),
			FailureWrapperModel.createFromValue({ argumentName, reason }),
			undefined,
			undefined
		);
	}

	public static createForNotFoundException(argumentName: string): FailureModel {
		return new FailureModel(
			undefined,
			FailureCondition.createForSource(FailureSource.NotFoundException, FailureSeverity.Error),
			FailureWrapperModel.createFromValue({ argumentName }),
			undefined,
			undefined
		);
	}

	public static createWithAppendedMetadata(failureModel: FailureModel, metadataToAppend: any): FailureModel {
		return new FailureModel(
			failureModel.resources,
			failureModel.condition,
			failureModel.failureWrapperModel,
			failureModel.failureLocalizationModel,
			failureModel.traceIdentifier,
			[
				...failureModel.metadata,
				metadataToAppend
			]
		);
	}

	public getReceivedSpecialCodes = () => {
		return (this.resources || []).map(resource => resource.code.specialCode);
	}

	public initializeFormDirections = (
		mapPropertyNameToCtrl: Map<string, string>,
		writeUnmappedCtrlsAsTextDirections: boolean = false,
		ignoreTextDirections: boolean = false,
		resourceName: string = undefined
	): FailureFormDirectoryModel => {
		const controlDirections = this.initializeControlDirections(
			mapPropertyNameToCtrl,
			resourceName);

		const mappedCtrlDirections = controlDirections.filter(direction => isValueDefined(direction.ctrlName));
		const unmappedCtrlDirections = controlDirections.filter(direction => !isValueDefined(direction.ctrlName));

		if (writeUnmappedCtrlsAsTextDirections) {
			const textDirections = this.initializeTextDirections()
				.concat(unmappedCtrlDirections.map(item => new FailureTextDirectoryModel(item.failureHint)));

			const directions = FailureFormDirectoryModel.create(
				!ignoreTextDirections ? textDirections : [],
				mappedCtrlDirections);

			return directions;
		}

		const textDirections = this.initializeTextDirections();
		const directions = FailureFormDirectoryModel.create(
			!ignoreTextDirections ? textDirections : [],
			mappedCtrlDirections);

		return directions;
	}

	public initializeControlDirections = (
		mapPropertyNameToCtrl: Map<string, string>,
		resourceName: string = undefined
	): Array<FailureControlDirectoryModel> => {
		const properties = (isStringDefinedAndNotEmpty(resourceName) ?
			((this.resources || [])
				.filter(resource => resource.code.failureOperationType === FailureModelOperationType.Form)
				.find(resource => resource.resourceName === resourceName)?.properties || []) :
			(this.resources || []).flatMap(resource => resource.properties)) || [];

		const directions = properties.map((prop: FailureDetailModel) => {
			if (isValueDefined(prop.name)) {
				const mappedCtrl = mapPropertyNameToCtrl?.get(prop.name);
				if (isValueDefined(mappedCtrl)) {
					return new FailureControlDirectoryModel(
						mapPropertyNameToCtrl?.get(prop.name),
						mapPropertyErrorSeverityTypeToComponentPropertySeverityType(prop.severity),
						prop.code
					);
				}
				// TODO: #300 remove this mess
				const detachedName = prop.name.substring(prop.name.indexOf('[') + 1, prop.name.length);
				const matchRegArray = detachedName.match(regularFailureCodeRetrieveExpressionPattern);
				const indexString = matchRegArray?.length > 0 ? matchRegArray[0] : undefined;
				const index = parseInt(indexString, 10);
				if (!isNaN(index)) {
					const name = prop.name.replace(`[${index}]`, indexVar);

					return new FailureControlDirectoryModel(
						mapPropertyNameToCtrl?.get(name),
						mapPropertyErrorSeverityTypeToComponentPropertySeverityType(prop.severity),
						prop.code,
						isNaN(index) ? undefined : index
					);
				}
			}

			return new FailureControlDirectoryModel(
				undefined,
				mapPropertyErrorSeverityTypeToComponentPropertySeverityType(prop.severity),
				prop.code
			);
		});

		return directions;
	}

	public initializeTextDirections = (): Array<FailureTextDirectoryModel> => {
		if (this.condition.mapping === FailureMappingStatus.SuccessMapping) {
			const directions = (this.resources || [])
				.filter(item => item.code.failureOperationType === FailureModelOperationType.Text)
				.map(item => new FailureTextDirectoryModel(`${item.code.specialCode}`)
				);

			return directions;
		}

		return [];
	}

	public initializeTabDirections = (): Array<FailureTabDirectoryModel> => {
		if (this.condition.mapping === FailureMappingStatus.SuccessMapping) {
			const directions = (this.resources || [])
				.filter(item => item.code.failureOperationType === FailureModelOperationType.Tab)
				.map(item => new FailureTextDirectoryModel(item.code.specialCode));

			return directions;
		}

		return [];
	}

	public initializeDialogDirection = (): FailureDialogDirectoryModel => {
		if (this.condition.mapping === FailureMappingStatus.SuccessMapping) {
			const resources = (this.resources || [])
				.filter(item => item.code.failureOperationType === FailureModelOperationType.Dialog);

			if (resources.length > 0) {
				const direction = new FailureDialogDirectoryModel(
					resources.map(item => item.code.specialCode)
				);

				return direction;
			}
		}

		return undefined;
	}

}

export class FailureCondition {

	public readonly mapping: FailureMappingStatus;
	public readonly source: FailureSource;
	public readonly severity: FailureSeverity;

	private constructor(
		mapping: FailureMappingStatus,
		source: FailureSource,
		severity: FailureSeverity
	) {
		this.mapping = mapping;
		this.source = source;
		this.severity = severity;
	}

	public static createForMapping(
		mapping: FailureMappingStatus,
		severity: FailureSeverity
	): FailureCondition {
		return new FailureCondition(mapping, FailureSource.ServerResponse, severity);
	}

	public static createForSource(
		source: FailureSource,
		severity: FailureSeverity
	): FailureCondition {
		return new FailureCondition(FailureMappingStatus.SuccessMapping, source, severity);
	}

}

export enum FailureSeverity {

	Error = 'error',
	Critical = 'critical'

}

export enum FailureMappingStatus {

	SuccessMapping = 'successMapping',
	FailedMapping = 'failedMapping',
	FailedMappingEmptyModel = 'failedMappingEmptyModel'

}

export enum FailureSource {

	Unknown = 'undefined',
	ServerResponse = 'serverResponse',
	NavigationIssue = 'navigationIssue',
	PatientDataNotFoundIssue = 'patientDataNotFoundIssue',
	PatientNotFoundIssue = 'patientNotFoundIssue',
	SubscribtionIssue = 'subscribtionIssue',
	InvalidForm = 'invalidForm',
	DeleteResourceIssue = 'deleteResourceIssue',
	FileUpload = 'fileUpload',

	Exception = 'exception',
	ArgumentException = 'argumentException',
	NotFoundException = 'notFoundException'

}

export class FailureResourceModel {

	public readonly resourceName: string;
	public readonly code: FailureCodeModel;
	public readonly properties: Array<FailureDetailModel>;

	private constructor(
		resourceName: string,
		code: FailureCodeModel,
		properties: Array<FailureDetailModel>
	) {
		// TODO: Guard
		this.resourceName = resourceName;
		this.code = code;
		this.properties = properties;
	}

	public static create(
		resourceName: string,
		code: FailureCodeModel,
		properties: Array<FailureDetailModel>): FailureResourceModel {
		return new FailureResourceModel(resourceName, code, properties);
	}
}

export class FailureDetailModel {

	public readonly name: string;
	public readonly message: string;
	public readonly code: string;
	public readonly severity: PropertyFailureModelSeverityType;

	private constructor(
		name: string,
		message: string,
		code: string,
		severity: PropertyFailureModelSeverityType
	) {
		// TODO: Guard
		this.name = name;
		this.message = message;
		this.code = code;
		this.severity = severity;
	}

	public static create(
		name: string,
		message: string,
		code: string,
		severity: PropertyFailureModelSeverityType
	): FailureDetailModel {
		return new FailureDetailModel(
			name,
			message,
			code,
			severity);
	}

}

export enum PropertyFailureModelSeverityType {

	Info = 'info',
	Warning = 'warning',
	Error = 'error'

}

export const mapPropertyFailureModelSeverityTypeFromString = (stringValue: string): PropertyFailureModelSeverityType => {
	const enumValue = Object
		.keys(PropertyFailureModelSeverityType)
		.find(x => PropertyFailureModelSeverityType[x] === stringValue);

	return isValueDefined(enumValue) ? stringValue as PropertyFailureModelSeverityType : PropertyFailureModelSeverityType.Error;
};

export class FailureCodeModel {

	public readonly severity: FailureModelSeverityType;
	public readonly failureOperationType: FailureModelOperationType;
	public readonly specialCode: string;

	private constructor(
		severity: FailureModelSeverityType,
		failureOperationType: FailureModelOperationType,
		specialCode: string
	) {
		// TODO: Guard
		this.severity = severity;
		this.failureOperationType = failureOperationType;
		this.specialCode = specialCode;
	}

	public static create(
		severity: FailureModelSeverityType,
		failureOperationType: FailureModelOperationType,
		specialCode: string
	): FailureCodeModel {
		return new FailureCodeModel(
			severity,
			failureOperationType,
			specialCode);
	}

}

export enum FailureModelSeverityType {

	Info = 'info',
	Warning = 'warning',
	Error = 'error',
	Fatal = 'fatal'

}

export const mapFailureModelSeverityTypeFromString = (stringValue: string): FailureModelSeverityType => {
	const enumValue = Object
		.keys(FailureModelSeverityType)
		.find(x => FailureModelSeverityType[x] === stringValue);

	return isValueDefined(enumValue) ?
		stringValue as FailureModelSeverityType :
		FailureModelSeverityType.Error;
};

export enum FailureModelOperationType {

	Form = 'form',
	FormGroup = 'formGroup',
	Text = 'text',
	Dialog = 'dialog',
	Tab = 'tab'

}

export const mapFailureModelOperationTypeFromString = (stringValue: string): FailureModelOperationType => {
	const enumValue = Object
		.keys(FailureModelOperationType)
		.find(x => FailureModelOperationType[x] === stringValue);

	return isValueDefined(enumValue) ?
		stringValue as FailureModelOperationType :
		FailureModelOperationType.Tab;
};

const mapPropertyErrorSeverityTypeToComponentPropertySeverityType = (severity: PropertyFailureModelSeverityType): PropertySeverityType => {
	switch (severity) {
		case PropertyFailureModelSeverityType.Error:
			return PropertySeverityType.Error;
		case PropertyFailureModelSeverityType.Info:
			return PropertySeverityType.Info;
		case PropertyFailureModelSeverityType.Warning:
			return PropertySeverityType.Warning;
		default:
			return PropertySeverityType.Error;
	}
};
