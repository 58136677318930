import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HealthIssueSeveritiesResolver, HealthIssueSeverityComboboxModel, HealthIssueSeverityFilterModel, HealthIssueSeverityQueryHandlerService } from '@nmn-domain/health-issue-severities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HealthIssueSeveritiesViaQueryHandlerResolver extends HealthIssueSeveritiesResolver {

	constructor(
		private readonly genderQueryHandlerService: HealthIssueSeverityQueryHandlerService
	) {
		super();
	}

	public resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<Array<HealthIssueSeverityComboboxModel>> | Array<HealthIssueSeverityComboboxModel> {
		return this.genderQueryHandlerService
			.getAsDescribedComboboxes(HealthIssueSeverityFilterModel.createEmpty())
			.pipe(map(result => result.successOrDefault(() => [])));
	}

}
