import { Injectable } from '@angular/core';
import { GeneticFileParseRequestClient } from '@nmn-communication/genetic-file-parse-requests';
import { Result } from '@nmn-core/shared';
import {
	GeneticFileParseRequestFilterModel, GeneticFileParseRequestGetQuery, GeneticFileParseRequestGetStateQuery,
	GeneticFileParseRequestModel, GeneticFileParseRequestQueryHandlerService
} from '@nmn-domain/genetic-file-parse-requests';
import { FailureModel, PagedCollectionModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapPagedCollectionDtoToModel } from '../../shared/factories/pagination.factory';
import { mapGeneticFileParseRequestDtoToModel, mapGeneticFileParseRequestFilterDtoToModel } from '../factories/genetic-file-parse-request.factory';

@Injectable()
export class GeneticFileParseRequestQueryHandlerViaClientService extends GeneticFileParseRequestQueryHandlerService {

	constructor(
		private readonly client: GeneticFileParseRequestClient
	) {
		super();
	}

	public handleBulkGetQuery(
		query: GeneticFileParseRequestGetStateQuery
	): Observable<Result<
		PagedCollectionModel<
			GeneticFileParseRequestModel,
			GeneticFileParseRequestFilterModel>,
		FailureModel>> {
		return this.client
			.getBulk({ filter: { patientId: query.patientId, isActive: query.isActive, status: query.status } })
			.pipe(map(result => result.map(
				pagedCollectionDto => mapPagedCollectionDtoToModel(
					pagedCollectionDto,
					mapGeneticFileParseRequestDtoToModel,
					mapGeneticFileParseRequestFilterDtoToModel),
				mapFailureToFailureModel)
			));

	}

	public handleGetQuery(
		query: GeneticFileParseRequestGetQuery
	): Observable<Result<GeneticFileParseRequestModel, FailureModel>> {
		return this.client
			.get({ id: query.id, patientId: query.patientId, isActive: query.isActive })
			.pipe(map(result => result.map(
				mapGeneticFileParseRequestDtoToModel,
				mapFailureToFailureModel)
			));
	}

}
