import { Injector, Provider } from '@angular/core';
import { BloodTypeClient } from '@nmn-communication/blood-types';
import { bloodTypeClientFactory } from './factories/blood-type.client.factory';

export const bloodTypeProviders: Array<Provider> = [
	{
		provide: BloodTypeClient,
		useFactory: bloodTypeClientFactory,
		deps: [Injector]
	}
];
