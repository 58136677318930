export class FailureWrapperModel {

	private readonly value: any

	private constructor(value: any) {
		this.value = value;
	}

	public static createFromValue(
		value: any
	): FailureWrapperModel {
		return new FailureWrapperModel(value);
	}

	public static createEmpty(): FailureWrapperModel {
		return new FailureWrapperModel(undefined);
	}

	public tryGetTraceIdentifier() {
		return this.value.error?.traceIdentifier;
	}

}
