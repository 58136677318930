import { Injectable } from '@angular/core';
import { getBrowserLang, HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';
import { LocalStorageService } from '@nmn-core/application-storages';
import { merge, Subject, Subscription } from 'rxjs';

@Injectable()
export class ApplicationLanguageService {

	private readonly currentApplicationLanguageSubject$ = new Subject<string>();
	public readonly currentApplicationLanguage$ = this.currentApplicationLanguageSubject$.asObservable();

	private currentAppLanguage: string;
	public get currentApplicationLanguage(): string {
		return this.currentAppLanguage;
	}

	public get currentBrowserLanguage(): string {
		return getBrowserLang();
	}

	private subscription: Subscription = undefined;

	constructor(
		private readonly translocoService: TranslocoService,
		private readonly localStorage: LocalStorageService
	) {
		this.currentApplicationLanguage$.subscribe();
		this.initializeLanguage();
	}

	public translate(key: TranslateParams, params?: HashMap, lang?: string): string {
		return this.translocoService.translate(key, params, lang);
	}

	public initializeSubscriptions(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = undefined;
		}

		merge(this.translocoService.langChanges$, this.translocoService.events$)
			.subscribe(() => {
				this.currentAppLanguage = this.translocoService.getActiveLang();
				this.localStorage.setLanguage(this.currentAppLanguage);
				this.currentApplicationLanguageSubject$.next(this.currentAppLanguage);
			});

		this.initializeLanguage();
	}

	private initializeLanguage(): void {
		const languages = this.translocoService.getAvailableLangs() as Array<string>;
		let language = this.localStorage.getLanguage();

		if (languages.indexOf(language) < 0) {
			language = getBrowserLang();

			if (languages.indexOf(language) < 0) {
				language = languages[0];
			}
		}

		this.currentAppLanguage = language;
		this.translocoService.setActiveLang(this.currentAppLanguage);
	}

}
