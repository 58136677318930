import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PopulationComboboxModel } from '../models/population-combobox.model';

export abstract class PopulationResolver implements Resolve<Array<PopulationComboboxModel>> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	): Observable<Array<PopulationComboboxModel>> | Array<PopulationComboboxModel>;

}
