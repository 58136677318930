import { Injectable } from '@angular/core';
import { LoginClient, LoginDto, TokenInfoDto } from '@nmn-communication/accounts';
import { Failure } from '@nmn-communication/shared';
import { LoginCommand, LoginCommandHandlerService, LoginViaFacebookCommand, LoginViaGoogleCommand } from '@nmn-domain/accounts';
import { FailureModel } from '@nmn-domain/shared';
import { FailureLocalizationModel } from '@nmn-domain/shared/failures/failure-localization-parameters.model';
import { FailureWrapperModel } from '@nmn-domain/shared/failures/failure-wrapper.model';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CookieStorageService } from '../../../../../core/application-storages';
import { Result } from '../../../../../core/shared';
import { EmptyCommandResult, EmptyResultOfEmptyCommand } from '../../../../../modules/core/models/commands/command-result';
import { FailureHandlingService, StorageService, updateSubscriptionsAfterLogin, UserConfigurationStorageService } from '../../../../../services';
import { mapFailureToFailureModel } from '../../../shared/factories/failure-handling.factory';
import { mapLoginCommandToParameter, mapLoginDtoToModel, mapLoginViaFacebookCommandToParameter, mapLoginViaGoogleCommandToParameter, mapTokenInfoDtoToModel } from '../factories/login.factory';

@Injectable()
export class LoginCommandHandlerViaClientService extends LoginCommandHandlerService {

	// TODO: Maybe better move in another place
	private readonly localizationKeyPattern = 'modules.shared.interceptors.authInterceptor';

	public constructor(
		private readonly client: LoginClient,
		private readonly storage: StorageService,
		private readonly cookieStorage: CookieStorageService,
		private readonly userConfigurationStorage: UserConfigurationStorageService,
		private readonly failureHandlingService: FailureHandlingService
	) {
		super();
	}

	public handleLogin(command: LoginCommand): Observable<Result<EmptyCommandResult<LoginCommand>, FailureModel>> {
		return this.client
			.login(mapLoginCommandToParameter(command))
			.pipe(
				tap(this.updateStorageInfoViaResult.bind(this)),
				map(result => result.map(() => new EmptyCommandResult(command), mapFailureToFailureModel))
			);
	}

	public handleLoginViaGoogle(command: LoginViaGoogleCommand): Observable<Result<EmptyCommandResult<LoginViaGoogleCommand>, FailureModel>> {
		return this.client
			.loginViaGoogle(mapLoginViaGoogleCommandToParameter(command))
			.pipe(
				tap(this.updateStorageInfoViaResult.bind(this)),
				map(result => result.map(() => new EmptyCommandResult(command), mapFailureToFailureModel))
			);
	}

	public handleLoginViaFacebook(command: LoginViaFacebookCommand): Observable<Result<EmptyCommandResult<LoginViaFacebookCommand>, FailureModel>> {
		return this.client
			.loginViaFacebook(mapLoginViaFacebookCommandToParameter(command))
			.pipe(
				tap(this.updateStorageInfoViaResult.bind(this)),
				map(result => result.map(() => new EmptyCommandResult(command), mapFailureToFailureModel))
			);
	}

	public refreshToken(): Observable<Result<EmptyResultOfEmptyCommand, FailureModel>> {
		return this.cookieStorage
			.getUserRefreshToken(this.storage.userId)
			.consume(
				refreshToken => {
					return this.client
						.refreshToken({ refreshToken })
						.pipe(
							tap({ next: this.updateStorageInfoWithRefreshedToken.bind(this), error: this.storage.logout.bind(this) }),
							map(result => result.map(() => new EmptyResultOfEmptyCommand(), mapFailureToFailureModel))
						);
				},
				failure => of(Result.failure<EmptyResultOfEmptyCommand, FailureModel>(failure))
			);

	}

	private updateStorageInfoViaResult(result: Result<LoginDto, Failure>): void {
		result
			.mapOnSuccess(mapLoginDtoToModel)
			.bindOnSuccess(success => { updateSubscriptionsAfterLogin(success, this.storage, this.userConfigurationStorage); });
	}

	private updateStorageInfoWithRefreshedToken(result: Result<TokenInfoDto, Failure>): void {
		result
			.mapOnSuccess(mapTokenInfoDtoToModel)
			.bind(
				result => this.storage.defineTokenInfo(result),
				(error) => {
					this.storage.logout();
					this.failureHandlingService.handleFailure(FailureModel.createLocaliableFailure(FailureWrapperModel.createFromValue(error.value), FailureLocalizationModel.createFromValue(`${this.localizationKeyPattern}.failureHints.sessionExpired`)));
				}
			);
	}

}
