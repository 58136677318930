import { Injectable } from '@angular/core';
import { CurrentUserConfigurationClient } from '@nmn-communication/accounts';
import { Result } from '@nmn-core/shared';
import { CurrentUserConfigurationCommandHandlerService, CurrentUserConfigurationUpdateCommand } from '@nmn-domain/accounts';
import { EmptyCommandResult, FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../../../../domain/implementations/shared/factories/failure-handling.factory';
import { mapUpdateCommandToParameter } from '../factories/user-configuration.factory';

@Injectable()
export class CurrentUserConfigurationCommandHandlerViaClientService extends CurrentUserConfigurationCommandHandlerService {

	constructor(
		private readonly client: CurrentUserConfigurationClient
	) {
		super();
	}

	public update(
		command: CurrentUserConfigurationUpdateCommand
	): Observable<Result<EmptyCommandResult<CurrentUserConfigurationUpdateCommand>, FailureModel>> {
		return this.client
			.update(mapUpdateCommandToParameter(command))
			.pipe(map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command))));
	}

}
