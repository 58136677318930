import { isValueDefined } from '@nmn-core/utils';

export enum TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType {

	Undefined = 'undefined',

	TakeWithWater = 'takeWithWater',
	TakeWithMeal = 'takeWithMeal',
	TakeBeforeMeal = 'takeBeforeMeal',
	TakeAfterMeal = 'takeAfterMeal',
	TakeWithoutFood = 'takeWithoutFood',
	TakeOnEmptyStomach = 'takeOnEmptyStomach',
	TakeSeparate = 'takeSeparate',
	AvoidAlcohol = 'avoidAlcohol'

}

export const mapTreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeTypeFromString = (
	stringValue: string
): TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType => {
	const enumValue = Object
		.keys(TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType)
		.find(x => TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType[x] === stringValue);

	return isValueDefined(enumValue) ?
		stringValue as TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType :
		TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.Undefined;
};
