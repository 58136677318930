import { GeneticFileParseRequestStatus } from '../genetic-file-parse-request-status.enum';

export class GeneticFileParseRequestGetStateQuery {

	public patientId: string;
	public isActive: boolean;
	public status: GeneticFileParseRequestStatus | undefined;

	private constructor(
		patientId: string,
		isActive: boolean,
		status: GeneticFileParseRequestStatus | undefined
	) {

		// TODO: Guard add defined check

		this.patientId = patientId;
		this.isActive = isActive;
		this.status = status;
	}

	public static createForCheckState(
		patientId: string
	): GeneticFileParseRequestGetStateQuery {
		return new GeneticFileParseRequestGetStateQuery(
			patientId,
			true,
			undefined);
	}

}
