import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginDto, UserSubscriptionClient, UserSubscriptionUpdateParameter } from '@nmn-communication/accounts';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { createFailedResultFromErrorResponse, createResultFromResponse } from '../../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { UserSubscriptionHttpClientConfiguration } from '../configurations/user-subscription.http-client.configuration';

export class UserSubscriptionHttpClient extends UserSubscriptionClient {

	constructor(
		private readonly configuration: UserSubscriptionHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public get(): Observable<Result<LoginDto, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<LoginDto>(url, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public update(
		parameter: UserSubscriptionUpdateParameter
	): Observable<Result<LoginDto, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`;
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion)

		return this.httpClient
			.post<LoginDto>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

}
