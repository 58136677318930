import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DocumentModel } from '../models/document.model';

export abstract class DocumentResolver implements Resolve<DocumentModel> {

	public abstract resolve(
		route: ActivatedRouteSnapshot,
		_: RouterStateSnapshot
	): Observable<DocumentModel> | Promise<DocumentModel> | DocumentModel;

}
