import { TranslocoService } from '@ngneat/transloco';

export const tryTranslate = (
	translationService: TranslocoService,
	key: string,
	defaultKey: string,
	params?: any
): string => {
	// TODO: need to avoid error in console 'missing translation'
	const translatedValue = translationService.translate(key, params);

	return translatedValue === key ? translationService.translate(defaultKey) : translatedValue;
};

export const getFailureLocalizationKey = (
	localizatioPattern: string,
	key: string
) => `${localizatioPattern}.failureHints.${key}`;

export const getSuccessLocalizationKey = (
	localizatioPattern: string,
	key: string
) => `${localizatioPattern}.successHints.${key}`;
