import { TranslocoService } from '@ngneat/transloco';
import { CompleteResetPasswordParameter } from '@nmn-communication/accounts';
import { Guid, isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../databases/fake.database';

export class ResetPasswordTokenFakeTable {

	private readonly database: FakeDatabase;
	private readonly data: Array<ResetPasswordTokenRecord>;

	constructor(
		database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.database = database;
		this.data = [...initialData];
	}

	public create(userId: string): void {
		const record = {
			userId,
			token: Guid.newGuid(),
			isUsed: false
		};
		this.data.push(record);

		console.log('Confirm e-mail (fake) send.', { userId: record.userId, token: record.token });
	}

	public use(parameter: CompleteResetPasswordParameter): void {
		const record = this.data.find((item: ResetPasswordTokenRecord) => findPredicate(item, parameter));

		if (!isValueDefined(record)) {
			throw new Error('Provided token is not valid.');
		}

		if (record!.isUsed) {
			throw new Error('Provided token is already used.');
		}

		record!.isUsed = true;
	}

}

const findPredicate = (item: ResetPasswordTokenRecord, findParameter: CompleteResetPasswordParameter): boolean =>
	item.userId === findParameter.userId &&
	item.token === findParameter.token;

interface ResetPasswordTokenRecord {
	userId: string;
	token: string;
	isUsed: boolean;
}

// ResetPasswordTokenRecord (initial data) has token mask 00000000-0000-0001-0002-************
const initialData: Array<ResetPasswordTokenRecord> = [
	{
		userId: '00000000-0000-0000-0001-000000000001',
		token: '00000000-0000-0001-0002-000000000001',
		isUsed: true
	},
	{
		userId: '00000000-0000-0000-0001-000000000001',
		token: '00000000-0000-0001-0002-000000000002',
		isUsed: false
	},
	{
		userId: '00000000-0000-0000-0001-000000000002',
		token: '00000000-0000-0001-0002-000000000003',
		isUsed: true
	},
	{
		userId: '00000000-0000-0000-0001-000000000002',
		token: '00000000-0000-0001-0002-000000000004',
		isUsed: false
	}
];
