export class Result<TSuccess, TFailure> {

	private readonly isSuccess: boolean;
	private readonly success: TSuccess | undefined;
	private readonly failure: TFailure | undefined;

	private constructor(
		isSuccess: boolean,
		success: TSuccess,
		failure: TFailure
	) {
		this.isSuccess = !!isSuccess;
		this.success = this.isSuccess ? success : undefined;
		this.failure = this.isSuccess ? undefined : failure;
	}

	public static success<TSuccess, TFailure>(success: TSuccess): Result<TSuccess, TFailure> {
		return new Result<TSuccess, TFailure>(true, success, undefined);
	}

	public static failure<TSuccess, TFailure>(failure: TFailure): Result<TSuccess, TFailure> {
		return new Result<TSuccess, TFailure>(false, undefined, failure);
	}

	public mapOnSuccess<TSuccessTo>(mapSuccess: (success: TSuccess) => TSuccessTo): Result<TSuccessTo, TFailure> {
		return this.map(mapSuccess, (failure: TFailure) => failure);
	}

	public mapOnFailure<TFailureTo>(mapFailure: (failure: TFailure) => TFailureTo): Result<TSuccess, TFailureTo> {
		return this.map((success: TSuccess) => success, mapFailure);
	}

	public map<TSuccessTo, TFailureTo>(
		mapSuccess: (success: TSuccess) => TSuccessTo,
		mapFailure: (failure: TFailure) => TFailureTo
	): Result<TSuccessTo, TFailureTo> {
		return this.isSuccess ?
			Result.success<TSuccessTo, TFailureTo>(mapSuccess(this.success)) :
			Result.failure<TSuccessTo, TFailureTo>(mapFailure(this.failure));
	}

	public consume<TConsumeResult>(
		consumeSuccess: (success: TSuccess) => TConsumeResult,
		consumeFailure: (failure: TFailure) => TConsumeResult
	): TConsumeResult {
		return this.isSuccess ?
			consumeSuccess(this.success) :
			consumeFailure(this.failure);
	}

	public successOrDefault(defaultSuccessFactory: () => TSuccess): TSuccess {
		return this.consume((success: TSuccess) => success, defaultSuccessFactory);
	}

	public successOrUndefined(): TSuccess | undefined {
		return this.successOrDefault(() => undefined);
	}

	public bindOnSuccess(
		handleSuccess: (success: TSuccess) => void,
		handleError: (error: any) => void = () => { }
	): Result<TSuccess, TFailure> {
		return this.bind(handleSuccess, () => { }, handleError);
	}

	public bindOnFailure(
		handleFailure: (failure: TFailure) => void,
		handleError: (error: any) => void = () => { }
	): Result<TSuccess, TFailure> {
		return this.bind(() => { }, handleFailure, handleError);
	}

	public bind(
		handleSuccess: (success: TSuccess) => void,
		handleFailure: (failure: TFailure) => void,
		handleError: (error: any) => void = () => { }
	): Result<TSuccess, TFailure> {
		try {
			this.isSuccess ? handleSuccess(this.success) : handleFailure(this.failure);
		} catch (error) {
			handleError(error);
		}

		return this;
	}

	public combine<TS, TF, TCombinedSuccess, TCombinedFailure>(
		result: Result<TS, TF>,
		buildSuccess: (a: TSuccess, b: TS) => TCombinedSuccess,
		buildFailure: (a: TFailure | undefined, b: TF | undefined) => TCombinedFailure
	): Result<TCombinedSuccess, TCombinedFailure> {
		return this.consume(
			(success: TSuccess) => result.map((s: TS) => buildSuccess(success, s), (failure: TF) => buildFailure(undefined, failure)),
			(failure: TFailure) => result.consume(_ => Result.failure(buildFailure(failure, undefined)), (f: TF) => Result.failure(buildFailure(failure, f)))
		);
	}

}
