import { InformationBlockModel } from '../../../../../modules/core/models/shared/information-block.model';
import { DescribedComboboxModel } from '../../../shared/comboboxes/described-combobox.model';

export class MedicalCardInformationBlockModel extends InformationBlockModel {

	public readonly firstRecordDate?: Date;
	public readonly lastModificationDate?: Date;
	public readonly medicationsCount: number;
	public readonly lastHealthIssue?: DescribedComboboxModel;
	public readonly lastEncounter?: DescribedComboboxModel;
	public readonly healthIssuesCount: number;
	public readonly doctorEncountersCount: number;

	constructor(
		fillingUpPercentage: number,
		firstRecordDate: Date | undefined,
		lastModificationDate: Date | undefined,
		medicationsCount: number,
		lastHealthIssue: DescribedComboboxModel | undefined,
		lastEncounter: DescribedComboboxModel | undefined,
		healthIssuesCount: number,
		doctorEncountersCount: number
	) {

		super(fillingUpPercentage);

		// TODO: add Guard check

		this.firstRecordDate = firstRecordDate;
		this.lastModificationDate = lastModificationDate;
		this.medicationsCount = medicationsCount;
		this.lastHealthIssue = lastHealthIssue;
		this.lastEncounter = lastEncounter;
		this.healthIssuesCount = healthIssuesCount;
		this.doctorEncountersCount = doctorEncountersCount;

	}

}
