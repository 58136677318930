import { UserConfigurationDto, UserConfigurationGetParameter, UserConfigurationUpdateParameter } from '@nmn-communication/accounts';
import { CurrentUserConfigurationGetCommand } from '@nmn-domain/accounts/user-configuration/models/commands/current-user-configuration.get-command';
import { CurrentUserConfigurationUpdateCommand } from '@nmn-domain/accounts/user-configuration/models/commands/current-user-configuration.update-command';
import { UserConfigurationModel } from '@nmn-domain/accounts/user-configuration/models/user-configuration.model';

export const mapUserConfigurationDtoToModel = (dto: UserConfigurationDto): UserConfigurationModel => {
	try {
		const parsedValue: UserConfigurationModel = JSON.parse(dto.settings);

		return UserConfigurationModel.createWithParameters(parsedValue);
	} catch (ex) {
		console.error('Failed user config mapping', ex);

		return UserConfigurationModel.createDefault();
	}
};

export const mapUpdateCommandToParameter = (
	command: CurrentUserConfigurationUpdateCommand
): UserConfigurationUpdateParameter => (
	{
		settings: command.settings
	}
);

export const mapGetCommandToParameter = (
	command: CurrentUserConfigurationGetCommand
): UserConfigurationGetParameter => (
	{
		patientAlias: command.patientAlias
	}
);
