export class TakenMedicationFormFailure {

	public readonly isValidationFailure: boolean;

	private constructor(
		isValidationFailure: boolean
	) {

		this.isValidationFailure = isValidationFailure;

	}

	public static createAsValidationFailure(): TakenMedicationFormFailure {

		return new TakenMedicationFormFailure(true);

	}

}
