import { isValueDefined } from '@nmn-core/utils';
import { AccountsPageConfiguration } from './page-configurations/accounts-page.configuration';
import { DoctorEncountersPageConfiguration } from './page-configurations/doctor-encounters-page.configuration';
import { DocumentsPageConfiguration } from './page-configurations/documents-page.configuration';
import { EmergencyCardPageConfiguration } from './page-configurations/emergency-card-page.configuration';
import { EncountersPageConfiguration } from './page-configurations/encounters-page.configuration';
import { GeneticCardPageConfiguration } from './page-configurations/genetic-card-page.configuration';
import { GeneticTestsPageConfiguration } from './page-configurations/genetic-tests-page.configuration';
import { HealthIssuesPageConfiguration } from './page-configurations/health-issues-page.configuration';
import { TakenMedicationsPageConfiguration } from './page-configurations/taken-medications-page.configuration';
import { TreatmentCheckerPageConfiguration } from './page-configurations/treatment-checker-page.configuration';
import { VaccinationCardPageConfiguration } from './page-configurations/vaccination-card-page.configuration';
import { SpecificUserConfiguration } from './specific/specific-user.configuration';

export class UserConfigurationModel {

	public readonly specificUserConfiguration: SpecificUserConfiguration;

	public readonly accountsPage: AccountsPageConfiguration;
	public readonly doctorEncountersPage: DoctorEncountersPageConfiguration;
	public readonly documentsPage: DocumentsPageConfiguration;
	public readonly emergencyCardPage: EmergencyCardPageConfiguration;
	public readonly encountersPage: EncountersPageConfiguration;
	public readonly geneticCardPage: GeneticCardPageConfiguration;
	public readonly geneticTestsPage: GeneticTestsPageConfiguration;
	public readonly healthIssuesPage: HealthIssuesPageConfiguration;
	public readonly takenMedicationsPage: TakenMedicationsPageConfiguration;
	public readonly treatmentCheckerPage: TreatmentCheckerPageConfiguration;
	public readonly vaccinationCardPage: VaccinationCardPageConfiguration;

	protected constructor(
		specificUserConfiguration: SpecificUserConfiguration,
		accountsPage: AccountsPageConfiguration,
		doctorEncountersPage: DoctorEncountersPageConfiguration,
		documentsPage: DocumentsPageConfiguration,
		emergencyCardPage: EmergencyCardPageConfiguration,
		encountersPage: EncountersPageConfiguration,
		geneticCardPage: GeneticCardPageConfiguration,
		geneticTestsPage: GeneticTestsPageConfiguration,
		healthIssuesPage: HealthIssuesPageConfiguration,
		takenMedicationsPage: TakenMedicationsPageConfiguration,
		treatmentCheckerPage: TreatmentCheckerPageConfiguration,
		vaccinationCardPage: VaccinationCardPageConfiguration
	) {

		// TODO: Guard check on defined
		this.specificUserConfiguration = specificUserConfiguration;
		this.accountsPage = accountsPage;
		this.doctorEncountersPage = doctorEncountersPage;
		this.documentsPage = documentsPage;
		this.emergencyCardPage = emergencyCardPage;
		this.encountersPage = encountersPage;
		this.geneticCardPage = geneticCardPage;
		this.geneticTestsPage = geneticTestsPage;
		this.healthIssuesPage = healthIssuesPage;
		this.takenMedicationsPage = takenMedicationsPage;
		this.treatmentCheckerPage = treatmentCheckerPage;
		this.vaccinationCardPage = vaccinationCardPage;
	}

	public static createWithParameters(
		config?: UserConfigurationModel
	): UserConfigurationModel {

		if (!isValueDefined(config)) {
			return UserConfigurationModel.createDefault();
		}

		return new UserConfigurationModel(
			isValueDefined(config.specificUserConfiguration) ?
				SpecificUserConfiguration.createWithParameters(config.specificUserConfiguration) :
				SpecificUserConfiguration.createDefault(),
			isValueDefined(config.accountsPage) ?
				AccountsPageConfiguration.createWithParameters(config.accountsPage) :
				AccountsPageConfiguration.createDefault(),
			isValueDefined(config.doctorEncountersPage) ?
				DoctorEncountersPageConfiguration.createWithParameters(config.doctorEncountersPage) :
				DoctorEncountersPageConfiguration.createDefault(),
			isValueDefined(config.documentsPage) ?
				DocumentsPageConfiguration.createWithParameters(config.documentsPage) :
				DocumentsPageConfiguration.createDefault(),
			isValueDefined(config.emergencyCardPage) ?
				EmergencyCardPageConfiguration.createWithParameters(config.emergencyCardPage) :
				EmergencyCardPageConfiguration.createDefault(),
			isValueDefined(config.encountersPage) ?
				EncountersPageConfiguration.createWithParameters(config.encountersPage) :
				EncountersPageConfiguration.createDefault(),
			isValueDefined(config.geneticCardPage) ?
				GeneticCardPageConfiguration.createWithParameters(config.geneticCardPage) :
				GeneticCardPageConfiguration.createDefault(),
			isValueDefined(config.geneticTestsPage) ?
				GeneticTestsPageConfiguration.createWithParameters(config.geneticTestsPage) :
				GeneticTestsPageConfiguration.createDefault(),
			isValueDefined(config.healthIssuesPage) ?
				HealthIssuesPageConfiguration.createWithParameters(config.healthIssuesPage) :
				HealthIssuesPageConfiguration.createDefault(),
			isValueDefined(config.takenMedicationsPage) ?
				TakenMedicationsPageConfiguration.createWithParameters(config.takenMedicationsPage) :
				TakenMedicationsPageConfiguration.createDefault(),
			isValueDefined(config.treatmentCheckerPage) ?
				TreatmentCheckerPageConfiguration.createWithParameters(config.treatmentCheckerPage) :
				TreatmentCheckerPageConfiguration.createDefault(),
			isValueDefined(config.vaccinationCardPage) ?
				VaccinationCardPageConfiguration.createWithParameters(config.vaccinationCardPage) :
				VaccinationCardPageConfiguration.createDefault()
		);
	}

	public static createDefault(): UserConfigurationModel {
		return new UserConfigurationModel(
			SpecificUserConfiguration.createDefault(),
			AccountsPageConfiguration.createDefault(),
			DoctorEncountersPageConfiguration.createDefault(),
			DocumentsPageConfiguration.createDefault(),
			EmergencyCardPageConfiguration.createDefault(),
			EncountersPageConfiguration.createDefault(),
			GeneticCardPageConfiguration.createDefault(),
			GeneticTestsPageConfiguration.createDefault(),
			HealthIssuesPageConfiguration.createDefault(),
			TakenMedicationsPageConfiguration.createDefault(),
			TreatmentCheckerPageConfiguration.createDefault(),
			VaccinationCardPageConfiguration.createDefault()
		);
	}

}
