import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FailureModel } from '../../shared/failures/failure.model';
import { GeneComboboxModel } from '../models/gene-combobox.model';
import { GeneFilterModel } from '../models/gene-filter.model';

export abstract class GeneQueryHandlerService {

	public abstract getAsComboboxes(query: GeneFilterModel): Observable<Result<Array<GeneComboboxModel>, FailureModel>>;

}
