import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LanguageFilterModel, LanguageQueryHandlerService, LanguagesResolver } from '@nmn-domain/languages';
import { ComboboxModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LanguagesViaQueryHandlerResolver extends LanguagesResolver {

	constructor(
		private readonly languageQueryHandlerService: LanguageQueryHandlerService
	) {
		super();
	}

	public resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<Array<ComboboxModel>> | Array<ComboboxModel> {
		return this.languageQueryHandlerService
			.getAsComboboxes(LanguageFilterModel.createEmpty())
			.pipe(map(result => result.successOrDefault(() => [])));
	}

}
