import { isStringDefinedAndNotEmpty } from './string.utils';

export const stringToHexColorSurjection = (
	str: string
): number => {
	let hash = 0;
	if (isStringDefinedAndNotEmpty(str)) {
		for (let index = 0; index < str.length; index++) {
			// eslint-disable-next-line no-bitwise
			const hashCode = ((hash << 5) - hash);
			// eslint-disable-next-line @typescript-eslint/restrict-plus-operands
			hash = str.charCodeAt(index) + hashCode;
		}
	}

	return hash % 360;
};

export const stringToHslColorSurjection = (
	str: string,
	saturation: number,
	lightness: number
): string => {
	return `hsl(${stringToHexColorSurjection(str)}, ${saturation}%, ${lightness}%)`;
};

export const lightnessToOppositeColorSurjection = (
	lightness: number
): string => {
	return lightness > 50 ? '#555' : '#fff';
};
