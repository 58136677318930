import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { DescribedComboboxDto } from '../../shared';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { MedicationDosageTypeFilterDto } from '../models/medication-dosage-type-filter.dto';

export abstract class MedicationDosageTypeClient {

	public abstract getBulkAsComboboxes(
		filter: MedicationDosageTypeFilterDto
	): Observable<Result<Array<DescribedComboboxDto<string>>, Failure>>;

}
