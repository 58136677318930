import { Injector } from '@angular/core';
import { ResetPasswordClient } from '@nmn-communication/accounts';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { ResetPasswordFakeClientBuilder } from '../builders/reset-password.fake-client.builder';
import { ResetPasswordFakeClientConfigurationBuilder } from '../builders/reset-password.fake-client.configuration.builder';
import { ResetPasswordHttpClientBuilder } from '../builders/reset-password.http-client.builder';
import { ResetPasswordHttpClientConfigurationBuilder } from '../builders/reset-password.http-client.configuration.builder';
import { ResetPasswordFakeClient } from '../services/reset-password.fake-client';
import { ResetPasswordHttpClient } from '../services/reset-password.http-client';

export const resetPasswordClientFactory = (
	injector: Injector
): ResetPasswordClient => {
	const useFake = environment?.api?.resources?.accounts?.resetPassword?.useFake ||
		!isValueDefined(environment?.api?.resources?.accounts?.resetPassword?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		resetPasswordFakeClientFactory(injector) :
		resetPasswordHttpClientFactory(injector);
};

export const resetPasswordFakeClientFactory = (
	injector: Injector
): ResetPasswordFakeClient => {
	const configuration = new ResetPasswordFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.accounts.resetPassword.serverDelayMs)
		.build();

	return new ResetPasswordFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const resetPasswordHttpClientFactory = (
	injector: Injector
): ResetPasswordHttpClient => {
	const configuration = new ResetPasswordHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.accounts?.resetPassword?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.accounts.resetPassword.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.accounts?.resetPassword?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new ResetPasswordHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
