import { DoctorEncounterComboboxModel, DoctorEncounterFormOutModel } from '../../../doctor-encounters';
import { DocumentDescribedComboboxModel, DocumentFormOutModel } from '../../../documents';
import { HealthComplaintComboboxModel } from '../../../health-complaints';
import { HealthIssueSeverityComboboxModel } from '../../../health-issue-severities';
import { TakenMedicationDescribedComboboxModel, TakenMedicationFormOutModel } from '../../../taken-medications';
import { HealthIssueStatus } from '../health-issue-status.enum';
import { HealthIssueModel } from '../health-issue.model';

export class HealthIssueFormInModel {

	public readonly patientId: string;

	public readonly name?: string;
	public readonly comment?: string;
	public readonly dateStart?: Date;
	public readonly dateEnd?: Date;
	public readonly status?: HealthIssueStatus;
	public readonly severity?: HealthIssueSeverityComboboxModel;
	public readonly healthComplaints: Array<HealthComplaintComboboxModel>;
	public readonly doctorEncounters: Array<DoctorEncounterComboboxModel>;
	public readonly newDoctorEncounters: Array<DoctorEncounterFormOutModel>;
	public readonly takenMedications: Array<TakenMedicationDescribedComboboxModel>;
	public readonly newTakenMedications: Array<TakenMedicationFormOutModel>;
	public readonly documents: Array<DocumentDescribedComboboxModel>;
	public readonly newDocuments: Array<DocumentFormOutModel>;

	constructor(
		patientId: string,
		name: string | undefined,
		comment: string | undefined,
		dateStart: Date | undefined,
		dateEnd: Date | undefined,
		status: HealthIssueStatus | undefined,
		severity: HealthIssueSeverityComboboxModel | undefined,
		healthComplaints: Array<HealthComplaintComboboxModel>,
		doctorEncounters: Array<DoctorEncounterComboboxModel>,
		newDoctorEncounters: Array<DoctorEncounterFormOutModel>,
		takenMedications: Array<TakenMedicationDescribedComboboxModel>,
		newTakenMedications: Array<TakenMedicationFormOutModel>,
		documents: Array<DocumentDescribedComboboxModel>,
		newDocuments: Array<DocumentFormOutModel>
	) {
		// TODO: Guard check on defined
		this.patientId = patientId;
		this.name = name;
		this.comment = comment;
		this.dateStart = dateStart;
		this.dateEnd = dateEnd;
		this.status = status;
		this.severity = severity;
		this.healthComplaints = healthComplaints;
		this.doctorEncounters = doctorEncounters;
		this.newDoctorEncounters = newDoctorEncounters;
		this.takenMedications = takenMedications;
		this.newTakenMedications = newTakenMedications;
		this.documents = documents;
		this.newDocuments = newDocuments;
	}

	public static createEmpty = (patientId: string): HealthIssueFormInModel => {
		return new HealthIssueFormInModel(
			patientId,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			[],
			[],
			[],
			[],
			[],
			[],
			[]
		);
	}

	public static createFromExistingModel(model: HealthIssueModel): HealthIssueFormInModel {
		return new HealthIssueFormInModel(
			model.patientId,
			model.name,
			model.comment,
			model.dateStart,
			model.dateStart,
			model.status,
			model.severity,
			model.healthComplaints,
			model.doctorEncounters,
			[],
			model.takenMedications,
			[],
			model.documents,
			[]
		);
	}

}
