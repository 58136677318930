import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FacebookAuthService } from './services/facebook-auth.service';

@NgModule({
	providers: [
		FacebookAuthService
	],
	imports: [
		CommonModule
	]
})
export class FacebookAuthModule { }
