import { GeneticTestCreationTypeEnum } from './genetic-test-creation-type.enum';

export class GeneticTestFilterModel {

	public readonly ids?: Array<string>;
	public readonly ignoreIds?: Array<string>;
	public readonly patientId?: string;
	public readonly searchPattern?: string;
	public readonly geneticTestCreationTypes?: Array<GeneticTestCreationTypeEnum>;
	public readonly ignoreGeneticTestCreationTypes?: Array<GeneticTestCreationTypeEnum>;

	constructor(
		ids: Array<string> | undefined,
		ignoreIds: Array<string> | undefined,
		patientId: string | undefined,
		searchPattern: string | undefined,
		geneticTestCreationTypes: Array<GeneticTestCreationTypeEnum> | undefined,
		ignoreGeneticTestCreationTypes: Array<GeneticTestCreationTypeEnum> | undefined
	) {
		this.ids = ids;
		this.ignoreIds = ignoreIds;
		this.patientId = patientId;
		this.searchPattern = searchPattern;
		this.geneticTestCreationTypes = geneticTestCreationTypes;
		this.ignoreGeneticTestCreationTypes = ignoreGeneticTestCreationTypes;
	}

	public static createForDocumentsAutocomplete(searchPattern: string, ignoreIds: Array<string>): GeneticTestFilterModel {
		return new GeneticTestFilterModel(
			undefined,
			ignoreIds,
			undefined,
			searchPattern,
			undefined,
			undefined
		);
	}

}
