import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { ComboboxModel, FailureModel } from '../../shared';
import { LanguageFilterModel } from '../models/language-filter.model';

export abstract class LanguageQueryHandlerService {

	public abstract getAsComboboxes(query: LanguageFilterModel): Observable<Result<Array<ComboboxModel>, FailureModel>>;

}
