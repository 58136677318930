import { TakenMedicationComboboxDto } from '@nmn-communication/taken-medications';
import { mapDateInServerFormatToDate } from '@nmn-core/utils';
import { mapMedicationTypeFromString } from '@nmn-domain/medications';
import { TakenMedicationDescribedComboboxModel } from '@nmn-domain/taken-medications';

export const mapTakenMedicationComboboxDtoToModel = (dto: TakenMedicationComboboxDto): TakenMedicationDescribedComboboxModel => (
	new TakenMedicationDescribedComboboxModel(
		dto.id,
		dto.displayText,
		dto.description,
		mapDateInServerFormatToDate(dto.from),
		mapDateInServerFormatToDate(dto.to),
		mapMedicationTypeFromString(dto.medicationType)
	)
);
