import { Injector } from '@angular/core';
import { ContactsClient } from '@nmn-communication/contacts';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { ContactsFakeClientBuilder } from '../builders/contacts.fake-client.builder';
import { ContactsFakeClientConfigurationBuilder } from '../builders/contacts.fake-client.configuration.builder';
import { ContactsHttpClientBuilder } from '../builders/contacts.http-client.builder';
import { ContactsHttpClientConfigurationBuilder } from '../builders/contacts.http-client.configuration.builder';
import { ContactsFakeClient } from '../services/contacts.fake-client';
import { ContactsHttpClient } from '../services/contacts.http-client';

export const contactsClientFactory = (injector: Injector): ContactsClient => {
	const useFake = environment?.api?.resources?.contacts?.useFake ||
		!isValueDefined(environment?.api?.resources?.contacts?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		contactsFakeClientFactory(injector) :
		contactsHttpClientFactory(injector);
};

export const contactsFakeClientFactory = (
	injector: Injector
): ContactsFakeClient => {
	const configuration = new ContactsFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.contacts.serverDelayMs)
		.build();

	return new ContactsFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const contactsHttpClientFactory = (
	injector: Injector
): ContactsHttpClient => {
	const configuration = new ContactsHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.contacts?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.contacts.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.contacts?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new ContactsHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
