import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { TypeOfMedicationHttpClientConfiguration } from '../configurations/type-of-medication.http-client.configuration';
import { TypeOfMedicationHttpClient } from '../services/type-of-medication.http-client';

export class TypeOfMedicationHttpClientBuilder
	extends HttpClientBuilder<TypeOfMedicationHttpClient, TypeOfMedicationHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): TypeOfMedicationHttpClient {
		return new TypeOfMedicationHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
