import { Failure, PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import {
	TakenMedicationClient, TakenMedicationComboboxDto, TakenMedicationCreateParameterDto, TakenMedicationDeleteBulkParameterDto,
	TakenMedicationDto, TakenMedicationFailCourseParameterDto, TakenMedicationFilterDto, TakenMedicationFindParameterDto,
	TakenMedicationFinishCourseBulkParameterDto, TakenMedicationUpdateBulkParameterDto, TakenMedicationUpdateParameterDto
} from '@nmn-communication/taken-medications';
import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { wrapActionCallAsFakeClientResponse, wrapFuncCallAsFakeClientResponse } from '../../../../modules/fakes/databases/fake.utils';
import { TakenMedicationFakeClientConfiguration } from '../configurations/taken-medication-fake-client.configuration';

export class TakenMedicationFakeClient extends TakenMedicationClient {

	constructor(
		private readonly configuration: TakenMedicationFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulkAsComboboxes(
		filter: TakenMedicationFilterDto
	): Observable<Result<Array<TakenMedicationComboboxDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.takenMedicationsTable.getComboboxesPagedCollection(filter),
			this.configuration.serverDelayMs
		);
	}

	public getBulk(
		parameter: PageOptionsDto<TakenMedicationFilterDto>
	): Observable<Result<PagedCollectionDto<TakenMedicationDto, TakenMedicationFilterDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.takenMedicationsTable.getPagedCollection(parameter),
			this.configuration.serverDelayMs
		);
	}

	public get(parameter: TakenMedicationFindParameterDto): Observable<Result<TakenMedicationDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.takenMedicationsTable.find(parameter),
			this.configuration.serverDelayMs
		);
	}

	public create(parameter: TakenMedicationCreateParameterDto): Observable<Result<string, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.takenMedicationsTable.create(parameter)
		);
	}

	public update(
		findParameter: TakenMedicationFindParameterDto,
		updateParameter: TakenMedicationUpdateParameterDto
	): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.takenMedicationsTable.update(findParameter, updateParameter);
			},
			this.configuration.serverDelayMs
		);
	}

	public updateBulk(
		parameter: TakenMedicationUpdateBulkParameterDto
	): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				for (const candidate of parameter.candidates) {
					this.fakeDatabase.takenMedicationsTable
						.update(
							{ id: candidate, patientId: parameter.patientId },
							undefined);
				}
			},
			this.configuration.serverDelayMs
		);
	}

	public delete(
		parameter: TakenMedicationFindParameterDto
	): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.takenMedicationsTable.delete(parameter);
			},
			this.configuration.serverDelayMs
		);
	}

	public deleteBulk(
		parameter: TakenMedicationDeleteBulkParameterDto
	): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				for (const id of parameter.ids) {
					this.fakeDatabase.takenMedicationsTable.delete({ id, patientId: parameter.patientId });
				}
			},
			this.configuration.serverDelayMs
		);
	}

	public finishCourse(
		findParameter: TakenMedicationFindParameterDto
	): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.takenMedicationsTable.finishCourse(findParameter);
			},
			this.configuration.serverDelayMs
		);
	}

	public finishCourceBulk(
		parameter: TakenMedicationFinishCourseBulkParameterDto
	): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.takenMedicationsTable.finishCourseBulk(parameter);
			},
			this.configuration.serverDelayMs
		);
	}

	public failCourse(
		parameter: TakenMedicationFailCourseParameterDto
	): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.takenMedicationsTable.failCourse(parameter);
			},
			this.configuration.serverDelayMs
		);
	}

}
