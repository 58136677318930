import { ClinicDto } from '@nmn-communication/clinics';
import { ClinicModel } from '@nmn-domain/clinics';

export const mapDtoToModel = (dto: ClinicDto): ClinicModel => (
	new ClinicModel(
		dto.name,
		dto.contacts,
		dto.address
	)
);

export const mapModelToDto = (model: ClinicModel): ClinicDto => (
	{
		name: model.name,
		contacts: model.contacts,
		address: model.address
	}
);
