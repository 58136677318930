import { isValueDefined } from '@nmn-core/utils';
import { TableColumnConfiguration } from '../../../../../../modules/core/utils/tables/table-column.configuration';

export class NmnMatTableUserConfiguration {

	public pageIndex: number;
	public pageSize: number;
	public pageSizeOptions: Array<number>;
	public sortingDirection: 'asc' | 'desc' | '';
	public sortingColumn?: string;

	constructor(
		pageIndex: number,
		pageSize: number,
		pageSizeOptions: Array<number>,
		sortingDirection: 'asc' | 'desc' | '',
		sortingColumn?: string
	) {
		this.pageIndex = pageIndex;
		this.pageSize = pageSize;
		this.pageSizeOptions = pageSizeOptions;
		this.sortingDirection = sortingDirection;
		this.sortingColumn = sortingColumn;
	}

	public static createWithParameters(
		config: NmnMatTableUserConfiguration
	): NmnMatTableUserConfiguration {
		if (!isValueDefined(config)) {
			return new NmnMatTableUserConfiguration(
				userConfigurationpageIndexDefault,
				userConfigurationPageSizeDefault,
				userConfigurationPageSizeOptionsDefault,
				userConfigurationSortingDirectionDefault,
				userConfigurationSortingColumnDefault
			);
		}

		return new NmnMatTableUserConfiguration(
			isValueDefined(config?.pageIndex) ?
				config.pageIndex :
				userConfigurationpageIndexDefault,
			isValueDefined(config?.pageSize) ?
				config.pageSize :
				userConfigurationPageSizeDefault,
			isValueDefined(config?.pageSizeOptions) ?
				config.pageSizeOptions :
				userConfigurationPageSizeOptionsDefault,
			isValueDefined(config?.sortingDirection) ?
				config.sortingDirection :
				userConfigurationSortingDirectionDefault,
			isValueDefined(config?.sortingColumn) ?
				config.sortingColumn :
				userConfigurationSortingColumnDefault
		);
	}

	public static createDefault(): NmnMatTableUserConfiguration {
		return new NmnMatTableUserConfiguration(
			userConfigurationpageIndexDefault,
			userConfigurationPageSizeDefault,
			userConfigurationPageSizeOptionsDefault,
			userConfigurationSortingDirectionDefault,
			userConfigurationSortingColumnDefault);
	}

	public getValidSortingField(columns: Array<string>): string | undefined {
		if (isValueDefined(columns) && columns.length > 0) {
			const column = isValueDefined(this.sortingColumn) && columns.indexOf(this.sortingColumn!) > -1 ?
				this.sortingColumn! :
				columns[0];

			return column;
		}

		return undefined;
	}

	public getValidSortingFieldByTableConfig(configrations: Array<TableColumnConfiguration>): string | undefined {
		const columns = configrations.map(item => item.def);

		return this.getValidSortingField(columns);
	}

}

export const userConfigurationpageIndexDefault = 1;
export const userConfigurationPageSizeDefault = 25;
export const userConfigurationPageSizeOptionsDefault = [5, 10, 25, 50, 100];
export const userConfigurationSortingDirectionDefault = 'desc';
export const userConfigurationSortingColumnDefault = undefined;
