import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { HealthComplaintComboboxDto } from '../models/health-complaint-combobox.dto';
import { HealthComplaintFilterDto } from '../models/health-complaint-filter.dto';

export abstract class HealthComplaintClient {

	public abstract getBulkAsComboboxes(
		filter: HealthComplaintFilterDto
	): Observable<Result<Array<HealthComplaintComboboxDto>, Failure>>;

}
