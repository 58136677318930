import { Injector } from '@angular/core';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { isValueDefined } from '@nmn-core/utils';
import { MedicationAdmStatusFakeClientConfiguration } from '../configurations/medication-adm-status.fake-client.configuration';
import { MedicationAdmStatusFakeClient } from '../services/medication-adm-status.fake-client';

export class MedicationAdmStatusFakeClientBuilder {

	private configuration: MedicationAdmStatusFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(
		configuration: MedicationAdmStatusFakeClientConfiguration
	): MedicationAdmStatusFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): MedicationAdmStatusFakeClient {
		return new MedicationAdmStatusFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
