import { DoctorEncounterDto, DoctorEncounterFilterDto } from '@nmn-communication/doctor-encounters';
import { PagedCollectionDto } from '@nmn-communication/shared';
import { isValueDefined, mapDateInServerFormatToDate, mapDateInServerFormatToDateSafely } from '@nmn-core/utils';
import { DoctorEncounterFilterModel, DoctorEncounterModel, mapDoctorEncounterStatusFromString } from '@nmn-domain/doctor-encounters';
import { PagedCollectionModel } from '@nmn-domain/shared';
import { mapDtoToModel as mapClinicDtoToModel } from '../../clinics/factories/clinic.factory';
import { mapDiseaseComboboxDtoToModel } from '../../diseases/factories/disease.factory';
import { mapDtoToModel as mapDoctorDtoToModel } from '../../doctors/factories/doctor.factory';
import { mapDocumentDescribedComboboxDtoToModel } from '../../documents/factories/document.factory';
import { mapHealthIssueComboboxDtoToModel } from '../../health-issues/factories/health-issue-combobox.factory';
import { mapPagedCollectionDtoToModel } from '../../shared/factories/pagination.factory';
import { mapTakenMedicationComboboxDtoToModel } from '../../taken-medications/factories/taken-medication-combobox.factory';

export const mapDtoToModel = (dto: DoctorEncounterDto): DoctorEncounterModel => (
	new DoctorEncounterModel(
		dto.id,
		dto.patientId,
		dto.diseases.map(mapDiseaseComboboxDtoToModel),
		dto.comment,
		mapDateInServerFormatToDate(dto.date),
		mapDoctorEncounterStatusFromString(dto.status),
		isValueDefined(dto.doctor) ? mapDoctorDtoToModel(dto.doctor) : undefined,
		isValueDefined(dto.clinic) ? mapClinicDtoToModel(dto.clinic) : undefined,
		isValueDefined(dto.healthIssue) ? mapHealthIssueComboboxDtoToModel(dto.healthIssue) : undefined,
		dto.takenMedications.map(mapTakenMedicationComboboxDtoToModel),
		dto.documents.map(mapDocumentDescribedComboboxDtoToModel),
		mapDateInServerFormatToDateSafely(dto.createdOn),
		mapDateInServerFormatToDate(dto.updatedOn)
	)
);

export const mapDoctorEncounterPagedCollectionDtoToModel = (
	pagedCollectionDto: PagedCollectionDto<DoctorEncounterDto, DoctorEncounterFilterDto>
): PagedCollectionModel<DoctorEncounterModel, DoctorEncounterFilterModel> =>
	mapPagedCollectionDtoToModel(pagedCollectionDto, mapDtoToModel, mapFilterDtoToModel);

export const mapFilterModelToDto = (model: DoctorEncounterFilterModel): DoctorEncounterFilterDto => (
	{
		patientId: model.patientId,
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern,
		statuses: model.statuses
	}
);

export const mapFilterDtoToModel = (dto: DoctorEncounterFilterDto): DoctorEncounterFilterModel => (
	new DoctorEncounterFilterModel(
		dto.patientId,
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern,
		dto.statuses?.map(mapDoctorEncounterStatusFromString)
	)
);
