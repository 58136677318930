import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../../../../communication/implementation/shared/builders/http-client.builder';
import { RegistrationHttpClientConfiguration } from '../configurations/registration.http-client.configuration';
import { RegistrationHttpClient } from '../services/registration.http-client';

export class RegistrationHttpClientBuilder
	extends HttpClientBuilder<RegistrationHttpClient, RegistrationHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): RegistrationHttpClient {
		return new RegistrationHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
