import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { ComboboxModel } from '@nmn-domain/shared';

export class DiseaseFilterModel {

	public readonly ids?: Array<string>;
	public readonly ignoreIds?: Array<string>;
	public readonly searchPattern?: string;
	public readonly isSeriousPathology?: boolean;
	public readonly hasVaccine?: boolean;
	public readonly isForTreatmentChecker?: boolean;

	constructor(
		ids: Array<string> | undefined,
		ignoreIds: Array<string> | undefined,
		searchPattern: string | undefined,
		isSeriousPathology: boolean | undefined,
		hasVaccine: boolean | undefined,
		isForTreatmentChecker: boolean | undefined
	) {
		this.ids = ids;
		this.ignoreIds = ignoreIds;
		this.searchPattern = searchPattern;
		this.isSeriousPathology = isSeriousPathology;
		this.hasVaccine = hasVaccine;
		this.isForTreatmentChecker = isForTreatmentChecker;
	}

	public static createForPredefinedList(diseaseIds: Array<string>): DiseaseFilterModel {
		return new DiseaseFilterModel(
			diseaseIds,
			undefined,
			undefined,
			undefined,
			undefined,
			false
		);
	}

	public static createForAutocomplete(
		searchPattern: string,
		selectedItem: ComboboxModel
	): DiseaseFilterModel {
		return new DiseaseFilterModel(
			undefined,
			isStringDefinedAndNotEmpty(selectedItem?.id) ? [selectedItem.id] : undefined,
			searchPattern,
			undefined,
			undefined,
			false
		);
	}

	public static createForMultiAutocomplete(
		searchPattern: string,
		ignoreIds: Array<string>
	): DiseaseFilterModel {
		return new DiseaseFilterModel(undefined, ignoreIds, searchPattern, undefined, undefined, false);
	}

	public static createForSeriousPathologyAutocomplete(
		searchPattern: string,
		ignoreIds: Array<string>
	): DiseaseFilterModel {
		return new DiseaseFilterModel(undefined, ignoreIds, searchPattern, true, undefined, false);
	}

	public static createForVaccinationFromDiseaseMultiAutocomplete(
		searchPattern: string,
		ignoreIds: Array<string>
	): DiseaseFilterModel {
		return new DiseaseFilterModel(undefined, ignoreIds, searchPattern, undefined, true, false);
	}

	public static createForTreatmentCheckerMultiAutocomplete(
		searchPattern: string,
		ignoreIds: Array<string>
	): DiseaseFilterModel {
		return new DiseaseFilterModel(undefined, ignoreIds, searchPattern, undefined, undefined, true);
	}

}
