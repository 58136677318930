import { Provider } from '@angular/core';
import { MedicationRateQuantityTypeQueryHandlerService, MedicationRateQuantityTypesResolver } from '@nmn-domain/medication-rate-quantity-types';
import { MedicationRateQuantityTypeQueryHandlerViaClientService, MedicationRateQuantityTypesViaQueryHandlerResolver } from './index';

export const medicationRateQuantityTypeProviders: Array<Provider> = [
	{
		provide: MedicationRateQuantityTypeQueryHandlerService,
		useClass: MedicationRateQuantityTypeQueryHandlerViaClientService
	},
	{
		provide: MedicationRateQuantityTypesResolver,
		useClass: MedicationRateQuantityTypesViaQueryHandlerResolver
	}
];
