import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FailureModel } from '../../shared/failures/failure.model';
import { DocumentTypeDescribedComboboxModel } from '../models/document-type-described-combobox.model';
import { DocumentTypeFilterModel } from '../models/document-type-filter.model';

export abstract class DocumentTypeQueryHandlerService {

	public abstract getAsDescribedComboboxes(
		query: DocumentTypeFilterModel
	): Observable<Result<Array<DocumentTypeDescribedComboboxModel>, FailureModel>>;

}
