export class FileUploadCancelUpdateCommand {

	public readonly blobId: string

	constructor(
		blobId: string
	) {
		// TODO: Guards
		this.blobId = blobId;
	}

}
