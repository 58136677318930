import { isValueDefined } from '@nmn-core/utils';
import { NmnMatTabGroupUserConfiguration } from '../component-configurations/nmn-mat-tab-group-user.configuation';

export class GeneticCardPageConfiguration {

	public readonly geneticTestsMatGroup: NmnMatTabGroupUserConfiguration;

	protected constructor(
		geneticTestsMatGroup: NmnMatTabGroupUserConfiguration
	) {
		this.geneticTestsMatGroup = geneticTestsMatGroup;
	}

	public static createWithParameters(
		config?: GeneticCardPageConfiguration
	): GeneticCardPageConfiguration {
		if (!isValueDefined(config)) {
			return GeneticCardPageConfiguration.createDefault();
		}

		return new GeneticCardPageConfiguration(
			isValueDefined(config.geneticTestsMatGroup) ?
				NmnMatTabGroupUserConfiguration.createWithParameters(config.geneticTestsMatGroup) :
				NmnMatTabGroupUserConfiguration.createDefault()
		);
	}

	public static createDefault(
	): GeneticCardPageConfiguration {
		return new GeneticCardPageConfiguration(
			NmnMatTabGroupUserConfiguration.createDefault()
		);
	}

}
