import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { PatientHttpClientConfiguration } from '../configurations/patient.http-client.configuration';
import { PatientHttpClient } from '../services/patient.http-client';

export class PatientHttpClientBuilder extends HttpClientBuilder<PatientHttpClient, PatientHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): PatientHttpClient {
		return new PatientHttpClient(this.injector.get<HttpClient>(HttpClient), this.configuration);
	}

}
