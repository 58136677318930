import { HttpClientConfigurationBuilder } from '../../../shared/builders/http-client.configuration.builder';
import { UserSubscriptionHttpClientConfiguration } from '../configurations/user-subscription.http-client.configuration';

export class UserSubscriptionHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<UserSubscriptionHttpClientConfiguration> {

	public build(): UserSubscriptionHttpClientConfiguration {
		return new UserSubscriptionHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
