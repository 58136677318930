import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { TakenMedicationGetQuery, TakenMedicationModel, TakenMedicationQueryHandlerService, TakenMedicationResolver } from '@nmn-domain/taken-medications';
import { RouteParameter } from '@nmn-middleware/navigation';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TakenMedicationViaQueryHandlerResolver extends TakenMedicationResolver {

	constructor(
		private readonly takenMedicationQueryHandlerService: TakenMedicationQueryHandlerService
	) {
		super();
	}

	public resolve(activatedRoute: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<TakenMedicationModel> | TakenMedicationModel {
		const takenMedicationId = activatedRoute.paramMap.get(RouteParameter.TakenMedicationId);
		const patientAlias = activatedRoute.paramMap.get(RouteParameter.PatientAlias);

		if (!isStringDefinedAndNotEmpty(takenMedicationId)) {
			return undefined;
		}

		return this.takenMedicationQueryHandlerService
			.get(TakenMedicationGetQuery.createById(takenMedicationId, patientAlias))
			.pipe(
				map(result => result.successOrDefault(() => undefined))
			);
	}

}
