import { AllergyIntoleranceStatusClient, AllergyIntoleranceStatusFilterDto } from '@nmn-communication/allergy-intolerance-statuses';
import { DescribedComboboxDto, Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { FakeDatabase } from 'app/modules/fakes/databases/fake.database';
import { wrapFuncCallAsFakeClientResponse } from 'app/modules/fakes/databases/fake.utils';
import { Observable } from 'rxjs';
import { AllergyIntoleranceStatusFakeClientConfiguration } from '../configurations/allergy-intolerance-status.fake-client.configuration';

export class AllergyIntoleranceStatusFakeClient extends AllergyIntoleranceStatusClient {

	constructor(
		private readonly configuration: AllergyIntoleranceStatusFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulkAsComboboxes(
		filter: AllergyIntoleranceStatusFilterDto
	): Observable<Result<Array<DescribedComboboxDto<string>>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.allergyIntoleranceStatusTable.getComboboxesPagedCollection(filter),
			this.configuration.serverDelayMs
		);
	}

}
