import { Injectable } from '@angular/core';
import { TakenMedicationClient } from '@nmn-communication/taken-medications';
import { Result } from '@nmn-core/shared';
import { CommandResult, EmptyCommandResult, FailureModel } from '@nmn-domain/shared';
import {
	TakenMedicationCommandHandlerService, TakenMedicationCreateCommand, TakenMedicationDeleteBulkCommand, TakenMedicationDeleteCommand,
	TakenMedicationFailCourseCommand, TakenMedicationFinishCourseBulkCommand, TakenMedicationFinishCourseCommand, TakenMedicationUpdateCommand
} from '@nmn-domain/taken-medications';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import {
	mapCreateCommandToCreateParameter, mapFinishCourseBulkCommandToFinishCourseBulkParameter,
	mapFinishCourseCommandToFinishCourseParameter, mapUpdateCommandToUpdateParameter
} from '../factories/taken-medication.factory';

@Injectable()
export class TakenMedicationCommandHandlerViaClientService extends TakenMedicationCommandHandlerService {

	constructor(
		private readonly client: TakenMedicationClient
	) {
		super();
	}

	public handleCreateCommand(command: TakenMedicationCreateCommand): Observable<Result<CommandResult<TakenMedicationCreateCommand, string>, FailureModel>> {
		return this.client
			.create(mapCreateCommandToCreateParameter(command))
			.pipe(map(result => result.map(success => new CommandResult(command, success), mapFailureToFailureModel)));
	}

	public handleUpdateCommand(command: TakenMedicationUpdateCommand): Observable<Result<EmptyCommandResult<TakenMedicationUpdateCommand>, FailureModel>> {
		return this.client
			.update({ id: command.id, patientId: command.patientId }, mapUpdateCommandToUpdateParameter(command))
			.pipe(map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command))));
	}

	public handleDeleteCommand(command: TakenMedicationDeleteCommand): Observable<Result<EmptyCommandResult<TakenMedicationDeleteCommand>, FailureModel>> {
		return this.client
			.delete({ id: command.id, patientId: command.patientId })
			.pipe(map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command))));
	}

	public handleDeleteBulkCommand(command: TakenMedicationDeleteBulkCommand): Observable<Result<EmptyCommandResult<TakenMedicationDeleteBulkCommand>, FailureModel>> {
		return this.client
			.deleteBulk({ ids: command.ids, patientId: command.patientId })
			.pipe(map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command))));
	}

	public handleFinishCourseCommand(command: TakenMedicationFinishCourseCommand): Observable<Result<EmptyCommandResult<TakenMedicationFinishCourseCommand>, FailureModel>> {
		return this.client
			.finishCourse(mapFinishCourseCommandToFinishCourseParameter(command))
			.pipe(map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command))));
	}

	public handleFinishCourseBulkCommand(command: TakenMedicationFinishCourseBulkCommand): Observable<Result<EmptyCommandResult<TakenMedicationFinishCourseBulkCommand>, FailureModel>> {
		return this.client
			.finishCourceBulk(mapFinishCourseBulkCommandToFinishCourseBulkParameter(command))
			.pipe(map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command))));
	}

	public handleFailCourseCommand(command: TakenMedicationFailCourseCommand): Observable<Result<EmptyCommandResult<TakenMedicationFailCourseCommand>, FailureModel>> {
		return this.client
			.failCourse(mapFinishCourseCommandToFinishCourseParameter(command))
			.pipe(map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command))));
	}

}
