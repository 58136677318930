import { TranslocoService } from '@ngneat/transloco';
import { HealthIssueSeverityComboboxDto, HealthIssueSeverityFilterDto } from '@nmn-communication/health-issue-severities';
import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../databases/fake.database';
import { getPagedCollectionWithoutDtoFilter } from '../../databases/fake.utils';
import { FakeLocalizableEntity } from '../../models/fake-localizable-entity';
import { setTranslation } from '../../utils/localize';

export class HealthIssueSeverityFakeTable {

	private readonly data: Array<HealthIssueSeverityFakeRecord>;

	constructor(
		private readonly database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.data = [...initialData];
	}

	public getAsCombobox(parameter: { id: number }): HealthIssueSeverityComboboxDto {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record not found');
		}

		return record;
	}

	public findAsCombobox(parameter: { id: number }): HealthIssueSeverityComboboxDto {
		const record = this.data
			.find((item: HealthIssueSeverityFakeRecord) => findPredicate(item, parameter));

		return isValueDefined(record) ? this.mapFromRecordToDescribedComboboxDto(record!) : undefined;
	}

	public getComboboxesPagedCollection(filter: HealthIssueSeverityFilterDto): Array<HealthIssueSeverityComboboxDto> {
		const pagedCollection = getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			this.filterPredicateForRecord.bind(this),
			this.mapFromRecordToDescribedComboboxDto.bind(this),
			compareFn
		).items;

		return pagedCollection;
	}

	private filterPredicateForRecord(
		item: HealthIssueSeverityFakeRecord,
		filter: HealthIssueSeverityFilterDto,
		translocoService: TranslocoService
	): boolean {
		let result = true;

		if (result && isArrayDefinedAndNotEmpty(filter.ids)) {
			result = result && filter.ids!.some(id => item.id === id);
		}

		if (result && isArrayDefinedAndNotEmpty(filter.ignoreIds)) {
			result = result && filter.ignoreIds!.every(ignoreId => item.id !== ignoreId);
		}

		if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
			result = result &&
				setTranslation(translocoService, item.name)
					?.toLowerCase()
					?.indexOf(filter.searchPattern!.toLowerCase()) >= 0;
		}

		return result;
	}

	private mapFromRecordToDescribedComboboxDto(record: HealthIssueSeverityFakeRecord): HealthIssueSeverityComboboxDto {
		return {
			id: record.id,
			displayText: setTranslation(this.translocoService, record.name),
			description: setTranslation(this.translocoService, record.description)
		};
	}

}

const findPredicate = (item: HealthIssueSeverityFakeRecord, findParameter: { id: number }): boolean =>
	item.id === findParameter.id;

/* eslint-disable  */
/* eslint-disable complexity */
const compareFn = (
	item1: HealthIssueSeverityComboboxDto,
	item2: HealthIssueSeverityComboboxDto,
	sorting: string
): number => {
	if (sorting === 'displayText asc') {
		return item1.displayText > item2.displayText ? 1 : item1.displayText < item2.displayText ? -1 : 0;
	} else if (sorting === 'displayText desc') {
		return item1.displayText < item2.displayText ? 1 : item1.displayText > item2.displayText ? -1 : 0;
	}

	return 0;
};
/* eslint-enable complexity */
/* eslint-enable */

interface HealthIssueSeverityFakeRecord {
	id: number;
	name: FakeLocalizableEntity;
	description: FakeLocalizableEntity;
}

const initialData: Array<HealthIssueSeverityFakeRecord> = [
	{
		id: 1,
		name: { en: 'Low', uk: 'Low' },
		description: { en: 'Low description', uk: 'Low description' }
	},
	{
		id: 2,
		name: { en: 'Medium', uk: 'Medium' },
		description: { en: 'Medium description', uk: 'Medium description' }
	},
	{
		id: 3,
		name: { en: 'High', uk: 'High' },
		description: { en: 'High description', uk: 'High description' }
	}
];

// eslint-disable-next-line max-lines
