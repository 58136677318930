import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RegionComboboxModel, RegionFilterModel, RegionQueryHandlerService, RegionsResolver } from '@nmn-domain/regions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RegionsViaQueryHandlerResolver extends RegionsResolver {

	constructor(
		private readonly populationQueryHandlerService: RegionQueryHandlerService
	) {
		super();
	}

	public resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<Array<RegionComboboxModel>> | Array<RegionComboboxModel> {
		return this.populationQueryHandlerService
			.getAsComboboxes(RegionFilterModel.createEmpty())
			.pipe(map(result => result.successOrDefault(() => [])));
	}

}
