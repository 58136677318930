import { Provider } from '@angular/core';
import { AllergyQueryHandlerService } from '@nmn-domain/allergies';
import { AllergyQueryHandlerViaClientService } from './services/allergy.query-handler-via-client.service';

export const allergyProviders: Array<Provider> = [
	{
		provide: AllergyQueryHandlerService,
		useClass: AllergyQueryHandlerViaClientService
	}
];
