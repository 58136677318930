import { Failure, PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { TakenMedicationCreateParameterDto } from '../models/parameters/taken-medication-create-parameter.dto';
import { TakenMedicationDeleteBulkParameterDto } from '../models/parameters/taken-medication-delete-bulk-parameter.dto';
import { TakenMedicationFailCourseParameterDto } from '../models/parameters/taken-medication-fail-course-parameter.dto';
import { TakenMedicationFindParameterDto } from '../models/parameters/taken-medication-find-parameter.dto';
import { TakenMedicationFinishCourseBulkParameterDto } from '../models/parameters/taken-medication-finish-course-bulk-parameter.dto';
import { TakenMedicationFinishCourseParameterDto } from '../models/parameters/taken-medication-finish-course-parameter.dto';
import { TakenMedicationUpdateBulkParameterDto } from '../models/parameters/taken-medication-update-bulk-parameter.dto';
import { TakenMedicationUpdateParameterDto } from '../models/parameters/taken-medication-update-parameter.dto';
import { TakenMedicationComboboxDto } from '../models/taken-medication-described-combobox.dto';
import { TakenMedicationFilterDto } from '../models/taken-medication-filter.dto';
import { TakenMedicationDto } from '../models/taken-medication.dto';

export abstract class TakenMedicationClient {

	public abstract getBulkAsComboboxes(
		filter: TakenMedicationFilterDto
	): Observable<Result<Array<TakenMedicationComboboxDto>, Failure>>;

	public abstract getBulk(
		parameter: PageOptionsDto<TakenMedicationFilterDto>
	): Observable<Result<PagedCollectionDto<TakenMedicationDto, TakenMedicationFilterDto>, Failure>>;

	public abstract get(
		parameter: TakenMedicationFindParameterDto
	): Observable<Result<TakenMedicationDto, Failure>>;

	public abstract create(
		parameter: TakenMedicationCreateParameterDto
	): Observable<Result<string, Failure>>;

	public abstract update(
		findParameter: TakenMedicationFindParameterDto,
		updateParameter: TakenMedicationUpdateParameterDto
	): Observable<EmptyResult<Failure>>;

	public abstract updateBulk(
		parameter: TakenMedicationUpdateBulkParameterDto
	): Observable<EmptyResult<Failure>>;

	public abstract delete(
		parameter: TakenMedicationFindParameterDto
	): Observable<EmptyResult<Failure>>;

	public abstract deleteBulk(
		parameter: TakenMedicationDeleteBulkParameterDto
	): Observable<EmptyResult<Failure>>;

	public abstract finishCourse(
		parameter: TakenMedicationFinishCourseParameterDto
	): Observable<EmptyResult<Failure>>;

	public abstract finishCourceBulk(
		parameter: TakenMedicationFinishCourseBulkParameterDto
	): Observable<EmptyResult<Failure>>;

	public abstract failCourse(
		parameter: TakenMedicationFailCourseParameterDto
	): Observable<EmptyResult<Failure>>;

}
