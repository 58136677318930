import { Result } from '@nmn-core/shared';
import { EmptyCommandResult, FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { RegisterAccountCommand } from '../models/commands/register-account.command';

export abstract class RegistrationCommandHandlerService {

	public abstract handleRegisterAccount(command: RegisterAccountCommand): Observable<Result<EmptyCommandResult<RegisterAccountCommand>, FailureModel>>;

}
