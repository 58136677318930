import { GeneComboboxDto, GeneFilterDto } from '@nmn-communication/genes';
import { GeneComboboxModel, GeneFilterModel } from '@nmn-domain/genes';

export const mapFilterModelToDto = (model: GeneFilterModel): GeneFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern
	}
);

export const mapFilterDtoToModel = (dto: GeneFilterDto): GeneFilterModel => (
	new GeneFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern
	)
);

export const mapGeneComboboxDtoToModel = (dto: GeneComboboxDto): GeneComboboxModel => (
	new GeneComboboxModel(
		dto.id,
		dto.displayText,
		dto.description,
		dto.fullName
	)
);
