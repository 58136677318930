import { TranslocoService } from '@ngneat/transloco';
import { GeneComboboxDto, GeneFilterDto } from '@nmn-communication/genes';
import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../databases/fake.database';
import { getPagedCollectionWithoutDtoFilter } from '../../databases/fake.utils';
import { FakeGeneComboboxDto, mapFakeGeneComboboxDtoToGeneComboboxDto } from '../../models/comboboxes/fake-gene-combobox.dto';
import { FakeLocalizableEntity } from '../../models/fake-localizable-entity';
import { setTranslation } from '../../utils/localize';

export class GeneFakeTable {

	private readonly data: Array<GeneFakeRecord>;

	constructor(
		_: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.data = [...initialData];
	}

	public getAsCombobox(parameter: { id: string }): GeneComboboxDto {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record was not found');
		}

		return record;
	}

	public findAsCombobox(parameter: { id: string }): GeneComboboxDto {
		const record = this.data.find((item: GeneFakeRecord) => findPredicate(item, parameter));

		const comboboxRecord = mapFromRecordToDescribedComboboxDto(record!);

		return mapFakeGeneComboboxDtoToGeneComboboxDto(this.translocoService, comboboxRecord!);
	}

	public getComboboxesPagedCollection(filter: GeneFilterDto): Array<GeneComboboxDto> {
		const pagedCollection = getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			filterPredicateForRecord,
			mapFromRecordToDescribedComboboxDto,
			compareFn
		).items;

		return pagedCollection.map(item => mapFakeGeneComboboxDtoToGeneComboboxDto(this.translocoService, item));
	}

}

const mapFromRecordToDescribedComboboxDto = (record: GeneFakeRecord): FakeGeneComboboxDto => (
	isValueDefined(record) ?
		{
			id: record.id,
			displayText: record.name,
			description: record.description,
			fullName: record.fullName
		} :
		undefined!
);

const findPredicate = (item: GeneFakeRecord, findParameter: { id: string }): boolean =>
	item.id === findParameter.id;

const filterPredicateForRecord = (item: GeneFakeRecord, filter: GeneFilterDto, translocoService: TranslocoService): boolean => {
	let result = true;

	if (result && isArrayDefinedAndNotEmpty(filter.ids)) {
		result = result && filter.ids!.some(id => item.id === id);
	}

	if (result && isArrayDefinedAndNotEmpty(filter.ignoreIds)) {
		result = result && filter.ignoreIds!.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result &&
			setTranslation(translocoService, item.name)
				.toLowerCase()
				.indexOf(filter.searchPattern!.toLowerCase()) >= 0;
	}

	return result;
};

/* eslint-disable  */
/* eslint-disable complexity */
const compareFn = (item1: FakeGeneComboboxDto, item2: FakeGeneComboboxDto, sorting: string): number => {
	if (sorting === 'displayText asc') {
		return item1.displayText > item2.displayText ? 1 : item1.displayText < item2.displayText ? -1 : 0;
	} else if (sorting === 'displayText desc') {
		return item1.displayText < item2.displayText ? 1 : item1.displayText > item2.displayText ? -1 : 0;
	}

	return 0;
};
/* eslint-enable complexity */
/* eslint-enable */

interface GeneFakeRecord {
	id: string;
	name: FakeLocalizableEntity;
	description: FakeLocalizableEntity;
	fullName?: string;
}

const initialData: Array<GeneFakeRecord> = [
	{
		id: 'ADRB2',
		name: {
			en: 'ADRB2',
			uk: 'ADRB2'
		},
		description: {
			en: 'ADRB2 is a cell membrane-spanning beta-adrenergic receptor that binds epinephrine (adrenaline), a hormone and neurotransmitter whose signaling, via adenylate cyclase stimulation through trimeric Gs proteins, increased cAMP, and downstream L-type calcium channel interaction, mediates physiologic responses such as smooth muscle relaxation and bronchodilation.',
			uk: 'ADRB2 білок, який кодується однойменним геном, розташованим у людей на короткому плечі 5-ї хромосоми. Довжина поліпептидного ланцюга білка становить 413 амінокислот, а молекулярна маса — 46 459. Кодований геном білок за функціями належить до рецепторів, g-білокспряжених рецепторів, білків внутрішньоклітинного сигналінгу, фосфопротеїнів. Задіяний у такому біологічному процесі як поліморфізм. Локалізований у клітинній мембрані, мембрані, ендосомах.'
		}
	},
	{
		id: 'CYP2B6',
		name: {
			en: 'CYP2B6',
			uk: 'CYP2B6'
		},
		description: {
			en: 'Cytochrome P450 Family 2 Subfamily C Member 9.\nCYP2C19 catalyzes the metabolism of several drugs, including proton pump inhibitors (PPIs) (e.g., omeprazole, lansoprazole, pantoprazole), antidepressants (e.g., citalopram and amitriptyline), antiplatelet drugs (e.g., clopidogrel), antifungals (e.g., voriconazole), and anticancer compounds (e.g., cyclophosphamide).',
			uk: 'Цитохром P450 Родина 2 Підродина C Член 9.\nCYP2C19 каталізує метаболізм кількох лікарських засобів, включаючи інгібітори протонної помпи (ІПП) (наприклад, омепразол, лансопразол, пантопразол), антидепресанти (наприклад, циталопрам і амітриптилін), антиагреганти (наприклад, клопідогрель), протигрибкові засоби (наприклад, вориконазол) та протипухлинні сполуки (наприклад, циклофосфамід).'
		}
	},
	{
		id: 'CYP2C8',
		name: {
			en: 'CYP2C8',
			uk: 'CYP2C8'
		},
		description: {
			en: 'Cytochrome P450 Family 2 Subfamily C Member 8.\nCYP2C8 substrate drugs include amodiaquine, cerivastatin, dasabuvir, enzalutamide, imatinib, loperamide, montelukast, paclitaxel, pioglitazone, repaglinide, and rosiglitazone, and the number is increasing. Similarly, many drugs have been identified as CYP2C8 inhibitors or inducers.',
			uk: 'Цитохром P450 Родина 2 Підродина C Член 8.\nПрепарати-субстрати CYP2C8 включають амодиахін, церивастатин, дасабувір, ензалутамід, іматиніб, лоперамід, монтелукаст, паклітаксел, піоглітазон, репаглінід і розиглітазон, і кількість зростає. Подібним чином, багато препаратів були визначені як інгібітори або індуктори CYP2C8.'
		}
	},
	{
		id: 'CYP2C9',
		name: {
			en: 'CYP2C9',
			uk: 'CYP2C9'
		},
		description: {
			en: 'Cytochrome P450 Family 2 Subfamily C Member 9.\nCYP2C19 catalyzes the metabolism of several drugs, including proton pump inhibitors (PPIs) (e.g., omeprazole, lansoprazole, pantoprazole), antidepressants (e.g., citalopram and amitriptyline), antiplatelet drugs (e.g., clopidogrel), antifungals (e.g., voriconazole), and anticancer compounds (e.g., cyclophosphamide).',
			uk: 'Цитохром P450 Родина 2 Підродина C Член 9.\nCYP2C19 каталізує метаболізм кількох лікарських засобів, включаючи інгібітори протонної помпи (ІПП) (наприклад, омепразол, лансопразол, пантопразол), антидепресанти (наприклад, циталопрам і амітриптилін), антиагреганти (наприклад, клопідогрель), протигрибкові засоби (наприклад, вориконазол) та протипухлинні сполуки (наприклад, циклофосфамід).'
		}
	},
	{
		id: 'CYP2C19',
		name: {
			en: 'CYP2C19',
			uk: 'CYP2C19'
		},
		description: {
			en: 'Cytochrome P450 Family 2 Subfamily C Member 19.\nCYP2C19 catalyzes the metabolism of several drugs, including proton pump inhibitors (PPIs) (e.g., omeprazole, lansoprazole, pantoprazole), antidepressants (e.g., citalopram and amitriptyline), antiplatelet drugs (e.g., clopidogrel), antifungals (e.g., voriconazole), and anticancer compounds (e.g., cyclophosphamide).',
			uk: 'Цитохром P450 Родина 2 Підродина C Член 19.\nCYP2C19 каталізує метаболізм кількох лікарських засобів, включаючи інгібітори протонної помпи (ІПП) (наприклад, омепразол, лансопразол, пантопразол), антидепресанти (наприклад, циталопрам і амітриптилін), антиагреганти (наприклад, клопідогрель), протигрибкові засоби (наприклад, вориконазол) та протипухлинні сполуки (наприклад, циклофосфамід).'
		}
	},
	{
		id: 'CYP2D6',
		name: {
			en: 'CYP2D6',
			uk: 'CYP2D6'
		},
		description: {
			en: 'Cytochrome P450 Family 2 Subfamily D Member 6.\nCYP2D6 impacts the metabolism of multiple tricyclic antidepressants, β blockers, haloperidol, sertraline, paroxetine, and thioridazine are among the common drugs metabolized by CYP2D6.',
			uk: 'Цитохром P450 Родина 2 Підродина D Член 6.\nCYP2D6 впливає на метаболізм кількох трициклічних антидепресантів, β-блокаторів, галоперидолу, сертраліну, пароксетину та тіоридазину є серед поширених препаратів, які метаболізуються CYP2D6.'
		},
		fullName: 'Cytochrome P450 Family 2 Subfamily D Member 6'
	},
	{
		id: 'CYP3A4',
		name: {
			en: 'CYP3A4',
			uk: 'CYP3A4'
		},
		description: {
			en: 'Cytochrome P450 Family 3 Subfamily A Member 4)\nCYP3A4 impacts the metabolism of  amiodarone, erythromycin, fluconazole, miconazole, diltiazem, verapamil, delavirdine, amprenavir, fosamprenavir, conivaptan.',
			uk: 'Цитохром P450 Родина 3 Член підродини A 4)\nCYP3A4 впливає на метаболізм аміодарону, еритроміцину, флуконазолу, міконазолу, дилтіазему, верапамілу, делавірдину, ампренавіру, фосампренавіру, коніваптану.'
		}
	},
	{
		id: 'CYP3A5',
		name: {
			en: 'CYP3A5',
			uk: 'CYP3A5'
		},
		description: {
			en: 'Cytochrome P450 Family 3 Subfamily A Member 5\nSome drugs that the CYP3A5 gene acts on include cyclosporine, quinine, vincristine, midazolam, and tacrolimus. Some CYP3A5 inducers are phenobarbital, rifampicin, carbamazepine, and enzalutamide.',
			uk: 'Цитохром P450 Родина 3 Член підродини A 5\nДеякі препарати, на які впливає ген CYP3A5, включають циклоспорин, хінін, вінкристин, мідазолам і такролімус. Деякі індуктори CYP3A5 - фенобарбітал, рифампіцин, карбамазепін і ензалутамід.'
		}
	},
	{
		id: 'HTR2C',
		name: {
			en: 'HTR2C',
			uk: 'HTR2C'
		},
		description: {
			en: 'The 5,10-methylenetetrahydrofolate reductase (MTHFR) is a key enzyme for intracellular folate homeostasis and metabolism. Two common MTHFR polymorphisms, C677T and A1298C, which lead to an altered amino acid sequence, have been associated with a decreased enzyme activity and susceptibility to cancer suggesting that these genetic variants may modulate the risk of several malignancies.',
			uk: '5,10-метилентетрагідрофолатредуктаза (MTHFR) є ключовим ферментом внутрішньоклітинного гомеостазу та метаболізму фолієвої кислоти. Два поширених поліморфізму MTHFR, C677T і A1298C, які призводять до зміненої послідовності амінокислот, були пов’язані зі зниженою активністю ферменту та сприйнятливістю до раку, що свідчить про те, що ці генетичні варіанти можуть модулювати ризик кількох злоякісних новоутворень.'
		}
	},
	{
		id: 'SLCO1B1',
		name: {
			en: 'SLCO1B1',
			uk: 'SLCO1B1'
		},
		description: {
			en: 'Solute carrier organic anion transporter family member 1B1 is a protein that in humans is encoded by the SLCO1B1 gene. Pharmacogenomic research indicates that genetic variations in this gene are associated with response to simvastatin. Clinical guidelines exist that can guide dosing of simvastatin based on SLCO1B1 gene variant using genotyping or whole exome sequencing.',
			uk: 'Білок, який кодується однойменним геном, розташованим у людей на короткому плечі 12-ї хромосоми. Довжина поліпептидного ланцюга білка становить 691 амінокислот, а молекулярна маса — 76 449. Кодований геном білок за функцією належить до фосфопротеїнів. Задіяний у таких біологічних процесах, як транспорт іонів, транспорт. Локалізований у клітинній мембрані, мембрані.'
		}
	},
	{
		id: 'SPATS2L',
		name: {
			en: 'SPATS2L',
			uk: 'SPATS2L'
		},
		description: {
			en: 'SPATS2-like protein (spermatogenesis associated, serine-rich 2-like protein) or DNAPTP6 (DNA polymerase transactivated protein 6) is a protein that in humans is encoded by the SPATS2L gene.',
			uk: 'SPATS2L (англ. Spermatogenesis associated serine rich 2 like) – білок, який кодується однойменним геном, розташованим у людей на короткому плечі 2-ї хромосоми.'
		}
	}
];
