import { Injectable } from '@angular/core';
import { LoginDto, UserProfileClient } from '@nmn-communication/accounts';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import {
	CurrentUserProfileCommandHandlerService, UserProfileAttachFacebookAuthCommand, UserProfileAttachGoogleAuthCommand, UserProfileAttachRegularAuthCommand,
	UserProfileDeleteCommand, UserProfilePasswordUpdateCommand, UserProfileRevokeDeleteCommand, UserProfileUpdateCommand
} from '@nmn-domain/accounts';
import { EmptyCommandResult, FailureModel } from '@nmn-domain/shared';
import { map, Observable, tap } from 'rxjs';
import { mapFailureToFailureModel } from '../../../../../domain/implementations/shared/factories/failure-handling.factory';
import { StorageService, updateSubscriptionsAfterLogin, UserConfigurationStorageService } from '../../../../../services';
import { mapLoginDtoToModel } from '../../login/factories/login.factory';
import {
	mapPasswordUpdateCommandToParameter,
	mapUpdateCommandToParameter,
	mapUserProfileAttachFacebookAuthCommandToParameter,
	mapUserProfileAttachGoogleAuthCommandToParameter,
	mapUserProfileAttachRegularAuthCommandToParameter,
	mapUserProfileDeleteCommandToParameter,
	mapUserProfileRevokeDeleteCommandToParameter
} from '../factories/user-profile.factory';

@Injectable()
export class UserProfileCommandHandlerViaClientService extends CurrentUserProfileCommandHandlerService {

	constructor(
		private readonly client: UserProfileClient,
		private readonly storage: StorageService,
		private readonly userConfigurationStorage: UserConfigurationStorageService
	) {
		super();
	}

	public update(command: UserProfileUpdateCommand): Observable<Result<EmptyCommandResult<UserProfileUpdateCommand>, FailureModel>> {
		return this.client
			.updateCurrent(mapUpdateCommandToParameter(command))
			.pipe(
				tap(this.updateStorageInfoViaResult.bind(this)),
				map(result => result.map(() => new EmptyCommandResult(command), mapFailureToFailureModel))
			);
	}

	public changePassword(command: UserProfilePasswordUpdateCommand): Observable<Result<EmptyCommandResult<UserProfilePasswordUpdateCommand>, FailureModel>> {
		return this.client
			.changePasswordCurrent(mapPasswordUpdateCommandToParameter(command))
			.pipe(
				tap(this.updateStorageInfoViaResult.bind(this)),
				map(result => result.map(() => new EmptyCommandResult(command), mapFailureToFailureModel))
			);
	}

	public delete(command: UserProfileDeleteCommand): Observable<Result<EmptyCommandResult<UserProfileDeleteCommand>, FailureModel>> {
		return this.client
			.deleteCurrent(mapUserProfileDeleteCommandToParameter(command))
			.pipe(
				// do not update user info on delete due to chained logout
				map(result => result.map(() => new EmptyCommandResult(command), mapFailureToFailureModel))
			);
	}

	public revokeDelete(command: UserProfileRevokeDeleteCommand): Observable<Result<EmptyCommandResult<UserProfileRevokeDeleteCommand>, FailureModel>> {
		return this.client
			.revokeDeleteCurrent(mapUserProfileRevokeDeleteCommandToParameter(command))
			.pipe(
				tap(this.updateStorageInfoViaResult.bind(this)),
				map(result => result.map(() => new EmptyCommandResult(command), mapFailureToFailureModel))
			);
	}

	public attachRegularAuthFlow(command: UserProfileAttachRegularAuthCommand): Observable<Result<EmptyCommandResult<UserProfileAttachRegularAuthCommand>, FailureModel>> {
		return this.client
			.attachRegularAuthFlow(mapUserProfileAttachRegularAuthCommandToParameter(command))
			.pipe(
				tap(this.updateStorageInfoViaResult.bind(this)),
				map(result => result.map(() => new EmptyCommandResult(command), mapFailureToFailureModel))
			);
	}

	public attachGoogleAuthFlow(command: UserProfileAttachGoogleAuthCommand): Observable<Result<EmptyCommandResult<UserProfileAttachGoogleAuthCommand>, FailureModel>> {
		return this.client
			.attachGoogleAuthFlow(mapUserProfileAttachGoogleAuthCommandToParameter(command))
			.pipe(
				tap(this.updateStorageInfoViaResult.bind(this)),
				map(result => result.map(() => new EmptyCommandResult(command), mapFailureToFailureModel))
			);
	}

	public attachFacebookAuthFlow(command: UserProfileAttachFacebookAuthCommand): Observable<Result<EmptyCommandResult<UserProfileAttachFacebookAuthCommand>, FailureModel>> {
		return this.client
			.attachFacebookAuthFlow(mapUserProfileAttachFacebookAuthCommandToParameter(command))
			.pipe(
				tap(this.updateStorageInfoViaResult.bind(this)),
				map(result => result.map(() => new EmptyCommandResult(command), mapFailureToFailureModel))
			);
	}

	private updateStorageInfoViaResult(result: Result<LoginDto, Failure>): void {
		result
			.mapOnSuccess(mapLoginDtoToModel)
			.bindOnSuccess(success => {
				updateSubscriptionsAfterLogin(success, this.storage, this.userConfigurationStorage);
			});
	}

}
