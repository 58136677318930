import { Injector, Provider } from '@angular/core';
import { AllergyIntoleranceVerificationStatusClient } from '@nmn-communication/allergy-intolerance-verification-statuses';
import { allergyIntoleranceVerificationStatusClientFactory } from './factories/allergy-intolerance-verification-status.client.factory';

export const allergyIntoleranceVerificationStatusProviders: Array<Provider> = [
	{
		provide: AllergyIntoleranceVerificationStatusClient,
		useFactory: allergyIntoleranceVerificationStatusClientFactory,
		deps: [Injector]
	}
];
