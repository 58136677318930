import { isValueDefined } from '@nmn-core/utils';
import { AllergyIntoleranceVerificationStatusFakeClientConfiguration } from '../configurations/allergy-intolerance-verification-status.fake-client.configuration';

export class AllergyIntoleranceVerificationStatusFakeClientConfigurationBuilder {

	private readonly serverDelayMs: { min: number, max: number } = { min: 0, max: 0 };

	public withServerDelay(
		serverDelayMs: { min: number, max: number }
	): AllergyIntoleranceVerificationStatusFakeClientConfigurationBuilder {
		const isServerDelayValidAndDefined = isValueDefined(serverDelayMs) &&
			isValueDefined(serverDelayMs.min) &&
			isValueDefined(serverDelayMs.max) &&
			serverDelayMs.min <= serverDelayMs.max;

		if (isServerDelayValidAndDefined) {
			this.serverDelayMs.min = serverDelayMs.min;
			this.serverDelayMs.max = serverDelayMs.max;
		}

		return this;
	}

	public build(): AllergyIntoleranceVerificationStatusFakeClientConfiguration {
		return new AllergyIntoleranceVerificationStatusFakeClientConfiguration(this.serverDelayMs);
	}

}
