
import { GeneticFileParseRequestStatus } from './genetic-file-parse-request-status.enum';

export class GeneticFileParseRequestModel {

	public readonly id: string;
	public readonly patientId: string;
	public readonly status: GeneticFileParseRequestStatus;
	public readonly error?: string;

	constructor(
		id: string,
		patientId: string,
		status: GeneticFileParseRequestStatus,
		error?: string
	) {
		// TODO: Guard check on defined

		this.id = id;
		this.patientId = patientId;
		this.status = status;
		this.error = error;
	}

	public static createForStatus(
		status: GeneticFileParseRequestStatus
	): GeneticFileParseRequestModel {
		return new GeneticFileParseRequestModel(
			undefined,
			undefined,
			status);
	}

}
