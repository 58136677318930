import { AllergyIntoleranceFormOutModel } from '../allergy-intolerance-form/allergy-intolerance-form.out-model';

export class PatientMedicalInfoFormOutModel {

	public readonly height: number;
	public readonly weight: number;
	public readonly bloodTypeId: string;
	public readonly allergyIntolerances: Array<AllergyIntoleranceFormOutModel>;
	public readonly seriousPathologyIds: Array<string>;
	public readonly familyDiseaseIds: Array<string>;
	public readonly regularlyTakenMedicationIds: Array<string>;

	public readonly isActiveLifestyle?: boolean;
	public readonly isAlcohol?: boolean;
	public readonly isOnHealthyDiet?: boolean;
	public readonly isSmoking?: boolean;
	public readonly isUnderHighLevelOfStress?: boolean;
	public readonly isUsingNutritionalSupplements?: boolean;

	constructor(
		height: number | undefined,
		weight: number | undefined,
		bloodTypeId: string | undefined,
		allergyIntolerances: Array<AllergyIntoleranceFormOutModel>,
		seriousPathologyIds: Array<string>,
		familyDiseaseIds: Array<string>,
		regularlyTakenMedicationIds: Array<string>,
		isActiveLifestyle: boolean | undefined,
		isAlcohol: boolean | undefined,
		isOnHealthyDiet: boolean | undefined,
		isSmoking: boolean | undefined,
		isUnderHighLevelOfStress: boolean | undefined,
		isUsingNutritionalSupplements: boolean | undefined
	) {
		this.height = height;
		this.weight = weight;
		this.bloodTypeId = bloodTypeId;
		this.allergyIntolerances = allergyIntolerances;
		this.seriousPathologyIds = seriousPathologyIds;
		this.familyDiseaseIds = familyDiseaseIds;
		this.regularlyTakenMedicationIds = regularlyTakenMedicationIds;
		this.isActiveLifestyle = isActiveLifestyle;
		this.isAlcohol = isAlcohol;
		this.isOnHealthyDiet = isOnHealthyDiet;
		this.isSmoking = isSmoking;
		this.isUnderHighLevelOfStress = isUnderHighLevelOfStress;
		this.isUsingNutritionalSupplements = isUsingNutritionalSupplements;
	}

}
