import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FailureModel } from '../../shared/failures/failure.model';
import { PageOptionsQuery } from '../../shared/pagination/page-options.query';
import { PagedCollectionModel } from '../../shared/pagination/paged-collection.model';
import { TreatmentCheckerReportGetQuery } from '../models/queries/treatment-checker-report.get.query';
import { TreatmentCheckerReportFilterModel } from '../models/treatment-checker-report-filter.model';
import { TreatmentCheckerReportModel } from '../models/treatment-checker-report.model';
import { TreatmentCheckerReportShortModel } from '../models/treatment-checker-report.short-model';

export abstract class TreatmentCheckerQueryHandlerService {

	public abstract getReportsPage(
		query: PageOptionsQuery<TreatmentCheckerReportFilterModel>
	): Observable<Result<PagedCollectionModel<TreatmentCheckerReportShortModel, TreatmentCheckerReportFilterModel>, FailureModel>>;

	public abstract getReport(query: TreatmentCheckerReportGetQuery): Observable<Result<TreatmentCheckerReportModel, FailureModel>>;

}
