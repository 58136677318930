export class PatientGetQuery {

	public alias: string;

	public constructor(
		alias: string
	) {
		this.alias = alias;
	}

	public static createByAlias(alias: string): PatientGetQuery {
		return new PatientGetQuery(alias);
	}

}
