import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ActivePatientsResolver, PatientModel, PatientQueryHandlerService } from '@nmn-domain/patients';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ActivePatientsViaQueryHandlerResolver extends ActivePatientsResolver {

	constructor(
		private readonly patientQueryHandlerService: PatientQueryHandlerService
	) {
		super();
	}

	public resolve(_: ActivatedRouteSnapshot, __: RouterStateSnapshot): Observable<Array<PatientModel>> | Promise<Array<PatientModel>> | Array<PatientModel> {
		return this.patientQueryHandlerService
			.getActivePatients()
			.pipe(map(result => result.successOrDefault(() => undefined)));
	}

}
