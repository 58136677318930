import {
	PatientProfileShortDto, UserProfileAttachFacebookAuthUpdateParameter, UserProfileAttachGoogleAuthUpdateParameter, UserProfileAttachRegularAuthUpdateParameter,
	UserProfileDeleteParameter, UserProfileDto, UserProfileFindParameter, UserProfilePasswordUpdateParameter, UserProfileRevokeDeleteParameter, UserProfileUpdateParameter
} from '@nmn-communication/accounts';
import { isValueDefined, mapDateInServerFormatToDate, mapDateInServerFormatToDateSafely } from '@nmn-core/utils';
import {
	PatientProfileShortModel, UserFileStorageProfileModel, UserProfileAttachFacebookAuthCommand, UserProfileAttachGoogleAuthCommand, UserProfileAttachRegularAuthCommand,
	UserProfileDeleteCommand, UserProfileGetQuery, UserProfileModel, UserProfilePasswordUpdateCommand, UserProfileRevokeDeleteCommand, UserProfileUpdateCommand
} from '@nmn-domain/accounts';
import { mapUserSubscriptionDtoToUserSubscriptionModel } from '../../../../../domain/implementations/accounts/user-subscription/factories/user-subscription.factory';
import { mapUploadedFileDtoToModel } from '../../../../../domain/implementations/file-uploader/factories/file-uploader.factory';

export const mapUserProfileDtoToModel = (dto: UserProfileDto): UserProfileModel => (
	new UserProfileModel(
		dto.id,
		dto.email,
		dto.isEmailNotificationsEnabled,
		dto.isPreferedBrowserTimezone,
		isValueDefined(dto.picture) ? mapUploadedFileDtoToModel(dto.picture) : undefined,
		dto.locale,
		dto.timeZone,
		mapDateInServerFormatToDateSafely(dto.createdOn),
		dto.isDeletionRequested,
		mapDateInServerFormatToDate(dto.deletionRequestedOn),
		dto.activePatientId,
		dto.activePatientAlias,
		dto.patients.map(mapPatientShortDtoToModel),
		dto.isRegularAuthAttached,
		dto.isGoogleAuthAttached,
		dto.isFacebookAuthAttached,
		mapUserSubscriptionDtoToUserSubscriptionModel(dto.userSubscription)
	)
);

export const mapPatientShortDtoToModel = (dto: PatientProfileShortDto): PatientProfileShortModel => (
	new PatientProfileShortModel(
		dto.id,
		dto.alias,
		dto.userId,
		dto.email,
		dto.givenName,
		dto.additionalName,
		dto.familyName,
		isValueDefined(dto.picture) ? mapUploadedFileDtoToModel(dto.picture) : undefined,
		dto.isDeletionRequested,
		mapDateInServerFormatToDate(dto.deletionRequestedOn)
	)
);

export const mapDtoToUserStorageModel = (dto: UserProfileDto): UserFileStorageProfileModel => (
	new UserFileStorageProfileModel(
		dto.usedStorageCapacity
	)
);

export const mapGetQueryToParameter = (query: UserProfileGetQuery): UserProfileFindParameter => (
	{
		id: query.id
	}
);

export const mapUpdateCommandToParameter = (command: UserProfileUpdateCommand): UserProfileUpdateParameter => (
	{
		isEmailNotificationsEnabled: command.isEmailNotificationsEnabled,
		isPreferedBrowserTimezone: command.isPreferedBrowserTimezone,
		locale: command.locale,
		pictureId: command.pictureId,
		timezone: command.timezone,
		activePatientAlias: command.activePatientAlias
	}
);

export const mapPasswordUpdateCommandToParameter = (command: UserProfilePasswordUpdateCommand): UserProfilePasswordUpdateParameter => (
	{
		oldPassword: command.oldPassword,
		newPassword: command.newPassword,
		confirmNewPassword: command.confirmNewPassword
	}
);

export const mapUserProfileDeleteCommandToParameter = (command: UserProfileDeleteCommand): UserProfileDeleteParameter => (
	{
		password: command.password
	}
);

export const mapUserProfileRevokeDeleteCommandToParameter = (command: UserProfileRevokeDeleteCommand): UserProfileRevokeDeleteParameter => (
	{
		password: command.password
	}
);

export const mapUserProfileAttachGoogleAuthCommandToParameter = (command: UserProfileAttachGoogleAuthCommand): UserProfileAttachGoogleAuthUpdateParameter => (
	{
		idToken: command.idToken
	}
);

export const mapUserProfileAttachFacebookAuthCommandToParameter = (command: UserProfileAttachFacebookAuthCommand): UserProfileAttachFacebookAuthUpdateParameter => (
	{
		accessToken: command.accessToken
	}
);

export const mapUserProfileAttachRegularAuthCommandToParameter = (command: UserProfileAttachRegularAuthCommand): UserProfileAttachRegularAuthUpdateParameter => (
	{
		password: command.password,
		confirmPassword: command.confirmPassword
	}
);
