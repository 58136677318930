import { AllergyIntoleranceCriticalityFilterDto } from '@nmn-communication/allergy-intolerance-criticalities';
import { AllergyIntoleranceCriticalityFilterModel } from '@nmn-domain/allergy-intolerance-criticalities';

export const mapFilterModelToDto = (model: AllergyIntoleranceCriticalityFilterModel): AllergyIntoleranceCriticalityFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern
	}
);

export const mapFilterDtoToModel = (dto: AllergyIntoleranceCriticalityFilterDto): AllergyIntoleranceCriticalityFilterModel => (
	new AllergyIntoleranceCriticalityFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern
	)
);
