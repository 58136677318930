import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../../../../communication/implementation/shared/builders/http-client.builder';
import { ResetPasswordHttpClientConfiguration } from '../configurations/reset-password.rest.http-client.configuration';
import { ResetPasswordHttpClient } from '../services/reset-password.http-client';

export class ResetPasswordHttpClientBuilder
	extends HttpClientBuilder<ResetPasswordHttpClient, ResetPasswordHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): ResetPasswordHttpClient {
		return new ResetPasswordHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
