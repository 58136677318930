import { GeneticTestFormOutModel } from '../genetic-test-form/genetic-test-form.out-model';
import { GeneticTestResultCreateCommand } from './genetic-test-result.create.command';

export class GeneticTestCreateCommand {

	public readonly patientId: string;
	public readonly date: Date;
	public readonly geneId: string;
	public readonly geneticTestResults: Array<GeneticTestResultCreateCommand>;
	public readonly comment: string | undefined;
	public readonly certificateIssuer: string | undefined;
	public readonly certificateUid: string | undefined;

	private constructor(
		patientId: string,
		date: Date,
		geneId: string,
		geneticTestResults: Array<GeneticTestResultCreateCommand>,
		comment: string | undefined,
		certificateIssuer: string | undefined,
		certificateUid: string | undefined
	) {
		//TODO: Guard check on defined

		this.patientId = patientId;
		this.date = date;
		this.geneId = geneId;
		this.geneticTestResults = geneticTestResults;
		this.comment = comment;
		this.certificateIssuer = certificateIssuer;
		this.certificateUid = certificateUid;
	}

	public static createFromFormOutModel = (model: GeneticTestFormOutModel): GeneticTestCreateCommand => {
		// TODO: Guard.isDefined(model);

		return new GeneticTestCreateCommand(
			model.patientId,
			model.date,
			model.geneId,
			model.geneticTestResults.map(GeneticTestResultCreateCommand.createFromFormOutModel),
			model.comment,
			model.certificateIssuer,
			model.certificateUid
		);
	}

}
