import { UserSubscriptionDto, UserSubscriptionGetParameter, UserSubscriptionUpdateParameter } from '@nmn-communication/accounts';
import { SubscriptionComboboxDto } from '@nmn-communication/subscriptions';
import { isValueDefined, mapDateInServerFormatToDate } from '@nmn-core/utils';
import { UserSubscriptionGetCommand, UserSubscriptionModel, UserSubscriptionUpdateCommand } from '@nmn-domain/accounts';
import { SubscriptionComboboxModel, SubscriptionType } from '@nmn-domain/subscriptions';

export const mapUserSubscriptionDtoToUserSubscriptionModel = (
	dto: UserSubscriptionDto
): UserSubscriptionModel =>
	new UserSubscriptionModel(
		mapSubscriptionComboboxDtoToModel(dto.subscription),
		mapDateInServerFormatToDate(dto.dateFrom),
		mapDateInServerFormatToDate(dto.dateTo),
		dto.treatmentCheckersUsed,
		dto.treatmentCheckersAvailableTotal,
		dto.patientsUsed,
		dto.patientsAvailableTotal,
		dto.storageBytesUsed,
		dto.storageBytesAvailableTotal
	);

export const mapSubscriptionTypeFromString = (stringValue: string): SubscriptionType => {
	const enumValue = Object
		.keys(SubscriptionType)
		.find(x => SubscriptionType[x] === stringValue);

	return isValueDefined(enumValue) ? stringValue as SubscriptionType : SubscriptionType.Basic;
};

export const mapUpdateCommandToParameter = (
	command: UserSubscriptionUpdateCommand
): UserSubscriptionUpdateParameter => (
	{
		userId: command.userId,
		subscriptionId: command.subscriptionId,
		pricingPlanId: command.pricingPlanId
	}
);

export const mapGetCommandToParameter = (
	command: UserSubscriptionGetCommand
): UserSubscriptionGetParameter => (
	{
		userId: command.userId
	}
);

export const mapSubscriptionComboboxDtoToModel = (dto: SubscriptionComboboxDto): SubscriptionComboboxModel => (
	new SubscriptionComboboxModel(
		dto.id,
		dto.displayText,
		dto.description,
		mapSubscriptionTypeFromString(dto.id)
	)
);

