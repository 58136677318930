import { Injector } from '@angular/core';
import { TimezoneClient } from '@nmn-communication/timezones';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { TimezoneFakeClientBuilder } from '../builders/timezone.fake-client.builder';
import { TimezoneFakeClientConfigurationBuilder } from '../builders/timezone.fake-client.configuration.builder';
import { TimezoneHttpClientBuilder } from '../builders/timezone.http-client.builder';
import { TimezoneHttpClientConfigurationBuilder } from '../builders/timezone.http-client.configuration.builder';
import { TimezoneFakeClient } from '../services/timezone.fake-client';
import { TimezoneHttpClient } from '../services/timezone.http-client';

export const timezoneClientFactory = (injector: Injector): TimezoneClient => {
	const useFake = environment?.api?.resources?.timezones?.useFake ||
		!isValueDefined(environment?.api?.resources?.timezones?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		timezoneFakeClientFactory(injector) :
		timezoneHttpClientFactory(injector);
};

export const timezoneFakeClientFactory = (injector: Injector): TimezoneFakeClient => {
	const configuration = new TimezoneFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.timezones.serverDelayMs)
		.build();

	return new TimezoneFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const timezoneHttpClientFactory = (injector: Injector): TimezoneHttpClient => {
	const configuration = new TimezoneHttpClientConfigurationBuilder()
		.withBaseUrl(environment?.api?.resources?.timezones?.baseUrl ?? environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.timezones.resourceRelativePath)
		.withResourceTypeVersion(environment?.api?.resources?.timezones?.resourceTypeVersion ?? environment?.api?.defaultResourceTypeVersion)
		.build();

	return new TimezoneHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
