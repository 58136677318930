import { isValueDefined } from '@nmn-core/utils';
import { NmnMatTableUserConfiguration } from '../component-configurations/nmn-mat-table-user.configuration';

export class DocumentsPageConfiguration {

	public readonly documentsTableUserConfiguration: NmnMatTableUserConfiguration;

	protected constructor(
		documentsTableUserConfiguration: NmnMatTableUserConfiguration
	) {
		this.documentsTableUserConfiguration = documentsTableUserConfiguration;
	}

	public static createWithParameters(config?: DocumentsPageConfiguration): DocumentsPageConfiguration {
		if (!isValueDefined(config)) {
			DocumentsPageConfiguration.createDefault();
		}

		return new DocumentsPageConfiguration(
			isValueDefined(config.documentsTableUserConfiguration) ?
				NmnMatTableUserConfiguration.createWithParameters(config.documentsTableUserConfiguration) :
				NmnMatTableUserConfiguration.createDefault()
		);
	}

	public static createDefault(): DocumentsPageConfiguration {
		return new DocumentsPageConfiguration(NmnMatTableUserConfiguration.createWithParameters(new NmnMatTableUserConfiguration(undefined, undefined, undefined, 'desc', 'lastModifiedOn')));
	}

}
