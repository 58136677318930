export class TreatmentCheckerReportGetQuery {

	public readonly id?: string;
	public readonly organizationId?: string;

	private constructor(
		id: string | undefined,
		organizationId: string | undefined
	) {
		this.id = id;
		this.organizationId = organizationId;
	}

	public static createByReportId(id: string): TreatmentCheckerReportGetQuery {
		// TODO: Guard
		return new TreatmentCheckerReportGetQuery(id, undefined);
	}

	public static createByReportIdAndOrganizationId(id: string, organizationId: string): TreatmentCheckerReportGetQuery {
		// TODO: Guard
		return new TreatmentCheckerReportGetQuery(id, organizationId);
	}

}
