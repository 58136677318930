import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { ComboboxModel } from './combobox.model';

export class DescribedComboboxModel extends ComboboxModel {

	public readonly description: string;

	public get isDescriptionDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.description);
	}

	public constructor(
		id: string,
		displayText: string,
		description: string
	) {
		super(id, displayText);

		this.description = description;
	}

}
