import { isValueDefined } from '@nmn-core/utils';

export enum TreatmentCheckerReportMedicationPropertyDrugType {

	Undefined = 'undefined',

	Drug = 'drug',
	DietarySupplement = 'dietarySupplement',
	MedicalDevice = 'medicalDevice',
	Cosmetic = 'cosmetic',
	ChildFood = 'childFood',
	ClinicalNutrition = 'clinicalNutrition'

}

export const mapTreatmentCheckerReportMedicationPropertyDrugTypeFromString = (
	stringValue: string
): TreatmentCheckerReportMedicationPropertyDrugType => {
	const enumValue = Object
		.keys(TreatmentCheckerReportMedicationPropertyDrugType)
		.find(x => TreatmentCheckerReportMedicationPropertyDrugType[x] === stringValue);

	return isValueDefined(enumValue) ?
		stringValue as TreatmentCheckerReportMedicationPropertyDrugType :
		TreatmentCheckerReportMedicationPropertyDrugType.Undefined;
};
