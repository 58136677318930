import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserProfileModel } from '../models/user-profile.model';

export abstract class UserProfileResolver implements Resolve<UserProfileModel> {

	public abstract resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<UserProfileModel> | Promise<UserProfileModel> | UserProfileModel;

}
