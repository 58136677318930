export { TakenMedicationCreateParameterDto } from './models/parameters/taken-medication-create-parameter.dto';
export { TakenMedicationDeleteBulkParameterDto } from './models/parameters/taken-medication-delete-bulk-parameter.dto';
export { TakenMedicationFailCourseParameterDto } from './models/parameters/taken-medication-fail-course-parameter.dto';
export { TakenMedicationFindParameterDto } from './models/parameters/taken-medication-find-parameter.dto';
export { TakenMedicationFinishCourseBulkParameterDto } from './models/parameters/taken-medication-finish-course-bulk-parameter.dto';
export { TakenMedicationFinishCourseParameterDto } from './models/parameters/taken-medication-finish-course-parameter.dto';
export { TakenMedicationUpdateBulkParameterDto } from './models/parameters/taken-medication-update-bulk-parameter.dto';
export { TakenMedicationUpdateParameterDto } from './models/parameters/taken-medication-update-parameter.dto';
export { TakenMedicationComboboxDto } from './models/taken-medication-described-combobox.dto';
export { TakenMedicationFilterDto } from './models/taken-medication-filter.dto';
export { TakenMedicationDto } from './models/taken-medication.dto';
export { TakenMedicationClient } from './services/taken-medication.client';
