import { TranslocoService } from '@ngneat/transloco';
import { ComboboxDto } from '@nmn-communication/shared';
import { isValueDefined } from '@nmn-core/utils';
import { setTranslation } from '../../utils/localize';
import { FakeLocalizableEntity } from '../fake-localizable-entity';

export interface FakeComboboxDto<TKey> {

	id: TKey;
	displayText: FakeLocalizableEntity;

}

export const mapFakeComboboxDtoToComboboxDto = (
	translocoService: TranslocoService,
	fakeComboboxDto: FakeComboboxDto<string>
): ComboboxDto<string> => {
	if (!isValueDefined(fakeComboboxDto)) {
		return undefined;
	}

	return {
		id: fakeComboboxDto.id,
		displayText: setTranslation(translocoService, fakeComboboxDto.displayText)
	};
};
