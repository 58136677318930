import { PatientTreatmentCheckerFormOutModel } from '../patient-treatment-checker-form/patient-treatment-checker-form.out-model';

export class PatientTreatmentCheckerAnalyzeCommand {

	public readonly patientId: string;
	public readonly healthIssueIds: Array<string>;
	public readonly doctorEncounterIds: Array<string>;
	public readonly takenMedicationIds: Array<string>;
	public readonly diseaseIds: Array<string>;
	public readonly medicationIds: Array<string>;

	constructor(
		patientId: string,
		healthIssueIds: Array<string>,
		doctorEncounterIds: Array<string>,
		takenMedicationIds: Array<string>,
		diseaseIds: Array<string>,
		medicationIds: Array<string>,
	) {
		// TODO: check on defined
		this.patientId = patientId;
		this.healthIssueIds = healthIssueIds;
		this.doctorEncounterIds = doctorEncounterIds;
		this.takenMedicationIds = takenMedicationIds;
		this.diseaseIds = diseaseIds;
		this.medicationIds = medicationIds;
	}

	public static createByPatientTreatmentCheckerFormOutModel(outModel: PatientTreatmentCheckerFormOutModel): PatientTreatmentCheckerAnalyzeCommand {
		// TODO: check on defined
		return new PatientTreatmentCheckerAnalyzeCommand(
			outModel.patientId,
			outModel.healthIssueIds,
			outModel.doctorEncounterIds,
			outModel.takenMedicationIds,
			outModel.diseaseIds,
			outModel.medicationIds
		);
	}

}
