import { UploadedFileDto, UploadedFileExtensionDto, UploadFileParameter } from '@nmn-communication/file-uploader';
import { isValueDefined, mapDateInServerFormatToDate, mapDateInServerFormatToDateSafely } from '@nmn-core/utils';
import { FileUploadCreateCommand, mapFileTypeFromString, mapIconTypeFromString, UploadedFileExtensionComboboxModel, UploadedFileModel } from '@nmn-domain/file-uploader';

export const mapUploadedFileDtoToModel = (dto: UploadedFileDto): UploadedFileModel => (
	new UploadedFileModel(
		dto.id,
		dto.downloadUrl,
		dto.fileName,
		isValueDefined(dto.fileExtension) ? mapUploadedFileExtensionDtoModel(dto.fileExtension) : undefined,
		dto.lengthInBytes,
		mapDateInServerFormatToDateSafely(dto.createdOn),
		mapDateInServerFormatToDate(dto.updatedOn),
		mapFileTypeFromString(dto.fileType))
);

export const mapUploadCreateCommandToUploadFileParameter = (
	command: FileUploadCreateCommand
): UploadFileParameter => (
	{
		file: command.file,
		fileType: command.fileType,
		extension: command.fileExtension,
		name: command.fileName,
		lengthInBytes: command.file?.size
	}
);

export const mapUploadedFileExtensionDtoModel = (
	fileExtension: UploadedFileExtensionDto
): UploadedFileExtensionComboboxModel => {
	return new UploadedFileExtensionComboboxModel(
		fileExtension.id,
		fileExtension.displayText,
		fileExtension.description,
		mapIconTypeFromString(fileExtension.fileBlobIconType));
};
