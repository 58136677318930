import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { NavigationResource } from '@nmn-middleware/navigation';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, filter, map, switchMap, timeout } from 'rxjs/operators';
import { StorageService } from '../../../services';

/**
 * Is used to check on required permissions.
 * Required permissions should be declaratively defined in route 'data' with name 'requiredPermissions'.
 */
@Injectable({ providedIn: 'root' })
export class RequirePermissionsGuard implements CanActivate, CanActivateChild {

	constructor(
		private readonly router: Router,
		private readonly storageService: StorageService
	) { }

	public canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
		return this.storageService.isUserLoggedIn$.pipe(
			switchMap(isLoggedIn => {
				if (isLoggedIn) {
					return combineLatest([this.storageService.isUserInfoLoading$, this.storageService.tokenInfo$])
						.pipe(
							filter(([isUserInfoLoading, tokenInfo]) => !isUserInfoLoading && tokenInfo.hasPermissions(route.data.requiredPermissions)),
							map(() => true)
						);
				}
				return of(this.router.createUrlTree([NavigationResource.Login]));
			}),
			catchError(() => of(this.router.createUrlTree([NavigationResource.Login]))));
	}

	public canActivateChild(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> | boolean | UrlTree {
		return this.canActivate(route);
	}

}
