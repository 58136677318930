import { isArrayDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';

export enum GeneticTestCreationTypeEnum {

	Manual = 'manual',
	GeneticFile = 'geneticFile'

}

export const mapGeneticTestCreationTypeEnumFromString = (stringValue: string): GeneticTestCreationTypeEnum => {
	const enumValue = Object
		.keys(GeneticTestCreationTypeEnum)
		.find(x => GeneticTestCreationTypeEnum[x] === stringValue);

	return isValueDefined(enumValue) ? stringValue as GeneticTestCreationTypeEnum : GeneticTestCreationTypeEnum.Manual;
};

export const mapGeneticTestCreationTypeEnumsFromStringArray = (stringValues: Array<string>): Array<GeneticTestCreationTypeEnum> | undefined => {
	return isArrayDefinedAndNotEmpty(stringValues) ? stringValues.map(mapGeneticTestCreationTypeEnumFromString) : undefined;
};
