import { Injectable } from '@angular/core';
import { Result } from '@nmn-core/shared';
import { isArrayDefined, isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { LocaleComboboxModel, LocaleFilterModel, LocaleQueryHandlerService } from '@nmn-domain/locales';
import { FailureModel } from '@nmn-domain/shared';
import { FailureLocalizationModel } from '@nmn-domain/shared/failures/failure-localization-parameters.model';
import { FailureWrapperModel } from '@nmn-domain/shared/failures/failure-wrapper.model';
import { availableLanguages } from '@nmn-vendor/transloco';
import { Observable, of } from 'rxjs';

@Injectable()
export class LocaleQueryHandlerViaConstantService extends LocaleQueryHandlerService {

	public getAsComboboxes(filter: LocaleFilterModel): Observable<Result<Array<LocaleComboboxModel>, FailureModel>> {
		try {
			const filteredComboboxes = this.getAsComboboxesViaConstant()
				.filter(
					item =>
						(!isStringDefinedAndNotEmpty(filter.searchPattern) || item.displayText.includes(filter.searchPattern)) &&
						(!isArrayDefined(filter.ids) || filter.ids.some(id => id === item.id)) &&
						(!isArrayDefined(filter.ignoreIds) || filter.ignoreIds.every(id => id !== item.id))
				);

			return of(Result.success<Array<LocaleComboboxModel>, FailureModel>(filteredComboboxes));
		} catch (error) {
			return of(Result.failure<Array<LocaleComboboxModel>, FailureModel>(FailureModel.createForUndefinedIssue(FailureWrapperModel.createFromValue(error), FailureLocalizationModel.createEmpty())));
		}
	}

	private getAsComboboxesViaConstant(): Array<LocaleComboboxModel> {
		return availableLanguages.map(item => new LocaleComboboxModel(item.value, item.displayText));
	}

}
