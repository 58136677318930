import { Injector } from '@angular/core';
import { EmailConfirmationClient } from '@nmn-communication/accounts';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { EmailConfirmationFakeClientBuilder } from '../builders/email-confirmation.fake-client.builder';
import { EmailConfirmationFakeClientConfigurationBuilder } from '../builders/email-confirmation.fake-client.configuration.builder';
import { EmailConfirmationHttpClientBuilder } from '../builders/email-confirmation.http-client.builder';
import { EmailConfirmationHttpClientConfigurationBuilder } from '../builders/email-confirmation.http-client.configuration.builder';
import { EmailConfirmationFakeClient } from '../services/email-confirmation.fake-client';
import { EmailConfirmationHttpClient } from '../services/email-confirmation.http-client';

export const emailConfirmationClientFactory = (
	injector: Injector
): EmailConfirmationClient => {
	const useFake = environment?.api?.resources?.accounts?.emailConfirmation?.useFake ||
		!isValueDefined(environment?.api?.resources?.accounts?.emailConfirmation?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		emailConfirmationFakeClientFactory(injector) :
		emailConfirmationHttpClientFactory(injector);
};

export const emailConfirmationFakeClientFactory = (
	injector: Injector
): EmailConfirmationFakeClient => {
	const configuration = new EmailConfirmationFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.accounts.emailConfirmation.serverDelayMs)
		.build();

	return new EmailConfirmationFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const emailConfirmationHttpClientFactory = (
	injector: Injector
): EmailConfirmationHttpClient => {
	const configuration = new EmailConfirmationHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.accounts?.emailConfirmation?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.accounts.emailConfirmation.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.accounts?.emailConfirmation?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new EmailConfirmationHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
