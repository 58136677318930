import { isValueDefined } from '@nmn-core/utils';
import { DocumentFakeClientConfiguration } from '../configurations/document.fake-client.configuration';

export class DocumentFakeClientConfigurationBuilder {

	private readonly serverDelayMs: { min: number, max: number } = { min: 0, max: 0 };

	public withServerDelay(
		serverDelayMs: { min: number, max: number }
	): DocumentFakeClientConfigurationBuilder {

		if (isValueDefined(serverDelayMs) &&
			isValueDefined(serverDelayMs.min) &&
			isValueDefined(serverDelayMs.max) &&
			serverDelayMs.min <= serverDelayMs.max) {

			this.serverDelayMs.min = serverDelayMs.min;
			this.serverDelayMs.max = serverDelayMs.max;

		}

		return this;

	}

	public build(): DocumentFakeClientConfiguration {

		return new DocumentFakeClientConfiguration(this.serverDelayMs);

	}

}
