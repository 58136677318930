import { GoogleAnalyticsEvent } from '../../models/google-analytics-event.enum';

export class GaEventTrackingPatientService {

	constructor(
		private readonly trackEvent: (event: GoogleAnalyticsEvent, data?: any, completeFn?: () => void) => void
	) { }

	public patientCreated(completeFn?: () => void): void {
		this.trackEvent(GoogleAnalyticsEvent.PatientCreation, {}, completeFn);
	}

	public patientDeletionRequested(completeFn?: () => void): void {
		this.trackEvent(GoogleAnalyticsEvent.PatientDeletionRequested, {}, completeFn);
	}

	public patientDeletionRevoked(completeFn?: () => void): void {
		this.trackEvent(GoogleAnalyticsEvent.PatientDeletionRevoked, {}, completeFn);
	}

}
