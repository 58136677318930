import { PopulationComboboxDto, PopulationFilterDto } from '@nmn-communication/populations';
import { PopulationComboboxModel, PopulationFilterModel } from '@nmn-domain/populations';

export const mapPopulationFilterModelToDto = (model: PopulationFilterModel): PopulationFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern
	}
);

export const mapPopulationFilterDtoToModel = (dto: PopulationFilterDto): PopulationFilterModel => (
	new PopulationFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern
	)
);

export const mapPopulationComboboxDtoToModel = (dto: PopulationComboboxDto): PopulationComboboxModel => (
	new PopulationComboboxModel(
		dto.id,
		dto.displayText
	)
);
