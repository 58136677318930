import { TranslocoService } from '@ngneat/transloco';
import { DescribedComboboxDto } from '@nmn-communication/shared';
import { isValueDefined } from '@nmn-core/utils';
import { setTranslation } from '../../utils/localize';
import { FakeLocalizableEntity } from '../fake-localizable-entity';

export interface FakeDescribedComboboxDto<TKey> {

	id: TKey;
	displayText: FakeLocalizableEntity;
	description?: FakeLocalizableEntity;
}

export const mapFakeDescriptedComboboxDtoToDesriptedComboboxDto = <T>(
	translocoService: TranslocoService,
	fakeDescribedComboboxDto: FakeDescribedComboboxDto<T>
): DescribedComboboxDto<T> => {
	if (!isValueDefined(fakeDescribedComboboxDto)) {
		return undefined;
	}

	return {
		id: fakeDescribedComboboxDto.id,
		displayText: setTranslation(translocoService, fakeDescribedComboboxDto.displayText),
		description: setTranslation(translocoService, fakeDescribedComboboxDto.description)
	};
};
