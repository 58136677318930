import { Injectable } from '@angular/core';
import { ResetPasswordClient } from '@nmn-communication/accounts';
import { Result } from '@nmn-core/shared';
import { CompleteResetPasswordCommand, InitiateResetPasswordCommand, ResetPasswordCommandHandlerService } from '@nmn-domain/accounts';
import { EmptyCommandResult, FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../../../../domain/implementations/shared/factories/failure-handling.factory';
import { mapCompleteResetPasswordCommandToParameter, mapInitiateResetPasswordCommandToParameter } from '../factories/reset-password.factory';

@Injectable()
export class ResetPasswordCommandHandlerViaClientService extends ResetPasswordCommandHandlerService {

	constructor(
		private readonly client: ResetPasswordClient
	) {
		super();
	}

	public completeResetPassword(command: CompleteResetPasswordCommand): Observable<Result<EmptyCommandResult<CompleteResetPasswordCommand>, FailureModel>> {
		return this.client
			.completeResetPassword(mapCompleteResetPasswordCommandToParameter(command))
			.pipe(map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command))));
	}

	public initiateResetPassword(command: InitiateResetPasswordCommand): Observable<Result<EmptyCommandResult<InitiateResetPasswordCommand>, FailureModel>> {
		return this.client
			.initiateResetPassword(mapInitiateResetPasswordCommandToParameter(command))
			.pipe(map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command))));
	}

}
