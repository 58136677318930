import { isValueDefined } from '@nmn-core/utils';
import { notProvided } from '../constants';
import { BusinessRestrictionsConfiguration } from './configurations/business-restrictions.configuration';
import { FileUploadConfiguration } from './configurations/file-upload.configuration';
import { GoogleServicesConfiguration } from './configurations/google-services.configuration.model';
import { FacebookServicesConfiguration } from './configurations/facebook-services.configuration.model';
import { StorageServicesConfiguration } from './configurations/storage-services.configuration';

export class ServerConfigurationModel {

	public readonly serverVersion: string;
	public readonly serverEnvironment: string;
	public readonly googleServices: GoogleServicesConfiguration;
	public readonly facebookServices: FacebookServicesConfiguration;
	public readonly storageServices: StorageServicesConfiguration;

	public readonly fileUpload: FileUploadConfiguration;

	public readonly businessRestrictions: BusinessRestrictionsConfiguration;

	public constructor(
		serverVersion: string,
		serverEnvironment: string,
		googleServices: GoogleServicesConfiguration,
		facebookServices: FacebookServicesConfiguration,
		storageServices: StorageServicesConfiguration,
		fileUpload: FileUploadConfiguration,
		businessRestrictions: BusinessRestrictionsConfiguration
	) {
		isValueDefined(serverVersion) ?
			this.serverVersion = serverVersion :
			this.serverVersion = notProvided;

		isValueDefined(serverEnvironment) ?
			this.serverEnvironment = serverEnvironment :
			this.serverEnvironment = notProvided;

		isValueDefined(googleServices) ?
			this.googleServices = googleServices :
			this.googleServices = GoogleServicesConfiguration.createDefault();

		isValueDefined(facebookServices) ?
			this.facebookServices = facebookServices :
			this.facebookServices = FacebookServicesConfiguration.createDefault();

		isValueDefined(storageServices) ?
			this.storageServices = storageServices :
			this.storageServices = StorageServicesConfiguration.createDefault();

		isValueDefined(fileUpload) ?
			this.fileUpload = fileUpload :
			this.fileUpload = FileUploadConfiguration.createDefault();

		isValueDefined(businessRestrictions) ?
			this.businessRestrictions = businessRestrictions :
			this.businessRestrictions = BusinessRestrictionsConfiguration.createDefault();
	}

	public static createDefault(): ServerConfigurationModel {
		return new ServerConfigurationModel(
			notProvided,
			notProvided,
			GoogleServicesConfiguration.createDefault(),
			FacebookServicesConfiguration.createDefault(),
			StorageServicesConfiguration.createDefault(),
			FileUploadConfiguration.createDefault(),
			BusinessRestrictionsConfiguration.createDefault()
		);
	}

}
