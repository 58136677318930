import { isValueDefined } from '@nmn-core/utils';
import { LoginFakeClientConfiguration } from '../configurations/login.fake-client.configuration';

export class LoginFakeClientConfigurationBuilder {

	private readonly serverDelayMs: { min: number, max: number } = { min: 0, max: 0 };

	public withServerDelay(
		serverDelayMs: { min: number, max: number }
	): LoginFakeClientConfigurationBuilder {
		if (
			isValueDefined(serverDelayMs) &&
			isValueDefined(serverDelayMs.min) &&
			isValueDefined(serverDelayMs.max) &&
			serverDelayMs.min <= serverDelayMs.max
		) {
			this.serverDelayMs.min = serverDelayMs.min;
			this.serverDelayMs.max = serverDelayMs.max;
		}

		return this;
	}

	public build(): LoginFakeClientConfiguration {
		return new LoginFakeClientConfiguration(this.serverDelayMs);
	}

}
