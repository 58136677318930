export const environment = {
	version: '0.8.24357.1.20240831.1',
	production: true,
	enableProfiling: true,
	snackbarSettings: {
		defaultHorizontalPosition: 'end',
		defaultVerticalPosition: 'top',
		durationMs: 3000,
		longDurationMs: 8000
	},
	api: {
		defaultUseFake: false,
		defaultBaseUrl: undefined,
		defaultResourceTypeVersion: '1.0',
		resources: {
			serverConfiguration: {
				useFake: undefined,
				serverDelayMs: {
					min: 400,
					max: 1000
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/configuration/frontend',
				resourceTypeVersion: '1.0'
			},
			accounts: {
				emailConfirmation: {
					useFake: undefined,
					serverDelayMs: {
						min: 400,
						max: 1000
					},
					baseUrl: undefined,
					resourceRelativePath: 'api/userAccounts/emailConfirmation',
					resourceTypeVersion: '1.0'
				},
				login: {
					useFake: undefined,
					serverDelayMs: {
						min: 400,
						max: 1000
					},
					baseUrl: undefined,
					resourceRelativePath: 'api/userAccounts',
					resourceTypeVersion: '1.0'
				},
				registration: {
					useFake: undefined,
					serverDelayMs: {
						min: 400,
						max: 1000
					},
					baseUrl: undefined,
					resourceRelativePath: 'api/userAccounts/internal/signUp',
					resourceTypeVersion: '1.0'
				},
				resetPassword: {
					useFake: undefined,
					serverDelayMs: {
						min: 400,
						max: 1000
					},
					baseUrl: undefined,
					resourceRelativePath: 'api/userAccounts',
					resourceTypeVersion: '1.0'
				},
				userConfiguration: {
					useFake: undefined,
					serverDelayMs: {
						min: 200,
						max: 500
					},
					baseUrl: undefined,
					resourceRelativePath: 'api/userAccounts/configuration/appclient',
					resourceTypeVersion: '1.0'
				},
				userProfile: {
					useFake: undefined,
					serverDelayMs: {
						min: 400,
						max: 1000
					},
					baseUrl: undefined,
					resourceRelativePath: 'api/userAccounts/userInfo',
					resourceTypeVersion: '1.0'
				},
				userSubscription: {
					useFake: undefined,
					serverDelayMs: {
						min: 400,
						max: 600
					},
					baseUrl: undefined,
					resourceRelativePath: 'api/userAccounts/subscriptions',
					resourceTypeVersion: '1.0'
				}
			},
			allergies: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/allergies/combobox',
				resourceTypeVersion: '1.0'
			},
			allergyIntoleranceCriticalities: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/allergyIntoleranceCriticalities/combobox',
				resourceTypeVersion: '1.0'
			},
			allergyIntoleranceStatuses: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/allergyIntoleranceClinicalStatuses/combobox',
				resourceTypeVersion: '1.0'
			},
			allergyIntoleranceVerificationStatuses: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/allergyIntoleranceVerificationStatuses/combobox',
				resourceTypeVersion: '1.0'
			},
			attachmentTypes: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: '/attachmentTypes',
				resourceTypeVersion: '1.0'
			},
			azureStorage: {
				useFake: undefined
			},
			bloodTypes: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/bloodTypes/combobox',
				resourceTypeVersion: '1.0'
			},
			contacts: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/users/contactforms',
				resourceTypeVersion: '1.0'
			},
			diseases: {
				useFake: undefined,
				serverDelayMs: {
					min: 400,
					max: 800
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/diseases/combobox',
				resourceTypeVersion: '1.0'
			},
			diseaseStatuses: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/diseaseStatuses/combobox',
				resourceTypeVersion: '1.0'
			},
			documents: {
				useFake: undefined,
				serverDelayMs: {
					min: 400,
					max: 1000
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/documents',
				resourceTypeVersion: '1.0'
			},
			documentTypes: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/documentTypes/combobox',
				resourceTypeVersion: '1.0'
			},
			doctorEncounters: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/patients/{patientId}/encounters',
				resourceTypeVersion: '1.0'
			},
			emergencyCard: {
				useFake: undefined,
				serverDelayMs: {
					min: 400,
					max: 1000
				},
				baseUrl: undefined,
				resourceRelativePath: '',
				resourceTypeVersion: '1.0'
			},
			fileUploader: {
				useFake: undefined,
				serverDelayMs: {
					min: 400,
					max: 1000
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/files',
				resourceTypeVersion: '1.0'
			},
			genders: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/genderTypes/combobox',
				resourceTypeVersion: '1.0'
			},
			geneticFileParseRequests: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/patients/{patientId}/geneticFileParseRequests',
				resourceTypeVersion: '1.0'
			},
			genes: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/genes/combobox',
				resourceTypeVersion: '1.0'
			},
			geneVariants: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/geneVariants/combobox',
				resourceTypeVersion: '1.0'
			},
			geneticTests: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/patients/{patientId}/geneticTests',
				resourceTypeVersion: '1.0'
			},
			healthComplaints: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/healthComplaints/combobox',
				resourceTypeVersion: '1.0'
			},
			healthIssues: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/patients/{patientId}/healthIssues',
				resourceTypeVersion: '1.0'
			},
			healthIssueSeverities: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/healthIssueSeverities/combobox',
				resourceTypeVersion: '1.0'
			},
			languages: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/languages/combobox',
				resourceTypeVersion: '1.0'
			},
			medicationDosageIntakePeriods: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: '/medicationDosageIntakePeriods',
				resourceTypeVersion: '1.0'
			},
			medicationDosageIntakeTypes: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: '/medicationDosageIntakeTypes',
				resourceTypeVersion: '1.0'
			},
			medicationAdmRoutes: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/medicationAdmRoutes/combobox',
				resourceTypeVersion: '1.0'
			},
			medicationAdmStatuses: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/medicationAdmStatuses/combobox',
				resourceTypeVersion: '1.0'
			},
			medicationBodySites: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/medicationBodySites/combobox',
				resourceTypeVersion: '1.0'
			},
			medicationDosageTypes: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/medicationDosages/combobox',
				resourceTypeVersion: '1.0'
			},
			medicationRateQuantityTypes: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/medicationRateQuantityTypes/combobox',
				resourceTypeVersion: '1.0'
			},
			medications: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/medications/combobox',
				resourceTypeVersion: '1.0'
			},
			patients: {
				useFake: undefined,
				serverDelayMs: {
					min: 400,
					max: 1000
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/patients',
				resourceTypeVersion: '1.0'
			},
			population: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/population/combobox',
				resourceTypeVersion: '1.0'
			},
			regions: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/countries/combobox',
				resourceTypeVersion: '1.0'
			},
			takenMedications: {
				useFake: undefined,
				serverDelayMs: {
					min: 400,
					max: 1000
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/patients/{patientId}/takenMedications',
				resourceTypeVersion: '1.0'
			},
			timezones: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/timeZones/combobox',
				resourceTypeVersion: '1.0'
			},
			treatmentChecker: {
				useFake: undefined,
				serverDelayMs: {
					min: 1500,
					max: 3000
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/treatmentChecker',
				resourceTypeVersion: '1.0'
			},
			typesOfMedication: {
				useFake: undefined,
				serverDelayMs: {
					min: 200,
					max: 500
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/typeOfTakenMedications/combobox',
				resourceTypeVersion: '1.0'
			},
			vaccination: {
				useFake: undefined,
				serverDelayMs: {
					min: 400,
					max: 1000
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/patients/{patientId}/vaccinations',
				resourceTypeVersion: '1.0'
			},
			vaccines: {
				useFake: undefined,
				serverDelayMs: {
					min: 400,
					max: 800
				},
				baseUrl: undefined,
				resourceRelativePath: 'api/tags/vaccineCommercials/combobox',
				resourceTypeVersion: '1.0'
			}
		}
	}
};

// eslint-disable-next-line max-lines
