import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DescribedComboboxModel } from '../../shared';

export abstract class BloodTypesResolver implements Resolve<Array<DescribedComboboxModel>> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	): Observable<Array<DescribedComboboxModel>> | Array<DescribedComboboxModel>;

}
