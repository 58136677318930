import { Injector, Provider } from '@angular/core';
import { RegionClient } from '@nmn-communication/regions';
import { regionClientFactory } from './factories/region.client.factory';

export const regionProviders: Array<Provider> = [
	{
		provide: RegionClient,
		useFactory: regionClientFactory,
		deps: [Injector]
	}
];
