import { Failure } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { UserConfigurationDto } from '../models/user-configuration.dto';
import { UserConfigurationGetParameter } from '../models/user-configuration.get-parameter';
import { UserConfigurationUpdateParameter } from '../models/user-configuration.update-parameter';

export abstract class CurrentUserConfigurationClient {

	public abstract get(parameter: UserConfigurationGetParameter): Observable<Result<UserConfigurationDto, Failure>>;

	public abstract update(parameter: UserConfigurationUpdateParameter): Observable<EmptyResult<Failure>>;

}
