import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { AllergyIntoleranceVerificationStatusHttpClientConfiguration } from '../configurations/allergy-intolerance-verification-status.http-client.configuration';

export class AllergyIntoleranceVerificationStatusHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<AllergyIntoleranceVerificationStatusHttpClientConfiguration> {

	public build(): AllergyIntoleranceVerificationStatusHttpClientConfiguration {
		return new AllergyIntoleranceVerificationStatusHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
