import { OperatorFunction, pipe } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { isValueDefined } from './value.utils';

export const debounceAutocomplete = <T>(timeout = 300) => pipe(
	debounceTime<T>(timeout)
);

export const debounceForSyncAutocomplete = <T>(timeout = 100) => pipe(
	debounceTime<T>(timeout)
);

export const debounceInput = <T>(timeout = 300) => pipe(
	debounceTime<T>(timeout),
	distinctUntilChanged()
);

export const debounceConfigChanges = <T>(timeout = 300) => pipe(
	debounceTime<T>(timeout)
);

export const debounceUserInfoRefresh = <T>(timeout = 300) => pipe(
	debounceTime<T>(timeout)
);

export const debounceSearch = <T>(timeout = 300) => pipe(
	debounceTime<T>(timeout)
);

export const filterDefined = <T>(): OperatorFunction<T | undefined | null, T> => {
	return filter<T>(value => isValueDefined(value));
};

export const filterTrue = (): OperatorFunction<boolean | undefined | null, boolean> => {
	return filter<boolean>(value => value === true);
};
