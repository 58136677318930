import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Failure, PageOptionsDto, PagedCollectionDto } from '@nmn-communication/shared';
import {
	TakenMedicationClient, TakenMedicationComboboxDto, TakenMedicationCreateParameterDto, TakenMedicationDeleteBulkParameterDto,
	TakenMedicationDto, TakenMedicationFailCourseParameterDto, TakenMedicationFilterDto, TakenMedicationFindParameterDto,
	TakenMedicationFinishCourseBulkParameterDto, TakenMedicationUpdateBulkParameterDto, TakenMedicationUpdateParameterDto
} from '@nmn-communication/taken-medications';
import { EmptyResult, Result } from '@nmn-core/shared';
import { constructHttpParams, injectParameters } from '@nmn-core/utils';
import { Observable } from 'rxjs';
import {
	createEmptyResultFromResponse, createFailedEmptyResultFromErrorResponse, createFailedResultFromErrorResponse, createResultFromResponse
} from '../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { TakenMedicationHttpClientConfiguration } from '../configurations/taken-medication-http-client.configuration';

export class TakenMedicationHttpClient extends TakenMedicationClient {

	constructor(
		private readonly configuration: TakenMedicationHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public getBulkAsComboboxes(
		findParameter: TakenMedicationFilterDto
	): Observable<Result<Array<TakenMedicationComboboxDto>, Failure>> {
		const url = `${this.configuration.baseUrl}/api/patients/${findParameter.patientIds[0]}/takenMedications/combobox`;
		const findParam = { ...findParameter };
		findParam.patientIds = undefined;
		const params = constructHttpParams(findParam);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<Array<TakenMedicationComboboxDto>>(url, { params, headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public getBulk(
		findParameter: PageOptionsDto<TakenMedicationFilterDto>
	): Observable<Result<PagedCollectionDto<TakenMedicationDto, TakenMedicationFilterDto>, Failure>> {
		const parameters = {
			patientId: findParameter.filter.patientIds[0]
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`,
			parameters);
		const params = constructHttpParams(findParameter);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<PagedCollectionDto<TakenMedicationDto, TakenMedicationFilterDto>>(url, { params, headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);

	}

	public get(parameter: TakenMedicationFindParameterDto): Observable<Result<TakenMedicationDto, Failure>> {
		const parameters = {
			patientId: parameter.patientId,
			key: parameter.id
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/{key}`,
			parameters);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<TakenMedicationDto>(url, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);

	}

	public create(
		createParameter: TakenMedicationCreateParameterDto
	): Observable<Result<string, Failure>> {
		const parameters = {
			patientId: createParameter.patientId
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`,
			parameters);
		const payload = createParameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post<string>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public update(
		findParameter: TakenMedicationFindParameterDto,
		updateParameter: TakenMedicationUpdateParameterDto
	): Observable<EmptyResult<Failure>> {
		const parameters = {
			patientId: findParameter.patientId,
			key: findParameter.id
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/{key}`,
			parameters);
		const payload = updateParameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put(url, payload, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

	public updateBulk(
		updateParameter: TakenMedicationUpdateBulkParameterDto
	): Observable<EmptyResult<Failure>> {
		const parameters = {
			patientId: updateParameter.patientId
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/bulk`,
			parameters);
		const payload = updateParameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put(url, payload, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

	public delete(
		updateParameter: TakenMedicationFindParameterDto
	): Observable<EmptyResult<Failure>> {
		const parameters = {
			patientId: updateParameter.patientId,
			key: updateParameter.id
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/{key}`,
			parameters);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.delete(url, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

	public deleteBulk(
		updateParameter: TakenMedicationDeleteBulkParameterDto
	): Observable<EmptyResult<Failure>> {
		const parameters = {
			patientId: updateParameter.patientId
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/bulk/delete`,
			parameters);
		const payload = updateParameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		// TODO: need to change to this.httpClient.delete
		return this.httpClient
			.put(url, payload, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

	public finishCourse(
		findParameter: TakenMedicationFindParameterDto
	): Observable<EmptyResult<Failure>> {
		const parameters = {
			patientId: findParameter.patientId,
			key: findParameter.id
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/{key}/finishCourse`,
			parameters);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put(url, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

	public finishCourceBulk(
		parameter: TakenMedicationFinishCourseBulkParameterDto
	): Observable<EmptyResult<Failure>> {
		const parameters = {
			patientId: parameter.patientId
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/finishCourse/bulk`,
			parameters);
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put(url, payload, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

	public failCourse(
		parameter: TakenMedicationFailCourseParameterDto
	): Observable<EmptyResult<Failure>> {
		const parameters = {
			patientId: parameter.patientId,
			key: parameter.id
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/{key}/failCourse`,
			parameters);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put(url, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

}
