import { Injector } from '@angular/core';
import { TakenMedicationClient } from '@nmn-communication/taken-medications';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { TakenMedicationFakeClientConfigurationBuilder } from '../builders/taken-medication-fake-client-configuration.builder';
import { TakenMedicationFakeClientBuilder } from '../builders/taken-medication-fake-client.builder';
import { TakenMedicationHttpClientConfigurationBuilder } from '../builders/taken-medication-http-client-configuration.builder';
import { TakenMedicationHttpClientBuilder } from '../builders/taken-medication-http-client.builder';
import { TakenMedicationFakeClient } from '../services/taken-medication.fake-client';
import { TakenMedicationHttpClient } from '../services/taken-medication.http-client';

export const takenMedicationClientFactory = (injector: Injector): TakenMedicationClient => {
	const useFake = environment?.api?.resources?.takenMedications?.useFake ||
		!isValueDefined(environment?.api?.resources?.takenMedications?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		takenMedicationFakeClientFactory(injector) :
		takenMedicationHttpClientFactory(injector);
};

export const takenMedicationFakeClientFactory = (injector: Injector): TakenMedicationFakeClient => {
	const configuration = new TakenMedicationFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.takenMedications.serverDelayMs)
		.build();

	return new TakenMedicationFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const takenMedicationHttpClientFactory = (injector: Injector): TakenMedicationHttpClient => {
	const configuration = new TakenMedicationHttpClientConfigurationBuilder()
		.withBaseUrl(environment?.api?.resources?.takenMedications?.baseUrl ?? environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.takenMedications.resourceRelativePath)
		.withResourceTypeVersion(environment?.api?.resources?.takenMedications?.resourceTypeVersion ?? environment?.api?.defaultResourceTypeVersion)
		.build();

	return new TakenMedicationHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
