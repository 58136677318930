import { Injector } from '@angular/core';
import { FakeDatabase } from '../../../../../modules/fakes/databases/fake.database';
import { isValueDefined } from '@nmn-core/utils';
import { RegistrationFakeClientConfiguration } from '../configurations/registration.fake-client.configuration';
import { RegistrationFakeClient } from '../services/registration.fake-client';

export class RegistrationFakeClientBuilder {

	private configuration: RegistrationFakeClientConfiguration;

	constructor(private readonly injector: Injector) { }

	public withConfiguration(
		configuration: RegistrationFakeClientConfiguration
	): RegistrationFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): RegistrationFakeClient {
		return new RegistrationFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
