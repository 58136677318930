import { isValueDefined } from '@nmn-core/utils';

export enum TreatmentCheckerReportInteractionType {

	Undefined = 'undefined',

	MedicationWithAlcohol = 'medicationWithAlcohol',
	MedicationWithMedication = 'medicationWithMedication',
	MedicationWithFood = 'medicationWithFood'

}

export const mapTreatmentCheckerReportInteractionTypeFromString = (
	stringValue: string
): TreatmentCheckerReportInteractionType => {
	const enumValue = Object
		.keys(TreatmentCheckerReportInteractionType)
		.find(x => TreatmentCheckerReportInteractionType[x] === stringValue);

	return isValueDefined(enumValue) ?
		stringValue as TreatmentCheckerReportInteractionType :
		TreatmentCheckerReportInteractionType.Undefined;
};
