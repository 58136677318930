import { Injector } from '@angular/core';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { isValueDefined } from '@nmn-core/utils';
import { HealthComplaintFakeClientConfiguration } from '../configurations/health-complaint.fake-client.configuration';
import { HealthComplaintFakeClient } from '../services/health-complaint.fake-client';

export class HealthComplaintFakeClientBuilder {

	private configuration: HealthComplaintFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(
		configuration: HealthComplaintFakeClientConfiguration
	): HealthComplaintFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): HealthComplaintFakeClient {
		return new HealthComplaintFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
