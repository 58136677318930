export class CompleteResetPasswordFormOutModel {

	public readonly newPassword: string;
	public readonly confirmNewPassword: string;

	public constructor(
		newPassword: string,
		confirmNewPassword: string
	) {
		this.newPassword = newPassword;
		this.confirmNewPassword = confirmNewPassword;
	}

}
