import { Provider } from '@angular/core';
import { ContactsCommandHandlerService, ContactsQueryHandlerService } from '@nmn-domain/contacts';
import { ContactsCommandHandlerViaClientService } from './services/contacts.command-handler-via-client.service';
import { ContactsQueryHandlerViaClientService } from './services/contacts.query-handler-via-client.service';

export const contactsProviders: Array<Provider> = [
	{
		provide: ContactsCommandHandlerService,
		useClass: ContactsCommandHandlerViaClientService
	},
	{
		provide: ContactsQueryHandlerService,
		useClass: ContactsQueryHandlerViaClientService
	}
];
