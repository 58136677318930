import { Injectable } from '@angular/core';
import { debounceUserInfoRefresh } from '@nmn-core/utils';
import { CurrentUserProfileQueryHandlerService } from '@nmn-domain/accounts/user-accounts/services/current-user-profile.query-handler.service';
import { FailureModel } from '@nmn-domain/shared';
import { FailureLocalizationModel } from '@nmn-domain/shared/failures/failure-localization-parameters.model';
import { Subject } from 'rxjs';
import { map, mergeMap, shareReplay } from 'rxjs/operators';
import { UserConfigurationStorageService } from '../configurations/user-configuration-storage.service';
import { updateSubscriptionsAfterLogin } from '../configurations/utils';
import { FailureHandlingService } from '../failure-handling/failure-handling.service';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class UserInfoManager {

	private readonly refreshUserInfoSubject$ = new Subject<RefreshUserInfoInModel>();

	public readonly refreshUserInfo$ = this.refreshUserInfoSubject$
		.asObservable()
		.pipe(
			debounceUserInfoRefresh(),
			shareReplay(1),
			mergeMap(
				(_: RefreshUserInfoInModel) => {
					return this.currentUserProfileQueryHandlerService.get();
				}
			),
			map(
				result => {
					result.consume(
						success => {
							updateSubscriptionsAfterLogin(success, this.storage, this.userConfigurationStorage);
						},
						error => { this.failureHandlingService.handleFailure(FailureModel.createFailureLocalizedViaExistingFailure(error, FailureLocalizationModel.createFromValue('shared.forms'))); }
					);
				}
			)
		);

	constructor(
		private readonly currentUserProfileQueryHandlerService: CurrentUserProfileQueryHandlerService,
		private readonly failureHandlingService: FailureHandlingService,
		private readonly storage: StorageService,
		private readonly userConfigurationStorage: UserConfigurationStorageService
	) {
		// Do not remove these subscriptions - they are used to keep subject alife

		this.refreshUserInfo$.subscribe();
	}

	public initiateRefreshUserInfo(): void {
		this.refreshUserInfoSubject$.next(new RefreshUserInfoInModel());
	}

}

export class RefreshUserInfoInModel {

}
