import { Injector, Provider } from '@angular/core';
import { TakenMedicationClient } from '@nmn-communication/taken-medications';
import { takenMedicationClientFactory } from './factories/taken-medication-client.factory';

export const takenMedicationProviders: Array<Provider> = [
	{
		provide: TakenMedicationClient,
		useFactory: takenMedicationClientFactory,
		deps: [Injector]
	}
];
