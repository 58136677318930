import { Injectable } from '@angular/core';
import { AzureStorageClientFactory } from '@nmn-communication/azure-file-storage';
import { isValueDefined } from '@nmn-core/utils';
import { FailureModel } from '@nmn-domain/shared';
import { FailureLocalizationModel } from '@nmn-domain/shared/failures/failure-localization-parameters.model';
import { FailureWrapperModel } from '@nmn-domain/shared/failures/failure-wrapper.model';
import { Observable, Subject } from 'rxjs';
import { mergeMap, shareReplay, take } from 'rxjs/operators';
import { ServerConfigurationStorageService } from '../configurations/server-configuration-storage.service';
import { FailureHandlingService } from '../failure-handling/failure-handling.service';
import { AzureStorageInModel } from './azure-storage.in-model';

// TODO: change scope after connection with userSettings api call.
@Injectable({ providedIn: 'root' })
export class AzureStorageServiceUploadService {

	public readonly initiatedUploadSubject$: Subject<AzureStorageInModel> = new Subject<AzureStorageInModel>();

	public readonly activeUploadProgressModelObservables: Map<string, Observable<AzureStorageProgressModel>> =
		new Map<string, Observable<AzureStorageProgressModel>>();

	public readonly activeUploadProgressModelSubjects: Map<string, Subject<AzureStorageProgressModel>> =
		new Map<string, Subject<AzureStorageProgressModel>>();

	public readonly uploadCompletionModelObservables: Map<string, Observable<UploadCompletionModel>> =
		new Map<string, Observable<UploadCompletionModel>>();

	public readonly uploadCompletionModelModelSubjects: Map<string, Subject<UploadCompletionModel>> =
		new Map<string, Subject<UploadCompletionModel>>();

	private readonly activeUploads: Map<string, AbortController> =
		new Map<string, AbortController>();

	constructor(
		private readonly serverConfigurationStorageService: ServerConfigurationStorageService,
		private readonly failureHandlingService: FailureHandlingService,
		private readonly azureStorageClientFactory: AzureStorageClientFactory
	) {
		this.initiatedUpload$.subscribe();
	}

	public readonly initiatedUpload$ = this.initiatedUploadSubject$
		.asObservable()
		.pipe(shareReplay(1));

	public addNewProgress(inModel: AzureStorageInModel): void {
		const subject$ = new Subject<AzureStorageProgressModel>();
		this.activeUploadProgressModelSubjects.set(inModel.blobId, subject$);
		this.activeUploadProgressModelObservables.set(
			inModel.blobId,
			subject$
				.asObservable()
				.pipe(shareReplay(1))
		);
		subject$.subscribe();
		this.initiatedUploadSubject$.next(inModel);
	}

	public getProgress(blobId: string): Observable<AzureStorageProgressModel> {
		return this.activeUploadProgressModelObservables.get(blobId);
	}

	public updateProgress(uploadModel: AzureStorageProgressModel): void {
		this.activeUploadProgressModelSubjects
			.get(uploadModel.blobId)
			?.next(uploadModel);
	}

	public deleteProgress(blobId: string): void {
		this.activeUploadProgressModelSubjects
			.get(blobId)
			?.next(undefined);
		this.activeUploadProgressModelSubjects
			.get(blobId)
			?.complete();
	}

	public getCompletion(blobId: string): Observable<UploadCompletionModel> {
		return this.uploadCompletionModelObservables.get(blobId);
	}

	public addNewCompletion(blobId: string): void {
		const subject$ = new Subject<UploadCompletionModel>();
		this.uploadCompletionModelModelSubjects.set(blobId, subject$);
		this.uploadCompletionModelObservables.set(
			blobId,
			subject$
				.asObservable()
				.pipe(shareReplay(1))
		);
		subject$.subscribe();
	}

	public updateCompletion(uploadModel: UploadCompletionModel): void {
		this.uploadCompletionModelModelSubjects
			.get(uploadModel.blobId)
			?.next(uploadModel);
	}

	public deleteCompletion(blobId: string): void {
		this.uploadCompletionModelModelSubjects
			.get(blobId)
			?.next(undefined);
		this.uploadCompletionModelModelSubjects
			.get(blobId)
			?.complete();
	}

	public initiateUpload(inModel: AzureStorageInModel): void {
		// TODO: Guard check on defined
		const abortController = new AbortController();
		this.activeUploads.set(inModel.blobId, abortController);

		// TODO: if change order of call addNewCompletion and addNewProgress.
		// Lead to error due to calling get completion before creating completing subject
		this.addNewCompletion(inModel.blobId);
		this.addNewProgress(inModel);

		this.serverConfigurationStorageService.serverConfiguration$
			.pipe(
				mergeMap(result => {
					const match = httpUrlRegExp.exec(inModel.blobUrl);
					if (!isValueDefined(match) || match.length < 9) {
						throw new Error('provided wrong storage url');
					}
					const sas = match[7];
					const container = match[5]?.replace('/', '');
					const blobName = match[6];

					return this.azureStorageClientFactory
						.create(
							{
								container,
								blobName,
								storageUrl: `https://${result.storageServices.storage}.blob.core.windows.net`,
								sasToken: sas
							}
						)
						.initBlobUpload(
							{
								blobId: inModel.blobId,
								file: inModel.file,
								abortSignal: abortController.signal,
								onProgress: event => {
									this.updateProgress(
										new AzureStorageProgressModel(
											inModel.blobId,
											Math.round(event.loadedBytes / inModel.file.size * 100)
										)
									);
								}
							}
						);
				}),
				take(1)
			)
			.subscribe(
				_ => { this.updateCompletion(new UploadCompletionModel(inModel.blobId, false)); },
				error => {
					this.updateCompletion(new UploadCompletionModel(inModel.blobId, true, error));
					this.failureHandlingService.handleFailure(FailureModel.createForSubscribtionIssue(FailureWrapperModel.createFromValue(error), FailureLocalizationModel.createFromValue('shared.forms')));
				}
			);
	}

	public cancelUpload(blobId: string): void {
		const progressSubject$ = this.activeUploadProgressModelSubjects.get(blobId);
		const uploadSubject$ = this.uploadCompletionModelModelSubjects.get(blobId);
		const abortController = this.activeUploads.get(blobId);

		if (isValueDefined(progressSubject$)) {
			progressSubject$.next(undefined);
			progressSubject$.complete();
			this.activeUploadProgressModelSubjects.delete(blobId);
		}

		if (isValueDefined(uploadSubject$)) {
			uploadSubject$.next(undefined);
			uploadSubject$.complete();
			this.uploadCompletionModelModelSubjects.delete(blobId);
		}

		if (isValueDefined(abortController)) {
			abortController.abort();
		}
	}

}

export class AzureStorageProgressModel {

	public readonly blobId: string;
	public readonly progress: number;

	constructor(
		blobId: string,
		progress: number
	) {
		this.blobId = blobId;
		this.progress = progress;
	}

	public isFinished(): boolean {
		return this.progress === 100;
	}

}

export class UploadCompletionModel {

	public readonly blobId: string;
	public readonly error: boolean;
	public readonly errorText: string;
	public readonly errorKey?: string;

	constructor(
		blobId: string,
		error: boolean,
		errorText?: string,
		errorKey?: string
	) {
		this.blobId = blobId;
		this.error = error;
		this.errorText = errorText;
		this.errorKey = errorKey;
	}

}

export const httpUrlRegExp = new RegExp('^((http[s]?|ftp):\\/)?\\/?([^\\/]+)((\\/\\w+)*\\/)([^\\/?]+\\/[^\\/?]+)(.*)?(#[\\w\\-]+)?$');
