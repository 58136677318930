export class ApplicationThemeModel {

	public readonly alias: string;
	public readonly displayTextKey: string;
	public readonly descriptionKey: string;
	public readonly type: 'light' | 'dark';

	constructor(
		alias: string,
		displayTextKey: string,
		descriptionKey: string,
		type: 'light' | 'dark'
	) {
		// TODO: Guard is defined
		this.alias = alias;
		this.displayTextKey = displayTextKey;
		this.descriptionKey = descriptionKey;
		this.type = type;
	}

}
