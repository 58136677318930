import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { PopulationHttpClientConfiguration } from '../configurations/population.http-client.configuration';

export class PopulationHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<PopulationHttpClientConfiguration> {

	public build(): PopulationHttpClientConfiguration {
		return new PopulationHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion
		);
	}

}
