import { Provider } from '@angular/core';
import { TakenMedicationCommandHandlerService, TakenMedicationQueryHandlerService, TakenMedicationResolver } from '@nmn-domain/taken-medications';
import { TakenMedicationViaQueryHandlerResolver } from './resolvers/taken-medication-via-query-handler.resolver';
import { TakenMedicationCommandHandlerViaClientService } from './services/taken-medication.command-handler-via-client.service';
import { TakenMedicationQueryHandlerViaClientService } from './services/taken-medication.query-handler-via-client.service';

export const takenMedicationProviders: Array<Provider> = [
	{
		provide: TakenMedicationCommandHandlerService,
		useClass: TakenMedicationCommandHandlerViaClientService
	},
	{
		provide: TakenMedicationQueryHandlerService,
		useClass: TakenMedicationQueryHandlerViaClientService
	},
	{
		provide: TakenMedicationResolver,
		useClass: TakenMedicationViaQueryHandlerResolver
	}
];
