import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { FileUploaderHttpClientConfiguration } from '../configurations/file-uploader-http-client.configuration';

export class FileUploaderHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<FileUploaderHttpClientConfiguration> {

	public build(): FileUploaderHttpClientConfiguration {
		return new FileUploaderHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion
		);
	}

}
