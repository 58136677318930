export class GoogleCredentialResponse {

	private readonly clientId: string;
	private readonly credential: string;
	private readonly selectedBy: string;

	public get token(): string {
		return this.credential;
	}

	private constructor(
		clientId: string,
		credential: string,
		selectedBy: string
	) {
		// TODO: Guard check on defined

		this.clientId = clientId;
		this.credential = credential;
		this.selectedBy = selectedBy;
	}

	public static createFromCredentialResponse(credentialResponseObj: any): GoogleCredentialResponse {
		return new GoogleCredentialResponse(
			credentialResponseObj.clientId,
			credentialResponseObj.credential,
			credentialResponseObj.select_by
		);
	}

}
