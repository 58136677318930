import { ValueRestrictionOptional } from '../../../../../modules/core/configurations/restrictions/value-restriction-optional';

export interface DoctorEncounterFormConfiguration {
	restrictions: {
		comment: {
			controlRowsCount: number;
		};
		date: ValueRestrictionOptional<Date>;
		healthIssue: {
			isEnabled: boolean;
		};
		takenMedications: {
			isCreatingEnabled: boolean;
		};
		documents: {
			isCreatingEnabled: boolean;
		};
	};
}

export const defaultDoctorEncounterFormConfiguration: DoctorEncounterFormConfiguration = {
	restrictions: {
		comment: {
			controlRowsCount: 5
		},
		date: ValueRestrictionOptional
			.initDate(
				new Date('1900-01-01T00:00:00Z'),
				new Date('2100-01-01T00:00:00Z')
			),
		healthIssue: {
			isEnabled: true
		},
		takenMedications: {
			isCreatingEnabled: true
		},
		documents: {
			isCreatingEnabled: true
		}
	}
};

export const forRelatedToHealthIssueDoctorEncounterFormConfiguration: DoctorEncounterFormConfiguration = {
	restrictions: {
		...defaultDoctorEncounterFormConfiguration.restrictions,
		healthIssue: {
			isEnabled: false
		},
		takenMedications: {
			isCreatingEnabled: false
		},
		documents: {
			isCreatingEnabled: false
		}
	}
};
