import { isValueDefined } from '@nmn-core/utils';
import { TypeOfMedicationFakeClientConfiguration } from '../configurations/type-of-medication.fake-client.configuration';

export class TypeOfMedicationFakeClientConfigurationBuilder {

	private readonly serverDelayMs: { min: number, max: number } = { min: 0, max: 0 };

	public withServerDelay(
		serverDelayMs: { min: number, max: number }
	): TypeOfMedicationFakeClientConfigurationBuilder {
		const isServerDelayValidAndDefined = isValueDefined(serverDelayMs) &&
			isValueDefined(serverDelayMs.min) &&
			isValueDefined(serverDelayMs.max) &&
			serverDelayMs.min <= serverDelayMs.max;

		if (isServerDelayValidAndDefined) {
			this.serverDelayMs.min = serverDelayMs.min;
			this.serverDelayMs.max = serverDelayMs.max;
		}

		return this;
	}

	public build(): TypeOfMedicationFakeClientConfiguration {
		return new TypeOfMedicationFakeClientConfiguration(this.serverDelayMs);
	}

}
