import { Injectable } from '@angular/core';
import { MonitoringService } from 'app/services';

@Injectable()
// TODO: Remove service entirely
export class ErrorHandlingService {

	constructor(
		private readonly monitoringService: MonitoringService
	) {
	}

	public handleNavigationError(error): void {
		this.monitoringService.logException(error);
		console.log('Navigation error happened.', error);
	}

	public handleSubscriptionError(error): void {
		this.monitoringService.logException(error);
		console.log('Subscription error happened.', error);
	}

}
