import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { CommandResult, EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { FailureModel } from '../../shared/failures/failure.model';
import { GeneticTestCreateCommand } from '../models/commands/genetic-test.create.command';
import { GeneticTestDeleteBulkCommand } from '../models/commands/genetic-test.delete-bulk.command';
import { GeneticTestDeleteCommand } from '../models/commands/genetic-test.delete.command';
import { GeneticTestUpdateCommand } from '../models/commands/genetic-test.update.command';

export abstract class GeneticTestCommandHandlerService {

	public abstract handleCreateCommand(command: GeneticTestCreateCommand): Observable<Result<CommandResult<GeneticTestCreateCommand, string>, FailureModel>>;

	public abstract handleUpdateCommand(command: GeneticTestUpdateCommand): Observable<Result<EmptyCommandResult<GeneticTestUpdateCommand>, FailureModel>>;

	public abstract handleDeleteCommand(command: GeneticTestDeleteCommand): Observable<Result<EmptyCommandResult<GeneticTestDeleteCommand>, FailureModel>>;

	public abstract handleDeleteBulkCommand(command: GeneticTestDeleteBulkCommand): Observable<Result<EmptyCommandResult<GeneticTestDeleteBulkCommand>, FailureModel>>;

}
