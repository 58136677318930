import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { BloodTypeHttpClientConfiguration } from '../configurations/blood-type.http-client.configuration';
import { BloodTypeHttpClient } from '../services/blood-type.http-client';

export class BloodTypeHttpClientBuilder
	extends HttpClientBuilder<BloodTypeHttpClient, BloodTypeHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): BloodTypeHttpClient {
		return new BloodTypeHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
