import { SourceModel } from './source.model';

export class PubmedCitationModel {

	public readonly pubmedId: string;
	public readonly source: SourceModel;
	public readonly authors: Array<string>;
	public readonly journal: string;
	public readonly year: number;

	public get showOnlyTopAuthors(): boolean {
		return this.authors.length > maxNumberOfAuthorsToShow;
	}

	public get topAuthorsCommaSeparated(): string {
		return this.authors.slice(0, maxNumberOfAuthorsToShow).join(', ');
	}

	constructor(
		pubmedId: string,
		source: SourceModel,
		authors: Array<string>,
		journal: string,
		year: number
	) {
		// TODO: Guard: check on defined

		this.pubmedId = pubmedId;
		this.source = source;
		this.authors = authors;
		this.journal = journal;
		this.year = year;
	}

}

export const maxNumberOfAuthorsToShow = 3;
