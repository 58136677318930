import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ComboboxModel } from '../../shared';

export abstract class DoctorEncounterStatusesResolver implements Resolve<Array<ComboboxModel>> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	): Observable<Array<ComboboxModel>> | Array<ComboboxModel>;

}
