import { Provider } from '@angular/core';
import { ResetPasswordCommandHandlerService } from '@nmn-domain/accounts/reset-password/services/reset-password.command-handler.service';
import { ResetPasswordCommandHandlerViaClientService } from './services/reset-password.command-handler-via-client.service';

export const accountsResetPasswordProviders: Array<Provider> = [
	{
		provide: ResetPasswordCommandHandlerService,
		useClass: ResetPasswordCommandHandlerViaClientService
	}
];
