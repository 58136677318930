import { Provider } from '@angular/core';
import { AllergyIntoleranceCriticalitiesResolver, AllergyIntoleranceCriticalityQueryHandlerService } from '@nmn-domain/allergy-intolerance-criticalities';
import { AllergyIntoleranceCriticalitiesViaQueryHandlerResolver } from './resolvers/allergy-intolerance-criticalities.via-query-handler.resolver';
import { AllergyIntoleranceCriticalityQueryHandlerViaClientService } from './services/allergy-intolerance-criticality.query-handler-via-client.service';

export const allergyIntoleranceCriticalityProviders: Array<Provider> = [
	{
		provide: AllergyIntoleranceCriticalityQueryHandlerService,
		useClass: AllergyIntoleranceCriticalityQueryHandlerViaClientService
	},
	{
		provide: AllergyIntoleranceCriticalitiesResolver,
		useClass: AllergyIntoleranceCriticalitiesViaQueryHandlerResolver
	}
];
