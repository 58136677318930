export class TreatmentCheckerOneStepFlowFormOutModel {

	public readonly email: string;
	public readonly diseaseIds: Array<string>;
	public readonly medicationIds: Array<string>;
	public readonly populationId?: string;
	public readonly genderId?: string;
	public readonly age?: number;
	public readonly weight?: number;
	public readonly height?: number;

	constructor(
		email: string,
		diseaseIds: Array<string>,
		medicationIds: Array<string>,
		populationId: string | undefined,
		genderId: string | undefined,
		age: number | undefined,
		weight: number | undefined,
		height: number | undefined
	) {
		this.email = email;
		this.diseaseIds = diseaseIds;
		this.medicationIds = medicationIds;
		this.populationId = populationId;
		this.genderId = genderId;
		this.age = age;
		this.weight = weight;
		this.height = height;
	}

}
