export class CompleteResetPasswordCommand {

	public readonly userId: string;
	public readonly token: string;
	public readonly newPassword: string;
	public readonly confirmNewPassword: string;

	constructor(
		userId: string,
		token: string,
		newPassword: string,
		confirmNewPassword: string
	) {

		//TODO: Guard.NotNull

		this.userId = userId;
		this.token = token;
		this.newPassword = newPassword;
		this.confirmNewPassword = confirmNewPassword;

	}

}
