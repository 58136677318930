import { isArrayDefinedAndNotEmpty } from '@nmn-core/utils';
import { PersonalizedMedicinePubmedCitationModel } from './personalized-medicine-pubmed-citation.model';
import { PubmedCitationModel } from './pubmed-citation.model';

export class PubmedCitationsModel {

	public readonly personalizedMedicineCitations: Array<PersonalizedMedicinePubmedCitationModel>;
	public readonly otherCitations: Array<PubmedCitationModel>;

	public get isPubmedPersonalizedMedicineCitationsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.personalizedMedicineCitations);
	}

	public get isPubmedOtherCitationsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.otherCitations);
	}

	constructor(
		personalizedMedicineCitations: Array<PersonalizedMedicinePubmedCitationModel>,
		otherCitations: Array<PubmedCitationModel>
	) {
		// TODO: Guard: Check on defined

		this.personalizedMedicineCitations = personalizedMedicineCitations;
		this.otherCitations = otherCitations;
	}

};
