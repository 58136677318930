<div *ngIf="isShowGdprPanel" class="nmn-full-width nmn-gdpr-panel-container" fxFlex="100" fxLayout="row wrap"
	fxLayoutAlign="space-around center">
	<div class="nmn-gdpr-panel-content" fxLayout="row wrap">

		<div class="nmn-gdpr-panel-content-info" fxLayout="row wrap">

			<div class="nmn-gdpr-panel-content-info-title" fxFlex="100" fxLayout="row wrap">
				<span class="nmn-translated-text">{{
					'modules.shared.gdprPanel.info.title' | transloco
					}}</span>
			</div>

			<div class="nmn-gdpr-panel-content-info-text" fxFlex="100" fxLayout="row wrap">
				<span class="nmn-translated-text">{{
					'modules.shared.gdprPanel.info.text' | transloco
					}}</span>
			</div>

		</div>

		<div class="nmn-gdpr-panel-content-delimiter-horizontal nmn-full-width"></div>

		<div class="nmn-gdpr-panel-content-delimiter-vertical-left"></div>

		<div class="nmn-gdpr-panel-content-delimiter-vertical-right"></div>

		<div class="nmn-gdpr-panel-content-maintenance" fxLayout="row wrap" fxLayoutAlign="space-around space-around">
			<div fxFlex="100" fxLayout="row wrap">

				<div class="nmn-gdpr-panel-content-maintenance-title" fxFlex="100" fxLayout="row wrap">
					<span class="nmn-translated-text">{{
						'modules.shared.gdprPanel.maintenance.title' | transloco
						}}</span>
				</div>

				<div class="nmn-gdpr-panel-content-maintenance-btns" fxFlex="100" fxLayout="row wrap"
					fxLayoutAlign="start center">

					<button class="nmn-gdpr-panel-content-maintenance-btn-accept" mat-raised-button
						(click)="acceptGdpr()">{{
						'modules.shared.gdprPanel.maintenance.acceptBtn.title' | transloco
						}}</button>

					<span class="nmn-gdpr-panel-content-maintenance-btn-cookie-policy text-as-link"
						(click)="navigateCookiePolicy()">{{
						'modules.shared.gdprPanel.maintenance.navigateCookiePolicyBtn.title' | transloco
						}}</span>

				</div>

			</div>
		</div>

	</div>
</div>