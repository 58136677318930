import { Provider } from '@angular/core';
import { MedicationDosageTypeQueryHandlerService, MedicationDosageTypesResolver } from '@nmn-domain/medication-dosage-types';
import { MedicationDosageTypeQueryHandlerViaClientService, MedicationDosageTypesViaQueryHandlerResolver } from './index';

export const medicationDosageTypeProviders: Array<Provider> = [
	{
		provide: MedicationDosageTypeQueryHandlerService,
		useClass: MedicationDosageTypeQueryHandlerViaClientService
	},
	{
		provide: MedicationDosageTypesResolver,
		useClass: MedicationDosageTypesViaQueryHandlerResolver
	}
];
