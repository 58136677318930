import { Provider } from '@angular/core';
import { DocumentTypeQueryHandlerService, DocumentTypesResolver } from '@nmn-domain/document-types';
import { DocumentTypesViaQueryHandlerResolver } from './resolvers/document-types.via-query-handler.resolver';
import { DocumentTypeQueryHandlerViaClientService } from './services/document-type.query-handler-via-client.service';

export const documentTypeProviders: Array<Provider> = [
	{
		provide: DocumentTypeQueryHandlerService,
		useClass: DocumentTypeQueryHandlerViaClientService
	},
	{
		provide: DocumentTypesResolver,
		useClass: DocumentTypesViaQueryHandlerResolver
	}
];
