import { Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { GeneticFileParseRequestFakeClientConfiguration } from '../configurations/genetic-file-parse-request.fake-client.configuration';
import { GeneticFileParseRequestFakeClient } from '../services/genetic-file-parse-request.fake-client';

export class GeneticFileParseRequestFakeClientBuilder {

	private configuration: GeneticFileParseRequestFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(
		configuration: GeneticFileParseRequestFakeClientConfiguration
	): GeneticFileParseRequestFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): GeneticFileParseRequestFakeClient {
		return new GeneticFileParseRequestFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
