import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { Failure } from '../../shared';
import { ContactsDto } from '../models/contacts.dto';
import { SendMessageParameterDto } from '../models/send-message-parameter.dto';

export abstract class ContactsClient {

	public abstract sendMessage(parameter: SendMessageParameterDto): Observable<EmptyResult<Failure>>;

	public abstract getContacts(): Observable<Result<ContactsDto, Failure>>;

}
