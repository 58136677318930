
import { TranslocoService } from '@ngneat/transloco';
import { MedicationAdmStatusDescribedComboboxDto } from '@nmn-communication/medication-adm-statuses';
import { isValueDefined } from '@nmn-core/utils';
import { setTranslation } from '../../utils/localize';
import { FakeDescribedComboboxDto } from './fake-described-combobox.dto';

export interface FakeMedicationAdmStatusDescribedComboboxDto extends FakeDescribedComboboxDto<string> {

	type: string;

}

export const mapFakeMedicationAdmStatusDescriptedComboboxDtoToComboboxDto = (
	translocoService: TranslocoService,
	fakeDescribedComboboxDto: FakeMedicationAdmStatusDescribedComboboxDto
): MedicationAdmStatusDescribedComboboxDto => {
	if (!isValueDefined(fakeDescribedComboboxDto)) {
		return undefined!;
	}

	return {
		id: fakeDescribedComboboxDto.id,
		displayText: setTranslation(translocoService, fakeDescribedComboboxDto.displayText),
		description: setTranslation(translocoService, fakeDescribedComboboxDto.description),
		type: fakeDescribedComboboxDto.type
	};
};
