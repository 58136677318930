import { Injector, Provider } from '@angular/core';
import { VaccinationClient } from '@nmn-communication/vaccination';
import { vaccinationClientFactory } from './factories/vaccination.client.factory';

export const vaccinationProviders: Array<Provider> = [
	{
		provide: VaccinationClient,
		useFactory: vaccinationClientFactory,
		deps: [Injector]
	}
];
