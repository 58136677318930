import { Injectable } from '@angular/core';
import { EmailConfirmationClient } from '@nmn-communication/accounts';
import { Result } from '@nmn-core/shared';
import { ConfirmEmailCommand, EmailConfirmationCommandHandlerService, InitiateEmailConfirmationCommand } from '@nmn-domain/accounts';
import { EmptyCommandResult, FailureModel } from '@nmn-domain/shared';
import { mapFailureToFailureModel } from 'app/domain/implementations/shared/factories/failure-handling.factory';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapConfirmEmailCommandToConfirmEmailParameter, mapInitiateEmailConfirmationCommandToParameter } from '../factories/confirm-email.factory';

@Injectable()
export class EmailConfirmationCommandHandlerViaClientService extends EmailConfirmationCommandHandlerService {

	constructor(
		private readonly client: EmailConfirmationClient
	) {
		super();
	}

	public confirmEmail(command: ConfirmEmailCommand): Observable<Result<EmptyCommandResult<ConfirmEmailCommand>, FailureModel>> {
		return this.client
			.confirmEmail(mapConfirmEmailCommandToConfirmEmailParameter(command))
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

	public initiateEmailConfirmation(command: InitiateEmailConfirmationCommand): Observable<Result<EmptyCommandResult<InitiateEmailConfirmationCommand>, FailureModel>> {
		return this.client
			.initiateEmailConfirmation(mapInitiateEmailConfirmationCommandToParameter(command))
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

}
