import { ComboboxDto, Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { LanguageFilterDto } from '../models/language-filter.dto';

export abstract class LanguageClient {

	public abstract getBulkAsComboboxes(filter: LanguageFilterDto): Observable<Result<Array<ComboboxDto<string>>, Failure>>;

}
