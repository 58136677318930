
import {
	LoginDto, UserProfileAttachFacebookAuthUpdateParameter, UserProfileAttachGoogleAuthUpdateParameter, UserProfileAttachRegularAuthUpdateParameter, UserProfileClient,
	UserProfileDeleteParameter, UserProfileFindParameter, UserProfilePasswordUpdateParameter, UserProfileRevokeDeleteParameter, UserProfileUpdateParameter
} from '@nmn-communication/accounts';
import { Failure } from '@nmn-communication/shared';
import { LocalStorageService } from '@nmn-core/application-storages';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../../modules/fakes/databases/fake.database';
import { wrapFuncCallAsFakeClientResponse } from '../../../../../modules/fakes/databases/fake.utils';
import { UserProfileFakeClientConfiguration } from '../configurations/user-profile.fake-client.configuration';

export class UserProfileFakeClient extends UserProfileClient {

	constructor(
		private readonly configuration: UserProfileFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase,
		private readonly localStorage: LocalStorageService
	) {
		super();
	}

	public get(parameter: UserProfileFindParameter): Observable<Result<LoginDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.usersTable.get(parameter),
			this.configuration.serverDelayMs
		);
	}

	public getCurrent(): Observable<Result<LoginDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.usersTable.getCurrent(this.localStorage.getLastUserId()),
			this.configuration.serverDelayMs
		);
	}

	public updateCurrent(parameter: UserProfileUpdateParameter): Observable<Result<LoginDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.usersTable.updateCurrent(this.localStorage.getLastUserId(), parameter),
			this.configuration.serverDelayMs
		);
	}

	public changePasswordCurrent(parameter: UserProfilePasswordUpdateParameter): Observable<Result<LoginDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.usersTable.patchPasswordCurrent(this.localStorage.getLastUserId(), parameter),
			this.configuration.serverDelayMs
		);
	}

	public deleteCurrent(parameter: UserProfileDeleteParameter): Observable<Result<LoginDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.usersTable.delete(this.localStorage.getLastUserId(), parameter),
			this.configuration.serverDelayMs
		);
	}

	public revokeDeleteCurrent(parameter: UserProfileRevokeDeleteParameter): Observable<Result<LoginDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.usersTable.revokeDeletion(this.localStorage.getLastUserId(), parameter),
			this.configuration.serverDelayMs
		);
	}

	public attachRegularAuthFlow(parameter: UserProfileAttachRegularAuthUpdateParameter): Observable<Result<LoginDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.usersTable.attachRegularAuth(this.localStorage.getLastUserId(), parameter),
			this.configuration.serverDelayMs
		);
	}

	public attachGoogleAuthFlow(_: UserProfileAttachGoogleAuthUpdateParameter): Observable<Result<LoginDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.usersTable.attachGoogleAuth(this.localStorage.getLastUserId()),
			this.configuration.serverDelayMs
		);
	}

	public attachFacebookAuthFlow(_: UserProfileAttachFacebookAuthUpdateParameter): Observable<Result<LoginDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.usersTable.attachFacebookAuth(this.localStorage.getLastUserId()),
			this.configuration.serverDelayMs
		);
	}

}
