import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { MedicationDosageTypeHttpClientConfiguration } from '../configurations/medication-dosage-type.http-client.configuration';
import { MedicationDosageTypeHttpClient } from '../services/medication-dosage-type.http-client';

export class MedicationDosageTypeHttpClientBuilder extends HttpClientBuilder<MedicationDosageTypeHttpClient, MedicationDosageTypeHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): MedicationDosageTypeHttpClient {
		return new MedicationDosageTypeHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
