import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { LanguageHttpClientConfiguration } from '../configurations/language.http-client.configuration';

export class LanguageHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<LanguageHttpClientConfiguration> {

	public build(): LanguageHttpClientConfiguration {
		return new LanguageHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
