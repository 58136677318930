import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { GeneVariantComboboxDto } from '../models/gene-variant-combobox.dto';
import { GeneVariantFilterDto } from '../models/gene-variant-filter.dto';

export abstract class GeneVariantClient {

	public abstract getBulkAsComboboxes(filter: GeneVariantFilterDto): Observable<Result<Array<GeneVariantComboboxDto>, Failure>>;

}
