export class UserProfileAttachFacebookAuthCommand {

	public accessToken: string;

	constructor(
		accessToken: string
	) {
		this.accessToken = accessToken;
	}

}
