export class Guid {

	public static newGuid(): string {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
			.replace(
				/[xy]/g,
				substring => {
					/* eslint-disable no-bitwise */
					const r = Math.random() * 16 | 0;
					const v = substring === 'x' ? r : (r & 0x3 | 0x8);

					return v.toString(16);
					/* eslint-enable no-bitwise */
				});
	}

}
