import { AllergyIntoleranceVerificationStatusClient, AllergyIntoleranceVerificationStatusFilterDto } from '@nmn-communication/allergy-intolerance-verification-statuses';
import { DescribedComboboxDto, Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { FakeDatabase } from 'app/modules/fakes/databases/fake.database';
import { wrapFuncCallAsFakeClientResponse } from 'app/modules/fakes/databases/fake.utils';
import { Observable } from 'rxjs';
import { AllergyIntoleranceVerificationStatusFakeClientConfiguration } from '../configurations/allergy-intolerance-verification-status.fake-client.configuration';

export class AllergyIntoleranceVerificationStatusFakeClient extends AllergyIntoleranceVerificationStatusClient {

	constructor(
		private readonly configuration: AllergyIntoleranceVerificationStatusFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulkAsComboboxes(
		filter: AllergyIntoleranceVerificationStatusFilterDto
	): Observable<Result<Array<DescribedComboboxDto<string>>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.allergyIntoleranceVerificationStatusTable.getComboboxesPagedCollection(filter),
			this.configuration.serverDelayMs
		);
	}

}
