import {
	VaccinationCardDto, VaccinationCardFindParameterDto, VaccinationCreateParameterDto,
	VaccinationDto, VaccinationFindParameterDto, VaccinationUpdateParameterDto
} from '@nmn-communication/vaccination';
import { mapDateInServerFormatToDate, mapDateInServerFormatToDateSafely, mapDateToServerFormat, mapDateToServerFormatSafely } from '@nmn-core/utils';
import { VaccinationCardGetQuery, VaccinationCardModel, VaccinationCreateCommand, VaccinationGetQuery, VaccinationModel, VaccinationUpdateCommand } from '@nmn-domain/vaccination';
import { mapDiseaseComboboxDtoToModel } from '../../diseases/factories/disease.factory';
import { mapVaccineDescribedComboboxDtoToModel } from '../../vaccines/factories/vaccine.factory';

export const mapVaccinationCardDtoToModel = (dto: VaccinationCardDto): VaccinationCardModel => (
	new VaccinationCardModel(
		dto.patientId,
		dto.vaccinations?.map(mapVaccinationDtoToModel)
	)
);

export const mapVaccinationDtoToModel = (dto: VaccinationDto): VaccinationModel => (
	new VaccinationModel(
		dto.id,
		dto.patientId,
		mapDateInServerFormatToDateSafely(dto.vaccinationDate),
		mapVaccineDescribedComboboxDtoToModel(dto.vaccine),
		dto.vaccinatedFromDiseases?.map(mapDiseaseComboboxDtoToModel),
		dto.comment,
		dto.numberInSeriesOfDoses,
		dto.memberStateOfVaccination,
		dto.certificateIssuer,
		dto.certificateUid,
		mapDateInServerFormatToDate(dto.expirationDate)
	)
);

export const mapVaccinationCardGetQueryToParameter = (query: VaccinationCardGetQuery): VaccinationCardFindParameterDto => (
	{
		patientId: query.patientId
	}
);

export const mapVaccinationGetQueryToParameter = (query: VaccinationGetQuery): VaccinationFindParameterDto => (
	{
		id: query.id,
		patientId: query.patientId
	}
);

export const mapCreateCommandToCreateParameter = (command: VaccinationCreateCommand): VaccinationCreateParameterDto => (
	{
		patientId: command.patientId,
		comment: command.comment,
		vaccinationDate: mapDateToServerFormatSafely(command.vaccinationDate),
		vaccineId: command.vaccineId,
		numberInSeriesOfDoses: command.numberInSeriesOfDoses,
		memberStateOfVaccination: command.memberStateOfVaccination,
		certificateIssuer: command.certificateIssuer,
		certificateUid: command.certificateUid,
		expirationDate: mapDateToServerFormat(command.expirationDate)
	}
);

export const mapUpdateCommandToUpdateParameter = (command: VaccinationUpdateCommand): VaccinationUpdateParameterDto => (
	{
		patientId: command.patientId,
		comment: command.comment,
		vaccinationDate: mapDateToServerFormatSafely(command.vaccinationDate),
		vaccineId: command.vaccineId,
		numberInSeriesOfDoses: command.numberInSeriesOfDoses,
		memberStateOfVaccination: command.memberStateOfVaccination,
		certificateIssuer: command.certificateIssuer,
		certificateUid: command.certificateUid,
		expirationDate: mapDateToServerFormat(command.expirationDate)
	}
);
