import { Injectable } from '@angular/core';
import { LoginDto, UserSubscriptionClient } from '@nmn-communication/accounts';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { UserSubscriptionCommandHandlerService, UserSubscriptionUpdateCommand } from '@nmn-domain/accounts';
import { EmptyCommandResult, FailureModel } from '@nmn-domain/shared';
import { StorageService, updateSubscriptionsAfterLogin, UserConfigurationStorageService } from 'app/services';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../../shared/factories/failure-handling.factory';
import { mapLoginDtoToModel } from '../../login/factories/login.factory';
import { mapUpdateCommandToParameter } from '../factories/user-subscription.factory';

@Injectable()
export class UserSubscriptionCommandHandlerViaClientService extends UserSubscriptionCommandHandlerService {

	constructor(
		private readonly client: UserSubscriptionClient,
		private readonly storageService: StorageService,
		private readonly userConfigurationStorage: UserConfigurationStorageService
	) {
		super();
	}

	public update(
		command: UserSubscriptionUpdateCommand
	): Observable<Result<EmptyCommandResult<UserSubscriptionUpdateCommand>, FailureModel>> {
		return this.client
			.update(mapUpdateCommandToParameter(command))
			.pipe(
				tap(this.updateStorageInfoViaResult.bind(this)),
				map(result => result.map(() => new EmptyCommandResult(command), mapFailureToFailureModel))
			);
	}

	private updateStorageInfoViaResult(result: Result<LoginDto, Failure>): void {
		result
			.mapOnSuccess(mapLoginDtoToModel)
			.bindOnSuccess(success => {
				updateSubscriptionsAfterLogin(success, this.storageService, this.userConfigurationStorage);
			});
	}
}
