import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { VaccinationModel } from '../models/vaccination.model';

export abstract class VaccinationResolver implements Resolve<VaccinationModel> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		routerState: RouterStateSnapshot
	): Observable<VaccinationModel> | Promise<VaccinationModel> | VaccinationModel;

}
