import { Provider } from '@angular/core';
import { MedicationAdmStatusesResolver, MedicationAdmStatusQueryHandlerService } from '@nmn-domain/medication-adm-statuses';
import { MedicationAdmStatusesViaQueryHandlerResolver, MedicationAdmStatusQueryHandlerViaClientService } from './index';

export const medicationAdmStatusProviders: Array<Provider> = [
	{
		provide: MedicationAdmStatusQueryHandlerService,
		useClass: MedicationAdmStatusQueryHandlerViaClientService
	},
	{
		provide: MedicationAdmStatusesResolver,
		useClass: MedicationAdmStatusesViaQueryHandlerResolver
	}
];
