import { isArrayDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { MedicationComboboxModel } from '@nmn-domain/medications';
import { ComboboxModel } from '@nmn-domain/shared';
import { PubmedCitationsModel } from '@nmn-domain/sources';
import { TreatmentCheckerPersonalizedMedicineGuidelineModel } from './treatment-checker-personalized-medicine-guideline.model';
import { TreatmentCheckerPersonalizedMedicineRecommendationModel } from './treatment-checker-personalized-medicine-recommendation.model';
import { TreatmentCheckerReportMedicationPropertiesModel } from './treatment-checker-report-medication-properties.model';
import { TreatmentCheckerReportMedicationSideEffectComboboxModel } from './treatment-checker-report-medication-side-effect-combobox.model';
import { TreatmentCheckerReportMedicationPgxLevelTestingModel } from './treatment-checker-report-medication.pgx-level-testing.model';

export class TreatmentCheckerReportMedicationReportModel {

	public readonly medication: MedicationComboboxModel;
	public readonly medicationProperties: TreatmentCheckerReportMedicationPropertiesModel;
	public readonly knownTradeNames: Array<ComboboxModel>;
	public readonly synonyms: Array<ComboboxModel>;
	public readonly sideEffects: Array<TreatmentCheckerReportMedicationSideEffectComboboxModel>;
	public readonly personalizedMedicineRecommendations: Array<TreatmentCheckerPersonalizedMedicineRecommendationModel>;
	public readonly pgxLevelTestings: Array<TreatmentCheckerReportMedicationPgxLevelTestingModel>;
	public readonly personalizedMedicineGuidelines: Array<TreatmentCheckerPersonalizedMedicineGuidelineModel>;
	public readonly pubmedCitations: PubmedCitationsModel;

	public get isMedicationPropertiesDefined(): boolean {
		return isValueDefined(this.medicationProperties);
	}

	public get isKnownTradeNamesDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.knownTradeNames);
	}

	public get isSynonymsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.synonyms);
	}

	public get isSideEffectsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.sideEffects);
	}

	public get isGeneticGuidelinesDefined(): boolean {
		return this.isPgxLevelTestingsDefined || this.isPersonalizedMedicineGuidelinesDefined;
	}

	public get isPersonalizedMedicineRecommendationsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.personalizedMedicineRecommendations);
	}

	public get isPgxLevelTestingsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.pgxLevelTestings);
	}

	public get isPersonalizedMedicineGuidelinesDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.personalizedMedicineGuidelines);
	}

	public get isPubmedCitationsDefined(): boolean {
		return isValueDefined(this.pubmedCitations) &&
			(this.pubmedCitations.isPubmedPersonalizedMedicineCitationsDefined || this.pubmedCitations.isPubmedOtherCitationsDefined);
	}

	constructor(
		medication: MedicationComboboxModel,
		medicationProperties: TreatmentCheckerReportMedicationPropertiesModel,
		knownTradeNames: Array<ComboboxModel>,
		synonyms: Array<ComboboxModel>,
		sideEffects: Array<TreatmentCheckerReportMedicationSideEffectComboboxModel>,
		personalizedMedicineRecommendations: Array<TreatmentCheckerPersonalizedMedicineRecommendationModel>,
		pgxLevelTestings: Array<TreatmentCheckerReportMedicationPgxLevelTestingModel>,
		personalizedMedicineGuidelines: Array<TreatmentCheckerPersonalizedMedicineGuidelineModel>,
		pubmedCitations: PubmedCitationsModel
	) {
		// TODO: Guard check on defined

		this.medication = medication;
		this.medicationProperties = medicationProperties;
		this.knownTradeNames = knownTradeNames;
		this.synonyms = synonyms;
		this.sideEffects = sideEffects;
		this.personalizedMedicineRecommendations = personalizedMedicineRecommendations;
		this.pgxLevelTestings = pgxLevelTestings;
		this.personalizedMedicineGuidelines = personalizedMedicineGuidelines;
		this.pubmedCitations = pubmedCitations;
	}

}
