import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RegionComboboxModel } from '../models/region-combobox.model';

export abstract class RegionsResolver implements Resolve<Array<RegionComboboxModel>> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	): Observable<Array<RegionComboboxModel>> | Array<RegionComboboxModel>;

}
