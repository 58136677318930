import { MedicationComboboxDto, MedicationFilterDto } from '@nmn-communication/medications';
import { mapMedicationTypeFromString, MedicationComboboxModel, MedicationFilterModel } from '@nmn-domain/medications';
import { mapDescribedComboboxDtoToDescribedComboboxModel } from '../../shared/factories/combobox.factory';

export const mapFilterModelToDto = (model: MedicationFilterModel): MedicationFilterDto => (
	{
		...model
	}
);

export const mapFilterDtoToModel = (dto: MedicationFilterDto): MedicationFilterModel => (
	new MedicationFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern,
		dto.isForTreatmentChecker
	)
);

export const mapMedicationComboboxDtoToModel = (dto: MedicationComboboxDto): MedicationComboboxModel => (
	new MedicationComboboxModel(
		dto.id,
		dto.displayText,
		dto.description,
		dto.substances.map(mapDescribedComboboxDtoToDescribedComboboxModel),
		mapMedicationTypeFromString(dto.type),
		dto.source,
		dto.inn,
		dto.atc
	)
);
