import { isValueDefined } from '@nmn-core/utils';

export enum PharmacogenomicTestResultCategory {

	Descreased = 'descreased',
	Absent = 'absent',
	Heterozygote = 'heterozygote',
	Homozygote = 'homozygote',
	Negative = 'negative',
	IncreasedRisk = 'increasedRisk',
	Increased = 'increased',
	Normal = 'normal',
	NoResponse = 'noResponse',
	Response = 'response',
	Associatio = 'associatio',
	Susceptibility = 'susceptibility',
	MetabolizerPoor = 'metabolizerPoor',
	MetabolizerIntermediate = 'metabolizerIntermediate',
	MetabolizerRapid = 'metabolizerRapid',
	MetabolizerUltrarapid = 'metabolizerUltrarapid',
	Uncertain = 'uncertain',
	Variable = 'variable',

	Undefined = 'undefined'

}

export const mapPharmacogenomicTestResultCategoryFromString = (stringValue: string): PharmacogenomicTestResultCategory => {
	const enumValue = Object
		.keys(PharmacogenomicTestResultCategory)
		.find(x => PharmacogenomicTestResultCategory[x] === stringValue);

	return isValueDefined(enumValue) ? stringValue as PharmacogenomicTestResultCategory : PharmacogenomicTestResultCategory.Undefined;
};
