import { MedicationClient, MedicationComboboxDto, MedicationFilterDto } from '@nmn-communication/medications';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { FakeDatabase } from 'app/modules/fakes/databases/fake.database';
import { wrapFuncCallAsFakeClientResponse } from 'app/modules/fakes/databases/fake.utils';
import { Observable } from 'rxjs';
import { MedicationFakeClientConfiguration } from '../configurations/medication.fake-client.configuration';

export class MedicationFakeClient extends MedicationClient {

	constructor(
		private readonly configuration: MedicationFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulkAsComboboxes(
		filter: MedicationFilterDto
	): Observable<Result<Array<MedicationComboboxDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.medicationsTable.getComboboxesPagedCollection(filter),
			this.configuration.serverDelayMs);
	}

}
