import { Result } from '@nmn-core/shared';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { ServerConfigurationGetQuery } from '../models/queries/server-configuration.get.query';
import { ServerConfigurationModel } from '../models/server-configuration.model';

export abstract class ServerConfigurationQueryHandlerService {

	public abstract get(query: ServerConfigurationGetQuery): Observable<Result<ServerConfigurationModel, FailureModel>>;

}
