import { AzureStorageClient, AzureStorageClientFactory, AzureStorageClientFactoryCreateParameterDto } from '@nmn-communication/azure-file-storage';
import { AzureStorageFakeClient } from '../services/azure-storage.fake-client';

export class AzureStorageClientFakeFactory extends AzureStorageClientFactory {

	public create(
		_: AzureStorageClientFactoryCreateParameterDto
	): AzureStorageClient {
		return new AzureStorageFakeClient();
	}

}
