import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { MedicationBodySiteHttpClientConfiguration } from '..//configurations/medication-body-site.http-client.configuration';

export class MedicationBodySiteHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<MedicationBodySiteHttpClientConfiguration> {

	public build(): MedicationBodySiteHttpClientConfiguration {
		return new MedicationBodySiteHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
