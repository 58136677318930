import { AllergyIntoleranceCriticalityClient, AllergyIntoleranceCriticalityFilterDto } from '@nmn-communication/allergy-intolerance-criticalities';
import { DescribedComboboxDto, Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { FakeDatabase } from 'app/modules/fakes/databases/fake.database';
import { wrapFuncCallAsFakeClientResponse } from 'app/modules/fakes/databases/fake.utils';
import { Observable } from 'rxjs';
import { AllergyIntoleranceCriticalityFakeClientConfiguration } from '../configurations/allergy-intolerance-criticality.fake-client.configuration';

export class AllergyIntoleranceCriticalityFakeClient extends AllergyIntoleranceCriticalityClient {

	constructor(
		private readonly configuration: AllergyIntoleranceCriticalityFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulkAsComboboxes(filter: AllergyIntoleranceCriticalityFilterDto): Observable<Result<Array<DescribedComboboxDto<string>>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.allergyIntoleranceCriticalityTable.getComboboxesPagedCollection(filter),
			this.configuration.serverDelayMs
		);
	}

}
