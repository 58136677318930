import { Injector } from '@angular/core';
import { UserSubscriptionClient } from '@nmn-communication/accounts';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { UserSubscriptionFakeClientBuilder } from '../builders/user-subscription.fake-client.builder';
import { UserSubscriptionFakeClientConfigurationBuilder } from '../builders/user-subscription.fake-client.configuration.builder';
import { UserSubscriptionHttpClientBuilder } from '../builders/user-subscription.http-client.builder';
import { UserSubscriptionHttpClientConfigurationBuilder } from '../builders/user-subscription.http-client.configuration.builder';
import { UserSubscriptionFakeClient } from '../services/user-subscription.fake-client';
import { UserSubscriptionHttpClient } from '../services/user-subscription.http-client';

export const userSubscriptionClientFactory = (
	injector: Injector
): UserSubscriptionClient => {
	const useFake = environment?.api?.resources?.accounts?.userSubscription?.useFake ||
		!isValueDefined(environment?.api?.resources?.accounts?.userSubscription?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		userSubscriptionFakeClientFactory(injector) :
		userSubscriptionHttpClientFactory(injector);
};

export const userSubscriptionFakeClientFactory = (
	injector: Injector
): UserSubscriptionFakeClient => {
	const configuration = new UserSubscriptionFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.accounts.userSubscription.serverDelayMs)
		.build();

	return new UserSubscriptionFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const userSubscriptionHttpClientFactory = (
	injector: Injector
): UserSubscriptionHttpClient => {
	const configuration = new UserSubscriptionHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.accounts?.userSubscription?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.accounts.userSubscription.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.accounts?.userSubscription?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new UserSubscriptionHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
