export class TakenMedicationGetQuery {

	public id: string;
	public patientId: string;

	private constructor(
		id: string,
		patientId: string
	) {

		// TODO: add null check
		this.id = id;
		this.patientId = patientId;

	}

	public static createById(
		id: string,
		patientId: string
	): TakenMedicationGetQuery {
		return new TakenMedicationGetQuery(id, patientId);
	}

}
