import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { DescribedComboboxModel, FailureModel } from '../../shared';
import { GenderFilterModel } from '../models/gender-filter.model';

export abstract class GenderQueryHandlerService {

	public abstract getAsDescribedComboboxes(query: GenderFilterModel): Observable<Result<Array<DescribedComboboxModel>, FailureModel>>;

}
