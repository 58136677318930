import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TakenMedicationModel } from '../models/taken-medication.model';

export abstract class TakenMedicationResolver implements Resolve<TakenMedicationModel> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	): Observable<TakenMedicationModel> | TakenMedicationModel;

}
