export class ChangePasswordFormOutModel {

	public readonly oldPassword: string;
	public readonly newPassword: string;
	public readonly confirmNewPassword: string;

	public constructor(
		oldPassword: string,
		newPassword: string,
		confirmNewPassword: string
	) {
		this.oldPassword = oldPassword;
		this.newPassword = newPassword;
		this.confirmNewPassword = confirmNewPassword;
	}

}
