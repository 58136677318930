import { Result } from '@nmn-core/shared';
import { EmptyCommandResult, FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { UserProfileAttachFacebookAuthCommand } from '../models/commands/user-profile-attach-facebook-auth.update.command';
import { UserProfileAttachGoogleAuthCommand } from '../models/commands/user-profile-attach-google-auth.update.command';
import { UserProfileAttachRegularAuthCommand } from '../models/commands/user-profile-attach-regular-auth.update.command';
import { UserProfilePasswordUpdateCommand } from '../models/commands/user-profile-password.update.command';
import { UserProfileDeleteCommand } from '../models/commands/user-profile.delete.command';
import { UserProfileRevokeDeleteCommand } from '../models/commands/user-profile.revoke-delete.command';
import { UserProfileUpdateCommand } from '../models/commands/user-profile.update.command';

export abstract class CurrentUserProfileCommandHandlerService {

	public abstract update(
		command: UserProfileUpdateCommand
	): Observable<Result<EmptyCommandResult<UserProfileUpdateCommand>, FailureModel>>;

	public abstract changePassword(
		command: UserProfilePasswordUpdateCommand
	): Observable<Result<EmptyCommandResult<UserProfilePasswordUpdateCommand>, FailureModel>>;

	public abstract delete(
		command: UserProfileDeleteCommand
	): Observable<Result<EmptyCommandResult<UserProfileDeleteCommand>, FailureModel>>;

	public abstract revokeDelete(
		command: UserProfileRevokeDeleteCommand
	): Observable<Result<EmptyCommandResult<UserProfileRevokeDeleteCommand>, FailureModel>>;

	public abstract attachRegularAuthFlow(
		command: UserProfileAttachRegularAuthCommand
	): Observable<Result<EmptyCommandResult<UserProfileAttachRegularAuthCommand>, FailureModel>>;

	public abstract attachGoogleAuthFlow(
		command: UserProfileAttachGoogleAuthCommand
	): Observable<Result<EmptyCommandResult<UserProfileAttachGoogleAuthCommand>, FailureModel>>;

	public abstract attachFacebookAuthFlow(
		command: UserProfileAttachFacebookAuthCommand
	): Observable<Result<EmptyCommandResult<UserProfileAttachFacebookAuthCommand>, FailureModel>>;

}
