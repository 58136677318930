import { isValueDefined } from '@nmn-core/utils';

export enum TreatmentCheckerReportMedicationPgxLevelTestingOrganization {

	Undefined = 'undefined',

	Fda = 'fda',
	Ema = 'ema',
	Hcsc = 'hcsc',
	Swissmedic = 'swissmedic',
	Pmda = 'pmda'

}

export const mapTreatmentCheckerReportMedicationPgxLevelTestingOrganizationFromString = (
	stringValue: string
): TreatmentCheckerReportMedicationPgxLevelTestingOrganization => {
	const enumValue = Object
		.keys(TreatmentCheckerReportMedicationPgxLevelTestingOrganization)
		.find(x => TreatmentCheckerReportMedicationPgxLevelTestingOrganization[x] === stringValue);

	return isValueDefined(enumValue) ?
		stringValue as TreatmentCheckerReportMedicationPgxLevelTestingOrganization :
		TreatmentCheckerReportMedicationPgxLevelTestingOrganization.Undefined;
};
