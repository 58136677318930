import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { MedicationDosageTypeHttpClientConfiguration } from '../configurations/medication-dosage-type.http-client.configuration';

export class MedicationDosageTypeHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<MedicationDosageTypeHttpClientConfiguration> {

	public build(): MedicationDosageTypeHttpClientConfiguration {
		return new MedicationDosageTypeHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
