import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';

export class ContactsModel {

	public readonly main: MainContactsModel;
	public readonly other: OtherContactsModel;

	constructor(
		main: MainContactsModel,
		other: OtherContactsModel
	) {
		// TODO: Guard check on defined

		this.main = main;
		this.other = other;
	}

}

export class MainContactsModel {

	public readonly common: СommonMainContactsModel;
	public readonly social: SocialMainContactsModel;

	constructor(
		common: СommonMainContactsModel,
		social: SocialMainContactsModel
	) {
		// TODO: Guard check on defined

		this.common = common;
		this.social = social;
	}

}

export class СommonMainContactsModel {

	public readonly email: string;
	public readonly mobile: string;

	constructor(
		email: string,
		mobile: string
	) {
		// TODO: Guard check on defined

		this.email = email;
		this.mobile = mobile;
	}

}

export class SocialMainContactsModel {

	public readonly twitter: string;
	public readonly facebook: string;
	public readonly medium: string;
	public readonly linkedin: string;
	public readonly telegram: string;

	public get telegramWithoutDefaultLinkPrefix(): string {
		return this.telegram?.replace('https://t.me/', '');
	}

	constructor(
		twitter: string,
		facebook: string,
		medium: string,
		linkedin: string,
		telegram: string
	) {
		// TODO: Guard check on defined

		this.twitter = twitter;
		this.facebook = facebook;
		this.medium = medium;
		this.linkedin = linkedin;
		this.telegram = telegram;
	}

}

export class OtherContactsModel {

	public readonly social: SocialOtherContactsModel;

	constructor(
		social: SocialOtherContactsModel
	) {
		// TODO: Guard check on defined

		this.social = social;
	}

}

export class SocialOtherContactsModel {

	public readonly twitter: string;
	public readonly facebook: string;
	public readonly medium: string;
	public readonly linkedin: string;
	public readonly youtube: string;
	public readonly telegram: string;

	constructor(
		twitter: string,
		facebook: string,
		medium: string,
		linkedin: string,
		youtube: string,
		telegram: string
	) {
		// TODO: Guard check on defined

		this.twitter = twitter;
		this.facebook = facebook;
		this.medium = medium;
		this.linkedin = linkedin;
		this.youtube = youtube;
		this.telegram = telegram;
	}

}
