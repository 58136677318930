import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { CommandResult, EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { FailureModel } from '../../shared/failures/failure.model';
import { PatientTreatmentCheckerAnalyzeCommand } from '../models/commands/patient-treatment-checker-analyze.command';
import { TreatmentCheckerAnalyzeCommand } from '../models/commands/treatment-checker-analyze.command';
import { TreatmentCheckerReportDeleteBulkCommand } from '../models/commands/treatment-checker-report.delete-bulk.command';
import { TreatmentCheckerReportDeleteCommand } from '../models/commands/treatment-checker-report.delete.command';

export abstract class TreatmentCheckerCommandHandlerService {

	public abstract handlePatientTreatmentCheckerAnalyzeCommand(
		command: PatientTreatmentCheckerAnalyzeCommand
	): Observable<Result<CommandResult<PatientTreatmentCheckerAnalyzeCommand, string>, FailureModel>>;

	public abstract handleTreatmentCheckerAnalyzeCommand(
		command: TreatmentCheckerAnalyzeCommand
	): Observable<Result<CommandResult<TreatmentCheckerAnalyzeCommand, string>, FailureModel>>;

	public abstract handleTreatmentCheckerReportDeleteCommand(
		command: TreatmentCheckerReportDeleteCommand
	): Observable<Result<EmptyCommandResult<TreatmentCheckerReportDeleteCommand>, FailureModel>>;

	public abstract handleTreatmentCheckerReportDeleteBulkCommand(
		command: TreatmentCheckerReportDeleteBulkCommand
	): Observable<Result<EmptyCommandResult<TreatmentCheckerReportDeleteBulkCommand>, FailureModel>>;

}
