import { diffDateInMs, isArrayDefinedAndNotEmpty } from '@nmn-core/utils';
import { DescribedComboboxModel } from '@nmn-domain/shared';
import { VaccinationModel } from './vaccination.model';

export class VaccinationCardModel {

	public readonly patientId: string;
	public readonly vaccinations: Array<VaccinationModel>;

	public get isVaccinationCardEmpty(): boolean {
		return !isArrayDefinedAndNotEmpty(this.vaccinations);
	}

	public get vaccinationYears(): Array<number> {
		return [...new Set(this.vaccinations.map(vaccination => vaccination.vaccinationYear))]
			.sort((a, b) => b - a);
	}

	public get vaccines(): Array<DescribedComboboxModel> {
		const vaccines = this.vaccinations.map(vaccination => vaccination.vaccine);

		return [...new Set(vaccines.map(vaccine => vaccine.id))]
			.map(vaccineId => vaccines.find(vaccine => vaccine.id === vaccineId))
			.sort((combobox1, combobox2) => combobox1.displayText.localeCompare(combobox2.displayText));
	}

	public get vaccinatedFromDiseases(): Array<DescribedComboboxModel> {
		const vaccinatedFromDiseases = this.vaccinations
			.map(vaccination => vaccination.vaccinatedFromDiseases)
			.reduce((pv, cv) => pv.concat(cv), []);

		return [...new Set(vaccinatedFromDiseases.map(disease => disease.id))]
			.map(diseaseId => vaccinatedFromDiseases.find(disease => disease.id === diseaseId))
			.sort((combobox1, combobox2) => combobox1.displayText.localeCompare(combobox2.displayText));
	}

	constructor(
		patientId: string,
		vaccinations: Array<VaccinationModel>
	) {

		// TODO: Guard check on defined

		if (vaccinations.length !== 0) {
			// TODO: Guard check on consistent model
			const patientIds = [...new Set(vaccinations.map(vaccination => vaccination.patientId))];
			if (patientIds.length !== 1 || patientIds[0] !== patientId) {
				throw new Error('Inconsistent model');
			}
		}

		this.patientId = patientId;
		this.vaccinations = vaccinations;
	}

	public vaccinationsByYear(year: number): Array<VaccinationModel> {
		return this.vaccinations
			.filter(item => item.vaccinationYear === year)
			.sort((a, b) => -diffDateInMs(a.vaccinationDate, b.vaccinationDate));
	}

	public vaccinationsByDiseaseId(diseaseId: string): Array<VaccinationModel> {
		return this.vaccinations
			.filter(item => item.vaccinatedFromDiseases.findIndex(disease => disease.id === diseaseId) >= 0)
			.sort((a, b) => -diffDateInMs(a.vaccinationDate, b.vaccinationDate));
	}

	public vaccinationsByVaccineId(vaccineId: string): Array<VaccinationModel> {
		return this.vaccinations
			.filter(item => item.vaccine.id === vaccineId)
			.sort((a, b) => -diffDateInMs(a.vaccinationDate, b.vaccinationDate));
	}

}
