import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RegisterAccountParameter, RegistrationClient } from '@nmn-communication/accounts';
import { Failure } from '@nmn-communication/shared';
import { EmptyResult } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { createEmptyResultFromResponse, createFailedEmptyResultFromErrorResponse } from '../../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { RegistrationHttpClientConfiguration } from '../configurations/registration.http-client.configuration';

export class RegistrationHttpClient extends RegistrationClient {

	constructor(
		private readonly configuration: RegistrationHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public registerAccount(parameter: RegisterAccountParameter): Observable<EmptyResult<Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`;
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post(url, payload, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

}
