export class FailureDialogDirectoryModel {

	public readonly failureHints: Array<string>;

	constructor(
		failureHints: Array<string>
	) {
		// TODO: Guard
		this.failureHints = failureHints;
	}

}
