export class NmnBooleanUserConfiguration {

	public value: boolean;

	constructor(value: boolean) {
		this.value = value;
	}

	public static createWithParameters(value: boolean): NmnBooleanUserConfiguration {
		return new NmnBooleanUserConfiguration(value);
	}

	public static createDefault(): NmnBooleanUserConfiguration {
		return new NmnBooleanUserConfiguration(false);
	}
}
