import { Injector, Provider } from '@angular/core';
import { AzureStorageClientFactory } from '@nmn-communication/azure-file-storage';
import { azureStorageHttpClientFactoryFactory } from './factories/azure-storage.factory.factory';

export const azureStorageProviders: Array<Provider> = [
	{
		provide: AzureStorageClientFactory,
		useFactory: azureStorageHttpClientFactoryFactory,
		deps: [Injector]
	}
];
