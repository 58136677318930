import { Injector, Provider } from '@angular/core';
import { MedicationDosageTypeClient } from '@nmn-communication/medication-dosage-types';
import { medicationDosageTypeClientFactory } from './factories/medication-dosage-type.client.factory';

export const medicationDosageTypeProviders: Array<Provider> = [
	{
		provide: MedicationDosageTypeClient,
		useFactory: medicationDosageTypeClientFactory,
		deps: [Injector]
	}
];
