import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { MedicationAdmRouteHttpClientConfiguration } from '../configurations/medication-adm-route.http-client.configuration';

export class MedicationAdmRouteHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<MedicationAdmRouteHttpClientConfiguration> {

	public build(): MedicationAdmRouteHttpClientConfiguration {
		return new MedicationAdmRouteHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
