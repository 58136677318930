import { Result } from '@nmn-core/shared';
import { EmptyCommandResult, FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { CurrentUserConfigurationUpdateCommand } from '../models/commands/current-user-configuration.update-command';

export abstract class CurrentUserConfigurationCommandHandlerService {

	public abstract update(
		command: CurrentUserConfigurationUpdateCommand
	): Observable<Result<EmptyCommandResult<CurrentUserConfigurationUpdateCommand>, FailureModel>>;

}
