import { DoctorDto } from '@nmn-communication/doctors';
import { DoctorModel } from '@nmn-domain/doctors';

export const mapDtoToModel = (dto: DoctorDto): DoctorModel => (
	new DoctorModel(
		dto.fullName,
		dto.contacts
	)
);

export const mapModelToDto = (model: DoctorModel): DoctorDto => (
	{
		fullName: model.fullName,
		contacts: model.contacts
	}
);
