import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { PatientGetQuery, PatientProfileInformationBlockModel, PatientProfileResolver, PatientQueryHandlerService } from '@nmn-domain/patients';
import { RouteParameter } from '@nmn-middleware/navigation';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PatientProfileViaQueryHandlerResolver extends PatientProfileResolver {

	constructor(
		private readonly patientQueryHandlerService: PatientQueryHandlerService
	) {
		super();
	}

	public resolve(
		route: ActivatedRouteSnapshot,
		_: RouterStateSnapshot
	): Observable<PatientProfileInformationBlockModel> | Promise<PatientProfileInformationBlockModel> | PatientProfileInformationBlockModel {
		const patientId = route.paramMap.get(RouteParameter.PatientAlias);

		if (!isStringDefinedAndNotEmpty(patientId)) {
			return undefined;
		}

		return this.patientQueryHandlerService
			.getProfile(PatientGetQuery.createByAlias(patientId))
			.pipe(map(result => result.successOrDefault(() => undefined)));
	}

}
