import { DescribedComboboxModel } from '@nmn-domain/shared';

export class AllergyIntoleranceModel {

	public readonly allergy: DescribedComboboxModel;
	public readonly criticality: DescribedComboboxModel;
	public readonly status: DescribedComboboxModel;
	public readonly verificationStatus: DescribedComboboxModel;
	public readonly lastOccurrence?: Date;

	public constructor(
		allergy: DescribedComboboxModel,
		criticality: DescribedComboboxModel | undefined,
		status: DescribedComboboxModel | undefined,
		verificationStatus: DescribedComboboxModel | undefined,
		lastOccurrence: Date | undefined
	) {
		// TODO: Guard check on defined
		this.allergy = allergy;
		this.criticality = criticality;
		this.status = status;
		this.verificationStatus = verificationStatus;
		this.lastOccurrence = lastOccurrence;
	}

}
