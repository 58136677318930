export class PageTableConfiguration {

	public readonly columns: Array<string>;

	constructor(
		columns: Array<string>
	) {
		this.columns = columns;
	}

}
