import { Injector } from '@angular/core';
import { ServerConfigurationClient } from '@nmn-communication/server-configuration';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { ServerConfigurationFakeClientConfigurationBuilder } from '../builders/server-configuration.fake-client-configuration.builder';
import { ServerConfigurationFakeClientBuilder } from '../builders/server-configuration.fake-client.builder';
import { ServerConfigurationHttpClientBuilder } from '../builders/server-configuration.http-client.builder';
import { ServerConfigurationHttpClientConfigurationBuilder } from '../builders/server-configuration.http-client.configuration.builder';
import { ServerConfigurationFakeClient } from '../services/server-configuration.fake-client';
import { ServerConfigurationHttpClient } from '../services/server-configuration.http-client';

export const serverConfigurationClientFactory = (injector: Injector): ServerConfigurationClient => {
	const useFake = environment?.api?.resources?.serverConfiguration?.useFake ||
		!isValueDefined(environment?.api?.resources?.serverConfiguration?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		serverConfigurationFakeClientFactory(injector) :
		serverConfigurationHttpClientFactory(injector);
};

export const serverConfigurationFakeClientFactory = (injector: Injector): ServerConfigurationFakeClient => {
	const configuration = new ServerConfigurationFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.serverConfiguration.serverDelayMs)
		.build();

	return new ServerConfigurationFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const serverConfigurationHttpClientFactory = (injector: Injector): ServerConfigurationHttpClient => {
	const configuration = new ServerConfigurationHttpClientConfigurationBuilder()
		.withBaseUrl(environment?.api?.resources?.serverConfiguration?.baseUrl ?? environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.serverConfiguration.resourceRelativePath)
		.withResourceTypeVersion(environment?.api?.resources?.serverConfiguration?.resourceTypeVersion ?? environment?.api?.defaultResourceTypeVersion)
		.build();

	return new ServerConfigurationHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
