import { Injectable } from '@angular/core';
import { SessionStorageRepositoryService } from '../repositories/session-storage.repository.service';

@Injectable()
export class SessionStorageService {

	private readonly storageKeys = {};

	constructor(
		private readonly sessionStorageRepository: SessionStorageRepositoryService
	) { }

}
