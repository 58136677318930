import { PatientAnamnesisModel } from '../patient-anamnesis.model';
import { PatientLifestyleModel } from '../patient-lifestyle.model';
import { PatientCreateCommand } from './patient.create.command';

export class PatientUpdateCommand extends PatientCreateCommand {

	public id: string;

	constructor(
		picture: string | undefined,
		givenName: string,
		additionalName: string | undefined,
		familyName: string,
		populationId: string,
		genderId: string,
		birthday: Date,
		phoneNumber: string | undefined,
		email: string,
		address: string | undefined,
		languageIds: Array<string>,
		preferredLanguageId: string,
		bloodTypeId: string | undefined,
		height: number | undefined,
		weight: number | undefined,
		allergyIntolerances: Array<{
			allergyId: string;
			criticalityId: string;
			statusId: string;
			verificationStatusId: string;
			lastOccurrence?: Date;
		}>,
		seriousPathologyIds: Array<string>,
		familyDiseaseIds: Array<string>,
		regularlyTakenMedicationIds: Array<string>,
		doctors: Array<{ fullName: string; contacts: string; }>,
		emergencyContacts: Array<{ fullName: string; data: string; }>,
		insurances: Array<{ company: string; code: string; }>,
		anamnesis: PatientAnamnesisModel,
		lifestyle: PatientLifestyleModel,
		id: string
	) {
		super(
			picture, givenName, additionalName, familyName, populationId, genderId, birthday,
			phoneNumber, email, address, languageIds, preferredLanguageId,
			bloodTypeId, height, weight,
			allergyIntolerances, seriousPathologyIds, familyDiseaseIds, regularlyTakenMedicationIds,
			doctors, emergencyContacts, insurances, anamnesis, lifestyle
		);

		// TODO: add Guard checks
		this.id = id;
	}

}
