import { Injector, Provider } from '@angular/core';
import { MedicationBodySiteClient } from '@nmn-communication/medication-body-sites';
import { MedicationBodySiteClientFactory } from './factories/medication-body-site.client.factory';

export const medicationBodySiteProviders: Array<Provider> = [
	{
		provide: MedicationBodySiteClient,
		useFactory: MedicationBodySiteClientFactory,
		deps: [Injector]
	}
];
