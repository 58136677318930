import { isValueDefined } from '@nmn-core/utils';

export enum TreatmentCheckerReportDiseaseReportInfectiousType {

	Undefined = 'undefined',

	NonInfectious = 'nonInfectious',
	Viral = 'viral',
	Bacterial = 'bacterial',
	Mycosis = 'mycosis',
	ParasiticInfectionsProtists = 'parasiticInfectionsProtists',
	ParasiticInfectionsAnimals = 'parasiticInfectionsAnimals',
	Fungi = 'fungi',
	Sexual = 'sexual'

}

export const mapTreatmentCheckerReportDiseaseReportInfectiousTypeFromString = (
	stringValue: string
): TreatmentCheckerReportDiseaseReportInfectiousType => {
	const enumValue = Object
		.keys(TreatmentCheckerReportDiseaseReportInfectiousType)
		.find(x => TreatmentCheckerReportDiseaseReportInfectiousType[x] === stringValue);

	return isValueDefined(enumValue) ?
		stringValue as TreatmentCheckerReportDiseaseReportInfectiousType :
		TreatmentCheckerReportDiseaseReportInfectiousType.Undefined;
};
