import { Provider } from '@angular/core';
import { TypeOfMedicationQueryHandlerService } from '@nmn-domain/types-of-medication';
import { TypeOfMedicationQueryHandlerViaClientService } from './services/type-of-medication.query-handler-via-client.service';

export const typeOfMedicationProviders: Array<Provider> = [
	{
		provide: TypeOfMedicationQueryHandlerService,
		useClass: TypeOfMedicationQueryHandlerViaClientService
	}
];
