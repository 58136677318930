import { Injectable } from '@angular/core';
import { HealthIssueClient } from '@nmn-communication/health-issues';
import { Result } from '@nmn-core/shared';
import { HealthIssueComboboxModel, HealthIssueFilterModel, HealthIssueGetQuery, HealthIssueModel, HealthIssueQueryHandlerService } from '@nmn-domain/health-issues';
import { FailureModel, PagedCollectionModel, PageOptionsQuery } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapDtoArrayResultToModelArrayResult, mapPageOptionsQueryToDto } from '../../shared/factories/pagination.factory';
import { mapHealthIssueComboboxDtoToModel } from '../factories/health-issue-combobox.factory';
import { mapDtoToModel, mapFilterModelToDto, mapHealthIssuePagedCollectionDtoToModel } from '../factories/health-issue.factory';

@Injectable()
export class HealthIssueQueryHandlerViaClientService extends HealthIssueQueryHandlerService {

	constructor(
		private readonly client: HealthIssueClient
	) {
		super();
	}

	public getAsDescribedComboboxes(filter: HealthIssueFilterModel): Observable<Result<Array<HealthIssueComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapHealthIssueComboboxDtoToModel))
			);
	}

	public getPagedCollection(query: PageOptionsQuery<HealthIssueFilterModel>): Observable<Result<PagedCollectionModel<HealthIssueModel, HealthIssueFilterModel>, FailureModel>> {
		return this.client
			.getBulk(mapPageOptionsQueryToDto(query, mapFilterModelToDto))
			.pipe(
				map(result => result.map(mapHealthIssuePagedCollectionDtoToModel, mapFailureToFailureModel))
			);
	}

	public get(query: HealthIssueGetQuery): Observable<Result<HealthIssueModel, FailureModel>> {
		return this.client
			.get({ id: query.id, patientId: query.patientId })
			.pipe(
				map(result => result.map(mapDtoToModel, mapFailureToFailureModel))
			);
	}

}
