import { Result } from '@nmn-core/shared';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { MedicationComboboxModel } from '../models/medication-combobox.model';
import { MedicationFilterModel } from '../models/medication-filter.model';

export abstract class MedicationQueryHandlerService {

	public abstract getAsDescribedComboboxes(query: MedicationFilterModel): Observable<Result<Array<MedicationComboboxModel>, FailureModel>>;

}
