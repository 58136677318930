export class AttachNativeLoginDialogInModel {

	public static createEmpty(): AttachNativeLoginDialogInModel {
		return new AttachNativeLoginDialogInModel();
	}

	public static mergeWithDefaultSafety(
		_?: AttachNativeLoginDialogInModel
	): AttachNativeLoginDialogInModel {
		return new AttachNativeLoginDialogInModel();
	}

}
