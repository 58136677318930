import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { GeneHttpClientConfiguration } from '../configurations/gene.http-client.configuration';
import { GeneHttpClient } from '../services/gene.http-client';

export class GeneHttpClientBuilder extends HttpClientBuilder<GeneHttpClient, GeneHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): GeneHttpClient {
		return new GeneHttpClient(this.configuration, this.injector.get<HttpClient>(HttpClient));
	}

}
