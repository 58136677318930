import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { PagedCollectionModel, PageOptionsQuery } from '../../shared';
import { FailureModel } from '../../shared/failures/failure.model';
import { PatientFilterModel } from '../models/patient-filter.model';
import { PatientProfileInformationBlockModel } from '../models/patient-profile/patient-profile-information-block.model';
import { PatientModel } from '../models/patient.model';
import { PatientGetQuery } from '../models/queries/patient.get.query';

export abstract class PatientQueryHandlerService {

	public abstract getPagedCollection(
		query: PageOptionsQuery<PatientFilterModel>
	): Observable<Result<PagedCollectionModel<PatientModel, PatientFilterModel>, FailureModel>>;

	public abstract getActivePatients(): Observable<Result<Array<PatientModel>, FailureModel>>;

	public abstract get(query: PatientGetQuery): Observable<Result<PatientModel, FailureModel>>;

	public abstract getProfile(query: PatientGetQuery): Observable<Result<PatientProfileInformationBlockModel, FailureModel>>;

}
