import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HealthIssueSeverityComboboxModel } from '../models/health-issue-severity-combobox.model';

export abstract class HealthIssueSeveritiesResolver implements Resolve<Array<HealthIssueSeverityComboboxModel>> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	): Observable<Array<HealthIssueSeverityComboboxModel>> | Array<HealthIssueSeverityComboboxModel>;

}
