import { isDefined, TranslocoService } from '@ngneat/transloco';
import { isValueDefined } from '@nmn-core/utils';
import { FakeLocalizableEntity } from '../models/fake-localizable-entity';

export const setTranslation = (
	translocoService: TranslocoService,
	localizableEntity: FakeLocalizableEntity | undefined
): string | undefined => {
	if (isValueDefined(localizableEntity)) {
		const language = translocoService.getActiveLang();
		const translation = localizableEntity[language];

		return isDefined(translation) ? translation : isDefined(localizableEntity.en) ? localizableEntity.en : localizableEntity.uk;
	}

	return undefined;
};

export const setLocalizableEntity = (
	translation: string | undefined,
	language: string
): FakeLocalizableEntity => {
	switch (language) {
		case 'uk':
			return {
				en: undefined,
				uk: translation
			};
		default:
			return {
				en: translation,
				uk: undefined
			};
	}
};
