export class UserProfileGetQuery {

	public id: string;

	constructor(
		id: string
	) {
		this.id = id;
	}

}
