import { AllergyFilterDto } from '@nmn-communication/allergies';
import { AllergyFilterModel } from '@nmn-domain/allergies';

export const mapFilterModelToDto = (model: AllergyFilterModel): AllergyFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern
	}
);

export const mapFilterDtoToModel = (dto: AllergyFilterDto): AllergyFilterModel => (
	new AllergyFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern
	)
);
