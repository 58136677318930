import { isValueDefined } from '@nmn-core/utils';

export enum TreatmentCheckerReportMedicationPgxLevelTestingType {

	Undefined = 'undefined',

	TestingRequired = 'testingRequired',
	TestingRecommended = 'testingRecommended',
	ActionablePgx = 'actionablePgx',
	InformativePgx = 'informativePgx'

}

export const mapTreatmentCheckerReportMedicationPgxLevelTestingTypeFromString = (
	stringValue: string
): TreatmentCheckerReportMedicationPgxLevelTestingType => {
	const enumValue = Object
		.keys(TreatmentCheckerReportMedicationPgxLevelTestingType)
		.find(x => TreatmentCheckerReportMedicationPgxLevelTestingType[x] === stringValue);

	return isValueDefined(enumValue) ?
		stringValue as TreatmentCheckerReportMedicationPgxLevelTestingType :
		TreatmentCheckerReportMedicationPgxLevelTestingType.Undefined;
};
