import { Injector } from '@angular/core';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { isValueDefined } from '@nmn-core/utils';
import { TypeOfMedicationFakeClientConfiguration } from '../configurations/type-of-medication.fake-client.configuration';
import { TypeOfMedicationFakeClient } from '../services/type-of-medication.fake-client';

export class TypeOfMedicationFakeClientBuilder {

	private configuration: TypeOfMedicationFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(
		configuration: TypeOfMedicationFakeClientConfiguration
	): TypeOfMedicationFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): TypeOfMedicationFakeClient {
		return new TypeOfMedicationFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
