import { Injector } from '@angular/core';
import { HealthComplaintClient } from '@nmn-communication/health-complaints';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { HealthComplaintFakeClientBuilder } from '../builders/health-complaint.fake-client.builder';
import { HealthComplaintFakeClientConfigurationBuilder } from '../builders/health-complaint.fake-client.configuration.builder';
import { HealthComplaintHttpClientBuilder } from '../builders/health-complaint.http-client.builder';
import { HealthComplaintHttpClientConfigurationBuilder } from '../builders/health-complaint.http-client.configuration.builder';
import { HealthComplaintFakeClient } from '../services/health-complaint.fake-client';
import { HealthComplaintHttpClient } from '../services/health-complaint.http-client';

export const healthComplaintClientFactory = (injector: Injector): HealthComplaintClient => {
	const useFake = environment?.api?.resources?.healthComplaints?.useFake === true ||
		!isValueDefined(environment?.api?.resources?.healthComplaints?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		healthComplaintFakeClientFactory(injector) :
		healthComplaintHttpClientFactory(injector);
};

export const healthComplaintFakeClientFactory = (
	injector: Injector
): HealthComplaintFakeClient => {
	const configuration = new HealthComplaintFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.healthComplaints.serverDelayMs)
		.build();

	return new HealthComplaintFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const healthComplaintHttpClientFactory = (
	injector: Injector
): HealthComplaintHttpClient => {
	const configuration = new HealthComplaintHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.healthComplaints?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.healthComplaints.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.healthComplaints?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new HealthComplaintHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
