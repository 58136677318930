import { Injectable } from '@angular/core';
import { filterTrue, isFunctionDefined, isNumberDefined, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { notProvided } from '@nmn-domain/server-configuration/constants';
import { MonitoringService } from 'app/services';
import { BehaviorSubject } from 'rxjs';
import { GoogleCredentialResponse } from '../models/google-credential-response';
import { GsiButtonConfiguration } from '../models/gsi-button-configuration';

declare const google;

// @see https://developers.google.com/identity/gsi/web/guides/overview
@Injectable({ providedIn: 'root' })
export class GoogleAuthService {

	private readonly isGoogleApiReadySubject$ = new BehaviorSubject<boolean>(false);

	public clientId: string;
	public get isClientIdDefined(): boolean { return isStringDefinedAndNotEmpty(this.clientId) && this.clientId !== notProvided; }

	private get gsiApi() { return google; }

	constructor(
		private readonly monitoringService: MonitoringService
	) { }

	public initialize(googleClientId: string): void {
		this.clientId = googleClientId;
		this.loadGsiScript();
	}

	public renderGoogleButton(
		containerElementId: string,
		handleCredentialResponseCallback: (googleCredentialResponse: GoogleCredentialResponse) => void,
		gsiButtonConfiguration: GsiButtonConfiguration | undefined = undefined,
		elementIdToGetWidth: string | undefined = undefined,
		enablePrompt: boolean = false
	): void {
		this.isGoogleApiReadySubject$
			.pipe(filterTrue())
			.subscribe({
				next: _ => {
					this.renderBtn(
						containerElementId,
						handleCredentialResponseCallback,
						this.buildGsiButtonConfiguration(gsiButtonConfiguration, elementIdToGetWidth),
						enablePrompt
					);
				},
				error: error => {
					this.monitoringService.logException(error);
				}
			});
	}

	private loadGsiScript(): void {
		const gsiScript = document.createElement('script');

		gsiScript.src = 'https://accounts.google.com/gsi/client';
		gsiScript.async = true;
		gsiScript.defer = true;
		gsiScript.onload = () => {
			if (isValueDefined((window as any).google)) {
				this.isGoogleApiReadySubject$.next(true);
			}
		};

		document.body.appendChild(gsiScript);
	}

	private buildGsiButtonConfiguration(
		configuration: GsiButtonConfiguration | undefined = undefined,
		elementIdToGetWidth: string | undefined = undefined
	): GsiButtonConfiguration {
		const toGetWidth = Math.floor(document.getElementById(elementIdToGetWidth)?.getBoundingClientRect()?.width);

		return !isValueDefined(configuration) ?
			GsiButtonConfiguration.createDefault(isNumberDefined(toGetWidth) && toGetWidth > 0 ? toGetWidth : undefined) :
			isNumberDefined(toGetWidth) && toGetWidth > 0 ?
				new GsiButtonConfiguration(
					configuration.type,
					configuration.theme,
					configuration.size,
					configuration.text,
					configuration.shape,
					configuration.logo_alignment,
					toGetWidth,
					configuration.locale
				) :
				configuration;
	}

	private renderBtn(
		containerElementId: string,
		handleCredentialResponseCallback: (googleCredentialResponse: GoogleCredentialResponse) => void,
		btnConfiguration: GsiButtonConfiguration,
		enablePrompt: boolean
	): void {
		const containerElement = document.getElementById(containerElementId);

		if (this.isClientIdDefined && isValueDefined(containerElement)) {
			const nativeElementConfiguration = {
				client_id: this.clientId,
				callback: async (credentialResponseObj: any) => {
					if (isFunctionDefined(handleCredentialResponseCallback)) {
						handleCredentialResponseCallback(GoogleCredentialResponse.createFromCredentialResponse(credentialResponseObj));
					}
				},
				auto_select: false,
				cancel_on_tap_outside: true
			};

			this.gsiApi.accounts.id.initialize(nativeElementConfiguration);
			this.gsiApi.accounts.id.renderButton(containerElement, btnConfiguration);

			if (enablePrompt) {
				this.gsiApi.accounts.id.prompt((_) => { });
			}
		}
	}

}
