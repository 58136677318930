import { isValueDefined } from '@nmn-core/utils';
import { DocumentUploadConfiguration } from './document-upload.configuration';
import { GeneticFileUploadConfiguration } from './genetic-file-upload.configuration';
import { ImageUploadConfiguration } from './image-upload.configuration';

export class FileUploadConfiguration {

	public readonly restrictedExtensions: Array<string>;
	public readonly fileMaxSize: number;

	public readonly storageCapacity: number;

	public readonly imageUploadConfiguration: ImageUploadConfiguration;
	public readonly documentUploadConfiguration: DocumentUploadConfiguration;
	public readonly geneticFileUploadConfiguration: GeneticFileUploadConfiguration;

	public constructor(
		restrictedExtensions: Array<string>,
		fileMaxSize: number,
		storageCapacity: number,
		imageUploadConfiguration: ImageUploadConfiguration,
		documentUploadConfiguration: DocumentUploadConfiguration,
		geneticFileUploadConfiguration: GeneticFileUploadConfiguration
	) {
		isValueDefined(restrictedExtensions) ?
			this.restrictedExtensions = restrictedExtensions :
			this.restrictedExtensions = fileUploadRestrictedExtensionsDefault;
		isValueDefined(fileMaxSize) ?
			this.fileMaxSize = fileMaxSize :
			this.fileMaxSize = fileUploadMaxSizeDefault;
		isValueDefined(storageCapacity) ?
			this.storageCapacity = storageCapacity :
			this.storageCapacity = storageCapacityDefault;
		isValueDefined(imageUploadConfiguration) ?
			this.imageUploadConfiguration = imageUploadConfiguration :
			this.imageUploadConfiguration = ImageUploadConfiguration.createDefault();
		isValueDefined(documentUploadConfiguration) ?
			this.documentUploadConfiguration = documentUploadConfiguration :
			this.documentUploadConfiguration = DocumentUploadConfiguration.createDefault();
		isValueDefined(geneticFileUploadConfiguration) ?
			this.geneticFileUploadConfiguration = geneticFileUploadConfiguration :
			this.geneticFileUploadConfiguration = GeneticFileUploadConfiguration.createDefault();
	}

	public static createDefault(): FileUploadConfiguration {
		return new FileUploadConfiguration(
			fileUploadRestrictedExtensionsDefault,
			fileUploadMaxSizeDefault,
			storageCapacityDefault,
			ImageUploadConfiguration.createDefault(),
			DocumentUploadConfiguration.createDefault(),
			GeneticFileUploadConfiguration.createDefault()
		);
	}
}

export const fileUploadRestrictedExtensionsDefault = ['.exe', '.bat'];
export const fileUploadMaxSizeDefault = 200 * 1024 * 1024;
export const storageCapacityDefault = 1 * 1024 * 1024 * 1024;
