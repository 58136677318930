import { Injector, Provider } from '@angular/core';
import { ResetPasswordClient } from '@nmn-communication/accounts';
import { resetPasswordClientFactory } from './factories/email-confirmation.client.factory';

export const accountsResetPasswordProviders: Array<Provider> = [
	{
		provide: ResetPasswordClient,
		useFactory: resetPasswordClientFactory,
		deps: [Injector]
	}
];
