export class TreatmentCheckerYourDataFormOutModel {

	public readonly email: string;
	public readonly diseaseIds: Array<string>;
	public readonly medicationIds: Array<string>;

	constructor(
		email: string,
		diseaseIds: Array<string>,
		medicationIds: Array<string>
	) {
		this.email = email;
		this.diseaseIds = diseaseIds;
		this.medicationIds = medicationIds;
	}

}
