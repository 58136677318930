import {
	DoctorEncounterClient, DoctorEncounterComboboxDto, DoctorEncounterCreateParameterDto, DoctorEncounterDto,
	DoctorEncounterFilterDto, DoctorEncounterFindParameterDto, DoctorEncounterUpdateParameterDto
} from '@nmn-communication/doctor-encounters';
import { Failure, PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { wrapActionCallAsFakeClientResponse, wrapFuncCallAsFakeClientResponse } from '../../../../modules/fakes/databases/fake.utils';
import { DoctorEncounterFakeClientConfiguration } from '../configurations/doctor-encounter.fake-client.configuration';

export class DoctorEncounterFakeClient extends DoctorEncounterClient {

	constructor(
		private readonly configuration: DoctorEncounterFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulkAsComboboxes(
		filter: DoctorEncounterFilterDto
	): Observable<Result<Array<DoctorEncounterComboboxDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.doctorEncounterTable.getComboboxesPagedCollection(filter),
			this.configuration.serverDelayMs
		);
	}

	public getBulk(
		parameter: PageOptionsDto<DoctorEncounterFilterDto>
	): Observable<Result<PagedCollectionDto<DoctorEncounterDto, DoctorEncounterFilterDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.doctorEncounterTable.getPagedCollection(parameter),
			this.configuration.serverDelayMs
		);
	}

	public get(parameter: DoctorEncounterFindParameterDto): Observable<Result<DoctorEncounterDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.doctorEncounterTable.find(parameter),
			this.configuration.serverDelayMs
		);
	}

	public create(parameter: DoctorEncounterCreateParameterDto): Observable<Result<string, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.doctorEncounterTable.create(parameter),
			this.configuration.serverDelayMs
		);
	}

	public update(
		findParameter: DoctorEncounterFindParameterDto,
		updateParameter: DoctorEncounterUpdateParameterDto
	): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => { this.fakeDatabase.doctorEncounterTable.update(findParameter, updateParameter); },
			this.configuration.serverDelayMs
		);
	}

	public delete(
		parameter: DoctorEncounterFindParameterDto
	): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => { this.fakeDatabase.doctorEncounterTable.delete(parameter); },
			this.configuration.serverDelayMs
		);
	}

}
