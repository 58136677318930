import { Injector } from '@angular/core';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { isValueDefined } from '@nmn-core/utils';
import { MedicationDosageTypeFakeClientConfiguration } from '../configurations/medication-dosage-type.fake-client.configuration';
import { MedicationDosageTypeFakeClient } from '../services/medication-dosage-type.fake-client';

export class MedicationDosageTypeFakeClientBuilder {

	private configuration: MedicationDosageTypeFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(
		configuration: MedicationDosageTypeFakeClientConfiguration
	): MedicationDosageTypeFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): MedicationDosageTypeFakeClient {
		return new MedicationDosageTypeFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
