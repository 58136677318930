import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { PatientHttpClientConfiguration } from '../configurations/patient.http-client.configuration';

export class PatientHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<PatientHttpClientConfiguration> {

	public build(): PatientHttpClientConfiguration {
		return new PatientHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
