import { booleanToNumber, isStringDefinedAndNotEmpty, numberToBoolean } from '@nmn-core/utils';

export class TreatmentCheckerReportLifestyleModel {

	public readonly isPregnant?: boolean;
	public readonly isActiveLifestyle?: boolean;
	public readonly isAlcohol?: boolean;
	public readonly isOnHealthyDiet?: boolean;
	public readonly isSmoking?: boolean;
	public readonly isUnderHighLevelOfStress?: boolean;
	public readonly isUsingNutritionalSupplements?: boolean;

	constructor(
		isPregnant: boolean | undefined,
		isActiveLifestyle: boolean | undefined,
		isAlcohol: boolean | undefined,
		isOnHealthyDiet: boolean | undefined,
		isSmoking: boolean | undefined,
		isUnderHighLevelOfStress: boolean | undefined,
		isUsingNutritionalSupplements: boolean | undefined
	) {
		this.isPregnant = isPregnant;
		this.isActiveLifestyle = isActiveLifestyle;
		this.isAlcohol = isAlcohol;
		this.isOnHealthyDiet = isOnHealthyDiet;
		this.isSmoking = isSmoking;
		this.isUnderHighLevelOfStress = isUnderHighLevelOfStress;
		this.isUsingNutritionalSupplements = isUsingNutritionalSupplements;
	}

	public getCode(): string {
		const isPregnantCode = booleanToNumber(this.isPregnant) ?? undefinedBooleanCode;
		const isActiveLifestyleCode = booleanToNumber(this.isActiveLifestyle) ?? undefinedBooleanCode;
		const isAlcoholCode = booleanToNumber(this.isAlcohol) ?? undefinedBooleanCode;
		const isOnHealthyDietCode = booleanToNumber(this.isOnHealthyDiet) ?? undefinedBooleanCode;
		const isSmokingCode = booleanToNumber(this.isSmoking) ?? undefinedBooleanCode;
		const isUnderHighLevelOfStressCode = booleanToNumber(this.isUnderHighLevelOfStress) ?? undefinedBooleanCode;
		const isUsingNutritionalSupplementsCode = booleanToNumber(this.isUsingNutritionalSupplements) ?? undefinedBooleanCode;

		return `${isPregnantCode}${isActiveLifestyleCode}${isAlcoholCode}${isOnHealthyDietCode}${isSmokingCode}${isUnderHighLevelOfStressCode}${isUsingNutritionalSupplementsCode}`;
	}

	public static createEmpty(): TreatmentCheckerReportLifestyleModel {
		return new TreatmentCheckerReportLifestyleModel(undefined, undefined, undefined, undefined, undefined, undefined, undefined);
	}

	public static createByCode(code: string): TreatmentCheckerReportLifestyleModel {
		return isStringDefinedAndNotEmpty(code) && code.length >= 7 ?
			new TreatmentCheckerReportLifestyleModel(
				TreatmentCheckerReportLifestyleModel.decodeProperty(code[0]),
				TreatmentCheckerReportLifestyleModel.decodeProperty(code[1]),
				TreatmentCheckerReportLifestyleModel.decodeProperty(code[2]),
				TreatmentCheckerReportLifestyleModel.decodeProperty(code[3]),
				TreatmentCheckerReportLifestyleModel.decodeProperty(code[4]),
				TreatmentCheckerReportLifestyleModel.decodeProperty(code[5]),
				TreatmentCheckerReportLifestyleModel.decodeProperty(code[6])
			) :
			TreatmentCheckerReportLifestyleModel.createEmpty();
	}

	private static decodeProperty(codedSymbol: string): boolean | undefined {
		return codedSymbol.length != 1 || codedSymbol === undefinedBooleanCode ? undefined : numberToBoolean(Number(codedSymbol));
	}

}

const undefinedBooleanCode = '2';
