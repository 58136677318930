import { Injector } from '@angular/core';
import { FakeDatabase } from '../../../../../modules/fakes/databases/fake.database';
import { isValueDefined } from '@nmn-core/utils';
import { ResetPasswordFakeClientConfiguration } from '../configurations/reset-password.rest.fake-client.configuration';
import { ResetPasswordFakeClient } from '../services/reset-password.fake-client';

export class ResetPasswordFakeClientBuilder {

	private configuration: ResetPasswordFakeClientConfiguration;

	constructor(private readonly injector: Injector) { }

	public withConfiguration(
		configuration: ResetPasswordFakeClientConfiguration
	): ResetPasswordFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): ResetPasswordFakeClient {
		return new ResetPasswordFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
