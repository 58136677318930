import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { FileUploaderHttpClientConfiguration } from '../configurations/file-uploader-http-client.configuration';
import { FileUploaderHttpClient } from '../services/file-uploader.http-client';

export class FileUploaderHttpClientBuilder
	extends HttpClientBuilder<FileUploaderHttpClient, FileUploaderHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): FileUploaderHttpClient {
		return new FileUploaderHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
