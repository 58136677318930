import { isValueDefined } from '@nmn-core/utils';
import { LanguageFakeClientConfiguration } from '../configurations/language.fake-client.configuration';

export class LanguageFakeClientConfigurationBuilder {

	private readonly serverDelayMs: { min: number, max: number } = { min: 0, max: 0 };

	public withServerDelay(
		serverDelayMs: { min: number, max: number }
	): LanguageFakeClientConfigurationBuilder {
		const isServerDelayValidAndDefined = isValueDefined(serverDelayMs) &&
			isValueDefined(serverDelayMs.min) &&
			isValueDefined(serverDelayMs.max) &&
			serverDelayMs.min <= serverDelayMs.max;

		if (isServerDelayValidAndDefined) {
			this.serverDelayMs.min = serverDelayMs.min;
			this.serverDelayMs.max = serverDelayMs.max;
		}

		return this;
	}

	public build(): LanguageFakeClientConfiguration {
		return new LanguageFakeClientConfiguration(this.serverDelayMs);
	}

}
