import { Injectable } from '@angular/core';
import { MedicationAdmRouteClient } from '@nmn-communication/medication-adm-routes';
import { Result } from '@nmn-core/shared';
import { MedicationAdmRouteDescribedComboboxModel, MedicationAdmRouteFilterModel, MedicationAdmRouteQueryHandlerService } from '@nmn-domain/medication-adm-routes';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapDtoArrayResultToModelArrayResult } from '../../shared/factories/pagination.factory';
import { mapFilterModelToDto, mapMedicationAdmRouteDescribedComboboxDtoToModel } from '../factories/medication-adm-route.factory';

@Injectable()
export class MedicationAdmRouteQueryHandlerViaClientService extends MedicationAdmRouteQueryHandlerService {

	constructor(
		private readonly client: MedicationAdmRouteClient
	) {
		super();
	}

	public getAsDescribedComboboxes(filter: MedicationAdmRouteFilterModel): Observable<Result<Array<MedicationAdmRouteDescribedComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapMedicationAdmRouteDescribedComboboxDtoToModel))
			);
	}

}
