export class ChangePasswordFormInModel {

	public readonly oldPassword?: string;
	public readonly newPassword?: string;
	public readonly confirmNewPassword?: string;

	private constructor(
		oldPassword: string | undefined,
		newPassword: string | undefined,
		confirmNewPassword: string | undefined
	) {
		this.oldPassword = oldPassword;
		this.newPassword = newPassword;
		this.confirmNewPassword = confirmNewPassword;
	}

	public static createEmpty = (): ChangePasswordFormInModel => {
		return new ChangePasswordFormInModel(undefined, undefined, undefined);
	}

}
