import {
	HealthIssueClient,
	HealthIssueComboboxDto,
	HealthIssueCreateParameterDto,
	HealthIssueDto,
	HealthIssueFilterDto,
	HealthIssueFindParameterDto,
	HealthIssueUpdateParameterDto
} from '@nmn-communication/health-issues';
import { Failure, PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { wrapActionCallAsFakeClientResponse, wrapFuncCallAsFakeClientResponse } from '../../../../modules/fakes/databases/fake.utils';
import { HealthIssueFakeClientConfiguration } from '../configurations/health-issue.fake-client.configuration';

export class HealthIssueFakeClient extends HealthIssueClient {

	constructor(
		private readonly configuration: HealthIssueFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulkAsComboboxes(
		filter: HealthIssueFilterDto
	): Observable<Result<Array<HealthIssueComboboxDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.healthIssueTable.getComboboxesPagedCollection(filter),
			this.configuration.serverDelayMs);
	}

	public getBulk(
		parameter: PageOptionsDto<HealthIssueFilterDto>
	): Observable<Result<PagedCollectionDto<HealthIssueDto, HealthIssueFilterDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.healthIssueTable.getPagedCollection(parameter),
			this.configuration.serverDelayMs);
	}

	public get(parameter: HealthIssueFindParameterDto): Observable<Result<HealthIssueDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.healthIssueTable.find(parameter),
			this.configuration.serverDelayMs);
	}

	public create(parameter: HealthIssueCreateParameterDto): Observable<Result<string, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.healthIssueTable.create(parameter),
			this.configuration.serverDelayMs);
	}

	public update(
		findParameter: HealthIssueFindParameterDto,
		updateParameter: HealthIssueUpdateParameterDto
	): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.healthIssueTable.update(findParameter, updateParameter);
			},
			this.configuration.serverDelayMs);
	}

	public delete(
		parameter: HealthIssueFindParameterDto
	): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.healthIssueTable.delete(parameter);
			},
			this.configuration.serverDelayMs);
	}

}
