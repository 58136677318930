import { isValueDefined } from '@nmn-core/utils';
import { UserSubscriptionFakeClientConfiguration } from '../configurations/user-subscription.fake-client.configuration';

export class UserSubscriptionFakeClientConfigurationBuilder {

	private readonly serverDelayMs: { min: number, max: number } = { min: 0, max: 0 };

	public withServerDelay(
		serverDelayMs: { min: number, max: number }
	): UserSubscriptionFakeClientConfigurationBuilder {
		if (isValueDefined(serverDelayMs) &&
			isValueDefined(serverDelayMs.min) &&
			isValueDefined(serverDelayMs.max) &&
			serverDelayMs.min <= serverDelayMs.max) {
			this.serverDelayMs.min = serverDelayMs.min;
			this.serverDelayMs.max = serverDelayMs.max;
		}

		return this;
	}

	public build(): UserSubscriptionFakeClientConfiguration {
		return new UserSubscriptionFakeClientConfiguration(this.serverDelayMs);
	}

}
