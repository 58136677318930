import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { HealthIssueSeverityComboboxDto } from '../models/health-issue-severity-combobox.dto';
import { HealthIssueSeverityFilterDto } from '../models/health-issue-severity-filter.dto';

export abstract class HealthIssueSeverityClient {

	public abstract getBulkAsComboboxes(
		filter: HealthIssueSeverityFilterDto
	): Observable<Result<Array<HealthIssueSeverityComboboxDto>, Failure>>;

}
