import { Provider } from '@angular/core';
import { CurrentUserProfileCommandHandlerService, CurrentUserProfileQueryHandlerService, TokenResolver, UserIdResolver, UserProfileResolver } from '@nmn-domain/accounts';
import { TokenFromActivatedRouteResolver } from './resolvers/token.from-activated-route.resolver';
import { UserIdFromActivatedRouteResolver } from './resolvers/user-id.from-activated-route.resolver';
import { UserProfileViaStorageResolver } from './resolvers/user-profile-via-storage.resolver';
import { UserProfileCommandHandlerViaClientService } from './services/current-user-profile.command-handler-via-client.service';
import { CurrentUserProfileQueryHandlerViaClientService } from './services/current-user-profile.query-handler-via-client.service';

export const accountsUserProfileProviders: Array<Provider> = [
	{
		provide: CurrentUserProfileCommandHandlerService,
		useClass: UserProfileCommandHandlerViaClientService
	},
	{
		provide: CurrentUserProfileQueryHandlerService,
		useClass: CurrentUserProfileQueryHandlerViaClientService
	},
	{
		provide: UserProfileResolver,
		useClass: UserProfileViaStorageResolver
	},
	{
		provide: TokenResolver,
		useClass: TokenFromActivatedRouteResolver
	},
	{
		provide: UserIdResolver,
		useClass: UserIdFromActivatedRouteResolver
	}
];
