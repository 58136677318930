import { TranslocoService } from '@ngneat/transloco';
import { AllergyIntoleranceVerificationStatusFilterDto, AllergyIntoleranceVerificationStatusFindParameterDto } from '@nmn-communication/allergy-intolerance-verification-statuses';
import { DescribedComboboxDto } from '@nmn-communication/shared';
import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../databases/fake.database';
import { getPagedCollectionWithoutDtoFilter } from '../../databases/fake.utils';
import { FakeDescribedComboboxDto, mapFakeDescriptedComboboxDtoToDesriptedComboboxDto } from '../../models/comboboxes/fake-described-combobox.dto';
import { FakeLocalizableEntity } from '../../models/fake-localizable-entity';
import { setTranslation } from '../../utils/localize';

export class AllergyIntoleranceVerificationStatusFakeTable {

	private readonly data: Array<AllergyIntoleranceVerificationStatusFakeRecord>;

	constructor(
		_: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.data = [...initialData];
	}

	public getAsCombobox(parameter: AllergyIntoleranceVerificationStatusFindParameterDto): DescribedComboboxDto<string> {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record was not found');
		}

		return record;
	}

	public findAsCombobox(parameter: AllergyIntoleranceVerificationStatusFindParameterDto): DescribedComboboxDto<string> {
		const record = this.data
			.find((item: AllergyIntoleranceVerificationStatusFakeRecord) => findPredicate(item, parameter));

		const comboboxRecord = mapFromRecordToFakeDescribedComboboxDto(record!);
		return mapFakeDescriptedComboboxDtoToDesriptedComboboxDto(
			this.translocoService,
			comboboxRecord);
	}

	public getComboboxesPagedCollection(filter: AllergyIntoleranceVerificationStatusFilterDto): Array<DescribedComboboxDto<string>> {
		const pagedCollection = getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			filterPredicateForRecord,
			mapFromRecordToFakeDescribedComboboxDto,
			compareFn
		).items;

		return pagedCollection.map((item) => mapFakeDescriptedComboboxDtoToDesriptedComboboxDto(this.translocoService, item));
	}

}

const mapFromRecordToFakeDescribedComboboxDto = (record: AllergyIntoleranceVerificationStatusFakeRecord): FakeDescribedComboboxDto<string> => (
	isValueDefined(record) ?
		{
			id: record!.id,
			displayText: record!.name,
			description: record!.description
		} :
		undefined!
);

const findPredicate = (
	item: AllergyIntoleranceVerificationStatusFakeRecord,
	findParameter: AllergyIntoleranceVerificationStatusFindParameterDto
): boolean =>
	item.id === findParameter.id;

const filterPredicateForRecord = (
	item: AllergyIntoleranceVerificationStatusFakeRecord,
	filter: AllergyIntoleranceVerificationStatusFilterDto,
	translocoService: TranslocoService
): boolean => {
	let result = true;

	if (result && isArrayDefinedAndNotEmpty(filter.ids)) {
		result = result && filter.ids!.some(id => item.id === id);
	}

	if (result && isArrayDefinedAndNotEmpty(filter.ignoreIds)) {
		result = result && filter.ignoreIds!.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result &&
			setTranslation(translocoService, item.name)
				.toLowerCase()
				.indexOf(filter.searchPattern!.toLowerCase()) >= 0;
	}

	return result;
};

// eslint-disable
// eslint-disable-next-line complexity
const compareFn = (
	item1: FakeDescribedComboboxDto<string>,
	item2: FakeDescribedComboboxDto<string>,
	sorting: string
): number => {
	if (sorting === 'displayText asc') {
		return item1.displayText > item2.displayText ? 1 : item1.displayText < item2.displayText ? -1 : 0;
	} else if (sorting === 'displayText desc') {
		return item1.displayText < item2.displayText ? 1 : item1.displayText > item2.displayText ? -1 : 0;
	}

	return 0;
};
// eslint-enable

interface AllergyIntoleranceVerificationStatusFakeRecord {
	id: string;
	name: FakeLocalizableEntity;
	description: FakeLocalizableEntity;
	order: number;
}

const initialData: Array<AllergyIntoleranceVerificationStatusFakeRecord> = [
	{
		id: 'unconfirmed',
		name: {
			en: 'Unconfirmed',
			uk: 'Непідтверджено'
		},
		description: {
			en: 'Fake description',
			uk: 'Фальшивий опис'
		},
		order: 1
	},
	{
		id: 'confirmed',
		name: {
			en: 'Confirmed',
			uk: 'Підтверджено'
		},
		description: {
			en: 'Fake description',
			uk: 'Фальшивий опис'
		},
		order: 2
	},
	{
		id: 'refuted',
		name: {
			en: 'Refuted',
			uk: 'Спростовано'
		},
		description: {
			en: 'Fake description',
			uk: 'Фальшивий опис'
		},
		order: 3
	}
];
