import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { PopulationComboboxDto } from '../models/population-combobox.dto';
import { PopulationFilterDto } from '../models/population-filter.dto';

export abstract class PopulationClient {

	public abstract getBulkAsComboboxes(filter: PopulationFilterDto): Observable<Result<Array<PopulationComboboxDto>, Failure>>;

}
