import { Injector } from '@angular/core';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { isValueDefined } from '@nmn-core/utils';
import { HealthIssueSeverityFakeClientConfiguration } from '../configurations/health-issue-severity.fake-client.configuration';
import { HealthIssueSeverityFakeClient } from '../services/health-issue-severity.fake-client';

export class HealthIssueSeverityFakeClientBuilder {

	private configuration: HealthIssueSeverityFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(
		configuration: HealthIssueSeverityFakeClientConfiguration
	): HealthIssueSeverityFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): HealthIssueSeverityFakeClient {
		return new HealthIssueSeverityFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
