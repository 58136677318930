import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { TakenMedicationHttpClientConfiguration } from '../configurations/taken-medication-http-client.configuration';

export class TakenMedicationHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<TakenMedicationHttpClientConfiguration> {

	public build(): TakenMedicationHttpClientConfiguration {
		return new TakenMedicationHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
