import { Injector } from '@angular/core';
import { TypeOfMedicationClient } from '@nmn-communication/types-of-medication';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { TypeOfMedicationFakeClientBuilder } from '../builders/type-of-medication.fake-client.builder';
import { TypeOfMedicationFakeClientConfigurationBuilder } from '../builders/type-of-medication.fake-client.configuration.builder';
import { TypeOfMedicationHttpClientBuilder } from '../builders/type-of-medication.http-client.builder';
import { TypeOfMedicationHttpClientConfigurationBuilder } from '../builders/type-of-medication.http-client.configuration.builder';
import { TypeOfMedicationFakeClient } from '../services/type-of-medication.fake-client';
import { TypeOfMedicationHttpClient } from '../services/type-of-medication.http-client';

export const typeOfMedicationClientFactory = (injector: Injector): TypeOfMedicationClient => {
	const useFake = environment?.api?.resources?.typesOfMedication?.useFake ||
		!isValueDefined(environment?.api?.resources?.typesOfMedication?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ? typeOfMedicationFakeClientFactory(injector) : typeOfMedicationHttpClientFactory(injector);
};

export const typeOfMedicationFakeClientFactory = (injector: Injector): TypeOfMedicationFakeClient => {
	const configuration = new TypeOfMedicationFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.typesOfMedication.serverDelayMs)
		.build();

	return new TypeOfMedicationFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const typeOfMedicationHttpClientFactory = (injector: Injector): TypeOfMedicationHttpClient => {
	const configuration = new TypeOfMedicationHttpClientConfigurationBuilder()
		.withBaseUrl(environment?.api?.resources?.typesOfMedication?.baseUrl ?? environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.typesOfMedication.resourceRelativePath)
		.withResourceTypeVersion(environment?.api?.resources?.typesOfMedication?.resourceTypeVersion ?? environment?.api?.defaultResourceTypeVersion)
		.build();

	return new TypeOfMedicationHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
