import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {
	PatientProfileInformationBlockModel
} from '../models/patient-profile/patient-profile-information-block.model';

export abstract class PatientProfileResolver implements Resolve<PatientProfileInformationBlockModel> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		routerState: RouterStateSnapshot
	): Observable<PatientProfileInformationBlockModel> |
		Promise<PatientProfileInformationBlockModel> |
		PatientProfileInformationBlockModel;

}
