import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FailureModel } from '../../shared';
import { ContactsModel } from '../models/contacts.model';

export abstract class ContactsQueryHandlerService {

	public abstract getContacts(): Observable<Result<ContactsModel, FailureModel>>;

}
