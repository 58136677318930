import { Injector, Provider } from '@angular/core';
import { GeneticFileParseRequestClient } from '@nmn-communication/genetic-file-parse-requests';
import { GeneticFileParseRequestClientFactory } from './factories/genetic-file-parse-request.client.factory';

export const geneticFileParseRequestProviders: Array<Provider> = [
	{
		provide: GeneticFileParseRequestClient,
		useFactory: GeneticFileParseRequestClientFactory,
		deps: [Injector]
	}
];
