export class InitiateResetPasswordCommand {

	public readonly email: string;

	constructor(
		email: string
	) {

		//TODO: Guard.NotNull

		this.email = email;

	}

}
