import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { TreatmentCheckerHttpClientConfiguration } from '../configurations/treatment-checker-http-client.configuration';
import { TreatmentCheckerHttpClient } from '../services/treatment-checker.http-client';

export class TreatmentCheckerHttpClientBuilder
	extends HttpClientBuilder<TreatmentCheckerHttpClient, TreatmentCheckerHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): TreatmentCheckerHttpClient {
		return new TreatmentCheckerHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
