import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { interceptorProviders } from '@nmn-middleware/interceptors';
import { MiddlewareModule } from '@nmn-middleware/middleware.module';
import { GoogleAnalyticsModule } from '@nmn-vendor/google';
import { TranslocoRootModule } from '@nmn-vendor/transloco';
import { AppRoutingModule } from './app-routing.module';
import { AppService } from './app.service';
import { BackgroundModule } from './background/background.module';
import { CommunicationModule } from './communication/communication.module';
import { CoreModule as NMNCoreModule } from './core/core.module';
import { DomainModule } from './domain/domain.module';
import { UserConfigurationModule } from './modules/accounts/user-configuration/modularity/user-configuration.module';
import { UserProfileGetModule } from './modules/accounts/user-profile/modularity/user-profile-get.module';
import { ErrorHandlingService } from './modules/core/error-handling/services/error-handling.service';
import { NotificationService } from './modules/core/notifications/services/notification.service';
import { RootComponent } from './root/root.component';
import { RootModule } from './root/root.module';

@NgModule({
	imports: [
		BrowserModule,
		CommonModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		HttpClientModule,
		RouterModule,

		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCheckboxModule,
		MatNativeDateModule,
		MatTabsModule,
		MatTooltipModule,
		MatIconModule,
		MatSelectModule,
		MatRadioModule,
		MatDividerModule,
		MatAutocompleteModule,
		MatCardModule,
		MatDatepickerModule,
		MatSliderModule,
		MatPaginatorModule,
		MatSortModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatSnackBarModule,
		MatListModule,
		MatStepperModule,
		MatChipsModule,
		MatExpansionModule,
		MatSlideToggleModule,
		MatProgressBarModule,
		MatDialogModule,

		FlexLayoutModule,
		TranslocoRootModule,
		GoogleAnalyticsModule,

		RootModule,
		NMNCoreModule,
		BackgroundModule,
		MiddlewareModule,
		CommunicationModule,
		DomainModule,
		UserConfigurationModule,
		UserProfileGetModule
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: (appService: AppService) => {
				return () => appService
					.initApp()
					.toPromise();
			},
			deps: [AppService],
			multi: true
		},
		...interceptorProviders,

		ErrorHandlingService,
		NotificationService
	],
	bootstrap: [
		RootComponent
	]
})
export class AppModule { }
