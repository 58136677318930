import { TranslocoService } from '@ngneat/transloco';
import { DocumentTypeDescribedComboboxDto, DocumentTypeFilterDto, DocumentTypeFindParameterDto } from '@nmn-communication/document-types';
import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { DocumentIconType } from '@nmn-domain/document-types';
import { FakeDatabase } from '../../databases/fake.database';
import { getPagedCollectionWithoutDtoFilter } from '../../databases/fake.utils';
import {
	FakeDocumentTypeDescribedComboboxDto,
	mapFakeDocumentTypeDescriptedComboboxDtoToDocumentTypeDescriptedComboboxDto
} from '../../models/comboboxes/fake-document-type-described-combobox.dto';
import { FakeLocalizableEntity } from '../../models/fake-localizable-entity';
import { setTranslation } from '../../utils/localize';

export class DocumentTypeFakeTable {

	private readonly data: Array<DocumentTypeFakeRecord>;

	constructor(
		_: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.data = [...initialData];
	}

	public getAsCombobox(parameter: DocumentTypeFindParameterDto): DocumentTypeDescribedComboboxDto {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record was not found');
		}

		return record;
	}

	public findAsCombobox(parameter: DocumentTypeFindParameterDto): DocumentTypeDescribedComboboxDto {
		const record = this.data
			.find((item: DocumentTypeFakeRecord) => findPredicate(item, parameter));
		const comboboxRecord = mapFromRecordToDescribedComboboxDto(record!);

		return mapFakeDocumentTypeDescriptedComboboxDtoToDocumentTypeDescriptedComboboxDto(
			this.translocoService, comboboxRecord!);
	}

	public getComboboxesPagedCollection(filter: DocumentTypeFilterDto): Array<DocumentTypeDescribedComboboxDto> {
		const pagedCollection = getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			filterPredicateForRecord,
			mapFromRecordToDescribedComboboxDto,
			compareFn
		).items;

		return pagedCollection.map((item) =>
			mapFakeDocumentTypeDescriptedComboboxDtoToDocumentTypeDescriptedComboboxDto(this.translocoService, item));
	}

}

const mapFromRecordToDescribedComboboxDto = (
	record: DocumentTypeFakeRecord
): FakeDocumentTypeDescribedComboboxDto => (
	isValueDefined(record) ?
		{
			id: record!.id,
			displayText: record!.name,
			description: record!.description
		} :
		undefined!
);

const findPredicate = (item: DocumentTypeFakeRecord, findParameter: DocumentTypeFindParameterDto): boolean =>
	item.id === findParameter.id;

const filterPredicateForRecord = (
	item: DocumentTypeFakeRecord,
	filter: DocumentTypeFilterDto,
	translocoService: TranslocoService
): boolean => {
	let result = true;

	if (result && isArrayDefinedAndNotEmpty(filter.ids)) {
		result = result && filter.ids!.some(id => item.id === id);
	}

	if (result && isArrayDefinedAndNotEmpty(filter.ignoreIds)) {
		result = result && filter.ignoreIds!.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result &&
			setTranslation(translocoService, item.name)
				.toLowerCase()
				.indexOf(filter.searchPattern!.toLowerCase()) >= 0;
	}

	return result;
};

// eslint-disable
// eslint-disable-next-line complexity
const compareFn = (
	item1: FakeDocumentTypeDescribedComboboxDto,
	item2: FakeDocumentTypeDescribedComboboxDto,
	sorting: string
): number => {
	if (sorting === 'displayText asc') {
		return item1.displayText > item2.displayText ? 1 : item1.displayText < item2.displayText ? -1 : 0;
	} else if (sorting === 'displayText desc') {
		return item1.displayText < item2.displayText ? 1 : item1.displayText > item2.displayText ? -1 : 0;
	}

	return 0;
};
// eslint-enable

interface DocumentTypeFakeRecord {
	id: DocumentIconType;
	name: FakeLocalizableEntity;
	description: FakeLocalizableEntity;
	order: number;
	createdOn: string;
	updatedOn?: string;
}

const initialData: Array<DocumentTypeFakeRecord> = [
	{
		id: DocumentIconType.Bill,
		name: {
			en: 'Bill',
			uk: 'Рахунок'
		},
		description: {
			en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
			uk: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
		},
		order: 1,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: DocumentIconType.Prescription,
		name: {
			en: 'Prescription',
			uk: 'Рецепт'
		},
		description: {
			en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
			uk: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
		},
		order: 2,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: DocumentIconType.LabResults,
		name: {
			en: 'Lab result',
			uk: 'Лабораторний результат'
		},
		description: {
			en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
			uk: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
		},
		order: 3,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: DocumentIconType.Undefined,
		name: {
			en: 'Undefined',
			uk: 'Невизначено'
		},
		description: {
			en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
			uk: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
		},
		order: 4,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: DocumentIconType.Observation,
		name: {
			en: 'Observation',
			uk: 'Спостереження'
		},
		description: {
			en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
			uk: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
		},
		order: 5,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: DocumentIconType.Photo,
		name: {
			en: 'Photo',
			uk: 'Фото'
		},
		description: {
			en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
			uk: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
		},
		order: 6,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: DocumentIconType.DrugInstruction,
		name: {
			en: 'Drug instruction',
			uk: 'Інструкція до препарату'
		},
		description: {
			en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
			uk: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
		},
		order: 7,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: DocumentIconType.Hospital,
		name: {
			en: 'Hospital discharge',
			uk: 'Виписка з лікарні'
		},
		description: {
			en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
			uk: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
		},
		order: 8,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: DocumentIconType.Insurance,
		name: {
			en: 'Insurance document',
			uk: 'Страховий документ'
		},
		description: {
			en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
			uk: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
		},
		order: 9,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: DocumentIconType.Other,
		name: {
			en: 'Other',
			uk: 'Інший'
		},
		description: {
			en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
			uk: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
		},
		order: 10,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	}
];
