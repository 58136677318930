import { ConfirmEmailParameter, InitiateEmailConfirmationParameter } from '@nmn-communication/accounts';
import { ConfirmEmailCommand } from '@nmn-domain/accounts/confirm-email/models/commands/confirm-email.command';
import { InitiateEmailConfirmationCommand } from '@nmn-domain/accounts/confirm-email/models/commands/initiate-email-confirmation.command';

export const mapConfirmEmailCommandToConfirmEmailParameter = (
	command: ConfirmEmailCommand
): ConfirmEmailParameter => (
	{
		userId: command.userId,
		token: command.token
	}
);

export const mapInitiateEmailConfirmationCommandToParameter = (
	command: InitiateEmailConfirmationCommand
): InitiateEmailConfirmationParameter => (
	{
		email: command.email
	}
);
