import { GenderFilterDto } from '@nmn-communication/genders';
import { GenderFilterModel } from '@nmn-domain/genders';

export const mapFilterModelToDto = (model: GenderFilterModel): GenderFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern
	}
);

export const mapFilterDtoToModel = (dto: GenderFilterDto): GenderFilterModel => (
	new GenderFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern
	)
);
