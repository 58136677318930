import { PageOptionsQuery } from './page-options.query';

export class PagedCollectionModel<TItem, TFilter> extends PageOptionsQuery<TFilter> {

	public readonly items: Array<TItem>;
	public readonly hasNextPage: boolean;
	public readonly hasPreviousPage: boolean;
	public readonly nextPage: number | null;
	public readonly previousPage: number | null;
	public readonly totalCount: number;
	public readonly totalPages: number;

	constructor(
		sorting: string,
		filter: TFilter,
		pageIndex: number,
		pageSize: number,
		indexFrom: number | null,
		items: Array<TItem>,
		hasNextPage: boolean,
		hasPreviousPage: boolean,
		nextPage: number | null,
		previousPage: number | null,
		totalCount: number,
		totalPages: number
	) {

		super(sorting, filter, pageIndex, pageSize, indexFrom);

		// TODO: check on null
		this.items = items;
		this.hasNextPage = hasNextPage;
		this.hasPreviousPage = hasPreviousPage;
		this.nextPage = nextPage;
		this.previousPage = previousPage;
		this.totalCount = totalCount;
		this.totalPages = totalPages;

	}

}
