import { diffDateInMs, isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { ClinicModel } from '../../clinics/models/clinic.model';
import { DiseaseComboboxModel } from '../../diseases/models/disease-combobox.model';
import { DoctorModel } from '../../doctors/models/doctor.model';
import { DocumentDescribedComboboxModel } from '../../documents';
import { HealthIssueComboboxModel } from '../../health-issues';
import { TakenMedicationDescribedComboboxModel } from '../../taken-medications';
import { DoctorEncounterStatus } from './doctor-encounter-status.enum';

export class DoctorEncounterModel {

	public readonly id: string;
	public readonly patientId: string;
	public readonly diseases: Array<DiseaseComboboxModel>;
	public readonly comment?: string;
	public readonly date?: Date;
	public readonly status: DoctorEncounterStatus;
	public readonly doctor?: DoctorModel;
	public readonly clinic?: ClinicModel;
	public readonly healthIssue?: HealthIssueComboboxModel;
	public readonly takenMedications: Array<TakenMedicationDescribedComboboxModel>;
	public readonly documents: Array<DocumentDescribedComboboxModel>;
	public readonly createdOn: Date;
	public readonly updatedOn?: Date;

	public get isDateDefined(): boolean {
		return isValueDefined(this.date);
	}

	public get isCommentDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.comment);
	}

	public get isDiseasesDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.diseases);
	}

	public get diseasesAsCommaSeparatedString(): string {
		return this.isDiseasesDefined ?
			this.diseases
				.map(item => item.displayText)
				.join(', ') :
			'';
	}

	public get isHealthIssueDefined(): boolean {
		return isValueDefined(this.healthIssue);
	}

	public get isTakenMedicationsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.takenMedications);
	}

	public get isDocumentsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.documents);
	}

	public get isDoctorDefined(): boolean {
		return isValueDefined(this.doctor) &&
			(this.doctor.isFullNameDefined || this.doctor.isContactsDefined);
	}

	public get isClinicDefined(): boolean {
		return isValueDefined(this.clinic) &&
			(this.clinic.isNameDefined || this.clinic.isContactsDefined || this.clinic.isAddressDefined);
	}

	public get lastModifiedOn(): Date {
		return isValueDefined(this.updatedOn) && diffDateInMs(this.createdOn, this.updatedOn) > 0 ?
			this.updatedOn :
			this.createdOn;
	}

	constructor(
		id: string,
		patientId: string,
		diseases: Array<DiseaseComboboxModel>,
		comment: string | undefined,
		date: Date | undefined,
		status: DoctorEncounterStatus,
		doctor: DoctorModel | undefined,
		clinic: ClinicModel | undefined,
		healthIssue: HealthIssueComboboxModel | undefined,
		takenMedications: Array<TakenMedicationDescribedComboboxModel>,
		documents: Array<DocumentDescribedComboboxModel>,
		createdOn: Date,
		updatedOn: Date | undefined
	) {
		// TODO: Guard check on defined
		this.id = id;
		this.patientId = patientId;
		this.diseases = diseases;
		this.comment = comment;
		this.date = date;
		this.status = status;
		this.doctor = doctor;
		this.clinic = clinic;
		this.healthIssue = healthIssue;
		this.takenMedications = takenMedications;
		this.documents = documents;
		this.createdOn = createdOn;
		this.updatedOn = updatedOn;
	}

}
