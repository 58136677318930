import { Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { ServerConfigurationFakeClientConfiguration } from '../configurations/server-configuration-fake-client.configuration';
import { ServerConfigurationFakeClient } from '../services/server-configuration.fake-client';

export class ServerConfigurationFakeClientBuilder {

	private configuration: ServerConfigurationFakeClientConfiguration;

	constructor(private readonly injector: Injector) { }

	public withConfiguration(
		configuration: ServerConfigurationFakeClientConfiguration
	): ServerConfigurationFakeClientBuilder {

		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;

	}

	public build(): ServerConfigurationFakeClient {

		return new ServerConfigurationFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));

	}

}
