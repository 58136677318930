import { GeneComboboxModel } from '@nmn-domain/genes';
import { MedicationComboboxModel } from '@nmn-domain/medications';
import { PharmacogenomicTestResultComboboxModel } from '@nmn-domain/pharmacogenomic-test-results';
import { SourceModel } from '@nmn-domain/sources';

export class TreatmentCheckerPersonalizedMedicineRecommendationModel {

	public readonly medication: MedicationComboboxModel;
	public readonly gene: GeneComboboxModel;
	public readonly source: SourceModel;

	public readonly geneticTestId: string;
	public readonly pharmacogenomicTestResult: PharmacogenomicTestResultComboboxModel;

	// if defined display before drugRecommendation as a new paragraph
	public readonly implication?: string;
	public readonly drugRecommendation: string;
	// if defined display after drugRecommendation as a new paragraph
	public readonly comment?: string;

	public readonly population?: string;
	public readonly classification?: string;
	public readonly activityScore?: number;

	constructor(
		medication: MedicationComboboxModel,
		gene: GeneComboboxModel,
		source: SourceModel,
		geneticTestId: string,
		pharmacogenomicTestResult: PharmacogenomicTestResultComboboxModel,
		implication: string | undefined,
		drugRecommendation: string,
		comment: string | undefined,
		population: string | undefined,
		classification: string | undefined,
		activityScore: number | undefined
	) {
		// TODO: Guard: check on defined

		this.medication = medication;
		this.gene = gene;
		this.source = source;
		this.geneticTestId = geneticTestId;
		this.pharmacogenomicTestResult = pharmacogenomicTestResult;
		this.implication = implication;
		this.drugRecommendation = drugRecommendation;
		this.comment = comment;
		this.population = population;
		this.classification = classification;
		this.activityScore = activityScore;
	}

}
