import { Injector } from '@angular/core';
import { MedicationAdmStatusClient } from '@nmn-communication/medication-adm-statuses';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { MedicationAdmStatusFakeClientBuilder } from '../builders/medication-adm-status.fake-client.builder';
import { MedicationAdmStatusFakeClientConfigurationBuilder } from '../builders/medication-adm-status.fake-client.configuration.builder';
import { MedicationAdmStatusHttpClientBuilder } from '../builders/medication-adm-status.http-client.builder';
import { MedicationAdmStatusHttpClientConfigurationBuilder } from '../builders/medication-adm-status.http-client.configuration.builder';
import { MedicationAdmStatusFakeClient } from '../services/medication-adm-status.fake-client';
import { MedicationAdmStatusHttpClient } from '../services/medication-adm-status.http-client';

export const MedicationAdmStatusClientFactory = (injector: Injector): MedicationAdmStatusClient => {
	const useFake = environment?.api?.resources?.medicationAdmStatuses?.useFake ||
		!isValueDefined(environment?.api?.resources?.medicationAdmStatuses?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		MedicationAdmStatusFakeClientFactory(injector) :
		MedicationAdmStatusHttpClientFactory(injector);
};

export const MedicationAdmStatusFakeClientFactory = (injector: Injector): MedicationAdmStatusFakeClient => {
	const configuration = new MedicationAdmStatusFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.medicationAdmStatuses.serverDelayMs)
		.build();

	return new MedicationAdmStatusFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const MedicationAdmStatusHttpClientFactory = (injector: Injector): MedicationAdmStatusHttpClient => {
	const configuration = new MedicationAdmStatusHttpClientConfigurationBuilder()
		.withBaseUrl(environment?.api?.resources?.medicationAdmStatuses?.baseUrl ?? environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.medicationAdmStatuses.resourceRelativePath)
		.withResourceTypeVersion(environment?.api?.resources?.medicationAdmStatuses?.resourceTypeVersion ?? environment?.api?.defaultResourceTypeVersion)
		.build();

	return new MedicationAdmStatusHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
