export class TakenMedicationFinishCourseBulkCommand {

	public readonly patientId: string;
	public readonly candidates: Array<string>;

	public constructor(
		patientId: string,
		candidates: Array<string>
	) {

		// TODO: add guards
		this.patientId = patientId;
		this.candidates = candidates;

	}

}
