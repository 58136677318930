import { Injector } from '@angular/core';
import { GeneVariantClient } from '@nmn-communication/gene-variants';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { GeneVariantFakeClientBuilder } from '../builders/gene-variant.fake-client.builder';
import { GeneVariantFakeClientConfigurationBuilder } from '../builders/gene-variant.fake-client.configuration.builder';
import { GeneVariantHttpClientBuilder } from '../builders/gene-variant.http-client.builder';
import { GeneVariantHttpClientConfigurationBuilder } from '../builders/gene-variant.http-client.configuration.builder';
import { GeneVariantFakeClient } from '../services/gene-variant.fake-client';
import { GeneVariantHttpClient } from '../services/gene-variant.http-client';

export const geneVariantClientFactory = (injector: Injector): GeneVariantClient => {
	const useFake = environment?.api?.resources?.geneVariants?.useFake || !isValueDefined(environment?.api?.resources?.geneVariants?.useFake) && environment.api.defaultUseFake;

	return useFake ?
		geneVariantFakeClientFactory(injector) :
		geneVariantHttpClientFactory(injector);
};

export const geneVariantFakeClientFactory = (injector: Injector): GeneVariantFakeClient => {
	const configuration = new GeneVariantFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.geneVariants.serverDelayMs)
		.build();

	return new GeneVariantFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const geneVariantHttpClientFactory = (injector: Injector): GeneVariantHttpClient => {
	const configuration = new GeneVariantHttpClientConfigurationBuilder()
		.withBaseUrl(environment?.api?.resources?.geneVariants?.baseUrl ?? environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.geneVariants.resourceRelativePath)
		.withResourceTypeVersion(environment?.api?.resources?.geneVariants?.resourceTypeVersion ?? environment?.api?.defaultResourceTypeVersion)
		.build();

	return new GeneVariantHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
