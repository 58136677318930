import { TranslocoService } from '@ngneat/transloco';
import { DocumentDescribedComboboxDto } from '@nmn-communication/documents';
import { isValueDefined } from '@nmn-core/utils';
import { setTranslation } from '../../utils/localize';
import { FakeDescribedComboboxDto } from './fake-described-combobox.dto';

export interface FakeDocumentDescribedComboboxDto extends FakeDescribedComboboxDto<string> {

	type: string;

}

export const mapFakeDocumentDescriptedComboboxDtoToDocumentDescriptedComboboxDto = (
	translocoService: TranslocoService,
	fakeDescribedComboboxDto: FakeDocumentDescribedComboboxDto
): DocumentDescribedComboboxDto => {
	if (!isValueDefined(fakeDescribedComboboxDto)) {
		return undefined;
	}

	return {
		id: fakeDescribedComboboxDto.id,
		displayText: setTranslation(translocoService, fakeDescribedComboboxDto.displayText),
		description: setTranslation(translocoService, fakeDescribedComboboxDto.description),
		type: fakeDescribedComboboxDto.type
	};
};
