import { ComboboxModel } from '@nmn-domain/shared';

export class TimezoneComboboxModel extends ComboboxModel {

	public readonly standardName: string;
	public readonly daylightName: string;
	public readonly supportsDST: boolean;
	public readonly utcStandardOffsetMinutes: number;
	public readonly utcDaylightOffsetMinutes: number;

	constructor(
		id: string,
		displayText: string,
		standardName: string,
		daylightName: string,
		supportsDST: boolean,
		utcStandardOffsetMinutes: number,
		utcDaylightOffsetMinutes: number
	) {
		super(id, displayText);

		//TODO: Guard

		this.standardName = standardName;
		this.daylightName = daylightName;
		this.supportsDST = supportsDST;
		this.utcStandardOffsetMinutes = utcStandardOffsetMinutes;
		this.utcDaylightOffsetMinutes = utcDaylightOffsetMinutes;
	}

}
