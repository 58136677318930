import { Injectable } from '@angular/core';
import { VaccineClient } from '@nmn-communication/vaccines';
import { Result } from '@nmn-core/shared';
import { FailureModel } from '@nmn-domain/shared';
import { VaccineDescribedComboboxModel, VaccineFilterModel, VaccineQueryHandlerService } from '@nmn-domain/vaccines';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapDtoArrayResultToModelArrayResult } from '../../shared/factories/pagination.factory';
import { mapFilterModelToDto, mapVaccineDescribedComboboxDtoToModel } from '../factories/vaccine.factory';

@Injectable()
export class VaccineQueryHandlerViaClientService extends VaccineQueryHandlerService {

	constructor(
		private readonly client: VaccineClient
	) {
		super();
	}

	public getAsDescribedComboboxes(filter: VaccineFilterModel): Observable<Result<Array<VaccineDescribedComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapVaccineDescribedComboboxDtoToModel))
			);
	}

}
