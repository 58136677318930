
import { PagedCollectionDto, PersonalizedMedicinePubmedCitationDto, PubmedCitationDto, PubmedCitationsDto } from '@nmn-communication/shared';
import {
	MedicationUsageDto,
	PatientTreatmentCheckerAnalyzeParameterDto,
	TreatmentCheckerAnalyzeParameterDto,
	TreatmentCheckerDiseaseTreatmentGuidelineDto,
	TreatmentCheckerPersonalizedMedicineGuidelineDto,
	TreatmentCheckerPersonalizedMedicineRecommendationDto,
	TreatmentCheckerReportCautionDto,
	TreatmentCheckerReportDeleteBulkParameterDto,
	TreatmentCheckerReportDiseaseDto,
	TreatmentCheckerReportDto,
	TreatmentCheckerReportFilterDto,
	TreatmentCheckerReportFindParameterDto,
	TreatmentCheckerReportInteractionDto,
	TreatmentCheckerReportMedicationDto,
	TreatmentCheckerReportMedicationPgxAffectedSubgroupDto,
	TreatmentCheckerReportMedicationPgxLevelTestingDto,
	TreatmentCheckerReportMedicationPropertiesDto,
	TreatmentCheckerReportMedicationSideEffectDto,
	TreatmentCheckerReportShortDto,
	TreatmentCheckerReportTreatmentInfoAnamnesisDto,
	TreatmentCheckerReportTreatmentInfoDto,
	TreatmentCheckerReportTreatmentInfoLifestyleDto
} from '@nmn-communication/treatment-checker';
import { isValueDefined, mapDateInServerFormatToDateSafely } from '@nmn-core/utils';
import { PagedCollectionModel } from '@nmn-domain/shared';
import { PersonalizedMedicinePubmedCitationModel, PubmedCitationModel, PubmedCitationsModel } from '@nmn-domain/sources';
import {
	mapTreatmentCheckerMedicationReportSideEffectFrequencyTypeFromString,
	mapTreatmentCheckerReportCautionSeverityFromString,
	mapTreatmentCheckerReportCautionTypeFromString,
	mapTreatmentCheckerReportDiseaseReportInfectiousTypeFromString,
	mapTreatmentCheckerReportInteractionCriticalityTypeFromString,
	mapTreatmentCheckerReportInteractionTypeFromString,
	mapTreatmentCheckerReportMedicationPgxLevelTestingAnnotationFromString,
	mapTreatmentCheckerReportMedicationPgxLevelTestingOrganizationFromString,
	mapTreatmentCheckerReportMedicationPgxLevelTestingTypeFromString,
	mapTreatmentCheckerReportMedicationPropertyDrugTypeFromString,
	mapTreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeTypeFromString,
	MedicationUsageModel,
	PatientTreatmentCheckerAnalyzeCommand,
	TreatmentCheckerAnalyzeCommand,
	TreatmentCheckerDiseaseTreatmentGuidelineModel,
	TreatmentCheckerPersonalizedMedicineGuidelineModel,
	TreatmentCheckerPersonalizedMedicineRecommendationModel,
	TreatmentCheckerReportAnamnesisModel,
	TreatmentCheckerReportCautionModel,
	TreatmentCheckerReportDeleteBulkCommand,
	TreatmentCheckerReportDeleteCommand,
	TreatmentCheckerReportDiseaseReportModel,
	TreatmentCheckerReportFilterModel,
	TreatmentCheckerReportGetQuery,
	TreatmentCheckerReportInteractionReportModel,
	TreatmentCheckerReportLifestyleModel,
	TreatmentCheckerReportMedicationPgxAffectedSubgroupModel,
	TreatmentCheckerReportMedicationPgxLevelTestingModel,
	TreatmentCheckerReportMedicationPropertiesModel,
	TreatmentCheckerReportMedicationReportModel,
	TreatmentCheckerReportMedicationSideEffectComboboxModel,
	TreatmentCheckerReportModel,
	TreatmentCheckerReportShortModel,
	TreatmentCheckerReportTreatmentInfoModel
} from '@nmn-domain/treatment-checker';
import { mapDiseaseComboboxDtoToModel } from '../../diseases/factories/disease.factory';
import { mapGeneComboboxDtoToModel } from '../../genes/factories/gene.factory';
import { mapMedicationComboboxDtoToModel } from '../../medications/factories/medication.factory';
import { mapPharmacogenomicTestResultComboboxDtoToModel } from '../../pharmacogenomic-test-results/factories/pharmacogenomic-test-result.factory';
import { mapPopulationComboboxDtoToModel } from '../../populations/factories/population.factory';
import { mapComboboxDtoToComboboxModel, mapDescribedComboboxDtoToDescribedComboboxModel } from '../../shared/factories/combobox.factory';
import { mapPagedCollectionDtoToModel } from '../../shared/factories/pagination.factory';
import { mapSourceDtoToModel } from '../../shared/factories/source.factory';

export const mapPatientTreatmentCheckerAnalyzeCommandToParameterDto = (model: PatientTreatmentCheckerAnalyzeCommand): PatientTreatmentCheckerAnalyzeParameterDto => (
	{
		...model
	}
);

export const mapTreatmentCheckerAnalyzeCommandToParameterDto = (model: TreatmentCheckerAnalyzeCommand): TreatmentCheckerAnalyzeParameterDto => (
	{
		...model
	}
);

export const mapDeleteCheckTreatmentReportCommandToParameterDto = (model: TreatmentCheckerReportDeleteCommand): TreatmentCheckerReportFindParameterDto => (
	{
		...model
	}
);

export const mapDeleteBulkCheckTreatmentReportCommandToParameterDto = (model: TreatmentCheckerReportDeleteBulkCommand): TreatmentCheckerReportDeleteBulkParameterDto => (
	{
		...model
	}
);

export const mapFilterModelToDto = (model: TreatmentCheckerReportFilterModel): TreatmentCheckerReportFilterDto => (
	{
		...model
	}
);

export const mapFilterDtoToModel = (dto: TreatmentCheckerReportFilterDto): TreatmentCheckerReportFilterModel => (
	new TreatmentCheckerReportFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.patientIds,
		dto.searchPattern
	)
);
export const mapGetQueryToParameterDto = (model: TreatmentCheckerReportGetQuery): TreatmentCheckerReportFindParameterDto => (
	{
		...model
	}
);

export const mapTreatmentCheckerReportPagedCollectionDtoToModel = (
	pagedCollectionDto: PagedCollectionDto<TreatmentCheckerReportShortDto, TreatmentCheckerReportFilterDto>
): PagedCollectionModel<TreatmentCheckerReportShortModel, TreatmentCheckerReportFilterModel> =>
	mapPagedCollectionDtoToModel(pagedCollectionDto, mapTreatmentCheckerReportShortDtoToModel, mapFilterDtoToModel);

export const mapTreatmentCheckerReportShortDtoToModel = (dto: TreatmentCheckerReportShortDto): TreatmentCheckerReportShortModel => (
	new TreatmentCheckerReportShortModel(
		dto.id,
		mapDateInServerFormatToDateSafely(dto.createdOn),
		isValueDefined(dto.treatmentInfo) ? mapTreatmentCheckerReportTreatmentInfoFromDtoToModel(dto.treatmentInfo) : undefined,
		dto.cautions.map(mapTreatmentCheckerReportCautionDtoToModel)
	)
);

export const mapTreatmentCheckerReportDtoToModel = (dto: TreatmentCheckerReportDto): TreatmentCheckerReportModel => (
	new TreatmentCheckerReportModel(
		dto.id,
		mapDateInServerFormatToDateSafely(dto.createdOn),
		isValueDefined(dto.treatmentInfo) ? mapTreatmentCheckerReportTreatmentInfoFromDtoToModel(dto.treatmentInfo) : undefined,
		dto.cautions.map(mapTreatmentCheckerReportCautionDtoToModel),
		dto.diseases.map(mapTreatmentCheckerReportDiseaseReportDtoToModel),
		dto.interactions.map(mapTreatmentCheckerReportInteractionReportDtoToModel),
		dto.medications.map(mapTreatmentCheckerReportMedicationReportDtoToModel)
	)
);

const mapTreatmentCheckerReportTreatmentInfoFromDtoToModel = (dto: TreatmentCheckerReportTreatmentInfoDto): TreatmentCheckerReportTreatmentInfoModel => (
	new TreatmentCheckerReportTreatmentInfoModel(
		isValueDefined(dto.population) ? mapPopulationComboboxDtoToModel(dto.population) : undefined,
		isValueDefined(dto.gender) ? mapDescribedComboboxDtoToDescribedComboboxModel(dto.gender) : undefined,
		dto.age,
		dto.weight,
		dto.height,
		dto.diseases.map(mapDiseaseComboboxDtoToModel),
		dto.medications.map(mapMedicationComboboxDtoToModel),
		isValueDefined(dto.anamnesis) ? mapTreatmentCheckerReportAnamnesisFromDtoToModel(dto.anamnesis) : TreatmentCheckerReportAnamnesisModel.createEmpty(),
		isValueDefined(dto.lifestyle) ? mapTreatmentCheckerReportLifestyleFromDtoToModel(dto.lifestyle) : TreatmentCheckerReportLifestyleModel.createEmpty()
	)
);

const mapTreatmentCheckerReportAnamnesisFromDtoToModel = (dto: TreatmentCheckerReportTreatmentInfoAnamnesisDto): TreatmentCheckerReportAnamnesisModel => (
	new TreatmentCheckerReportAnamnesisModel(
		dto.hasHealthIssuesWithLiver,
		dto.hasHealthIssuesWithKidney,
		dto.hasHealthIssuesWithHeart,
		dto.hasHealthIssuesWithStomach,
		dto.hasHealthIssuesWithNervousSystem,
		dto.hasHealthIssuesWithImmuneSystem,
		dto.hasHealthIssuesWithEndocrine,
		dto.hasHealthIssuesWithCancer,
		dto.hasHealthIssuesWithEye,
		dto.hasHealthIssuesWithEar,
		dto.hasHealthIssuesWithMuscles,
		dto.hasHealthIssuesWithGeneticConditions
	)
);

const mapTreatmentCheckerReportLifestyleFromDtoToModel = (dto: TreatmentCheckerReportTreatmentInfoLifestyleDto): TreatmentCheckerReportLifestyleModel => (
	new TreatmentCheckerReportLifestyleModel(
		dto.isPregnant,
		dto.isActiveLifestyle,
		dto.isAlcohol,
		dto.isOnHealthyDiet,
		dto.isSmoking,
		dto.isUnderHighLevelOfStress,
		dto.isUsingNutritionalSupplements
	)
);

const mapTreatmentCheckerReportCautionDtoToModel = (dto: TreatmentCheckerReportCautionDto): TreatmentCheckerReportCautionModel => (
	new TreatmentCheckerReportCautionModel(
		mapTreatmentCheckerReportCautionTypeFromString(dto.type),
		mapTreatmentCheckerReportCautionSeverityFromString(dto.severity),
		dto.parameters
	)
);

const mapTreatmentCheckerReportDiseaseReportDtoToModel = (dto: TreatmentCheckerReportDiseaseDto): TreatmentCheckerReportDiseaseReportModel => (
	new TreatmentCheckerReportDiseaseReportModel(
		mapDiseaseComboboxDtoToModel(dto.disease),
		mapTreatmentCheckerReportDiseaseReportInfectiousTypeFromString(dto.infectiousType),
		dto.isHeritable,
		dto.heritabilityRate,
		dto.frequency,
		dto.isGeneticTestingAvailable,
		(dto.comorbidities ?? []).map(mapComboboxDtoToComboboxModel),
		(dto.synonyms ?? []).map(mapComboboxDtoToComboboxModel),
		(dto.affectedOrgans ?? []).map(mapComboboxDtoToComboboxModel),
		(dto.personalizedMedicineGuidelines ?? []).map(mapTreatmentCheckerPersonalizedMedicineGuidelineDtoToModel),
		(dto.diseaseTreatmentGuidelines ?? []).map(mapTreatmentCheckerDiseaseTreatmentGuidelineDtoToModel)
	)
);

const mapTreatmentCheckerReportInteractionReportDtoToModel = (dto: TreatmentCheckerReportInteractionDto): TreatmentCheckerReportInteractionReportModel => (
	new TreatmentCheckerReportInteractionReportModel(
		mapTreatmentCheckerReportInteractionTypeFromString(dto.type),
		mapTreatmentCheckerReportInteractionCriticalityTypeFromString(dto.criticality),
		dto.description,
		mapComboboxDtoToComboboxModel(dto.entity1),
		mapComboboxDtoToComboboxModel(dto.entity2)
	)
);

const mapTreatmentCheckerReportMedicationReportDtoToModel = (dto: TreatmentCheckerReportMedicationDto): TreatmentCheckerReportMedicationReportModel => (
	new TreatmentCheckerReportMedicationReportModel(
		mapMedicationComboboxDtoToModel(dto.medication),
		mapTreatmentCheckerReportMedicationReportMedicationPropertiesDtoToModel(dto.medicationProperties),
		(dto.knownTradeNames ?? []).map(mapComboboxDtoToComboboxModel),
		(dto.synonyms ?? []).map(mapComboboxDtoToComboboxModel),
		(dto.sideEffects ?? []).map(mapTreatmentCheckerReportMedicationReportSideEffectDtoToModel),
		(dto.personalizedMedicineRecommendations ?? []).map(mapPersonalizedMedicineRecommendationDtoToModel),
		(dto.pgxLevelTestings ?? []).map(mapTreatmentCheckerReportPgxLevelTestingDtoToModel),
		(dto.personalizedMedicineGuidelines ?? []).map(mapTreatmentCheckerPersonalizedMedicineGuidelineDtoToModel),
		mapPubmedCitationsDtoToModel(dto.pubmedCitations)
	)
);

const mapTreatmentCheckerReportMedicationReportMedicationPropertiesDtoToModel = (dto: TreatmentCheckerReportMedicationPropertiesDto): TreatmentCheckerReportMedicationPropertiesModel => (
	new TreatmentCheckerReportMedicationPropertiesModel(
		dto.drugTypes.map(mapTreatmentCheckerReportMedicationPropertyDrugTypeFromString),
		dto.isHerbal,
		dto.isPersonalizedMedicineAvailable,
		dto.isIncompartibleWithPregnancy,
		dto.relyOnFoodIntakeTypes.map(mapTreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeTypeFromString),
		dto.isEfficacyUnproven,
		isValueDefined(dto.hasPGxRecommendation) ? mapMedicationUsageDtoToModel(dto.hasPGxRecommendation) : undefined
	)
);

const mapMedicationUsageDtoToModel = (dto: MedicationUsageDto): MedicationUsageModel => (
	new MedicationUsageModel(
		mapComboboxDtoToComboboxModel(dto.region),
		mapComboboxDtoToComboboxModel(dto.population),
		dto.percent
	)
);
const mapTreatmentCheckerReportMedicationReportSideEffectDtoToModel = (dto: TreatmentCheckerReportMedicationSideEffectDto): TreatmentCheckerReportMedicationSideEffectComboboxModel => (
	new TreatmentCheckerReportMedicationSideEffectComboboxModel(
		dto.id,
		dto.displayText,
		mapTreatmentCheckerMedicationReportSideEffectFrequencyTypeFromString(dto.frequency)
	)
);

const mapTreatmentCheckerPersonalizedMedicineGuidelineDtoToModel = (dto: TreatmentCheckerPersonalizedMedicineGuidelineDto): TreatmentCheckerPersonalizedMedicineGuidelineModel => (
	new TreatmentCheckerPersonalizedMedicineGuidelineModel(
		dto.id,
		dto.displayText,
		(dto.sources ?? []).map(mapSourceDtoToModel)
	)
);

const mapPersonalizedMedicineRecommendationDtoToModel = (dto: TreatmentCheckerPersonalizedMedicineRecommendationDto): TreatmentCheckerPersonalizedMedicineRecommendationModel => (
	new TreatmentCheckerPersonalizedMedicineRecommendationModel(
		mapMedicationComboboxDtoToModel(dto.medication),
		mapGeneComboboxDtoToModel(dto.gene),
		mapSourceDtoToModel(dto.source),
		dto.geneticTestId,
		mapPharmacogenomicTestResultComboboxDtoToModel(dto.pharmacogenomicTestResult),
		dto.implication,
		dto.drugRecommendation,
		dto.comment,
		dto.population,
		dto.classification,
		dto.activityScore
	)
);

const mapTreatmentCheckerReportPgxLevelTestingDtoToModel = (dto: TreatmentCheckerReportMedicationPgxLevelTestingDto): TreatmentCheckerReportMedicationPgxLevelTestingModel => (
	new TreatmentCheckerReportMedicationPgxLevelTestingModel(
		dto.id,
		mapTreatmentCheckerReportMedicationPgxLevelTestingTypeFromString(dto.type),
		mapTreatmentCheckerReportMedicationPgxLevelTestingOrganizationFromString(dto.organization),
		mapSourceDtoToModel(dto.source),
		(dto.annotations ?? []).map(mapTreatmentCheckerReportMedicationPgxLevelTestingAnnotationFromString),
		(dto.affectedSubgroups ?? []).map(mapTreatmentCheckerReportMedicationPgxAffectedSubgroupDtoToModel)
	)
);

const mapTreatmentCheckerDiseaseTreatmentGuidelineDtoToModel = (dto: TreatmentCheckerDiseaseTreatmentGuidelineDto): TreatmentCheckerDiseaseTreatmentGuidelineModel => (
	new TreatmentCheckerDiseaseTreatmentGuidelineModel(
		dto.id,
		dto.displayCode,
		mapComboboxDtoToComboboxModel(dto.target),
		mapSourceDtoToModel(dto.source)
	)
);

const mapTreatmentCheckerReportMedicationPgxAffectedSubgroupDtoToModel = (
	dto: TreatmentCheckerReportMedicationPgxAffectedSubgroupDto
): TreatmentCheckerReportMedicationPgxAffectedSubgroupModel => (
	new TreatmentCheckerReportMedicationPgxAffectedSubgroupModel(
		dto.geneDisplayText,
		dto.name,
		dto.description
	)
);

const mapPubmedCitationsDtoToModel = (dto: PubmedCitationsDto): PubmedCitationsModel => (
	new PubmedCitationsModel(
		(dto?.personalizedMedicineCitations ?? []).map(mapPubmedPersonalizedMedicineCitationDtoToModel),
		(dto?.otherCitations ?? []).map(mapPubmedCitationDtoToModel)
	)
);

const mapPubmedPersonalizedMedicineCitationDtoToModel = (dto: PersonalizedMedicinePubmedCitationDto): PersonalizedMedicinePubmedCitationModel => (
	new PersonalizedMedicinePubmedCitationModel(
		dto.pubmedId,
		mapSourceDtoToModel(dto.source),
		dto.authors ?? [],
		dto.journal,
		dto.year,
		mapGeneComboboxDtoToModel(dto.gene)
	)
);

const mapPubmedCitationDtoToModel = (dto: PubmedCitationDto): PubmedCitationModel => (
	new PubmedCitationModel(
		dto.pubmedId,
		mapSourceDtoToModel(dto.source),
		dto.authors ?? [],
		dto.journal,
		dto.year
	)
);
