
import { GeneticFileParseRequestModel } from './genetic-file-parse-request.model';

export class GeneticFileParseRequestStateModel {

	public readonly isAlreadyRequested: boolean;
	public readonly request: GeneticFileParseRequestModel | undefined;

	protected constructor(
		isAlreadyRequested: boolean,
		request: GeneticFileParseRequestModel | undefined
	) {
		// TODO: Guard check on defined

		this.isAlreadyRequested = isAlreadyRequested;
		this.request = request;
	}

	public static createRequested(requestModel: GeneticFileParseRequestModel): GeneticFileParseRequestStateModel {
		return new GeneticFileParseRequestStateModel(true, requestModel);
	}

	public static createDefault(): GeneticFileParseRequestStateModel {
		return new GeneticFileParseRequestStateModel(false, undefined);
	}

}
