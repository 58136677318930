import { Provider } from '@angular/core';
import {
	ActivePatientsResolver, PatientAliasResolver, PatientCommandHandlerService, PatientProfileResolver, PatientQueryHandlerService, PatientResolver
} from '@nmn-domain/patients';
import { ActivePatientsViaQueryHandlerResolver } from './resolvers/active-patients.via-query-handler.resolver';
import { PatientAliasViaRouteResolver } from './resolvers/patient-alias.via-route.resolver';
import { PatientProfileViaQueryHandlerResolver } from './resolvers/patient-profile.via-query-handler.resolver';
import { PatientViaQueryHandlerResolver } from './resolvers/patient.via-query-handler.resolver';
import { PatientCommandHandlerViaClientService } from './services/patient.command-handler-via-client.service';
import { PatientQueryHandlerViaClientService } from './services/patient.query-handler-via-client.service';

export const patientProviders: Array<Provider> = [
	{
		provide: PatientCommandHandlerService,
		useClass: PatientCommandHandlerViaClientService
	},
	{
		provide: PatientQueryHandlerService,
		useClass: PatientQueryHandlerViaClientService
	},
	{
		provide: ActivePatientsResolver,
		useClass: ActivePatientsViaQueryHandlerResolver
	},
	{
		provide: PatientAliasResolver,
		useClass: PatientAliasViaRouteResolver
	},
	{
		provide: PatientProfileResolver,
		useClass: PatientProfileViaQueryHandlerResolver
	},
	{
		provide: PatientResolver,
		useClass: PatientViaQueryHandlerResolver
	}
];
