import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { CommandResult, EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { FailureModel } from '../../shared/failures/failure.model';
import { VaccinationCreateCommand } from '../models/commands/vaccination.create.command';
import { VaccinationDeleteCommand } from '../models/commands/vaccination.delete.command';
import { VaccinationUpdateCommand } from '../models/commands/vaccination.update.command';

export abstract class VaccinationCommandHandlerService {

	public abstract handleCreateCommand(
		command: VaccinationCreateCommand
	): Observable<Result<CommandResult<VaccinationCreateCommand, string>, FailureModel>>;

	public abstract handleUpdateCommand(
		command: VaccinationUpdateCommand
	): Observable<Result<EmptyCommandResult<VaccinationUpdateCommand>, FailureModel>>;

	public abstract handleDeleteCommand(
		command: VaccinationDeleteCommand
	): Observable<Result<EmptyCommandResult<VaccinationDeleteCommand>, FailureModel>>;

}
