import { Injectable } from '@angular/core';
import { MedicationDosageTypeClient } from '@nmn-communication/medication-dosage-types';
import { Result } from '@nmn-core/shared';
import { MedicationDosageTypeFilterModel, MedicationDosageTypeQueryHandlerService } from '@nmn-domain/medication-dosage-types';
import { DescribedComboboxModel, FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapDescribedComboboxDtoToDescribedComboboxModel } from '../../shared/factories/combobox.factory';
import { mapDtoArrayResultToModelArrayResult } from '../../shared/factories/pagination.factory';
import { mapFilterModelToDto } from '../factories/medication-dosage-type.factory';

@Injectable()
export class MedicationDosageTypeQueryHandlerViaClientService extends MedicationDosageTypeQueryHandlerService {

	constructor(
		private readonly client: MedicationDosageTypeClient
	) {
		super();
	}

	public getAsDescribedComboboxes(filter: MedicationDosageTypeFilterModel): Observable<Result<Array<DescribedComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapDescribedComboboxDtoToDescribedComboboxModel))
			);
	}

}
