import { GeneticFileParseRequestDto, GeneticFileParseRequestFilterDto } from '@nmn-communication/genetic-file-parse-requests';
import { GeneticFileParseRequestFilterModel, GeneticFileParseRequestModel, mapGeneticFileParseRequestStatusFromString } from '@nmn-domain/genetic-file-parse-requests';

export const mapGeneticFileParseRequestDtoToModel = (dto: GeneticFileParseRequestDto): GeneticFileParseRequestModel => (
	new GeneticFileParseRequestModel(
		dto.id,
		dto.patientId,
		mapGeneticFileParseRequestStatusFromString(dto.status),
		dto.error
	)
);

export const mapGeneticFileParseRequestFilterDtoToModel = (dto: GeneticFileParseRequestFilterDto): GeneticFileParseRequestFilterModel => (
	new GeneticFileParseRequestFilterModel(
		dto.patientId,
		dto.isActive,
		mapGeneticFileParseRequestStatusFromString(dto.status)
	)
);
