import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ApplicationRegionService } from '@nmn-background/application-region';
import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { catchError, mergeMap, Observable, of, take } from 'rxjs';

@Injectable()
export class RegionInterceptor implements HttpInterceptor {

	private readonly exclusions = [
		'assets',
		'api/configuration/frontend',
		'api/files',
		'api/tags/countries' // endpoints to retrieve regions themself
	];

	constructor(
		private readonly injector: Injector
	) { }

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.isExclusion(request)) {
			return next.handle(request);
		}

		return this.injector
			.get(ApplicationRegionService).currentApplicationRegion$
			.pipe(
				take(1),
				catchError(() => of(undefined)),
				mergeMap(region => next.handle(isStringDefinedAndNotEmpty(region?.id) ? request.clone({ setHeaders: { 'Accept-Region': region.id } }) : request))
			);
	}

	private isExclusion(request: HttpRequest<any>): boolean {
		return this.exclusions.some(item => request.url.indexOf(item) > -1);
	}

}
