import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneVariantClient, GeneVariantComboboxDto, GeneVariantFilterDto } from '@nmn-communication/gene-variants';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { constructHttpParams } from '@nmn-core/utils';
import { Observable } from 'rxjs';
import { createFailedResultFromErrorResponse, createResultFromResponse } from '../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { GeneVariantHttpClientConfiguration } from '../configurations/gene-variant.http-client.configuration';

export class GeneVariantHttpClient extends GeneVariantClient {

	constructor(
		private readonly configuration: GeneVariantHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public getBulkAsComboboxes(filter: GeneVariantFilterDto): Observable<Result<Array<GeneVariantComboboxDto>, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`;
		const params = constructHttpParams(filter);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<Array<GeneVariantComboboxDto>>(url, { params, headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

}
