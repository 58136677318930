import { LoginClient, LoginDto, LoginParameter, LoginViaFacebookParameter, LoginViaGoogleParameter, RefreshTokenParameter, TokenInfoDto } from '@nmn-communication/accounts';
import { Failure } from '@nmn-communication/shared';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../../../../../core/application-storages';
import { Result } from '../../../../../core/shared';
import { FakeDatabase } from '../../../../../modules/fakes/databases/fake.database';
import { wrapFuncCallAsFakeClientResponse } from '../../../../../modules/fakes/databases/fake.utils';
import { LoginFakeClientConfiguration } from '../configurations/login.fake-client.configuration';

export class LoginFakeClient extends LoginClient {

	constructor(
		private readonly configuration: LoginFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase,
		private readonly localStorage: LocalStorageService
	) {
		super();
	}

	public login(parameter: LoginParameter): Observable<Result<LoginDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.usersTable.login(parameter),
			this.configuration.serverDelayMs
		);
	}

	public loginViaGoogle(parameter: LoginViaGoogleParameter): Observable<Result<LoginDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.usersTable.loginViaGoogle(this.localStorage.getLastUserId(), parameter),
			this.configuration.serverDelayMs
		);
	}

	public loginViaFacebook(parameter: LoginViaFacebookParameter): Observable<Result<LoginDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.usersTable.loginViaFacebook(this.localStorage.getLastUserId(), parameter),
			this.configuration.serverDelayMs
		);
	}

	public refreshToken(parameter: RefreshTokenParameter): Observable<Result<TokenInfoDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.usersTable.refreshToken(parameter),
			this.configuration.serverDelayMs
		);
	}

}
