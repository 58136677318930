import { Provider } from '@angular/core';
import {
	PatientTreatmentCheckerFormConfigurationResolver, PatientTreatmentCheckerFormInModelResolver, TreatmentCheckerCommandHandlerService, TreatmentCheckerFormConfigurationResolver,
	TreatmentCheckerFormInModelResolver, TreatmentCheckerQueryHandlerService, TreatmentCheckerReportResolver
} from '@nmn-domain/treatment-checker';
import { PatientTreatmentCheckerFormConfigurationDefaultResolver } from './resolvers/patient-treatment-checker-form-configuration.default.resolver';
import { PatientTreatmentCheckerFormInModelDefaultResolver } from './resolvers/patient-treatment-checker-form-in model.default.resolver';
import { TreatmentCheckerFormConfigurationDefaultResolver } from './resolvers/treatment-checker-form-configuration.default.resolver';
import { TreatmentCheckerFormInModelDefaultResolver } from './resolvers/treatment-checker-form-in model.default.resolver';
import { TreatmentCheckerReportViaQueryHandlerResolver } from './resolvers/treatment-checker-report.via-query-handler.resolver';
import { TreatmentCheckerCommandHandlerViaClientService } from './services/treatment-checker.command-handler-via-client.service';
import { TreatmentCheckerQueryHandlerViaClientService } from './services/treatment-checker.query-handler-via-client.service';

export const treatmentCheckerProviders: Array<Provider> = [
	{
		provide: TreatmentCheckerReportResolver,
		useClass: TreatmentCheckerReportViaQueryHandlerResolver
	},
	{
		provide: PatientTreatmentCheckerFormConfigurationResolver,
		useClass: PatientTreatmentCheckerFormConfigurationDefaultResolver
	},
	{
		provide: PatientTreatmentCheckerFormInModelResolver,
		useClass: PatientTreatmentCheckerFormInModelDefaultResolver
	},
	{
		provide: TreatmentCheckerCommandHandlerService,
		useClass: TreatmentCheckerCommandHandlerViaClientService
	},
	{
		provide: TreatmentCheckerQueryHandlerService,
		useClass: TreatmentCheckerQueryHandlerViaClientService
	},
	{
		provide: TreatmentCheckerFormConfigurationResolver,
		useClass: TreatmentCheckerFormConfigurationDefaultResolver
	},
	{
		provide: TreatmentCheckerFormInModelResolver,
		useClass: TreatmentCheckerFormInModelDefaultResolver
	}
];
