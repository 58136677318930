import { Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { RegionFakeClientConfiguration } from '../configurations/region.fake-client.configuration';
import { RegionFakeClient } from '../services/region.fake-client';

export class RegionFakeClientBuilder {

	private configuration: RegionFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(configuration: RegionFakeClientConfiguration): RegionFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): RegionFakeClient {
		return new RegionFakeClient(this.configuration, this.injector.get(FakeDatabase));
	}

}
