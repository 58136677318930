import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { NavigationResource, RouteParameter } from '@nmn-middleware/navigation';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StorageService } from '../../../services';

@Injectable({ providedIn: 'root' })
export class ActivePatientGuard implements CanActivate, CanActivateChild {

	constructor(
		private readonly router: Router,
		private readonly storageService: StorageService
	) { }

	public canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> | boolean | UrlTree {
		return combineLatest([this.storageService.isUserInfoLoading$, this.storageService.userProfile$])
			.pipe(
				filter(([isUserInfoLoading, _]) => !isUserInfoLoading),
				map(([_, userProfile]) => {
					const patientId = route.paramMap.get(RouteParameter.PatientAlias);
					const isCurrentPatientActive = isValueDefined(userProfile.activePatientId) &&
						isValueDefined(userProfile.activePatientAlias) &&
						isStringDefinedAndNotEmpty(patientId) &&
						(patientId === userProfile.activePatientAlias || patientId === userProfile.activePatientId);

					if (isCurrentPatientActive) {
						return true;
					}

					return this.router.createUrlTree([NavigationResource.Account]);
				})
			);
	}

	public canActivateChild(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> | boolean | UrlTree {
		return this.canActivate(route);
	}

}
