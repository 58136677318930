import { Injectable } from '@angular/core';
import { GeneticFileParseRequestClient } from '@nmn-communication/genetic-file-parse-requests';
import { Result } from '@nmn-core/shared';
import {
	GeneticFileParseRequestCancelCommand, GeneticFileParseRequestCommandHandlerService, GeneticFileParseRequestCreateCommand,
	GeneticFileParseRequestModel, GeneticFileParseRequestStatus
} from '@nmn-domain/genetic-file-parse-requests';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommandResult, EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapGeneticFileParseRequestDtoToModel } from '../factories/genetic-file-parse-request.factory';

@Injectable()
export class GeneticFileParseRequestCommandHandlerViaClientService extends GeneticFileParseRequestCommandHandlerService {

	constructor(
		private readonly client: GeneticFileParseRequestClient
	) {
		super();
	}

	public handleCreateCommand(
		command: GeneticFileParseRequestCreateCommand
	): Observable<
		Result<CommandResult<GeneticFileParseRequestCreateCommand, GeneticFileParseRequestModel>, FailureModel>> {
		return this.client
			.create({ blobId: command.blobId, patientId: command.patientId })
			.pipe(map(result => result.map(
				success => new CommandResult(command, mapGeneticFileParseRequestDtoToModel(success)),
				mapFailureToFailureModel)
			));
	}

	public handleCancelCommand(
		command: GeneticFileParseRequestCancelCommand
	): Observable<Result<EmptyCommandResult<GeneticFileParseRequestCancelCommand>, FailureModel>> {
		return this.client
			.update(
				{ id: command.id, patientId: command.patientId, isActive: true },
				{
					patientId: command.patientId,
					status: GeneticFileParseRequestStatus.Aborted.toString(),
					isActive: false
				})
			.pipe(map(result => result.map(mapFailureToFailureModel)
				.toResult(() => new EmptyCommandResult(command))));
	}

}
