import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';

export class ClinicModel {

	public readonly name?: string;
	public readonly contacts?: string;
	public readonly address?: string;

	public get isNameDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.name);
	}

	public get isContactsDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.contacts);
	}

	public get isAddressDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.address);
	}

	constructor(
		name: string | undefined,
		contacts: string | undefined,
		address: string | undefined
	) {
		this.name = name;
		this.contacts = contacts;
		this.address = address;
	}

}
