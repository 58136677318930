import { MedicationAdmStatusDescribedComboboxDto, MedicationAdmStatusFilterDto } from '@nmn-communication/medication-adm-statuses';
import { mapMedicationAdmStatusTypeFromString, MedicationAdmStatusDescribedComboboxModel, MedicationAdmStatusFilterModel } from '@nmn-domain/medication-adm-statuses';

export const mapFilterModelToDto = (model: MedicationAdmStatusFilterModel): MedicationAdmStatusFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern
	}
);

export const mapFilterDtoToModel = (dto: MedicationAdmStatusFilterDto): MedicationAdmStatusFilterModel => (
	new MedicationAdmStatusFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern
	)
);

export const mapMedicationAdmStatusDescribedComboboxDtoToModel = (dto: MedicationAdmStatusDescribedComboboxDto): MedicationAdmStatusDescribedComboboxModel => (
	new MedicationAdmStatusDescribedComboboxModel(
		dto.id,
		dto.displayText,
		dto.description,
		mapMedicationAdmStatusTypeFromString(dto.type)
	)
);
