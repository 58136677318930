import { InformationBlockModel } from '../../../../../modules/core/models/shared/information-block.model';

export class LabTestCheckerInformationBlockModel extends InformationBlockModel {

	public readonly count: number;
	public readonly lastCheckUpDate: Date;

	constructor(
		fillingUpPercentage: number,
		count: number,
		lastCheckUpDate: Date
	) {

		super(fillingUpPercentage);

		// TODO: add Guard check

		this.count = count;
		this.lastCheckUpDate = lastCheckUpDate;

	}

}
