import { DocumentAttachmentDto, DocumentCreateParameter, DocumentDescribedComboboxDto, DocumentDto, DocumentFilterDto, DocumentUpdateParameter } from '@nmn-communication/documents';
import { mapDateInServerFormatToDate, mapDateInServerFormatToDateSafely } from '@nmn-core/utils';
import { mapDocumentIconTypeFromString } from '@nmn-domain/document-types';
import { DocumentAttachmentModel, DocumentCreateCommand, DocumentDescribedComboboxModel, DocumentFilterModel, DocumentModel, DocumentUpdateCommand } from '@nmn-domain/documents';
import { PagedCollectionDto } from '../../../../modules/core/clients';
import { PagedCollectionModel } from '../../../../modules/core/models/pagination/paged-collection.model';
import { mapPagedCollectionDtoToModel } from '../../../../modules/core/utils/pagination/pagination.factory.utils';
import { mapDoctorEncounterComboboxDtoToModel } from '../../doctor-encounters/factories/doctor-encounter-combobox.factory';
import { mapDocumentTypeDescribedComboboxDtoToModel } from '../../document-types/factories/document-type.factory';
import { mapUploadedFileDtoToModel } from '../../file-uploader/factories/file-uploader.factory';
import { mapHealthIssueComboboxDtoToModel } from '../../health-issues/factories/health-issue-combobox.factory';
import { mapTakenMedicationComboboxDtoToModel } from '../../taken-medications/factories/taken-medication-combobox.factory';

export const mapDtoToModel = (dto: DocumentDto): DocumentModel => (
	new DocumentModel(
		dto.id,
		dto.patientId,
		dto.name,
		dto.description,
		mapDocumentTypeDescribedComboboxDtoToModel(dto.type),
		dto.attachments?.map(mapDocumentAttachmentDtoToModel) || [],
		dto.relatedEncounterRecords?.map(mapDoctorEncounterComboboxDtoToModel) || [],
		dto.relatedTakenMedications?.map(mapTakenMedicationComboboxDtoToModel) || [],
		dto.relatedHealthIssues?.map(mapHealthIssueComboboxDtoToModel) || [],
		mapDateInServerFormatToDateSafely(dto.createdOn),
		mapDateInServerFormatToDate(dto.updatedOn),
		dto.size
	)
);

export const mapFilterModelToDto = (model: DocumentFilterModel): DocumentFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		documentTypeIds: model.documentTypeIds,
		patientIds: model.patientIds,
		searchPattern: model.searchPattern
	}
);

export const mapFilterDtoToModel = (dto: DocumentFilterDto): DocumentFilterModel => (
	new DocumentFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.documentTypeIds,
		dto.patientIds,
		dto.searchPattern
	)
);

export const mapDocumentPagedCollectionDtoToModel = (
	pagedCollectionDto: PagedCollectionDto<DocumentDto, DocumentFilterDto>
): PagedCollectionModel<DocumentModel, DocumentFilterModel> =>
	mapPagedCollectionDtoToModel(pagedCollectionDto, mapDtoToModel, mapFilterDtoToModel);

export const mapCreateCommandToParameter = (command: DocumentCreateCommand): DocumentCreateParameter => (
	{
		patientId: command.patientId,
		name: command.name,
		description: command.description,
		typeId: command.typeId,
		attachmentFileIds: command.attachmentIds,
		relatedEncounterIds: command.relatedEncounterIds,
		relatedTakenMedicationIds: command.relatedTakenMedicationIds,
		relatedHealthIssueIds: command.relatedHealthIssueIds
	}
);

export const mapUpdateCommandToParameter = (command: DocumentUpdateCommand): DocumentUpdateParameter => (
	{
		patientId: command.patientId,
		name: command.name,
		description: command.description,
		typeId: command.typeId,
		attachmentIds: command.attachmentIds,
		relatedEncounterIds: command.relatedEncounterIds,
		relatedTakenMedicationIds: command.relatedTakenMedicationIds,
		relatedHealthIssueIds: command.relatedHealthIssueIds
	}
);

export const mapDocumentDescribedComboboxDtoToModel = (dto: DocumentDescribedComboboxDto): DocumentDescribedComboboxModel => (
	new DocumentDescribedComboboxModel(
		dto.id,
		dto.displayText,
		dto.description,
		mapDocumentIconTypeFromString(dto.type)
	)
);

export const mapDocumentAttachmentDtoToModel = (dto: DocumentAttachmentDto): DocumentAttachmentModel => (
	new DocumentAttachmentModel(
		dto.id,
		mapUploadedFileDtoToModel(dto.file),
		true
	)
);
