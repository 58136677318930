import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

export abstract class PatientAliasResolver implements Resolve<string> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		routerState: RouterStateSnapshot
	): Observable<string> | Promise<string> | string;

}
