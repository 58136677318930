import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LocaleComboboxModel } from '../models/locale-combobox.model';

export abstract class LocalesResolver implements Resolve<Array<LocaleComboboxModel>> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		routerState: RouterStateSnapshot
	): Observable<Array<LocaleComboboxModel>> | Promise<Array<LocaleComboboxModel>> | Array<LocaleComboboxModel>;

}
