export class GeneticFileParseRequestCancelCommand {

	public readonly id: string;
	public readonly patientId: string;
	public readonly isActive: boolean;

	private constructor(
		id: string,
		patientId: string,
		isActive: boolean
	) {
		// TODO: Guard add null check

		this.id = id;
		this.patientId = patientId;
		this.isActive = isActive;
	}

	public static create(id: string, patientId: string): GeneticFileParseRequestCancelCommand {
		return new GeneticFileParseRequestCancelCommand(id, patientId, true);
	}

}
