export class UserProfileDeleteCommand {

	public readonly password: string;

	constructor(
		password: string
	) {
		this.password = password;
	}

}
