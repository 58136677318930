import { Injector, Provider } from '@angular/core';
import { DiseaseClient } from '@nmn-communication/diseases';
import { diseaseClientFactory } from './factories/disease.client.factory';

export const diseaseProviders: Array<Provider> = [
	{
		provide: DiseaseClient,
		useFactory: diseaseClientFactory,
		deps: [Injector]
	}
];
