import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';

export class FailuresDialogInModel {

	public readonly failureHints: Array<string>;
	public readonly title: string;
	public readonly confirmBtnTitle: string;

	public constructor(
		failureHints: Array<string>,
		title: string,
		confirmBtnTitle: string
	) {
		// TODO: Guards
		this.title = title;
		this.failureHints = failureHints;
		this.confirmBtnTitle = confirmBtnTitle;
	}

	public static mergeWithDefaultSafety = (obj: FailuresDialogInModel): FailuresDialogInModel => {
		const failureHints = (obj.failureHints || []).length > 0 ?
			obj.failureHints :
			['shared.forms.failureHints.defaultDialogMessage'];

		const title = isStringDefinedAndNotEmpty(obj?.title) ?
			obj.title :
			'shared.forms.failureHints.defaultDialogTitle';

		const confirmBtnTitle = isStringDefinedAndNotEmpty(obj?.confirmBtnTitle) ?
			obj.confirmBtnTitle :
			'shared.forms.failureHints.defaultDialogConfirmBtnTitle';

		return new FailuresDialogInModel(failureHints, title, confirmBtnTitle);
	}

}
