export class TreatmentCheckerReportMedicationPgxAffectedSubgroupModel {

	public readonly geneDisplayText: string;
	public readonly name: string;
	public readonly description: string;

	constructor(
		geneDisplayText: string,
		name: string,
		description: string
	) {
		// TODO: Guard

		this.geneDisplayText = geneDisplayText;
		this.name = name;
		this.description = description;
	}

}
