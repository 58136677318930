export enum GoogleAnalyticsEvent {

	// ============================================================================================
	// Ecommerce events
	// ============================================================================================

	AddToCart = 'add_to_cart',
	RemoveFromCart = 'remove_from_cart',
	Purchase = 'purchase',

	// ============================================================================================
	// Custom events
	// ============================================================================================

	// Account events

	Login = 'nmn__login',
	Registration = 'nmn__registration',
	AccountDeletionRequested = 'nmn__account_deletion_requested',
	AccountDeletionRevoked = 'nmn__account_deletion_revoked',
	AccountAttached = 'nmn__account_attached',

	// Document events

	// TODO: think what to track

	// General events

	SentMessageViaContactForm = 'nmn__sent_message_via_contact_form',

	// Genetic card events

	GeneticTestCreated = 'nmn__genetic_test_created',
	GeneticTestDeleted = 'nmn__genetic_test_deleted',
	GeneticTestDeletedBulk = 'nmn__genetic_test_deleted_bulk',
	UploadedDnaFile = 'nmn__uploaded_dna_file',

	// Medical card events

	// TODO: think what to track

	// Patient events

	PatientCreation = 'nmn__patient_creation',
	PatientDeletionRequested = 'nmn__patient_deletion_requested',
	PatientDeletionRevoked = 'nmn__patient_deletion_revoked',

	// Treatment checker events

	TreatmentCheckerReportCreation = 'nmn__treatment_checker_report_creation',
	TreatmentCheckerReportExportedAsPdf = 'nmn__treatment_checker_report_exported_as_pdf',

	// Vaccination card events

	VaccinationCreated = 'nmn__vaccination_created',
	VaccinationDeleted = 'nmn__vaccination_deleted'

}
