import { TranslocoService } from '@ngneat/transloco';
import {
	DocumentCreateParameter, DocumentDeleteAttachmentParameter, DocumentDeleteBulkParameter, DocumentDescribedComboboxDto,
	DocumentDto, DocumentFilterDto, DocumentFindParameter, DocumentUpdateParameter
} from '@nmn-communication/documents';
import { Guid, isArrayDefined, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { DocumentIconType } from '@nmn-domain/document-types';
import { PagedCollectionDto, PageOptionsDto } from '../../core/clients';
import { FakeDatabase } from '../databases/fake.database';
import { getPagedCollectionWithoutDtoFilter, getPagedCollectionWithoutItemFilter } from '../databases/fake.utils';
import { FakeDocumentDescribedComboboxDto, mapFakeDocumentDescriptedComboboxDtoToDocumentDescriptedComboboxDto } from '../models/comboboxes/fake-document-described-combobox.dto';
import { FakeLocalizableEntity } from '../models/fake-localizable-entity';
import { setLocalizableEntity, setTranslation } from '../utils/localize';

export class DocumentFakeTable {

	private readonly database: FakeDatabase;
	private readonly data: Array<DocumentFakeRecord>;

	constructor(
		database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.database = database;
		this.data = [...initialData];
	}

	public getComboboxesPagedCollection(filter: DocumentFilterDto): Array<DocumentDescribedComboboxDto> {
		return getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			filterPredicateForRecord,
			this.mapFromRecordToDto.bind(this),
			compareFn
		).items
			.map(item => this.mapFromDtoToDescribedComboboxDto(this.translocoService, item))
			.map(item => mapFakeDocumentDescriptedComboboxDtoToDocumentDescriptedComboboxDto(this.translocoService, item));
	}

	public getPagedCollection(
		pageOptions: PageOptionsDto<DocumentFilterDto>
	): PagedCollectionDto<DocumentDto, DocumentFilterDto> {
		return getPagedCollectionWithoutItemFilter(
			this.translocoService,
			this.data,
			pageOptions,
			this.mapFromRecordToDto.bind(this),
			filterPredicate,
			compareFn);
	}

	public find(parameter: DocumentFindParameter): DocumentDto {
		const record = this.data
			.find((item: DocumentFakeRecord) => findPredicate(item, parameter));

		return this.mapFromRecordToDto(record);
	}

	public findExactMany(ids: Array<string>): Array<DocumentDescribedComboboxDto> {
		if (!isValueDefined(ids) || ids.length < 1) {
			return [];
		}

		const records = this.data
			.filter((item: DocumentFakeRecord) => findExactManyPredicate(item, ids));

		return records.map(
			item => mapFakeDocumentDescriptedComboboxDtoToDocumentDescriptedComboboxDto(
				this.translocoService,
				this.mapFromDtoToDescribedComboboxDto(
					this.translocoService,
					this.mapFromRecordToDto(item)
				)));
	}

	public create(parameter: DocumentCreateParameter): string {
		const record = this.mapFromCreateParameterToRecord(parameter);
		this.data.push(record);

		return record.id;
	}

	public delete(parameter: DocumentFindParameter): void {
		const index = this.data
			.findIndex((item: DocumentFakeRecord) => findPredicate(item, parameter));

		if (index >= 0) {
			// do not delete attachment here
			this.data.splice(index, 1);
		}
	}

	public deleteBulk(parameter: DocumentDeleteBulkParameter): void {
		for (const id of parameter.ids) {
			this.delete({ id, patientId: parameter.patientId });
		}
	}

	public deleteAttachment(
		findParameter: DocumentFindParameter,
		deleteParameter: DocumentDeleteAttachmentParameter
	): void {
		if (deleteParameter.attachmentId) {
			const record = this.data
				.find((item: DocumentFakeRecord) => findPredicate(item, findParameter));
			if (isValueDefined(record)) {
				record.attachmentIds = record.attachmentIds
					.filter(item => deleteParameter.attachmentId !== item);
			}
		}
	}

	public update(findParameter: DocumentFindParameter, updateParameter: DocumentUpdateParameter): void {
		const record = this.data.find((item: DocumentFakeRecord) => findPredicate(item, findParameter));

		if (!isValueDefined(record)) {
			throw new Error('Document to update was not found');
		}

		applyUpdateParameter(this.translocoService, record, updateParameter);
	}

	public attachHealthIssue(findParameter: DocumentFindParameter, healthIssueId: string): void {
		const record = this.data.find((item: DocumentFakeRecord) => findPredicate(item, findParameter));

		if (!isValueDefined(record)) {
			throw new Error('Document to update was not found');
		}

		if (!record.relations.healthIssueIds.some(id => id === healthIssueId)) {
			record.relations.healthIssueIds.push(healthIssueId);
		}
	}

	public detachHealthIssueForAll(healthIssueId: string, excludeDocumentIds: Array<string>): void {
		const records = this.data.filter((item: DocumentFakeRecord) =>
			filterPredicateForRecord(item, { healthIssueIds: [healthIssueId], ignoreIds: excludeDocumentIds })
		);
		records.forEach(record => {
			const index = record.relations.healthIssueIds.findIndex(item => item === healthIssueId);

			if (index >= 0) {
				record.relations.healthIssueIds.splice(index, 1);
			}
		});
	}

	public attachEncounter(findParameter: DocumentFindParameter, doctorEncounterId: string): void {
		const record = this.data.find((item: DocumentFakeRecord) => findPredicate(item, findParameter));

		if (!isValueDefined(record)) {
			throw new Error('Document to update was not found');
		}

		if (!record.relations.doctorEncounterIds.some(id => id === doctorEncounterId)) {
			record.relations.doctorEncounterIds.push(doctorEncounterId);
		}
	}

	public detachDoctorEncounterForAll(doctorEncounterId: string, excludeDocumentIds: Array<string>): void {
		const records = this.data.filter((item: DocumentFakeRecord) =>
			filterPredicateForRecord(item, { encounterIds: [doctorEncounterId], ignoreIds: excludeDocumentIds })
		);
		records.forEach(record => {
			const index = record.relations.doctorEncounterIds.findIndex(item => item === doctorEncounterId);

			if (index >= 0) {
				record.relations.doctorEncounterIds.splice(index, 1);
			}
		});
	}

	public attachTakenMedication(findParameter: DocumentFindParameter, takenMedicationId: string): void {
		const record = this.data.find((item: DocumentFakeRecord) => findPredicate(item, findParameter));

		if (!isValueDefined(record)) {
			throw new Error('Document to update was not found');
		}

		if (!record.relations.takenMedicationIds.some(id => id === takenMedicationId)) {
			record.relations.takenMedicationIds.push(takenMedicationId);
		}
	}

	public detachTakenMedicationForAll(takenMedicationId: string, excludeDocumentIds: Array<string>): void {
		const records = this.data.filter((item: DocumentFakeRecord) =>
			filterPredicateForRecord(item, { takenMedicationIds: [takenMedicationId], ignoreIds: excludeDocumentIds })
		);
		records.forEach(record => {
			const index = record.relations.takenMedicationIds.findIndex(item => item === takenMedicationId);

			if (index >= 0) {
				record.relations.takenMedicationIds.splice(index, 1);
			}
		});
	}

	public addGeneticDocument(patientId: string): void {
		this.data.push(
			{
				id: '00000000-0000-0000-0201-000000000100',
				name: {
					en: 'Genetic file',
					uk: 'Генетичний файл'
				},
				description: {
					en: 'autogenerated by Apixmed',
					uk: 'Сгенеровано автоматично Apixmed'
				},
				typeId: DocumentIconType.Genetic,
				patientId,
				attachmentIds: ['00000000-0000-0000-0202-000000000001', '00000000-0000-0000-0202-000000000006'],
				relations: {
					doctorEncounterIds: [],
					takenMedicationIds: [],
					healthIssueIds: []
				},
				createdOn: '2020-09-12T11:15:00Z',
				updatedOn: '2020-09-12T18:00:00Z'
			}
		);
	}

	private mapFromDtoToDescribedComboboxDto(
		translocoService: TranslocoService,
		record: DocumentDto
	): FakeDocumentDescribedComboboxDto {
		if (!isValueDefined(record)) {
			return undefined;
		}

		return {
			id: record.id,
			displayText: setLocalizableEntity(record.name, translocoService.getActiveLang()),
			description: setLocalizableEntity(record.description, translocoService.getActiveLang()),
			type: this.database.documentTypesTable.findAsCombobox({ id: record.type.id })?.id
		};
	}

	private mapFromRecordToDto(record: DocumentFakeRecord): DocumentDto {
		return {
			id: record.id,
			patientId: record.patientId,
			name: setTranslation(this.translocoService, record.name),
			description: setTranslation(this.translocoService, record.description),
			attachments: this.database.documentAttachmentsTable.find(record.attachmentIds),
			type: this.database.documentTypesTable.findAsCombobox({ id: record.typeId }),
			relatedEncounterRecords: record.relations.doctorEncounterIds
				.map((id: string) => this.database.doctorEncounterTable.findAsCombobox({ id, patientId: record.patientId }))
				.filter(isValueDefined),
			relatedTakenMedications: record.relations.takenMedicationIds
				.map((id: string) => this.database.takenMedicationsTable.findAsCombobox({ id, patientId: record.patientId }))
				.filter(isValueDefined),
			relatedHealthIssues: record.relations.healthIssueIds
				.map((id: string) => this.database.healthIssueTable.findAsCombobox({ id, patientId: record.patientId }))
				.filter(isValueDefined),
			createdOn: record.createdOn,
			updatedOn: record.updatedOn,
			size: this.calculateSize(record)
		};
	}

	private calculateSize(record: DocumentFakeRecord): number {
		const documentAttachments = this.database.documentAttachmentsTable.find(record.attachmentIds);
		if (documentAttachments.length < 1) {
			return 0;
		}

		return documentAttachments
			.map(item => item.file.lengthInBytes)
			.reduce((prev, next) => prev + next);
	}

	private mapFromCreateParameterToRecord(parameter: DocumentCreateParameter): DocumentFakeRecord {
		const operationDateTime = (new Date()).toISOString();

		return {
			id: Guid.newGuid(),
			patientId: parameter.patientId,
			name: setLocalizableEntity(parameter.name, this.translocoService.getActiveLang()),
			description: setLocalizableEntity(parameter.description, this.translocoService.getActiveLang()),
			typeId: parameter.typeId,
			attachmentIds: parameter.attachmentFileIds ?? [],
			relations: {
				doctorEncounterIds: parameter.relatedEncounterIds,
				takenMedicationIds: parameter.relatedTakenMedicationIds,
				healthIssueIds: parameter.relatedHealthIssueIds
			},
			createdOn: operationDateTime,
			updatedOn: undefined
		};
	}

}

const filterPredicate = (item: DocumentDto, filter: DocumentFilterDto): boolean => {
	let result = true;

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result &&
			(
				item.name.indexOf(filter.searchPattern) >= 0 ||
				item.type.displayText.indexOf(filter.searchPattern) >= 0 ||
				item.createdOn.indexOf(filter.searchPattern) >= 0 ||
				item.updatedOn?.indexOf(filter.searchPattern) >= 0
				// TODO: add search in relations
			);
	}

	if (result && isArrayDefined(filter.ignoreIds)) {
		result = result && filter.ignoreIds.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isArrayDefined(filter.patientIds)) {
		result = result && filter.patientIds.some(patientId => item.patientId === patientId);
	}

	if (result && isArrayDefined(filter.documentTypeIds)) {
		result = result && filter.documentTypeIds.some(documentTypeId => item.type.id === documentTypeId);
	}

	return result;
};

const filterPredicateForRecord = (
	item: DocumentFakeRecord,
	filter: DocumentFilterDto
): boolean => {
	let result = true;

	if (result && isArrayDefined(filter.ids)) {
		result = result && filter.ids.some(id => item.id === id);
	}

	if (result && isArrayDefined(filter.ignoreIds)) {
		result = result && filter.ignoreIds.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isArrayDefined(filter.healthIssueIds)) {
		result = result &&
			filter.healthIssueIds.some(id => item.relations.healthIssueIds.some(relationId => relationId === id));
	}

	if (result && isArrayDefined(filter.encounterIds)) {
		result = result &&
			filter.encounterIds.some(id => item.relations.doctorEncounterIds.some(relationId => relationId === id));
	}

	if (result && isArrayDefined(filter.takenMedicationIds)) {
		result = result &&
			filter.takenMedicationIds.some(id => item.relations.takenMedicationIds.some(relationId => relationId === id));
	}

	return result;
};

const findPredicate = (
	item: DocumentFakeRecord,
	findParameter: DocumentFindParameter
): boolean =>
	item.id === findParameter.id;

const findExactManyPredicate = (
	item: DocumentFakeRecord,
	ids: Array<string>
): boolean =>
	ids.indexOf(item.id) > -1;

/* eslint-disable  */
/* eslint-disable complexity */
const compareFn = (item1: DocumentDto, item2: DocumentDto, sorting: string): number => {
	if (sorting === 'name asc') {
		return item1.name > item2.name ? 1 : item1.name < item2.name ? -1 : 0;
	} else if (sorting === 'name desc') {
		return item1.name < item2.name ? 1 : item1.name > item2.name ? -1 : 0;
	} else if (sorting === 'createdOn asc') {
		return item1.createdOn > item2.createdOn ? 1 : item1.createdOn < item2.createdOn ? -1 : 0;
	} else if (sorting === 'createdOn desc') {
		return item1.createdOn < item2.createdOn ? 1 : item1.createdOn > item2.createdOn ? -1 : 0;
	} else if (sorting === 'lastModifiedOn asc') {
		const lastModifiedOnItem1 = isValueDefined(item1.updatedOn) && item1.updatedOn > item1.createdOn ?
			item1.updatedOn : item1.createdOn;
		const lastModifiedOnItem2 = isValueDefined(item2.updatedOn) && item2.updatedOn > item2.createdOn ?
			item2.updatedOn : item2.createdOn;

		return lastModifiedOnItem1 > lastModifiedOnItem2 ? 1 : lastModifiedOnItem1 < lastModifiedOnItem2 ? -1 : 0;
	} else if (sorting === 'lastModifiedOn desc') {
		const lastModifiedOnItem1 = isValueDefined(item1.updatedOn) && item1.updatedOn > item1.createdOn ?
			item1.updatedOn : item1.createdOn;
		const lastModifiedOnItem2 = isValueDefined(item2.updatedOn) && item2.updatedOn > item2.createdOn ?
			item2.updatedOn : item2.createdOn;

		return lastModifiedOnItem1 < lastModifiedOnItem2 ? 1 : lastModifiedOnItem1 > lastModifiedOnItem2 ? -1 : 0;
	}

	return 0;
};
/* eslint-enable complexity */
/* eslint-enable */

const applyUpdateParameter = (
	translocoService: TranslocoService,
	record: DocumentFakeRecord,
	parameter: DocumentUpdateParameter
): void => {
	const operationDateTime = (new Date()).toISOString();

	record.patientId = parameter.patientId;
	record.name = setLocalizableEntity(parameter.name, translocoService.getActiveLang());
	record.description = setLocalizableEntity(parameter.description, translocoService.getActiveLang());
	record.typeId = parameter.typeId;
	record.attachmentIds = parameter.attachmentIds;
	record.relations = {
		doctorEncounterIds: parameter.relatedEncounterIds,
		takenMedicationIds: parameter.relatedTakenMedicationIds,
		healthIssueIds: parameter.relatedHealthIssueIds
	};
	record.updatedOn = operationDateTime;
};

interface DocumentFakeRecord {
	id: string;
	name: FakeLocalizableEntity;
	description: FakeLocalizableEntity;
	typeId: string;
	patientId: string;
	attachmentIds: Array<string>;
	relations: DocumentRelations;
	createdOn: string;
	updatedOn?: string;
}

interface DocumentRelations {
	doctorEncounterIds: Array<string>;
	takenMedicationIds: Array<string>;
	healthIssueIds: Array<string>;
}

// DocumentFakeRecord (initial data) has id mask 00000000-0000-0000-0201-************
const initialData: Array<DocumentFakeRecord> = [
	{
		id: '00000000-0000-0000-0201-000000000001',
		name: {
			en: 'Routine complete blood count',
			uk: 'Загальний аналіз крові'
		},
		description: {
			en: 'Mayo Clinic Laboratory examination Center\nAnalysis was done with Siemens Sysmex CS-2500 Hemostasis Blood Coagulation Analyzer',
			uk: 'Центр лабораторних досліджень клініки Мейо\nАналіз проводився за допомогою аналізатора гемостазу, згортання крові Siemens Sysmex CS-2500'
		},
		typeId: DocumentIconType.LabResults,
		patientId: '00000000-0000-0000-0002-000000000001',
		attachmentIds: ['00000000-0000-0000-0202-000000000007'],
		relations: {
			doctorEncounterIds: [
				'00000000-0000-0000-0301-100000000002',
				'doctor-encounter-fake-record-2'
			],
			takenMedicationIds: [
				'00000000-0000-0000-0201-700000000001'
			],
			healthIssueIds: [
				'health-issue-fake-record-1'
			]
		},
		createdOn: '2020-09-12T11:15:00Z',
		updatedOn: '2020-09-12T18:00:00Z'
	},
	{
		id: '00000000-0000-0000-0201-000000000002',
		name: {
			en: 'X-Ray of left ankle join',
			uk: 'Рентген лівого гомілковостопного суглоба'
		},
		description: {
			en: 'No bone damage is detected',
			uk: 'Пошкоджень кісток не виявлено'
		},
		typeId: DocumentIconType.LabResults,
		patientId: '00000000-0000-0000-0002-000000000001',
		attachmentIds: ['00000000-0000-0000-0202-000000000002'],
		relations: {
			doctorEncounterIds: [],
			takenMedicationIds: [],
			healthIssueIds: []
		},
		createdOn: '2021-03-12T18:00:00Z',
		updatedOn: '2020-09-11T11:10:00Z'
	},
	{
		id: '00000000-0000-0000-0201-000000000003',
		name: {
			en: 'GERD treatment, Pharmacy',
			uk: 'Лікування ГЕРХ, Фармація'
		},
		description: {
			en: 'Prescription of Dexilant (Dexlansoprazole), 60 mg. \nCheck carefully drug instruction and contact a doctor if symptoms are not',
			uk: 'Рецепт Dexilant (Dexlansoprazole), 60 мг. \nУважно ознайомтеся з інструкцією до препарату та зверніться до лікаря, якщо симптомів немає'
		},
		typeId: DocumentIconType.DrugInstruction,
		patientId: '00000000-0000-0000-0002-000000000001',
		attachmentIds: ['00000000-0000-0000-0202-000000000003'],
		relations: {
			doctorEncounterIds: [
				'00000000-0000-0000-0301-100000000005'
			],
			takenMedicationIds: [],
			healthIssueIds: []
		},
		createdOn: '2021-03-07T18:00:00Z',
		updatedOn: '2020-10-11T14:00:00Z'
	},
	{
		id: '00000000-0000-0000-0201-000000000004',
		name: {
			en: 'Upper endoscopy',
			uk: 'Верхня ендоскопія'
		},
		description: {
			en: '43241. Esophagogastroduodenoscopy, flexible, transoral; with insertion of intraluminal tube or catheter.\nREPORT ON UGI ENDOSCOPY\n\nSEDATION Nil\nINDICATION : GERD\nENDOSCOPIC FINDINGS:\nIsland of pinkish mucosa < 3 cm seen coming out from the GE\njunction. No hiatus hemia seen. Bx taken for HPE.\nStomach:\nPatchy erythema seen in the antrum of the stomach. Fundus and body\nof the stomach normal. RUT for H.pylori taken and found to be\nDuodenum:\nNormal mucosa upto lind part.\nDIAGNOSIS:\nANTRAL GASTRITIS\nR/O SMALL BARRETT\'S\nRecommendation:\nReview in OPD with HPE report\nComplication 2 Nil\nRapid Urease Test Yes\nMucosal biopsy : Yes\nContact: HITENDRA K GARG',
			uk: '43241. Езофагогастродуоденоскопія гнучка трансоральна; із введенням внутрішньопросвітної трубки або катетера.\nЗВІТ ПРО ЕНДОСКОПІЮ УГІ\n\nСЕДАЦІЇ Ніякої\nПОКАЗКИ: ГЕРХ\nЕНДОСКОПІЧНІ ЗНАХОДИ:\nОстрівець рожевої слизової оболонки < 3 см, який виходить із шлунково-кишкового з’єднання. Гіатус-гемія не спостерігається. Bx зроблено для HPE.\nШлунок:\nВ антральному відділі шлунка спостерігається нерівна еритема. Очне дно і тіло\nшлунка в нормі. RUT для H.pylori взято та виявлено, що це\nДванадцятипала кишка:\nНормальна слизова до линдової частини.\nДІАГНОЗ:\nАНТРАЛЬНИЙ ГАСТРИТ\nR/O МАЛИЙ БАРРЕТ\nРекомендація:\nОгляд в OPD зі звітом HPE\nУскладнення 2 Ніяк\ nШвидкий уреазний тест Так\nБіопсія слизової оболонки: Так\nКонтактна особа: HITENDRA K GARG'
		},
		typeId: DocumentIconType.Photo,
		patientId: '00000000-0000-0000-0002-000000000001',
		attachmentIds: ['00000000-0000-0000-0202-000000000004'],
		relations: {
			doctorEncounterIds: [],
			takenMedicationIds: [],
			healthIssueIds: []
		},
		createdOn: '2021-02-17T18:00:00Z',
		updatedOn: '2020-11-10T13:00:00Z'
	},
	{
		id: '00000000-0000-0000-0201-000000000005',
		name: {
			en: 'Levofloxacin Prescription',
			uk: 'Левофлоксацин за рецептом'
		},
		description: {
			en: 'Prescription of Levofloxacin, 500 mg Imodium (loperamide), 4 mg',
			uk: 'Рецепт Левофлоксацин 500 мг Імодіум (лоперамід) 4 мг'
		},
		typeId: DocumentIconType.Prescription,
		patientId: '00000000-0000-0000-0002-000000000001',
		attachmentIds: ['00000000-0000-0000-0202-000000000005'],
		relations: {
			doctorEncounterIds: [],
			takenMedicationIds: [
				'00000000-0000-0000-0201-200000000001'
			],
			healthIssueIds: []
		},
		createdOn: '2021-02-06T15:00:00Z',
		updatedOn: '2020-12-01T14:00:00Z'
	}
];

// eslint-disable-next-line max-lines
