import { DescribedComboboxDto, Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { GenderFilterDto } from '../models/gender-filter.dto';

export abstract class GenderClient {

	public abstract getBulkAsComboboxes(filter: GenderFilterDto): Observable<Result<Array<DescribedComboboxDto<string>>, Failure>>;

}
