export class SendMessageCommand {

	public readonly contactEmail: string;
	public readonly fullName: string;
	public readonly message: string;

	public constructor(
		contactEmail: string,
		fullName: string,
		message: string
	) {
		this.contactEmail = contactEmail;
		this.fullName = fullName;
		this.message = message;
	}

}
