import { DiseaseComboboxDto, DiseaseFilterDto } from '@nmn-communication/diseases';
import { isValueDefined } from '@nmn-core/utils';
import { DiseaseComboboxModel, DiseaseFilterModel, mapDiseaseTypeFromString } from '@nmn-domain/diseases';
import { mapComboboxDtoToComboboxModel } from '../../shared/factories/combobox.factory';

export const mapFilterModelToDto = (model: DiseaseFilterModel): DiseaseFilterDto => (
	{
		...model
	}
);

export const mapFilterDtoToModel = (dto: DiseaseFilterDto): DiseaseFilterModel => (
	new DiseaseFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern,
		dto.isSeriousPathology,
		dto.hasVaccine,
		dto.isForTreatmentChecker
	)
);

export const mapDiseaseComboboxDtoToModel = (dto: DiseaseComboboxDto): DiseaseComboboxModel => (
	new DiseaseComboboxModel(
		dto.id,
		dto.displayText,
		dto.description,
		mapDiseaseTypeFromString(dto.type),
		dto.icd10,
		dto.icd10Group,
		isValueDefined(dto.group) ? mapComboboxDtoToComboboxModel(dto.group) : undefined
	)
);
