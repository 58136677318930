import { DescribedComboboxModel } from '../../../shared/comboboxes/described-combobox.model';
import { AllergyIntoleranceModel } from '../allergy-intolerance.model';

export class AllergyIntoleranceFormInModel {

	public readonly allergy?: DescribedComboboxModel;
	public readonly criticality?: DescribedComboboxModel;
	public readonly status?: DescribedComboboxModel;
	public readonly verificationStatus?: DescribedComboboxModel;
	public readonly lastOccurrence?: Date;

	public constructor(
		allergy: DescribedComboboxModel | undefined,
		criticality: DescribedComboboxModel | undefined,
		status: DescribedComboboxModel | undefined,
		verificationStatus: DescribedComboboxModel | undefined,
		lastOccurrence: Date | undefined
	) {
		this.allergy = allergy;
		this.criticality = criticality;
		this.status = status;
		this.verificationStatus = verificationStatus;
		this.lastOccurrence = lastOccurrence;
	}

	public static createEmpty = (): AllergyIntoleranceFormInModel => {
		return new AllergyIntoleranceFormInModel(
			undefined,
			undefined,
			undefined,
			undefined,
			undefined
		);
	}

	public static createViaAllergyIntolerance = (
		allergyIntolerance: AllergyIntoleranceModel
	): AllergyIntoleranceFormInModel => {
		return new AllergyIntoleranceFormInModel(
			allergyIntolerance?.allergy,
			allergyIntolerance?.criticality,
			allergyIntolerance?.status,
			allergyIntolerance?.verificationStatus,
			allergyIntolerance?.lastOccurrence
		);
	}

}
