import { Provider } from '@angular/core';
import { HealthIssueSeveritiesResolver, HealthIssueSeverityQueryHandlerService } from '@nmn-domain/health-issue-severities';
import { HealthIssueSeveritiesViaQueryHandlerResolver } from './resolvers/health-issue-severities.via-query-handler.resolver';
import { HealthIssueSeverityQueryHandlerViaClientService } from './services/health-issue-severity.query-handler-via-client.service';

export const healthIssueSeverityProviders: Array<Provider> = [
	{
		provide: HealthIssueSeverityQueryHandlerService,
		useClass: HealthIssueSeverityQueryHandlerViaClientService
	},
	{
		provide: HealthIssueSeveritiesResolver,
		useClass: HealthIssueSeveritiesViaQueryHandlerResolver
	}
];
