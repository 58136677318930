import { Injector } from '@angular/core';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { isValueDefined } from '@nmn-core/utils';
import { HealthIssueFakeClientConfiguration } from '../configurations/health-issue.fake-client.configuration';
import { HealthIssueFakeClient } from '../services/health-issue.fake-client';

export class HealthIssueFakeClientBuilder {

	private configuration: HealthIssueFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(
		configuration: HealthIssueFakeClientConfiguration
	): HealthIssueFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): HealthIssueFakeClient {
		return new HealthIssueFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
