import { Injectable } from '@angular/core';
import { GeneVariantClient } from '@nmn-communication/gene-variants';
import { Result } from '@nmn-core/shared';
import { GeneVariantComboboxModel, GeneVariantFilterModel, GeneVariantQueryHandlerService } from '@nmn-domain/gene-variants';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapDtoArrayResultToModelArrayResult } from '../../shared/factories/pagination.factory';
import { mapFilterModelToDto, mapGeneVariantComboboxDtoToModel } from '../factories/gene-variant.factory';

@Injectable()
export class GeneVariantQueryHandlerViaClientService extends GeneVariantQueryHandlerService {

	constructor(
		private readonly client: GeneVariantClient
	) {
		super();
	}

	public getAsComboboxes(filter: GeneVariantFilterModel): Observable<Result<Array<GeneVariantComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapGeneVariantComboboxDtoToModel))
			);
	}

}
