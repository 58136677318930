import { Injectable } from '@angular/core';
import { GeneClient } from '@nmn-communication/genes';
import { Result } from '@nmn-core/shared';
import { GeneComboboxModel, GeneFilterModel, GeneQueryHandlerService } from '@nmn-domain/genes';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapDtoArrayResultToModelArrayResult } from '../../shared/factories/pagination.factory';
import { mapFilterModelToDto, mapGeneComboboxDtoToModel } from '../factories/gene.factory';

@Injectable()
export class GeneQueryHandlerViaClientService extends GeneQueryHandlerService {

	constructor(
		private readonly client: GeneClient
	) {
		super();
	}

	public getAsComboboxes(filter: GeneFilterModel): Observable<Result<Array<GeneComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapGeneComboboxDtoToModel))
			);
	}

}
