import { Result } from './result';

export class EmptyResult<TFailure> {

	private readonly isSuccess: boolean;
	private readonly failure: TFailure | undefined;

	private constructor(isSuccess: boolean, failure: TFailure) {
		this.isSuccess = !!isSuccess;
		this.failure = this.isSuccess ? undefined : failure;
	}

	public static success<TFailure>(): EmptyResult<TFailure> {
		return new EmptyResult(true, undefined);
	}

	public static failure<TFailure>(failure: TFailure): EmptyResult<TFailure> {
		return new EmptyResult(false, failure);
	}

	public map<TFailureTo>(mapFailure: (failure: TFailure) => TFailureTo): EmptyResult<TFailureTo> {
		return this.isSuccess ? EmptyResult.success() : EmptyResult.failure(mapFailure(this.failure));
	}

	public toResult<TSuccess>(successFactory: () => TSuccess): Result<TSuccess, TFailure> {
		// TODO: check on null here

		return this.isSuccess ?
			Result.success<TSuccess, TFailure>(successFactory()) :
			Result.failure<TSuccess, TFailure>(this.failure);
	}

	public consume<TConsumeResult>(consumeSuccess: () => TConsumeResult, consumeFailure: (failure: TFailure) => TConsumeResult): TConsumeResult {
		// TODO: check on null here

		return this.isSuccess ? consumeSuccess() : consumeFailure(this.failure);
	}

	public consumeFailure(consumeFailure: (failure: TFailure) => void): void {
		this.consume(() => { }, consumeFailure);

		return;
	}

	public bindOnSuccess(handleSuccess: () => void, handleError: (error: any) => void = () => { }): EmptyResult<TFailure> {
		return this.bind(handleSuccess, () => { }, handleError);
	}

	public bindOnFailure(handleFailure: (failure: TFailure) => void, handleError: (error: any) => void = () => { }): EmptyResult<TFailure> {
		return this.bind(() => { }, handleFailure, handleError);
	}

	public bind(handleSuccess: () => void, handleFailure: (failure: TFailure) => void, handleError: (error: any) => void = () => { }): EmptyResult<TFailure> {
		try {
			this.isSuccess ? handleSuccess() : handleFailure(this.failure);
		} catch (error) {
			handleError(error);
		}

		return this;
	}

}
