import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TimezoneComboboxModel } from '../models/timezone-combobox.model';

export abstract class TimezonesResolver implements Resolve<Array<TimezoneComboboxModel>> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	): Observable<Array<TimezoneComboboxModel>> | Array<TimezoneComboboxModel>;

}
