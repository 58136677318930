import { Injector } from '@angular/core';
import { PopulationClient } from '@nmn-communication/populations';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { PopulationFakeClientBuilder } from '../builders/population.fake-client.builder';
import { PopulationFakeClientConfigurationBuilder } from '../builders/population.fake-client.configuration.builder';
import { PopulationHttpClientBuilder } from '../builders/population.http-client.builder';
import { PopulationHttpClientConfigurationBuilder } from '../builders/population.http-client.configuration.builder';
import { PopulationFakeClient } from '../services/population.fake-client';
import { PopulationHttpClient } from '../services/population.http-client';

export const populationClientFactory = (injector: Injector): PopulationClient => {
	const useFake = environment?.api?.resources?.population?.useFake ||
		!isValueDefined(environment?.api?.resources?.population?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		populationFakeClientFactory(injector) :
		populationHttpClientFactory(injector);
};

export const populationFakeClientFactory = (
	injector: Injector
): PopulationFakeClient => {
	const configuration = new PopulationFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.population.serverDelayMs)
		.build();

	return new PopulationFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const populationHttpClientFactory = (
	injector: Injector
): PopulationHttpClient => {
	const configuration = new PopulationHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.population?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.population.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.population?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new PopulationHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
