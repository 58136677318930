import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { AllergyIntoleranceVerificationStatusHttpClientConfiguration } from '../configurations/allergy-intolerance-verification-status.http-client.configuration';
import { AllergyIntoleranceVerificationStatusHttpClient } from '../services/allergy-intolerance-verification-status.http-client';

export class AllergyIntoleranceVerificationStatusHttpClientBuilder
	extends HttpClientBuilder<
	AllergyIntoleranceVerificationStatusHttpClient,
	AllergyIntoleranceVerificationStatusHttpClientConfiguration
	> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): AllergyIntoleranceVerificationStatusHttpClient {
		return new AllergyIntoleranceVerificationStatusHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
