import { isArrayDefined, isValueDefined } from '@nmn-core/utils';

export class ImageUploadConfiguration {

	public readonly allowedExtensions: Array<string>;
	public readonly maxSize: number;
	public readonly fileExtension: string;
	public readonly imageQuality: number;

	public constructor(
		allowedExtensions: Array<string>,
		maxSize: number,
		fileExtension: string,
		imageQuality: number
	) {
		isArrayDefined(allowedExtensions) ?
			this.allowedExtensions = allowedExtensions :
			this.allowedExtensions = imageUploadAllowedExtensionsDefault;
		isValueDefined(maxSize) ?
			this.maxSize = maxSize :
			this.maxSize = imageUploadMaxSizeDefault;
		isValueDefined(fileExtension) ?
			this.fileExtension = fileExtension :
			this.fileExtension = imageUploadFileExtensionDefault;
		isValueDefined(imageQuality) ?
			this.imageQuality = imageQuality :
			this.imageQuality = imageUploadIageQualityDefault;
	}

	public static createDefault(): ImageUploadConfiguration {
		return new ImageUploadConfiguration(
			imageUploadAllowedExtensionsDefault,
			imageUploadMaxSizeDefault,
			imageUploadFileExtensionDefault,
			imageUploadIageQualityDefault
		);
	}

}

export const imageUploadAllowedExtensionsDefault = ['.png', '.jpg', '.jpeg', '.gif'];
export const imageUploadMaxSizeDefault = 5 * 1024 * 1024;
export const imageUploadFileExtensionDefault = '.jpg';
export const imageUploadIageQualityDefault = 80;

