import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { VaccinationHttpClientConfiguration } from '../configurations/vaccination.http-client.configuration';

export class VaccinationHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<VaccinationHttpClientConfiguration> {

	public build(): VaccinationHttpClientConfiguration {
		return new VaccinationHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
