export class DocumentFormFailure {

	public readonly isValidationFailure: boolean;

	private constructor(
		isValidationFailure: boolean
	) {
		this.isValidationFailure = isValidationFailure;
	}

	public static createAsValidationFailure(): DocumentFormFailure {
		return new DocumentFormFailure(true);
	}

}
