import { isValueDefined } from '@nmn-core/utils';
import { NmnMatTableUserConfiguration } from '../component-configurations/nmn-mat-table-user.configuration';

export class EncountersPageConfiguration {

	public readonly encountersTableUserConfiguration: NmnMatTableUserConfiguration;

	protected constructor(
		encountersTableUserConfiguration: NmnMatTableUserConfiguration
	) {
		this.encountersTableUserConfiguration = encountersTableUserConfiguration;
	}

	public static createWithParameters(
		config?: EncountersPageConfiguration
	): EncountersPageConfiguration {
		if (!isValueDefined(config)) {
			return EncountersPageConfiguration.createDefault();
		}

		return new EncountersPageConfiguration(
			isValueDefined(config.encountersTableUserConfiguration) ?
				NmnMatTableUserConfiguration.createWithParameters(config.encountersTableUserConfiguration) :
				NmnMatTableUserConfiguration.createDefault()
		);
	}

	public static createDefault(
	): EncountersPageConfiguration {
		return new EncountersPageConfiguration(
			NmnMatTableUserConfiguration.createDefault()
		);
	}

}
