import { Injectable } from '@angular/core';
import { DoctorEncounterClient } from '@nmn-communication/doctor-encounters';
import { Result } from '@nmn-core/shared';
import { DoctorEncounterCommandHandlerService, DoctorEncounterCreateCommand, DoctorEncounterDeleteCommand, DoctorEncounterUpdateCommand } from '@nmn-domain/doctor-encounters';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommandResult, EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapCreateCommandToParameter, mapUpdateCommandToParameter } from '../factories/doctor-encounter-command.factory';

@Injectable()
export class DoctorEncounterCommandHandlerViaClientService extends DoctorEncounterCommandHandlerService {

	constructor(
		private readonly client: DoctorEncounterClient
	) {
		super();
	}

	public handleCreateCommand(
		command: DoctorEncounterCreateCommand
	): Observable<Result<CommandResult<DoctorEncounterCreateCommand, string>, FailureModel>> {
		return this.client
			.create(mapCreateCommandToParameter(command))
			.pipe(
				map(result => result.map(success => new CommandResult(command, success), mapFailureToFailureModel))
			);
	}

	public handleUpdateCommand(command: DoctorEncounterUpdateCommand): Observable<Result<EmptyCommandResult<DoctorEncounterUpdateCommand>, FailureModel>> {
		return this.client
			.update(
				{ id: command.id, patientId: command.patientId },
				mapUpdateCommandToParameter(command)
			)
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

	public handleDeleteCommand(command: DoctorEncounterDeleteCommand): Observable<Result<EmptyCommandResult<DoctorEncounterDeleteCommand>, FailureModel>> {
		return this.client
			.delete({ id: command.id, patientId: command.patientId })
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

}
