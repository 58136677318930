import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { DescribedComboboxModel } from '../../shared/comboboxes/described-combobox.model';
import { MedicationType } from './medication-type.enum';

export class MedicationComboboxModel extends DescribedComboboxModel {

	public readonly substances: Array<DescribedComboboxModel>;
	public readonly type: MedicationType;
	public readonly source?: string;
	// International Nonproprietary Name
	public readonly inn?: string;
	// Anatomical Therapeutic Chemical code
	public readonly atc?: string;

	public get isSubstancesDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.substances);
	}

	public get substancesToDisplay(): string | undefined {
		return this.substancesCount > 0 ? this.substances.map(item => item.displayText).join(', ') : undefined;
	}

	public get substancesCount(): number {
		return isArrayDefinedAndNotEmpty(this.substances) ? this.substances.length : 0;
	}

	public get isInnDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.inn);
	}

	public get isAtcDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.atc);
	}


	constructor(
		id: string,
		displayText: string,
		description: string,
		substances: Array<DescribedComboboxModel>,
		type: MedicationType,
		source: string | undefined,
		inn: string | undefined,
		atc: string | undefined
	) {

		super(id, displayText, description);

		// TODO: Guard check on defined
		this.substances = substances;
		this.type = type;

		this.source = source;
		this.inn = inn;
		this.atc = atc;

	}

	public static createDisplayOptionFn(): (option: MedicationComboboxModel) => string | undefined {
		return option => option.displayText;
	}

}
