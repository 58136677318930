import { notProvided } from '../../constants';

export class FacebookServicesConfiguration {

	public readonly facebookAuthClientId?: string;
	public readonly facebookAuthSdkNonce?: string;

	constructor(
		facebookAuthClientId: string | undefined,
		facebookAuthSdkNonce: string | undefined
	) {
		this.facebookAuthClientId = facebookAuthClientId;
		this.facebookAuthSdkNonce = facebookAuthSdkNonce;
	}

	public static createDefault(): FacebookServicesConfiguration {
		return new FacebookServicesConfiguration(notProvided, notProvided);
	}

}
