import { TreatmentCheckerAnamnesisFormOutModel } from '../treatment-checker-anamnesis-form/treatment-checker-anamnesis-form.out-model';
import { TreatmentCheckerTreatmentInfoFormOutModel } from '../treatment-checker-treatment-info-form/treatment-checker-treatment-info-form.out-model';
import { TreatmentCheckerYourDataFormOutModel } from '../treatment-checker-your-data-form/treatment-checker-your-data-form.out-model';

export class TreatmentCheckerFormOutModel {

	public readonly anamnesis: TreatmentCheckerAnamnesisFormOutModel;
	public readonly treatmentInfo: TreatmentCheckerTreatmentInfoFormOutModel;
	public readonly yourData: TreatmentCheckerYourDataFormOutModel;

	constructor(
		anamnesis: TreatmentCheckerAnamnesisFormOutModel,
		treatmentInfo: TreatmentCheckerTreatmentInfoFormOutModel,
		yourData: TreatmentCheckerYourDataFormOutModel
	) {
		this.anamnesis = anamnesis;
		this.treatmentInfo = treatmentInfo;
		this.yourData = yourData;
	}

}
