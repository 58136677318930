import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { GenderHttpClientConfiguration } from '../configurations/gender.http-client.configuration';

export class GenderHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<GenderHttpClientConfiguration> {

	public build(): GenderHttpClientConfiguration {
		return new GenderHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
