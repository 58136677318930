import { Injectable } from '@angular/core';
import { CurrentUserConfigurationClient, UserConfigurationDto } from '@nmn-communication/accounts';
import { Failure } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { CurrentUserConfigurationGetCommand } from '@nmn-domain/accounts/user-configuration/models/commands/current-user-configuration.get-command';
import { CurrentUserConfigurationQueryHandlerService } from '@nmn-domain/accounts/user-configuration/services/current-user-configuration.query-handler.service';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../../../../domain/implementations/shared/factories/failure-handling.factory';
import { UserConfigurationStorageService } from '../../../../../services';
import { mapGetCommandToParameter, mapUserConfigurationDtoToModel } from '../factories/user-configuration.factory';

@Injectable()
export class CurrentUserConfigurationQueryHandlerViaClientService extends CurrentUserConfigurationQueryHandlerService {

	constructor(
		private readonly client: CurrentUserConfigurationClient,
		private readonly userConfigurationStorage: UserConfigurationStorageService
	) {
		super();
	}

	public get(command: CurrentUserConfigurationGetCommand): Observable<EmptyResult<FailureModel>> {
		return this.client
			.get(mapGetCommandToParameter(command))
			.pipe(
				tap(this.updateUserConfigurationStorageInfoViaResult.bind(this)),
				map(_ => EmptyResult.success(), result => result.map(mapFailureToFailureModel))
			);
	}

	private updateUserConfigurationStorageInfoViaResult(result: Result<UserConfigurationDto, Failure>): void {
		result
			.mapOnSuccess(mapUserConfigurationDtoToModel)
			.bindOnSuccess(success => {
				this.userConfigurationStorage.defineUserConfiguration(success);
			});
	}

}
