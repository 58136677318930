import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { PagedCollectionModel, PageOptionsQuery } from '../../shared';
import { FailureModel } from '../../shared/failures/failure.model';
import { GeneticCardModel } from '../models/genetic-card.model';
import { GeneticTestFilterModel } from '../models/genetic-test-filter.model';
import { GeneticTestModel } from '../models/genetic-test.model';
import { GeneticCardGetQuery } from '../models/queries/genetic-card.get.query';
import { GeneticTestGetQuery } from '../models/queries/genetic-test.get.query';

export abstract class GeneticTestQueryHandlerService {

	public abstract handleGeneticCardGetQuery(query: GeneticCardGetQuery): Observable<Result<GeneticCardModel, FailureModel>>;

	public abstract handleGetQuery(query: GeneticTestGetQuery): Observable<Result<GeneticTestModel, FailureModel>>;

	public abstract getPagedCollection(query: PageOptionsQuery<GeneticTestFilterModel>): Observable<Result<PagedCollectionModel<GeneticTestModel, GeneticTestFilterModel>, FailureModel>>;

}
