import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { ComboboxModel } from '@nmn-domain/shared';

export class MedicationFilterModel {

	public readonly ids?: Array<string>;
	public readonly ignoreIds?: Array<string>;
	public readonly searchPattern?: string;
	public readonly isForTreatmentChecker?: boolean;

	constructor(
		ids: Array<string> | undefined,
		ignoreIds: Array<string> | undefined,
		searchPattern: string | undefined,
		isForTreatmentChecker: boolean
	) {
		this.ids = ids;
		this.ignoreIds = ignoreIds;
		this.searchPattern = searchPattern;
		this.isForTreatmentChecker = isForTreatmentChecker;
	}

	public static createForPredefinedList(medicationIds: Array<string>): MedicationFilterModel {
		return new MedicationFilterModel(
			medicationIds,
			undefined,
			undefined,
			false
		);
	}

	public static createForAutocomplete(searchPattern: string, selectedItem: ComboboxModel): MedicationFilterModel {
		return new MedicationFilterModel(
			undefined,
			isStringDefinedAndNotEmpty(selectedItem?.id) ? [selectedItem.id] : undefined,
			searchPattern,
			false
		);
	}

	public static createForMultiAutocomplete(searchPattern: string, ignoreIds: Array<string>): MedicationFilterModel {
		return new MedicationFilterModel(undefined, ignoreIds, searchPattern, false);
	}

	public static createForTreatmentCheckerMultiAutocomplete(searchPattern: string, ignoreIds: Array<string>): MedicationFilterModel {
		return new MedicationFilterModel(undefined, ignoreIds, searchPattern, true);
	}

}
