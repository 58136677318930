import { DiseaseComboboxModel } from '@nmn-domain/diseases';
import { MedicationComboboxModel } from '@nmn-domain/medications';

export class TreatmentCheckerYourDataFormInModel {

	public readonly email?: string;
	public readonly diseases: Array<DiseaseComboboxModel>;
	public readonly medications: Array<MedicationComboboxModel>;

	private constructor(
		email: string | undefined,
		diseases: Array<DiseaseComboboxModel>,
		medications: Array<MedicationComboboxModel>
	) {
		this.email = email;
		this.diseases = diseases;
		this.medications = medications;
	}

	public static createEmpty = (): TreatmentCheckerYourDataFormInModel => {
		return new TreatmentCheckerYourDataFormInModel(undefined, [], []);
	}

	public static createForPredefinedData = (diseases: Array<DiseaseComboboxModel>, medications: Array<MedicationComboboxModel>): TreatmentCheckerYourDataFormInModel => {
		// Guard.CheckOnDefined
		return new TreatmentCheckerYourDataFormInModel(undefined, diseases, medications);
	}

	public static createByPredefinedDataAndEmail = (email: string, predefinedData: TreatmentCheckerYourDataFormInModel): TreatmentCheckerYourDataFormInModel => {
		// Guard.CheckOnDefined
		return new TreatmentCheckerYourDataFormInModel(email, predefinedData.diseases, predefinedData.medications);
	}

}
