import { Provider } from '@angular/core';
import { BloodTypeQueryHandlerService, BloodTypesResolver } from '@nmn-domain/blood-types';
import { BloodTypesViaQueryHandlerResolver } from './resolvers/blood-types.via-query-handler.resolver';
import { BloodTypeQueryHandlerViaClientService } from './services/blood-type.query-handler-via-client.service';

export const bloodTypeProviders: Array<Provider> = [
	{
		provide: BloodTypeQueryHandlerService,
		useClass: BloodTypeQueryHandlerViaClientService
	},
	{
		provide: BloodTypesResolver,
		useClass: BloodTypesViaQueryHandlerResolver
	}
];
