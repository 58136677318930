import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { DescribedComboboxModel } from '../../shared';
import { FailureModel } from '../../shared/failures/failure.model';
import { MedicationDosageTypeFilterModel } from '../models/medication-dosage-type-filter.model';

export abstract class MedicationDosageTypeQueryHandlerService {

	public abstract getAsDescribedComboboxes(
		query: MedicationDosageTypeFilterModel
	): Observable<Result<Array<DescribedComboboxModel>, FailureModel>>;

}
