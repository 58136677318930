import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { ComboboxModel } from '../../shared';
import { DoctorEncounterStatus } from './doctor-encounter-status.enum';

export class DoctorEncounterFilterModel {

	public readonly patientId: string;
	public readonly ids?: Array<string>;
	public readonly ignoreIds?: Array<string>;
	public readonly searchPattern?: string;
	public readonly statuses?: Array<DoctorEncounterStatus>;

	constructor(
		patientId: string,
		ids: Array<string> | undefined,
		ignoreIds: Array<string> | undefined,
		searchPattern: string | undefined,
		statuses: Array<DoctorEncounterStatus> | undefined
	) {
		this.patientId = patientId;
		this.ids = ids;
		this.ignoreIds = ignoreIds;
		this.searchPattern = searchPattern;
		this.statuses = statuses;
	}

	public static createForPredefinedList(
		patientId: string,
		healthIssueIds: Array<string>
	): DoctorEncounterFilterModel {
		return new DoctorEncounterFilterModel(
			patientId,
			healthIssueIds,
			undefined,
			undefined,
			undefined
		);
	}

	public static createForAutocomplete(
		patientId: string,
		searchPattern: string,
		selectedItem: ComboboxModel
	): DoctorEncounterFilterModel {
		return new DoctorEncounterFilterModel(
			patientId,
			undefined,
			isStringDefinedAndNotEmpty(selectedItem?.id) ? [selectedItem.id] : undefined,
			searchPattern,
			undefined
		);
	}

	public static createForMultiAutocomplete(
		patientId: string,
		searchPattern: string,
		ignoreIds: Array<string>
	): DoctorEncounterFilterModel {
		return new DoctorEncounterFilterModel(
			patientId,
			undefined,
			ignoreIds,
			searchPattern,
			undefined
		);
	}

}
