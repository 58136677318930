import { Injectable } from '@angular/core';
import { HealthIssueSeverityClient } from '@nmn-communication/health-issue-severities';
import { Result } from '@nmn-core/shared';
import { HealthIssueSeverityComboboxModel, HealthIssueSeverityFilterModel, HealthIssueSeverityQueryHandlerService } from '@nmn-domain/health-issue-severities';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapDtoArrayResultToModelArrayResult } from '../../shared/factories/pagination.factory';
import { mapFilterModelToDto, mapHealthIssueSeverityComboboxDtoToModel } from '../factories/health-issue-severity.factory';

@Injectable()
export class HealthIssueSeverityQueryHandlerViaClientService extends HealthIssueSeverityQueryHandlerService {

	constructor(
		private readonly client: HealthIssueSeverityClient
	) {
		super();
	}

	public getAsDescribedComboboxes(filter: HealthIssueSeverityFilterModel): Observable<Result<Array<HealthIssueSeverityComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapHealthIssueSeverityComboboxDtoToModel))
			);
	}

}
