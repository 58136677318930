import { Injector } from '@angular/core';
import { CurrentUserConfigurationClient } from '@nmn-communication/accounts';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { UserConfigurationFakeClientBuilder } from '../builders/user-configuration.fake-client.builder';
import { UserConfigurationFakeClientConfigurationBuilder } from '../builders/user-configuration.fake-client.configuration.builder';
import { UserConfigurationHttpClientBuilder } from '../builders/user-configuration.http-client.builder';
import { UserConfigurationHttpClientConfigurationBuilder } from '../builders/user-configuration.http-client.configuration.builder';
import { CurrentUserConfigurationFakeClient } from '../services/current-user-configuration.fake-client';
import { CurrentUserConfigurationHttpClient } from '../services/current-user-configuration.http-client';

export const userConfigurationClientFactory = (
	injector: Injector
): CurrentUserConfigurationClient => {
	const useFake = environment?.api?.resources?.accounts?.userConfiguration?.useFake ||
		!isValueDefined(environment?.api?.resources?.accounts?.userConfiguration?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		userConfigurationFakeClientFactory(injector) :
		userConfigurationHttpClientFactory(injector);
};

export const userConfigurationFakeClientFactory = (
	injector: Injector
): CurrentUserConfigurationFakeClient => {
	const configuration = new UserConfigurationFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.accounts.userConfiguration.serverDelayMs)
		.build();

	return new UserConfigurationFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const userConfigurationHttpClientFactory = (
	injector: Injector
): CurrentUserConfigurationHttpClient => {
	const configuration = new UserConfigurationHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.accounts?.userConfiguration?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.accounts.userConfiguration.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.accounts?.userConfiguration?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new UserConfigurationHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
