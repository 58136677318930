import { HttpClientConfigurationBuilder } from '../../../../../communication/implementation/shared/builders/http-client.configuration.builder';
import { EmailConfirmationHttpClientConfiguration } from '../configurations/email-confirmation.http-client.configuration';

export class EmailConfirmationHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<EmailConfirmationHttpClientConfiguration> {

	public build(): EmailConfirmationHttpClientConfiguration {
		return new EmailConfirmationHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
