import { PatientModel } from '@nmn-domain/patients/models/patient.model';
import { PopulationComboboxModel } from '@nmn-domain/populations';
import { DescribedComboboxModel } from '@nmn-domain/shared';

export class TreatmentCheckerTreatmentInfoFormInModel {

	public readonly population?: PopulationComboboxModel;
	public readonly gender?: DescribedComboboxModel;
	public readonly age?: number;
	public readonly weight?: number;
	public readonly height?: number;
	public readonly isPregnant?: boolean;
	public readonly isActiveLifestyle?: boolean;
	public readonly isAlcohol?: boolean;
	public readonly isOnHealthyDiet?: boolean;
	public readonly isSmoking?: boolean;
	public readonly isUnderHighLevelOfStress?: boolean;
	public readonly isUsingNutritionalSupplements?: boolean;

	private constructor(
		population: PopulationComboboxModel | undefined,
		gender: DescribedComboboxModel | undefined,
		age: number | undefined,
		weight: number | undefined,
		height: number | undefined,
		isPregnant: boolean | undefined,
		isActiveLifestyle: boolean | undefined,
		isAlcohol: boolean | undefined,
		isOnHealthyDiet: boolean | undefined,
		isSmoking: boolean | undefined,
		isUnderHighLevelOfStress: boolean | undefined,
		isUsingNutritionalSupplements: boolean | undefined
	) {
		this.population = population;
		this.gender = gender;
		this.age = age;
		this.weight = weight;
		this.height = height;
		this.isPregnant = isPregnant;
		this.isActiveLifestyle = isActiveLifestyle;
		this.isAlcohol = isAlcohol;
		this.isOnHealthyDiet = isOnHealthyDiet;
		this.isSmoking = isSmoking;
		this.isUnderHighLevelOfStress = isUnderHighLevelOfStress;
		this.isUsingNutritionalSupplements = isUsingNutritionalSupplements;
	}

	public static createEmpty = (): TreatmentCheckerTreatmentInfoFormInModel => {
		return new TreatmentCheckerTreatmentInfoFormInModel(
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined
		);
	}

	public static createByPatient = (patient: PatientModel): TreatmentCheckerTreatmentInfoFormInModel => {
		return new TreatmentCheckerTreatmentInfoFormInModel(
			patient.population,
			patient.gender,
			patient.age,
			patient.weight,
			patient.height,
			patient.lifestyle.isPregnant,
			patient.lifestyle.isActiveLifestyle,
			patient.lifestyle.isAlcohol,
			patient.lifestyle.isOnHealthyDiet,
			patient.lifestyle.isSmoking,
			patient.lifestyle.isUnderHighLevelOfStress,
			patient.lifestyle.isUsingNutritionalSupplements
		);
	}

	public static createForPredefinedData = (
		population: PopulationComboboxModel | undefined,
		gender: DescribedComboboxModel | undefined,
		age: number | undefined,
		weight: number | undefined,
		height: number | undefined,
		isPregnant: boolean | undefined,
		isActiveLifestyle: boolean | undefined,
		isAlcohol: boolean | undefined,
		isOnHealthyDiet: boolean | undefined,
		isSmoking: boolean | undefined,
		isUnderHighLevelOfStress: boolean | undefined,
		isUsingNutritionalSupplements: boolean | undefined
	): TreatmentCheckerTreatmentInfoFormInModel => {
		return new TreatmentCheckerTreatmentInfoFormInModel(
			population,
			gender,
			age,
			weight,
			height,
			isPregnant,
			isActiveLifestyle,
			isAlcohol,
			isOnHealthyDiet,
			isSmoking,
			isUnderHighLevelOfStress,
			isUsingNutritionalSupplements
		);
	}

}
