export class InitiateEmailConfirmationCommand {

	public readonly email: string;

	constructor(
		email: string
	) {
		//TODO: Guard.IsDefined(email);

		this.email = email;
	}

}
