import { DescribedComboboxModel } from '@nmn-domain/shared';
import { PharmacogenomicTestResultCategory } from './pharmacogenomic-test-result-category.enum';

export class PharmacogenomicTestResultComboboxModel extends DescribedComboboxModel {

	public readonly category: PharmacogenomicTestResultCategory;

	constructor(
		id: string,
		displayText: string,
		description: string,
		category: PharmacogenomicTestResultCategory
	) {
		super(id, displayText, description);

		// TODO: Guard check on defined
		this.category = category;
	}

}
