export class PatientTreatmentCheckerFormOutModel {

	public readonly patientId: string;
	public readonly healthIssueIds: Array<string>;
	public readonly doctorEncounterIds: Array<string>;
	public readonly takenMedicationIds: Array<string>;
	public readonly diseaseIds: Array<string>;
	public readonly medicationIds: Array<string>;
	public readonly isIncludeSeriousPathologies: boolean;

	constructor(
		patientId: string,
		healthIssueIds: Array<string>,
		doctorEncounterIds: Array<string>,
		takenMedicationIds: Array<string>,
		diseaseIds: Array<string>,
		medicationIds: Array<string>,
		isIncludeSeriousPathologies: boolean
	) {
		// TODO: check on defined
		this.patientId = patientId;
		this.healthIssueIds = healthIssueIds;
		this.doctorEncounterIds = doctorEncounterIds;
		this.takenMedicationIds = takenMedicationIds;
		this.diseaseIds = diseaseIds;
		this.medicationIds = medicationIds;
		this.isIncludeSeriousPathologies = isIncludeSeriousPathologies;
	}

}
