import {
	buildAddToCart, buildCorporateSubscriptionSinglePurchaseItem, buildPremiumAnnualSubscriptionSinglePurchaseItem,
	buildPremiumMonthlySubscriptionSinglePurchaseItem, buildPremiumSemiAnnualSubscriptionSinglePurchaseItem, buildPurchase, buildRemoveFromCart
} from '../../factories/ga-ecommerce.subscription.factory';
import { GaItemDto } from '../../models/ecommerce/ga-item.dto';
import { GoogleAnalyticsEvent } from '../../models/google-analytics-event.enum';

export class GaEventTrackingPurchaseService {

	constructor(
		private readonly trackEvent: (event: GoogleAnalyticsEvent, data?: any, completeFn?: () => void) => void
	) { }

	public addedToCartSubscriptionPremiumMonthly(): void {
		this.trackAddToCartSubscription(buildPremiumMonthlySubscriptionSinglePurchaseItem());
	}

	public addedToCartSubscriptionPremiumSemiAnnual(): void {
		this.trackAddToCartSubscription(buildPremiumSemiAnnualSubscriptionSinglePurchaseItem());
	}

	public addedToCartSubscriptionPremiumAnnual(): void {
		this.trackAddToCartSubscription(buildPremiumAnnualSubscriptionSinglePurchaseItem());
	}

	public addedToCartSubscriptionCorporate(): void {
		this.trackAddToCartSubscription(buildCorporateSubscriptionSinglePurchaseItem());
	}

	public removedFromCartSubscriptionPremiumMonthly(): void {
		this.trackRemoveFromCartSubscription(buildPremiumMonthlySubscriptionSinglePurchaseItem());
	}

	public removedFromCartSubscriptionPremiumSemiAnnual(): void {
		this.trackRemoveFromCartSubscription(buildPremiumSemiAnnualSubscriptionSinglePurchaseItem());
	}

	public removedFromCartSubscriptionPremiumAnnual(): void {
		this.trackRemoveFromCartSubscription(buildPremiumAnnualSubscriptionSinglePurchaseItem());
	}

	public removedFromCartSubscriptionCorporate(): void {
		this.trackRemoveFromCartSubscription(buildCorporateSubscriptionSinglePurchaseItem());
	}

	public purchasedSubscriptionPremiumMonthly(transactionId: string): void {
		this.trackPurchaseSubscription(transactionId, buildPremiumMonthlySubscriptionSinglePurchaseItem());
	}

	public purchasedSubscriptionPremiumSemiAnnual(transactionId: string): void {
		this.trackPurchaseSubscription(transactionId, buildPremiumSemiAnnualSubscriptionSinglePurchaseItem());
	}

	public purchasedSubscriptionPremiumAnnual(transactionId: string): void {
		this.trackPurchaseSubscription(transactionId, buildPremiumAnnualSubscriptionSinglePurchaseItem());
	}

	private trackAddToCartSubscription(suscriptionItem: GaItemDto): void {
		this.trackEvent(GoogleAnalyticsEvent.AddToCart, buildAddToCart([suscriptionItem]));
	}

	private trackRemoveFromCartSubscription(suscriptionItem: GaItemDto): void {
		this.trackEvent(GoogleAnalyticsEvent.RemoveFromCart, buildRemoveFromCart([suscriptionItem]));
	}

	private trackPurchaseSubscription(transactionId: string, suscriptionItem: GaItemDto): void {
		this.trackEvent(GoogleAnalyticsEvent.Purchase, buildPurchase(transactionId, [suscriptionItem]));
	}

}
