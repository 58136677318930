import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PatientTreatmentCheckerFormConfiguration } from '../models/patient-treatment-checker-form/patient-treatment-checker-form.configuration';

export abstract class PatientTreatmentCheckerFormConfigurationResolver implements Resolve<PatientTreatmentCheckerFormConfiguration> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	): Observable<PatientTreatmentCheckerFormConfiguration> | PatientTreatmentCheckerFormConfiguration;

}
