import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { GeneticFileParseRequestHttpClientConfiguration } from '../configurations/genetic-file-parse-request.http-client.configuration';

export class GeneticFileParseRequestHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<GeneticFileParseRequestHttpClientConfiguration> {

	public build(): GeneticFileParseRequestHttpClientConfiguration {
		return new GeneticFileParseRequestHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
