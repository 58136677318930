import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DocumentTypeDescribedComboboxModel, DocumentTypeFilterModel, DocumentTypeQueryHandlerService, DocumentTypesResolver } from '@nmn-domain/document-types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DocumentTypesViaQueryHandlerResolver extends DocumentTypesResolver {

	constructor(
		private readonly documentTypeQueryHandlerService: DocumentTypeQueryHandlerService
	) {
		super();
	}

	public resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<Array<DocumentTypeDescribedComboboxModel>> | Array<DocumentTypeDescribedComboboxModel> {
		return this.documentTypeQueryHandlerService
			.getAsDescribedComboboxes(DocumentTypeFilterModel.createEmpty())
			.pipe(map(result => result.successOrDefault(() => [])));
	}

}
