import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { MedicationBodySiteDescribedComboboxDto } from '../models/medication-body-site-described-combobox.dto';
import { MedicationBodySiteFilterDto } from '../models/medication-body-site-filter.dto';

export abstract class MedicationBodySiteClient {

	public abstract getBulkAsComboboxes(
		filter: MedicationBodySiteFilterDto
	): Observable<Result<Array<MedicationBodySiteDescribedComboboxDto>, Failure>>;

}
