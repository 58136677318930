export class TreatmentCheckerTreatmentInfoFormOutModel {

	public readonly populationId?: string;
	public readonly genderId?: string;
	public readonly age?: number;
	public readonly weight?: number;
	public readonly height?: number;
	public readonly isPregnant?: boolean;
	public readonly isActiveLifestyle?: boolean;
	public readonly isAlcohol?: boolean;
	public readonly isOnHealthyDiet?: boolean;
	public readonly isSmoking?: boolean;
	public readonly isUnderHighLevelOfStress?: boolean;
	public readonly isUsingNutritionalSupplements?: boolean;

	constructor(
		populationId: string | undefined,
		genderId: string | undefined,
		age: number | undefined,
		weight: number | undefined,
		height: number | undefined,
		isPregnant: boolean | undefined,
		isActiveLifestyle: boolean | undefined,
		isAlcohol: boolean | undefined,
		isOnHealthyDiet: boolean | undefined,
		isSmoking: boolean | undefined,
		isUnderHighLevelOfStress: boolean | undefined,
		isUsingNutritionalSupplements: boolean | undefined
	) {
		this.populationId = populationId;
		this.genderId = genderId;
		this.age = age;
		this.weight = weight;
		this.height = height;
		this.isPregnant = isPregnant;
		this.isActiveLifestyle = isActiveLifestyle;
		this.isAlcohol = isAlcohol;
		this.isOnHealthyDiet = isOnHealthyDiet;
		this.isSmoking = isSmoking;
		this.isUnderHighLevelOfStress = isUnderHighLevelOfStress;
		this.isUsingNutritionalSupplements = isUsingNutritionalSupplements;
	}

}
