import { isValueDefined } from '@nmn-core/utils';

const hash = 19;
const hashMultiplier = 31;

export const getFieldHashCodeCollection = (
	array: Array<string>
): number => {
	if (!isValueDefined(array)) {
		return 0;
	}

	let calculatedHash = hash;
	array.forEach(item => {
		if (isValueDefined(item)) {
			calculatedHash = (calculatedHash * hashMultiplier) + getFieldHashCode(item);
		}
	});

	return calculatedHash;
};

const hashShift = 19;

export const getFieldHashCode = (str: string): number => {
	if (!isValueDefined(str)) {
		return 0;
	}

	let calculatedHash = 0;
	for (let i = 0; i < str.length; i++) {
		const code = str.charCodeAt(i);
		// eslint-disable-next-line no-bitwise
		calculatedHash = ((calculatedHash << hashShift) - calculatedHash) + code;
		// eslint-disable-next-line no-bitwise
		calculatedHash = calculatedHash & calculatedHash;
	}

	return calculatedHash;
};

export const accumulateHashCode = (hashcodes: Array<number>): number => {
	// eslint-disable-next-line no-bitwise
	return hashcodes.reduce((sum, current) => sum ^ current);
};
