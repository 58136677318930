export class AttachNativeLoginDialogOutModel {

	public readonly declined?: boolean;

	protected constructor(
		declined?: boolean | undefined
	) {

		// TODO: Guard check defined

		this.declined = declined;
	}

	public static createSucceded(): AttachNativeLoginDialogOutModel {
		return new AttachNativeLoginDialogOutModel();
	}

	public static createDeclined(): AttachNativeLoginDialogOutModel {
		return new AttachNativeLoginDialogOutModel(
			true);
	}
}
