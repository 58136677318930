import { Injector, Provider } from '@angular/core';
import { GeneticTestClient } from '@nmn-communication/genetic-tests';
import { geneticTestClientFactory } from './factories/genetic-test.client.factory';

export const geneticTestProviders: Array<Provider> = [
	{
		provide: GeneticTestClient,
		useFactory: geneticTestClientFactory,
		deps: [Injector]
	}
];
