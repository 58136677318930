import { TranslocoService } from '@ngneat/transloco';
import { LoginDto, UserSubscriptionDto, UserSubscriptionUpdateParameter } from '@nmn-communication/accounts';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../databases/fake.database';

export class UserSubscriptionFakeTable {

	private readonly database: FakeDatabase;
	private readonly data: Array<UserSubscriptionFakeRecord>;

	constructor(
		database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.database = database;
		this.data = [...initialData];
	}

	public getUserSubscription(userId: string): UserSubscriptionDto {
		const record = this.data.filter((item: UserSubscriptionFakeRecord) => findPredicate(item, userId));

		if (!isValueDefined(record) || record.length !== 1) {
			throw new Error('User configuration was not found.');
		}

		return this.mapUserRecordToUserSubscriptionDto(record[0]);
	}

	public get(userId: string): LoginDto {
		const record = this.data.filter((item: UserSubscriptionFakeRecord) => findPredicate(item, userId));

		if (!isValueDefined(record) || record.length !== 1) {
			throw new Error('User configuration was not found.');
		}

		return this.database.usersTable.get({ id: userId });
	}

	public update(userId: string, parameter: UserSubscriptionUpdateParameter): LoginDto {
		const record = this.data.filter((item: UserSubscriptionFakeRecord) => findPredicate(item, userId));

		if (!isValueDefined(record) || record.length !== 1) {
			throw new Error('User configuration was not found.');
		}

		applyUpdateParameter(record[0], parameter);

		return this.database.usersTable.get({ id: userId });
	}

	public mapUserRecordToUserSubscriptionDto(record: UserSubscriptionFakeRecord): UserSubscriptionDto {
		const subscribtion = this.database.subscriptionFakeTable.getAsFakeRecord(record.subscriptionId);
		return {
			userId: record.userId,
			subscription: this.database.subscriptionFakeTable.getAsCombobox(record.subscriptionId),
			dateFrom: record.dateFrom,
			dateTo: record.dateTo,
			treatmentCheckersUsed: record.treatmentCheckersUsed,
			treatmentCheckersAvailableTotal: subscribtion.treatmentCheckersAvailableTotal,
			patientsUsed: record.patientsUsed,
			patientsAvailableTotal: subscribtion.patientsAvailableTotal,
			storageBytesUsed: record.storageBytesUsed,
			storageBytesAvailableTotal: subscribtion.storageBytesAvailableTotal,
		};
	};

	public incrementTreatmentCheckerUsed(userId: string): void {
		const record = this.data.filter((item: UserSubscriptionFakeRecord) => findPredicate(item, userId));

		if (!isValueDefined(record) || record.length !== 1) {
			throw new Error('User subscription was not found.');
		}

		record[0].treatmentCheckersUsed = (record[0].treatmentCheckersUsed || 0) + 1;
	}

	public incrementPatientUsed(userId: string): void {
		const record = this.data.filter((item: UserSubscriptionFakeRecord) => findPredicate(item, userId));

		if (!isValueDefined(record) || record.length !== 1) {
			throw new Error('User subscription was not found.');
		}

		record[0].patientsUsed = (record[0].patientsUsed || 0) + 1;
	}

	public incrementStorageUsed(userId: string): void {
		const record = this.data.filter((item: UserSubscriptionFakeRecord) => findPredicate(item, userId));

		if (!isValueDefined(record) || record.length !== 1) {
			throw new Error('User subscription was not found.');
		}

		record[0].storageBytesUsed = (record[0].storageBytesUsed || 0) + 10000;
	}

}

const findPredicate = (item: UserSubscriptionFakeRecord, userId: string): boolean =>
	item.userId === userId;

const applyUpdateParameter = (
	record: UserSubscriptionFakeRecord,
	updateParameter: UserSubscriptionUpdateParameter
): void => {
	record.subscriptionId = updateParameter.subscriptionId;
	record.pricingId = updateParameter.pricingPlanId;
};

export interface UserSubscriptionFakeRecord {

	userId: string;
	subscriptionId: string;
	pricingId: string;
	dateFrom: string;
	dateTo: string;
	treatmentCheckersUsed: number;
	patientsUsed: number;
	storageBytesUsed: number;

}

const initialData: Array<UserSubscriptionFakeRecord> = [
	{
		userId: '00000000-0000-0000-0001-000000000001',
		subscriptionId: 'Basic',
		pricingId: 'Unlimited',
		dateFrom: '2020-12-01',
		dateTo: undefined,
		treatmentCheckersUsed: 1,
		patientsUsed: 3,
		storageBytesUsed: 10
	},
	{
		userId: '00000000-0000-0000-0001-000000000002',
		subscriptionId: 'Basic',
		pricingId: 'Unlimited',
		dateFrom: '2023-01-01',
		dateTo: undefined,
		treatmentCheckersUsed: 23,
		patientsUsed: 2,
		storageBytesUsed: 10
	},
];
