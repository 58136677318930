import { Injector } from '@angular/core';
import { DocumentClient } from '@nmn-communication/documents';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { DocumentFakeClientBuilder } from '../builders/document.fake-client.builder';
import { DocumentFakeClientConfigurationBuilder } from '../builders/document.fake-client.configuration.builder';
import { DocumentHttpClientBuilder } from '../builders/document.http-client.builder';
import { DocumentHttpClientConfigurationBuilder } from '../builders/document.http-client.configuration.builder';
import { DocumentFakeClient } from '../services/document.fake-client';
import { DocumentHttpClient } from '../services/document.http-client';

export const documentClientFactory = (injector: Injector): DocumentClient => {
	const useFake = environment?.api?.resources?.documents?.useFake ||
		!isValueDefined(environment?.api?.resources?.documents?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		documentFakeClientFactory(injector) :
		documentHttpClientFactory(injector);
};

export const documentFakeClientFactory = (
	injector: Injector
): DocumentFakeClient => {
	const configuration = new DocumentFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.documents.serverDelayMs)
		.build();

	return new DocumentFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const documentHttpClientFactory = (
	injector: Injector
): DocumentHttpClient => {
	const configuration = new DocumentHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.documents?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.documents.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.documents?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new DocumentHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
