import { ValueRestrictionOptional } from '../../../../../modules/core/configurations/restrictions/value-restriction-optional';

export interface HealthIssueFormConfiguration {
	restrictions: {
		name: {
			isRequired: boolean;
		};
		comment: {
			controlRowsCount: number;
		};
		period: {
			dateStart: ValueRestrictionOptional<Date>;
			dateEnd: ValueRestrictionOptional<Date>;
		};
		doctorEncounters: {
			isCreatingEnabled: boolean;
		};
		takenMedications: {
			isCreatingEnabled: boolean;
		};
		documents: {
			isCreatingEnabled: boolean;
		};
	};
}

export const defaultHealthIssueCreateFormConfiguration: HealthIssueFormConfiguration = {
	restrictions: {
		name: {
			isRequired: false
		},
		comment: {
			controlRowsCount: 5
		},
		period: {
			dateStart: ValueRestrictionOptional
				.initDate(
					new Date('1900-01-01T00:00:00Z'),
					new Date('2100-01-01T00:00:00Z')
				),
			dateEnd: ValueRestrictionOptional
				.initDate(
					new Date('1900-01-01T00:00:00Z'),
					new Date('2100-01-01T00:00:00Z')
				)
		},
		doctorEncounters: {
			isCreatingEnabled: true
		},
		takenMedications: {
			isCreatingEnabled: true
		},
		documents: {
			isCreatingEnabled: true
		}
	}
};

export const defaultHealthIssueEditFormConfiguration: HealthIssueFormConfiguration = {
	restrictions: {
		...defaultHealthIssueCreateFormConfiguration.restrictions,
		name: {
			isRequired: true
		}
	}
};
