import { Injectable } from '@angular/core';
import { FailureModel } from '@nmn-domain/shared';
import { Result } from '../../shared';
import { DefaultCookieExpiresAfterDays } from '../constants';
import { RemoveCookieBehaviorSettingsModel, SetCookieBehaviorSettingsModel } from '../models/cookie-behavior-settings.model';
import { CookieStorageRepositoryService } from '../repositories/cookie-storage.repository.service';

@Injectable()
export class CookieStorageService {

	private readonly storageKeys = {
		tokenType: `${window.location.host}_token-type`,
		accessToken: `${window.location.host}_access-token`,
		refreshToken: `${window.location.host}_refresh-token`
	};

	constructor(
		private readonly cookieStorageRepository: CookieStorageRepositoryService
	) { }

	public setUserTokens(userId: string, tokenType: string, accessToken: string, refreshToken: string): void {
		const behaviorSettings = SetCookieBehaviorSettingsModel
			.createWithBuilder()
			.withLifetimeExpiresAfterDays(DefaultCookieExpiresAfterDays)
			.build();

		this.cookieStorageRepository.set(this.buildUserTokenTypeKey(userId), tokenType, behaviorSettings);
		this.cookieStorageRepository.set(this.buildUserAccessTokenKey(userId), accessToken, behaviorSettings);
		this.cookieStorageRepository.set(this.buildUserRefreshTokenKey(userId), refreshToken, behaviorSettings);
	}

	public checkUserAccessToken(userId: string): boolean {
		return this.cookieStorageRepository
			.check(this.buildUserAccessTokenKey(userId))
			.mapOnSuccess(success => success)
			.successOrDefault(() => false);
	}

	public checkUserRefreshToken(userId: string): boolean {
		return this.cookieStorageRepository
			.check(this.buildUserRefreshTokenKey(userId))
			.mapOnSuccess(success => success)
			.successOrDefault(() => false);
	}

	public getUserTokenType(userId: string): Result<string, FailureModel> {
		return this.cookieStorageRepository.get(this.buildUserTokenTypeKey(userId));
	}

	public getUserAccessToken(userId: string): Result<string, FailureModel> {
		return this.cookieStorageRepository.get(this.buildUserAccessTokenKey(userId));
	}

	public getUserRefreshToken(userId: string): Result<string, FailureModel> {
		return this.cookieStorageRepository.get(this.buildUserRefreshTokenKey(userId));
	}

	public removeUserTokens(userId: string): void {
		const behaviorSettings = RemoveCookieBehaviorSettingsModel
			.createWithBuilder()
			.build();

		this.cookieStorageRepository.remove(this.buildUserTokenTypeKey(userId), behaviorSettings);
		this.cookieStorageRepository.remove(this.buildUserAccessTokenKey(userId), behaviorSettings);
		this.cookieStorageRepository.remove(this.buildUserRefreshTokenKey(userId), behaviorSettings);
	}

	private buildUserTokenTypeKey(userId: string): string {
		return `${userId}${this.storageKeys.tokenType}`;
	}

	private buildUserAccessTokenKey(userId: string): string {
		return `${userId}${this.storageKeys.accessToken}`;
	}

	private buildUserRefreshTokenKey(userId: string): string {
		return `${userId}${this.storageKeys.refreshToken}`;
	}

}
