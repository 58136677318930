import { Injectable } from '@angular/core';
import { DiseaseClient } from '@nmn-communication/diseases';
import { Result } from '@nmn-core/shared';
import { DiseaseComboboxModel, DiseaseFilterModel, DiseaseQueryHandlerService } from '@nmn-domain/diseases';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapDtoArrayResultToModelArrayResult } from '../../shared/factories/pagination.factory';
import { mapDiseaseComboboxDtoToModel, mapFilterModelToDto } from '../factories/disease.factory';

@Injectable()
export class DiseaseQueryHandlerViaClientService extends DiseaseQueryHandlerService {

	constructor(
		private readonly client: DiseaseClient
	) {
		super();
	}

	public getAsDescribedComboboxes(filter: DiseaseFilterModel): Observable<Result<Array<DiseaseComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapDiseaseComboboxDtoToModel))
			);
	}

}
