export class AllergyIntoleranceFormOutModel {

	public readonly allergyId: string;
	public readonly criticalityId: string;
	public readonly statusId: string;
	public readonly verificationStatusId: string;
	public readonly lastOccurrence?: Date;

	public constructor(
		allergyId: string,
		criticalityId: string,
		statusId: string,
		verificationStatusId: string,
		lastOccurrence: Date | undefined
	) {
		// TODO: Guard check on defined
		this.allergyId = allergyId;
		this.criticalityId = criticalityId;
		this.statusId = statusId;
		this.verificationStatusId = verificationStatusId;
		this.lastOccurrence = lastOccurrence;
	}

}
