import { Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { MedicationFakeClientConfiguration } from '../configurations/medication.fake-client.configuration';
import { MedicationFakeClient } from '../services/medication.fake-client';

export class MedicationFakeClientBuilder {

	private configuration: MedicationFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(
		configuration: MedicationFakeClientConfiguration
	): MedicationFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): MedicationFakeClient {
		return new MedicationFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
