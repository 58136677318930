import { Injector, Provider } from '@angular/core';
import { AllergyClient } from '@nmn-communication/allergies';
import { allergyClientFactory } from './factories/allergy.client.factory';

export const allergyProviders: Array<Provider> = [
	{
		provide: AllergyClient,
		useFactory: allergyClientFactory,
		deps: [Injector]
	}
];
