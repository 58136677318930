import { Injectable, Injector } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApplicationLanguageService } from '@nmn-background/application-language';
import { ApplicationTitleService } from '@nmn-background/application-title';
import { isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { ServerConfigurationGetQuery, ServerConfigurationQueryHandlerService } from '@nmn-domain/server-configuration';
import { FailureModel } from '@nmn-domain/shared';
import { FailureLocalizationModel } from '@nmn-domain/shared/failures/failure-localization-parameters.model';
import { FacebookAuthService } from '@nmn-vendor/facebook';
import { GoogleAnalyticsConfigurationService, GoogleAuthService } from '@nmn-vendor/google';
import { addIcons as addMaterialIcons } from '@nmn-vendor/mat-icons';
import { environment } from 'environments/environment';
import { merge, Observable, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { NotificationService } from './modules/core/notifications/services/notification.service';
import { FailureHandlingService, MonitoringService, ServerConfigurationStorageService, StorageService } from './services';

declare const apiBaseUrl: string;
declare const appInsightsInsKey: string;

@Injectable({ providedIn: 'root' })
export class AppService {

	private subscription: Subscription = undefined;

	public get apiBaseUrl(): string {
		const value = (window as any).apiBaseUrl ?? apiBaseUrl;
		if (!isStringDefinedAndNotEmpty(value) || value === '{ApiBaseUrl}') {
			console.log('ApiBaseUrl is not defined');

			return '';
		}

		return value;
	}

	public get appInsightsInsKey(): string {
		const value = (window as any).appInsightsInsKey ?? appInsightsInsKey;
		if (!isStringDefinedAndNotEmpty(value) || value === '{AppInsightsInsKey}') {
			// TODO: write this only in debug mode !environment.isProd
			console.log('AppInsightsInsKey is not defined');

			return '';
		}

		return value;
	}

	// Do not inject here any service that uses server config
	constructor(
		private readonly injector: Injector,
		private readonly googleAuthService: GoogleAuthService,
		private readonly facebookAuthService: FacebookAuthService,
		private readonly failureHandlingService: FailureHandlingService,
		private readonly serverConfigurationStorageService: ServerConfigurationStorageService,
		private readonly notificationService: NotificationService,
		private readonly monitoringService: MonitoringService,
		private readonly matIconRegistry: MatIconRegistry,
		private readonly domSanitizer: DomSanitizer,
		private readonly storage: StorageService,
		private readonly router: Router,
		private readonly applicationLanguageService: ApplicationLanguageService,
		private readonly applicationTitleService: ApplicationTitleService,
		private readonly googleAnalyticsConfigurationService: GoogleAnalyticsConfigurationService
	) {
		addMaterialIcons(this.matIconRegistry, this.domSanitizer);
	}

	public initApp(): Observable<undefined> {
		if (isValueDefined(environment.version)) {
			console.log(`%c FRONT-END VERSION: ${environment.version} `, 'background: black; color: white');
		}
		if (isValueDefined(environment.production) && environment.production) {
			console.log(`%c FRONT-END ENVIRONMENT: PRODUCTION `, 'background: black; color: white');
		}

		try {
			const defaultBaseUrl = this.apiBaseUrl;
			if (isStringDefinedAndNotEmpty(defaultBaseUrl)) {
				environment.api.defaultBaseUrl = defaultBaseUrl;
			}

			const appInsightsKey = this.appInsightsInsKey;
			if (isStringDefinedAndNotEmpty(appInsightsKey)) {
				this.monitoringService.initialize(appInsightsKey);
			}

			this.injector
				.get(ServerConfigurationQueryHandlerService)
				.get(new ServerConfigurationGetQuery())
				.pipe(
					map(result => {
						result.consume(
							success => {
								this.serverConfigurationStorageService.defineServerConfiguration(success);

								this.googleAuthService.initialize(success.googleServices?.googleOpenId);
								this.facebookAuthService.initialize(success.facebookServices?.facebookAuthClientId, success.facebookServices?.facebookAuthSdkNonce);

								if (isValueDefined(success?.serverVersion)) {
									console.log(`%c BACK-END VERSION: ${success.serverVersion} `, 'background: black; color: white');
								}
								if (isValueDefined(success?.serverEnvironment)) {
									console.log(`%c BACK-END ENVIRONMENT: ${success.serverEnvironment} `, 'background: black; color: white');
								}
							},
							failure => {
								this.failureHandlingService.handleFailure(
									FailureModel.createFailureLocalizedViaExistingFailure(
										failure,
										FailureLocalizationModel.createFromValue('shared.forms.failureHints'))
								);
							}
						);
					}),
					take(1)
				)
				.subscribe(() => {
					this.defineInitState();
					this.initializeSubscriptions();
				});

			return of(undefined);
		} catch (error) {
			console.log('Error during app initialization', error);

			return of(undefined);
		}
	}

	private defineInitState() {
		this.storage.tryRestoreLastSession();
		this.applicationTitleService.updateApplicationTitle();
		this.googleAnalyticsConfigurationService.applyPagePath();
	}

	private initializeSubscriptions(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = undefined;
		}

		this.applicationLanguageService.initializeSubscriptions();
		this.notificationService.initSubscriptions();

		this.subscription = merge(this.router.events, this.applicationLanguageService.currentApplicationLanguage$)
			.subscribe((event) => {
				this.applicationTitleService.updateApplicationTitleByEvent(event);
				this.googleAnalyticsConfigurationService.updateConfigByEvent(event);
			});
	}

}
