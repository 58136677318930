import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MedicationRateQuantityTypeFilterModel, MedicationRateQuantityTypeQueryHandlerService, MedicationRateQuantityTypesResolver } from '@nmn-domain/medication-rate-quantity-types';
import { DescribedComboboxModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MedicationRateQuantityTypesViaQueryHandlerResolver extends MedicationRateQuantityTypesResolver {

	constructor(
		private readonly queryHandlerService: MedicationRateQuantityTypeQueryHandlerService
	) {
		super();
	}

	public resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<Array<DescribedComboboxModel>> | Array<DescribedComboboxModel> {
		return this.queryHandlerService
			.getAsDescribedComboboxes(MedicationRateQuantityTypeFilterModel.createEmpty())
			.pipe(map(result => result.successOrDefault(() => [])));
	}

}
