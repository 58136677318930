import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { isValueDefined } from '@nmn-core/utils';
import { BehaviorSubject } from 'rxjs';

// TODO: move to nmn-background
@Injectable({ providedIn: 'root' })
export class MonitoringService {

	private isInitialized$ = new BehaviorSubject<boolean>(undefined);

	appInsights: ApplicationInsights;

	public startInitialize() {
		this.isInitialized$.next(false);
	}

	public initialize(appInsightsInsKey: string) {
		console.log(`%c Inited monitoring service `, 'background: yellow; color: blue');

		this.appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: appInsightsInsKey,
				enableAutoRouteTracking: true
			}
		});

		this.appInsights.loadAppInsights();
		this.isInitialized$.next(true);
	}

	// TODO: need to track page navigation.
	logPageView(name?: string, url?: string) {
		this.appInsights.trackPageView({
			name: name,
			uri: url
		});
	}

	logException(exception: Error, errorText?: string, severityLevel?: number) {
		this.logAction(
			() => this.appInsights.trackException(
				{ exception: exception, severityLevel: severityLevel },
				{ errorCustomText: errorText }
			),
			() => console.log('exception:', exception, errorText)
		);
	}

	private logAction(action: () => void, consoleLogAction: () => void) {
		if (isValueDefined(this.isInitialized$.getValue())) {
			if (!this.isInitialized$.getValue()) {
				this.isInitialized$.subscribe(
					_ => {
						consoleLogAction();
						action();
					}
				);
			} else {
				consoleLogAction();
				action();
			}
		} else {
			consoleLogAction();
		}
	}
}
