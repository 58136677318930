import { Injector } from '@angular/core';
import { AllergyClient } from '@nmn-communication/allergies';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { AllergyFakeClientBuilder } from '../builders/allergy.fake-client.builder';
import { AllergyFakeClientConfigurationBuilder } from '../builders/allergy.fake-client.configuration.builder';
import { AllergyHttpClientBuilder } from '../builders/allergy.http-client.builder';
import { AllergyHttpClientConfigurationBuilder } from '../builders/allergy.http-client.configuration.builder';
import { AllergyFakeClient } from '../services/allergy.fake-client';
import { AllergyHttpClient } from '../services/allergy.http-client';

export const allergyClientFactory = (injector: Injector): AllergyClient => {
	const useFake = environment?.api?.resources?.allergies?.useFake ||
		!isValueDefined(environment?.api?.resources?.allergies?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		allergyFakeClientFactory(injector) :
		allergyHttpClientFactory(injector);
};

export const allergyFakeClientFactory = (
	injector: Injector
): AllergyFakeClient => {
	const configuration = new AllergyFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.allergies.serverDelayMs)
		.build();

	return new AllergyFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const allergyHttpClientFactory = (
	injector: Injector
): AllergyHttpClient => {
	const configuration = new AllergyHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.allergies?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.allergies.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.allergies?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new AllergyHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
