export class TakenMedicationFinishCourseCommand {

	public id: string;
	public patientId: string;

	public constructor(
		id: string,
		patientId: string
	) {

		// TODO: add null check
		this.id = id;
		this.patientId = patientId;

	}

}
