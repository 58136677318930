import { Provider } from '@angular/core';
import { LocaleQueryHandlerService, LocalesResolver } from '@nmn-domain/locales';
import { LocalesViaQueryHandlerResolver } from './resolvers/locales.via-query-handler.resolver';
import { LocaleQueryHandlerViaConstantService } from './services/locale.query-handler-via-constant.service';

export const localeProviders: Array<Provider> = [
	{
		provide: LocaleQueryHandlerService,
		useClass: LocaleQueryHandlerViaConstantService
	},
	{
		provide: LocalesResolver,
		useClass: LocalesViaQueryHandlerResolver
	}
];
