import { TranslocoService } from '@ngneat/transloco';
import {
	VaccinationCardDto, VaccinationCardFindParameterDto, VaccinationCreateParameterDto, VaccinationDto,
	VaccinationFindParameterDto, VaccinationUpdateParameterDto
} from '@nmn-communication/vaccination';
import { Guid, isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../databases/fake.database';
import { FakeLocalizableEntity } from '../models/fake-localizable-entity';
import { setLocalizableEntity, setTranslation } from '../utils/localize';

export class VaccinationFakeTable {

	private readonly database: FakeDatabase;
	private readonly data: Array<VaccinationFakeRecord>;

	constructor(
		database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.database = database;
		this.data = [...initialData];
	}

	public getVaccinationCard(parameter: VaccinationCardFindParameterDto): VaccinationCardDto {
		const patientId = this.database.patientsTable.get({ alias: parameter.patientId })?.id;
		const vaccinations = this.data
			.filter(record => record.patientId === patientId)
			.map(this.mapVaccinationFromRecordToDto.bind(this));

		return {
			patientId,
			vaccinations
		};
	}

	public get(findParameter: VaccinationFindParameterDto): VaccinationDto | undefined {
		const record = this.data
			.find((item: VaccinationFakeRecord) => findPredicate(item, findParameter));

		return isValueDefined(record) ?
			this.mapVaccinationFromRecordToDto(record!) :
			undefined;
	}

	public create(parameter: VaccinationCreateParameterDto): string {
		const record = mapFromCreateParameterToRecord(parameter, this.translocoService.getActiveLang());
		this.data.push(record);

		return record.id;
	}

	public update(
		findParameter: VaccinationFindParameterDto,
		updateParameter: VaccinationUpdateParameterDto
	): void {
		const record = this.data
			.find((item: VaccinationFakeRecord) => findPredicate(item, findParameter));

		if (!isValueDefined(record)) {
			throw new Error('Vaccination to update was not found');
		}

		applyUpdateParameter(record!, updateParameter, this.translocoService.getActiveLang());
	}

	public delete(findParameter: VaccinationFindParameterDto): void {
		const index = this.data
			.findIndex((item: VaccinationFakeRecord) => findPredicate(item, findParameter));

		if (index >= 0) {
			this.data.splice(index, 1);
		}
	}

	private mapVaccinationFromRecordToDto(record: VaccinationFakeRecord): VaccinationDto {
		return {
			id: record.id,
			comment: setTranslation(this.translocoService, record.comment),
			patientId: record.patientId,
			vaccinatedFromDiseases: this.database.vaccinesTable.findFromDiseasesForVaccineAsComboboxes({ id: record.vaccineId }),
			vaccinationDate: record.vaccinationDate,
			vaccine: this.database.vaccinesTable.findAsCombobox({ id: record.vaccineId }),
			numberInSeriesOfDoses: record.numberInSeriesOfDoses,
			memberStateOfVaccination: setTranslation(this.translocoService, record.memberStateOfVaccination),
			expirationDate: record.expirationDate,
			certificateIssuer: setTranslation(this.translocoService, record.certificateIssuer),
			certificateUid: record.certificateUid
		};
	}

}

const findPredicate = (
	item: VaccinationFakeRecord,
	findParameter: VaccinationFindParameterDto
): boolean =>
	item.id === findParameter.id;

const mapFromCreateParameterToRecord = (
	parameter: VaccinationCreateParameterDto,
	language: string
): VaccinationFakeRecord => {
	return {
		id: Guid.newGuid(),
		patientId: parameter.patientId,
		vaccinationDate: parameter.vaccinationDate,
		vaccineId: parameter.vaccineId,
		comment: setLocalizableEntity(parameter.comment, language),
		numberInSeriesOfDoses: parameter.numberInSeriesOfDoses,
		memberStateOfVaccination: setLocalizableEntity(parameter.memberStateOfVaccination, language),
		certificateIssuer: setLocalizableEntity(parameter.certificateIssuer, language),
		certificateUid: parameter.certificateUid,
		expirationDate: parameter.expirationDate,
		createdOn: (new Date()).toISOString(),
		updatedOn: undefined
	};
};

const applyUpdateParameter = (
	record: VaccinationFakeRecord,
	updateParameter: VaccinationUpdateParameterDto,
	language: string
): void => {
	const operationDateTime = (new Date()).toISOString();

	record.patientId = updateParameter.patientId;
	record.comment = setLocalizableEntity(updateParameter.comment, language);
	record.vaccinationDate = updateParameter.vaccinationDate;
	record.vaccineId = updateParameter.vaccineId;
	record.numberInSeriesOfDoses = updateParameter.numberInSeriesOfDoses;
	record.memberStateOfVaccination = setLocalizableEntity(updateParameter.memberStateOfVaccination, language);
	record.certificateIssuer = setLocalizableEntity(updateParameter.certificateIssuer, language);
	record.certificateUid = updateParameter.certificateUid;
	record.expirationDate = updateParameter.expirationDate;
	record.updatedOn = operationDateTime;
};

interface VaccinationFakeRecord {
	id: string;
	patientId: string;
	vaccinationDate: string;
	vaccineId: string;
	comment?: FakeLocalizableEntity;
	numberInSeriesOfDoses: number;
	memberStateOfVaccination?: FakeLocalizableEntity;
	certificateIssuer?: FakeLocalizableEntity;
	certificateUid?: string;
	expirationDate?: string;
	createdOn: string;
	updatedOn?: string;
}

// VaccinationFakeRecord (initial data) has id mask 00000000-0000-0000-0601-************
const initialData: Array<VaccinationFakeRecord> = [
	{
		id: '00000000-0000-0000-0601-000000000001',
		patientId: '00000000-0000-0000-0002-000000000001',
		vaccineId: 'flucelvax-quadrivalent',
		numberInSeriesOfDoses: 1,
		vaccinationDate: '2021-06-01T00:00:00',
		expirationDate: '2022-06-14T12:00:00',
		comment: {
			en: 'Influenza live attenuated vaccine, LAIV4',
			uk: 'Жива атенуйована вакцина проти грипу, LAIV4'
		},
		memberStateOfVaccination: {
			en: 'Netherlands',
			uk: 'Нідерланди'
		},
		certificateIssuer: {
			en: 'Ministry of Health, Welfare and Sports of the Netherlands',
			uk: 'Міністерство охорони здоров\'я, соціального забезпечення та спорту Нідерландів'
		},
		certificateUid: 'EU:NE:FJSJBVLDK83H8E0D928SD',
		createdOn: '2021-06-01T12:00:00',
		updatedOn: undefined
	},
	{
		id: '00000000-0000-0000-0601-000000000002',
		patientId: '00000000-0000-0000-0002-000000000001',
		vaccineId: 'menactra',
		numberInSeriesOfDoses: 1,
		vaccinationDate: '2019-03-11T00:00:00',
		expirationDate: '2024-03-11T12:00:00',
		comment: {
			en: '1 dose MenACWY',
			uk: '1 доза MenACWY'
		},
		memberStateOfVaccination: {
			en: 'Netherlands',
			uk: 'Нідерланди'
		},
		certificateIssuer: {
			en: 'Ministry of Health, Welfare and Sports of the Netherlands',
			uk: 'Міністерство охорони здоров\'я, соціального забезпечення та спорту Нідерландів'
		},
		certificateUid: 'EU:NE:1E3JBVLDK83H8E0D9193R',
		createdOn: '2021-06-01T12:00:00',
		updatedOn: undefined
	},
	{
		id: '00000000-0000-0000-0601-000000000003',
		patientId: '00000000-0000-0000-0002-000000000001',
		vaccineId: 'spikevax',
		numberInSeriesOfDoses: 1,
		vaccinationDate: '2021-01-15T00:00:00',
		expirationDate: '2022-01-15T12:00:00',
		comment: undefined,
		memberStateOfVaccination: {
			en: 'Netherlands',
			uk: 'Нідерланди'
		},
		certificateIssuer: {
			en: 'Ministry of Health, Welfare and Sports of the Netherlands',
			uk: 'Міністерство охорони здоров\'я, соціального забезпечення та спорту Нідерландів'
		},
		certificateUid: 'EU:NE:0D7JBVLDK83H8E0DSP78',
		createdOn: '2021-01-15T12:00:00',
		updatedOn: undefined
	},
	{
		id: '00000000-0000-0000-0601-000000000004',
		patientId: '00000000-0000-0000-0002-000000000001',
		vaccineId: 'spikevax',
		numberInSeriesOfDoses: 2,
		vaccinationDate: '2021-02-15T00:00:00',
		expirationDate: '2022-01-15T12:00:00',
		comment: undefined,
		memberStateOfVaccination: {
			en: 'Netherlands',
			uk: 'Нідерланди'
		},
		certificateIssuer: {
			en: 'Ministry of Health, Welfare and Sports of the Netherlands',
			uk: 'Міністерство охорони здоров\'я, соціального забезпечення та спорту Нідерландів'
		},
		certificateUid: 'EU:NE:0D7JBVLDK83H8E0DSP78',
		createdOn: '2021-02-15T12:00:00',
		updatedOn: undefined
	}
];
