import { RegisterAccountParameter, RegistrationClient } from '@nmn-communication/accounts';
import { Failure } from '@nmn-communication/shared';
import { EmptyResult } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../../modules/fakes/databases/fake.database';
import { wrapActionCallAsFakeClientResponse } from '../../../../../modules/fakes/databases/fake.utils';
import { RegistrationFakeClientConfiguration } from '../configurations/registration.fake-client.configuration';

export class RegistrationFakeClient extends RegistrationClient {

	constructor(
		private readonly configuration: RegistrationFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public registerAccount(parameter: RegisterAccountParameter): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				const userId = this.fakeDatabase.usersTable.create(parameter);
				this.fakeDatabase.emailConfirmationTokenTable.create(userId);
			},
			this.configuration.serverDelayMs
		);
	}

}
