import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { AllergyHttpClientConfiguration } from '../configurations/allergy.http-client.configuration';

export class AllergyHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<AllergyHttpClientConfiguration> {

	public build(): AllergyHttpClientConfiguration {
		return new AllergyHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
