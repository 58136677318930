export class UserProfilePasswordUpdateCommand {

	public oldPassword: string;
	public newPassword: string;
	public confirmNewPassword: string;

	constructor(
		oldPassword: string,
		newPassword: string,
		confirmNewPassword: string
	) {
		this.oldPassword = oldPassword;
		this.newPassword = newPassword;
		this.confirmNewPassword = confirmNewPassword;
	}

}
