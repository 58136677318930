import { Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { AllergyIntoleranceStatusFakeClientConfiguration } from '../configurations/allergy-intolerance-status.fake-client.configuration';
import { AllergyIntoleranceStatusFakeClient } from '../services/allergy-intolerance-status.fake-client';

export class AllergyIntoleranceStatusFakeClientBuilder {

	private configuration: AllergyIntoleranceStatusFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(
		configuration: AllergyIntoleranceStatusFakeClientConfiguration
	): AllergyIntoleranceStatusFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): AllergyIntoleranceStatusFakeClient {
		return new AllergyIntoleranceStatusFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
