export class ConfirmEmailCommand {

	public readonly token: string;
	public readonly userId: string;

	constructor(
		token: string,
		userId: string
	) {

		//TODO: Guard.NotNull

		this.token = token;
		this.userId = userId;

	}

}
