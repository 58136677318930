import { isValueDefined } from '@nmn-core/utils';
import { UserProfileModel } from '../user-profile.model';

export class UserProfileUpdateCommand {

	public readonly isEmailNotificationsEnabled: boolean;
	public readonly isPreferedBrowserTimezone: boolean;
	public readonly pictureId: string;
	public readonly locale: string;
	public readonly timezone: string;
	public readonly activePatientAlias: string;

	constructor(
		isEmailNotificationsEnabled: boolean,
		isPreferedBrowserTimezone: boolean,
		pictureId: string,
		locale: string,
		timezone: string,
		activePatientAlias: string
	) {
		this.isEmailNotificationsEnabled = isEmailNotificationsEnabled;
		this.isPreferedBrowserTimezone = isPreferedBrowserTimezone;
		this.pictureId = pictureId;
		this.locale = locale;
		this.timezone = timezone;
		this.activePatientAlias = activePatientAlias;
	}

	public static createUserProfileUpdateCommandViaActivePatient(
		storageUseProfileModel: UserProfileModel,
		activePatientParameter: string
	): UserProfileUpdateCommand {
		return new UserProfileUpdateCommand(
			storageUseProfileModel.isEmailNotificationsEnabled,
			storageUseProfileModel.isPreferedBrowserTimezone,
			isValueDefined(storageUseProfileModel.picture) ? storageUseProfileModel.picture.id : undefined,
			storageUseProfileModel.locale,
			storageUseProfileModel.timezone,
			activePatientParameter
		);
	}

}
