import { Injectable } from '@angular/core';
import { MedicationRateQuantityTypeClient } from '@nmn-communication/medication-rate-quantity-types';
import { Result } from '@nmn-core/shared';
import { MedicationRateQuantityTypeFilterModel, MedicationRateQuantityTypeQueryHandlerService } from '@nmn-domain/medication-rate-quantity-types';
import { DescribedComboboxModel, FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapDescribedComboboxDtoToDescribedComboboxModel } from '../../shared/factories/combobox.factory';
import { mapDtoArrayResultToModelArrayResult } from '../../shared/factories/pagination.factory';
import { mapFilterModelToDto } from '../factories/medication-rate-quanity-type.factory';

@Injectable()
export class MedicationRateQuantityTypeQueryHandlerViaClientService extends MedicationRateQuantityTypeQueryHandlerService {

	constructor(
		private readonly client: MedicationRateQuantityTypeClient
	) {
		super();
	}

	public getAsDescribedComboboxes(filter: MedicationRateQuantityTypeFilterModel): Observable<Result<Array<DescribedComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapDescribedComboboxDtoToDescribedComboboxModel))
			);
	}

}
