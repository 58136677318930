import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { PagedCollectionDto, PageOptionsDto } from '../../shared';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { DocumentDescribedComboboxDto } from '../models/dtos/document-described-combobox.dto';
import { DocumentDto } from '../models/dtos/document.dto';
import { DocumentFilterDto } from '../models/dtos/document.filter.dto';
import { DocumentCreateParameter } from '../models/parameters/document.create.parameter';
import { DocumentDeleteAttachmentParameter } from '../models/parameters/document.delete-attachment.parameter';
import { DocumentDeleteBulkParameter } from '../models/parameters/document.delete-bulk.parameter';
import { DocumentFindParameter } from '../models/parameters/document.find.parameter';
import { DocumentUpdateParameter } from '../models/parameters/document.update.parameter';

export abstract class DocumentClient {

	public abstract getBulkAsComboboxes(
		filter: DocumentFilterDto
	): Observable<Result<Array<DocumentDescribedComboboxDto>, Failure>>;

	public abstract getBulk(
		parameter: PageOptionsDto<DocumentFilterDto>
	): Observable<Result<PagedCollectionDto<DocumentDto, DocumentFilterDto>, Failure>>;

	public abstract get(
		parameter: DocumentFindParameter
	): Observable<Result<DocumentDto, Failure>>;

	public abstract create(
		parameter: DocumentCreateParameter
	): Observable<Result<string, Failure>>;

	public abstract delete(
		parameter: DocumentFindParameter
	): Observable<EmptyResult<Failure>>;

	public abstract deleteBulk(
		parameter: DocumentDeleteBulkParameter
	): Observable<EmptyResult<Failure>>;

	public abstract deleteAttachment(
		findParameter: DocumentFindParameter,
		deleteParameter: DocumentDeleteAttachmentParameter
	): Observable<EmptyResult<Failure>>;

	public abstract update(
		findParameter: DocumentFindParameter,
		updateParameter: DocumentUpdateParameter
	): Observable<EmptyResult<Failure>>;

}
