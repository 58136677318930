import { Provider } from '@angular/core';
import {
	CurrentPatientVaccinationCardResolver, VaccinationCommandHandlerService, VaccinationFormConfigurationResolver,
	VaccinationQueryHandlerService, VaccinationResolver
} from '@nmn-domain/vaccination';
import { CurrentPatientVaccinationCardViaQueryHandlerResolver } from './resolvers/current-patient-vaccination-card.via-query-handler.resolver';
import { VaccinationFormConfigurationViaConstResolver } from './resolvers/vaccination-form-configuration.from-const.resolver';
import { VaccinationViaQueryHandlerResolver } from './resolvers/vaccination.via-query-handler.resolver';
import { VaccinationCommandHandlerViaClientService } from './services/vaccination.command-handler-via-client.service';
import { VaccinationQueryHandlerViaClientService } from './services/vaccination.query-handler-via-client.service';

export const vaccinationProviders: Array<Provider> = [
	{
		provide: VaccinationCommandHandlerService,
		useClass: VaccinationCommandHandlerViaClientService
	},
	{
		provide: VaccinationQueryHandlerService,
		useClass: VaccinationQueryHandlerViaClientService
	},
	{
		provide: CurrentPatientVaccinationCardResolver,
		useClass: CurrentPatientVaccinationCardViaQueryHandlerResolver
	},
	{
		provide: VaccinationFormConfigurationResolver,
		useClass: VaccinationFormConfigurationViaConstResolver
	},
	{
		provide: VaccinationResolver,
		useClass: VaccinationViaQueryHandlerResolver
	}
];
