import { ClinicModel } from '../../../clinics/models/clinic.model';
import { DoctorModel } from '../../../doctors/models/doctor.model';
import { DocumentFormOutModel } from '../../../documents';
import { TakenMedicationFormOutModel } from '../../../taken-medications';
import { DoctorEncounterComboboxModel } from '../doctor-encounter-combobox.model';
import { DoctorEncounterStatus } from '../doctor-encounter-status.enum';

export class DoctorEncounterFormOutModel {

	// Is used to be able to show model as an inline view
	public readonly comboboxView: DoctorEncounterComboboxModel;

	public readonly patientId: string;
	public readonly diseaseIds: Array<string>;
	public readonly comment?: string;
	public readonly date?: Date;
	public readonly status: DoctorEncounterStatus;
	public readonly doctor?: DoctorModel;
	public readonly clinic?: ClinicModel;
	public readonly healthIssueId?: string;
	public readonly takenMedicationIds: Array<string>;
	public readonly takenMedications: Array<TakenMedicationFormOutModel>;
	public readonly documentIds: Array<string>;
	public readonly documents: Array<DocumentFormOutModel>;

	constructor(
		comboboxView: DoctorEncounterComboboxModel,
		patientId: string,
		diseaseIds: Array<string>,
		comment: string | undefined,
		date: Date | undefined,
		status: DoctorEncounterStatus,
		doctor: DoctorModel | undefined,
		clinic: ClinicModel | undefined,
		healthIssueId: string | undefined,
		takenMedicationIds: Array<string>,
		takenMedications: Array<TakenMedicationFormOutModel>,
		documentIds: Array<string>,
		documents: Array<DocumentFormOutModel>
	) {
		// TODO: Guard check on defined
		this.comboboxView = comboboxView;
		this.patientId = patientId;
		this.diseaseIds = diseaseIds;
		this.comment = comment;
		this.date = date;
		this.status = status;
		this.doctor = doctor;
		this.clinic = clinic;
		this.healthIssueId = healthIssueId;
		this.takenMedicationIds = takenMedicationIds;
		this.takenMedications = takenMedications;
		this.documentIds = documentIds;
		this.documents = documents;
	}

}
