import { NgModule } from '@angular/core';
import { GoogleAnalyticsConfigurationService } from './services/google-analytics-configuration.service';
import { GoogleAnalyticsEventTrackingService } from './services/event-tracking/google-analytics-event-tracking.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';

@NgModule({
	providers: [
		GoogleAnalyticsService,
		GoogleAnalyticsConfigurationService,
		GoogleAnalyticsEventTrackingService
	]
})
export class GoogleAnalyticsModule { }
