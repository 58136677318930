import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { DoctorEncounterHttpClientConfiguration } from '../configurations/doctor-encounter.http-client.configuration';

export class DoctorEncounterHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<DoctorEncounterHttpClientConfiguration> {

	public build(): DoctorEncounterHttpClientConfiguration {
		return new DoctorEncounterHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
