import { HttpClientConfigurationBuilder } from '../../../../../communication/implementation/shared/builders/http-client.configuration.builder';
import { RegistrationHttpClientConfiguration } from '../configurations/registration.http-client.configuration';

export class RegistrationHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<RegistrationHttpClientConfiguration> {

	public build(): RegistrationHttpClientConfiguration {
		return new RegistrationHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
