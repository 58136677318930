import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { TreatmentCheckerQueryHandlerService, TreatmentCheckerReportGetQuery, TreatmentCheckerReportModel, TreatmentCheckerReportResolver } from '@nmn-domain/treatment-checker';
import { RouteParameter } from '@nmn-middleware/navigation';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TreatmentCheckerReportViaQueryHandlerResolver extends TreatmentCheckerReportResolver {

	constructor(
		private readonly treatmentCheckerQueryHandlerService: TreatmentCheckerQueryHandlerService
	) {
		super();
	}

	public resolve(activatedRoute: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<TreatmentCheckerReportModel> | TreatmentCheckerReportModel {
		const resultId = activatedRoute.paramMap.get(RouteParameter.CheckerResultId);

		if (!isStringDefinedAndNotEmpty(resultId)) {
			return undefined;
		}

		const organizationId = activatedRoute.paramMap.get(RouteParameter.OrganizationId);
		const getQuery = isStringDefinedAndNotEmpty(organizationId) ?
			TreatmentCheckerReportGetQuery.createByReportIdAndOrganizationId(resultId, organizationId) :
			TreatmentCheckerReportGetQuery.createByReportId(resultId);

		return this.treatmentCheckerQueryHandlerService
			.getReport(getQuery)
			.pipe(map(result => result.successOrDefault(() => undefined)));
	}

}
