import { GeneClient, GeneComboboxDto, GeneFilterDto } from '@nmn-communication/genes';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { wrapFuncCallAsFakeClientResponse } from '../../../../modules/fakes/databases/fake.utils';
import { GeneFakeClientConfiguration } from '../configurations/gene.fake-client.configuration';

export class GeneFakeClient extends GeneClient {

	constructor(
		private readonly configuration: GeneFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulkAsComboboxes(filter: GeneFilterDto): Observable<Result<Array<GeneComboboxDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.genesTable.getComboboxesPagedCollection(filter),
			this.configuration.serverDelayMs
		);
	}

}
