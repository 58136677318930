import { TypeOfMedicationComboboxDto, TypeOfMedicationFilterDto } from '@nmn-communication/types-of-medication';
import { TypeOfMedicationComboboxModel, TypeOfMedicationFilterModel } from '@nmn-domain/types-of-medication';

export const mapFilterModelToDto = (model: TypeOfMedicationFilterModel): TypeOfMedicationFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern
	}
);

export const mapFilterDtoToModel = (dto: TypeOfMedicationFilterDto): TypeOfMedicationFilterModel => (
	new TypeOfMedicationFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern
	)
);

export const mapTypeOfMedicationComboboxDtoToModel = (dto: TypeOfMedicationComboboxDto): TypeOfMedicationComboboxModel => (
	new TypeOfMedicationComboboxModel(
		dto.id.toString(),
		dto.displayText,
		dto.description
	)
);
