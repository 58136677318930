import { Failure } from '@nmn-communication/shared';
import { Observable } from 'rxjs';
import { Result } from '../../../../../core/shared';
import { LoginViaFacebookParameter } from '../models/login-via-facebook.parameter';
import { LoginViaGoogleParameter } from '../models/login-via-google.parameter';
import { LoginDto, TokenInfoDto } from '../models/login.dto';
import { LoginParameter } from '../models/login.parameter';
import { RefreshTokenParameter } from '../models/refresh-token.parameter';

export abstract class LoginClient {

	public abstract login(parameter: LoginParameter): Observable<Result<LoginDto, Failure>>;

	public abstract loginViaGoogle(parameter: LoginViaGoogleParameter): Observable<Result<LoginDto, Failure>>;

	public abstract loginViaFacebook(parameter: LoginViaFacebookParameter): Observable<Result<LoginDto, Failure>>;

	public abstract refreshToken(parameter: RefreshTokenParameter): Observable<Result<TokenInfoDto, Failure>>;

}
