export class DocumentTypeFilterModel {

	public readonly ids?: Array<string>;

	public readonly ignoreIds?: Array<string>;

	public readonly searchPattern?: string;

	constructor(
		ids: Array<string> | undefined,
		ignoreIds: Array<string> | undefined,
		searchPattern: string | undefined
	) {
		this.ids = ids;
		this.ignoreIds = ignoreIds;
		this.searchPattern = searchPattern;
	}

	public static createEmpty(): DocumentTypeFilterModel {
		return new DocumentTypeFilterModel(undefined, undefined, undefined);
	}

	public static createForDocumentTypesAutocomplete(
		searchPattern: string,
		ignoreIds: Array<string>
	): DocumentTypeFilterModel {
		return new DocumentTypeFilterModel(undefined, ignoreIds, searchPattern);
	}

}
