import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { ServerConfigurationHttpClientConfiguration } from '../configurations/server-configuration.http-client.configuration';
import { ServerConfigurationHttpClient } from '../services/server-configuration.http-client';

export class ServerConfigurationHttpClientBuilder
	extends HttpClientBuilder<ServerConfigurationHttpClient, ServerConfigurationHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): ServerConfigurationHttpClient {
		return new ServerConfigurationHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
