import { LoginDto } from '@nmn-communication/accounts';
import {
	PatientClient, PatientCreateParameter, PatientDeleteParameter, PatientDto, PatientFilterDto, PatientFindParameter,
	PatientProfileDto, PatientRevokeDeleteParameter, PatientUpdateParameter
} from '@nmn-communication/patients';
import { Failure, PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../../../../core/application-storages';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { wrapActionCallAsFakeClientResponse, wrapFuncCallAsFakeClientResponse } from '../../../../modules/fakes/databases/fake.utils';
import { PatientFakeClientConfiguration } from '../configurations/patient.fake-client.configuration';

export class PatientFakeClient extends PatientClient {

	constructor(
		private readonly configuration: PatientFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase,
		private readonly localStorage: LocalStorageService
	) {
		super();
	}

	public get(parameter: PatientFindParameter): Observable<Result<PatientDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.patientsTable.get(parameter),
			this.configuration.serverDelayMs
		);
	}

	public getProfile(parameter: PatientFindParameter): Observable<Result<PatientProfileDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.patientsTable.getProfile(parameter),
			this.configuration.serverDelayMs
		);
	}

	public getBulk(
		parameter: PageOptionsDto<PatientFilterDto>
	): Observable<Result<PagedCollectionDto<PatientDto, PatientFilterDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.patientsTable.getPagedCollection(parameter),
			this.configuration.serverDelayMs
		);
	}

	public getActivePatients(): Observable<Result<Array<PatientDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => {
				// TODO: remove usage of localstorage in fakes
				const userId = this.localStorage.getLastUserId();

				return this.fakeDatabase.patientsTable.getActivePatients(userId);
			},
			this.configuration.serverDelayMs
		);
	}

	public create(parameter: PatientCreateParameter): Observable<Result<string, Failure>> {
		wrapActionCallAsFakeClientResponse(
			// TODO: remove usage of localstorage in fakes
			() => { this.fakeDatabase.userSubscriptionFakeTable.incrementPatientUsed(this.localStorage.getLastUserId()); },
			{ min: 0, max: 0 }
		);
		return wrapFuncCallAsFakeClientResponse(
			() => {
				// TODO: remove usage of localstorage in fakes
				const userId = this.localStorage.getLastUserId();

				return this.fakeDatabase.patientsTable.create(userId, parameter);
			},
			this.configuration.serverDelayMs
		);
	}

	public update(
		findParameter: PatientFindParameter,
		updateParameter: PatientUpdateParameter
	): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => { this.fakeDatabase.patientsTable.update(findParameter, updateParameter); },
			this.configuration.serverDelayMs
		);
	}

	public delete(parameter: PatientDeleteParameter): Observable<Result<LoginDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => { return this.fakeDatabase.patientsTable.delete(parameter); },
			this.configuration.serverDelayMs
		);
	}

	public revokeDelete(parameter: PatientRevokeDeleteParameter): Observable<Result<LoginDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => { return this.fakeDatabase.patientsTable.revokeDelete(parameter); },
			this.configuration.serverDelayMs
		);
	}

}
