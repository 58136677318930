import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CurrentUserConfigurationClient, UserConfigurationDto, UserConfigurationUpdateParameter } from '@nmn-communication/accounts';
import { Failure } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { createEmptyResultFromResponse, createFailedEmptyResultFromErrorResponse, createFailedResultFromErrorResponse, createResultFromResponse } from '../../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { UserConfigurationHttpClientConfiguration } from '../configurations/user-configuration.http-client.configuration';

export class CurrentUserConfigurationHttpClient extends CurrentUserConfigurationClient {

	constructor(
		private readonly configuration: UserConfigurationHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public get(): Observable<Result<UserConfigurationDto, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<UserConfigurationDto>(url, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public update(
		parameter: UserConfigurationUpdateParameter
	): Observable<EmptyResult<Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`;
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion)

		return this.httpClient
			.put(url, payload, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

}
