import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthorizedGuard } from '@nmn-middleware/guards';
import { NavigationResource } from '@nmn-middleware/navigation';
import { PreloadingStrategyService } from './middleware/application-behavior';

const routes: Routes = [
	// {
	// 	path: `${NavigationResource.Home}`,
	// 	loadChildren: () => import('./modules/home/home.module')
	// 		.then(importedModule => importedModule.HomeModule),
	// 	data: {
	// 		shouldPreload: true
	// 	}
	// },
	{
		path: `${NavigationResource.Home}`,
		loadChildren: () => import('./modules/home-b2c/home-b2c.module')
			.then(importedModule => importedModule.HomeB2CModule),
		data: {
			shouldPreload: true
		}
	},
	{
		path: `${NavigationResource.PrivacyPolicy}`,
		loadChildren: () => import('./modules/privacy-policy/privacy-policy.module')
			.then(importedModule => importedModule.PrivacyPolicyModule),
		data: {
			shouldPreload: true,
			loadAfterMs: 10000
		}
	},
	{
		path: `${NavigationResource.Login}`,
		loadChildren: () => import('./modules/accounts/login/modularity/login.module')
			.then(importedModule => importedModule.LoginModule),
		data: {
			shouldPreload: true
		}
	},
	{
		path: `${NavigationResource.RegisterAccount}`,
		loadChildren: () => import('./modules/accounts/registration/modularity/registration.module')
			.then(importedModule => importedModule.RegistrationModule),
		data: {
			shouldPreload: true,
			loadAfterMs: 10000
		}
	},
	{
		path: `${NavigationResource.ConfirmEmail}`,
		loadChildren: () => import('./modules/accounts/confirm-email/modularity/confirm-email.module')
			.then(importedModule => importedModule.ConfirmEmailModule),
		data: {
			shouldPreload: true
		}
	},
	{
		path: `${NavigationResource.ResetPassword}`,
		loadChildren: () => import('./modules/accounts/reset-password/modularity/reset-password.module')
			.then(importedModule => importedModule.ResetPasswordModule),
		data: {
			shouldPreload: true
		}
	},
	{
		path: `${NavigationResource.TreatmentChecker}`,
		loadChildren: () => import('./modules/treatment-checker/treatment-checker-layout/treatment-checker-layout.module')
			.then(importedModule => importedModule.TreatmentCheckerLayoutModule)
	},
	{
		// TODO: define route for anonymous medication checker
		path: `${NavigationResource.MedicationChecker}`,
		redirectTo: `${NavigationResource.Home}`
	},
	{
		path: `${NavigationResource.Pricing}`,
		loadChildren: () => import('./modules/pricings/modularity/pricing.module')
			.then(importedModule => importedModule.PricingModule)
	},
	{
		path: '',
		loadChildren: () => import('./modules/layouts/layout/layout.module')
			.then(importedModule => importedModule.LayoutModule),
		canActivate: [AuthorizedGuard],
		canActivateChild: [AuthorizedGuard]
	},
	{
		path: '**',
		redirectTo: `${NavigationResource.Home}`
	}
];

const routingConfiguration: ExtraOptions = {
	paramsInheritanceStrategy: 'always',
	preloadingStrategy: PreloadingStrategyService,
	relativeLinkResolution: 'legacy'
};

@NgModule({
	imports: [RouterModule.forRoot(routes, routingConfiguration)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
