import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { NotificationDialogInModel } from '../../models/notification-dialog.in-model';

@Component({
	selector: 'app-notification-dialog',
	templateUrl: './notification-dialog.component.html',
	styleUrls: ['./notification-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationDialogComponent implements OnDestroy {

	private readonly destroyed$ = new Subject<undefined>();

	public dialogSettings: NotificationDialogInModel;

	constructor(
		@Inject(MAT_DIALOG_DATA) data: NotificationDialogInModel,
		public dialogRef: MatDialogRef<NotificationDialogComponent>
	) {
		this.dialogSettings = data;
	}

	public ngOnDestroy(): void {
		this.destroyed$.next(undefined);
		this.destroyed$.complete();
	}

	public confirm(): void {
		this.dialogRef.close();
	}

}
