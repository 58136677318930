import { DiseaseComboboxModel } from '@nmn-domain/diseases';
import { UploadedFileModel } from '@nmn-domain/file-uploader';
import { MedicationComboboxModel } from '@nmn-domain/medications';
import { PopulationComboboxModel } from '@nmn-domain/populations';
import { ComboboxModel, DescribedComboboxModel } from '@nmn-domain/shared';
import { InformationBlock } from '../../../../../modules/core/models';
import { AllergyIntoleranceModel } from '../allergy-intolerance.model';
import { PatientAnamnesisModel } from '../patient-anamnesis.model';
import { PatientLifestyleModel } from '../patient-lifestyle.model';
import { PatientModel } from '../patient.model';

export class PatientInformationBlockModel extends PatientModel implements InformationBlock {

	public readonly fillingUpPercentage: number;

	constructor(
		id: string,
		alias: string,
		userId: string,
		createdOn: Date,
		updatedOn: Date,
		givenName: string,
		additionalName: string,
		familyName: string,
		languages: Array<ComboboxModel>,
		preferredLanguage: ComboboxModel,
		picture: UploadedFileModel,
		population: PopulationComboboxModel,
		gender: DescribedComboboxModel,
		birthday: Date,
		bloodType: DescribedComboboxModel,
		height: number,
		weight: number,
		email: string,
		phoneNumber: string,
		address: string,
		allergyIntolerances: Array<AllergyIntoleranceModel>,
		seriousPathologies: Array<DiseaseComboboxModel>,
		familyDiseases: Array<DiseaseComboboxModel>,
		regularlyTakenMedications: Array<MedicationComboboxModel>,
		doctors: Array<{ fullName: string; contacts: string; }>,
		emergencyContacts: Array<{ fullName: string; data: string }>,
		insurances: Array<{ company: string; code: string; }>,
		anamnesis: PatientAnamnesisModel,
		lifestyle: PatientLifestyleModel,
		fillingUpPercentage: number
	) {
		super(
			id, alias, userId, createdOn, updatedOn,
			givenName, additionalName, familyName, languages, preferredLanguage,
			picture, population, gender, birthday, bloodType, height, weight,
			email, phoneNumber, address,
			allergyIntolerances, seriousPathologies, familyDiseases, regularlyTakenMedications,
			doctors, emergencyContacts, insurances, anamnesis, lifestyle
		);

		// TODO: add Guard check for InformationBlock
		this.fillingUpPercentage = fillingUpPercentage;
	}

}
