import { Injector } from '@angular/core';
import { AllergyIntoleranceCriticalityClient } from '@nmn-communication/allergy-intolerance-criticalities';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { AllergyIntoleranceCriticalityFakeClientBuilder } from '../builders/allergy-intolerance-criticality.fake-client.builder';
import { AllergyIntoleranceCriticalityFakeClientConfigurationBuilder } from '../builders/allergy-intolerance-criticality.fake-client.configuration.builder';
import { AllergyIntoleranceCriticalityHttpClientBuilder } from '../builders/allergy-intolerance-criticality.http-client.builder';
import { AllergyIntoleranceCriticalityHttpClientConfigurationBuilder } from '../builders/allergy-intolerance-criticality.http-client.configuration.builder';
import { AllergyIntoleranceCriticalityFakeClient } from '../services/allergy-intolerance-criticality.fake-client';
import { AllergyIntoleranceCriticalityHttpClient } from '../services/allergy-intolerance-criticality.http-client';

export const allergyIntoleranceCriticalityClientFactory = (injector: Injector): AllergyIntoleranceCriticalityClient => {
	const useFake = environment?.api?.resources?.allergyIntoleranceCriticalities?.useFake ||
		!isValueDefined(environment?.api?.resources?.allergyIntoleranceCriticalities?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		allergyIntoleranceCriticalityFakeClientFactory(injector) :
		allergyIntoleranceCriticalityHttpClientFactory(injector);
};

export const allergyIntoleranceCriticalityFakeClientFactory = (
	injector: Injector
): AllergyIntoleranceCriticalityFakeClient => {
	const configuration = new AllergyIntoleranceCriticalityFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.allergyIntoleranceCriticalities.serverDelayMs)
		.build();

	return new AllergyIntoleranceCriticalityFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const allergyIntoleranceCriticalityHttpClientFactory = (
	injector: Injector
): AllergyIntoleranceCriticalityHttpClient => {
	const configuration = new AllergyIntoleranceCriticalityHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.allergyIntoleranceCriticalities?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.allergyIntoleranceCriticalities.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.allergyIntoleranceCriticalities?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new AllergyIntoleranceCriticalityHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
