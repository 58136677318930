import { isValueDefined } from '@nmn-core/utils';
import { NmnMatTableUserConfiguration } from '../component-configurations/nmn-mat-table-user.configuration';

export class HealthIssuesPageConfiguration {

	public readonly healthIssuesTableUserConfiguration: NmnMatTableUserConfiguration;

	protected constructor(
		healthIssuesTableUserConfiguration: NmnMatTableUserConfiguration
	) {
		this.healthIssuesTableUserConfiguration = healthIssuesTableUserConfiguration;
	}

	public static createWithParameters(
		config?: HealthIssuesPageConfiguration
	): HealthIssuesPageConfiguration {
		if (!isValueDefined(config)) {
			HealthIssuesPageConfiguration.createDefault();
		}

		return new HealthIssuesPageConfiguration(
			isValueDefined(config?.healthIssuesTableUserConfiguration) ?
				NmnMatTableUserConfiguration.createWithParameters(config.healthIssuesTableUserConfiguration) :
				NmnMatTableUserConfiguration.createDefault()
		);
	}

	public static createDefault(): HealthIssuesPageConfiguration {
		return new HealthIssuesPageConfiguration(
			NmnMatTableUserConfiguration.createWithParameters(
				new NmnMatTableUserConfiguration(undefined, undefined, undefined, 'desc', 'period'))
		);
	}

}
