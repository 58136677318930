import { Provider } from '@angular/core';
import { FileUploaderCommandHandlerService } from '@nmn-domain/file-uploader';
import { FileUploaderCommandHandlerViaClientService } from './services/file-uploader.command-handler-via-client.service';

export const fileUploaderProviders: Array<Provider> = [
	{
		provide: FileUploaderCommandHandlerService,
		useClass: FileUploaderCommandHandlerViaClientService
	}
];
