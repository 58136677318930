import { DiseaseType } from '../../diseases/models/disease-type.enum';
import { DescribedComboboxModel } from '../../shared/comboboxes/described-combobox.model';
import { HealthComplaintType } from './health-complaint-type.enum';

export class HealthComplaintComboboxModel extends DescribedComboboxModel {

	public readonly complientType: HealthComplaintType;
	public readonly type?: DiseaseType | undefined;

	public get isDisease(): boolean {
		return this.complientType === HealthComplaintType.Disease;
	}

	public get isSymptom(): boolean {
		return this.complientType === HealthComplaintType.Symptom;
	}

	public get isNotDefinedComplaintType(): boolean {
		return this.complientType === HealthComplaintType.Undefined;
	}

	constructor(
		id: string,
		displayText: string,
		description: string,
		complientType: HealthComplaintType,
		type: DiseaseType | undefined
	) {
		super(id, displayText, description);

		// TODO: Guard check on defined
		this.complientType = complientType;
		this.type = type;
	}

	public static createDisplayOptionFn(): (option: HealthComplaintComboboxModel) => string | undefined {
		return option => option.displayText;
	}

}
