import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { PagedCollectionModel, PageOptionsQuery } from '../../shared';
import { FailureModel } from '../../shared/failures/failure.model';
import { HealthIssueComboboxModel } from '../models/health-issue-combobox.model';
import { HealthIssueFilterModel } from '../models/health-issue-filter.model';
import { HealthIssueModel } from '../models/health-issue.model';
import { HealthIssueGetQuery } from '../models/queries/health-issue.get.query';

export abstract class HealthIssueQueryHandlerService {

	public abstract getAsDescribedComboboxes(
		query: HealthIssueFilterModel
	): Observable<Result<Array<HealthIssueComboboxModel>, FailureModel>>;

	public abstract getPagedCollection(
		query: PageOptionsQuery<HealthIssueFilterModel>
	): Observable<Result<PagedCollectionModel<HealthIssueModel, HealthIssueFilterModel>, FailureModel>>;

	public abstract get(
		query: HealthIssueGetQuery
	): Observable<Result<HealthIssueModel, FailureModel>>;

}
