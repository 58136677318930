import { LanguageFilterDto } from '@nmn-communication/languages';
import { LanguageFilterModel } from '@nmn-domain/languages';

export const mapFilterModelToDto = (model: LanguageFilterModel): LanguageFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern
	}
);

export const mapFilterDtoToModel = (dto: LanguageFilterDto): LanguageFilterModel => (
	new LanguageFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern
	)
);
