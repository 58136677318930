export class TreatmentCheckerReportFilterModel {

	public readonly ids?: Array<string>;
	public readonly ignoreIds?: Array<string>;
	public readonly patientIds?: Array<string>;
	public readonly searchPattern?: string;

	constructor(
		ids: Array<string> | undefined,
		ignoreIds: Array<string> | undefined,
		patientIds: Array<string> | undefined,
		searchPattern: string | undefined
	) {
		this.ids = ids;
		this.ignoreIds = ignoreIds;
		this.patientIds = patientIds;
		this.searchPattern = searchPattern;
	}

}
