import { isNumberDefined } from '@nmn-core/utils';

export enum TreatmentCheckerReportDiseaseReportFrequencyType {

	Undefined = 'undefined',

	VeryLow = 'veryLow',
	Low = 'low',
	High = 'high',
	VeryHigh = 'veryHigh'

}

export const mapTreatmentCheckerReportDiseaseReportFrequencyTypeFromNumberValue = (
	value: number | undefined
): TreatmentCheckerReportDiseaseReportFrequencyType => {
	if (isNumberDefined(value)) {
		if (value < 0) {
			return TreatmentCheckerReportDiseaseReportFrequencyType.Undefined;
		} else if (value <= 1) {
			return TreatmentCheckerReportDiseaseReportFrequencyType.VeryLow;
		} else if (value <= 5) {
			return TreatmentCheckerReportDiseaseReportFrequencyType.Low;
		} else if (value <= 25) {
			return TreatmentCheckerReportDiseaseReportFrequencyType.High;
		} else if (value <= 100) {
			return TreatmentCheckerReportDiseaseReportFrequencyType.VeryHigh;
		} else {
			return TreatmentCheckerReportDiseaseReportFrequencyType.Undefined;
		}
	}

	return TreatmentCheckerReportDiseaseReportFrequencyType.Undefined;
};
