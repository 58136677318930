import { Injector } from '@angular/core';
import { UserProfileClient } from '@nmn-communication/accounts';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { UserProfileFakeClientBuilder } from '../builders/user-profile.fake-client.builder';
import { UserProfileFakeClientConfigurationBuilder } from '../builders/user-profile.fake-client.configuration.builder';
import { UserProfileHttpClientBuilder } from '../builders/user-profile.http-client.builder';
import { UserProfileHttpClientConfigurationBuilder } from '../builders/user-profile.http-client.configuration.builder';
import { UserProfileFakeClient } from '../services/user-profile.fake-client';
import { UserProfileHttpClient } from '../services/user-profile.http-client';

export const userProfileClientFactory = (
	injector: Injector
): UserProfileClient => {
	const useFake = environment?.api?.resources?.accounts?.userProfile?.useFake ||
		!isValueDefined(environment?.api?.resources?.accounts?.userProfile?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		userProfileFakeClientFactory(injector) :
		userProfileHttpClientFactory(injector);
};

export const userProfileFakeClientFactory = (
	injector: Injector
): UserProfileFakeClient => {
	const configuration = new UserProfileFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.accounts.userProfile.serverDelayMs)
		.build();

	return new UserProfileFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const userProfileHttpClientFactory = (
	injector: Injector
): UserProfileHttpClient => {
	const configuration = new UserProfileHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.accounts?.userProfile?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.accounts.userProfile.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.accounts?.userProfile?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new UserProfileHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
