import { Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { GeneVariantFakeClientConfiguration } from '../configurations/gene-variant.fake-client.configuration';
import { GeneVariantFakeClient } from '../services/gene-variant.fake-client';

export class GeneVariantFakeClientBuilder {

	private configuration: GeneVariantFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(configuration: GeneVariantFakeClientConfiguration): GeneVariantFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): GeneVariantFakeClient {
		return new GeneVariantFakeClient(this.configuration, this.injector.get(FakeDatabase));
	}

}
