import { GeneticFileParseRequestStatus } from '../genetic-file-parse-request-status.enum';

export class GeneticFileParseRequestFormOutModel {

	public readonly patientId: string;
	public readonly date: Date;
	public readonly status: GeneticFileParseRequestStatus;

	constructor(
		patientId: string,
		date: Date,
		status: GeneticFileParseRequestStatus
	) {
		// TODO: Guard check on defined

		this.patientId = patientId;
		this.date = date;
		this.status = status;
	}

}
