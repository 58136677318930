import { Injectable } from '@angular/core';
import { VaccinationClient } from '@nmn-communication/vaccination';
import { Result } from '@nmn-core/shared';
import { FailureModel } from '@nmn-domain/shared';
import { VaccinationCommandHandlerService, VaccinationCreateCommand, VaccinationDeleteCommand, VaccinationUpdateCommand } from '@nmn-domain/vaccination';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommandResult, EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapCreateCommandToCreateParameter, mapUpdateCommandToUpdateParameter } from '../factories/vaccination.factory';

@Injectable()
export class VaccinationCommandHandlerViaClientService extends VaccinationCommandHandlerService {

	constructor(
		private readonly client: VaccinationClient
	) {
		super();
	}

	public handleCreateCommand(command: VaccinationCreateCommand): Observable<Result<CommandResult<VaccinationCreateCommand, string>, FailureModel>> {
		return this.client
			.create(mapCreateCommandToCreateParameter(command))
			.pipe(map(result => result.map(success => new CommandResult(command, success), mapFailureToFailureModel)));
	}

	public handleUpdateCommand(command: VaccinationUpdateCommand): Observable<Result<EmptyCommandResult<VaccinationUpdateCommand>, FailureModel>> {
		return this.client
			.update(
				{ id: command.id, patientId: command.patientId },
				mapUpdateCommandToUpdateParameter(command)
			)
			.pipe(map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command))));
	}

	public handleDeleteCommand(command: VaccinationDeleteCommand): Observable<Result<EmptyCommandResult<VaccinationDeleteCommand>, FailureModel>> {
		return this.client
			.delete({ id: command.id, patientId: command.patientId })
			.pipe(map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command))));
	}

}
