export class LoginViaGoogleCommand {

	public readonly idToken: string;

	constructor(
		idToken: string
	) {
		// TODO: Guard check on defined
		this.idToken = idToken;
	}

}
