import { Injector } from '@angular/core';
import { LoginClient } from '@nmn-communication/accounts';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { LoginFakeClientBuilder } from '../builders/login.fake-client.builder';
import { LoginFakeClientConfigurationBuilder } from '../builders/login.fake-client.configuration.builder';
import { LoginHttpClientBuilder } from '../builders/login.http-client.builder';
import { LoginHttpClientConfigurationBuilder } from '../builders/login.http-client.configuration.builder';
import { LoginFakeClient } from '../services/login.fake-client';
import { LoginHttpClient } from '../services/login.http-client';

export const loginClientFactory = (injector: Injector): LoginClient => {
	const useFake = environment?.api?.resources?.accounts?.login?.useFake ||
		!isValueDefined(environment?.api?.resources?.accounts?.login?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ? loginFakeClientFactory(injector) : loginHttpClientFactory(injector);
};

export const loginFakeClientFactory = (injector: Injector): LoginFakeClient => {
	const configuration = new LoginFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.accounts.login.serverDelayMs)
		.build();

	return new LoginFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const loginHttpClientFactory = (injector: Injector): LoginHttpClient => {
	const configuration = new LoginHttpClientConfigurationBuilder()
		.withBaseUrl(environment?.api?.resources?.accounts?.login?.baseUrl ?? environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.accounts.login.resourceRelativePath)
		.withResourceTypeVersion(environment?.api?.resources?.accounts?.login?.resourceTypeVersion ?? environment?.api?.defaultResourceTypeVersion)
		.build();

	return new LoginHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
