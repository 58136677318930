import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../../../../communication/implementation/shared/builders/http-client.builder';
import { UserConfigurationHttpClientConfiguration } from '../configurations/user-configuration.http-client.configuration';
import { CurrentUserConfigurationHttpClient } from '../services/current-user-configuration.http-client';

export class UserConfigurationHttpClientBuilder
	extends HttpClientBuilder<CurrentUserConfigurationHttpClient, UserConfigurationHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): CurrentUserConfigurationHttpClient {
		return new CurrentUserConfigurationHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
