import { Injectable } from '@angular/core';
import { RegistrationClient } from '@nmn-communication/accounts';
import { Result } from '@nmn-core/shared';
import { RegisterAccountCommand, RegistrationCommandHandlerService } from '@nmn-domain/accounts';
import { EmptyCommandResult, FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../../../../domain/implementations/shared/factories/failure-handling.factory';
import { mapRegisterAccountCommandToParameter } from '../factories/registration.factory';

@Injectable()
export class RegistrationCommandHandlerViaClientService extends RegistrationCommandHandlerService {

	constructor(
		private readonly client: RegistrationClient
	) {
		super();
	}

	public handleRegisterAccount(command: RegisterAccountCommand): Observable<Result<EmptyCommandResult<RegisterAccountCommand>, FailureModel>> {
		return this.client
			.registerAccount(mapRegisterAccountCommandToParameter(command))
			.pipe(map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command))));
	}

}
