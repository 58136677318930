import { TranslocoService } from '@ngneat/transloco';
import { TreatmentCheckerReportMedicationSideEffectDto } from '@nmn-communication/treatment-checker';
import { isValueDefined } from '@nmn-core/utils';
import { setTranslation } from '../utils/localize';
import { FakeComboboxDto } from './comboboxes/fake-combox.dto';

export interface FakeTreatmentCheckerReportMedicationSideEffectDto extends FakeComboboxDto<string> {

	frequency?: string;

}

export const mapFakeTreatmentCheckerReportMedicationSideEffectDto = (
	translocoService: TranslocoService,
	dto: FakeTreatmentCheckerReportMedicationSideEffectDto
): TreatmentCheckerReportMedicationSideEffectDto => {
	if (!isValueDefined(dto)) {
		return undefined;
	}

	return {
		id: dto.id,
		displayText: setTranslation(translocoService, dto.displayText),
		frequency: dto.frequency
	};
};
