import { Injectable } from '@angular/core';
import { DoctorEncounterClient } from '@nmn-communication/doctor-encounters';
import { Result } from '@nmn-core/shared';
import { DoctorEncounterComboboxModel, DoctorEncounterFilterModel, DoctorEncounterGetQuery, DoctorEncounterModel, DoctorEncounterQueryHandlerService } from '@nmn-domain/doctor-encounters';
import { FailureModel, PagedCollectionModel, PageOptionsQuery } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapDtoArrayResultToModelArrayResult, mapPageOptionsQueryToDto } from '../../shared/factories/pagination.factory';
import { mapDoctorEncounterComboboxDtoToModel } from '../factories/doctor-encounter-combobox.factory';
import { mapDoctorEncounterPagedCollectionDtoToModel, mapDtoToModel, mapFilterModelToDto } from '../factories/doctor-encounter.factory';

@Injectable()
export class DoctorEncounterQueryHandlerViaClientService extends DoctorEncounterQueryHandlerService {

	constructor(
		private readonly client: DoctorEncounterClient
	) {
		super();
	}

	public getAsDescribedComboboxes(filter: DoctorEncounterFilterModel): Observable<Result<Array<DoctorEncounterComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapDoctorEncounterComboboxDtoToModel))
			);
	}

	public getPagedCollection(query: PageOptionsQuery<DoctorEncounterFilterModel>): Observable<Result<PagedCollectionModel<DoctorEncounterModel, DoctorEncounterFilterModel>, FailureModel>> {
		return this.client
			.getBulk(mapPageOptionsQueryToDto(query, mapFilterModelToDto))
			.pipe(
				map(result => result.map(mapDoctorEncounterPagedCollectionDtoToModel, mapFailureToFailureModel))
			);
	}

	public get(query: DoctorEncounterGetQuery): Observable<Result<DoctorEncounterModel, FailureModel>> {
		return this.client
			.get({ id: query.id, patientId: query.patientId })
			.pipe(
				map(result => result.map(mapDtoToModel, mapFailureToFailureModel))
			);
	}

}
