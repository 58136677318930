import { notProvided } from '../../constants';

export class GoogleServicesConfiguration {

	public readonly googleOpenId?: string;

	constructor(
		googleOpenId: string | undefined
	) {
		this.googleOpenId = googleOpenId;
	}

	public static createDefault(): GoogleServicesConfiguration {
		return new GoogleServicesConfiguration(notProvided);
	}

}
