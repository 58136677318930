import { isValueDefined } from './value.utils';

export const isStringDefinedAndNotEmpty = (str: any): boolean => {
	return isValueDefined(str) &&
		typeof str === 'string' &&
		str !== '' &&
		// eslint-disable-next-line @typescript-eslint/quotes
		str !== "";
};

export const injectParameters = (inputString: string, dictionary: any): string => {

	const keys = Object.keys(dictionary);

	let outputString = inputString;
	keys.forEach(key => {
		if (isValueDefined(dictionary[key])) {
			outputString = outputString.replace(`{${key}}`, dictionary[key]);
		}
	});

	return outputString;
}

export const convertParametersStringToObject = (parametersString: string, context: any): any => {
	const paramsObj = Object.assign(
		{},
		...parametersString
			.split(',')
			.filter(item => isStringDefinedAndNotEmpty(item) && item.split(':').length === 2)
			.map((item) => ({ [item.split(':')[0].trim()]: item.split(':')[1].trim() }))
	);

	return Object.assign({}, ...Object.keys(paramsObj).map(item => ({ [item]: getPropertyValueFromContext(paramsObj[item], context) })));
}

export const getPropertyValueFromContext = (path: string, context: any): any => {
	return path.split('.').reduce((propertyContext, propertyName) => !isValueDefined(propertyContext) ? undefined : propertyContext[propertyName], context);
}
