import { Injector, Provider } from '@angular/core';
import { GeneClient } from '@nmn-communication/genes';
import { geneClientFactory } from './factories/gene.client.factory';

export const geneProviders: Array<Provider> = [
	{
		provide: GeneClient,
		useFactory: geneClientFactory,
		deps: [Injector]
	}
];
