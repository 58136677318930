import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Failure } from '@nmn-communication/shared';
import {
	VaccinationCardDto, VaccinationCardFindParameterDto, VaccinationClient, VaccinationCreateParameterDto,
	VaccinationDto, VaccinationFindParameterDto, VaccinationUpdateParameterDto
} from '@nmn-communication/vaccination';
import { EmptyResult, Result } from '@nmn-core/shared';
import { injectParameters } from '@nmn-core/utils';
import { Observable } from 'rxjs';
import {
	createEmptyResultFromResponse,
	createFailedEmptyResultFromErrorResponse,
	createFailedResultFromErrorResponse,
	createResultFromResponse,
	createResultFromResponseViaIdStringProjection
} from '../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { VaccinationHttpClientConfiguration } from '../configurations/vaccination.http-client.configuration';

export class VaccinationHttpClient extends VaccinationClient {

	constructor(
		private readonly configuration: VaccinationHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public getVaccinationCard(parameter: VaccinationCardFindParameterDto): Observable<Result<VaccinationCardDto, Failure>> {
		const parameters = {
			patientId: parameter.patientId
		};
		const url = injectParameters(`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/vaccinationCard`, parameters);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<VaccinationCardDto>(url, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public get(parameter: VaccinationFindParameterDto): Observable<Result<VaccinationDto, Failure>> {
		const parameters = {
			patientId: parameter.patientId,
			id: parameter.id
		};
		const url = injectParameters(`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/${parameters.id}`, parameters);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<VaccinationDto>(url, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public create(parameter: VaccinationCreateParameterDto): Observable<Result<string, Failure>> {
		const parameters = {
			patientId: parameter.patientId
		};
		const url = injectParameters(`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`, parameters);
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post<VaccinationDto>(url, payload, { headers })
			.pipe(
				createResultFromResponseViaIdStringProjection(),
				createFailedResultFromErrorResponse()
			);
	}

	public update(findParameter: VaccinationFindParameterDto, updateParameter: VaccinationUpdateParameterDto): Observable<EmptyResult<Failure>> {
		const parameters = {
			patientId: findParameter.patientId,
			id: findParameter.id
		};
		const url = injectParameters(`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/${parameters.id}`, parameters);
		const payload = updateParameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put<VaccinationDto>(url, payload, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

	public delete(parameter: VaccinationFindParameterDto): Observable<EmptyResult<Failure>> {
		const parameters = {
			patientId: parameter.patientId,
			id: parameter.id
		};
		const url = injectParameters(`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/${parameters.id}`, parameters);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.delete<VaccinationDto>(url, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

}
