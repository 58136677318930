import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TokenResolver } from '@nmn-domain/accounts';
import { Observable } from 'rxjs';

@Injectable()
export class TokenFromActivatedRouteResolver implements TokenResolver {

	public resolve(
		route: ActivatedRouteSnapshot,
		_: RouterStateSnapshot
	): Observable<string> | Promise<string> | string {
		const token = route.queryParamMap.get('token');

		// TODO: validate token and nullify if not valid

		return token;
	}

}
