import { Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { DiseaseFakeClientConfiguration } from '../configurations/disease.fake-client.configuration';
import { DiseaseFakeClient } from '../services/disease.fake-client';

export class DiseaseFakeClientBuilder {

	private configuration: DiseaseFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(
		configuration: DiseaseFakeClientConfiguration
	): DiseaseFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): DiseaseFakeClient {
		return new DiseaseFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
