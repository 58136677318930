import { Injector } from '@angular/core';
import { HealthIssueSeverityClient } from '@nmn-communication/health-issue-severities';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { HealthIssueSeverityFakeClientBuilder } from '../builders/health-issue-severity.fake-client.builder';
import { HealthIssueSeverityFakeClientConfigurationBuilder } from '../builders/health-issue-severity.fake-client.configuration.builder';
import { HealthIssueSeverityHttpClientBuilder } from '../builders/health-issue-severity.http-client.builder';
import { HealthIssueSeverityHttpClientConfigurationBuilder } from '../builders/health-issue-severity.http-client.configuration.builder';
import { HealthIssueSeverityFakeClient } from '../services/health-issue-severity.fake-client';
import { HealthIssueSeverityHttpClient } from '../services/health-issue-severity.http-client';

export const healthIssueSeverityClientFactory = (injector: Injector): HealthIssueSeverityClient => {
	const useFake = environment?.api?.resources?.healthIssueSeverities?.useFake === true ||
		!isValueDefined(environment?.api?.resources?.healthIssueSeverities?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		healthIssueSeverityFakeClientFactory(injector) :
		healthIssueSeverityHttpClientFactory(injector);
};

export const healthIssueSeverityFakeClientFactory = (
	injector: Injector
): HealthIssueSeverityFakeClient => {
	const configuration = new HealthIssueSeverityFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.healthIssueSeverities.serverDelayMs)
		.build();

	return new HealthIssueSeverityFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const healthIssueSeverityHttpClientFactory = (
	injector: Injector
): HealthIssueSeverityHttpClient => {
	const configuration = new HealthIssueSeverityHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.healthIssueSeverities?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.healthIssueSeverities.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.healthIssueSeverities?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new HealthIssueSeverityHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
