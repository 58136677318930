import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { LoginDto } from '../../accounts/login/models/login.dto';
import { PagedCollectionDto, PageOptionsDto } from '../../shared';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { PatientCreateParameter } from '../models/parameters/patient.create-parameter';
import { PatientDeleteParameter } from '../models/parameters/patient.delete-parameter';
import { PatientFindParameter } from '../models/parameters/patient.find-parameter';
import { PatientRevokeDeleteParameter } from '../models/parameters/patient.revoke-delete-parameter';
import { PatientUpdateParameter } from '../models/parameters/patient.update-parameter';
import { PatientProfileDto } from '../models/patient-profile.dto';
import { PatientDto } from '../models/patient.dto';
import { PatientFilterDto } from '../models/patient.filter.dto';

export abstract class PatientClient {

	public abstract get(parameter: PatientFindParameter): Observable<Result<PatientDto, Failure>>;

	public abstract getProfile(parameter: PatientFindParameter): Observable<Result<PatientProfileDto, Failure>>;

	public abstract getBulk(
		parameter: PageOptionsDto<PatientFilterDto>
	): Observable<Result<PagedCollectionDto<PatientDto, PatientFilterDto>, Failure>>;

	public abstract getActivePatients(): Observable<Result<Array<PatientDto>, Failure>>;

	public abstract create(parameter: PatientCreateParameter): Observable<Result<string, Failure>>;

	public abstract update(
		findParameter: PatientFindParameter,
		updateParameter: PatientUpdateParameter
	): Observable<EmptyResult<Failure>>;

	public abstract delete(parameter: PatientDeleteParameter): Observable<Result<LoginDto, Failure>>;

	public abstract revokeDelete(parameter: PatientRevokeDeleteParameter): Observable<Result<LoginDto, Failure>>;

}
