import { Injector } from '@angular/core';
import { PatientClient } from '@nmn-communication/patients';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { PatientFakeClientBuilder } from '../builders/patient.fake-client.builder';
import { PatientFakeClientConfigurationBuilder } from '../builders/patient.fake-client.configuration.builder';
import { PatientHttpClientBuilder } from '../builders/patient.http-client.builder';
import { PatientHttpClientConfigurationBuilder } from '../builders/patient.http-client.configuration.builder';
import { PatientFakeClient } from '../services/patient.fake-client';
import { PatientHttpClient } from '../services/patient.http-client';

export const patientClientFactory = (injector: Injector): PatientClient => {
	const useFake = environment?.api?.resources?.patients?.useFake ||
		!isValueDefined(environment?.api?.resources?.patients?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		patientFakeClientFactory(injector) :
		patientHttpClientFactory(injector);
};

export const patientFakeClientFactory = (injector: Injector): PatientFakeClient => {
	const configuration = new PatientFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.patients.serverDelayMs)
		.build();

	return new PatientFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const patientHttpClientFactory = (injector: Injector): PatientHttpClient => {
	const configuration = new PatientHttpClientConfigurationBuilder()
		.withBaseUrl(environment?.api?.resources?.patients?.baseUrl ?? environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.patients.resourceRelativePath)
		.withResourceTypeVersion(environment?.api?.resources?.patients?.resourceTypeVersion ?? environment?.api?.defaultResourceTypeVersion)
		.build();

	return new PatientHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
