import { isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { ComboboxModel, DescribedComboboxModel } from '@nmn-domain/shared';
import { DiseaseType } from './disease-type.enum';

export class DiseaseComboboxModel extends DescribedComboboxModel {

	public readonly type: DiseaseType;
	public readonly icd10?: string;
	public readonly icd10Group?: string;
	public readonly group?: ComboboxModel;

	public get isIcd10Defined(): boolean {
		return isStringDefinedAndNotEmpty(this.icd10);
	}

	public get isGroupDefined(): boolean {
		return isValueDefined(this.group);
	}

	constructor(
		id: string,
		displayText: string,
		description: string,
		type: DiseaseType,
		icd10: string | undefined,
		icd10Group: string | undefined,
		group: ComboboxModel | undefined
	) {
		super(id, displayText, description);

		// TODO: Guard check on defined
		this.type = type;

		this.icd10 = icd10;
		this.icd10Group = icd10Group;
		this.group = group;
	}

	public static createDisplayOptionFn(): (option: DiseaseComboboxModel) => string | undefined {
		return option => option.displayText;
	}

}
