import { HttpClientConfigurationBuilder } from '../../../../../communication/implementation/shared/builders/http-client.configuration.builder';
import { UserProfileHttpClientConfiguration } from '../configurations/user-profile.http-client.configuration';

export class UserProfileHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<UserProfileHttpClientConfiguration> {

	public build(): UserProfileHttpClientConfiguration {
		return new UserProfileHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
