export class UserProfileFormOutModel {

	public readonly isEmailNotificationsEnabled: boolean;
	public readonly isPreferedBrowserTimezone: boolean;
	public readonly picture?: string;
	public readonly locale?: string;
	public readonly timezone?: string;

	public constructor(
		isEmailNotificationsEnabled: boolean,
		isPreferedBrowserTimezone: boolean,
		picture: string | undefined,
		locale: string | undefined,
		timezone: string | undefined
	) {
		this.isEmailNotificationsEnabled = isEmailNotificationsEnabled;
		this.isPreferedBrowserTimezone = isPreferedBrowserTimezone;
		this.picture = picture;
		this.locale = locale;
		this.timezone = timezone;
	}

}
