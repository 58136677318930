import { Provider } from '@angular/core';
import { ServerConfigurationQueryHandlerService } from '@nmn-domain/server-configuration';
import { ServerConfigurationQueryHandlerViaClientService } from '../server-configuration/services/server-configuration.query-handler-via-client.service';

export const serverConfigurationProviders: Array<Provider> = [
	{
		provide: ServerConfigurationQueryHandlerService,
		useClass: ServerConfigurationQueryHandlerViaClientService
	}
];
