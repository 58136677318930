import { Provider } from '@angular/core';
import { PopulationQueryHandlerService, PopulationResolver } from '@nmn-domain/populations';
import { PopulationViaQueryHandlerResolver } from './resolvers/population.via-query-handler.resolver';
import { PopulationQueryHandlerViaClientService } from './services/population.query-handler-via-client.service';

export const populationProviders: Array<Provider> = [
	{
		provide: PopulationQueryHandlerService,
		useClass: PopulationQueryHandlerViaClientService
	},
	{
		provide: PopulationResolver,
		useClass: PopulationViaQueryHandlerResolver
	}
];
