import { FileType } from '../file-type.enum';

export class FileUploadCreateCommand {

	public readonly file: File | Blob;
	public readonly fileExtension: string;
	public readonly fileType: FileType;
	public readonly fileName: string;

	constructor(
		file: File | Blob,
		fileExtension: string,
		fileType: FileType,
		fileName: string
	) {
		// TODO: Guards
		this.file = file;
		this.fileExtension = fileExtension;
		this.fileType = fileType;
		this.fileName = fileName;
	}

}
