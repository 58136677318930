import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { FailureModel } from '@nmn-domain/shared';
import { FailureLocalizationModel } from '@nmn-domain/shared/failures/failure-localization-parameters.model';
import { FailureWrapperModel } from '@nmn-domain/shared/failures/failure-wrapper.model';
import { NavigationResource, QueryParameter, QueryParameterValueForPrivacyPolicySection } from '@nmn-middleware/navigation';
import { LocalStorageService } from '../../../../../core/application-storages';
import { FailureHandlingService } from '../../../../../services';

@Component({
	selector: 'nmn-gdpr-panel',
	templateUrl: './gdpr-panel.component.html',
	styleUrls: ['./gdpr-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class GdprPanelComponent {

	public get isShowGdprPanel(): boolean {
		return !this.localStorageService.getIsGDPRAccepted() && !this.router.url.startsWith(`/${NavigationResource.PrivacyPolicy}`);
	}

	constructor(
		private readonly router: Router,
		private readonly failureHandlingService: FailureHandlingService,
		private readonly localStorageService: LocalStorageService
	) { }

	public navigatePrivacyPolicyWithParams(queryParams): void {
		this.router
			.navigate([NavigationResource.PrivacyPolicy], { queryParams })
			.catch(error => { this.failureHandlingService.handleFailure(FailureModel.createForNavigationIssue(FailureWrapperModel.createFromValue(error), FailureLocalizationModel.createEmpty())); });
	}

	public navigateCookiePolicy(): void {
		const queryParams = {};
		queryParams[QueryParameter.Section] = QueryParameterValueForPrivacyPolicySection.CookiePolicy;
		this.navigatePrivacyPolicyWithParams(queryParams);
	}

	public acceptGdpr(): void {
		this.localStorageService.setGDPRAccept();
	}

}
