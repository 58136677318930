import { Result } from '@nmn-core/shared';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { RegionComboboxModel } from '../models/region-combobox.model';
import { RegionFilterModel } from '../models/region-filter.model';

export abstract class RegionQueryHandlerService {

	public abstract getAsComboboxes(query: RegionFilterModel): Observable<Result<Array<RegionComboboxModel>, FailureModel>>;

}
