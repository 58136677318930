import { TranslocoService } from '@ngneat/transloco';
import { RegionComboboxDto, RegionFilterDto } from '@nmn-communication/regions';
import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../databases/fake.database';
import { getPagedCollectionWithoutDtoFilter } from '../../databases/fake.utils';
import { FakeComboboxDto } from '../../models/comboboxes/fake-combox.dto';
import { FakeLocalizableEntity } from '../../models/fake-localizable-entity';
import { setTranslation } from '../../utils/localize';

export class RegionFakeTable {

	private readonly data: Array<RegionFakeRecord>;

	constructor(
		private readonly database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.data = [...initialData];
	}

	public getAsCombobox(parameter: { id: string }): RegionComboboxDto {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record was not found');
		}

		return record;
	}

	public findAsCombobox(parameter: { id: string }): RegionComboboxDto {
		const record = this.data.find((item: RegionFakeRecord) => findPredicate(item, parameter));

		return this.mapFromRecordToComboboxDto(record);
	}

	public getComboboxesPagedCollection(filter: RegionFilterDto): Array<RegionComboboxDto> {
		const pagedCollection = getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			filterPredicateForRecord,
			this.mapFromRecordToComboboxDto.bind(this),
			compareFn
		).items;

		return pagedCollection;
	}

	private mapFromRecordToComboboxDto(record: RegionFakeRecord): RegionComboboxDto {
		return isValueDefined(record) ?
			{
				id: record.id,
				displayText: setTranslation(this.translocoService, record.displayText),
				regionGroup: {
					id: record.regionGroup.id,
					displayText: setTranslation(this.translocoService, record.regionGroup.displayText)
				},
				defaultPopulation: this.database.populationTable.getAsCombobox({ id: record.defaultPopulationId })
			} :
			undefined!
	};

}

const findPredicate = (item: RegionFakeRecord, findParameter: { id: string }): boolean =>
	item.id === findParameter.id;

const filterPredicateForRecord = (item: RegionFakeRecord, filter: RegionFilterDto, translocoService: TranslocoService): boolean => {
	let result = true;

	if (result && isArrayDefinedAndNotEmpty(filter.ids)) {
		result = result && filter.ids!.some(id => item.id === id);
	}

	if (result && isArrayDefinedAndNotEmpty(filter.ignoreIds)) {
		result = result && filter.ignoreIds!.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result &&
			setTranslation(translocoService, item.displayText)
				.toLowerCase()
				.indexOf(filter.searchPattern!.toLowerCase()) >= 0;
	}

	return result;
};

// eslint-disable
// eslint-disable-next-line complexity
const compareFn = (item1: RegionComboboxDto, item2: RegionComboboxDto, sorting: string): number => {
	if (sorting === 'displayText asc') {
		return item1.displayText > item2.displayText ? 1 : item1.displayText < item2.displayText ? -1 : 0;
	} else if (sorting === 'displayText desc') {
		return item1.displayText < item2.displayText ? 1 : item1.displayText > item2.displayText ? -1 : 0;
	}

	return 0;
};
// eslint-enable

interface RegionFakeRecord {
	id: string;
	displayText: FakeLocalizableEntity;
	regionGroup: FakeComboboxDto<string>;
	defaultPopulationId: string;
}

const initialData: Array<RegionFakeRecord> = [
	{
		id: 'nld',
		displayText: {
			en: 'The Netherlands',
			uk: 'The Netherlands'
		},
		regionGroup: {
			id: 'europe-eu',
			displayText: {
				en: 'Europe (EU)',
				uk: 'Europe (EU)'
			}
		},
		defaultPopulationId: 'european'
	},
	{
		id: 'pol',
		displayText: {
			en: 'Poland',
			uk: 'Poland'
		},
		regionGroup: {
			id: 'other-europe-eu',
			displayText: {
				en: 'Europe (EU)',
				uk: 'Europe (EU)'
			}
		},
		defaultPopulationId: 'european'
	},
	{
		id: 'ukr',
		displayText: {
			en: 'Ukraine',
			uk: 'Ukraine'
		},
		regionGroup: {
			id: 'other-europe-not-eu',
			displayText: {
				en: 'Europe (not-EU)',
				uk: 'Europe (not-EU)'
			}
		},
		defaultPopulationId: 'european'
	},
	{
		id: 'gbr',
		displayText: {
			en: 'United Kingdom',
			uk: 'United Kingdom'
		},
		regionGroup: {
			id: 'europe-not-eu',
			displayText: {
				en: 'Europe (not-EU)',
				uk: 'Europe (not-EU)'
			}
		},
		defaultPopulationId: 'european'
	},
	{
		id: 'jap',
		displayText: {
			en: 'Japan',
			uk: 'Japan'
		},
		regionGroup: {
			id: 'asia',
			displayText: {
				en: 'Asia',
				uk: 'Asia'
			}
		},
		defaultPopulationId: 'central-south-asian'
	},
	{
		id: 'other-world',
		displayText: {
			en: 'Other world',
			uk: 'Other world'
		},
		regionGroup: {
			id: 'other-world',
			displayText: {
				en: 'Other world',
				uk: 'Other world'
			}
		},
		defaultPopulationId: 'european'
	}
];
