import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { DocumentGetQuery, DocumentModel, DocumentResolver, DocumentsQueryHandlerService } from '@nmn-domain/documents';
import { RouteParameter } from '@nmn-middleware/navigation';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DocumentViaQueryHandlerResolver implements DocumentResolver {

	constructor(
		private readonly documentsQueryHandlerService: DocumentsQueryHandlerService
	) { }

	public resolve(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<DocumentModel> | Promise<DocumentModel> | DocumentModel {
		const documentId = route.paramMap.get(RouteParameter.DocumentId);
		const patientAlias = route.paramMap.get(RouteParameter.PatientAlias);
		if (!isStringDefinedAndNotEmpty(documentId)) {
			return undefined;
		}

		if (!isStringDefinedAndNotEmpty(patientAlias)) {
			return undefined;
		}

		return this.documentsQueryHandlerService
			.get(DocumentGetQuery.createById(documentId, patientAlias))
			.pipe(map(result => result.successOrDefault(() => undefined)));
	}

}
