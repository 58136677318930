import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { DescribedComboboxModel } from '../../shared';

export class MedicationDosageTypeFilterModel {

	public readonly ids?: Array<string>;

	public readonly ignoreIds?: Array<string>;

	public readonly searchPattern?: string;

	constructor(
		ids: Array<string> | undefined,
		ignoreIds: Array<string> | undefined,
		searchPattern: string | undefined
	) {
		this.ids = ids;
		this.ignoreIds = ignoreIds;
		this.searchPattern = searchPattern;
	}

	public static createEmpty(): MedicationDosageTypeFilterModel {
		return new MedicationDosageTypeFilterModel(undefined, undefined, undefined);
	}

	public static createForMedicationDosageTypesAutocomplete(
		searchPattern: string,
		ignoreIds: Array<string>
	): MedicationDosageTypeFilterModel {
		return new MedicationDosageTypeFilterModel(undefined, ignoreIds, searchPattern);
	}

	public static createForMedicationDosageTypeAutocomplete(
		searchPattern: string,
		selectedItem: DescribedComboboxModel
	): MedicationDosageTypeFilterModel {
		return new MedicationDosageTypeFilterModel(
			undefined,
			isStringDefinedAndNotEmpty(selectedItem?.id) ? [selectedItem.id] : undefined,
			searchPattern
		);
	}
}
