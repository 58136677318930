export class GeneticFileParseRequestFormFailure {

	public readonly isValidationFailure: boolean;

	private constructor(
		isValidationFailure: boolean
	) {
		this.isValidationFailure = isValidationFailure;
	}

	public static createAsValidationFailure(): GeneticFileParseRequestFormFailure {
		return new GeneticFileParseRequestFormFailure(true);
	}

}
