import { isNumberDefined, isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { GoogleAnalyticsEvent } from '../../models/google-analytics-event.enum';

export class GaEventTrackingTreatmentCheckerService {

	constructor(
		private readonly trackEvent: (event: GoogleAnalyticsEvent, data?: any, completeFn?: () => void) => void
	) { }

	public treatmentCheckerReportCreated(patientGender: string | undefined, patientAge: number | undefined, completeFn?: () => void): void {
		this.trackEvent(
			GoogleAnalyticsEvent.TreatmentCheckerReportCreation,
			this.buildEventData(patientGender, patientAge),
			completeFn
		);
	}

	public treatmentCheckerReportExportedAsPdf(patientGender: string | undefined, patientAge: number | undefined, completeFn?: () => void): void {
		this.trackEvent(
			GoogleAnalyticsEvent.TreatmentCheckerReportExportedAsPdf,
			this.buildEventData(patientGender, patientAge),
			completeFn
		);
	}

	private buildEventData(patientGender: string | undefined, patientAge: number | undefined): any {
		return {
			nmn__patient_gender: isStringDefinedAndNotEmpty(patientGender) ? patientGender : 'Undefined',
			nmn__patient_age: isNumberDefined(patientAge) ? patientAge : undefined
		};
	}

}
