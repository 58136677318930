import { DiseaseComboboxModel } from '@nmn-domain/diseases';
import { MedicationComboboxModel } from '@nmn-domain/medications';
import { PatientModel } from '@nmn-domain/patients/models/patient.model';
import { PopulationComboboxModel } from '@nmn-domain/populations';
import { DescribedComboboxModel } from '@nmn-domain/shared';

export class TreatmentCheckerOneStepFlowFormInModel {

	public readonly email?: string;
	public readonly diseases: Array<DiseaseComboboxModel>;
	public readonly medications: Array<MedicationComboboxModel>;
	public readonly population?: PopulationComboboxModel;
	public readonly gender?: DescribedComboboxModel;
	public readonly age?: number;
	public readonly weight?: number;
	public readonly height?: number;

	private constructor(
		email: string | undefined,
		diseases: Array<DiseaseComboboxModel>,
		medications: Array<MedicationComboboxModel>,
		population: PopulationComboboxModel | undefined,
		gender: DescribedComboboxModel | undefined,
		age: number | undefined,
		weight: number | undefined,
		height: number | undefined
	) {
		this.email = email;
		this.diseases = diseases;
		this.medications = medications;
		this.population = population;
		this.gender = gender;
		this.age = age;
		this.weight = weight;
		this.height = height;
	}

	public static createEmpty = (): TreatmentCheckerOneStepFlowFormInModel => {
		return new TreatmentCheckerOneStepFlowFormInModel(
			undefined,
			[],
			[],
			undefined,
			undefined,
			undefined,
			undefined,
			undefined
		);
	}

	public static createByPatient = (patient: PatientModel): TreatmentCheckerOneStepFlowFormInModel => {
		return new TreatmentCheckerOneStepFlowFormInModel(
			undefined,
			undefined,
			undefined,
			patient.population,
			patient.gender,
			patient.age,
			patient.weight,
			patient.height
		);
	}

	public static createForPredefinedData = (
		email: string | undefined,
		diseases: Array<DiseaseComboboxModel>,
		medications: Array<MedicationComboboxModel>,
		population: PopulationComboboxModel | undefined,
		gender: DescribedComboboxModel | undefined,
		age: number | undefined,
		weight: number | undefined,
		height: number | undefined
	): TreatmentCheckerOneStepFlowFormInModel => {
		return new TreatmentCheckerOneStepFlowFormInModel(email, diseases, medications, population, gender, age, weight, height);
	}

}
