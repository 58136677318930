import { ServerConfigurationClient, ServerConfigurationDto } from '@nmn-communication/server-configuration';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { wrapFuncCallAsFakeClientResponse } from '../../../../modules/fakes/databases/fake.utils';
import { ServerConfigurationFakeClientConfiguration } from '../configurations/server-configuration-fake-client.configuration';

export class ServerConfigurationFakeClient extends ServerConfigurationClient {

	constructor(
		private readonly configuration: ServerConfigurationFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public get(): Observable<Result<ServerConfigurationDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.serverConfigurationTable.get(),
			this.configuration.serverDelayMs
		);
	}

}
