import { Injector } from '@angular/core';
import { VaccinationClient } from '@nmn-communication/vaccination';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { VaccinationFakeClientBuilder } from '../builders/vaccination.fake-client.builder';
import { VaccinationFakeClientConfigurationBuilder } from '../builders/vaccination.fake-client.configuration.builder';
import { VaccinationHttpClientBuilder } from '../builders/vaccination.http-client.builder';
import { VaccinationHttpClientConfigurationBuilder } from '../builders/vaccination.http-client.configuration.builder';
import { VaccinationFakeClient } from '../services/vaccination.fake-client';
import { VaccinationHttpClient } from '../services/vaccination.http-client';

export const vaccinationClientFactory = (injector: Injector): VaccinationClient => {
	const useFake = environment?.api?.resources?.vaccination?.useFake ||
		!isValueDefined(environment?.api?.resources?.vaccination?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		vaccinationFakeClientFactory(injector) :
		vaccinationHttpClientFactory(injector);
};

export const vaccinationFakeClientFactory = (injector: Injector): VaccinationFakeClient => {
	const configuration = new VaccinationFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.vaccination.serverDelayMs)
		.build();

	return new VaccinationFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const vaccinationHttpClientFactory = (injector: Injector): VaccinationHttpClient => {
	const configuration = new VaccinationHttpClientConfigurationBuilder()
		.withBaseUrl(environment?.api?.resources?.vaccination?.baseUrl ?? environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.vaccination.resourceRelativePath)
		.withResourceTypeVersion(environment?.api?.resources?.vaccination?.resourceTypeVersion ?? environment?.api?.defaultResourceTypeVersion)
		.build();

	return new VaccinationHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
