import { isArrayDefined } from '@nmn-core/utils';
import { DiseaseComboboxModel } from '@nmn-domain/diseases';
import { MedicationComboboxModel } from '@nmn-domain/medications';
import { DescribedComboboxModel } from '@nmn-domain/shared';
import { AllergyIntoleranceFormInModel } from '../allergy-intolerance-form/allergy-intolerance-form.in-model';
import { PatientModel } from '../patient.model';

export class PatientMedicalInfoFormInModel {

	public readonly height: number;
	public readonly weight: number;
	public readonly bloodType: DescribedComboboxModel;
	public readonly allergyIntolerances: Array<AllergyIntoleranceFormInModel>;
	public readonly seriousPathologies: Array<DiseaseComboboxModel>;
	public readonly familyDiseases: Array<DiseaseComboboxModel>;
	public readonly regularlyTakenMedications: Array<MedicationComboboxModel>;

	public readonly isActiveLifestyle?: boolean;
	public readonly isAlcohol?: boolean;
	public readonly isOnHealthyDiet?: boolean;
	public readonly isSmoking?: boolean;
	public readonly isUnderHighLevelOfStress?: boolean;
	public readonly isUsingNutritionalSupplements?: boolean;

	public constructor(
		height: number | undefined,
		weight: number | undefined,
		bloodType: DescribedComboboxModel | undefined,
		allergyIntolerances: Array<AllergyIntoleranceFormInModel>,
		seriousPathologies: Array<DiseaseComboboxModel>,
		familyDiseases: Array<DiseaseComboboxModel>,
		regularlyTakenMedications: Array<MedicationComboboxModel>,
		isActiveLifestyle: boolean | undefined,
		isAlcohol: boolean | undefined,
		isOnHealthyDiet: boolean | undefined,
		isSmoking: boolean | undefined,
		isUnderHighLevelOfStress: boolean | undefined,
		isUsingNutritionalSupplements: boolean | undefined
	) {
		// TODO: Guard check arrays on defined
		this.height = height;
		this.weight = weight;
		this.bloodType = bloodType;
		this.allergyIntolerances = allergyIntolerances;
		this.seriousPathologies = seriousPathologies;
		this.familyDiseases = familyDiseases;
		this.regularlyTakenMedications = regularlyTakenMedications;
		this.isActiveLifestyle = isActiveLifestyle;
		this.isAlcohol = isAlcohol;
		this.isOnHealthyDiet = isOnHealthyDiet;
		this.isSmoking = isSmoking;
		this.isUnderHighLevelOfStress = isUnderHighLevelOfStress;
		this.isUsingNutritionalSupplements = isUsingNutritionalSupplements;
	}

	public static createEmpty = (): PatientMedicalInfoFormInModel => {
		return new PatientMedicalInfoFormInModel(
			undefined,
			undefined,
			undefined,
			[],
			[],
			[],
			[],
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined
		);
	}

	public static createByPatient = (patient: PatientModel): PatientMedicalInfoFormInModel => {
		return new PatientMedicalInfoFormInModel(
			patient?.height,
			patient?.weight,
			patient?.bloodType,
			isArrayDefined(patient?.allergyIntolerances) ?
				patient.allergyIntolerances.map(AllergyIntoleranceFormInModel.createViaAllergyIntolerance) :
				[],
			isArrayDefined(patient?.seriousPathologies) ? patient.seriousPathologies : [],
			isArrayDefined(patient?.familyDiseases) ? patient.familyDiseases : [],
			isArrayDefined(patient?.regularlyTakenMedications) ? patient.regularlyTakenMedications : [],
			patient.lifestyle.isActiveLifestyle,
			patient.lifestyle.isAlcohol,
			patient.lifestyle.isOnHealthyDiet,
			patient.lifestyle.isSmoking,
			patient.lifestyle.isUnderHighLevelOfStress,
			patient.lifestyle.isUsingNutritionalSupplements
		);
	}

}
