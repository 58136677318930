import { Injector } from '@angular/core';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { isValueDefined } from '@nmn-core/utils';
import { MedicationAdmRouteFakeClientConfiguration } from '../configurations/medication-adm-route.fake-client.configuration';
import { MedicationAdmRouteFakeClient } from '../services/medication-adm-route.fake-client';

export class MedicationAdmRouteFakeClientBuilder {

	private configuration: MedicationAdmRouteFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(
		configuration: MedicationAdmRouteFakeClientConfiguration
	): MedicationAdmRouteFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): MedicationAdmRouteFakeClient {
		return new MedicationAdmRouteFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
