import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { CurrentPatientGeneticCardResolver, GeneticCardGetQuery, GeneticCardModel, GeneticTestQueryHandlerService } from '@nmn-domain/genetic-tests';
import { RouteParameter } from '@nmn-middleware/navigation';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CurrentPatientGeneticCardViaQueryHandlerResolver extends CurrentPatientGeneticCardResolver {

	constructor(
		private readonly geneticTestQueryHandlerService: GeneticTestQueryHandlerService
	) {
		super();
	}

	public resolve(
		activatedRoute: ActivatedRouteSnapshot,
		_: RouterStateSnapshot
	): Observable<GeneticCardModel> | Promise<GeneticCardModel> | GeneticCardModel {
		const patientId = activatedRoute.paramMap.get(RouteParameter.PatientAlias);

		if (!isStringDefinedAndNotEmpty(patientId)) {
			return undefined;
		}

		return this.geneticTestQueryHandlerService
			.handleGeneticCardGetQuery(GeneticCardGetQuery.createByPatientId(patientId))
			.pipe(
				map(result => result.successOrDefault(() => undefined))
			);
	}

}
