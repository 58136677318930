import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { MedicationHttpClientConfiguration } from '../configurations/medication.http-client.configuration';

export class MedicationHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<MedicationHttpClientConfiguration> {

	public build(): MedicationHttpClientConfiguration {
		return new MedicationHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
