import { diffDateInMs, isArrayDefinedAndNotEmpty } from '@nmn-core/utils';
import { GeneComboboxModel } from '@nmn-domain/genes';
import { GeneticFileParseRequestModel } from '../../genetic-file-parse-requests/models/genetic-file-parse-request.model';
import { GeneticTestModel } from './genetic-test.model';

export class GeneticCardModel {

	public readonly patientId: string;
	public readonly geneticTests: Array<GeneticTestModel>;
	public readonly geneticFileParseRequest: GeneticFileParseRequestModel | undefined;

	public get isGeneticCardEmpty(): boolean {
		return !isArrayDefinedAndNotEmpty(this.geneticTests);
	}

	public get years(): Array<number> {
		return [...new Set(this.geneticTests.map(geneticTest => geneticTest.year))]
			.sort((a, b) => b - a);
	}

	public get genes(): Array<GeneComboboxModel> {
		const genes = this.geneticTests.map(geneticTest => geneticTest.gene);

		return [...new Set(genes.map(gene => gene.id))]
			.map(geneId => genes.find(gene => gene.id === geneId))
			.sort((combobox1, combobox2) => combobox1.displayText.localeCompare(combobox2.displayText));
	}

	constructor(
		patientId: string,
		geneticTests: Array<GeneticTestModel>,
		geneticFileParseRequest: GeneticFileParseRequestModel | undefined
	) {
		// TODO: Guard check on defined

		if (geneticTests.length !== 0) {
			// TODO: Guard check on consistent model
			const patientIds = [...new Set(geneticTests.map(geneticTest => geneticTest.patientId))];
			if (patientIds.length !== 1 || patientIds[0] !== patientId) {
				throw new Error('Inconsistent model');
			}
		}

		this.patientId = patientId;
		this.geneticTests = geneticTests;
		this.geneticFileParseRequest = geneticFileParseRequest;
	}

	public geneticTestsByYear(year: number): Array<GeneticTestModel> {
		return this.geneticTests
			.filter(item => item.year === year)
			.sort((a, b) => -diffDateInMs(a.date, b.date));
	}

	public geneticTestsByGeneId(geneId: string): Array<GeneticTestModel> {
		return this.geneticTests
			.filter(item => item.gene.id === geneId)
			.sort((a, b) => -diffDateInMs(a.date, b.date));
	}

}
