import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TreatmentCheckerFormInModel } from '../models/treatment-checker-form/treatment-checker-form.in-model';

export abstract class TreatmentCheckerFormInModelResolver implements Resolve<TreatmentCheckerFormInModel> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	): Observable<TreatmentCheckerFormInModel> | TreatmentCheckerFormInModel;

}
