import { TranslocoService } from '@ngneat/transloco';
import { AllergyIntoleranceCriticalityFilterDto, AllergyIntoleranceCriticalityFindParameterDto } from '@nmn-communication/allergy-intolerance-criticalities';
import { DescribedComboboxDto } from '@nmn-communication/shared';
import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../databases/fake.database';
import { getPagedCollectionWithoutDtoFilter } from '../../databases/fake.utils';
import { FakeDescribedComboboxDto, mapFakeDescriptedComboboxDtoToDesriptedComboboxDto } from '../../models/comboboxes/fake-described-combobox.dto';
import { FakeLocalizableEntity } from '../../models/fake-localizable-entity';
import { setTranslation } from '../../utils/localize';

export class AllergyIntoleranceCriticalityFakeTable {

	private readonly data: Array<AllergyIntoleranceCriticalityFakeRecord>;

	constructor(
		_: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.data = [...initialData];
	}

	public getAsCombobox(
		parameter: AllergyIntoleranceCriticalityFindParameterDto
	): DescribedComboboxDto<string> {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record was not found');
		}

		return record;
	}

	public findAsCombobox(
		parameter: AllergyIntoleranceCriticalityFindParameterDto
	): DescribedComboboxDto<string> {
		const record = this.data
			.find((item: AllergyIntoleranceCriticalityFakeRecord) => findPredicate(item, parameter));

		const comboboxRecord = mapFromRecordToFakeDescribedComboboxDto(record!);
		return mapFakeDescriptedComboboxDtoToDesriptedComboboxDto(
			this.translocoService,
			comboboxRecord!);
	}

	public getComboboxesPagedCollection(
		filter: AllergyIntoleranceCriticalityFilterDto
	): Array<DescribedComboboxDto<string>> {
		const pagedCollection = getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			filterPredicateForRecord,
			mapFromRecordToFakeDescribedComboboxDto,
			compareFn
		).items;

		return pagedCollection.map((item) => mapFakeDescriptedComboboxDtoToDesriptedComboboxDto(this.translocoService, item));
	}

}

const mapFromRecordToFakeDescribedComboboxDto = (
	record: AllergyIntoleranceCriticalityFakeRecord
): FakeDescribedComboboxDto<string> => (
	isValueDefined(record) ?
		{
			id: record!.id,
			displayText: record!.name,
			description: record!.description
		} :
		undefined!
);

const findPredicate = (
	item: AllergyIntoleranceCriticalityFakeRecord,
	findParameter: AllergyIntoleranceCriticalityFindParameterDto
): boolean =>
	item.id === findParameter.id;

const filterPredicateForRecord = (
	item: AllergyIntoleranceCriticalityFakeRecord,
	filter: AllergyIntoleranceCriticalityFilterDto,
	translocoService: TranslocoService
): boolean => {
	let result = true;

	if (result && isArrayDefinedAndNotEmpty(filter.ids)) {
		result = result && filter.ids!.some(id => item.id === id);
	}

	if (result && isArrayDefinedAndNotEmpty(filter.ignoreIds)) {
		result = result && filter.ignoreIds!.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result &&
			setTranslation(translocoService, item.name)
				.toLowerCase()
				.indexOf(filter.searchPattern!.toLowerCase()) >= 0;
	}

	return result;
};

// eslint-disable
// eslint-disable-next-line complexity
const compareFn = (
	item1: FakeDescribedComboboxDto<string>,
	item2: FakeDescribedComboboxDto<string>,
	sorting: string
): number => {
	if (sorting === 'displayText asc') {
		return item1.displayText > item2.displayText ? 1 : item1.displayText < item2.displayText ? -1 : 0;
	} else if (sorting === 'displayText desc') {
		return item1.displayText < item2.displayText ? 1 : item1.displayText > item2.displayText ? -1 : 0;
	}

	return 0;
};
// eslint-enable

interface AllergyIntoleranceCriticalityFakeRecord {
	id: string;
	name: FakeLocalizableEntity;
	description: FakeLocalizableEntity;
	order: number;
}

const initialData: Array<AllergyIntoleranceCriticalityFakeRecord> = [
	{
		id: 'low',
		name: {
			en: 'Low Risk',
			uk: 'Низький ризик'
		},
		description: {
			en: 'Worst case result of a future exposure is not assessed to be life-threatening or having high potential for organ system failure.',
			uk: 'Найгірший результат майбутнього опромінення не оцінюється як такий, що загрожує життю або має високий потенціал для збою системи органів.'
		},
		order: 1
	},
	{
		id: 'high',
		name: {
			en: 'High Risk',
			uk: 'Високий ризик'
		},
		description: {
			en: 'Worst case result of a future exposure is assessed to be life-threatening or having high potential for organ system failure.',
			uk: 'Найгірший результат майбутнього опромінення оцінюється як такий, що загрожує життю або має високий потенціал для відмови системи органів.'
		},
		order: 2
	},
	{
		id: 'unable-to-assess',
		name: {
			en: 'Unable to Assess Risk',
			uk: 'Неможливо оцінити ризик'
		},
		description: {
			en: 'Unable to assess the worst case result of a future exposure.',
			uk: 'Неможливо оцінити найгірший результат майбутнього опромінення.'
		},
		order: 3
	}
];
