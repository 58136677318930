import { Provider } from '@angular/core';
import { VaccineQueryHandlerService } from '@nmn-domain/vaccines';
import { VaccineQueryHandlerViaClientService } from './services/vaccine.query-handler-via-client.service';

export const vaccineProviders: Array<Provider> = [
	{
		provide: VaccineQueryHandlerService,
		useClass: VaccineQueryHandlerViaClientService
	}
];
