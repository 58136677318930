import { notProvided } from '../../constants';

export class StorageServicesConfiguration {

	public readonly storage: string;

	constructor(
		storage: string
	) {
		this.storage = storage;
	}

	public static createDefault(): StorageServicesConfiguration {
		return new StorageServicesConfiguration(notProvided);
	}

}
