import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { PagedCollectionModel, PageOptionsQuery } from '../../shared';
import { FailureModel } from '../../shared/failures/failure.model';
import { DocumentDescribedComboboxModel } from '../models/document-described-combobox.model';
import { DocumentFilterModel } from '../models/document-filter.model';
import { DocumentModel } from '../models/document.model';
import { DocumentGetQuery } from '../models/queries/document.get.query';

export abstract class DocumentsQueryHandlerService {

	public abstract getAsDescribedComboboxes(
		filter: DocumentFilterModel
	): Observable<Result<Array<DocumentDescribedComboboxModel>, FailureModel>>;

	public abstract getPagedCollection(
		query: PageOptionsQuery<DocumentFilterModel>
	): Observable<Result<PagedCollectionModel<DocumentModel, DocumentFilterModel>, FailureModel>>;

	public abstract get(
		query: DocumentGetQuery
	): Observable<Result<DocumentModel, FailureModel>>;

}
