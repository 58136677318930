import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { ServerConfigurationHttpClientConfiguration } from '../configurations/server-configuration.http-client.configuration';

export class ServerConfigurationHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<ServerConfigurationHttpClientConfiguration> {

	public build(): ServerConfigurationHttpClientConfiguration {
		return new ServerConfigurationHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
