import { Result } from '@nmn-core/shared';
import { EmptyCommandResult, FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { ConfirmEmailCommand } from '../models/commands/confirm-email.command';
import { InitiateEmailConfirmationCommand } from '../models/commands/initiate-email-confirmation.command';

export abstract class EmailConfirmationCommandHandlerService {

	public abstract confirmEmail(command: ConfirmEmailCommand): Observable<Result<EmptyCommandResult<ConfirmEmailCommand>, FailureModel>>;

	public abstract initiateEmailConfirmation(command: InitiateEmailConfirmationCommand): Observable<Result<EmptyCommandResult<InitiateEmailConfirmationCommand>, FailureModel>>;

}
