import {
	GeneticCardDto, GeneticCardFindParameterDto, GeneticTestClient, GeneticTestCreateParameterDto, GeneticTestDeleteBulkParameter,
	GeneticTestDto, GeneticTestFilterDto, GeneticTestFindParameterDto, GeneticTestUpdateParameterDto
} from '@nmn-communication/genetic-tests';
import { Failure, PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { wrapActionCallAsFakeClientResponse, wrapFuncCallAsFakeClientResponse } from '../../../../modules/fakes/databases/fake.utils';
import { GeneticTestFakeClientConfiguration } from '../configurations/genetic-test.fake-client.configuration';

export class GeneticTestFakeClient extends GeneticTestClient {

	constructor(
		private readonly configuration: GeneticTestFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getGeneticCard(parameter: GeneticCardFindParameterDto): Observable<Result<GeneticCardDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.geneticTestsTable.getGeneticCard(parameter),
			this.configuration.serverDelayMs
		);
	}

	public get(parameter: GeneticTestFindParameterDto): Observable<Result<GeneticTestDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.geneticTestsTable.get(parameter),
			this.configuration.serverDelayMs
		);
	}

	public getBulk(parameter: PageOptionsDto<GeneticTestFilterDto>): Observable<Result<PagedCollectionDto<GeneticTestDto, GeneticTestFilterDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.geneticTestsTable.getPagedCollection(parameter),
			this.configuration.serverDelayMs
		);
	}

	public create(parameter: GeneticTestCreateParameterDto): Observable<Result<string, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.geneticTestsTable.create(parameter),
			this.configuration.serverDelayMs
		);
	}

	public update(findParameter: GeneticTestFindParameterDto, updateParameter: GeneticTestUpdateParameterDto): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.geneticTestsTable.update(findParameter, updateParameter);
			},
			this.configuration.serverDelayMs
		);
	}

	public delete(parameter: GeneticTestFindParameterDto): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.geneticTestsTable.delete(parameter);
			},
			this.configuration.serverDelayMs
		);
	}

	public deleteBulk(parameter: GeneticTestDeleteBulkParameter): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.geneticTestsTable.deleteBulk(parameter);
			},
			this.configuration.serverDelayMs
		);
	}

}
