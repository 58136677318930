import { GaAuthMethod } from '../../models/ga-auth-method.enum';
import { GoogleAnalyticsEvent } from '../../models/google-analytics-event.enum';

export class GaEventTrackingAccountService {

	constructor(
		private readonly trackEvent: (event: GoogleAnalyticsEvent, data?: any, completeFn?: () => void) => void
	) { }

	public loggedInViaNative(completeFn?: () => void): void {
		this.loggedIn(GaAuthMethod.Native, completeFn);
	}

	public loggedInViaGoogle(completeFn?: () => void): void {
		this.loggedIn(GaAuthMethod.Google, completeFn);
	}

	public loggedInViaFacebook(completeFn?: () => void): void {
		this.loggedIn(GaAuthMethod.Facebook, completeFn);
	}

	public registeredViaNative(completeFn?: () => void): void {
		this.registered(GaAuthMethod.Native, completeFn);
	}

	public registeredViaGoogle(completeFn?: () => void): void {
		this.registered(GaAuthMethod.Google, completeFn);
	}

	public registeredViaFacebook(completeFn?: () => void): void {
		this.registered(GaAuthMethod.Facebook, completeFn);
	}

	public accountDeletionRequested(completeFn?: () => void): void {
		this.trackEvent(GoogleAnalyticsEvent.AccountDeletionRequested, {}, completeFn);
	}

	public accountDeletionRevoked(completeFn?: () => void): void {
		this.trackEvent(GoogleAnalyticsEvent.AccountDeletionRevoked, {}, completeFn);
	}

	public accountAttachedNative(completeFn?: () => void): void {
		this.accountAttached(GaAuthMethod.Native, completeFn);
	}

	public accountAttachedGoogle(completeFn?: () => void): void {
		this.accountAttached(GaAuthMethod.Google, completeFn);
	}

	public accountAttachedFacebook(completeFn?: () => void): void {
		this.accountAttached(GaAuthMethod.Facebook, completeFn);
	}

	private loggedIn(method: GaAuthMethod, completeFn?: () => void): void {
		this.trackEvent(GoogleAnalyticsEvent.Login, { nmn__auth_method: method }, completeFn);
	}

	private registered(method: GaAuthMethod, completeFn?: () => void): void {
		this.trackEvent(GoogleAnalyticsEvent.Registration, { nmn__auth_method: method }, completeFn);
	}

	private accountAttached(method: GaAuthMethod, completeFn?: () => void): void {
		this.trackEvent(GoogleAnalyticsEvent.AccountAttached, { nmn__auth_method: method }, completeFn);
	}

}
