import { isValueDefined } from '@nmn-core/utils';
import { SubscriptionComboboxModel, SubscriptionType } from '@nmn-domain/subscriptions';

export class UserSubscriptionModel {

	public readonly subscription: SubscriptionComboboxModel;

	public readonly dateFrom: Date;
	public readonly dateTo: Date;

	public readonly treatmentChecksUsed: number;
	public readonly treatmentChecksAvailableTotal: number;

	public readonly patientsUsed: number;
	public readonly patientsAvailableTotal: number;

	public readonly storageBytesUsed: number;
	public readonly storageBytesAvailableTotal: number;

	public get isPremium(): boolean {
		return isValueDefined(this.subscription.subscriptionType) &&
			(this.subscription.subscriptionType === SubscriptionType.Premium
				|| this.subscription.subscriptionType === SubscriptionType.Corporate);
	}

	public get isBasic(): boolean {
		return isValueDefined(this.subscription.subscriptionType) &&
			(this.subscription.subscriptionType === SubscriptionType.Basic);
	}

	public get isDateFromDefined(): boolean {
		return isValueDefined(this.dateFrom);
	}

	public get isDateToDefined(): boolean {
		return isValueDefined(this.dateTo);
	}

	public get remainingPatientsToCreate(): number {
		let left = this.patientsAvailableTotal - this.patientsUsed;
		if (left < 0) {
			left = 0;
		}

		return left;
	}

	public get isTreatmentChecksLeftUnlimited(): boolean {
		if (isValueDefined(this.treatmentChecksAvailableTotal)) {
			return false;
		}

		return true;
	}

	public get isPatientsLeftUnlimited(): boolean {
		if (isValueDefined(this.patientsAvailableTotal)) {
			return false;
		}

		return true;
	}

	public get remainingTreatmentChecksToCreate(): number {
		let left = this.treatmentChecksAvailableTotal - this.treatmentChecksUsed;
		if (left < 0) {
			left = 0;
		}

		return left;
	}

	public get subscriptionDisplayText(): string {
		return this.subscription.displayText
	}

	public constructor(
		subscription: SubscriptionComboboxModel,
		dateFrom: Date,
		dateTo: Date,
		treatmentChecksUsed: number,
		treatmentChecksAvailableTotal: number,
		patientsUsed: number,
		patientsAvailableTotal: number,
		storageBytesUsed: number,
		storageBytesAvailableTotal: number
	) {
		this.subscription = subscription;
		this.dateFrom = dateFrom;
		this.dateTo = dateTo;
		this.treatmentChecksUsed = treatmentChecksUsed;
		this.treatmentChecksAvailableTotal = treatmentChecksAvailableTotal;
		this.patientsUsed = patientsUsed;
		this.patientsAvailableTotal = patientsAvailableTotal;
		this.storageBytesUsed = storageBytesUsed;
		this.storageBytesAvailableTotal = storageBytesAvailableTotal;
	}

}
