import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MedicationBodySiteFilterModel, MedicationBodySiteQueryHandlerService, MedicationBodySitesResolver } from '@nmn-domain/medication-body-sites';
import { DescribedComboboxModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MedicationBodySitesViaQueryHandlerResolver extends MedicationBodySitesResolver {

	constructor(
		private readonly queryHandlerService: MedicationBodySiteQueryHandlerService
	) {
		super();
	}

	public resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<Array<DescribedComboboxModel>> | Array<DescribedComboboxModel> {
		return this.queryHandlerService
			.getAsDescribedComboboxes(MedicationBodySiteFilterModel.createEmpty())
			.pipe(map(result => result.successOrDefault(() => [])));
	}

}
