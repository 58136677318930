import { ComboboxModel } from '@nmn-domain/shared';
import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { TreatmentCheckerReportInteractionCriticalityType } from './treatment-checker-report-interaction-criticality-type.enum';
import { TreatmentCheckerReportInteractionType } from './treatment-checker-report-interaction-type.enum';

export class TreatmentCheckerReportInteractionReportModel {

	public readonly type: TreatmentCheckerReportInteractionType;
	public readonly criticality: TreatmentCheckerReportInteractionCriticalityType;
	public readonly description?: string;
	public readonly entity1: ComboboxModel;
	public readonly entity2: ComboboxModel;

	public get isDescriptionDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.description);
	}

	public constructor(
		type: TreatmentCheckerReportInteractionType,
		criticality: TreatmentCheckerReportInteractionCriticalityType,
		description: string | undefined,
		entity1: ComboboxModel,
		entity2: ComboboxModel
	) {
		// TODO: Guard check
		this.type = type;
		this.criticality = criticality;
		this.description = description;
		this.entity1 = entity1;
		this.entity2 = entity2;
	}

}
