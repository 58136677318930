import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FileUploaderClient, UploadedFileDto, UploadFileCancelParameter, UploadFileCompleteParameter, UploadFileParameter } from '@nmn-communication/file-uploader';
import { Failure } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import {
	createEmptyResultFromResponse,
	createFailedEmptyResultFromErrorResponse,
	createFailedResultFromErrorResponse,
	createResultFromResponse
} from '../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { FileUploaderHttpClientConfiguration } from '../configurations/file-uploader-http-client.configuration';

export class FileUploaderHttpClient extends FileUploaderClient {

	constructor(
		private readonly configuration: FileUploaderHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public initiateUploadFile(
		parameter: UploadFileParameter
	): Observable<Result<UploadedFileDto, Failure>> {
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/initiate`;

		return this.httpClient
			.post<UploadedFileDto>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public completeUploadFile(
		parameter: UploadFileCompleteParameter
	): Observable<Result<UploadedFileDto, Failure>> {
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/complete`;
		const payload = parameter;

		return this.httpClient
			.put<UploadedFileDto>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public cancelUploadFile(parameter: UploadFileCancelParameter): Observable<EmptyResult<Failure>> {
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/cancel`;
		const payload = parameter;

		return this.httpClient
			.put(url, payload, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

}
