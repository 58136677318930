import { NgModule } from '@angular/core';
import { ApplicationLanguageService } from './application-language';
import { ApplicationRegionService } from './application-region';
import { ApplicationThemeService } from './application-theme';
import { ApplicationTitleService } from './application-title';

@NgModule({
	declarations: [],
	providers: [
		ApplicationLanguageService,
		ApplicationRegionService,
		ApplicationThemeService,
		ApplicationTitleService
	],
	imports: []
})
export class BackgroundModule { }
