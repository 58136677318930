import { ClinicModel } from '@nmn-domain/clinics';
import { DoctorModel } from '@nmn-domain/doctors';
import { DocumentCreateCommand } from '@nmn-domain/documents';
import { TakenMedicationCreateCommand } from '@nmn-domain/taken-medications';
import { DoctorEncounterStatus } from '../doctor-encounter-status.enum';
import { DoctorEncounterFormOutModel } from '../form/doctor-encounter-form.out-model';

export class DoctorEncounterUpdateCommand {

	public readonly id: string;
	public readonly patientId: string;
	public readonly diseaseIds: Array<string>;
	public readonly comment?: string;
	public readonly date?: Date;
	public readonly status: DoctorEncounterStatus;
	public readonly doctor?: DoctorModel;
	public readonly clinic?: ClinicModel;
	public readonly healthIssueId?: string;
	public readonly takenMedicationIds: Array<string>;
	public readonly takenMedications: Array<TakenMedicationCreateCommand>;
	public readonly documentIds: Array<string>;
	public readonly documents: Array<DocumentCreateCommand>;

	constructor(
		id: string,
		patientId: string,
		diseaseIds: Array<string>,
		comment: string | undefined,
		date: Date | undefined,
		status: DoctorEncounterStatus,
		doctor: DoctorModel | undefined,
		clinic: ClinicModel | undefined,
		healthIssueId: string | undefined,
		takenMedicationIds: Array<string>,
		takenMedications: Array<TakenMedicationCreateCommand>,
		documentIds: Array<string>,
		documents: Array<DocumentCreateCommand>
	) {
		// TODO: Guard check on defined
		this.id = id;
		this.patientId = patientId;
		this.diseaseIds = diseaseIds;
		this.comment = comment;
		this.date = date;
		this.status = status;
		this.doctor = doctor;
		this.clinic = clinic;
		this.healthIssueId = healthIssueId;
		this.takenMedicationIds = takenMedicationIds;
		this.takenMedications = takenMedications;
		this.documentIds = documentIds;
		this.documents = documents;
	}

	public static createByFormOutModel(id: string, formOutModel: DoctorEncounterFormOutModel): DoctorEncounterUpdateCommand {
		return new DoctorEncounterUpdateCommand(
			id,
			formOutModel.patientId,
			formOutModel.diseaseIds,
			formOutModel.comment,
			formOutModel.date,
			formOutModel.status,
			formOutModel.doctor,
			formOutModel.clinic,
			formOutModel.healthIssueId,
			formOutModel.takenMedicationIds,
			formOutModel.takenMedications.map(TakenMedicationCreateCommand.createByFormOutModel),
			formOutModel.documentIds,
			formOutModel.documents.map(DocumentCreateCommand.createByFormOutModel)
		);
	}

}
