export class PatientFilterModel {

	public readonly ids?: Array<string>;

	public readonly searchPattern?: string;

	constructor(
		ids: Array<string> | null,
		searchPattern: string | null
	) {

		this.ids = ids;
		this.searchPattern = searchPattern;

	}

}
