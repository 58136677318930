import { Injector } from '@angular/core';
import { MedicationDosageTypeClient } from '@nmn-communication/medication-dosage-types';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { MedicationDosageTypeFakeClientBuilder } from '../builders/medication-dosage-type.fake-client.builder';
import { MedicationDosageTypeFakeClientConfigurationBuilder } from '../builders/medication-dosage-type.fake-client.configuration.builder';
import { MedicationDosageTypeHttpClientBuilder } from '../builders/medication-dosage-type.http-client.builder';
import { MedicationDosageTypeHttpClientConfigurationBuilder } from '../builders/medication-dosage-type.http-client.configuration.builder';
import { MedicationDosageTypeFakeClient } from '../services/medication-dosage-type.fake-client';
import { MedicationDosageTypeHttpClient } from '../services/medication-dosage-type.http-client';

export const medicationDosageTypeClientFactory = (injector: Injector): MedicationDosageTypeClient => {
	const useFake = environment?.api?.resources?.medicationDosageTypes?.useFake ||
		!isValueDefined(environment?.api?.resources?.medicationDosageTypes?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		medicationDosageTypeFakeClientFactory(injector) :
		medicationDosageTypeHttpClientFactory(injector);
};

export const medicationDosageTypeFakeClientFactory = (
	injector: Injector
): MedicationDosageTypeFakeClient => {
	const configuration = new MedicationDosageTypeFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.medicationDosageTypes.serverDelayMs)
		.build();

	return new MedicationDosageTypeFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const medicationDosageTypeHttpClientFactory = (
	injector: Injector
): MedicationDosageTypeHttpClient => {
	const configuration = new MedicationDosageTypeHttpClientConfigurationBuilder()
		.withBaseUrl(environment?.api?.resources?.medicationDosageTypes?.baseUrl ?? environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.medicationDosageTypes.resourceRelativePath)
		.withResourceTypeVersion(environment?.api?.resources?.medicationDosageTypes?.resourceTypeVersion ?? environment?.api?.defaultResourceTypeVersion)
		.build();

	return new MedicationDosageTypeHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
