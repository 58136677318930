export enum QueryParameter {

	Section = 'section',
	Email = 'email'

}

export enum QueryParameterValueForPrivacyPolicySection {

	CookiePolicy = 'cookie-policy',
	PrivacyPolicy = 'privacy-policy',
	SubscriptionPolicy = 'subscription-policy',
	TermsOfService = 'terms-of-service'

}

export enum QueryParameterValueForChecker {

	Age = 'age',
	Weight = 'weight',
	Height = 'height',
	PopulationId = 'population',
	GenderId = 'gender',
	DiseaseIds = 'diseases',
	MedicationIds = 'medications',
	Lifestyle = 'lifestyle',
	Anamnesis = 'anamnesis'

}
