import { Injector } from '@angular/core';
import { LanguageClient } from '@nmn-communication/languages';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { LanguageFakeClientBuilder } from '../builders/language.fake-client.builder';
import { LanguageFakeClientConfigurationBuilder } from '../builders/language.fake-client.configuration.builder';
import { LanguageHttpClientBuilder } from '../builders/language.http-client.builder';
import { LanguageHttpClientConfigurationBuilder } from '../builders/language.http-client.configuration.builder';
import { LanguageFakeClient } from '../services/language.fake-client';
import { LanguageHttpClient } from '../services/language.http-client';

export const languageClientFactory = (injector: Injector): LanguageClient => {
	const useFake = environment?.api?.resources?.languages?.useFake ||
		!isValueDefined(environment?.api?.resources?.languages?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		languageFakeClientFactory(injector) :
		languageHttpClientFactory(injector);
};

export const languageFakeClientFactory = (
	injector: Injector
): LanguageFakeClient => {
	const configuration = new LanguageFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.languages.serverDelayMs)
		.build();

	return new LanguageFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const languageHttpClientFactory = (
	injector: Injector
): LanguageHttpClient => {
	const configuration = new LanguageHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.languages?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.languages.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.languages?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new LanguageHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
