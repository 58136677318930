import { Injectable } from '@angular/core';
import { ContactsClient } from '@nmn-communication/contacts';
import { Result } from '@nmn-core/shared';
import { ContactsCommandHandlerService, SendMessageCommand } from '@nmn-domain/contacts';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapSendMessageCommandToParameter } from '../factories/contacts.factory';

@Injectable()
export class ContactsCommandHandlerViaClientService extends ContactsCommandHandlerService {

	constructor(
		private readonly client: ContactsClient
	) {
		super();
	}

	public handleSendMessageCommand(command: SendMessageCommand): Observable<Result<EmptyCommandResult<SendMessageCommand>, FailureModel>> {
		return this.client
			.sendMessage(mapSendMessageCommandToParameter(command))
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

}
