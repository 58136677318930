export class PatientTreatmentCheckerFormFailure {

	public readonly isValidationFailure: boolean;

	private constructor(
		isValidationFailure: boolean
	) {
		this.isValidationFailure = isValidationFailure;
	}

	public static createAsValidationFailure(): PatientTreatmentCheckerFormFailure {
		return new PatientTreatmentCheckerFormFailure(true);
	}

}
