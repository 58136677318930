import { isArrayDefinedAndNotEmpty } from '@nmn-core/utils';
import { PatientModel } from '../patient.model';

export class PatientInsuranceFormInModel {

	public readonly insuranceCode: string;
	public readonly insuranceCompany: string;

	private constructor(
		insuranceCode: string | undefined,
		insuranceCompany: string | undefined
	) {
		this.insuranceCode = insuranceCode;
		this.insuranceCompany = insuranceCompany;
	}

	public static createEmpty = (): PatientInsuranceFormInModel => {
		return new PatientInsuranceFormInModel(undefined, undefined);
	}

	public static createByPatient = (patient: PatientModel): PatientInsuranceFormInModel => {
		const insurance = isArrayDefinedAndNotEmpty(patient?.insurances) ?
			patient.insurances[0] :
			undefined;

		return new PatientInsuranceFormInModel(
			insurance?.code,
			insurance?.company);
	}
}
