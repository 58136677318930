import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../../shared/builders/http-client.builder';
import { UserSubscriptionHttpClientConfiguration } from '../configurations/user-subscription.http-client.configuration';
import { UserSubscriptionHttpClient } from '../services/user-subscription.http-client';

export class UserSubscriptionHttpClientBuilder
	extends HttpClientBuilder<UserSubscriptionHttpClient, UserSubscriptionHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): UserSubscriptionHttpClient {
		return new UserSubscriptionHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
