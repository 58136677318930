import { DescribedComboboxModel } from '../../shared/comboboxes/described-combobox.model';
import { MedicationAdmStatusType } from './medication-adm-status-type.enum';

export class MedicationAdmStatusDescribedComboboxModel extends DescribedComboboxModel {

	public readonly type: MedicationAdmStatusType;

	constructor(
		id: string,
		displayText: string,
		description: string,
		type: MedicationAdmStatusType
	) {

		super(id, displayText, description);

		// TODO: Guard check on defined
		this.type = type;

	}

}
