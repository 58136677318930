import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TimezoneComboboxModel, TimezoneFilterModel, TimezoneQueryHandlerService, TimezonesResolver } from '@nmn-domain/timezones';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TimezonesViaQueryHandlerResolver extends TimezonesResolver {

	constructor(
		private readonly genderQueryHandlerService: TimezoneQueryHandlerService
	) {
		super();
	}

	public resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<Array<TimezoneComboboxModel>> | Array<TimezoneComboboxModel> {
		return this.genderQueryHandlerService
			.getAsComboboxes(TimezoneFilterModel.createEmpty())
			.pipe(map(result => result.successOrDefault(() => [])));
	}

}
