import { TreatmentCheckerFormOutModel } from '../treatment-checker-form/treatment-checker-form.out-model';
import { TreatmentCheckerOneStepFlowFormOutModel } from '../treatment-checker-one-step-flow-form/treatment-checker-one-step-flow-form.out-model';

export class TreatmentCheckerAnalyzeCommand {

	public readonly email: string;
	public readonly diseaseIds: Array<string>;
	public readonly medicationIds: Array<string>;

	public readonly populationId?: string;
	public readonly genderId?: string;
	public readonly age?: number;
	public readonly weight?: number;
	public readonly height?: number;
	public readonly isPregnant?: boolean;
	public readonly isActiveLifestyle?: boolean;
	public readonly isAlcohol?: boolean;
	public readonly isOnHealthyDiet?: boolean;
	public readonly isSmoking?: boolean;
	public readonly isUnderHighLevelOfStress?: boolean;
	public readonly isUsingNutritionalSupplements?: boolean;

	public readonly hasHealthIssuesWithLiver?: boolean;
	public readonly hasHealthIssuesWithKidney?: boolean;
	public readonly hasHealthIssuesWithHeart?: boolean;
	public readonly hasHealthIssuesWithStomach?: boolean;
	public readonly hasHealthIssuesWithNervousSystem?: boolean;
	public readonly hasHealthIssuesWithImmuneSystem?: boolean;
	public readonly hasHealthIssuesWithEndocrine?: boolean;
	public readonly hasHealthIssuesWithCancer?: boolean;
	public readonly hasHealthIssuesWithEye?: boolean;
	public readonly hasHealthIssuesWithEar?: boolean;
	public readonly hasHealthIssuesWithMuscles?: boolean;
	public readonly hasHealthIssuesWithGeneticConditions?: boolean;

	constructor(
		email: string,
		diseaseIds: Array<string>,
		medicationIds: Array<string>,

		populationId: string | undefined,
		genderId: string | undefined,
		age: number | undefined,
		weight: number | undefined,
		height: number | undefined,
		isPregnant: boolean | undefined,
		isActiveLifestyle: boolean | undefined,
		isAlcohol: boolean | undefined,
		isOnHealthyDiet: boolean | undefined,
		isSmoking: boolean | undefined,
		isUnderHighLevelOfStress: boolean | undefined,
		isUsingNutritionalSupplements: boolean | undefined,

		hasHealthIssuesWithLiver: boolean | undefined,
		hasHealthIssuesWithKidney: boolean | undefined,
		hasHealthIssuesWithHeart: boolean | undefined,
		hasHealthIssuesWithStomach: boolean | undefined,
		hasHealthIssuesWithNervousSystem: boolean | undefined,
		hasHealthIssuesWithImmuneSystem: boolean | undefined,
		hasHealthIssuesWithEndocrine: boolean | undefined,
		hasHealthIssuesWithCancer: boolean | undefined,
		hasHealthIssuesWithEye: boolean | undefined,
		hasHealthIssuesWithEar: boolean | undefined,
		hasHealthIssuesWithMuscles: boolean | undefined,
		hasHealthIssuesWithGeneticConditions: boolean | undefined
	) {
		// TODO: Guard check on defined
		this.email = email;
		this.diseaseIds = diseaseIds;
		this.medicationIds = medicationIds;

		this.populationId = populationId;
		this.genderId = genderId;
		this.age = age;
		this.weight = weight;
		this.height = height;
		this.isPregnant = isPregnant;
		this.isActiveLifestyle = isActiveLifestyle;
		this.isAlcohol = isAlcohol;
		this.isOnHealthyDiet = isOnHealthyDiet;
		this.isSmoking = isSmoking;
		this.isUnderHighLevelOfStress = isUnderHighLevelOfStress;
		this.isUsingNutritionalSupplements = isUsingNutritionalSupplements;

		this.hasHealthIssuesWithLiver = hasHealthIssuesWithLiver;
		this.hasHealthIssuesWithKidney = hasHealthIssuesWithKidney;
		this.hasHealthIssuesWithHeart = hasHealthIssuesWithHeart;
		this.hasHealthIssuesWithStomach = hasHealthIssuesWithStomach;
		this.hasHealthIssuesWithNervousSystem = hasHealthIssuesWithNervousSystem;
		this.hasHealthIssuesWithImmuneSystem = hasHealthIssuesWithImmuneSystem;
		this.hasHealthIssuesWithEndocrine = hasHealthIssuesWithEndocrine;
		this.hasHealthIssuesWithCancer = hasHealthIssuesWithCancer;
		this.hasHealthIssuesWithEye = hasHealthIssuesWithEye;
		this.hasHealthIssuesWithEar = hasHealthIssuesWithEar;
		this.hasHealthIssuesWithMuscles = hasHealthIssuesWithMuscles;
		this.hasHealthIssuesWithGeneticConditions = hasHealthIssuesWithGeneticConditions;
	}

	public static createByTreatmentCheckerFormOutModel(treatmentCheckerFormOutModel: TreatmentCheckerFormOutModel): TreatmentCheckerAnalyzeCommand {
		// TODO: Guard check on defined
		return new TreatmentCheckerAnalyzeCommand(
			treatmentCheckerFormOutModel.yourData.email,
			treatmentCheckerFormOutModel.yourData.diseaseIds,
			treatmentCheckerFormOutModel.yourData.medicationIds,
			treatmentCheckerFormOutModel.treatmentInfo.populationId,
			treatmentCheckerFormOutModel.treatmentInfo.genderId,
			treatmentCheckerFormOutModel.treatmentInfo.age,
			treatmentCheckerFormOutModel.treatmentInfo.weight,
			treatmentCheckerFormOutModel.treatmentInfo.height,
			treatmentCheckerFormOutModel.treatmentInfo.isPregnant,
			treatmentCheckerFormOutModel.treatmentInfo.isActiveLifestyle,
			treatmentCheckerFormOutModel.treatmentInfo.isAlcohol,
			treatmentCheckerFormOutModel.treatmentInfo.isOnHealthyDiet,
			treatmentCheckerFormOutModel.treatmentInfo.isSmoking,
			treatmentCheckerFormOutModel.treatmentInfo.isUnderHighLevelOfStress,
			treatmentCheckerFormOutModel.treatmentInfo.isUsingNutritionalSupplements,
			treatmentCheckerFormOutModel.anamnesis.hasHealthIssuesWithLiver,
			treatmentCheckerFormOutModel.anamnesis.hasHealthIssuesWithKidney,
			treatmentCheckerFormOutModel.anamnesis.hasHealthIssuesWithHeart,
			treatmentCheckerFormOutModel.anamnesis.hasHealthIssuesWithStomach,
			treatmentCheckerFormOutModel.anamnesis.hasHealthIssuesWithNervousSystem,
			treatmentCheckerFormOutModel.anamnesis.hasHealthIssuesWithImmuneSystem,
			treatmentCheckerFormOutModel.anamnesis.hasHealthIssuesWithEndocrine,
			treatmentCheckerFormOutModel.anamnesis.hasHealthIssuesWithCancer,
			treatmentCheckerFormOutModel.anamnesis.hasHealthIssuesWithEye,
			treatmentCheckerFormOutModel.anamnesis.hasHealthIssuesWithEar,
			treatmentCheckerFormOutModel.anamnesis.hasHealthIssuesWithMuscles,
			treatmentCheckerFormOutModel.anamnesis.hasHealthIssuesWithGeneticConditions
		);
	}

	public static createByTreatmentCheckerOneStepFlowFormOutModel(treatmentCheckerOneStepFlowFormOutModel: TreatmentCheckerOneStepFlowFormOutModel): TreatmentCheckerAnalyzeCommand {
		// TODO: Guard check on defined
		return new TreatmentCheckerAnalyzeCommand(
			treatmentCheckerOneStepFlowFormOutModel.email,
			treatmentCheckerOneStepFlowFormOutModel.diseaseIds,
			treatmentCheckerOneStepFlowFormOutModel.medicationIds,
			treatmentCheckerOneStepFlowFormOutModel.populationId,
			treatmentCheckerOneStepFlowFormOutModel.genderId,
			treatmentCheckerOneStepFlowFormOutModel.age,
			treatmentCheckerOneStepFlowFormOutModel.weight,
			treatmentCheckerOneStepFlowFormOutModel.height,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined
		);
	}

}
