import { Injectable } from '@angular/core';
import { LocalStorageService } from '@nmn-core/application-storages';
import { Result } from '@nmn-core/shared';
import { isStringDefinedAndNotEmpty, isValueDefined, onlyUnique } from '@nmn-core/utils';
import { RegionComboboxModel, RegionFilterModel, RegionQueryHandlerService } from '@nmn-domain/regions';
import { FailureModel } from '@nmn-domain/shared';
import { catchError, map, of, shareReplay, Subject } from 'rxjs';
import { timeZoneToRegionIdMapping } from '../data/timezone-to-country.mapping';

const defaultRegionId = 'other-world';

@Injectable()
export class ApplicationRegionService {

	private readonly currentApplicationRegionSubject$ = new Subject<RegionComboboxModel | undefined>();
	public readonly currentApplicationRegion$ = this.currentApplicationRegionSubject$.asObservable().pipe(shareReplay(1));

	constructor(
		private readonly localStorage: LocalStorageService,
		private readonly regionQueryHandlerService: RegionQueryHandlerService
	) {
		this.currentApplicationRegion$.subscribe();
		this.initializeRegion();
	}

	public setRegion(isoCode: string): void {
		if (isStringDefinedAndNotEmpty(isoCode)) {
			this.regionQueryHandlerService
				.getAsComboboxes(RegionFilterModel.createForPredefinedList([isoCode]))
				.pipe(
					map(
						(result: Result<Array<RegionComboboxModel>, FailureModel>) => result
							.mapOnSuccess((success: Array<RegionComboboxModel>) => {
								const region = success.find(item => item.id === isoCode);

								return isValueDefined(region) ? region : undefined;
							})
							.successOrUndefined()
					),
					catchError(() => of(undefined))
				)
				.subscribe({
					next: (region: RegionComboboxModel) => {
						if (isValueDefined(region)) {
							this.currentApplicationRegionSubject$.next(region);
							this.localStorage.setRegionId(region.id);
						}
					}
				});
		}
	}

	private initializeRegion(): void {
		let regionIdFromLocalStorage = this.localStorage.getRegionId();
		let regionIdFromBrowser = this.getBrowserRegionId();

		this.regionQueryHandlerService
			.getAsComboboxes(RegionFilterModel.createForPredefinedList([defaultRegionId, regionIdFromBrowser, regionIdFromLocalStorage].filter(onlyUnique).filter(isValueDefined)))
			.pipe(
				map(
					(result: Result<Array<RegionComboboxModel>, FailureModel>) => result
						.mapOnSuccess((success: Array<RegionComboboxModel>) => {
							let regionFromLocalStorage = regionIdFromLocalStorage !== defaultRegionId ? success.find(item => item.id === regionIdFromLocalStorage) : undefined;
							let regionFromBrowser = regionIdFromBrowser !== defaultRegionId ? success.find(item => item.id === regionIdFromBrowser) : undefined;
							let regionDefault = success.find(item => item.id === defaultRegionId);

							return isValueDefined(regionFromLocalStorage) ? regionFromLocalStorage : isValueDefined(regionFromBrowser) ? regionFromBrowser : regionDefault;
						})
						.successOrUndefined()
				),
				catchError(() => of(undefined))
			)
			.subscribe({
				// do not set region to localStorage here
				next: (region: RegionComboboxModel) => this.currentApplicationRegionSubject$.next(region)
			});
	}

	private getBrowserRegionId(): string {
		try {
			const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			const regionIdByTimezone = timeZoneToRegionIdMapping[timezone]?.toLowerCase();

			if (
				isStringDefinedAndNotEmpty(timezone) && timezone.startsWith('Europe/')
				&& (!isStringDefinedAndNotEmpty(regionIdByTimezone) || regionIdByTimezone?.length !== 3)
			) {
				return 'europe-non-eu';
			}

			return regionIdByTimezone;
		} catch {
			return undefined;
		}
	}

}
