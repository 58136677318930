import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { HealthIssueHttpClientConfiguration } from '../configurations/health-issue.http-client.configuration';

export class HealthIssueHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<HealthIssueHttpClientConfiguration> {

	public build(): HealthIssueHttpClientConfiguration {
		return new HealthIssueHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
