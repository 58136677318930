import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { TimezoneHttpClientConfiguration } from '../configurations/timezone.http-client.configuration';

export class TimezoneHttpClientConfigurationBuilder extends HttpClientConfigurationBuilder<TimezoneHttpClientConfiguration> {

	public build(): TimezoneHttpClientConfiguration {
		return new TimezoneHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion
		);
	}

}
