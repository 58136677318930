import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HashMap, TranslocoService } from '@ngneat/transloco';
import { getSuccessLocalizationKey, isValueDefined, tryTranslate } from '@nmn-core/utils';
import { UserConfigurationModel } from '@nmn-domain/accounts/user-configuration/models/user-configuration.model';
import { NotificationDialogComponent } from 'app/modules/shared/notifications/components/notification-dialog/notification-dialog.component';
import { NotificationDialogInModel } from 'app/modules/shared/notifications/models/notification-dialog.in-model';
import { Observable, Subscription } from 'rxjs';
import { UserConfigurationStorageService } from '../../../../services';
import {
	NotificationDialogAppearanceSettings, NotificationModel, NotificationSnackbarAppearanceSettings, NotificationSnackBarHorizontalPosition, NotificationType, NotificationViewType
} from '../models/notification.model';

@Injectable({ providedIn: 'root' })
export class NotificationService {

	private openedDialog: MatDialogRef<NotificationDialogComponent>;

	private readonly defaultLocalizationPattern = 'shared.forms';
	private readonly successLocalization = 'operationSuccessful';
	private readonly deletionSuccessLocalization = 'deletionSuccessful';
	private readonly cancelledActionLocalization = 'cancelled';
	private readonly resetActionLocalization = 'reset';
	private readonly clearedActionLocalization = 'cleared';
	private readonly closeBtnLocalization = 'closeBtn';

	private readonly userConfigurationObs$: Observable<UserConfigurationModel>;
	private userConfiguration: UserConfigurationModel;

	private subscription: Subscription = undefined;

	constructor(
		private readonly snackBar: MatSnackBar,
		private readonly userConfigurationStorageService: UserConfigurationStorageService,
		private readonly dialog: MatDialog,
		private readonly translocoService: TranslocoService
	) {
		this.userConfigurationObs$ = this.userConfigurationStorageService.userConfiguration$;
		this.userConfiguration = this.userConfigurationStorageService.userConfiguration;
	}

	public initSubscriptions(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = undefined;
		}

		this.subscription = this.userConfigurationObs$
			.subscribe({
				next: result => {
					console.log('Changed notification settings.', result);
					this.userConfiguration = result;
				},
				error: error => {
					console.error(error, 'Notification service is broken');
				}
			});
	}

	public notify(notification: NotificationModel): void {
		switch (notification.notificationType) {
			case NotificationType.CustomAction:
				this.handleCustomAction(notification);
				break;

			case NotificationType.SuccessAction:
				this.handleSuccessAction(notification);
				break;

			case NotificationType.SuccessDeletion:
				this.handleSuccessDeletion(notification);
				break;

			case NotificationType.CancelAction:
				this.handleCancelledAction(notification);
				break;

			case NotificationType.ClearAction:
				this.handleClearedAction(notification);
				break;

			case NotificationType.ResetAction:
				this.handleResetAction(notification);
				break;

			default:
				this.handleUndefinedAction(notification);
				break;
		}
	}

	private handleCustomAction(notification: NotificationModel): void {
		this.localizeCustomNotificationAndNotify(
			notification.localizationPattern,
			notification.notificationViewType,
			notification.notificationAppearanceSettings,
			notification.notificationDialogAppearanceSettings,
			notification.localizationString,
			this.closeBtnLocalization,
			notification.localizationKeyParams
		);
	}

	private handleResetAction(notification: NotificationModel): void {
		if (this.userConfiguration.specificUserConfiguration.notificationConfiguration.isEnabledResetNotifications) {
			this.localizeAndNotify(
				notification.localizationPattern,
				notification.notificationViewType,
				notification.notificationAppearanceSettings,
				notification.notificationDialogAppearanceSettings,
				this.resetActionLocalization,
				this.closeBtnLocalization,
				notification.localizationKeyParams
			);
		}
	}

	private handleClearedAction(notification: NotificationModel): void {
		if (this.userConfiguration.specificUserConfiguration.notificationConfiguration.isEnabledClearNotifications) {
			this.localizeAndNotify(
				notification.localizationPattern,
				notification.notificationViewType,
				notification.notificationAppearanceSettings,
				notification.notificationDialogAppearanceSettings,
				this.clearedActionLocalization,
				this.closeBtnLocalization,
				notification.localizationKeyParams
			);
		}
	}

	private handleCancelledAction(notification: NotificationModel): void {
		if (this.userConfiguration.specificUserConfiguration.notificationConfiguration.isEnabledCancelNotifications) {
			this.localizeAndNotify(
				notification.localizationPattern,
				notification.notificationViewType,
				notification.notificationAppearanceSettings,
				notification.notificationDialogAppearanceSettings,
				this.cancelledActionLocalization,
				this.closeBtnLocalization,
				notification.localizationKeyParams
			);
		}
	}

	private handleSuccessAction(notification: NotificationModel): void {
		if (notification.notificationType === NotificationType.SuccessAction) {
			if (this.userConfiguration.specificUserConfiguration.notificationConfiguration.isEnabledSuccessNotifications) {
				this.localizeAndNotify(
					notification.localizationPattern,
					notification.notificationViewType,
					notification.notificationAppearanceSettings,
					notification.notificationDialogAppearanceSettings,
					this.successLocalization,
					this.closeBtnLocalization,
					notification.localizationKeyParams
				);
			}
		}
	}

	private handleSuccessDeletion(notification: NotificationModel): void {
		if (notification.notificationType === NotificationType.SuccessDeletion) {
			if (this.userConfiguration.specificUserConfiguration.notificationConfiguration.isEnabledSuccessNotifications) {
				this.localizeAndNotify(
					notification.localizationPattern,
					notification.notificationViewType,
					notification.notificationAppearanceSettings,
					notification.notificationDialogAppearanceSettings,
					this.deletionSuccessLocalization,
					this.closeBtnLocalization,
					notification.localizationKeyParams
				);
			}
		}
	}

	private handleUndefinedAction(notification: NotificationModel): void {
		if (notification.notificationType === NotificationType.Undefined) {
			this.openSnackbar(notification.message, 'close', notification.notificationAppearanceSettings);
		}
	}

	private localizeCustomNotificationAndNotify(
		localizationPattern: string,
		notificationViewType: NotificationViewType,
		notificationAppearanceSettings: NotificationSnackbarAppearanceSettings,
		notificationDialogAppearanceSettings: NotificationDialogAppearanceSettings,
		localizationKey: string,
		closeButtonKey: string,
		localizationKeyParams: HashMap
	): void {
		const messageTranslation = tryTranslate(
			this.translocoService,
			getSuccessLocalizationKey(localizationPattern, localizationKey),
			getSuccessLocalizationKey(this.defaultLocalizationPattern, this.successLocalization),
			localizationKeyParams);

		const closeBtnTranslation = tryTranslate(
			this.translocoService,
			getSuccessLocalizationKey(localizationPattern, closeButtonKey),
			getSuccessLocalizationKey(this.defaultLocalizationPattern, closeButtonKey),
			localizationKeyParams);

		if (notificationViewType === NotificationViewType.Snackbar) {
			this.openSnackbar(messageTranslation, closeBtnTranslation, notificationAppearanceSettings);
		}

		if (notificationViewType === NotificationViewType.Dialog) {
			this.openDialog(NotificationDialogInModel.createDefault(messageTranslation), notificationDialogAppearanceSettings);
		}
	}

	private localizeAndNotify(
		localizationPattern: string,
		notificationViewType: NotificationViewType,
		notificationAppearanceSettings: NotificationSnackbarAppearanceSettings,
		notificationDialogAppearanceSettings: NotificationDialogAppearanceSettings,
		localizationKey: string,
		closeButtonKey: string,
		localizationKeyParams: HashMap
	): void {
		const messageTranslation = tryTranslate(
			this.translocoService,
			getSuccessLocalizationKey(localizationPattern, localizationKey),
			getSuccessLocalizationKey(this.defaultLocalizationPattern, localizationKey),
			localizationKeyParams
		);

		const closeBtnTranslation = tryTranslate(
			this.translocoService,
			getSuccessLocalizationKey(localizationPattern, closeButtonKey),
			getSuccessLocalizationKey(this.defaultLocalizationPattern, closeButtonKey),
			localizationKeyParams
		);

		if (notificationViewType === NotificationViewType.Snackbar) {
			this.openSnackbar(messageTranslation, closeBtnTranslation, notificationAppearanceSettings);
		}

		if (notificationViewType === NotificationViewType.Dialog) {
			this.openDialog(NotificationDialogInModel.createDefault(messageTranslation), notificationDialogAppearanceSettings);
		}
	}

	private openSnackbar(message: string, actionLabel: string, settings: NotificationSnackbarAppearanceSettings): void {
		this.snackBar
			.open(
				message,
				actionLabel,
				{
					panelClass: ['nmn-translated-text'],
					duration: settings.duration,
					horizontalPosition: NotificationSnackBarHorizontalPosition[settings.horizontalPosition],
					verticalPosition: 'top'// NotificationSnackBarVerticalPosition[NotificationSnackBarVerticalPosition.Top]
				}
			);
	}

	private openDialog(inModel: NotificationDialogInModel, settings: NotificationDialogAppearanceSettings): void {
		if (!isValueDefined(this.openedDialog)) {
			this.openedDialog = this.dialog
				.open(
					NotificationDialogComponent,
					{
						maxHeight: settings.height,
						maxWidth: settings.width,
						disableClose: false,
						data: inModel
					}
				);

			this.openedDialog
				.afterClosed()
				.subscribe(() => { this.openedDialog = undefined; });
		}
	}

}
