import { isValueDefined } from '@nmn-core/utils';

export enum FileType {

	Undefined = 'undefined',
	PatientAvatar = 'patientAvatar',
	UserAvatar = 'userAvatar',
	Documents = 'documents'

}

export const mapFileTypeFromString = (stringValue: string): FileType => {
	const enumValue = Object
		.keys(FileType)
		.find(x => FileType[x] === stringValue);

	return isValueDefined(enumValue) ? stringValue as FileType : FileType.Undefined;
};
