import { RemoveCookieBehaviorSettingsBuilder } from '../builders/remove-cookie-behavior-settings-model.builder';
import { SetCookieBehaviorSettingsBuilder } from '../builders/set-cookie-behavior-settings-model.builder';

export abstract class CookieBehaviorSettingsModel {

	public readonly path?: string;
	public readonly domain?: string;
	public readonly secure?: boolean;
	public readonly sameSite?: 'Lax' | 'None' | 'Strict';

	public constructor(
		path: string | undefined,
		domain: string | undefined,
		secure: boolean | undefined,
		sameSite: 'Lax' | 'None' | 'Strict' | undefined
	) {
		this.path = path;
		this.domain = domain;
		this.secure = secure;
		this.sameSite = sameSite;
	}

}

export class RemoveCookieBehaviorSettingsModel extends CookieBehaviorSettingsModel {

	public static createWithBuilder(): RemoveCookieBehaviorSettingsBuilder {
		return new RemoveCookieBehaviorSettingsBuilder();
	}

}

export class SetCookieBehaviorSettingsModel extends CookieBehaviorSettingsModel {

	public readonly expires?: number | Date;

	public constructor(
		expires: number | Date | undefined,
		path: string | undefined,
		domain: string | undefined,
		secure: boolean | undefined,
		sameSite: 'Lax' | 'None' | 'Strict' | undefined
	) {
		super(path, domain, secure, sameSite);

		this.expires = expires;
	}

	public static createWithBuilder(): SetCookieBehaviorSettingsBuilder {
		return new SetCookieBehaviorSettingsBuilder();
	}

}
