import { isValueDefined } from '@nmn-core/utils';
import { NotificationConfiguration } from './notification.configuration';

export class SpecificUserConfiguration {

	public readonly notificationConfiguration: NotificationConfiguration;

	constructor(
		notificationConfiguration: NotificationConfiguration
	) {
		this.notificationConfiguration = notificationConfiguration;
	}

	public static createWithParameters(
		config?: SpecificUserConfiguration
	): SpecificUserConfiguration {
		if (!isValueDefined(config)) {
			return SpecificUserConfiguration.createDefault();
		}

		return new SpecificUserConfiguration(
			isValueDefined(config?.notificationConfiguration) ?
				NotificationConfiguration.createWithParameters(config!.notificationConfiguration) :
				NotificationConfiguration.createDefault()
		);
	}

	public static createDefault(
	): SpecificUserConfiguration {
		return new SpecificUserConfiguration(
			NotificationConfiguration.createDefault()
		);
	}

}
