import { isArrayDefinedAndNotEmpty, isValueDefined, onlyUnique } from '@nmn-core/utils';
import { ComboboxModel } from '@nmn-domain/shared';
import { TreatmentCheckerReportMedicationPropertyDrugType } from './treatment-checker-report-medication-property.drug-type.enum';
import { TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType } from './treatment-checker-report-medication-property.rely-on-food-intake-type.enum';

export class TreatmentCheckerReportMedicationPropertiesModel {

	public readonly drugTypes: Array<TreatmentCheckerReportMedicationPropertyDrugType>;
	public readonly isHerbal?: boolean;
	public readonly isPersonalizedMedicineAvailable: boolean;
	public readonly isIncompartibleWithPregnancy?: boolean;
	public readonly relyOnFoodIntakeTypes: Array<TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType>;
	public readonly isEfficacyUnproven: boolean;
	public readonly hasPGxRecommendation?: MedicationUsageModel;

	public get isTakeBeforeMealDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.relyOnFoodIntakeTypes) &&
			this.relyOnFoodIntakeTypes.indexOf(TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.TakeBeforeMeal) > -1;
	}

	public get isTakeAfterMealDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.relyOnFoodIntakeTypes) &&
			this.relyOnFoodIntakeTypes.indexOf(TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.TakeAfterMeal) > -1;
	}

	public get isTakeWithMealDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.relyOnFoodIntakeTypes) &&
			this.relyOnFoodIntakeTypes.indexOf(TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.TakeWithMeal) > -1;
	}

	public get isTakeWithoutFoodDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.relyOnFoodIntakeTypes) &&
			this.relyOnFoodIntakeTypes.indexOf(TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.TakeWithoutFood) > -1;
	}

	public get isTakeOnEmtpyStomachDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.relyOnFoodIntakeTypes) &&
			this.relyOnFoodIntakeTypes.indexOf(TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.TakeOnEmptyStomach) > -1;
	}

	public get isTakeSeparateDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.relyOnFoodIntakeTypes) &&
			this.relyOnFoodIntakeTypes.indexOf(TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.TakeSeparate) > -1;
	}

	public get isTakeWithWaterDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.relyOnFoodIntakeTypes) &&
			this.relyOnFoodIntakeTypes.indexOf(TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.TakeWithWater) > -1;
	}

	public get isAvoidAlcoholDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.relyOnFoodIntakeTypes) &&
			this.relyOnFoodIntakeTypes.indexOf(TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.AvoidAlcohol) > -1;
	}

	public get isRelyOnFoodIntakeTypeUndefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.relyOnFoodIntakeTypes) &&
			this.relyOnFoodIntakeTypes.every(item => item === TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.Undefined);
	}

	public get uniqueDrugTypes(): Array<TreatmentCheckerReportMedicationPropertyDrugType> {
		return this.drugTypes
			.filter(onlyUnique)
			.filter(isValueDefined)
			.filter((x, _, array) => array.length === 1 || x !== TreatmentCheckerReportMedicationPropertyDrugType.Undefined);
	}

	public get isHasPGxRecommendationDefined(): boolean {
		return isValueDefined(this.hasPGxRecommendation);
	}

	public constructor(
		drugTypes: Array<TreatmentCheckerReportMedicationPropertyDrugType>,
		isHerbal: boolean | undefined,
		isPersonalizedMedicineAvailable: boolean,
		isIncompartibleWithPregnancy: boolean | undefined,
		relyOnFoodIntakeTypes: Array<TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType>,
		isEfficacyUnproven: boolean,
		hasPGxRecommendation: MedicationUsageModel | undefined
	) {
		// TODO: Guard check on defined

		this.drugTypes = drugTypes;
		this.isHerbal = isHerbal;
		this.isPersonalizedMedicineAvailable = isPersonalizedMedicineAvailable;
		this.isIncompartibleWithPregnancy = isIncompartibleWithPregnancy;
		this.relyOnFoodIntakeTypes = relyOnFoodIntakeTypes;
		this.isEfficacyUnproven = isEfficacyUnproven;
		this.hasPGxRecommendation = hasPGxRecommendation;
	}

}

export class MedicationUsageModel {

	public readonly region: ComboboxModel;
	public readonly population: ComboboxModel;
	public readonly percent: number;

	constructor(
		region: ComboboxModel,
		population: ComboboxModel,
		percent: number
	) {
		// TODO: Guard check on defined
		this.region = region;
		this.population = population;
		this.percent = percent;
	}

}
