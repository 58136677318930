import { Result } from '@nmn-core/shared';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { LocaleComboboxModel } from '../models/locale-combobox.model';
import { LocaleFilterModel } from '../models/locale-filter.model';

export abstract class LocaleQueryHandlerService {

	public abstract getAsComboboxes(query: LocaleFilterModel): Observable<Result<Array<LocaleComboboxModel>, FailureModel>>;

}
