import { Injector } from '@angular/core';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { isValueDefined } from '@nmn-core/utils';
import { TakenMedicationFakeClientConfiguration } from '../configurations/taken-medication-fake-client.configuration';
import { TakenMedicationFakeClient } from '../services/taken-medication.fake-client';

export class TakenMedicationFakeClientBuilder {

	private configuration: TakenMedicationFakeClientConfiguration;

	constructor(private readonly injector: Injector) { }

	public withConfiguration(
		configuration: TakenMedicationFakeClientConfiguration
	): TakenMedicationFakeClientBuilder {

		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;

	}

	public build(): TakenMedicationFakeClient {

		return new TakenMedicationFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));

	}

}
