export class AttachNativeLoginDialogFormOutModel {

	public readonly password: string;
	public readonly confirmPassword: string;

	public constructor(
		password: string,
		confirmPassword: string
	) {
		this.password = password;
		this.confirmPassword = confirmPassword;
	}

}
