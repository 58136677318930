import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { AllergyIntoleranceCriticalityHttpClientConfiguration } from '../configurations/allergy-intolerance-criticality.http-client.configuration';

export class AllergyIntoleranceCriticalityHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<AllergyIntoleranceCriticalityHttpClientConfiguration> {

	public build(): AllergyIntoleranceCriticalityHttpClientConfiguration {
		return new AllergyIntoleranceCriticalityHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
