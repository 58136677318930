import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { RegionHttpClientConfiguration } from '../configurations/region.http-client.configuration';

export class RegionHttpClientConfigurationBuilder extends HttpClientConfigurationBuilder<RegionHttpClientConfiguration> {

	public build(): RegionHttpClientConfiguration {
		return new RegionHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion
		);
	}

}
