import { isValueDefined } from '@nmn-core/utils';
import { DescribedComboboxModel } from '@nmn-domain/shared';

export class UploadedFileExtensionComboboxModel extends DescribedComboboxModel {

	public readonly iconType: FileIconTypeEnum;

	constructor(
		id: string,
		displayText: string,
		description: string,
		iconType: FileIconTypeEnum
	) {
		super(id, displayText, description);
		this.iconType = iconType;
	}

}

export enum FileIconTypeEnum {

	Undefined = 'undefined',
	Pdf = 'pdf',
	Doc = 'doc',
	Image = 'image'

}

export const mapIconTypeFromString = (stringValue: string): FileIconTypeEnum => {
	const enumValue = Object
		.keys(FileIconTypeEnum)
		.find(x => FileIconTypeEnum[x] === stringValue);

	return isValueDefined(enumValue) ? stringValue as FileIconTypeEnum : FileIconTypeEnum.Undefined;
};
