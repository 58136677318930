import { GeneComboboxModel } from '@nmn-domain/genes';
import { PharmacogenomicTestResultComboboxModel } from '@nmn-domain/pharmacogenomic-test-results';
import { DescribedComboboxModel } from '@nmn-domain/shared';

export class GeneVariantComboboxModel extends DescribedComboboxModel {

	public readonly gene: GeneComboboxModel;
	public readonly allele1: string;
	public readonly allele2?: string;
	public readonly pharmacogenomicTestResult: PharmacogenomicTestResultComboboxModel;

	constructor(
		id: string,
		displayText: string,
		description: string,
		gene: GeneComboboxModel,
		allele1: string,
		allele2: string | undefined,
		pharmacogenomicTestResult: PharmacogenomicTestResultComboboxModel
	) {
		super(id, displayText, description);

		this.allele2 = allele2;

		// TODO: Guard check on defined
		this.gene = gene;
		this.allele1 = allele1;
		this.pharmacogenomicTestResult = pharmacogenomicTestResult;
	}

}
