import { Injectable } from '@angular/core';
import { isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';

declare const gtag: Function;
declare const googleAnalyticsMeasurementId: string;

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {

	private get gtag(): Function {
		return gtag;
	}

	private get isGoogleAnalyticsEnabled(): boolean {
		return isStringDefinedAndNotEmpty(this.googleAnalyticsMeasurementId);
	}

	private get googleAnalyticsMeasurementId(): string {
		const measurementId = (window as any).googleAnalyticsMeasurementId ?? googleAnalyticsMeasurementId;

		return isStringDefinedAndNotEmpty(measurementId) && measurementId !== '{GoogleAnalyticsMeasurementId}' ? measurementId : '';
	}

	public updateConfig(data?: any): void {
		if (this.isGoogleAnalyticsEnabled) {
			let payload = {};

			if (isValueDefined(data)) {
				payload = typeof data === 'object' ? { ...data } : { payload: data };
			}

			this.gtag('set', payload);
			this.gtag('config', this.googleAnalyticsMeasurementId);
		}
	}

	public trackEvent(event: string, data?: any, completeFn?: () => void): void {
		if (this.isGoogleAnalyticsEnabled) {
			let payload = {};

			if (isValueDefined(data)) {
				payload = typeof data === 'object' ? { ...data } : { payload: data };
			}

			if (isValueDefined(completeFn)) {
				payload['event_callback'] = completeFn;
			}

			// uncomment to enable debug mode
			// payload['debug_mode'] = true;

			this.gtag('event', event, payload);
		}
	}

}
