import { DescribedComboboxModel } from '@nmn-domain/shared';
import { SubscriptionType } from './subscription-type.enum';

export class SubscriptionComboboxModel extends DescribedComboboxModel {

	public readonly subscriptionType: SubscriptionType;

	constructor(
		id: string,
		displayText: string,
		description: string,
		subscriptionType: SubscriptionType
	) {
		super(id, displayText, description);

		// TODO: Guard check on defined
		this.subscriptionType = subscriptionType;
	}

}
