import { UserFileStorageProfileModel, UserProfileModel } from '@nmn-domain/accounts/user-accounts/models/user-profile.model';
import { UserConfigurationModel } from '@nmn-domain/accounts/user-configuration/models/user-configuration.model';
import { TokenInfoModel } from './token-info.model';

export class LoginModel {

	public readonly tokenInfo: TokenInfoModel;
	public readonly user: UserProfileModel;
	public readonly userConfiguration: UserConfigurationModel;
	public readonly userFileStorageProfile: UserFileStorageProfileModel;

	constructor(
		tokenInfo: TokenInfoModel,
		user: UserProfileModel,
		userConfiguration: UserConfigurationModel,
		userFileStorageProfile: UserFileStorageProfileModel
	) {
		// TODO: Guard check on defined
		this.tokenInfo = tokenInfo;
		this.user = user;
		this.userConfiguration = userConfiguration;
		this.userFileStorageProfile = userFileStorageProfile;
	}

}
