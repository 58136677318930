import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DoctorEncounterModel } from '../models/doctor-encounter.model';

export abstract class DoctorEncounterResolver implements Resolve<DoctorEncounterModel> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	): Observable<DoctorEncounterModel> | DoctorEncounterModel;

}
