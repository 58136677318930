import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { DoctorEncounterComboboxModel } from '../../doctor-encounters';
import { DocumentDescribedComboboxModel } from '../../documents';
import { HealthComplaintComboboxModel } from '../../health-complaints';
import { HealthIssueSeverityComboboxModel } from '../../health-issue-severities';
import { TakenMedicationDescribedComboboxModel } from '../../taken-medications';
import { HealthIssueStatus } from './health-issue-status.enum';

export class HealthIssueModel {

	public readonly id: string;
	public readonly name: string;
	public readonly comment?: string;
	public readonly dateStart?: Date;
	public readonly dateEnd?: Date;
	public readonly status: HealthIssueStatus;
	public readonly patientId: string;
	public readonly severity: HealthIssueSeverityComboboxModel;
	public readonly healthComplaints: Array<HealthComplaintComboboxModel>;
	public readonly doctorEncounters: Array<DoctorEncounterComboboxModel>;
	public readonly takenMedications: Array<TakenMedicationDescribedComboboxModel>;
	public readonly documents: Array<DocumentDescribedComboboxModel>;

	public get isPeriodStartDefined(): boolean {
		return isValueDefined(this.dateStart);
	}

	public get isPeriodEndDefined(): boolean {
		return isValueDefined(this.dateEnd);
	}

	public get isCommentDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.comment);
	}

	public get isHealthComplaintsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.healthComplaints);
	}

	public get healthComplaintsAsCommaSeparatedString(): string {
		return this.isHealthComplaintsDefined ?
			this.healthComplaints
				.map(item => item.displayText)
				.join(', ') :
			'';
	}

	public get isDoctorEncountersDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.doctorEncounters);
	}

	public get lastDoctorEncounter(): DoctorEncounterComboboxModel {
		return this.isDoctorEncountersDefined ?
			this.doctorEncounters[this.doctorEncounters.length - 1] :
			undefined;
	}

	public get isTakenMedicationsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.takenMedications);
	}

	public get isDocumentsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.documents);
	}

	constructor(
		id: string,
		name: string,
		comment: string | undefined,
		dateStart: Date | undefined,
		dateEnd: Date | undefined,
		status: HealthIssueStatus,
		patientId: string,
		severity: HealthIssueSeverityComboboxModel,
		healthComplaints: Array<HealthComplaintComboboxModel>,
		doctorEncounters: Array<DoctorEncounterComboboxModel>,
		takenMedications: Array<TakenMedicationDescribedComboboxModel>,
		documents: Array<DocumentDescribedComboboxModel>
	) {
		// TODO: Guard check on defined
		this.id = id;
		this.name = name;
		this.comment = comment;
		this.dateStart = dateStart;
		this.dateEnd = dateEnd;
		this.status = status;
		this.patientId = patientId;
		this.severity = severity;
		this.healthComplaints = healthComplaints;
		this.doctorEncounters = doctorEncounters;
		this.takenMedications = takenMedications;
		this.documents = documents;
	}

}
