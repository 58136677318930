import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { VaccinationCardModel } from '../models/vaccination-card.model';

export abstract class CurrentPatientVaccinationCardResolver implements Resolve<VaccinationCardModel> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		routerState: RouterStateSnapshot
	): Observable<VaccinationCardModel> | Promise<VaccinationCardModel> | VaccinationCardModel;

}
