import { GoogleAnalyticsEvent } from '../../models/google-analytics-event.enum';

export class GaEventTrackingGeneticCardService {

	constructor(
		private readonly trackEvent: (event: GoogleAnalyticsEvent, data?: any, completeFn?: () => void) => void
	) { }

	public geneticTestCreated(completeFn?: () => void): void {
		this.trackEvent(GoogleAnalyticsEvent.GeneticTestCreated, {}, completeFn);
	}

	public geneticTestDeleted(completeFn?: () => void): void {
		this.trackEvent(GoogleAnalyticsEvent.GeneticTestDeleted, {}, completeFn);
	}

	public geneticTestDeletedBulk(completeFn?: () => void): void {
		this.trackEvent(GoogleAnalyticsEvent.GeneticTestDeletedBulk, {}, completeFn);
	}

	public uploadedDnaFile(completeFn?: () => void): void {
		this.trackEvent(GoogleAnalyticsEvent.UploadedDnaFile, {}, completeFn);
	}

}
