import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleAuthService } from './services/google-auth.service';

@NgModule({
	providers: [
		GoogleAuthService
	],
	imports: [
		CommonModule
	]
})
export class GoogleAuthModule { }
