import { LanguageClient, LanguageFilterDto } from '@nmn-communication/languages';
import { ComboboxDto, Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { FakeDatabase } from 'app/modules/fakes/databases/fake.database';
import { wrapFuncCallAsFakeClientResponse } from 'app/modules/fakes/databases/fake.utils';
import { Observable } from 'rxjs';
import { LanguageFakeClientConfiguration } from '../configurations/language.fake-client.configuration';

export class LanguageFakeClient extends LanguageClient {

	constructor(
		private readonly configuration: LanguageFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulkAsComboboxes(
		filter: LanguageFilterDto
	): Observable<Result<Array<ComboboxDto<string>>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.languageTable.getComboboxesPagedCollection(filter),
			this.configuration.serverDelayMs
		);
	}

}
