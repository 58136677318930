import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { CurrentPatientVaccinationCardResolver, VaccinationCardGetQuery, VaccinationCardModel, VaccinationQueryHandlerService } from '@nmn-domain/vaccination';
import { RouteParameter } from '@nmn-middleware/navigation';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CurrentPatientVaccinationCardViaQueryHandlerResolver extends CurrentPatientVaccinationCardResolver {

	constructor(
		private readonly vaccinationQueryHandlerService: VaccinationQueryHandlerService
	) {
		super();
	}

	public resolve(
		activatedRoute: ActivatedRouteSnapshot,
		_: RouterStateSnapshot
	): Observable<VaccinationCardModel> | Promise<VaccinationCardModel> | VaccinationCardModel {
		const patientId = activatedRoute.paramMap.get(RouteParameter.PatientAlias);

		if (!isStringDefinedAndNotEmpty(patientId)) {
			return undefined;
		}

		return this.vaccinationQueryHandlerService
			.handleVaccinationCardGetQuery(VaccinationCardGetQuery.createByPatientId(patientId))
			.pipe(map(result => result.successOrDefault(() => undefined)));
	}

}
