import { ComboboxModel } from '@nmn-domain/shared';
import { SourceModel } from '@nmn-domain/sources';

export class TreatmentCheckerDiseaseTreatmentGuidelineModel {

	public readonly id: string;
	public readonly displayCode: string;
	public readonly target: ComboboxModel;
	public readonly source: SourceModel;

	public get sourceDisplayText(): string {
		return this.source.isDisplayTextDefined ? this.source.displayText : this.displayCode;
	}

	constructor(
		id: string,
		displayCode: string,
		target: ComboboxModel,
		source: SourceModel
	) {
		//TODO: Guard

		this.id = id;
		this.displayCode = displayCode;
		this.target = target;
		this.source = source;
	}

}
