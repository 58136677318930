import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { DescribedComboboxDto, Failure } from '../../shared';
import { AllergyIntoleranceStatusFilterDto } from '../models/allergy-intolerance-status-filter.dto';

export abstract class AllergyIntoleranceStatusClient {

	public abstract getBulkAsComboboxes(filter: AllergyIntoleranceStatusFilterDto): Observable<Result<Array<DescribedComboboxDto<string>>, Failure>>;

}
