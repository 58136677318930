import { isValueDefined } from '@nmn-core/utils';

export enum DoctorEncounterStatus {

	Planned = 'planned',
	Arrived = 'arrived',
	Triaged = 'triaged',
	InProgress = 'inProgress',
	OnLeave = 'onLeave',
	Finished = 'finished',
	Cancelled = 'cancelled',
	EnteredInError = 'enteredInError',
	Unknown = 'unknown'

}

export const mapDoctorEncounterStatusFromString = (stringValue: string): DoctorEncounterStatus => {
	const enumValue = Object
		.keys(DoctorEncounterStatus)
		.find(x => DoctorEncounterStatus[x] === stringValue);

	return isValueDefined(enumValue) ? stringValue as DoctorEncounterStatus : DoctorEncounterStatus.Unknown;
};
