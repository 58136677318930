import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AllergyClient, AllergyFilterDto } from '@nmn-communication/allergies';
import { DescribedComboboxDto, Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { constructHttpParams } from '@nmn-core/utils';
import { createFailedResultFromErrorResponse, createResultFromResponse } from 'app/modules/shared/error-handling/utils/http-client-default-handler.utils';
import { Observable } from 'rxjs';
import { AllergyHttpClientConfiguration } from '../configurations/allergy.http-client.configuration';

export class AllergyHttpClient extends AllergyClient {

	constructor(
		private readonly configuration: AllergyHttpClientConfiguration,
		private readonly http: HttpClient
	) {
		super();
	}

	public getBulkAsComboboxes(
		filter: AllergyFilterDto
	): Observable<Result<Array<DescribedComboboxDto<string>>, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`;
		const params = constructHttpParams(filter);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.http
			.get<Array<DescribedComboboxDto<string>>>(url, { params, headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

}
