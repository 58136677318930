
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { VaccinationFormConfiguration, VaccinationFormConfigurationResolver } from '@nmn-domain/vaccination';
import { Observable } from 'rxjs';
import { ValueRestrictionOptional } from '../../../../modules/core/configurations/restrictions/value-restriction-optional';

export class VaccinationFormConfigurationViaConstResolver implements VaccinationFormConfigurationResolver {

	public resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<VaccinationFormConfiguration> | Promise<VaccinationFormConfiguration> | VaccinationFormConfiguration {
		return defaultVaccinationFormConfiguration;
	}

}

const defaultVaccinationFormConfiguration: VaccinationFormConfiguration = {
	restrictions: {
		vaccinationDate: ValueRestrictionOptional.initDate(new Date('1900-01-01T00:00:00Z'), new Date('2100-01-01T00:00:00Z')),
		numberInSeriesOfDoses: { min: 1 },
		comment: { controlRowsCount: 3 }
	}
};
