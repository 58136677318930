import { Injector } from '@angular/core';
import { HealthIssueClient } from '@nmn-communication/health-issues';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { HealthIssueFakeClientBuilder } from '../builders/health-issue.fake-client.builder';
import { HealthIssueFakeClientConfigurationBuilder } from '../builders/health-issue.fake-client.configuration.builder';
import { HealthIssueHttpClientBuilder } from '../builders/health-issue.http-client.builder';
import { HealthIssueHttpClientConfigurationBuilder } from '../builders/health-issue.http-client.configuration.builder';
import { HealthIssueFakeClient } from '../services/health-issue.fake-client';
import { HealthIssueHttpClient } from '../services/health-issue.http-client';

export const healthIssueClientFactory = (injector: Injector): HealthIssueClient => {
	const useFake = environment?.api?.resources?.healthIssues?.useFake === true ||
		!isValueDefined(environment?.api?.resources?.healthIssues?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		healthIssueFakeClientFactory(injector) :
		healthIssueHttpClientFactory(injector);
};

export const healthIssueFakeClientFactory = (
	injector: Injector
): HealthIssueFakeClient => {
	const configuration = new HealthIssueFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.healthIssues.serverDelayMs)
		.build();

	return new HealthIssueFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const healthIssueHttpClientFactory = (
	injector: Injector
): HealthIssueHttpClient => {
	const configuration = new HealthIssueHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.healthIssues?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.healthIssues.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.healthIssues?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new HealthIssueHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
