import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { UploadFileCancelParameter } from '../models/upload-file-cancel-parameter.dto';
import { UploadFileCompleteParameter } from '../models/upload-file-complete-parameter.dto';
import { UploadFileParameter } from '../models/upload-file-paramter.dto';
import { UploadedFileDto } from '../models/uploaded-file.dto';

export abstract class FileUploaderClient {

	public abstract initiateUploadFile(parameter: UploadFileParameter): Observable<Result<UploadedFileDto, Failure>>;

	public abstract completeUploadFile(parameter: UploadFileCompleteParameter): Observable<Result<UploadedFileDto, Failure>>;

	public abstract cancelUploadFile(parameter: UploadFileCancelParameter): Observable<EmptyResult<Failure>>;

}
