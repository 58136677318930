export enum Resource {

	Home = 'home',
	PrivacyPolicy = 'privacy-policy',

	ConfirmEmail = 'confirm-email',
	Login = 'login',
	RegisterAccount = 'register-account',
	ResetPassword = 'reset-password',

	Account = 'account',
	ChangePassword = 'change-password',
	UserProfile = 'user-profile',

	TreatmentChecker = 'treatment-checker',
	TreatmentCheckerAbout = 'about',
	TreatmentCheckerAnalyze = 'analyze',
	TreatmentCheckerOneStep = 'quick',
	TreatmentCheckerReports = 'reports',
	TreatmentCheckerHistory = 'history',

	MedicationChecker = 'medication-checker',

	DoctorEncounters = 'doctor-encounters',

	Documents = 'documents',

	EmergencyCard = 'emergency-card',

	PatientProfile = 'patient-profile',

	TakenMedications = 'medications',

	HealthIssues = 'health-issues',

	Vaccinations = 'vaccinations',
	VaccinationCard = 'vaccination-card',
	VaccinationInfo = 'info',

	GeneticTests = 'genetic-tests',
	GeneticCard = 'genetic-card',
	GeneticFileUploader = 'dna-upload',
	GeneticTestingInformation = 'info',

	Pricing = 'pricing'

}
