import { Injectable } from '@angular/core';
import { PatientClient } from '@nmn-communication/patients';
import { Result } from '@nmn-core/shared';
import { PatientFilterModel, PatientGetQuery, PatientModel, PatientProfileInformationBlockModel, PatientQueryHandlerService } from '@nmn-domain/patients';
import { FailureModel, PagedCollectionModel, PageOptionsQuery } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapPageOptionsQueryToDto } from '../../shared/factories/pagination.factory';
import { mapDtosToModels, mapDtoToModel, mapFilterModelToDto, mapGetQueryToParameter, mapPatientPagedCollectionDtoToModel, mapProfileDtoToModel } from '../factories/patient.factory';

@Injectable()
export class PatientQueryHandlerViaClientService extends PatientQueryHandlerService {

	constructor(
		private readonly client: PatientClient
	) {
		super();
	}

	public getPagedCollection(query: PageOptionsQuery<PatientFilterModel>): Observable<Result<PagedCollectionModel<PatientModel, PatientFilterModel>, FailureModel>> {
		return this.client
			.getBulk(mapPageOptionsQueryToDto(query, mapFilterModelToDto))
			.pipe(map(result => result.map(mapPatientPagedCollectionDtoToModel, mapFailureToFailureModel)));
	}

	public get(query: PatientGetQuery): Observable<Result<PatientModel, FailureModel>> {
		return this.client
			.get(mapGetQueryToParameter(query))
			.pipe(map(result => result.map(mapDtoToModel, mapFailureToFailureModel)));
	}

	public getProfile(query: PatientGetQuery): Observable<Result<PatientProfileInformationBlockModel, FailureModel>> {
		return this.client
			.getProfile(mapGetQueryToParameter(query))
			.pipe(map(result => result.map(mapProfileDtoToModel, mapFailureToFailureModel)));
	}

	public getActivePatients(): Observable<Result<Array<PatientModel>, FailureModel>> {
		return this.client
			.getActivePatients()
			.pipe(map(result => result.map(mapDtosToModels, mapFailureToFailureModel)));
	}

}
