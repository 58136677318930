import { Injector } from '@angular/core';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { isValueDefined } from '@nmn-core/utils';
import { GeneticTestFakeClientConfiguration } from '../configurations/genetic-test.fake-client.configuration';
import { GeneticTestFakeClient } from '../services/genetic-test.fake-client';

export class GeneticTestFakeClientBuilder {

	private configuration: GeneticTestFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(configuration: GeneticTestFakeClientConfiguration): GeneticTestFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): GeneticTestFakeClient {
		return new GeneticTestFakeClient(this.configuration, this.injector.get(FakeDatabase));
	}

}
