import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { TypeOfMedicationComboboxDto } from '../models/type-of-medication-combobox.dto';
import { TypeOfMedicationFilterDto } from '../models/type-of-medication-filter.dto';

export abstract class TypeOfMedicationClient {

	public abstract getBulkAsComboboxes(
		filter: TypeOfMedicationFilterDto
	): Observable<Result<Array<TypeOfMedicationComboboxDto>, Failure>>;

}
