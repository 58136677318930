import { GeneComboboxModel } from '@nmn-domain/genes';
import { PubmedCitationModel } from './pubmed-citation.model';
import { SourceModel } from './source.model';

export class PersonalizedMedicinePubmedCitationModel extends PubmedCitationModel {

	public readonly gene: GeneComboboxModel;

	constructor(
		pubmedId: string,
		source: SourceModel,
		authors: Array<string>,
		journal: string,
		year: number,
		gene: GeneComboboxModel
	) {
		super(pubmedId, source, authors, journal, year);

		// TODO: Guard: check on defined

		this.gene = gene;
	}

}
