import { TranslocoService } from '@ngneat/transloco';
import { DocumentCreateParameter } from '@nmn-communication/documents';
import { PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import {
	TakenMedicationComboboxDto, TakenMedicationCreateParameterDto, TakenMedicationDto, TakenMedicationFilterDto,
	TakenMedicationFindParameterDto, TakenMedicationFinishCourseBulkParameterDto, TakenMedicationUpdateParameterDto
} from '@nmn-communication/taken-medications';
import { Guid, isArrayDefined, isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../databases/fake.database';
import { getPagedCollection, getPagedCollectionWithoutDtoFilter } from '../databases/fake.utils';

export class TakenMedicationFakeTable {

	private readonly database: FakeDatabase;
	private readonly data: Array<TakenMedicationFakeRecord>;

	constructor(
		database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.database = database;
		this.data = [...initialData];
	}

	public getAsCombobox(parameter: TakenMedicationFindParameterDto): TakenMedicationComboboxDto {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record was not found');
		}

		return record;
	}

	public findAsCombobox(parameter: TakenMedicationFindParameterDto): TakenMedicationComboboxDto {
		const record = this.data.find((item: TakenMedicationFakeRecord) => findPredicate(item, parameter));

		return this.mapFromRecordToDescribedComboboxDto(record);
	}

	public getComboboxesPagedCollection(filter: TakenMedicationFilterDto): Array<TakenMedicationComboboxDto> {
		return getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			filterPredicateForRecord,
			this.mapFromRecordToDescribedComboboxDto.bind(this),
			compareAsComboboxesFn
		).items;
	}

	public getPagedCollection(pageOptions: PageOptionsDto<TakenMedicationFilterDto>): PagedCollectionDto<TakenMedicationDto, TakenMedicationFilterDto> {
		return getPagedCollection(
			this.translocoService,
			this.data,
			pageOptions,
			filterPredicateForRecord,
			this.mapFromRecordToDto.bind(this),
			filterPredicateForDto,
			compareFn
		);
	}

	public find(findParameter: TakenMedicationFindParameterDto): TakenMedicationDto {
		const record = this.data.find((item: TakenMedicationFakeRecord) => findPredicate(item, findParameter));

		return this.mapFromRecordToDto(record);
	}

	public create(parameter: TakenMedicationCreateParameterDto): string {
		const record = mapFromCreateParameterToRecord(parameter);
		this.data.push(record);
		this.createAndAttachRelations(record, parameter.documents);

		if (isValueDefined(parameter.healthIssueId)) {
			this.database.healthIssueTable.attachTakenMedication({ id: parameter.healthIssueId, patientId: record.patientId }, record.id);
		}
		if (isValueDefined(parameter.encounterId)) {
			this.database.doctorEncounterTable.attachTakenMedication({ id: parameter.encounterId, patientId: record.patientId }, record.id);
		}
		if (isArrayDefinedAndNotEmpty(parameter.documentIds)) {
			parameter.documentIds.forEach(documentId => {
				this.database.documentsTable.attachTakenMedication({ id: documentId, patientId: record.patientId }, record.id);
			});
		}

		return record.id;
	}

	public update(findParameter: TakenMedicationFindParameterDto, updateParameter: TakenMedicationUpdateParameterDto): void {
		const record = this.data.find((item: TakenMedicationFakeRecord) => findPredicate(item, findParameter));

		if (!isValueDefined(record)) {
			throw new Error('Taken medication to update was not found');
		}

		applyUpdateParameter(record, updateParameter);
		this.createAndAttachRelations(record, updateParameter.documents);

		if (isValueDefined(updateParameter.healthIssueId)) {
			this.database.healthIssueTable.attachTakenMedication({ id: updateParameter.healthIssueId, patientId: record.patientId }, record.id);
		}
		if (isValueDefined(updateParameter.encounterId)) {
			this.database.doctorEncounterTable.attachTakenMedication({ id: updateParameter.encounterId, patientId: record.patientId }, record.id);
		}
		if (isArrayDefinedAndNotEmpty(updateParameter.documentIds)) {
			updateParameter.documentIds.forEach(documentId => {
				this.database.documentsTable.attachTakenMedication({ id: documentId, patientId: record.patientId }, record.id);
			});
		}

		this.database.healthIssueTable.detachTakenMedicationForAll(
			record.id,
			record.patientId,
			isValueDefined(updateParameter.healthIssueId) ? [updateParameter.healthIssueId] : undefined
		);
		this.database.doctorEncounterTable.detachTakenMedicationForAll(
			record.id,
			record.patientId,
			isValueDefined(updateParameter.encounterId) ? [updateParameter.encounterId] : undefined
		);
		this.database.documentsTable.detachTakenMedicationForAll(record.id, updateParameter.documentIds);
	}

	public delete(findParameter: TakenMedicationFindParameterDto): void {
		const index = this.data.findIndex((item: TakenMedicationFakeRecord) => findPredicate(item, findParameter));

		if (index >= 0) {
			this.data.splice(index, 1);
		}

		this.database.documentsTable.detachTakenMedicationForAll(findParameter.id, undefined);
		this.database.healthIssueTable.detachTakenMedicationForAll(findParameter.id, findParameter.patientId, undefined);
		this.database.doctorEncounterTable.detachTakenMedicationForAll(findParameter.id, findParameter.patientId, undefined);
	}

	public finishCourse(findParameter: TakenMedicationFindParameterDto): void {
		const record = this.data.find((item: TakenMedicationFakeRecord) => findPredicate(item, findParameter));

		record.medicationAdmStatusId = 'completed';

		if (!isValueDefined(record.to)) {
			record.to = new Date().toString();
		}
	}

	public finishCourseBulk(parameter: TakenMedicationFinishCourseBulkParameterDto): void {
		const items = this.data.filter((item: TakenMedicationFakeRecord) => findPredicateBulk(item, { ids: parameter.candidates }));

		if (items.length >= 0) {
			items.forEach(item => { this.finishCourse({ id: item.id, patientId: item.patientId }); });
		}
	}

	public failCourse(findParameter: TakenMedicationFindParameterDto): void {
		const record = this.data.find((item: TakenMedicationFakeRecord) => findPredicate(item, findParameter));
		record.medicationAdmStatusId = 'failed';

		if (!isValueDefined(record.to)) {
			record.to = new Date().toString();
		}
	}

	private createAndAttachRelations(record: TakenMedicationFakeRecord, documents: Array<DocumentCreateParameter>): void {
		for (const document of documents) {
			if (isArrayDefinedAndNotEmpty(document.relatedHealthIssueIds)) {
				document.relatedHealthIssueIds.push(record.id);
			} else {
				document.relatedHealthIssueIds = [record.id];
			}

			this.database.documentsTable.create(document);
		}
	}

	private mapFromRecordToDescribedComboboxDto(record: TakenMedicationFakeRecord): TakenMedicationComboboxDto {
		if (!isValueDefined(record)) {
			return undefined;
		}

		const medication = this.database.medicationsTable.findAsCombobox({ id: record.medicationId });

		return {
			id: record.id,
			displayText: medication?.displayText,
			description: medication?.description,
			from: record.from,
			to: record.to,
			medicationType: medication.type
		};
	}

	private mapFromRecordToDto(record: TakenMedicationFakeRecord): TakenMedicationDto {
		return {
			id: record.id,
			from: record.from,
			to: record.to,
			comment: record.comment,
			dosageValue: record.dosageValue,
			rateValue: record.rateValue,
			patientId: record.patientId,
			medication: this.database.medicationsTable.findAsCombobox({ id: record.medicationId }),
			encounter: this.database.doctorEncounterTable.findAsCombobox({ takenMedicationId: record.id, patientId: record.patientId }),
			healthIssue: this.database.healthIssueTable.findAsCombobox({ takenMedicationId: record.id, patientId: record.patientId }),
			medicationAdmRoute: this.database.medicationAdmRoutesTable.findAsCombobox({ id: record.medicationAdmRouteId }),
			medicationAdmStatus: this.database.medicationAdmStatusesTable.findAsCombobox({ id: record.medicationAdmStatusId }),
			medicationDosage: this.database.medicationDosageTypesTable.findAsCombobox({ id: record.medicationDosageId }),
			medicationBodySite: this.database.medicationBodySitesTable.findAsCombobox({ id: record.medicationBodySiteId }),
			medicationRateQuantityType: this.database.medicationRateQuantityTypesTable.findAsCombobox({ id: record.medicationRateQuantityTypeId }),
			isIncompatibleWithAlcohol: record.isIncompatibleWithAlcohol,
			isEfficacyUnproven: record.isEfficacyUnproven,
			isIncompatibleWithOtherTakenMedication: record.isIncompatibleWithOtherTakenMedication,
			incompatibleTakenMedications: record.incompatibleTakenMedications,
			documents: this.database.documentsTable.getComboboxesPagedCollection({ takenMedicationIds: [record.id] }),
			typeOfMedication: this.database.typesOfMedicationTable.findAsCombobox({ id: record.typeOfMedicationId })
		};
	}

}

const mapFromCreateParameterToRecord = (parameter: TakenMedicationCreateParameterDto): TakenMedicationFakeRecord => (
	{
		id: Guid.newGuid(),
		from: parameter.from,
		to: parameter.to,
		comment: parameter.comment,
		dosageValue: parameter.dosageValue,
		rateValue: parameter.rateValue,
		patientId: parameter.patientId,
		medicationId: parameter.medicationId,
		medicationAdmRouteId: parameter.medicationAdmRouteId,
		medicationAdmStatusId: parameter.medicationAdmStatusId,
		medicationDosageId: parameter.medicationDosageId,
		medicationBodySiteId: parameter.medicationBodySiteId,
		medicationRateQuantityTypeId: parameter.medicationRateQuantityTypeId,
		isIncompatibleWithAlcohol: (Math.floor(Math.random() * 2) + 1) === 1,
		isEfficacyUnproven: (Math.floor(Math.random() * 4) + 1) === 1,
		isIncompatibleWithOtherTakenMedication: (Math.floor(Math.random() * 3) + 1) === 1,
		incompatibleTakenMedications: ['medication1', 'medication2', 'medication2'],
		typeOfMedicationId: parameter.typeOfMedicationId
	}
);

const applyUpdateParameter = (record: TakenMedicationFakeRecord, updateParameter: TakenMedicationUpdateParameterDto): void => {
	record.from = updateParameter.from;
	record.to = updateParameter.to;
	record.comment = updateParameter.comment;
	record.patientId = updateParameter.patientId;
	record.medicationId = updateParameter.medicationId;
	record.dosageValue = updateParameter.dosageValue;
	record.rateValue = updateParameter.rateValue;
	record.medicationAdmRouteId = updateParameter.medicationAdmRouteId;
	record.medicationAdmStatusId = updateParameter.medicationAdmStatusId;
	record.medicationDosageId = updateParameter.medicationDosageId;
	record.medicationBodySiteId = updateParameter.medicationBodySiteId;
	record.medicationRateQuantityTypeId = updateParameter.medicationRateQuantityTypeId;
	record.typeOfMedicationId = updateParameter.typeOfMedicationId;
};

const filterPredicateForRecord = (item: TakenMedicationFakeRecord, filter: TakenMedicationFilterDto, _: TranslocoService): boolean => {
	let result = true;

	if (result && isArrayDefined(filter.ids)) {
		result = result && filter.ids.some(id => item.id === id);
	}

	if (result && isArrayDefined(filter.ignoreIds)) {
		result = result && filter.ignoreIds.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isArrayDefined(filter.patientIds)) {
		// todo: implement to support patient aliases too
		// result = result && filter.patientIds.some(patientId => item.patientId === patientId);
	}

	if (result && isArrayDefined(filter.encounterIds)) {
		// todo: implement
	}

	if (result && isArrayDefined(filter.onlyDetachedOrAttachedToHealthIssueId)) {
		// todo: implement
	}

	return result;
};

// eslint-disable-next-line complexity
const filterPredicateForDto = (item: TakenMedicationDto, filter: TakenMedicationFilterDto): boolean => {
	let result = true;

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result &&
			(
				item.from.indexOf(filter.searchPattern) >= 0 ||
				item.to?.indexOf(filter.searchPattern) >= 0 ||
				item.medication.displayText.indexOf(filter.searchPattern) >= 0 ||
				item.encounter?.displayText?.indexOf(filter.searchPattern) >= 0 ||
				item.healthIssue?.displayText?.indexOf(filter.searchPattern) >= 0 ||
				item.medicationAdmRoute.displayText.indexOf(filter.searchPattern) >= 0 ||
				item.medicationAdmStatus.displayText.indexOf(filter.searchPattern) >= 0 ||
				item.medicationDosage.displayText.indexOf(filter.searchPattern) >= 0 ||
				item.medicationBodySite.displayText.indexOf(filter.searchPattern) >= 0 ||
				item.medicationRateQuantityType.displayText.indexOf(filter.searchPattern) >= 0
			);
	}

	if (result && isArrayDefined(filter.ignoreIds)) {
		result = result && filter.ignoreIds.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isArrayDefined(filter.patientIds)) {
		result = result && filter.patientIds.some(patientId => item.patientId === patientId);
	}

	if (result && isArrayDefined(filter.encounterIds)) {
		result = result && filter.encounterIds.some(id => item.encounter?.id === id);
	}

	if (result && isArrayDefined(filter.onlyDetachedOrAttachedToHealthIssueId)) {
		result = result &&
			(
				!isValueDefined(item.encounter) ||
				filter.onlyDetachedOrAttachedToHealthIssueId.some(id => item.encounter?.id === id)
			);
	}

	if (result && isValueDefined(filter.isIncompatibleWithOtherTakenMedication)) {
		result = result && filter.isIncompatibleWithOtherTakenMedication === item.isIncompatibleWithOtherTakenMedication;
	}

	if (result && isValueDefined(filter.isEfficacyUnproven)) {
		result = result && filter.isEfficacyUnproven === item.isEfficacyUnproven;
	}

	if (result && isValueDefined(filter.isIncompatibleWithAlcohol)) {
		result = result && filter.isIncompatibleWithAlcohol === item.isIncompatibleWithAlcohol;
	}

	return result;
};

const findPredicate = (item: TakenMedicationFakeRecord, findParameter: TakenMedicationFindParameterDto): boolean => {
	if (isValueDefined(findParameter)) {
		return item.id === findParameter.id;
	}

	return true;
};

const findPredicateBulk = (item: TakenMedicationFakeRecord, findParameter: TakenMedicationFilterDto): boolean => {
	if (isValueDefined(findParameter)) {
		return isValueDefined(findParameter.ids) && findParameter.ids.indexOf(item.id) > -1;
	}

	return true;
};

/* eslint-disable  */
/* eslint-disable complexity */
const compareAsComboboxesFn = (
	item1: TakenMedicationComboboxDto,
	item2: TakenMedicationComboboxDto,
	sorting: string
): number => {
	if (sorting === 'displayText asc') {
		return item1.displayText > item2.displayText ? 1 : item1.displayText < item2.displayText ? -1 : 0;
	} else if (sorting === 'displayText desc') {
		return item1.displayText < item2.displayText ? 1 : item1.displayText > item2.displayText ? -1 : 0;
	}

	return 0;
};

const compareFn = (item1: TakenMedicationDto, item2: TakenMedicationDto, sorting: string): number => {
	if (sorting === 'medication.displayText asc') {

		return item1.medication.displayText > item2.medication.displayText ?
			1 :
			item1.medication.displayText < item2.medication.displayText ? -1 : 0;
	} else if (sorting === 'medication.displayText desc') {

		return item1.medication.displayText < item2.medication.displayText ?
			1 :
			item1.medication.displayText > item2.medication.displayText ? -1 : 0;
	} else if (sorting === 'from asc') {

		return item1.from > item2.from ? 1 : item1.from < item2.from ? -1 : 0;
	} else if (sorting === 'from desc') {

		return item1.from < item2.from ? 1 : item1.from > item2.from ? -1 : 0;
	} else if (sorting === 'period asc') {
		if (!isStringDefinedAndNotEmpty(item1.to) && isStringDefinedAndNotEmpty(item2.to)) {
			return 1;
		} else if (isStringDefinedAndNotEmpty(item1.to) && !isStringDefinedAndNotEmpty(item2.to)) {
			return -1;
		}

		return item1.from > item2.from ? 1 : item1.from < item2.from ? -1 : 0;
	} else if (sorting === 'period desc') {
		if (!isStringDefinedAndNotEmpty(item1.to) && isStringDefinedAndNotEmpty(item2.to)) {
			return -1;
		} else if (isStringDefinedAndNotEmpty(item1.to) && !isStringDefinedAndNotEmpty(item2.to)) {
			return 1;
		}

		return item1.from < item2.from ? 1 : item1.from > item2.from ? -1 : 0;
	}

	return 0;
};
/* eslint-enable complexity */
/* eslint-enable */

interface TakenMedicationFakeRecord {

	id: string;
	from?: string;
	to?: string;
	comment?: string;
	dosageValue?: number;
	rateValue?: number;
	patientId: string;
	medicationId: string;
	medicationAdmRouteId?: string;
	medicationAdmStatusId: string;
	medicationDosageId?: string;
	medicationBodySiteId?: string;
	medicationRateQuantityTypeId?: string;
	isIncompatibleWithAlcohol: boolean;
	isEfficacyUnproven: boolean;
	isIncompatibleWithOtherTakenMedication: boolean;
	incompatibleTakenMedications: Array<string>;
	typeOfMedicationId: string;

}

// TakenMedicationFakeRecord (initial data) has id mask 00000000-0000-0000-0201-************
const initialData: Array<TakenMedicationFakeRecord> = [
	{
		id: '00000000-0000-0000-0201-100000000001',
		from: '2021-02-02',
		to: '2021-02-19',
		comment: undefined,
		dosageValue: 5,
		rateValue: 10,
		patientId: '00000000-0000-0000-0002-000000000001',
		medicationId: 'advil',
		medicationAdmRouteId: '1',
		medicationAdmStatusId: 'active',
		medicationDosageId: '1',
		medicationBodySiteId: '1',
		medicationRateQuantityTypeId: '1',
		isIncompatibleWithAlcohol: false,
		isEfficacyUnproven: false,
		isIncompatibleWithOtherTakenMedication: true,
		incompatibleTakenMedications: ['acetaminophen'],
		typeOfMedicationId: 'Collodion'
	},
	{
		id: '00000000-0000-0000-0201-200000000001',
		from: '2020-09-12T12:00:00',
		to: '2020-09-15T12:00:00',
		comment: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium.',
		dosageValue: 1,
		rateValue: 2,
		patientId: '00000000-0000-0000-0002-000000000001',
		medicationId: 'levofloxacin',
		medicationAdmRouteId: '1',
		medicationAdmStatusId: 'active',
		medicationDosageId: '1',
		medicationBodySiteId: '1',
		medicationRateQuantityTypeId: '1',
		isIncompatibleWithAlcohol: false,
		isEfficacyUnproven: false,
		isIncompatibleWithOtherTakenMedication: false,
		incompatibleTakenMedications: [],
		typeOfMedicationId: 'Collodion'
	},
	{
		id: '00000000-0000-0000-0201-200000000002',
		from: '2020-09-12T12:00:00',
		to: '2020-09-15T12:00:00',
		comment: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.',
		dosageValue: 5,
		rateValue: 20,
		patientId: '00000000-0000-0000-0002-000000000001',
		medicationId: 'imodium',
		medicationAdmRouteId: '1',
		medicationAdmStatusId: 'active',
		medicationDosageId: '1',
		medicationBodySiteId: '1',
		medicationRateQuantityTypeId: '1',
		isIncompatibleWithAlcohol: false,
		isEfficacyUnproven: false,
		isIncompatibleWithOtherTakenMedication: false,
		incompatibleTakenMedications: [],
		typeOfMedicationId: 'Collodion'
	},
	{
		id: '00000000-0000-0000-0201-300000000001',
		from: '2020-04-11T12:00:00',
		to: '2020-04-21T12:00:00',
		comment: undefined,
		dosageValue: 5,
		rateValue: 10,
		patientId: '00000000-0000-0000-0002-000000000001',
		medicationId: 'acyclovir',
		medicationAdmRouteId: '1',
		medicationAdmStatusId: 'active',
		medicationDosageId: '1',
		medicationBodySiteId: '1',
		medicationRateQuantityTypeId: '1',
		isIncompatibleWithAlcohol: false,
		isEfficacyUnproven: false,
		isIncompatibleWithOtherTakenMedication: false,
		incompatibleTakenMedications: [],
		typeOfMedicationId: 'Collodion'
	},
	{
		id: '00000000-0000-0000-0201-400000000001',
		from: '2018-12-04T12:00:00',
		to: undefined,
		comment: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
		dosageValue: 5,
		rateValue: 10,
		patientId: '00000000-0000-0000-0002-000000000001',
		medicationId: 'celecoxib',
		medicationAdmRouteId: '1',
		medicationAdmStatusId: 'active',
		medicationDosageId: '1',
		medicationBodySiteId: '1',
		medicationRateQuantityTypeId: '1',
		isIncompatibleWithAlcohol: true,
		isEfficacyUnproven: false,
		isIncompatibleWithOtherTakenMedication: false,
		incompatibleTakenMedications: [],
		typeOfMedicationId: 'Collodion'
	},
	{
		id: '00000000-0000-0000-0201-400000000002',
		from: undefined,
		to: undefined,
		comment: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
		dosageValue: 5,
		rateValue: 10,
		patientId: '00000000-0000-0000-0002-000000000001',
		medicationId: 'acetaminophen',
		medicationAdmRouteId: '1',
		medicationAdmStatusId: 'active',
		medicationDosageId: '1',
		medicationBodySiteId: '1',
		medicationRateQuantityTypeId: '1',
		isIncompatibleWithAlcohol: true,
		isEfficacyUnproven: false,
		isIncompatibleWithOtherTakenMedication: true,
		incompatibleTakenMedications: ['advil'],
		typeOfMedicationId: 'Collodion'
	},
	{
		id: '00000000-0000-0000-0201-400000000003',
		from: '2018-12-04T12:00:00',
		to: undefined,
		comment: undefined,
		dosageValue: 5,
		rateValue: 10,
		patientId: '00000000-0000-0000-0002-000000000001',
		medicationId: 'aspirin',
		medicationAdmRouteId: '1',
		medicationAdmStatusId: 'active',
		medicationDosageId: '1',
		medicationBodySiteId: '1',
		medicationRateQuantityTypeId: '1',
		isIncompatibleWithAlcohol: false,
		isEfficacyUnproven: false,
		isIncompatibleWithOtherTakenMedication: false,
		incompatibleTakenMedications: [],
		typeOfMedicationId: 'Collodion'
	},
	{
		id: '00000000-0000-0000-0201-500000000001',
		from: '2018-01-09T12:00:00',
		to: '2018-02-16T12:00:00',
		comment: undefined,
		dosageValue: 5,
		rateValue: 10,
		patientId: '00000000-0000-0000-0002-000000000001',
		medicationId: 'dexlansoprazole',
		medicationAdmRouteId: '1',
		medicationAdmStatusId: 'active',
		medicationDosageId: '1',
		medicationBodySiteId: '1',
		medicationRateQuantityTypeId: '1',
		isIncompatibleWithAlcohol: true,
		isEfficacyUnproven: false,
		isIncompatibleWithOtherTakenMedication: false,
		incompatibleTakenMedications: [],
		typeOfMedicationId: 'Collodion'
	},
	{
		id: '00000000-0000-0000-0201-500000000002',
		from: '2018-12-04T12:00:00',
		to: undefined,
		comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
		dosageValue: 5,
		rateValue: 10,
		patientId: '00000000-0000-0000-0002-000000000001',
		medicationId: 'mylanta',
		medicationAdmRouteId: '1',
		medicationAdmStatusId: 'active',
		medicationDosageId: '1',
		medicationBodySiteId: '1',
		medicationRateQuantityTypeId: '1',
		isIncompatibleWithAlcohol: true,
		isEfficacyUnproven: false,
		isIncompatibleWithOtherTakenMedication: false,
		incompatibleTakenMedications: [],
		typeOfMedicationId: 'Collodion'
	},
	{
		id: '00000000-0000-0000-0201-600000000001',
		from: '2015-03-19T12:00:00',
		to: '2015-04-01T12:00:00',
		comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
		dosageValue: 5,
		rateValue: 10,
		patientId: '00000000-0000-0000-0002-000000000001',
		medicationId: 'albuterol',
		medicationAdmRouteId: '1',
		medicationAdmStatusId: 'active',
		medicationDosageId: '1',
		medicationBodySiteId: '1',
		medicationRateQuantityTypeId: '1',
		isIncompatibleWithAlcohol: false,
		isEfficacyUnproven: false,
		isIncompatibleWithOtherTakenMedication: false,
		incompatibleTakenMedications: [],
		typeOfMedicationId: 'Collodion'
	},
	{
		id: '00000000-0000-0000-0201-600000000002',
		from: '2015-03-20T12:00:00',
		to: '2015-07-16T12:00:00',
		comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
		dosageValue: 5,
		rateValue: 10,
		patientId: '00000000-0000-0000-0002-000000000001',
		medicationId: 'serevent-diskus',
		medicationAdmRouteId: '1',
		medicationAdmStatusId: 'active',
		medicationDosageId: '1',
		medicationBodySiteId: '1',
		medicationRateQuantityTypeId: '1',
		isIncompatibleWithAlcohol: false,
		isEfficacyUnproven: false,
		isIncompatibleWithOtherTakenMedication: false,
		incompatibleTakenMedications: [],
		typeOfMedicationId: 'Collodion'
	},
	{
		id: '00000000-0000-0000-0201-700000000001',
		from: '2018-02-04T12:00:00',
		to: '2018-03-16T12:00:00',
		comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
		dosageValue: 5,
		rateValue: 10,
		patientId: '00000000-0000-0000-0002-000000000001',
		medicationId: 'takra-vasti',
		medicationAdmRouteId: '1',
		medicationAdmStatusId: 'active',
		medicationDosageId: '1',
		medicationBodySiteId: '1',
		medicationRateQuantityTypeId: '1',
		isIncompatibleWithAlcohol: false,
		isEfficacyUnproven: true,
		isIncompatibleWithOtherTakenMedication: false,
		incompatibleTakenMedications: [],
		typeOfMedicationId: 'Collodion'
	}
];

// eslint-disable-next-line max-lines
