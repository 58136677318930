
import { GeneticFileParseRequestStatus } from './genetic-file-parse-request-status.enum';

export class GeneticFileParseRequestFilterModel {

	public readonly patientId: string;
	public readonly isActive: boolean;
	public readonly status: GeneticFileParseRequestStatus | undefined;

	constructor(
		patientId: string,
		isActive: boolean,
		status: GeneticFileParseRequestStatus | undefined
	) {
		// TODO: Guard check on defined

		this.patientId = patientId;
		this.isActive = isActive;
		this.status = status;
	}

}
