import { GoogleAnalyticsEvent } from '../../models/google-analytics-event.enum';

export class GaEventTrackingGeneralService {

	constructor(
		private readonly trackEvent: (event: GoogleAnalyticsEvent, data?: any, completeFn?: () => void) => void
	) { }

	public sentMessageViaContactForm(completeFn?: () => void): void {
		this.trackEvent(GoogleAnalyticsEvent.SentMessageViaContactForm, {}, completeFn);
	}

}
