import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FailureModel } from '../../shared/failures/failure.model';
import { HealthIssueSeverityComboboxModel } from '../models/health-issue-severity-combobox.model';
import { HealthIssueSeverityFilterModel } from '../models/health-issue-severity-filter.model';

export abstract class HealthIssueSeverityQueryHandlerService {

	public abstract getAsDescribedComboboxes(
		query: HealthIssueSeverityFilterModel
	): Observable<Result<Array<HealthIssueSeverityComboboxModel>, FailureModel>>;

}
