import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../../../../communication/implementation/shared/builders/http-client.builder';
import { UserProfileHttpClientConfiguration } from '../configurations/user-profile.http-client.configuration';
import { UserProfileHttpClient } from '../services/user-profile.http-client';

export class UserProfileHttpClientBuilder
	extends HttpClientBuilder<UserProfileHttpClient, UserProfileHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): UserProfileHttpClient {
		return new UserProfileHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
