import { DatePipe } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';
import { isArrayDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { DiseaseComboboxModel } from '../../diseases/models/disease-combobox.model';
import { DescribedComboboxModel } from '../../shared/comboboxes/described-combobox.model';
import { DoctorEncounterStatus } from './doctor-encounter-status.enum';

export class DoctorEncounterComboboxModel extends DescribedComboboxModel {

	public readonly diseases: Array<DiseaseComboboxModel>;
	public readonly date?: Date;
	public readonly status: string;

	public get isDateDefined(): boolean {
		return isValueDefined(this.date);
	}

	public get isDiseasesDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.diseases);
	}

	public get diseasesAsCommaSeparatedString(): string {
		return this.isDiseasesDefined ?
			this.diseases
				.map(item => item.displayText)
				.join(', ') :
			'';
	}

	constructor(
		id: string,
		displayText: string,
		description: string,
		diseases: Array<DiseaseComboboxModel>,
		date: Date | undefined,
		status: DoctorEncounterStatus
	) {
		super(id, displayText, description);

		// TODO: Guard check on defined
		this.diseases = diseases;
		this.date = date;
		this.status = status;
	}

	public static createDisplayOptionFn(
		translocoService: TranslocoService
	): (option: DoctorEncounterComboboxModel) => string | undefined {
		return (option: DoctorEncounterComboboxModel): string | undefined => {
			if (!isValueDefined(option)) {
				return undefined;
			}

			if (!option.isDateDefined) {
				return translocoService?.translate(
					'inlineViews.doctorEncounter.value_displayText',
					{
						displayText: option.displayText
					}
				);
			}

			if (option.isDateDefined) {
				const datePipe = new DatePipe(translocoService.getActiveLang());

				return translocoService?.translate(
					'inlineViews.doctorEncounter.value_displayText_date',
					{
						displayText: option.displayText,
						date: datePipe.transform(option.date, 'yyyy-MM-dd', undefined, 'en-US')
					}
				);
			}

			return undefined;
		};
	}

}
