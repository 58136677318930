import { Injector } from '@angular/core';
import { GeneClient } from '@nmn-communication/genes';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { GeneFakeClientBuilder } from '../builders/gene.fake-client.builder';
import { GeneFakeClientConfigurationBuilder } from '../builders/gene.fake-client.configuration.builder';
import { GeneHttpClientBuilder } from '../builders/gene.http-client.builder';
import { GeneHttpClientConfigurationBuilder } from '../builders/gene.http-client.configuration.builder';
import { GeneFakeClient } from '../services/gene.fake-client';
import { GeneHttpClient } from '../services/gene.http-client';

export const geneClientFactory = (injector: Injector): GeneClient => {
	const useFake = environment?.api?.resources?.genes?.useFake || !isValueDefined(environment?.api?.resources?.genes?.useFake) && environment.api.defaultUseFake;

	return useFake ? geneFakeClientFactory(injector) : geneHttpClientFactory(injector);
};

export const geneFakeClientFactory = (injector: Injector): GeneFakeClient => {
	const configuration = new GeneFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.genes.serverDelayMs)
		.build();

	return new GeneFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const geneHttpClientFactory = (injector: Injector): GeneHttpClient => {
	const configuration = new GeneHttpClientConfigurationBuilder()
		.withBaseUrl(environment?.api?.resources?.genes?.baseUrl ?? environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.genes.resourceRelativePath)
		.withResourceTypeVersion(environment?.api?.resources?.genes?.resourceTypeVersion ?? environment?.api?.defaultResourceTypeVersion)
		.build();

	return new GeneHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
