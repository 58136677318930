import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginDto } from '@nmn-communication/accounts';
import {
	PatientClient, PatientCreateParameter, PatientDeleteParameter, PatientDto, PatientFilterDto, PatientFindParameter,
	PatientProfileDto, PatientRevokeDeleteParameter, PatientUpdateParameter
} from '@nmn-communication/patients';
import { Failure, PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { constructHttpParams } from '@nmn-core/utils';
import { Observable } from 'rxjs';
import {
	createEmptyResultFromResponse, createFailedEmptyResultFromErrorResponse, createFailedResultFromErrorResponse,
	createResultFromResponse, createResultFromResponseViaIdStringProjection
} from '../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { PatientHttpClientConfiguration } from '../configurations/patient.http-client.configuration';

export class PatientHttpClient extends PatientClient {

	constructor(
		private readonly httpClient: HttpClient,
		private readonly configuration: PatientHttpClientConfiguration
	) {
		super();
	}

	public get(parameter: PatientFindParameter): Observable<Result<PatientDto, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/${parameter.alias}`;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<PatientDto>(url, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public getProfile(parameter: PatientFindParameter): Observable<Result<PatientProfileDto, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/${parameter.alias}/profile`;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<PatientProfileDto>(url, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public getBulk(
		parameter: PageOptionsDto<PatientFilterDto>
	): Observable<Result<PagedCollectionDto<PatientDto, PatientFilterDto>, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`;
		const params = constructHttpParams(parameter);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<PagedCollectionDto<PatientDto, PatientFilterDto>>(url, { params, headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public getActivePatients(): Observable<Result<Array<PatientDto>, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/all`;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<Array<PatientDto>>(url, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public create(parameter: PatientCreateParameter): Observable<Result<string, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`;
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post<PatientDto>(url, payload, { headers })
			.pipe(
				createResultFromResponseViaIdStringProjection(),
				createFailedResultFromErrorResponse()
			);
	}

	public update(
		findParameter: PatientFindParameter,
		updateParameter: PatientUpdateParameter
	): Observable<EmptyResult<Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/${findParameter.alias}`;
		const payload = updateParameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put<PatientDto>(url, payload, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

	public delete(parameter: PatientDeleteParameter): Observable<Result<LoginDto, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/${parameter.alias}/deactivate/request`;
		const payload = { password: parameter.password };
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put<LoginDto>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public revokeDelete(parameter: PatientRevokeDeleteParameter): Observable<Result<LoginDto, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/${parameter.alias}/deactivate/revoke`;
		const payload = { password: parameter.password };
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put<LoginDto>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

}
