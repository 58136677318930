import { isArrayDefinedAndNotEmpty } from '@nmn-core/utils';
import { SourceModel } from '@nmn-domain/sources';
import { TreatmentCheckerReportMedicationPgxAffectedSubgroupModel } from './treatment-checker-medication.pgx-affected-subgroup.model';
import { TreatmentCheckerReportMedicationPgxLevelTestingAnnotation } from './treatment-checker-report-medication.pgx-level-testing-annotation.enum';
import { TreatmentCheckerReportMedicationPgxLevelTestingOrganization } from './treatment-checker-report-medication.pgx-level-testing-organization.enum';
import { TreatmentCheckerReportMedicationPgxLevelTestingType } from './treatment-checker-report-medication.pgx-level-testing-type.enum';

export class TreatmentCheckerReportMedicationPgxLevelTestingModel {

	public readonly id: string;
	public readonly type: TreatmentCheckerReportMedicationPgxLevelTestingType;
	public readonly organization: TreatmentCheckerReportMedicationPgxLevelTestingOrganization;
	public readonly source: SourceModel;
	public readonly annotations: Array<TreatmentCheckerReportMedicationPgxLevelTestingAnnotation>;
	public readonly affectedSubgroups: Array<TreatmentCheckerReportMedicationPgxAffectedSubgroupModel>;

	public get isAffectedSubgroupsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.affectedSubgroups);
	}

	constructor(
		id: string,
		type: TreatmentCheckerReportMedicationPgxLevelTestingType,
		organization: TreatmentCheckerReportMedicationPgxLevelTestingOrganization,
		source: SourceModel,
		annotations: Array<TreatmentCheckerReportMedicationPgxLevelTestingAnnotation>,
		affectedSubgroups: Array<TreatmentCheckerReportMedicationPgxAffectedSubgroupModel>
	) {
		// TODO: Guard

		this.id = id;
		this.type = type;
		this.organization = organization;
		this.source = source;
		this.annotations = annotations;
		this.affectedSubgroups = affectedSubgroups;
	}

}
