import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { DescribedComboboxDto, Failure } from '../../shared';
import { BloodTypeFilterDto } from '../models/blood-type-filter.dto';

export abstract class BloodTypeClient {

	public abstract getBulkAsComboboxes(filter: BloodTypeFilterDto): Observable<Result<Array<DescribedComboboxDto<string>>, Failure>>;

}
