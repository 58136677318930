import { isValueDefined } from '@nmn-core/utils';

export class PageOptionsQuery<TFilter> {

	public readonly sorting: string;
	public readonly filter: TFilter;
	public readonly pageIndex: number;
	public readonly pageSize: number;
	public readonly indexFrom: number;

	constructor(
		sorting: string,
		filter: TFilter,
		pageIndex: number,
		pageSize: number,
		indexFrom?: number
	) {

		// TODO: check on null

		this.sorting = sorting;
		this.filter = filter;
		this.pageIndex = pageIndex;
		this.pageSize = pageSize;
		this.indexFrom = isValueDefined(indexFrom) ? indexFrom : globalIndexFrom;

	}

}

// TODO: move to application constants
const globalIndexFrom = 0;
