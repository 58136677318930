import { TranslocoService } from '@ngneat/transloco';
import { LanguageFilterDto, LanguageFindParameterDto } from '@nmn-communication/languages';
import { ComboboxDto } from '@nmn-communication/shared';
import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../databases/fake.database';
import { getPagedCollectionWithoutDtoFilter } from '../../databases/fake.utils';
import { FakeComboboxDto, mapFakeComboboxDtoToComboboxDto } from '../../models//comboboxes/fake-combox.dto';
import { FakeLocalizableEntity } from '../../models/fake-localizable-entity';
import { setTranslation } from '../../utils/localize';

export class LanguageFakeTable {

	private readonly data: Array<LanguageFakeRecord>;

	constructor(
		_: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.data = [...initialData];
	}

	public getAsCombobox(parameter: LanguageFindParameterDto): ComboboxDto<string> {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record was not found');
		}

		return record;
	}

	public findAsCombobox(parameter: LanguageFindParameterDto): ComboboxDto<string> {
		const record = this.data.find((item: LanguageFakeRecord) => findPredicate(item, parameter));
		const comboboxRecord = mapFromRecordToComboboxDto(record);

		return mapFakeComboboxDtoToComboboxDto(this.translocoService, comboboxRecord);
	}

	public getComboboxesPagedCollection(filter: LanguageFilterDto): Array<ComboboxDto<string>> {
		const pagedCollection = getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			filterPredicateForRecord,
			mapFromRecordToComboboxDto,
			compareFn
		).items;

		return pagedCollection.map(item => mapFakeComboboxDtoToComboboxDto(this.translocoService, item));
	}

}

const mapFromRecordToComboboxDto = (record: LanguageFakeRecord): FakeComboboxDto<string> => (
	{
		id: record.id,
		displayText: record.name
	}
);

const findPredicate = (
	item: LanguageFakeRecord,
	findParameter: LanguageFindParameterDto
): boolean =>
	item.id === findParameter.id;

const filterPredicateForRecord = (
	item: LanguageFakeRecord,
	filter: LanguageFilterDto,
	translocoService: TranslocoService
): boolean => {
	let result = true;

	if (result && isArrayDefinedAndNotEmpty(filter.ids)) {
		result = result && filter.ids.some(id => item.id === id);
	}

	if (result && isArrayDefinedAndNotEmpty(filter.ignoreIds)) {
		result = result && filter.ignoreIds.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result &&
			setTranslation(translocoService, item.name)
				.toLowerCase()
				.indexOf(filter.searchPattern.toLowerCase()) >= 0;
	}

	return result;
};

// eslint-disable
// eslint-disable-next-line complexity
const compareFn = (
	item1: FakeComboboxDto<string>,
	item2: FakeComboboxDto<string>,
	sorting: string
): number => {
	if (sorting === 'displayText asc') {
		return item1.displayText > item2.displayText ? 1 : item1.displayText < item2.displayText ? -1 : 0;
	} else if (sorting === 'displayText desc') {
		return item1.displayText < item2.displayText ? 1 : item1.displayText > item2.displayText ? -1 : 0;
	}

	return 0;
};
// eslint-enable

interface LanguageFakeRecord {
	id: string;
	name: FakeLocalizableEntity;
	order: number;
}

const initialData: Array<LanguageFakeRecord> = [
	{
		id: 'en',
		name: {
			en: 'English',
			uk: 'Англійська'
		},
		order: 1
	},
	{
		id: 'uk',
		name: {
			en: 'Ukrainian',
			uk: 'Українська'
		},
		order: 4
	}
];
