import { HealthComplaintComboboxDto, HealthComplaintFilterDto } from '@nmn-communication/health-complaints';
import { mapDiseaseTypeFromString } from '@nmn-domain/diseases';
import { HealthComplaintComboboxModel, HealthComplaintFilterModel, mapHealthComplaintTypeFromString } from '@nmn-domain/health-complaints';

export const mapFilterModelToDto = (model: HealthComplaintFilterModel): HealthComplaintFilterDto => (
	{
		...model
	}
);

export const mapFilterDtoToModel = (dto: HealthComplaintFilterDto): HealthComplaintFilterModel => (
	new HealthComplaintFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern
	)
);

export const mapHealthComplaintComboboxDtoToModel = (dto: HealthComplaintComboboxDto): HealthComplaintComboboxModel => (
	new HealthComplaintComboboxModel(
		dto.id,
		dto.displayText,
		dto.description,
		mapHealthComplaintTypeFromString(dto.complaintType),
		mapDiseaseTypeFromString(dto.type)
	)
);
