import { InformationBlockModel } from '../../../../../modules/core/models/shared/information-block.model';
import { DocumentsInformationBlockModel } from './documents-information-block.model';
import { EmergencyCardInformationBlockModel } from './emergency-card-information-block.model';
import { GeneticTestCardInformationBlockModel } from './genetic-test-card-information-block.model';
import { LabTestCheckerInformationBlockModel } from './lab-test-checker-information-block.model';
import { MedicalCardInformationBlockModel } from './medical-card-information-block.model';
import { PatientInformationBlockModel } from './patient-information-block.model';

export class PatientProfileInformationBlockModel extends InformationBlockModel {

	public readonly documents: DocumentsInformationBlockModel;
	public readonly emergencyCard: EmergencyCardInformationBlockModel;
	public readonly geneticTestCard: GeneticTestCardInformationBlockModel;
	public readonly labTestChecker: LabTestCheckerInformationBlockModel;
	public readonly medicalCard: MedicalCardInformationBlockModel;
	public readonly patient: PatientInformationBlockModel;

	constructor(
		fillingUpPercentage: number,
		documents: DocumentsInformationBlockModel,
		emergencyCard: EmergencyCardInformationBlockModel,
		geneticTestCard: GeneticTestCardInformationBlockModel,
		labTestChecker: LabTestCheckerInformationBlockModel,
		medicalCard: MedicalCardInformationBlockModel,
		patient: PatientInformationBlockModel
	) {
		super(fillingUpPercentage);

		// TODO: add Guard checks

		this.documents = documents;
		this.emergencyCard = emergencyCard;
		this.geneticTestCard = geneticTestCard;
		this.labTestChecker = labTestChecker;
		this.medicalCard = medicalCard;
		this.patient = patient;

	}

}
