import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { DescribedComboboxDto, Failure } from '../../shared';
import { AllergyIntoleranceVerificationStatusFilterDto } from '../models/allergy-intolerance-verification-status-filter.dto';

export abstract class AllergyIntoleranceVerificationStatusClient {

	public abstract getBulkAsComboboxes(filter: AllergyIntoleranceVerificationStatusFilterDto): Observable<Result<Array<DescribedComboboxDto<string>>, Failure>>;

}
