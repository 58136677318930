import { isValueDefined } from '@nmn-core/utils';

export enum MedicationBodySiteType {

	Undefined = 'undefined'

}

export const mapMedicationBodySiteTypeFromString = (stringValue: string): MedicationBodySiteType => {
	const enumValue = Object
		.keys(MedicationBodySiteType)
		.find(x => MedicationBodySiteType[x] === stringValue);

	return isValueDefined(enumValue) ? stringValue as MedicationBodySiteType : MedicationBodySiteType.Undefined;
};
