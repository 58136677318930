import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { TypeOfMedicationHttpClientConfiguration } from '../configurations/type-of-medication.http-client.configuration';

export class TypeOfMedicationHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<TypeOfMedicationHttpClientConfiguration> {

	public build(): TypeOfMedicationHttpClientConfiguration {
		return new TypeOfMedicationHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
