export class PatientAnamnesisModel {

	public readonly hasHealthIssuesWithLiver?: boolean;
	public readonly hasHealthIssuesWithKidney?: boolean;
	public readonly hasHealthIssuesWithHeart?: boolean;
	public readonly hasHealthIssuesWithStomach?: boolean;
	public readonly hasHealthIssuesWithNervousSystem?: boolean;
	public readonly hasHealthIssuesWithImmuneSystem?: boolean;
	public readonly hasHealthIssuesWithEndocrine?: boolean;
	public readonly hasHealthIssuesWithCancer?: boolean;
	public readonly hasHealthIssuesWithEye?: boolean;
	public readonly hasHealthIssuesWithEar?: boolean;
	public readonly hasHealthIssuesWithMuscles?: boolean;
	public readonly hasHealthIssuesWithGeneticConditions?: boolean;

	constructor(
		hasHealthIssuesWithLiver: boolean | undefined,
		hasHealthIssuesWithKidney: boolean | undefined,
		hasHealthIssuesWithHeart: boolean | undefined,
		hasHealthIssuesWithStomach: boolean | undefined,
		hasHealthIssuesWithNervousSystem: boolean | undefined,
		hasHealthIssuesWithImmuneSystem: boolean | undefined,
		hasHealthIssuesWithEndocrine: boolean | undefined,
		hasHealthIssuesWithCancer: boolean | undefined,
		hasHealthIssuesWithEye: boolean | undefined,
		hasHealthIssuesWithEar: boolean | undefined,
		hasHealthIssuesWithMuscles: boolean | undefined,
		hasHealthIssuesWithGeneticConditions: boolean | undefined
	) {
		this.hasHealthIssuesWithLiver = hasHealthIssuesWithLiver;
		this.hasHealthIssuesWithKidney = hasHealthIssuesWithKidney;
		this.hasHealthIssuesWithHeart = hasHealthIssuesWithHeart;
		this.hasHealthIssuesWithStomach = hasHealthIssuesWithStomach;
		this.hasHealthIssuesWithNervousSystem = hasHealthIssuesWithNervousSystem;
		this.hasHealthIssuesWithImmuneSystem = hasHealthIssuesWithImmuneSystem;
		this.hasHealthIssuesWithEndocrine = hasHealthIssuesWithEndocrine;
		this.hasHealthIssuesWithCancer = hasHealthIssuesWithCancer;
		this.hasHealthIssuesWithEye = hasHealthIssuesWithEye;
		this.hasHealthIssuesWithEar = hasHealthIssuesWithEar;
		this.hasHealthIssuesWithMuscles = hasHealthIssuesWithMuscles;
		this.hasHealthIssuesWithGeneticConditions = hasHealthIssuesWithGeneticConditions;
	}

}
