import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { TakenMedicationHttpClientConfiguration } from '../configurations/taken-medication-http-client.configuration';
import { TakenMedicationHttpClient } from '../services/taken-medication.http-client';

export class TakenMedicationHttpClientBuilder
	extends HttpClientBuilder<TakenMedicationHttpClient, TakenMedicationHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): TakenMedicationHttpClient {
		return new TakenMedicationHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
