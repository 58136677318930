import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneticFileParseRequestStateModel } from '../models/genetic-file-parse-request-state.model';

export abstract class GeneticFileParseRequestResolver implements
	Resolve<GeneticFileParseRequestStateModel> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		routerState: RouterStateSnapshot
	): Observable<GeneticFileParseRequestStateModel> |
		Promise<GeneticFileParseRequestStateModel> |
		GeneticFileParseRequestStateModel;

}
