export class LoginViaFacebookCommand {

	public readonly accessToken: string;

	constructor(
		accessToken: string
	) {
		// TODO: Guard check on defined
		this.accessToken = accessToken;
	}

}
