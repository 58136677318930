import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MedicationDosageTypeFilterModel, MedicationDosageTypeQueryHandlerService, MedicationDosageTypesResolver } from '@nmn-domain/medication-dosage-types';
import { DescribedComboboxModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MedicationDosageTypesViaQueryHandlerResolver extends MedicationDosageTypesResolver {

	constructor(
		private readonly queryHandlerService: MedicationDosageTypeQueryHandlerService
	) {
		super();
	}

	public resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<Array<DescribedComboboxModel>> | Array<DescribedComboboxModel> {
		return this.queryHandlerService
			.getAsDescribedComboboxes(MedicationDosageTypeFilterModel.createEmpty())
			.pipe(map(result => result.successOrDefault(() => [])));
	}

}
