import { TranslocoService } from '@ngneat/transloco';
import { SubscriptionComboboxDto } from '@nmn-communication/subscriptions';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../databases/fake.database';
import { FakeComboboxDto } from '../../models/comboboxes/fake-combox.dto';
import { FakeLocalizableEntity } from '../../models/fake-localizable-entity';
import { setTranslation } from '../../utils/localize';

export class SubscriptionFakeTable {

	private readonly data: Array<SubscriptionFakeRecord>;

	constructor(
		_: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.data = [...initialData];
	}

	public getAsCombobox(parameter: string): SubscriptionComboboxDto {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record was not found');
		}

		return record;
	}

	public findAsCombobox(parameter: string): SubscriptionComboboxDto {
		const record = this.data.find((item: SubscriptionFakeRecord) => findPredicate(item, parameter));
		const comboboxRecord = mapFromRecordToComboboxDto(record);

		return {
			id: comboboxRecord.id,
			displayText: setTranslation(this.translocoService, comboboxRecord.displayText),
			description: setTranslation(this.translocoService, comboboxRecord.displayText),
		}
	}


	public getAsFakeRecord(parameter: string): SubscriptionFakeRecord {
		return this.data.find(x => x.id === parameter);
	}

}

const mapFromRecordToComboboxDto = (record: SubscriptionFakeRecord): FakeComboboxDto<string> => (
	{
		id: record.id,
		displayText: record.name
	}
);

const findPredicate = (
	item: SubscriptionFakeRecord,
	findParameter: string
): boolean =>
	item.id === findParameter;

interface SubscriptionFakeRecord {
	id: string;
	name: FakeLocalizableEntity;
	treatmentCheckersAvailableTotal: number;
	patientsAvailableTotal: number;
	storageBytesAvailableTotal: number;
}

const initialData: Array<SubscriptionFakeRecord> = [
	{
		id: 'Basic',
		name: {
			en: 'Basic',
			uk: 'Basic',
		},
		treatmentCheckersAvailableTotal: 2,
		patientsAvailableTotal: 2,
		storageBytesAvailableTotal: 10000
	},
	{
		id: 'Premium',
		name: {
			en: 'Premium',
			uk: 'Premium',
		},
		treatmentCheckersAvailableTotal: undefined,
		patientsAvailableTotal: 10,
		storageBytesAvailableTotal: 50000
	}
];
