import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { PagedCollectionModel } from '../../shared';
import { FailureModel } from '../../shared/failures/failure.model';
import { GeneticFileParseRequestFilterModel } from '../models/genetic-file-parse-request-filter.model';
import { GeneticFileParseRequestModel } from '../models/genetic-file-parse-request.model';
import { GeneticFileParseRequestGetStateQuery } from '../models/queries/genetic-file-parse-request.get-state.query';
import { GeneticFileParseRequestGetQuery } from '../models/queries/genetic-file-parse-request.get.query';

export abstract class GeneticFileParseRequestQueryHandlerService {

	public abstract handleBulkGetQuery(
		query: GeneticFileParseRequestGetStateQuery
	): Observable<Result<
		PagedCollectionModel<GeneticFileParseRequestModel,
			GeneticFileParseRequestFilterModel>,
		FailureModel>>;

	public abstract handleGetQuery(
		query: GeneticFileParseRequestGetQuery
	): Observable<Result<GeneticFileParseRequestModel, FailureModel>>;

}
