export class LoginFormOutModel {

	public readonly login: string;
	public readonly password: string;
	public readonly isPersistent: boolean;

	public constructor(
		login: string,
		password: string,
		isPersistent: boolean
	) {
		this.login = login;
		this.password = password;
		this.isPersistent = isPersistent;
	}

}
