import { Injectable } from '@angular/core';
import { isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { FailureModel } from '@nmn-domain/shared';
import { FailureLocalizationModel } from '@nmn-domain/shared/failures/failure-localization-parameters.model';
import { FailureWrapperModel } from '@nmn-domain/shared/failures/failure-wrapper.model';
import { EmptyResult, Result } from '../../shared';
import { SessionStorageAuditData } from '../models/session-storage-audit-data';
import { SessionStorageRecord } from '../models/session-storage-record';

@Injectable()
export class SessionStorageRepositoryService {

	public getPayload<TPayload>(key: string): Result<TPayload, FailureModel> {
		return this.get<TPayload>(key).mapOnSuccess(success => success.payload);
	}

	public get<TPayload>(key: string): Result<SessionStorageRecord<TPayload>, FailureModel> {
		try {
			if (!isStringDefinedAndNotEmpty(key)) {
				return Result.failure(FailureModel.createForArgumentException('key', 'isStringDefinedAndNotEmpty'));
			}

			const serializedRecord = sessionStorage.getItem(key);

			if (!isStringDefinedAndNotEmpty(serializedRecord)) {
				return Result.failure(FailureModel.createForNotFoundException('serializedRecord'));
			}

			const record = this.deSerializeRecord<TPayload>(serializedRecord);

			if (!isValueDefined(record)) {
				return Result.failure(FailureModel.createForArgumentException('record', 'isValueDefined'));
			}

			return Result.success(record);
		}
		catch (error) {
			return Result.failure(FailureModel.createForGeneralException(FailureWrapperModel.createFromValue(error), FailureLocalizationModel.createEmpty()));
		}
	}

	public set<TPayload>(key: string, payload: TPayload): EmptyResult<FailureModel> {
		try {
			if (!isStringDefinedAndNotEmpty(key)) {
				return EmptyResult.failure(FailureModel.createForArgumentException('key', 'isStringDefinedAndNotEmpty'));
			}
			if (!isValueDefined(payload)) {
				return EmptyResult.failure(FailureModel.createForArgumentException('payload', 'isValueDefined'));
			}

			sessionStorage.setItem(key, this.serializeRecord(this.createRecord(payload)));

			return EmptyResult.success();
		}
		catch (error) {
			return EmptyResult.failure(FailureModel.createForGeneralException(FailureWrapperModel.createFromValue(error), FailureLocalizationModel.createEmpty()));
		}
	}

	public remove(key: string): EmptyResult<FailureModel> {
		try {
			if (!isStringDefinedAndNotEmpty(key)) {
				return EmptyResult.failure(FailureModel.createForArgumentException('key', 'isStringDefinedAndNotEmpty'));
			}

			sessionStorage.removeItem(key);

			return EmptyResult.success();
		}
		catch (error) {
			return EmptyResult.failure(FailureModel.createForGeneralException(FailureWrapperModel.createFromValue(error), FailureLocalizationModel.createEmpty()));
		}
	}

	public clear(): EmptyResult<FailureModel> {
		try {
			sessionStorage.clear();

			return EmptyResult.success();
		}
		catch (error) {
			return EmptyResult.failure(FailureModel.createForGeneralException(FailureWrapperModel.createFromValue(error), FailureLocalizationModel.createEmpty()));
		}
	}

	private serializeRecord<TPayload>(record: SessionStorageRecord<TPayload>): string {
		return JSON.stringify(record);
	}

	private deSerializeRecord<TPayload>(serializedRecord: string): SessionStorageRecord<TPayload> {
		return JSON.parse(serializedRecord);
	}

	private createRecord<TPayload>(payload: TPayload): SessionStorageRecord<TPayload> {
		const record: SessionStorageRecord<TPayload> = {
			payload: payload,
			audit: this.createAuditData()
		};

		return record;
	}

	private createAuditData(): SessionStorageAuditData {
		const auditData: SessionStorageAuditData = {
			createdOn: (new Date()).toISOString()
		};

		return auditData;
	}

}
