import { Injector } from '@angular/core';
import { RegionClient } from '@nmn-communication/regions';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { RegionFakeClientBuilder } from '../builders/region.fake-client.builder';
import { RegionFakeClientConfigurationBuilder } from '../builders/region.fake-client.configuration.builder';
import { RegionHttpClientBuilder } from '../builders/region.http-client.builder';
import { RegionHttpClientConfigurationBuilder } from '../builders/region.http-client.configuration.builder';
import { RegionFakeClient } from '../services/region.fake-client';
import { RegionHttpClient } from '../services/region.http-client';

export const regionClientFactory = (injector: Injector): RegionClient => {
	const useFake = environment?.api?.resources?.regions?.useFake ||
		!isValueDefined(environment?.api?.resources?.regions?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		regionFakeClientFactory(injector) :
		regionHttpClientFactory(injector);
};

export const regionFakeClientFactory = (
	injector: Injector
): RegionFakeClient => {
	const configuration = new RegionFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.regions.serverDelayMs)
		.build();

	return new RegionFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const regionHttpClientFactory = (
	injector: Injector
): RegionHttpClient => {
	const configuration = new RegionHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.regions?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.regions.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.regions?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new RegionHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
