import { PopulationClient, PopulationComboboxDto, PopulationFilterDto } from '@nmn-communication/populations';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { FakeDatabase } from 'app/modules/fakes/databases/fake.database';
import { wrapFuncCallAsFakeClientResponse } from 'app/modules/fakes/databases/fake.utils';
import { Observable } from 'rxjs';
import { PopulationFakeClientConfiguration } from '../configurations/population.fake-client.configuration';

export class PopulationFakeClient extends PopulationClient {

	constructor(
		private readonly configuration: PopulationFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulkAsComboboxes(filter: PopulationFilterDto): Observable<Result<Array<PopulationComboboxDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.populationTable.getComboboxesPagedCollection(filter),
			this.configuration.serverDelayMs);
	}

}
