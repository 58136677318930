import { isArrayDefinedAndNotEmpty } from '@nmn-core/utils';
import { PageOptionsDto } from '../../clients/dtos/pagination/page-options.dto';
import { PagedCollectionDto } from '../../clients/dtos/pagination/paged-collection.dto';
import { PageOptionsQuery } from '../../models/pagination/page-options.query';
import { PagedCollectionModel } from '../../models/pagination/paged-collection.model';
export const mapPageOptionsQueryToDto = <TFilterModel, TFilterDto>(
	query: PageOptionsQuery<TFilterModel>,
	filterMappingFactory: (filterModel: TFilterModel) => TFilterDto
): PageOptionsDto<TFilterDto> => {

	return {
		indexFrom: query.indexFrom,
		pageIndex: query.pageIndex,
		pageSize: query.pageSize,
		sorting: query.sorting,
		filter: filterMappingFactory(query.filter)
	};

};

export const mapPagedCollectionDtoToModel = <TDto, TFilterDto, TModel, TFilterModel>(
	pagedCollectionDto: PagedCollectionDto<TDto, TFilterDto>,
	dtoToModelMappingFactory: (dto: TDto) => TModel,
	filterDtoToModelMappingFactory: (filterDto: TFilterDto) => TFilterModel
): PagedCollectionModel<TModel, TFilterModel> => {

	return new PagedCollectionModel(
		pagedCollectionDto.sorting,
		filterDtoToModelMappingFactory(pagedCollectionDto.filter),
		pagedCollectionDto.pageIndex,
		pagedCollectionDto.pageSize,
		pagedCollectionDto.indexFrom,
		isArrayDefinedAndNotEmpty(pagedCollectionDto.items) ?
			pagedCollectionDto.items.map(dtoToModelMappingFactory) :
			[],
		pagedCollectionDto.hasNextPage,
		pagedCollectionDto.hasPreviousPage,
		pagedCollectionDto.nextPage,
		pagedCollectionDto.previousPage,
		pagedCollectionDto.totalCount,
		pagedCollectionDto.totalPages
	);

};
