import { isValueDefined } from '@nmn-core/utils';

export enum Permission {

	Undefined = 'undefined'

}

export const mapPermissionFromString = (stringValue: string): Permission => {
	const enumValue = Object
		.keys(Permission)
		.find(x => Permission[x] === stringValue);

	return isValueDefined(enumValue) ? stringValue as Permission : Permission.Undefined;
};
