import { isValueDefined } from '@nmn-core/utils';

export class EmergencyCardPageConfiguration {

	protected constructor(
	) { }

	public static createWithParameters(
		config?: EmergencyCardPageConfiguration
	): EmergencyCardPageConfiguration {
		if (!isValueDefined(config)) {
			return EmergencyCardPageConfiguration.createDefault();
		}

		return new EmergencyCardPageConfiguration();
	}

	public static createDefault(
	): EmergencyCardPageConfiguration {
		return new EmergencyCardPageConfiguration();
	}

}
