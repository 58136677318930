import { Result } from '@nmn-core/shared';
import { EmptyCommandResult, FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { UserSubscriptionUpdateCommand } from '../models/commands/user-configuration.update-command';

export abstract class UserSubscriptionCommandHandlerService {

	// TODO: <EG> Here we should return a defined result that have at least transactionId: Result<CommandResult<UserSubscriptionUpdateCommand, UserSubscriptionUpdateResult>, FailureModel>
	public abstract update(
		command: UserSubscriptionUpdateCommand
	): Observable<Result<EmptyCommandResult<UserSubscriptionUpdateCommand>, FailureModel>>;

}
