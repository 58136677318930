export class InitiateEmailConfirmationFormOutModel {

	public readonly email: string;

	public constructor(
		email: string
	) {
		this.email = email;
	}

}
