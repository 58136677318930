import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { TokenInfoModel } from '@nmn-domain/accounts/login/models/token-info.model';
import { PatientProfileShortModel, UserFileStorageProfileModel, UserProfileModel } from '@nmn-domain/accounts/user-accounts/models/user-profile.model';
import { CurrentUserProfileQueryHandlerService } from '@nmn-domain/accounts/user-accounts/services/current-user-profile.query-handler.service';
import { NavigationResource } from '@nmn-middleware/navigation';
import { Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { CookieStorageService, LocalStorageService } from '../../core/application-storages';
import { UserConfigurationStorageService } from '../configurations/user-configuration-storage.service';
import { updateSubscriptionsAfterLogin } from '../configurations/utils';

@Injectable({ providedIn: 'root' })
export class StorageService {

	private currentUserProfileQueryHandler: CurrentUserProfileQueryHandlerService;

	private readonly activePatientSubject$ = new Subject<PatientProfileShortModel>();
	private readonly userProfileSubject$ = new Subject<UserProfileModel>();
	private readonly userFileStorageSubject$ = new Subject<UserFileStorageProfileModel>();
	private readonly tokenInfoSubject$ = new Subject<TokenInfoModel>();
	private readonly isUserInfoLoadingSubject$ = new Subject<boolean>();
	private readonly isUserLoggedInSubject$ = new Subject<boolean>();

	private isUserInfoLoadingValue: boolean;
	private get isUserInfoLoading(): boolean {
		return this.isUserInfoLoadingValue;
	}
	private set isUserInfoLoading(value: boolean) {
		this.isUserInfoLoadingValue = value;
		this.isUserInfoLoadingSubject$.next(this.isUserInfoLoadingValue);
	}

	private userIdValue: string;
	private tokenInfoValue: TokenInfoModel;
	private userProfileValue: UserProfileModel;
	private userFileStorageValue: UserFileStorageProfileModel;

	// TODO: check if these observers work as expected (looks like they do not: I believe `.pipe(shareReplay(1))` must be on consumer side)
	public readonly isUserInfoLoading$ = this.isUserInfoLoadingSubject$
		.asObservable()
		.pipe(shareReplay(1));

	public readonly activePatient$ = this.activePatientSubject$
		.asObservable()
		.pipe(shareReplay(1));

	public readonly userProfile$ = this.userProfileSubject$
		.asObservable()
		.pipe(shareReplay(1));

	public readonly userFileStorage$ = this.userFileStorageSubject$
		.asObservable()
		.pipe(shareReplay(1));

	public readonly tokenInfo$ = this.tokenInfoSubject$
		.asObservable()
		.pipe(shareReplay(1));

	public readonly isUserLoggedIn$ = this.isUserLoggedInSubject$
		.asObservable()
		.pipe(shareReplay(1));

	public get userId(): string {
		return this.userIdValue;
	}

	public get tokenInfo(): TokenInfoModel {
		return this.tokenInfoValue;
	}

	public get isLoggedIn(): boolean {
		return this.isUserIdDefined;
	}

	public get isTokenInfoDefined(): boolean {
		return isValueDefined(this.tokenInfoValue);
	}

	public get userProfile(): UserProfileModel {
		return this.userProfileValue;
	}

	public get isUserIdDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.userId);
	}

	public get isUserProfileDefined(): boolean {
		return isValueDefined(this.userProfileValue);
	}

	public get isActivePatientAliasDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.userProfileValue?.activePatientAlias);
	}

	public get activePatientAlias(): string {
		return this.userProfileValue?.activePatientAlias;
	}

	public get activePatientId(): string {
		return this.userProfileValue?.activePatientId;
	}

	public get userFileStorage(): UserFileStorageProfileModel {
		return this.userFileStorageValue;
	}

	constructor(
		private readonly router: Router,
		private readonly cookieStorage: CookieStorageService,
		private readonly localStorage: LocalStorageService,
		private readonly userConfigurationStorage: UserConfigurationStorageService,
		// injector used only for CurrentUserProfileQueryHandlerService since to prevent initialization before configuration from server receive
		private readonly injector: Injector
	) {
		// Do not remove these subscriptions - they are used to keep subject alife
		this.activePatient$.subscribe();
		this.userProfile$.subscribe();
		this.userFileStorage$.subscribe();
		this.tokenInfo$.subscribe();
		this.isUserInfoLoading$.subscribe();
		this.isUserLoggedIn$.subscribe();
		this.isUserInfoLoading = false;
	}

	public tryRestoreLastSession(): void {
		const lastUserId = this.localStorage.getLastUserId();

		if (isStringDefinedAndNotEmpty(lastUserId) && this.cookieStorage.checkUserAccessToken(lastUserId)) {
			this.userIdValue = lastUserId;
			this.isUserInfoLoading = true;

			if (!isValueDefined(this.currentUserProfileQueryHandler)) {
				this.currentUserProfileQueryHandler = this.injector.get(CurrentUserProfileQueryHandlerService);
			}
			this.currentUserProfileQueryHandler
				.get()
				.subscribe({
					next: res => res.consume(success => updateSubscriptionsAfterLogin(success, this, this.userConfigurationStorage), _ => this.logout()),
					error: _ => this.logout(),
					complete: () => this.isUserInfoLoading = false
				});
		}
		else {
			this.isUserLoggedInSubject$.next(false);
		}

		window.addEventListener(
			'focus',
			() => {
				const userId = this.userId;
				this.localStorage.setLastUserId(userId);

				if (isValueDefined(userId)) {
					if (!this.cookieStorage.checkUserAccessToken(userId) && !this.cookieStorage.checkUserRefreshToken(userId)) {
						this.logout();
					}
				}
			},
			false
		);

		window.addEventListener(
			'beforeunload',
			() => {
				const userId = this.userId;
				this.localStorage.setLastUserId(userId);
			}
		);
	}

	public logout(): void {
		this.cookieStorage.removeUserTokens(this.userId);
		this.tokenInfoValue = undefined;
		this.userProfileValue = undefined;
		this.userIdValue = undefined;
		this.isUserLoggedInSubject$.next(false);
		this.router.navigate([`/${NavigationResource.Login}`]);
	}

	public defineTokenInfo(tokenInfo: TokenInfoModel): void {
		// TODO: Guard check on defined
		this.tokenInfoValue = tokenInfo;
		this.cookieStorage.setUserTokens(this.userId, tokenInfo.tokenType, tokenInfo.accessToken, tokenInfo.refreshToken);
		this.tokenInfoSubject$.next(this.tokenInfoValue);
	}

	public defineUserFileStorageProfile(userFileStorageProfileModel: UserFileStorageProfileModel): void {
		// TODO: Guard check on defined
		this.userFileStorageValue = userFileStorageProfileModel;
		this.userFileStorageSubject$.next(this.userFileStorageValue);
	}

	public isPatientProfileActive(patientProfileId: string): boolean {
		return patientProfileId === this.userProfile?.activePatientId;
	}

	public defineUserProfile(userProfile: UserProfileModel): void {
		// TODO: Guard check on defined
		this.userIdValue = userProfile?.id;
		this.userProfileValue = userProfile;
		this.userProfileSubject$.next(this.userProfileValue);
		this.activePatientSubject$.next(this.userProfileValue?.activePatientProfile);
		this.isUserLoggedInSubject$.next(true);
	}

}
