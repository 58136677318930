import { MedicationDosageTypeFilterDto } from '@nmn-communication/medication-dosage-types';
import { MedicationDosageTypeFilterModel } from '@nmn-domain/medication-dosage-types';

export const mapFilterModelToDto = (model: MedicationDosageTypeFilterModel): MedicationDosageTypeFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern
	}
);

export const mapFilterDtoToModel = (dto: MedicationDosageTypeFilterDto): MedicationDosageTypeFilterModel => (
	new MedicationDosageTypeFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern
	)
);
