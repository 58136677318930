import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { DescribedComboboxModel, FailureModel } from '../../shared';
import { AllergyIntoleranceCriticalityFilterModel } from '../models/allergy-intolerance-criticality-filter.model';

export abstract class AllergyIntoleranceCriticalityQueryHandlerService {

	public abstract getAsDescribedComboboxes(query: AllergyIntoleranceCriticalityFilterModel): Observable<Result<Array<DescribedComboboxModel>, FailureModel>>;

}
