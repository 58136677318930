import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { GenderHttpClientConfiguration } from '../configurations/gender.http-client.configuration';
import { GenderHttpClient } from '../services/gender.http-client';

export class GenderHttpClientBuilder
	extends HttpClientBuilder<GenderHttpClient, GenderHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): GenderHttpClient {
		return new GenderHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
