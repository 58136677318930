import { Injector, Provider } from '@angular/core';
import { AllergyIntoleranceStatusClient } from '@nmn-communication/allergy-intolerance-statuses';
import { allergyIntoleranceStatusClientFactory } from './factories/allergy-intolerance-status.client.factory';

export const allergyIntoleranceStatusProviders: Array<Provider> = [
	{
		provide: AllergyIntoleranceStatusClient,
		useFactory: allergyIntoleranceStatusClientFactory,
		deps: [Injector]
	}
];
