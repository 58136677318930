import { Injector } from '@angular/core';
import { FileUploaderClient } from '@nmn-communication/file-uploader';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { FileUploaderFakeClientConfigurationBuilder } from '../builders/file-uploader-fake-client-configuration.builder';
import { FileUploaderFakeClientBuilder } from '../builders/file-uploader-fake-client.builder';
import { FileUploaderHttpClientConfigurationBuilder } from '../builders/file-uploader-http-client-configuration.builder';
import { FileUploaderHttpClientBuilder } from '../builders/file-uploader-http-client.builder';
import { FileUploaderFakeClient } from '../services/file-uploader.fake-client';
import { FileUploaderHttpClient } from '../services/file-uploader.http-client';

export const fileUploaderClientFactory = (injector: Injector): FileUploaderClient => {
	const useFake = environment?.api?.resources?.fileUploader?.useFake ||
		!isValueDefined(environment?.api?.resources?.fileUploader?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		FileUploaderFakeClientFactory(injector) :
		FileUploaderHttpClientFactory(injector);
};

export const FileUploaderFakeClientFactory = (injector: Injector): FileUploaderFakeClient => {
	const configuration = new FileUploaderFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.fileUploader.serverDelayMs)
		.build();

	return new FileUploaderFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const FileUploaderHttpClientFactory = (injector: Injector): FileUploaderHttpClient => {
	const configuration = new FileUploaderHttpClientConfigurationBuilder()
		.withBaseUrl(environment?.api?.resources?.fileUploader?.baseUrl ?? environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.fileUploader.resourceRelativePath)
		.withResourceTypeVersion(environment?.api?.resources?.fileUploader?.resourceTypeVersion ?? environment?.api?.defaultResourceTypeVersion)
		.build();

	return new FileUploaderHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
