import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { RegionHttpClientConfiguration } from '../configurations/region.http-client.configuration';
import { RegionHttpClient } from '../services/region.http-client';

export class RegionHttpClientBuilder extends HttpClientBuilder<RegionHttpClient, RegionHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): RegionHttpClient {
		return new RegionHttpClient(this.configuration, this.injector.get<HttpClient>(HttpClient));
	}

}
