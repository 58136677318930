import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GdprPanelModule } from '../modules/shared/gdpr-panel/gdpr-panel.module';
import { RootComponent } from './root.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		GdprPanelModule
	],
	declarations: [
		RootComponent
	],
	exports: [
		RootComponent
	]
})
export class RootModule { }
