export class GenderFilterModel {

	public readonly ids?: Array<string>;

	public readonly ignoreIds?: Array<string>;

	public readonly searchPattern?: string;

	constructor(
		ids: Array<string> | undefined,
		ignoreIds: Array<string> | undefined,
		searchPattern: string | undefined
	) {
		this.ids = ids;
		this.ignoreIds = ignoreIds;
		this.searchPattern = searchPattern;
	}

	public static createEmpty(): GenderFilterModel {
		return new GenderFilterModel(undefined, undefined, undefined);
	}

	public static createForPredefinedList(genderIds: Array<string>): GenderFilterModel {
		return new GenderFilterModel(
			genderIds,
			undefined,
			undefined
		);
	}

	public static createForGendersAutocomplete(
		searchPattern: string,
		ignoreIds: Array<string>
	): GenderFilterModel {
		return new GenderFilterModel(undefined, ignoreIds, searchPattern);
	}

}
