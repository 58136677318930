import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { PopulationHttpClientConfiguration } from '../configurations/population.http-client.configuration';
import { PopulationHttpClient } from '../services/population.http-client';

export class PopulationHttpClientBuilder extends HttpClientBuilder<PopulationHttpClient, PopulationHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): PopulationHttpClient {
		return new PopulationHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient)
		);
	}

}
