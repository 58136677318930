import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { AzureStorageCreateParameterDto } from '../models/azure-storage-create-parameter.dto';
import { AzureStorageInitedUploadDto } from '../models/azure-storage-inited-upload.dto';

export abstract class AzureStorageClient {

	public abstract initBlobUpload(parameter: AzureStorageCreateParameterDto): Observable<Result<AzureStorageInitedUploadDto, Failure>>;

}
