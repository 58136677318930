import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { GeneticFileParseRequestHttpClientConfiguration } from '../configurations/genetic-file-parse-request.http-client.configuration';
import { GeneticFileParseRequestHttpClient } from '../services/genetic-file-parse-request.http-client';

export class GeneticFileParseRequestHttpClientBuilder
	extends HttpClientBuilder<GeneticFileParseRequestHttpClient, GeneticFileParseRequestHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): GeneticFileParseRequestHttpClient {
		return new GeneticFileParseRequestHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
