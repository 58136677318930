import { isValueDefined } from '@nmn-core/utils';
import { NmnMatTableUserConfiguration } from '../component-configurations/nmn-mat-table-user.configuration';

export class DoctorEncountersPageConfiguration {

	public readonly doctorEncountersTableUserConfiguration: NmnMatTableUserConfiguration;

	protected constructor(
		doctorEncountersTableUserConfiguration: NmnMatTableUserConfiguration
	) {
		this.doctorEncountersTableUserConfiguration = doctorEncountersTableUserConfiguration;
	}

	public static createWithParameters(
		config?: DoctorEncountersPageConfiguration
	): DoctorEncountersPageConfiguration {
		if (!isValueDefined(config)) {
			DoctorEncountersPageConfiguration.createDefault();
		}

		return new DoctorEncountersPageConfiguration(
			isValueDefined(config?.doctorEncountersTableUserConfiguration) ?
				NmnMatTableUserConfiguration.createWithParameters(config.doctorEncountersTableUserConfiguration) :
				NmnMatTableUserConfiguration.createDefault()
		);
	}

	public static createDefault(): DoctorEncountersPageConfiguration {
		return new DoctorEncountersPageConfiguration(
			NmnMatTableUserConfiguration.createWithParameters(
				new NmnMatTableUserConfiguration(undefined, undefined, undefined, 'asc', 'status')
			)
		);
	}

}
