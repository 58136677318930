import { HealthIssueSeverityComboboxDto, HealthIssueSeverityFilterDto } from '@nmn-communication/health-issue-severities';
import { HealthIssueSeverityComboboxModel, HealthIssueSeverityFilterModel } from '@nmn-domain/health-issue-severities';

export const mapFilterModelToDto = (model: HealthIssueSeverityFilterModel): HealthIssueSeverityFilterDto => (
	{
		ids: model.ids?.map(Number),
		ignoreIds: model.ignoreIds?.map(Number),
		searchPattern: model.searchPattern
	}
);

export const mapFilterDtoToModel = (dto: HealthIssueSeverityFilterDto): HealthIssueSeverityFilterModel => (
	new HealthIssueSeverityFilterModel(
		dto.ids?.map(item => item.toString()),
		dto.ignoreIds?.map(item => item.toString()),
		dto.searchPattern
	)
);

export const mapHealthIssueSeverityComboboxDtoToModel = (dto: HealthIssueSeverityComboboxDto): HealthIssueSeverityComboboxModel => (
	new HealthIssueSeverityComboboxModel(
		dto.id.toString(),
		dto.displayText,
		dto.description
	)
);
