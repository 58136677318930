import { Injector, Provider } from '@angular/core';
import { LoginClient } from '@nmn-communication/accounts';
import { loginClientFactory } from './factories/login.client.factory';

export const accountsLoginProviders: Array<Provider> = [
	{
		provide: LoginClient,
		useFactory: loginClientFactory,
		deps: [Injector]
	}
];
