import { Injectable } from '@angular/core';
import { GeneticTestClient } from '@nmn-communication/genetic-tests';
import { Result } from '@nmn-core/shared';
import { GeneticTestCommandHandlerService, GeneticTestCreateCommand, GeneticTestDeleteBulkCommand, GeneticTestDeleteCommand, GeneticTestUpdateCommand } from '@nmn-domain/genetic-tests';
import { CommandResult, EmptyCommandResult, FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapCreateCommandToCreateParameter, mapUpdateCommandToUpdateParameter } from '../factories/genetic-test.factory';

@Injectable()
export class GeneticTestCommandHandlerViaClientService extends GeneticTestCommandHandlerService {

	constructor(
		private readonly client: GeneticTestClient
	) {
		super();
	}

	public handleCreateCommand(command: GeneticTestCreateCommand): Observable<Result<CommandResult<GeneticTestCreateCommand, string>, FailureModel>> {
		return this.client
			.create(mapCreateCommandToCreateParameter(command))
			.pipe(
				map(result => result.map(success => new CommandResult(command, success), mapFailureToFailureModel))
			);
	}

	public handleUpdateCommand(command: GeneticTestUpdateCommand): Observable<Result<EmptyCommandResult<GeneticTestUpdateCommand>, FailureModel>> {
		return this.client
			.update({ patientId: command.patientId, id: command.id }, mapUpdateCommandToUpdateParameter(command))
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

	public handleDeleteCommand(command: GeneticTestDeleteCommand): Observable<Result<EmptyCommandResult<GeneticTestDeleteCommand>, FailureModel>> {
		return this.client
			.delete({ patientId: command.patientId, id: command.id })
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

	public handleDeleteBulkCommand(command: GeneticTestDeleteBulkCommand): Observable<Result<EmptyCommandResult<GeneticTestDeleteBulkCommand>, FailureModel>> {
		return this.client
			.deleteBulk({ ids: command.ids, patientId: command.patientId })
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

}
