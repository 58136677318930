import { DocumentDescribedComboboxModel } from '../document-described-combobox.model';

export class DocumentFormOutModel {

	// Is used to be able to show model as an inline view
	public readonly comboboxView: DocumentDescribedComboboxModel;

	public readonly patientId: string;
	public readonly name: string;
	public readonly description: string;
	public readonly typeId: string;
	public readonly attachments: Array<string>;
	public readonly relatedEncounterIds: Array<string>;
	public readonly relatedTakenMedicationIds: Array<string>;
	public readonly relatedHealthIssueIds: Array<string>;

	public constructor(
		comboboxView: DocumentDescribedComboboxModel,
		patientId: string,
		name: string,
		description: string,
		typeId: string,
		attachments: Array<string>,
		relatedEncounterIds: Array<string>,
		relatedTakenMedicationIds: Array<string>,
		relatedHealthIssueIds: Array<string>
	) {
		// TODO: Guard check on defined

		this.comboboxView = comboboxView;
		this.patientId = patientId;
		this.name = name;
		this.description = description;
		this.typeId = typeId;
		this.attachments = attachments;
		this.relatedEncounterIds = relatedEncounterIds;
		this.relatedTakenMedicationIds = relatedTakenMedicationIds;
		this.relatedHealthIssueIds = relatedHealthIssueIds;
	}

}
