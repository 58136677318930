import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { InitiateEmailConfirmationFormInModel, InitiateEmailConfirmationFormInModelResolver } from '@nmn-domain/accounts';
import { QueryParameter } from '@nmn-middleware/navigation';
import { Observable } from 'rxjs';

@Injectable()
export class InitiateEmailConfirmationFormInModelViaRouteResolver implements InitiateEmailConfirmationFormInModelResolver {

	public resolve(
		activatedRoute: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<InitiateEmailConfirmationFormInModel> | InitiateEmailConfirmationFormInModel {
		const email = activatedRoute.queryParamMap.get(QueryParameter.Email);
		const inModel = InitiateEmailConfirmationFormInModel.createByEmail(email);

		return inModel;
	}

}
