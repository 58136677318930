import { Injector, Provider } from '@angular/core';
import { MedicationAdmRouteClient } from '@nmn-communication/medication-adm-routes';
import { MedicationAdmRouteClientFactory } from './factories/medication-adm-route.client.factory';

export const medicationAdmRouteProviders: Array<Provider> = [
	{
		provide: MedicationAdmRouteClient,
		useFactory: MedicationAdmRouteClientFactory,
		deps: [Injector]
	}
];
