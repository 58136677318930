import { DocumentTypeDescribedComboboxDto, DocumentTypeFilterDto } from '@nmn-communication/document-types';
import { DocumentTypeDescribedComboboxModel, DocumentTypeFilterModel, mapDocumentIconTypeFromString } from '@nmn-domain/document-types';

export const mapFilterModelToDto = (model: DocumentTypeFilterModel): DocumentTypeFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern
	}
);

export const mapFilterDtoToModel = (dto: DocumentTypeFilterDto): DocumentTypeFilterModel => (
	new DocumentTypeFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern
	)
);

export const mapDocumentTypeDescribedComboboxDtoToModel = (dto: DocumentTypeDescribedComboboxDto): DocumentTypeDescribedComboboxModel => (
	new DocumentTypeDescribedComboboxModel(
		dto.id.toString(),
		dto.displayText,
		dto.description,
		mapDocumentIconTypeFromString(dto.id)
	)
);
