import { Injectable } from '@angular/core';
import { ServerConfigurationModel } from '@nmn-domain/server-configuration';
import { Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

// TODO: change scope after connection with userSettings api call.
@Injectable({ providedIn: 'root' })
export class ServerConfigurationStorageService {

	private readonly serverConfigurationSubject$ = new Subject<ServerConfigurationModel>();

	private serverConfigurationValue: ServerConfigurationModel;

	constructor() {
		this.serverConfiguration$.subscribe();
	}

	public readonly serverConfiguration$ = this.serverConfigurationSubject$
		.asObservable()
		.pipe(shareReplay(1));

	public get serverConfiguration(): ServerConfigurationModel {
		return this.serverConfigurationValue;
	}

	public defineServerConfiguration(serverConfigurationValue: ServerConfigurationModel): void {
		// TODO: Guard check on defined
		this.serverConfigurationValue = serverConfigurationValue;
		this.serverConfigurationSubject$.next(this.serverConfigurationValue);
	}

}
