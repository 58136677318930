import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PatientModel } from '../models/patient.model';

export abstract class PatientResolver implements Resolve<PatientModel> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		routerState: RouterStateSnapshot
	): Observable<PatientModel> | Promise<PatientModel> | PatientModel;

}
