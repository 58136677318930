<div mat-dialog-title>
	<span class="nmn-page-title">{{ dialogSettings.title }}</span>
</div>

<div mat-dialog-content fxLayout="row wrap">
	<div class="nmn-form">
		<div *ngFor="let item of dialogSettings.failureHints" class="nmn-form-content-section" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.gt-xs="start center">
			<span>{{ item }}</span>
		</div>
	</div>
</div>

<div mat-dialog-actions fxLayout="row-reverse wrap">
	<button cdkFocusInitial mat-button color="primary" type="button" (click)="confirm()">{{ dialogSettings.confirmBtnTitle }}</button>
</div>