import { TranslocoService } from '@ngneat/transloco';
import { TimezoneComboboxDto, TimezoneFilterDto } from '@nmn-communication/timezones';
import { isArrayDefined, isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { FakeDatabase } from '../../databases/fake.database';

export class TimezoneFakeTable {

	private readonly data: Array<TimezoneComboboxDto>;

	constructor(
		_: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.data = [...initialData];
	}

	public getComboboxesPagedCollection(filter: TimezoneFilterDto): Array<TimezoneComboboxDto> {
		return this.data.filter(
			item =>
				(!isStringDefinedAndNotEmpty(filter.searchPattern) || item.displayText.includes(filter.searchPattern)) &&
				(!isArrayDefined(filter.ids) || filter.ids.some(id => id === item.id)) &&
				(!isArrayDefined(filter.ignoreIds) || filter.ignoreIds.every(id => id !== item.id))
		);
	}

}

const initialData = [
	{
		'standardName': 'Coordinated Universal Time',
		'daylightName': 'Coordinated Universal Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 0,
		'utcDaylightOffsetMinutes': 0,
		'id': 'UTC',
		'displayText': '(UTC) Coordinated Universal Time'
	},
	{
		'standardName': 'GMT Standard Time',
		'daylightName': 'GMT Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 0,
		'utcDaylightOffsetMinutes': 60,
		'id': 'GMT Standard Time',
		'displayText': '(UTC+00:00) Dublin, Edinburgh, Lisbon, London'
	},
	{
		'standardName': 'Greenwich Standard Time',
		'daylightName': 'Greenwich Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 0,
		'utcDaylightOffsetMinutes': 0,
		'id': 'Greenwich Standard Time',
		'displayText': '(UTC+00:00) Monrovia, Reykjavik'
	},
	{
		'standardName': 'Sao Tome Standard Time',
		'daylightName': 'Sao Tome Daylight Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 0,
		'utcDaylightOffsetMinutes': 0,
		'id': 'Sao Tome Standard Time',
		'displayText': '(UTC+00:00) Sao Tome'
	},
	{
		'standardName': 'W. Europe Standard Time',
		'daylightName': 'W. Europe Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 60,
		'utcDaylightOffsetMinutes': 120,
		'id': 'W. Europe Standard Time',
		'displayText': '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'
	},
	{
		'standardName': 'Central Europe Standard Time',
		'daylightName': 'Central Europe Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 60,
		'utcDaylightOffsetMinutes': 120,
		'id': 'Central Europe Standard Time',
		'displayText': '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague'
	},
	{
		'standardName': 'Romance Standard Time',
		'daylightName': 'Romance Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 60,
		'utcDaylightOffsetMinutes': 120,
		'id': 'Romance Standard Time',
		'displayText': '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris'
	},
	{
		'standardName': 'Morocco Standard Time',
		'daylightName': 'Morocco Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 0,
		'utcDaylightOffsetMinutes': 60,
		'id': 'Morocco Standard Time',
		'displayText': '(UTC+01:00) Casablanca'
	},
	{
		'standardName': 'Central European Standard Time',
		'daylightName': 'Central European Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 60,
		'utcDaylightOffsetMinutes': 120,
		'id': 'Central European Standard Time',
		'displayText': '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb'
	},
	{
		'standardName': 'W. Central Africa Standard Time',
		'daylightName': 'W. Central Africa Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 60,
		'utcDaylightOffsetMinutes': 60,
		'id': 'W. Central Africa Standard Time',
		'displayText': '(UTC+01:00) West Central Africa'
	},
	{
		'standardName': 'GTB Standard Time',
		'daylightName': 'GTB Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 120,
		'utcDaylightOffsetMinutes': 180,
		'id': 'GTB Standard Time',
		'displayText': '(UTC+02:00) Athens, Bucharest'
	},
	{
		'standardName': 'Middle East Standard Time',
		'daylightName': 'Middle East Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 120,
		'utcDaylightOffsetMinutes': 180,
		'id': 'Middle East Standard Time',
		'displayText': '(UTC+02:00) Beirut'
	},
	{
		'standardName': 'Egypt Standard Time',
		'daylightName': 'Egypt Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 120,
		'utcDaylightOffsetMinutes': 120,
		'id': 'Egypt Standard Time',
		'displayText': '(UTC+02:00) Cairo'
	},
	{
		'standardName': 'E. Europe Standard Time',
		'daylightName': 'E. Europe Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 120,
		'utcDaylightOffsetMinutes': 180,
		'id': 'E. Europe Standard Time',
		'displayText': '(UTC+02:00) Chisinau'
	},
	{
		'standardName': 'Syria Standard Time',
		'daylightName': 'Syria Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 120,
		'utcDaylightOffsetMinutes': 180,
		'id': 'Syria Standard Time',
		'displayText': '(UTC+02:00) Damascus'
	},
	{
		'standardName': 'West Bank Gaza Standard Time',
		'daylightName': 'West Bank Gaza Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 120,
		'utcDaylightOffsetMinutes': 180,
		'id': 'West Bank Standard Time',
		'displayText': '(UTC+02:00) Gaza, Hebron'
	},
	{
		'standardName': 'South Africa Standard Time',
		'daylightName': 'South Africa Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 120,
		'utcDaylightOffsetMinutes': 120,
		'id': 'South Africa Standard Time',
		'displayText': '(UTC+02:00) Harare, Pretoria'
	},
	{
		'standardName': 'FLE Standard Time',
		'daylightName': 'FLE Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 120,
		'utcDaylightOffsetMinutes': 180,
		'id': 'FLE Standard Time',
		'displayText': '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius'
	},
	{
		'standardName': 'Jerusalem Standard Time',
		'daylightName': 'Jerusalem Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 120,
		'utcDaylightOffsetMinutes': 180,
		'id': 'Israel Standard Time',
		'displayText': '(UTC+02:00) Jerusalem'
	},
	{
		'standardName': 'South Sudan Standard Time',
		'daylightName': 'South Sudan Daylight Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 180,
		'utcDaylightOffsetMinutes': 180,
		'id': 'South Sudan Standard Time',
		'displayText': '(UTC+02:00) Juba'
	},
	{
		'standardName': 'Russia TZ 1 Standard Time',
		'daylightName': 'Russia TZ 1 Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 120,
		'utcDaylightOffsetMinutes': 120,
		'id': 'Kaliningrad Standard Time',
		'displayText': '(UTC+02:00) Kaliningrad'
	},
	{
		'standardName': 'Sudan Standard Time',
		'daylightName': 'Sudan Daylight Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 180,
		'utcDaylightOffsetMinutes': 180,
		'id': 'Sudan Standard Time',
		'displayText': '(UTC+02:00) Khartoum'
	},
	{
		'standardName': 'Libya Standard Time',
		'daylightName': 'Libya Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 120,
		'utcDaylightOffsetMinutes': 120,
		'id': 'Libya Standard Time',
		'displayText': '(UTC+02:00) Tripoli'
	},
	{
		'standardName': 'Namibia Standard Time',
		'daylightName': 'Namibia Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 120,
		'utcDaylightOffsetMinutes': 60,
		'id': 'Namibia Standard Time',
		'displayText': '(UTC+02:00) Windhoek'
	},
	{
		'standardName': 'Jordan Standard Time',
		'daylightName': 'Jordan Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 120,
		'utcDaylightOffsetMinutes': 180,
		'id': 'Jordan Standard Time',
		'displayText': '(UTC+03:00) Amman'
	},
	{
		'standardName': 'Arabic Standard Time',
		'daylightName': 'Arabic Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 180,
		'utcDaylightOffsetMinutes': 180,
		'id': 'Arabic Standard Time',
		'displayText': '(UTC+03:00) Baghdad'
	},
	{
		'standardName': 'Turkey Standard Time',
		'daylightName': 'Turkey Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 120,
		'utcDaylightOffsetMinutes': 180,
		'id': 'Turkey Standard Time',
		'displayText': '(UTC+03:00) Istanbul'
	},
	{
		'standardName': 'Arab Standard Time',
		'daylightName': 'Arab Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 180,
		'utcDaylightOffsetMinutes': 180,
		'id': 'Arab Standard Time',
		'displayText': '(UTC+03:00) Kuwait, Riyadh'
	},
	{
		'standardName': 'Belarus Standard Time',
		'daylightName': 'Belarus Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 180,
		'utcDaylightOffsetMinutes': 180,
		'id': 'Belarus Standard Time',
		'displayText': '(UTC+03:00) Minsk'
	},
	{
		'standardName': 'Russia TZ 2 Standard Time',
		'daylightName': 'Russia TZ 2 Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 180,
		'utcDaylightOffsetMinutes': 180,
		'id': 'Russian Standard Time',
		'displayText': '(UTC+03:00) Moscow, St Petersburg'
	},
	{
		'standardName': 'E. Africa Standard Time',
		'daylightName': 'E. Africa Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 180,
		'utcDaylightOffsetMinutes': 180,
		'id': 'E. Africa Standard Time',
		'displayText': '(UTC+03:00) Nairobi'
	},
	{
		'standardName': 'Volgograd Standard Time',
		'daylightName': 'Volgograd Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 180,
		'utcDaylightOffsetMinutes': 180,
		'id': 'Volgograd Standard Time',
		'displayText': '(UTC+03:00) Volgograd'
	},
	{
		'standardName': 'Iran Standard Time',
		'daylightName': 'Iran Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 210,
		'utcDaylightOffsetMinutes': 270,
		'id': 'Iran Standard Time',
		'displayText': '(UTC+03:30) Tehran'
	},
	{
		'standardName': 'Arabian Standard Time',
		'daylightName': 'Arabian Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 240,
		'utcDaylightOffsetMinutes': 240,
		'id': 'Arabian Standard Time',
		'displayText': '(UTC+04:00) Abu Dhabi, Muscat'
	},
	{
		'standardName': 'Astrakhan Standard Time',
		'daylightName': 'Astrakhan Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 180,
		'utcDaylightOffsetMinutes': 180,
		'id': 'Astrakhan Standard Time',
		'displayText': '(UTC+04:00) Astrakhan, Ulyanovsk'
	},
	{
		'standardName': 'Azerbaijan Standard Time',
		'daylightName': 'Azerbaijan Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 240,
		'utcDaylightOffsetMinutes': 300,
		'id': 'Azerbaijan Standard Time',
		'displayText': '(UTC+04:00) Baku'
	},
	{
		'standardName': 'Russia TZ 3 Standard Time',
		'daylightName': 'Russia TZ 3 Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 240,
		'utcDaylightOffsetMinutes': 240,
		'id': 'Russia Time Zone 3',
		'displayText': '(UTC+04:00) Izhevsk, Samara'
	},
	{
		'standardName': 'Mauritius Standard Time',
		'daylightName': 'Mauritius Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 240,
		'utcDaylightOffsetMinutes': 240,
		'id': 'Mauritius Standard Time',
		'displayText': '(UTC+04:00) Port Louis'
	},
	{
		'standardName': 'Saratov Standard Time',
		'daylightName': 'Saratov Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 180,
		'utcDaylightOffsetMinutes': 180,
		'id': 'Saratov Standard Time',
		'displayText': '(UTC+04:00) Saratov'
	},
	{
		'standardName': 'Georgian Standard Time',
		'daylightName': 'Georgian Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 240,
		'utcDaylightOffsetMinutes': 240,
		'id': 'Georgian Standard Time',
		'displayText': '(UTC+04:00) Tbilisi'
	},
	{
		'standardName': 'Caucasus Standard Time',
		'daylightName': 'Caucasus Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 240,
		'utcDaylightOffsetMinutes': 240,
		'id': 'Caucasus Standard Time',
		'displayText': '(UTC+04:00) Yerevan'
	},
	{
		'standardName': 'Afghanistan Standard Time',
		'daylightName': 'Afghanistan Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 270,
		'utcDaylightOffsetMinutes': 270,
		'id': 'Afghanistan Standard Time',
		'displayText': '(UTC+04:30) Kabul'
	},
	{
		'standardName': 'West Asia Standard Time',
		'daylightName': 'West Asia Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 300,
		'utcDaylightOffsetMinutes': 300,
		'id': 'West Asia Standard Time',
		'displayText': '(UTC+05:00) Ashgabat, Tashkent'
	},
	{
		'standardName': 'Russia TZ 4 Standard Time',
		'daylightName': 'Russia TZ 4 Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 300,
		'utcDaylightOffsetMinutes': 300,
		'id': 'Ekaterinburg Standard Time',
		'displayText': '(UTC+05:00) Ekaterinburg'
	},
	{
		'standardName': 'Pakistan Standard Time',
		'daylightName': 'Pakistan Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 300,
		'utcDaylightOffsetMinutes': 300,
		'id': 'Pakistan Standard Time',
		'displayText': '(UTC+05:00) Islamabad, Karachi'
	},
	{
		'standardName': 'Qyzylorda Standard Time',
		'daylightName': 'Qyzylorda Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 360,
		'utcDaylightOffsetMinutes': 360,
		'id': 'Qyzylorda Standard Time',
		'displayText': '(UTC+05:00) Qyzylorda'
	},
	{
		'standardName': 'India Standard Time',
		'daylightName': 'India Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 330,
		'utcDaylightOffsetMinutes': 330,
		'id': 'India Standard Time',
		'displayText': '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi'
	},
	{
		'standardName': 'Sri Lanka Standard Time',
		'daylightName': 'Sri Lanka Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 330,
		'utcDaylightOffsetMinutes': 330,
		'id': 'Sri Lanka Standard Time',
		'displayText': '(UTC+05:30) Sri Jayawardenepura'
	},
	{
		'standardName': 'Nepal Standard Time',
		'daylightName': 'Nepal Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 345,
		'utcDaylightOffsetMinutes': 345,
		'id': 'Nepal Standard Time',
		'displayText': '(UTC+05:45) Kathmandu'
	},
	{
		'standardName': 'Bangladesh Standard Time',
		'daylightName': 'Bangladesh Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 360,
		'utcDaylightOffsetMinutes': 360,
		'id': 'Bangladesh Standard Time',
		'displayText': '(UTC+06:00) Dhaka'
	},
	{
		'standardName': 'Central Asia Standard Time',
		'daylightName': 'Central Asia Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 360,
		'utcDaylightOffsetMinutes': 360,
		'id': 'Central Asia Standard Time',
		'displayText': '(UTC+06:00) Nur-Sultan'
	},
	{
		'standardName': 'Omsk Standard Time',
		'daylightName': 'Omsk Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 360,
		'utcDaylightOffsetMinutes': 360,
		'id': 'Omsk Standard Time',
		'displayText': '(UTC+06:00) Omsk'
	},
	{
		'standardName': 'Myanmar Standard Time',
		'daylightName': 'Myanmar Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 390,
		'utcDaylightOffsetMinutes': 390,
		'id': 'Myanmar Standard Time',
		'displayText': '(UTC+06:30) Yangon (Rangoon)'
	},
	{
		'standardName': 'SE Asia Standard Time',
		'daylightName': 'SE Asia Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 420,
		'utcDaylightOffsetMinutes': 420,
		'id': 'SE Asia Standard Time',
		'displayText': '(UTC+07:00) Bangkok, Hanoi, Jakarta'
	},
	{
		'standardName': 'Altai Standard Time',
		'daylightName': 'Altai Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 360,
		'utcDaylightOffsetMinutes': 360,
		'id': 'Altai Standard Time',
		'displayText': '(UTC+07:00) Barnaul, Gorno-Altaysk'
	},
	{
		'standardName': 'W. Mongolia Standard Time',
		'daylightName': 'W. Mongolia Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 420,
		'utcDaylightOffsetMinutes': 480,
		'id': 'W. Mongolia Standard Time',
		'displayText': '(UTC+07:00) Hovd'
	},
	{
		'standardName': 'Russia TZ 6 Standard Time',
		'daylightName': 'Russia TZ 6 Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 420,
		'utcDaylightOffsetMinutes': 420,
		'id': 'North Asia Standard Time',
		'displayText': '(UTC+07:00) Krasnoyarsk'
	},
	{
		'standardName': 'Novosibirsk Standard Time',
		'daylightName': 'Novosibirsk Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 360,
		'utcDaylightOffsetMinutes': 360,
		'id': 'N. Central Asia Standard Time',
		'displayText': '(UTC+07:00) Novosibirsk'
	},
	{
		'standardName': 'Tomsk Standard Time',
		'daylightName': 'Tomsk Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 360,
		'utcDaylightOffsetMinutes': 360,
		'id': 'Tomsk Standard Time',
		'displayText': '(UTC+07:00) Tomsk'
	},
	{
		'standardName': 'China Standard Time',
		'daylightName': 'China Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 480,
		'utcDaylightOffsetMinutes': 480,
		'id': 'China Standard Time',
		'displayText': '(UTC+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi'
	},
	{
		'standardName': 'Russia TZ 7 Standard Time',
		'daylightName': 'Russia TZ 7 Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 480,
		'utcDaylightOffsetMinutes': 480,
		'id': 'North Asia East Standard Time',
		'displayText': '(UTC+08:00) Irkutsk'
	},
	{
		'standardName': 'Malay Peninsula Standard Time',
		'daylightName': 'Malay Peninsula Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 480,
		'utcDaylightOffsetMinutes': 480,
		'id': 'Singapore Standard Time',
		'displayText': '(UTC+08:00) Kuala Lumpur, Singapore'
	},
	{
		'standardName': 'W. Australia Standard Time',
		'daylightName': 'W. Australia Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 480,
		'utcDaylightOffsetMinutes': 480,
		'id': 'W. Australia Standard Time',
		'displayText': '(UTC+08:00) Perth'
	},
	{
		'standardName': 'Taipei Standard Time',
		'daylightName': 'Taipei Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 480,
		'utcDaylightOffsetMinutes': 480,
		'id': 'Taipei Standard Time',
		'displayText': '(UTC+08:00) Taipei'
	},
	{
		'standardName': 'Ulaanbaatar Standard Time',
		'daylightName': 'Ulaanbaatar Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 480,
		'utcDaylightOffsetMinutes': 540,
		'id': 'Ulaanbaatar Standard Time',
		'displayText': '(UTC+08:00) Ulaanbaatar'
	},
	{
		'standardName': 'Aus Central W. Standard Time',
		'daylightName': 'Aus Central W. Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 525,
		'utcDaylightOffsetMinutes': 525,
		'id': 'Aus Central W. Standard Time',
		'displayText': '(UTC+08:45) Eucla'
	},
	{
		'standardName': 'Transbaikal Standard Time',
		'daylightName': 'Transbaikal Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 480,
		'utcDaylightOffsetMinutes': 480,
		'id': 'Transbaikal Standard Time',
		'displayText': '(UTC+09:00) Chita'
	},
	{
		'standardName': 'Tokyo Standard Time',
		'daylightName': 'Tokyo Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 540,
		'utcDaylightOffsetMinutes': 540,
		'id': 'Tokyo Standard Time',
		'displayText': '(UTC+09:00) Osaka, Sapporo, Tokyo'
	},
	{
		'standardName': 'North Korea Standard Time',
		'daylightName': 'North Korea Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 540,
		'utcDaylightOffsetMinutes': 540,
		'id': 'North Korea Standard Time',
		'displayText': '(UTC+09:00) Pyongyang'
	},
	{
		'standardName': 'Korea Standard Time',
		'daylightName': 'Korea Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 540,
		'utcDaylightOffsetMinutes': 540,
		'id': 'Korea Standard Time',
		'displayText': '(UTC+09:00) Seoul'
	},
	{
		'standardName': 'Russia TZ 8 Standard Time',
		'daylightName': 'Russia TZ 8 Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 540,
		'utcDaylightOffsetMinutes': 540,
		'id': 'Yakutsk Standard Time',
		'displayText': '(UTC+09:00) Yakutsk'
	},
	{
		'standardName': 'Cen. Australia Standard Time',
		'daylightName': 'Cen. Australia Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 630,
		'utcDaylightOffsetMinutes': 570,
		'id': 'Cen. Australia Standard Time',
		'displayText': '(UTC+09:30) Adelaide'
	},
	{
		'standardName': 'AUS Central Standard Time',
		'daylightName': 'AUS Central Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 570,
		'utcDaylightOffsetMinutes': 570,
		'id': 'AUS Central Standard Time',
		'displayText': '(UTC+09:30) Darwin'
	},
	{
		'standardName': 'E. Australia Standard Time',
		'daylightName': 'E. Australia Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 600,
		'utcDaylightOffsetMinutes': 600,
		'id': 'E. Australia Standard Time',
		'displayText': '(UTC+10:00) Brisbane'
	},
	{
		'standardName': 'AUS Eastern Standard Time',
		'daylightName': 'AUS Eastern Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 660,
		'utcDaylightOffsetMinutes': 600,
		'id': 'AUS Eastern Standard Time',
		'displayText': '(UTC+10:00) Canberra, Melbourne, Sydney'
	},
	{
		'standardName': 'West Pacific Standard Time',
		'daylightName': 'West Pacific Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 600,
		'utcDaylightOffsetMinutes': 600,
		'id': 'West Pacific Standard Time',
		'displayText': '(UTC+10:00) Guam, Port Moresby'
	},
	{
		'standardName': 'Tasmania Standard Time',
		'daylightName': 'Tasmania Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 660,
		'utcDaylightOffsetMinutes': 600,
		'id': 'Tasmania Standard Time',
		'displayText': '(UTC+10:00) Hobart'
	},
	{
		'standardName': 'Russia TZ 9 Standard Time',
		'daylightName': 'Russia TZ 9 Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 600,
		'utcDaylightOffsetMinutes': 600,
		'id': 'Vladivostok Standard Time',
		'displayText': '(UTC+10:00) Vladivostok'
	},
	{
		'standardName': 'Lord Howe Standard Time',
		'daylightName': 'Lord Howe Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 660,
		'utcDaylightOffsetMinutes': 630,
		'id': 'Lord Howe Standard Time',
		'displayText': '(UTC+10:30) Lord Howe Island'
	},
	{
		'standardName': 'Bougainville Standard Time',
		'daylightName': 'Bougainville Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 660,
		'utcDaylightOffsetMinutes': 660,
		'id': 'Bougainville Standard Time',
		'displayText': '(UTC+11:00) Bougainville Island'
	},
	{
		'standardName': 'Russia TZ 10 Standard Time',
		'daylightName': 'Russia TZ 10 Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 660,
		'utcDaylightOffsetMinutes': 660,
		'id': 'Russia Time Zone 10',
		'displayText': '(UTC+11:00) Chokurdakh'
	},
	{
		'standardName': 'Magadan Standard Time',
		'daylightName': 'Magadan Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 600,
		'utcDaylightOffsetMinutes': 600,
		'id': 'Magadan Standard Time',
		'displayText': '(UTC+11:00) Magadan'
	},
	{
		'standardName': 'Norfolk Standard Time',
		'daylightName': 'Norfolk Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 690,
		'utcDaylightOffsetMinutes': 690,
		'id': 'Norfolk Standard Time',
		'displayText': '(UTC+11:00) Norfolk Island'
	},
	{
		'standardName': 'Sakhalin Standard Time',
		'daylightName': 'Sakhalin Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 600,
		'utcDaylightOffsetMinutes': 600,
		'id': 'Sakhalin Standard Time',
		'displayText': '(UTC+11:00) Sakhalin'
	},
	{
		'standardName': 'Central Pacific Standard Time',
		'daylightName': 'Central Pacific Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 660,
		'utcDaylightOffsetMinutes': 660,
		'id': 'Central Pacific Standard Time',
		'displayText': '(UTC+11:00) Solomon Is., New Caledonia'
	},
	{
		'standardName': 'Russia TZ 11 Standard Time',
		'daylightName': 'Russia TZ 11 Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 720,
		'utcDaylightOffsetMinutes': 720,
		'id': 'Russia Time Zone 11',
		'displayText': '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky'
	},
	{
		'standardName': 'New Zealand Standard Time',
		'daylightName': 'New Zealand Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 780,
		'utcDaylightOffsetMinutes': 720,
		'id': 'New Zealand Standard Time',
		'displayText': '(UTC+12:00) Auckland, Wellington'
	},
	{
		'standardName': 'UTC+12',
		'daylightName': 'UTC+12',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 720,
		'utcDaylightOffsetMinutes': 720,
		'id': 'UTC+12',
		'displayText': '(UTC+12:00) Co-ordinated Universal Time+12'
	},
	{
		'standardName': 'Fiji Standard Time',
		'daylightName': 'Fiji Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 780,
		'utcDaylightOffsetMinutes': 720,
		'id': 'Fiji Standard Time',
		'displayText': '(UTC+12:00) Fiji'
	},
	{
		'standardName': 'Kamchatka Standard Time',
		'daylightName': 'Kamchatka Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 720,
		'utcDaylightOffsetMinutes': 780,
		'id': 'Kamchatka Standard Time',
		'displayText': '(UTC+12:00) Petropavlovsk-Kamchatsky - Old'
	},
	{
		'standardName': 'Chatham Islands Standard Time',
		'daylightName': 'Chatham Islands Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 825,
		'utcDaylightOffsetMinutes': 765,
		'id': 'Chatham Islands Standard Time',
		'displayText': '(UTC+12:45) Chatham Islands'
	},
	{
		'standardName': 'UTC+13',
		'daylightName': 'UTC+13',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 780,
		'utcDaylightOffsetMinutes': 780,
		'id': 'UTC+13',
		'displayText': '(UTC+13:00) Co-ordinated Universal Time+13'
	},
	{
		'standardName': 'Tonga Standard Time',
		'daylightName': 'Tonga Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 780,
		'utcDaylightOffsetMinutes': 780,
		'id': 'Tonga Standard Time',
		'displayText': '(UTC+13:00) Nuku\'alofa'
	},
	{
		'standardName': 'Samoa Standard Time',
		'daylightName': 'Samoa Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': 840,
		'utcDaylightOffsetMinutes': 780,
		'id': 'Samoa Standard Time',
		'displayText': '(UTC+13:00) Samoa'
	},
	{
		'standardName': 'Line Islands Standard Time',
		'daylightName': 'Line Islands Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': 840,
		'utcDaylightOffsetMinutes': 840,
		'id': 'Line Islands Standard Time',
		'displayText': '(UTC+14:00) Kiritimati Island'
	},
	{
		'standardName': 'Azores Standard Time',
		'daylightName': 'Azores SummerTime',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -60,
		'utcDaylightOffsetMinutes': 0,
		'id': 'Azores Standard Time',
		'displayText': '(UTC-01:00) Azores'
	},
	{
		'standardName': 'Cabo Verde Standard Time',
		'daylightName': 'Cabo Verde Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': -60,
		'utcDaylightOffsetMinutes': -60,
		'id': 'Cape Verde Standard Time',
		'displayText': '(UTC-01:00) Cabo Verde Is.'
	},
	{
		'standardName': 'UTC-02',
		'daylightName': 'UTC-02',
		'supportsDST': false,
		'utcStandardOffsetMinutes': -120,
		'utcDaylightOffsetMinutes': -120,
		'id': 'UTC-02',
		'displayText': '(UTC-02:00) Co-ordinated Universal Time-02'
	},
	{
		'standardName': 'Mid-Atlantic Standard Time',
		'daylightName': 'Mid-Atlantic Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -120,
		'utcDaylightOffsetMinutes': -60,
		'id': 'Mid-Atlantic Standard Time',
		'displayText': '(UTC-02:00) Mid-Atlantic - Old'
	},
	{
		'standardName': 'Tocantins Standard Time',
		'daylightName': 'Tocantins Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -180,
		'utcDaylightOffsetMinutes': -180,
		'id': 'Tocantins Standard Time',
		'displayText': '(UTC-03:00) Araguaina'
	},
	{
		'standardName': 'E. South America Standard Time',
		'daylightName': 'E. South America Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -120,
		'utcDaylightOffsetMinutes': -180,
		'id': 'E. South America Standard Time',
		'displayText': '(UTC-03:00) Brasilia'
	},
	{
		'standardName': 'SA Eastern Standard Time',
		'daylightName': 'SA Eastern Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': -180,
		'utcDaylightOffsetMinutes': -180,
		'id': 'SA Eastern Standard Time',
		'displayText': '(UTC-03:00) Cayenne, Fortaleza'
	},
	{
		'standardName': 'Argentina Standard Time',
		'daylightName': 'Argentina Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -180,
		'utcDaylightOffsetMinutes': -180,
		'id': 'Argentina Standard Time',
		'displayText': '(UTC-03:00) City of Buenos Aires'
	},
	{
		'standardName': 'Greenland Standard Time',
		'daylightName': 'Greenland Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -180,
		'utcDaylightOffsetMinutes': -120,
		'id': 'Greenland Standard Time',
		'displayText': '(UTC-03:00) Greenland'
	},
	{
		'standardName': 'Montevideo Standard Time',
		'daylightName': 'Montevideo Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -120,
		'utcDaylightOffsetMinutes': -180,
		'id': 'Montevideo Standard Time',
		'displayText': '(UTC-03:00) Montevideo'
	},
	{
		'standardName': 'Magallanes Standard Time',
		'daylightName': 'Magallanes Daylight Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -180,
		'utcDaylightOffsetMinutes': -180,
		'id': 'Magallanes Standard Time',
		'displayText': '(UTC-03:00) Punta Arenas'
	},
	{
		'standardName': 'Saint Pierre Standard Time',
		'daylightName': 'Saint Pierre Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -180,
		'utcDaylightOffsetMinutes': -120,
		'id': 'Saint Pierre Standard Time',
		'displayText': '(UTC-03:00) Saint Pierre and Miquelon'
	},
	{
		'standardName': 'Bahia Standard Time',
		'daylightName': 'Bahia Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -180,
		'utcDaylightOffsetMinutes': -180,
		'id': 'Bahia Standard Time',
		'displayText': '(UTC-03:00) Salvador'
	},
	{
		'standardName': 'Newfoundland Standard Time',
		'daylightName': 'Newfoundland Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -210,
		'utcDaylightOffsetMinutes': -150,
		'id': 'Newfoundland Standard Time',
		'displayText': '(UTC-03:30) Newfoundland'
	},
	{
		'standardName': 'Paraguay Standard Time',
		'daylightName': 'Paraguay Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -180,
		'utcDaylightOffsetMinutes': -240,
		'id': 'Paraguay Standard Time',
		'displayText': '(UTC-04:00) Asuncion'
	},
	{
		'standardName': 'Atlantic Standard Time',
		'daylightName': 'Atlantic Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -240,
		'utcDaylightOffsetMinutes': -180,
		'id': 'Atlantic Standard Time',
		'displayText': '(UTC-04:00) Atlantic Time (Canada)'
	},
	{
		'standardName': 'Venezuela Standard Time',
		'daylightName': 'Venezuela Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -270,
		'utcDaylightOffsetMinutes': -270,
		'id': 'Venezuela Standard Time',
		'displayText': '(UTC-04:00) Caracas'
	},
	{
		'standardName': 'Central Brazilian Standard Time',
		'daylightName': 'Central Brazilian Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -180,
		'utcDaylightOffsetMinutes': -240,
		'id': 'Central Brazilian Standard Time',
		'displayText': '(UTC-04:00) Cuiaba'
	},
	{
		'standardName': 'SA Western Standard Time',
		'daylightName': 'SA Western Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': -240,
		'utcDaylightOffsetMinutes': -240,
		'id': 'SA Western Standard Time',
		'displayText': '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan'
	},
	{
		'standardName': 'Pacific SA Standard Time',
		'daylightName': 'Pacific SA Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -180,
		'utcDaylightOffsetMinutes': -180,
		'id': 'Pacific SA Standard Time',
		'displayText': '(UTC-04:00) Santiago'
	},
	{
		'standardName': 'SA Pacific Standard Time',
		'daylightName': 'SA Pacific Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': -300,
		'utcDaylightOffsetMinutes': -300,
		'id': 'SA Pacific Standard Time',
		'displayText': '(UTC-05:00) Bogota, Lima, Quito, Rio Branco'
	},
	{
		'standardName': 'Eastern Standard Time (Mexico)',
		'daylightName': 'Eastern Summer Time (Mexico)',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -360,
		'utcDaylightOffsetMinutes': -300,
		'id': 'Eastern Standard Time (Mexico)',
		'displayText': '(UTC-05:00) Chetumal'
	},
	{
		'standardName': 'Eastern Standard Time',
		'daylightName': 'Eastern Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -300,
		'utcDaylightOffsetMinutes': -240,
		'id': 'Eastern Standard Time',
		'displayText': '(UTC-05:00) Eastern Time (US & Canada)'
	},
	{
		'standardName': 'Haiti Standard Time',
		'daylightName': 'Haiti Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -300,
		'utcDaylightOffsetMinutes': -240,
		'id': 'Haiti Standard Time',
		'displayText': '(UTC-05:00) Haiti'
	},
	{
		'standardName': 'Cuba Standard Time',
		'daylightName': 'Cuba Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -300,
		'utcDaylightOffsetMinutes': -240,
		'id': 'Cuba Standard Time',
		'displayText': '(UTC-05:00) Havana'
	},
	{
		'standardName': 'US Eastern Standard Time',
		'daylightName': 'US Eastern Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -300,
		'utcDaylightOffsetMinutes': -240,
		'id': 'US Eastern Standard Time',
		'displayText': '(UTC-05:00) Indiana (East)'
	},
	{
		'standardName': 'Turks and Caicos Standard Time',
		'daylightName': 'Turks and Caicos Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -300,
		'utcDaylightOffsetMinutes': -240,
		'id': 'Turks And Caicos Standard Time',
		'displayText': '(UTC-05:00) Turks and Caicos'
	},
	{
		'standardName': 'Central America Standard Time',
		'daylightName': 'Central America Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': -360,
		'utcDaylightOffsetMinutes': -360,
		'id': 'Central America Standard Time',
		'displayText': '(UTC-06:00) Central America'
	},
	{
		'standardName': 'Central Standard Time',
		'daylightName': 'Central Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -360,
		'utcDaylightOffsetMinutes': -300,
		'id': 'Central Standard Time',
		'displayText': '(UTC-06:00) Central Time (US & Canada)'
	},
	{
		'standardName': 'Easter Island Standard Time',
		'daylightName': 'Easter Island Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -300,
		'utcDaylightOffsetMinutes': -300,
		'id': 'Easter Island Standard Time',
		'displayText': '(UTC-06:00) Easter Island'
	},
	{
		'standardName': 'Central Standard Time (Mexico)',
		'daylightName': 'Central Summer Time (Mexico)',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -360,
		'utcDaylightOffsetMinutes': -300,
		'id': 'Central Standard Time (Mexico)',
		'displayText': '(UTC-06:00) Guadalajara, Mexico City, Monterrey'
	},
	{
		'standardName': 'Canada Central Standard Time',
		'daylightName': 'Canada Central Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': -360,
		'utcDaylightOffsetMinutes': -360,
		'id': 'Canada Central Standard Time',
		'displayText': '(UTC-06:00) Saskatchewan'
	},
	{
		'standardName': 'US Mountain Standard Time',
		'daylightName': 'US Mountain Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': -420,
		'utcDaylightOffsetMinutes': -420,
		'id': 'US Mountain Standard Time',
		'displayText': '(UTC-07:00) Arizona'
	},
	{
		'standardName': 'Mountain Standard Time (Mexico)',
		'daylightName': 'Mountain Summer Time (Mexico)',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -420,
		'utcDaylightOffsetMinutes': -360,
		'id': 'Mountain Standard Time (Mexico)',
		'displayText': '(UTC-07:00) La Paz, Mazatlan'
	},
	{
		'standardName': 'Mountain Standard Time',
		'daylightName': 'Mountain Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -420,
		'utcDaylightOffsetMinutes': -360,
		'id': 'Mountain Standard Time',
		'displayText': '(UTC-07:00) Mountain Time (US & Canada)'
	},
	{
		'standardName': 'Yukon Standard Time',
		'daylightName': 'Yukon Daylight Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -480,
		'utcDaylightOffsetMinutes': -420,
		'id': 'Yukon Standard Time',
		'displayText': '(UTC-07:00) Yukon'
	},
	{
		'standardName': 'Pacific Standard Time (Mexico)',
		'daylightName': 'Pacific Summer Time (Mexico)',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -480,
		'utcDaylightOffsetMinutes': -420,
		'id': 'Pacific Standard Time (Mexico)',
		'displayText': '(UTC-08:00) Baja California'
	},
	{
		'standardName': 'UTC-08',
		'daylightName': 'UTC-08',
		'supportsDST': false,
		'utcStandardOffsetMinutes': -480,
		'utcDaylightOffsetMinutes': -480,
		'id': 'UTC-08',
		'displayText': '(UTC-08:00) Co-ordinated Universal Time-08'
	},
	{
		'standardName': 'Pacific Standard Time',
		'daylightName': 'Pacific Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -480,
		'utcDaylightOffsetMinutes': -420,
		'id': 'Pacific Standard Time',
		'displayText': '(UTC-08:00) Pacific Time (US & Canada)'
	},
	{
		'standardName': 'Alaskan Standard Time',
		'daylightName': 'Alaskan Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -540,
		'utcDaylightOffsetMinutes': -480,
		'id': 'Alaskan Standard Time',
		'displayText': '(UTC-09:00) Alaska'
	},
	{
		'standardName': 'UTC-09',
		'daylightName': 'UTC-09',
		'supportsDST': false,
		'utcStandardOffsetMinutes': -540,
		'utcDaylightOffsetMinutes': -540,
		'id': 'UTC-09',
		'displayText': '(UTC-09:00) Co-ordinated Universal Time-09'
	},
	{
		'standardName': 'Marquesas Standard Time',
		'daylightName': 'Marquesas Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': -570,
		'utcDaylightOffsetMinutes': -570,
		'id': 'Marquesas Standard Time',
		'displayText': '(UTC-09:30) Marquesas Islands'
	},
	{
		'standardName': 'Aleutian Standard Time',
		'daylightName': 'Aleutian Summer Time',
		'supportsDST': true,
		'utcStandardOffsetMinutes': -600,
		'utcDaylightOffsetMinutes': -540,
		'id': 'Aleutian Standard Time',
		'displayText': '(UTC-10:00) Aleutian Islands'
	},
	{
		'standardName': 'Hawaiian Standard Time',
		'daylightName': 'Hawaiian Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': -600,
		'utcDaylightOffsetMinutes': -600,
		'id': 'Hawaiian Standard Time',
		'displayText': '(UTC-10:00) Hawaii'
	},
	{
		'standardName': 'UTC-11',
		'daylightName': 'UTC-11',
		'supportsDST': false,
		'utcStandardOffsetMinutes': -660,
		'utcDaylightOffsetMinutes': -660,
		'id': 'UTC-11',
		'displayText': '(UTC-11:00) Co-ordinated Universal Time-11'
	},
	{
		'standardName': 'Dateline Standard Time',
		'daylightName': 'Dateline Summer Time',
		'supportsDST': false,
		'utcStandardOffsetMinutes': -720,
		'utcDaylightOffsetMinutes': -720,
		'id': 'Dateline Standard Time',
		'displayText': '(UTC-12:00) International Date Line West'
	}
];
