import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { getFailureLocalizationKey, isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined, tryTranslate } from '@nmn-core/utils';
import { FailureMappingStatus, FailureModel, FailureSeverity, FailureSource } from '@nmn-domain/shared';
import { NotificationModel } from '../../modules/core/notifications/models/notification.model';
import { NotificationService } from '../../modules/core/notifications/services/notification.service';
import { FailuresDialogComponent } from '../../modules/shared/error-handling/components/failures-dialog/failures-dialog.component';
import { FailuresDialogInModel } from '../../modules/shared/error-handling/models/failures-dialog/failures-dialog-view.in-model';
import { MonitoringService } from '../monitoring/monitoring.service';

@Injectable({ providedIn: 'root' })
// TODO: rename, because it handles only two types of error.
export class FailureHandlingService {

	// Is used only for console.log debug.
	private handler: string;

	private openedDialog: MatDialogRef<FailuresDialogComponent>;
	private readonly defaultLocalizatonPattern = 'shared.forms';

	private readonly tabErrorFailureKey = 'tabError';

	private readonly dialogMessage = 'dialogMessage';
	private readonly dialogTitle = 'dialogTitle';
	private readonly dialogConfirmBtnTitle = 'dialogConfirmBtnTitle';

	private readonly failedMappingFailureKey = 'failedMapping';
	private readonly invalidFormFailureKey = 'invalidForm';
	private readonly patientNotFoundFailureKey = 'patientNotFound';
	private readonly patientDataNotFoundFailureKey = 'patientDataNotFound';
	private readonly deleteFailedFailureKey = 'deleteFailed';
	private readonly unknownFailureKey = 'undefined';

	constructor(
		private readonly dialog: MatDialog,
		private readonly notificationService: NotificationService,
		private readonly translocoService: TranslocoService,
		private readonly monitoringService: MonitoringService
	) { }

	public handleFailure(failure: FailureModel): void {
		console.error(failure.condition.source, failure);

		this.monitoringService.logException(failure as any);

		// specific errors that handles only via front-end.

		if (this.handleEmptyModel(failure) ||
			this.handleFailedMapping(failure) ||
			this.handleInvalidFormFailure(failure) ||
			this.handlePatientNotFound(failure) ||
			this.handlePatientDataNotFound(failure) ||
			this.handleDeleteIssue(failure) ||
			this.handleUnknownIssue(failure) ||
			this.handleFileUploadIssue(failure) ||

			// default direction errors that are handled from back-end resposne.
			this.handleTabDirections(failure) ||
			this.handleDialogDirections(failure) ||
			this.handleDefault()) {
			console.log("Failure handling. Handled by: " + this.handler);
		}
	}

	private handleEmptyModel(failure: FailureModel): boolean {
		let handled = false;
		this.handler += this.handleEmptyModel.name;

		if (failure.condition.mapping === FailureMappingStatus.FailedMappingEmptyModel) {
			handled = true;
			this.sendNotification(NotificationModel.create(this.getLocalization(failure.failureLocalizationModel.localizationKey, this.failedMappingFailureKey)));
		}

		return handled;
	}

	private handleFailedMapping(failure: FailureModel): boolean {
		let handled = false;
		this.handler += this.handleFailedMapping.name;

		if (failure.condition.mapping === FailureMappingStatus.FailedMapping) {
			handled = true;
			this.sendNotification(NotificationModel.create(this.getLocalization(failure.failureLocalizationModel.localizationKey, this.failedMappingFailureKey)));
		}

		return handled;
	}

	private handleInvalidFormFailure(failure: FailureModel): boolean {
		let handled = false;
		this.handler += this.handleInvalidFormFailure.name;

		if (failure.condition.source === FailureSource.InvalidForm) {
			if (failure.condition.severity === FailureSeverity.Error) {
				handled = true;
				this.sendNotification(NotificationModel.create(this.getLocalization(failure.failureLocalizationModel.localizationKey, this.invalidFormFailureKey)));

			}
			if (failure.condition.severity === FailureSeverity.Critical) {
				handled = true;
				this.initOpenDialog(failure.failureLocalizationModel.localizationKey, this.invalidFormFailureKey);
			}
		}

		return handled;
	}

	private handlePatientNotFound(failure: FailureModel): boolean {
		let handled = false;
		this.handler += this.handlePatientNotFound.name;

		if (failure.condition.source === FailureSource.PatientNotFoundIssue) {
			if (failure.condition.severity === FailureSeverity.Error) {
				handled = true;
				this.sendNotification(NotificationModel.create(this.getLocalization(failure.failureLocalizationModel.localizationKey, this.patientNotFoundFailureKey)));
			}
			if (failure.condition.severity === FailureSeverity.Critical) {
				handled = true;
				this.initOpenDialog(failure.failureLocalizationModel.localizationKey, this.patientNotFoundFailureKey);
			}
		}

		return handled;
	}

	private handlePatientDataNotFound(failure: FailureModel): boolean {
		let handled = false;
		this.handler += this.handlePatientDataNotFound.name;

		if (failure.condition.source === FailureSource.PatientDataNotFoundIssue) {
			if (failure.condition.severity === FailureSeverity.Error) {
				handled = true;
				this.sendNotification(NotificationModel.create(this.getLocalization(failure.failureLocalizationModel.localizationKey, this.patientDataNotFoundFailureKey)));
			}
			if (failure.condition.severity === FailureSeverity.Critical) {
				handled = true;
				this.initOpenDialog(failure.failureLocalizationModel.localizationKey, this.patientDataNotFoundFailureKey);
			}
		}

		return handled;
	}

	private handleDeleteIssue(failure: FailureModel): boolean {
		let handled = false;
		this.handler += this.handleDeleteIssue.name;

		if (failure.condition.source === FailureSource.DeleteResourceIssue) {
			if (failure.condition.severity === FailureSeverity.Error) {
				handled = true;
				this.sendNotification(NotificationModel.create(this.getLocalization(failure.failureLocalizationModel.localizationKey, this.deleteFailedFailureKey)));
			}
			if (failure.condition.severity === FailureSeverity.Critical) {
				handled = true;
				this.initOpenDialog(failure.failureLocalizationModel.localizationKey, this.deleteFailedFailureKey);
			}
		}

		return handled;
	}

	private handleUnknownIssue(failure: FailureModel): boolean {
		let handled = false;
		this.handler += this.handleUnknownIssue.name;

		const fullLocalizationKey = isStringDefinedAndNotEmpty(failure.failureLocalizationModel.localizationKey) ?
			failure.failureLocalizationModel.localizationKey :
			getFailureLocalizationKey(this.defaultLocalizatonPattern, this.unknownFailureKey);

		if (failure.condition.source === FailureSource.Unknown || failure.condition.source === FailureSource.SubscribtionIssue) {
			if (failure.condition.severity === FailureSeverity.Error) {
				handled = true;
				this.sendNotification(NotificationModel.create(this.tryLocalization(fullLocalizationKey, failure.failureLocalizationModel.injectParamers)));
			}
			if (failure.condition.severity === FailureSeverity.Critical) {
				handled = true;
				this.initOpenDialogViaFullLocalization(fullLocalizationKey, failure.failureLocalizationModel.injectParamers);
			}
		}

		return handled;
	}

	private handleFileUploadIssue(failure: FailureModel): boolean {
		let handled = false;
		this.handler += this.handleFileUploadIssue.name;

		let fullLocalizationKey = failure.failureLocalizationModel.localizationKey;

		if (!isStringDefinedAndNotEmpty(fullLocalizationKey)) {
			handled = true;
			fullLocalizationKey = failure.getReceivedSpecialCodes().length > 0 ?
				`shared.fileUploader.failures.${failure.getReceivedSpecialCodes()[0]}` :
				getFailureLocalizationKey(failure.failureLocalizationModel.localizationKey, this.unknownFailureKey);
		}

		if (failure.condition.source === FailureSource.FileUpload) {
			if (failure.condition.severity === FailureSeverity.Error) {
				handled = true;
				this.sendNotification(
					NotificationModel.create(this.tryLocalization(fullLocalizationKey, failure.failureLocalizationModel.injectParamers)));
			}
			if (failure.condition.severity === FailureSeverity.Critical) {
				handled = true;
				this.initOpenDialogViaFullLocalization(fullLocalizationKey, failure.failureLocalizationModel.injectParamers);
			}
		}

		return handled;
	}

	private handleTabDirections(failure: FailureModel): boolean {
		let handled = false;
		this.handler += this.handleDialogDirections.name;

		const tabDirections = failure.initializeTabDirections();
		if (isArrayDefinedAndNotEmpty(tabDirections)) {
			handled = true;
			tabDirections.forEach(tabDirection => {
				this.sendNotification(NotificationModel.create(this.getTabDirectionLocalization(failure.failureLocalizationModel.localizationKey, tabDirection.failureHint)));
			});
		}

		return handled;
	}

	private handleDialogDirections(failure: FailureModel): boolean {
		let handled = false;
		this.handler += this.handleDialogDirections.name;

		const dialogDirection = failure.initializeDialogDirection();
		if (isValueDefined(dialogDirection)) {
			handled = true;
			this.openDialog(
				new FailuresDialogInModel(
					dialogDirection.failureHints.map(item => this.getDialogDirectionLocalization(failure.failureLocalizationModel.localizationKey, item)),
					this.getLocalization(failure.failureLocalizationModel.localizationKey, this.dialogTitle),
					this.getLocalization(failure.failureLocalizationModel.localizationKey, this.dialogConfirmBtnTitle)
				)
			);
		}

		return handled;
	}

	private handleDefault(): boolean {
		this.handler += this.handleDefault.name;
		this.sendNotification(NotificationModel.create(this.getLocalization(this.defaultLocalizatonPattern, this.unknownFailureKey)));
		return true;
	}

	private getDialogDirectionLocalization(localizationPattern: string, failureHint: string): string {
		return tryTranslate(
			this.translocoService,
			getFailureLocalizationKey(localizationPattern, failureHint),
			getFailureLocalizationKey(this.defaultLocalizatonPattern, this.dialogMessage)
		);
	}

	private getTabDirectionLocalization(localizationPattern: string, failureHint: string): string {
		return tryTranslate(
			this.translocoService,
			getFailureLocalizationKey(localizationPattern, failureHint),
			getFailureLocalizationKey(this.defaultLocalizatonPattern, this.tabErrorFailureKey)
		);
	}

	private openDialog(inModel: FailuresDialogInModel): void {
		if (!isValueDefined(this.openedDialog)) {
			this.openedDialog = this.dialog
				.open(
					FailuresDialogComponent,
					{
						maxHeight: 600,
						maxWidth: 400,
						disableClose: false,
						data: inModel
					}
				);

			this.openedDialog
				.afterClosed()
				.subscribe(() => { this.openedDialog = undefined; });
		}
	}

	private getLocalization(localizationPattern: string, failureKey: string): string {
		return tryTranslate(
			this.translocoService,
			getFailureLocalizationKey(localizationPattern, failureKey),
			getFailureLocalizationKey(this.defaultLocalizatonPattern, failureKey)
		);
	}

	private tryLocalization(fullLocalization: string, params: any): string {
		return tryTranslate(
			this.translocoService,
			fullLocalization,
			getFailureLocalizationKey(this.defaultLocalizatonPattern, this.unknownFailureKey),
			params
		);
	}

	private initOpenDialogViaFullLocalization(fullLocalization: string, localizationParameters?: any): void {
		this.openDialog(
			new FailuresDialogInModel(
				[this.translocoService.translate(fullLocalization, localizationParameters)],
				this.getLocalization(this.defaultLocalizatonPattern, this.dialogTitle),
				this.getLocalization(this.defaultLocalizatonPattern, this.dialogConfirmBtnTitle)
			)
		);
	}

	private initOpenDialog(
		localizationPattern: string,
		messageFailureKey: string,
		dialogTitleKey: string = this.dialogTitle,
		dialogConfirmBtnTitleKey: string = this.dialogConfirmBtnTitle
	): void {
		this.openDialog(
			new FailuresDialogInModel(
				[this.getDialogDirectionLocalization(localizationPattern, messageFailureKey)],
				this.getLocalization(localizationPattern, dialogTitleKey),
				this.getLocalization(localizationPattern, dialogConfirmBtnTitleKey)
			)
		);
	}

	private sendNotification(notification: NotificationModel): void {
		this.notificationService.notify(notification);
	}

}
