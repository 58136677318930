import { isArrayDefinedAndNotEmpty } from '@nmn-core/utils';
import { TreatmentCheckerReportCautionSeverity } from './treatment-checker-report-caution-severity.enum';
import { TreatmentCheckerReportCautionType } from './treatment-checker-report-caution-type.enum';

export class TreatmentCheckerReportCautionModel {

	public readonly type: TreatmentCheckerReportCautionType;
	public readonly severity: TreatmentCheckerReportCautionSeverity;
	public readonly parameters: Array<string>;

	public get isParametersDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.parameters);
	}

	constructor(
		type: TreatmentCheckerReportCautionType,
		severity: TreatmentCheckerReportCautionSeverity,
		parameters: Array<string>
	) {
		// TODO: Guard check on defined

		this.type = type;
		this.severity = severity;
		this.parameters = parameters;
	}

	public getParameterAsString(index: number): string {
		return this.isParametersDefined ? this.parameters[index] : '';
	}

	public getParameterAsApostrophedString(index: number): string {
		return this.isParametersDefined ? `'${this.parameters[index]}'` : '';
	}

	public getParametersAsCommaSeparatedString(startIndex?: number, endIndex?: number): string {
		return this.isParametersDefined ?
			this.parameters
				.slice(startIndex, endIndex)
				.join(', ') :
			'';
	}

	public getParametersAsCommaSeparatedApostrophedString(startIndex?: number, endIndex?: number): string {
		return this.isParametersDefined ?
			this.parameters
				.slice(startIndex, endIndex)
				.map(parameter => `'${parameter}'`)
				.join(', ') :
			'';
	}

}
