import { isValueDefined } from '@nmn-core/utils';

export enum TreatmentCheckerReportCautionType {

	Undefined = 'undefined',

	NotCompartibleWithOtherMedication = 'notCompartibleWithOtherMedication',

	IsNotPartOfAnyDiseaseTreatment = 'isNotPartOfAnyDiseaseTreatment',
	IsNotApprovedMedication = 'isNotApprovedMedication',

	PersonalizedMedicineIsAvailable = 'personalizedMedicineIsAvailable',
	PersonalizedMedicineIsAvailableByGeneticTest = 'personalizedMedicineIsAvailableByGeneticTest',
	PersonalizedMedicineIsAvailableByGeneticTestCustom = 'personalizedMedicineIsAvailableByGeneticTestCustom', // used for custom pharmacogenomic result

	DecreaseEffectiveness = 'decreaseEffectiveness',
	MayDecreaseEffectiveness = 'mayDecreaseEffectiveness',

	TreatmentGuidelinesAvailable = 'treatmentGuidelinesAvailable',

	BMIAdultSevereUnderweight = 'bmiAdultSevereUnderweight',
	BMIAdultUnderweight = 'bmiAdultUnderweight',
	BMIAdultOverweight = 'bmiAdultOverweight',
	BMIAdultObesity = 'bmiAdultObesity',
	BMIAdultSevereObesity = 'bmiAdultSevereObesity',

	BMIChildLowHeight = 'bmiChildLowHeight',
	BMIChildHighHeight = 'bmiChildHighHeight',
	BMIChildLowWeight = 'bmiChildLowWeight',
	BMIChildHighWeight = 'bmiChildHighWeight',

}

export const mapTreatmentCheckerReportCautionTypeFromString = (
	stringValue: string
): TreatmentCheckerReportCautionType => {
	const enumValue = Object
		.keys(TreatmentCheckerReportCautionType)
		.find(x => TreatmentCheckerReportCautionType[x] === stringValue);

	return isValueDefined(enumValue) ? stringValue as TreatmentCheckerReportCautionType : TreatmentCheckerReportCautionType.Undefined;
};
