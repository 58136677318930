import { isValueDefined } from '@nmn-core/utils';
import { FailureControlDirectoryModel } from './failure-control-directory.model';
import { FailureTextDirectoryModel } from './failure-text-directory.model';

export class FailureFormDirectoryModel {

	public readonly textDirectories: ReadonlyArray<FailureTextDirectoryModel>;
	public readonly controlDirectories: ReadonlyArray<FailureControlDirectoryModel>;

	private constructor(
		textDirectories: Array<FailureTextDirectoryModel>,
		controlDirectories: Array<FailureControlDirectoryModel>
	) {
		// TODO: Guard
		this.textDirectories = textDirectories;
		this.controlDirectories = controlDirectories;
	}

	public static create(
		textDirectories: Array<FailureTextDirectoryModel>,
		controlDirectories: Array<FailureControlDirectoryModel>
	): FailureFormDirectoryModel {
		return new FailureFormDirectoryModel(textDirectories, controlDirectories);
	}

	public isCtrlPresent(ctrlName: string): boolean {
		return isValueDefined(this.controlDirectories
			.find(item => item.ctrlName === ctrlName));
	}

}
