import { Injectable } from '@angular/core';
import { GenderClient } from '@nmn-communication/genders';
import { Result } from '@nmn-core/shared';
import { GenderFilterModel, GenderQueryHandlerService } from '@nmn-domain/genders';
import { DescribedComboboxModel, FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapDescribedComboboxDtoToDescribedComboboxModel } from '../../shared/factories/combobox.factory';
import { mapDtoArrayResultToModelArrayResult } from '../../shared/factories/pagination.factory';
import { mapFilterModelToDto } from '../factories/gender.factory';

@Injectable()
export class GenderQueryHandlerViaClientService extends GenderQueryHandlerService {

	constructor(
		private readonly client: GenderClient
	) {
		super();
	}

	public getAsDescribedComboboxes(filter: GenderFilterModel): Observable<Result<Array<DescribedComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapDescribedComboboxDtoToDescribedComboboxModel))
			);
	}

}
