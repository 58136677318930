import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { DoctorEncounterGetQuery, DoctorEncounterModel, DoctorEncounterQueryHandlerService, DoctorEncounterResolver } from '@nmn-domain/doctor-encounters';
import { RouteParameter } from '@nmn-middleware/navigation';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DoctorEncounterViaQueryHandlerResolver extends DoctorEncounterResolver {

	constructor(
		private readonly doctorEncounterQueryHandler: DoctorEncounterQueryHandlerService
	) {
		super();
	}

	public resolve(
		activatedRoute: ActivatedRouteSnapshot,
		_: RouterStateSnapshot
	): Observable<DoctorEncounterModel> | DoctorEncounterModel {
		const patientId = activatedRoute.paramMap.get(RouteParameter.PatientAlias);
		const doctorEncounterId = activatedRoute.paramMap.get(RouteParameter.DoctorEncounterId);

		if (!isStringDefinedAndNotEmpty(doctorEncounterId) || !isStringDefinedAndNotEmpty(patientId)) {
			return undefined;
		}

		return this.doctorEncounterQueryHandler
			.get(DoctorEncounterGetQuery.createById(doctorEncounterId, patientId))
			.pipe(
				map(result => result.successOrDefault(() => undefined))
			);
	}

}
