import { Provider } from '@angular/core';
import { HealthComplaintQueryHandlerService } from '@nmn-domain/health-complaints';
import { HealthComplaintQueryHandlerViaClientService } from './services/health-complaint.query-handler-via-client.service';

export const healthComplaintProviders: Array<Provider> = [
	{
		provide: HealthComplaintQueryHandlerService,
		useClass: HealthComplaintQueryHandlerViaClientService
	}
];
