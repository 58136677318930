import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { PagedCollectionDto, PageOptionsDto } from '../../shared';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { HealthIssueComboboxDto } from '../models/health-issue-combobox.dto';
import { HealthIssueFilterDto } from '../models/health-issue-filter.dto';
import { HealthIssueDto } from '../models/health-issue.dto';
import { HealthIssueCreateParameterDto } from '../models/parameters/health-issue.create-parameter.dto';
import { HealthIssueFindParameterDto } from '../models/parameters/health-issue.find-parameter.dto';
import { HealthIssueUpdateParameterDto } from '../models/parameters/health-issue.update-parameter.dto';

export abstract class HealthIssueClient {

	public abstract getBulkAsComboboxes(
		filter: HealthIssueFilterDto
	): Observable<Result<Array<HealthIssueComboboxDto>, Failure>>;

	public abstract getBulk(
		parameter: PageOptionsDto<HealthIssueFilterDto>
	): Observable<Result<PagedCollectionDto<HealthIssueDto, HealthIssueFilterDto>, Failure>>;

	public abstract get(
		parameter: HealthIssueFindParameterDto
	): Observable<Result<HealthIssueDto, Failure>>;

	public abstract create(
		parameter: HealthIssueCreateParameterDto
	): Observable<Result<string, Failure>>;

	public abstract update(
		findParameter: HealthIssueFindParameterDto,
		updateParameter: HealthIssueUpdateParameterDto
	): Observable<EmptyResult<Failure>>;

	public abstract delete(
		parameter: HealthIssueFindParameterDto
	): Observable<EmptyResult<Failure>>;

	// TODO: add patch

}
