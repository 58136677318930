export class AllergyIntoleranceStatusFilterModel {

	public readonly ids?: Array<string>;
	public readonly ignoreIds?: Array<string>;
	public readonly searchPattern?: string;

	constructor(
		ids: Array<string> | undefined,
		ignoreIds: Array<string> | undefined,
		searchPattern: string | undefined
	) {
		this.ids = ids;
		this.ignoreIds = ignoreIds;
		this.searchPattern = searchPattern;
	}

	public static createEmpty(): AllergyIntoleranceStatusFilterModel {
		return new AllergyIntoleranceStatusFilterModel(undefined, undefined, undefined);
	}

	public static createForAutocomplete(
		searchPattern: string,
		ignoreIds: Array<string>
	): AllergyIntoleranceStatusFilterModel {
		return new AllergyIntoleranceStatusFilterModel(undefined, ignoreIds, searchPattern);
	}

}
