import { Injectable } from '@angular/core';
import { TakenMedicationClient } from '@nmn-communication/taken-medications';
import { Result } from '@nmn-core/shared';
import { FailureModel, PagedCollectionModel, PageOptionsQuery } from '@nmn-domain/shared';
import {
	TakenMedicationDescribedComboboxModel, TakenMedicationFilterModel, TakenMedicationGetQuery, TakenMedicationModel, TakenMedicationQueryHandlerService
} from '@nmn-domain/taken-medications';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapDtoArrayResultToModelArrayResult, mapPageOptionsQueryToDto } from '../../shared/factories/pagination.factory';
import { mapTakenMedicationComboboxDtoToModel } from '../factories/taken-medication-combobox.factory';
import { mapDtoToModel, mapFilterModelToDto, mapTakenMedicationPagedCollectionDtoToModel } from '../factories/taken-medication.factory';

@Injectable()
export class TakenMedicationQueryHandlerViaClientService extends TakenMedicationQueryHandlerService {

	constructor(
		private readonly client: TakenMedicationClient
	) {
		super();
	}

	public getAsDescribedComboboxes(filter: TakenMedicationFilterModel): Observable<Result<Array<TakenMedicationDescribedComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(map(result => mapDtoArrayResultToModelArrayResult(result, mapTakenMedicationComboboxDtoToModel)));
	}

	public getPagedCollection(
		query: PageOptionsQuery<TakenMedicationFilterModel>
	): Observable<Result<PagedCollectionModel<TakenMedicationModel, TakenMedicationFilterModel>, FailureModel>> {
		return this.client
			.getBulk(mapPageOptionsQueryToDto(query, mapFilterModelToDto))
			.pipe(map(result => result.map(mapTakenMedicationPagedCollectionDtoToModel, mapFailureToFailureModel)));
	}

	public get(query: TakenMedicationGetQuery): Observable<Result<TakenMedicationModel, FailureModel>> {
		return this.client
			.get({ id: query.id, patientId: query.patientId })
			.pipe(map(result => result.map(mapDtoToModel, mapFailureToFailureModel)));
	}

}
