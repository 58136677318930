import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { VaccinationCardFindParameterDto } from '../models/parameters/vaccination-card.find-parameter.dto';
import { VaccinationCreateParameterDto } from '../models/parameters/vaccination.create-parameter.dto';
import { VaccinationFindParameterDto } from '../models/parameters/vaccination.find-parameter.dto';
import { VaccinationUpdateParameterDto } from '../models/parameters/vaccination.update-parameter.dto';
import { VaccinationCardDto } from '../models/vaccination-card.dto';
import { VaccinationDto } from '../models/vaccination.dto';

export abstract class VaccinationClient {

	public abstract getVaccinationCard(
		parameter: VaccinationCardFindParameterDto
	): Observable<Result<VaccinationCardDto, Failure>>;

	public abstract get(
		parameter: VaccinationFindParameterDto
	): Observable<Result<VaccinationDto, Failure>>;

	public abstract create(
		parameter: VaccinationCreateParameterDto
	): Observable<Result<string, Failure>>;

	public abstract update(
		findParameter: VaccinationFindParameterDto,
		updateParameter: VaccinationUpdateParameterDto
	): Observable<EmptyResult<Failure>>;

	public abstract delete(
		parameter: VaccinationFindParameterDto
	): Observable<EmptyResult<Failure>>;

}
