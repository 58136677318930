
import { DocumentDescribedComboboxModel, DocumentFormOutModel } from '../../../documents';
import { MedicationAdmRouteDescribedComboboxModel } from '../../../medication-adm-routes';
import { MedicationAdmStatusDescribedComboboxModel } from '../../../medication-adm-statuses';
import { MedicationBodySiteDescribedComboboxModel } from '../../../medication-body-sites';
import { DescribedComboboxModel } from '../../../shared/comboboxes/described-combobox.model';
import { TypeOfMedicationComboboxModel } from '../../../types-of-medication';
import { TakenMedicationModel } from '../taken-medication.model';

export class TakenMedicationFormInModel {

	public readonly id: string | undefined;
	public readonly takingPeriod: { from: Date | undefined; to: Date | undefined; };
	public readonly comment: string | undefined;
	public readonly dosage: { value: number | undefined; type: DescribedComboboxModel | undefined };
	public readonly rate: { value: number | undefined; type: DescribedComboboxModel | undefined };
	public readonly patientId: string;
	public readonly medication: DescribedComboboxModel;
	public readonly encounter: DescribedComboboxModel | undefined;
	public readonly healthIssue: DescribedComboboxModel | undefined;
	public readonly medicationAdmStatus: MedicationAdmStatusDescribedComboboxModel | undefined;
	public readonly medicationAdmRoute: MedicationAdmRouteDescribedComboboxModel | undefined;
	public readonly medicationBodySite: MedicationBodySiteDescribedComboboxModel | undefined;
	public readonly documents: Array<DocumentDescribedComboboxModel>;
	public readonly newDocuments: Array<DocumentFormOutModel>;
	public readonly typeOfMedication: TypeOfMedicationComboboxModel;

	private constructor(
		id: string | undefined,
		takingPeriod: { from: Date | undefined, to: Date | undefined },
		comment: string | undefined,
		dosage: { value: number | undefined, type: DescribedComboboxModel | undefined },
		rate: { value: number | undefined, type: DescribedComboboxModel | undefined },
		patientId: string,
		medication: DescribedComboboxModel,
		encounter: DescribedComboboxModel | undefined,
		healthIssue: DescribedComboboxModel | undefined,
		medicationAdmStatus: MedicationAdmStatusDescribedComboboxModel | undefined,
		medicationAdmRoute: MedicationAdmRouteDescribedComboboxModel | undefined,
		medicationBodySite: MedicationBodySiteDescribedComboboxModel | undefined,
		documents: Array<DocumentDescribedComboboxModel>,
		newDocuments: Array<DocumentFormOutModel>,
		typeOfMedication: TypeOfMedicationComboboxModel
	) {
		this.id = id;
		this.takingPeriod = takingPeriod;
		this.comment = comment;
		this.dosage = dosage;
		this.takingPeriod = takingPeriod;
		this.rate = rate;
		this.patientId = patientId;
		this.medication = medication;
		this.encounter = encounter;
		this.healthIssue = healthIssue;
		this.medicationAdmStatus = medicationAdmStatus;
		this.medicationAdmRoute = medicationAdmRoute;
		this.medicationBodySite = medicationBodySite;
		this.medicationBodySite = medicationBodySite;
		this.documents = documents;
		this.newDocuments = newDocuments;
		this.typeOfMedication = typeOfMedication;
	}

	public static createEmpty = (patientId: string): TakenMedicationFormInModel => {
		return new TakenMedicationFormInModel(
			undefined,
			{ from: undefined, to: undefined },
			undefined,
			{ value: undefined, type: undefined },
			{ value: undefined, type: undefined },
			patientId,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			[],
			[],
			undefined
		);
	}

	public static createFromExistingModel = (model: TakenMedicationModel): TakenMedicationFormInModel => {
		// TODO: Guard.isDefined(model);

		return new TakenMedicationFormInModel(
			model.id,
			{ from: model.from, to: model.to },
			model.comment,
			{ value: model.dosageValue, type: model.medicationDosage },
			{ value: model.rateValue, type: model.medicationRateQuantityType },
			model.patientId,
			model.medication,
			model.encounter,
			model.healthIssue,
			model.medicationAdmStatus,
			model.medicationAdmRoute,
			model.medicationBodySite,
			model.documents,
			[],
			model.typeOfMedication
		);
	}

}
