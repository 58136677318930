import { CompleteResetPasswordParameter, InitiateResetPasswordParameter } from '@nmn-communication/accounts';
import { CompleteResetPasswordCommand } from '@nmn-domain/accounts/reset-password/models/commands/complete-reset-password.command';
import { InitiateResetPasswordCommand } from '@nmn-domain/accounts/reset-password/models/commands/initiate-reset-password.command';

export const mapCompleteResetPasswordCommandToParameter = (
	command: CompleteResetPasswordCommand
): CompleteResetPasswordParameter => (
	{
		userId: command.userId,
		token: command.token,
		newPassword: command.newPassword,
		confirmNewPassword: command.confirmNewPassword
	}
);

export const mapInitiateResetPasswordCommandToParameter = (
	command: InitiateResetPasswordCommand
): InitiateResetPasswordParameter => (
	{
		email: command.email
	}
);
