import { TranslocoService } from '@ngneat/transloco';
import { ComboboxDto } from '@nmn-communication/shared';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../databases/fake.database';
import { FakeLocalizableEntity } from '../../models/fake-localizable-entity';
import { setTranslation } from '../../utils/localize';

export class FoodFakeTable {

	private readonly data: Array<FoodFakeRecord>;

	constructor(
		_: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.data = [...initialData];
	}

	public getAsCombobox(parameter: { id: string }): ComboboxDto<string> {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record was not found');
		}

		return record;
	}

	public findAsCombobox(parameter: { id: string }): ComboboxDto<string> {
		const record = this.data.find(item => item.id === parameter?.id);

		return isValueDefined(record) ? { id: record!.id, displayText: setTranslation(this.translocoService, record!.name) } : undefined!;
	}

}

interface FoodFakeRecord {
	id: string;
	name: FakeLocalizableEntity;
}

const initialData: Array<FoodFakeRecord> = [
	{
		id: 'alcohol-ethanol',
		name: {
			en: 'Alcohol (Ethanol)',
			uk: 'Алкоголь (етанол)'
		}
	},
	{
		id: 'alcohol',
		name: {
			en: 'Alcohol',
			uk: 'Алкоголь'
		}
	}
];
