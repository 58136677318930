import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { PatientGetQuery, PatientModel, PatientQueryHandlerService, PatientResolver } from '@nmn-domain/patients';
import { RouteParameter } from '@nmn-middleware/navigation';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PatientViaQueryHandlerResolver extends PatientResolver {

	constructor(
		private readonly patientQueryHandlerService: PatientQueryHandlerService
	) {
		super();
	}

	public resolve(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<PatientModel> | Promise<PatientModel> | PatientModel {
		const patientAlias = route.paramMap.get(RouteParameter.PatientAlias);

		if (!isStringDefinedAndNotEmpty(patientAlias)) {
			return undefined;
		}

		return this.patientQueryHandlerService
			.get(PatientGetQuery.createByAlias(patientAlias))
			.pipe(map(result => result.successOrDefault(() => undefined)));
	}

}
