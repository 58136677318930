import { isValueDefined } from '@nmn-core/utils';
import { ContactsFakeClientConfiguration } from '../configurations/contacts.fake-client.configuration';

export class ContactsFakeClientConfigurationBuilder {

	private readonly serverDelayMs: { min: number, max: number } = { min: 0, max: 0 };

	public withServerDelay(
		serverDelayMs: { min: number, max: number }
	): ContactsFakeClientConfigurationBuilder {
		const isServerDelayValidAndDefined = isValueDefined(serverDelayMs) &&
			isValueDefined(serverDelayMs.min) &&
			isValueDefined(serverDelayMs.max) &&
			serverDelayMs.min <= serverDelayMs.max;

		if (isServerDelayValidAndDefined) {
			this.serverDelayMs.min = serverDelayMs.min;
			this.serverDelayMs.max = serverDelayMs.max;
		}

		return this;
	}

	public build(): ContactsFakeClientConfiguration {
		return new ContactsFakeClientConfiguration(this.serverDelayMs);
	}

}
