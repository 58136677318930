import { Injectable } from '@angular/core';
import { ContactsClient } from '@nmn-communication/contacts';
import { Result } from '@nmn-core/shared';
import { ContactsModel, ContactsQueryHandlerService } from '@nmn-domain/contacts';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapContactsDtoToModel } from '../factories/contacts.factory';

@Injectable()
export class ContactsQueryHandlerViaClientService extends ContactsQueryHandlerService {

	constructor(
		private readonly client: ContactsClient
	) {
		super();
	}

	public getContacts(): Observable<Result<ContactsModel, FailureModel>> {
		return this.client
			.getContacts()
			.pipe(map(result => result.map(mapContactsDtoToModel, mapFailureToFailureModel)));
	}

}
