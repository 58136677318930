import { DoctorEncounterCreateCommand } from '@nmn-domain/doctor-encounters';
import { DocumentCreateCommand } from '@nmn-domain/documents';
import { TakenMedicationCreateCommand } from '@nmn-domain/taken-medications';
import { HealthIssueFormOutModel } from '../health-issue-form/health-issue-form.out-model';
import { HealthIssueStatus } from '../health-issue-status.enum';

export class HealthIssueCreateCommand {

	public readonly patientId: string;
	public readonly name?: string;
	public readonly comment?: string;
	public readonly dateStart?: Date;
	public readonly dateEnd?: Date;
	public readonly status: HealthIssueStatus;
	public readonly severityId: string;
	public readonly healthComplaintIds: Array<string>;
	public readonly encounterIds: Array<string>;
	public readonly doctorEncounters: Array<DoctorEncounterCreateCommand>;
	public readonly takenMedicationIds: Array<string>;
	public readonly takenMedications: Array<TakenMedicationCreateCommand>;
	public readonly documentIds: Array<string>;
	public readonly documents: Array<DocumentCreateCommand>;

	constructor(
		patientId: string,
		name: string | undefined,
		comment: string | undefined,
		dateStart: Date | undefined,
		dateEnd: Date | undefined,
		status: HealthIssueStatus,
		severityId: string,
		healthComplaintIds: Array<string>,
		encounterIds: Array<string>,
		doctorEncounters: Array<DoctorEncounterCreateCommand>,
		takenMedicationIds: Array<string>,
		takenMedications: Array<TakenMedicationCreateCommand>,
		documentIds: Array<string>,
		documents: Array<DocumentCreateCommand>
	) {
		// TODO: Guard check on defined
		this.patientId = patientId;
		this.name = name;
		this.comment = comment;
		this.dateStart = dateStart;
		this.dateEnd = dateEnd;
		this.status = status;
		this.severityId = severityId;
		this.healthComplaintIds = healthComplaintIds;
		this.encounterIds = encounterIds;
		this.doctorEncounters = doctorEncounters;
		this.takenMedicationIds = takenMedicationIds;
		this.takenMedications = takenMedications;
		this.documentIds = documentIds;
		this.documents = documents;
	}

	public static createByFormOutModel(formOutModel: HealthIssueFormOutModel): HealthIssueCreateCommand {
		return new HealthIssueCreateCommand(
			formOutModel.patientId,
			formOutModel.name,
			formOutModel.comment,
			formOutModel.dateStart,
			formOutModel.dateEnd,
			formOutModel.status,
			formOutModel.severityId,
			formOutModel.healthComplaintIds,
			formOutModel.encounterIds,
			formOutModel.doctorEncounters.map(DoctorEncounterCreateCommand.createByFormOutModel),
			formOutModel.takenMedicationIds,
			formOutModel.takenMedications.map(TakenMedicationCreateCommand.createByFormOutModel),
			formOutModel.documentIds,
			formOutModel.documents.map(DocumentCreateCommand.createByFormOutModel)
		);
	}

}
