import { EmptyResult } from '@nmn-core/shared';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { CurrentUserConfigurationGetCommand } from '../models/commands/current-user-configuration.get-command';

export abstract class CurrentUserConfigurationQueryHandlerService {

	public abstract get(command: CurrentUserConfigurationGetCommand): Observable<EmptyResult<FailureModel>>;

}
