import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { AllergyHttpClientConfiguration } from '../configurations/allergy.http-client.configuration';
import { AllergyHttpClient } from '../services/allergy.http-client';

export class AllergyHttpClientBuilder
	extends HttpClientBuilder<AllergyHttpClient, AllergyHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): AllergyHttpClient {
		return new AllergyHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
