import { LoginDto, UserSubscriptionClient, UserSubscriptionGetParameter, UserSubscriptionUpdateParameter } from '@nmn-communication/accounts';
import { Failure } from '@nmn-communication/shared';
import { LocalStorageService } from '@nmn-core/application-storages';
import { Result } from '@nmn-core/shared';
import { wrapFuncCallAsFakeClientResponse } from 'app/modules/fakes/databases/fake.utils';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../../modules/fakes/databases/fake.database';
import { UserSubscriptionFakeClientConfiguration } from '../configurations/user-subscription.fake-client.configuration';

export class UserSubscriptionFakeClient extends UserSubscriptionClient {

	constructor(
		private readonly configuration: UserSubscriptionFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase,
		private readonly localStorage: LocalStorageService
	) {
		super();
	}

	public update(parameter: UserSubscriptionUpdateParameter): Observable<Result<LoginDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.userSubscriptionFakeTable.update(this.localStorage.getLastUserId(), parameter),
			this.configuration.serverDelayMs
		);
	}

	public get(parameter: UserSubscriptionGetParameter): Observable<Result<LoginDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.usersTable.get({ id: parameter.userId }),
			this.configuration.serverDelayMs
		);
	}

}
