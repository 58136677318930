import { isValueDefined } from '@nmn-core/utils';

export enum GeneticFileParseRequestStatus {

	Undefined = 'undefined',
	Aborted = 'aborted',
	Inited = 'inited',
	UploadFinished = 'uploadFinished',
	Created = 'created',
	DocumentCreated = 'documentCreated',
	ParseFinished = 'parseFinished',
	GeneticInfoCreated = 'geneticInfoCreated',
	Done = 'done',
	EndedWithError = 'endedWithError'

}

export const mapGeneticFileParseRequestStatusFromString = (stringValue: string): GeneticFileParseRequestStatus => {
	const enumValue = Object
		.keys(GeneticFileParseRequestStatus)
		.find(x => GeneticFileParseRequestStatus[x] === stringValue);

	return isValueDefined(enumValue) ? stringValue as GeneticFileParseRequestStatus : GeneticFileParseRequestStatus.Undefined;
};
