import { Injector } from '@angular/core';
import { GeneticFileParseRequestClient } from '@nmn-communication/genetic-file-parse-requests';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { GeneticFileParseRequestFakeClientBuilder } from '../builders/genetic-file-parse-request.fake-client.builder';
import { GeneticFileParseRequestFakeClientConfigurationBuilder } from '../builders/genetic-file-parse-request.fake-client.configuration.builder';
import { GeneticFileParseRequestHttpClientBuilder } from '../builders/genetic-file-parse-request.http-client.builder';
import { GeneticFileParseRequestHttpClientConfigurationBuilder } from '../builders/genetic-file-parse-request.http-client.configuration.builder';
import { GeneticFileParseRequestFakeClient } from '../services/genetic-file-parse-request.fake-client';
import { GeneticFileParseRequestHttpClient } from '../services/genetic-file-parse-request.http-client';

export const GeneticFileParseRequestClientFactory = (injector: Injector): GeneticFileParseRequestClient => {
	const useFake = environment?.api?.resources?.geneticFileParseRequests?.useFake ||
		!isValueDefined(environment?.api?.resources?.geneticFileParseRequests?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		GeneticFileParseRequestFakeClientFactory(injector) :
		GeneticFileParseRequestHttpClientFactory(injector);
};

export const GeneticFileParseRequestFakeClientFactory = (
	injector: Injector
): GeneticFileParseRequestFakeClient => {
	const configuration = new GeneticFileParseRequestFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.geneticFileParseRequests.serverDelayMs)
		.build();

	return new GeneticFileParseRequestFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const GeneticFileParseRequestHttpClientFactory = (
	injector: Injector
): GeneticFileParseRequestHttpClient => {
	const configuration = new GeneticFileParseRequestHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.geneticFileParseRequests?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.geneticFileParseRequests.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.geneticFileParseRequests?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new GeneticFileParseRequestHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
