import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { VaccinationGetQuery, VaccinationModel, VaccinationQueryHandlerService, VaccinationResolver } from '@nmn-domain/vaccination';
import { RouteParameter } from '@nmn-middleware/navigation';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class VaccinationViaQueryHandlerResolver extends VaccinationResolver {

	constructor(
		private readonly vaccinationQueryHandlerService: VaccinationQueryHandlerService
	) {
		super();
	}

	public resolve(
		activatedRoute: ActivatedRouteSnapshot,
		_: RouterStateSnapshot
	): Observable<VaccinationModel> | Promise<VaccinationModel> | VaccinationModel {
		const vaccinationId = activatedRoute.paramMap.get(RouteParameter.VaccinationId);
		const patientId = activatedRoute.paramMap.get(RouteParameter.PatientAlias);

		if (!isStringDefinedAndNotEmpty(vaccinationId) || !isStringDefinedAndNotEmpty(patientId)) {
			return undefined;
		}

		return this.vaccinationQueryHandlerService
			.handleGetQuery(VaccinationGetQuery.create(vaccinationId, patientId))
			.pipe(map(result => result.successOrDefault(() => undefined)));
	}

}
