import { Failure } from '@nmn-communication/shared';
import { EmptyResult } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { RegisterAccountParameter } from '../models/register-account.parameter';

export abstract class RegistrationClient {

	public abstract registerAccount(parameter: RegisterAccountParameter): Observable<EmptyResult<Failure>>;

}
