import { Injector } from '@angular/core';
import { LocalStorageService } from '../../../../core/application-storages';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { isValueDefined } from '@nmn-core/utils';
import { PatientFakeClientConfiguration } from '../configurations/patient.fake-client.configuration';
import { PatientFakeClient } from '../services/patient.fake-client';

export class PatientFakeClientBuilder {

	private configuration: PatientFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(configuration: PatientFakeClientConfiguration): PatientFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): PatientFakeClient {
		return new PatientFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase),
			this.injector.get(LocalStorageService)
		);
	}

}
