import { Injector, Provider } from '@angular/core';
import { TimezoneClient } from '@nmn-communication/timezones';
import { timezoneClientFactory } from './factories/timezone.client.factory';

export const timezoneProviders: Array<Provider> = [
	{
		provide: TimezoneClient,
		useFactory: timezoneClientFactory,
		deps: [Injector]
	}
];
