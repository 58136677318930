import { Failure } from '@nmn-communication/shared';
import { EmptyResult } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { CompleteResetPasswordParameter } from '../models/complete-reset-password.parameter';
import { InitiateResetPasswordParameter } from '../models/initiate-reset-password.parameter';

export abstract class ResetPasswordClient {

	public abstract completeResetPassword(parameter: CompleteResetPasswordParameter): Observable<EmptyResult<Failure>>;

	public abstract initiateResetPassword(parameter: InitiateResetPasswordParameter): Observable<EmptyResult<Failure>>;

}
