import { Failure } from '@nmn-communication/shared';
import { ErrorCodeDto, ErrorDetailDto, ErrorResourceDto } from '@nmn-communication/shared/error-handling/error.dto';
import { isArrayDefined, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import {
	FailureCodeModel, FailureDetailModel,
	FailureModel, FailureResourceModel,
	mapFailureModelOperationTypeFromString, mapFailureModelSeverityTypeFromString, mapPropertyFailureModelSeverityTypeFromString
} from '@nmn-domain/shared';
import { FailureLocalizationModel } from '@nmn-domain/shared/failures/failure-localization-parameters.model';
import { FailureWrapperModel } from '@nmn-domain/shared/failures/failure-wrapper.model';

export const mapFailureToFailureModel = (failureEnvelope: Failure): FailureModel => {
	if (!isValueDefined(failureEnvelope.error) ||
		!isArrayDefined(failureEnvelope.error.resources) ||
		!isValueDefined(failureEnvelope.error.traceIdentifier)
	) {
		return isStringDefinedAndNotEmpty(failureEnvelope.fullLocalizationKey)
			? FailureModel.createForUndefinedIssue(failureEnvelope.value, FailureLocalizationModel.createEmpty())
			: FailureModel.createForUndefinedIssue(failureEnvelope.value, FailureLocalizationModel.createFromValue(failureEnvelope.fullLocalizationKey, undefined));
	}

	return FailureModel.createForBackendIssue(
		failureEnvelope.error.resources.map(mapFailureResourceModelFromErrorResourceDto),
		FailureWrapperModel.createFromValue(failureEnvelope.error)
	);
};

export const mapFailureResourceModelFromErrorResourceDto = (dto: ErrorResourceDto): FailureResourceModel => {
	return FailureResourceModel.create(
		dto.entityName,
		mapErrorCodeDtoToFailureCodeModel(dto.code),
		(dto.properties || []).map(mapFailureDetailModelFromErrorDetailDto)
	);
};

export const mapFailureDetailModelFromErrorDetailDto = (dto: ErrorDetailDto): FailureDetailModel => {
	return FailureDetailModel.create(
		dto.name,
		dto.message,
		dto.code,
		mapPropertyFailureModelSeverityTypeFromString(dto.severity)
	);
};

export const mapErrorCodeDtoToFailureCodeModel = (errorCode: ErrorCodeDto): FailureCodeModel =>
	FailureCodeModel.create(
		mapFailureModelSeverityTypeFromString(errorCode.severity),
		mapFailureModelOperationTypeFromString(errorCode.failureOperationHandlingType),
		errorCode.specialCode
	);
