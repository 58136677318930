import { Provider } from '@angular/core';
import { LoginCommandHandlerService } from '@nmn-domain/accounts/login/services/login.command-handler.service';
import { LoginCommandHandlerViaClientService } from './services/login.command-handler-via-client.service';

export const accountsLoginProviders: Array<Provider> = [
	{
		provide: LoginCommandHandlerService,
		useClass: LoginCommandHandlerViaClientService
	}
];
