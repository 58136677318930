import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { MedicationAdmStatusHttpClientConfiguration } from '../configurations/medication-adm-status.http-client.configuration';

export class MedicationAdmStatusHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<MedicationAdmStatusHttpClientConfiguration> {

	public build(): MedicationAdmStatusHttpClientConfiguration {
		return new MedicationAdmStatusHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
