import { TranslocoService } from '@ngneat/transloco';
import { LoginDto, PatientProfileShortDto } from '@nmn-communication/accounts';
import { UploadedFileDto } from '@nmn-communication/file-uploader';
import {
	PatientCreateParameter, PatientDeleteParameter, PatientDto, PatientFilterDto, PatientFindParameter,
	PatientProfileDto, PatientRevokeDeleteParameter, PatientUpdateParameter
} from '@nmn-communication/patients';
import { Guid, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { PageOptionsDto, PagedCollectionDto } from '../../core/clients';
import { EmergencyCardDto } from '../../emergency-card/clients/models/dtos/emergency-card.dto';
import { EmergencyCardFindParameter } from '../../emergency-card/clients/models/parameters/emergency-card.find-parameter';
import { FakeDatabase } from '../databases/fake.database';
import { getPagedCollectionWithoutItemFilter } from '../databases/fake.utils';
import { FakeLocalizableEntity } from '../models/fake-localizable-entity';
import { setLocalizableEntity, setTranslation } from '../utils/localize';

export class PatientFakeTable {

	private readonly database: FakeDatabase;
	private readonly data: Array<PatientFakeRecord>;

	constructor(
		database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.database = database;
		this.data = [...initialData];
	}

	public get(parameter: PatientFindParameter): PatientDto {
		const record = this.data.find((item: PatientFakeRecord) => findPredicate(item, parameter));

		if (!isValueDefined(record)) {
			throw new Error('Patient was not found.');
		}

		return this.mapFromRecordToDto(record!);
	}

	public getEmergencyCard(parameter: EmergencyCardFindParameter): EmergencyCardDto {
		const record = this.data
			.find((item: PatientFakeRecord) => findPredicate(item, { alias: parameter.patientAlias }));

		if (!isValueDefined(record)) {
			throw new Error('Patient was not found.');
		}

		return this.mapFromRecordToDto(record!);
	}

	public getProfile(parameter: PatientFindParameter): PatientProfileDto {
		const record = this.data.find((item: PatientFakeRecord) => findPredicate(item, parameter));

		if (!isValueDefined(record)) {
			throw new Error('Patient was not found.');
		}
		// TODO: stub implementation
		switch (this.translocoService.getActiveLang()) {
			case 'uk':
				return {
					fillingUpPercentage: 86,
					documents: {
						fillingUpPercentage: 100,
						count: 5,
						lastAddedDate: '2021-03-12T20:00:00Z',
						lastAddedDisplayText: '\'Звичайний загальний аналіз крові\''
					},
					emergencyCard: {
						fillingUpPercentage: 80
					},
					geneticTestCard:
					{
						fillingUpPercentage: 100,
						count: 3
					},
					labTestChecker: {
						count: 3,
						fillingUpPercentage: 100,
						lastCheckUpDate: '2021-02-19T12:47:00Z'
					},
					medicalCard: {
						fillingUpPercentage: 100,
						lastModificationDate: '2021-02-02T00:00:00Z',
						firstRecordDate: '2015-03-19T00:00:00Z',
						encountersCount: 6,
						healthIssuesCount: 6,
						lastEncounter: {
							description: undefined!,
							displayText: 'Розтягнення щиколотки',
							id: 'sprained-ankle'
						},
						lastHealthIssue: {
							description: undefined!,
							displayText: 'Розтягнення щиколотки',
							id: 'sprained-ankle'
						},
						medicationsCount: 12
					},
					patient: {
						fillingUpPercentage: 70,
						...this.mapFromRecordToDto(record!)
					}
				};
			default:
				return {
					fillingUpPercentage: 86,
					documents: {
						fillingUpPercentage: 100,
						count: 5,
						lastAddedDate: '2021-03-12T20:00:00Z',
						lastAddedDisplayText: '\'Routine complete blood count\''
					},
					emergencyCard: {
						fillingUpPercentage: 80
					},
					geneticTestCard: {
						fillingUpPercentage: 100,
						count: 3
					},
					labTestChecker: {
						count: 3,
						fillingUpPercentage: 100,
						lastCheckUpDate: '2021-02-19T12:47:00Z'
					},
					medicalCard: {
						fillingUpPercentage: 100,
						lastModificationDate: '2021-02-02T00:00:00Z',
						firstRecordDate: '2015-03-19T00:00:00Z',
						encountersCount: 6,
						healthIssuesCount: 6,
						lastEncounter: {
							description: undefined!,
							displayText: 'Sprained ankle',
							id: 'sprained-ankle'
						},
						lastHealthIssue: {
							description: undefined!,
							displayText: 'Sprained ankle',
							id: 'sprained-ankle'
						},
						medicationsCount: 12
					},
					patient: {
						fillingUpPercentage: 70,
						...this.mapFromRecordToDto(record!)
					}
				};
		}
	}

	public getPagedCollection(
		pageOptions: PageOptionsDto<PatientFilterDto>
	): PagedCollectionDto<PatientDto, PatientFilterDto> {
		return getPagedCollectionWithoutItemFilter(
			this.translocoService,
			this.data,
			pageOptions,
			this.mapFromRecordToDto.bind(this),
			filterPredicate,
			compareFn);
	}

	public getShortPatientProfiles(userId: string): Array<PatientProfileShortDto> {
		return this.data
			.filter(record => record.userId === userId)
			.map(
				record => ({
					id: record.id,
					alias: record.alias,
					userId: record.userId,
					email: record.email,
					givenName: setTranslation(this.translocoService, record.givenName),
					additionalName: setTranslation(this.translocoService, record.additionalName),
					familyName: setTranslation(this.translocoService, record.familyName),
					picture: record.picture,
					isDeletionRequested: record.isDeletionRequested,
					deletionRequestedOn: record.deletionRequestedOn
				}));
	}

	public getActivePatients(userId: string): Array<PatientDto> {
		return this.data
			.filter((item: PatientFakeRecord) => item.userId === userId && !item.isDeletionRequested)
			.map((item: PatientFakeRecord) => this.mapFromRecordToDto(item));
	}

	public create(userId: string, parameter: PatientCreateParameter): string {
		const record = mapFromCreateParameterToRecord(this.translocoService.getActiveLang(), userId, parameter);
		this.data.push(record);

		return record.id;
	}

	public update(findParameter: PatientFindParameter, updateParameter: PatientUpdateParameter): void {
		const record = this.data
			.find((item: PatientFakeRecord) => findPredicate(item, findParameter));

		applyUpdateParameter(this.translocoService.getActiveLang(), record!, updateParameter);
	}

	public delete(parameter: PatientDeleteParameter): LoginDto {
		const record = this.data.find((item: PatientFakeRecord) => findPredicate(item, parameter));

		if (!isValueDefined(record)) {
			throw new Error('Patient was not found.');
		}

		// TODO: get user and check password
		// if (userRecord.password !== parameter.password) {
		// 	throw new Error('Password is not correct.');
		// }

		record!.isDeletionRequested = true;
		record!.deletionRequestedOn = (new Date()).toISOString();

		return this.database.usersTable.get({ id: record!.userId });
	}

	public revokeDelete(parameter: PatientRevokeDeleteParameter): LoginDto {
		const record = this.data.find((item: PatientFakeRecord) => findPredicate(item, parameter));

		if (!isValueDefined(record)) {
			throw new Error('Patient was not found.');
		}

		// TODO: get user and check password
		// if (userRecord.password !== parameter.password) {
		// 	throw new Error('Password is not correct.');
		// }

		record!.isDeletionRequested = false;
		record!.deletionRequestedOn = undefined;

		return this.database.usersTable.get({ id: record!.userId });
	}

	private mapFromRecordToDto(record: PatientFakeRecord): PatientDto {
		return {
			id: record.id,
			alias: record.alias,
			userId: record.userId,
			createdOn: record.createdOn,
			updatedOn: record.updatedOn!,
			givenName: setTranslation(this.translocoService, record.givenName),
			additionalName: setTranslation(this.translocoService, record.additionalName),
			familyName: setTranslation(this.translocoService, record.familyName),
			picture: record.picture,
			population: this.database.populationTable.getAsCombobox({ id: record.populationId }),
			gender: this.database.gendersTable.getAsCombobox({ id: record.genderId }),
			birthday: record.birthday,
			bloodType: this.database.bloodTypesTable.getAsCombobox({ id: record.bloodTypeId! }),
			height: record.height!,
			weight: record.weight!,
			email: record.email,
			phoneNumber: record.phoneNumber!,
			address: setTranslation(this.translocoService, record.address),
			languages: record.languageIds
				.map(id => this.database.languageTable.findAsCombobox({ id }))
				.filter(isValueDefined),
			preferredLanguage: this.database.languageTable.findAsCombobox({ id: record.preferredLanguageId }),
			allergyIntolerances: record.allergyIntolerances
				.map(item => {
					return {
						allergy: this.database.allergyTable.findAsCombobox({ id: item.allergyId }),
						criticality: this.database.allergyIntoleranceCriticalityTable
							.findAsCombobox({ id: item.criticalityId }),
						status: this.database.allergyIntoleranceStatusTable
							.findAsCombobox({ id: item.statusId }),
						verificationStatus: this.database.allergyIntoleranceVerificationStatusTable
							.findAsCombobox({ id: item.verificationStatusId }),
						lastOccurrence: item.lastOccurrence
					};
				})
				.filter(isValueDefined),
			seriousPathologies: record.seriousPathologyIds
				.map(id => this.database.diseasesTable.findAsCombobox({ id, isSeriousPathology: true }))
				.filter(isValueDefined),
			familyDiseases: record.familyDiseaseIds
				.map(id => this.database.diseasesTable.findAsCombobox({ id, isSeriousPathology: true }))
				.filter(isValueDefined),
			regularlyTakenMedications: record.regularlyTakenMedicationIds
				.map(id => this.database.medicationsTable.findAsCombobox({ id }))
				.filter(isValueDefined),
			doctors: record.doctors?.map(item => {
				return {
					fullName: setTranslation(this.translocoService, item.fullName),
					contacts: setTranslation(this.translocoService, item.contacts)
				}
			}),
			emergencyContacts: record.emergencyContacts.map(item => {
				return {
					fullName: setTranslation(this.translocoService, item.fullName),
					data: item.data
				}
			}),
			insurances: record.insurances.map(item => {
				return {
					company: setTranslation(this.translocoService, item.company),
					code: setTranslation(this.translocoService, item.code),
				}
			}),
			anamnesis: record.anamnesis,
			lifestyle: record.lifestyle
		};
	}

}

const findPredicate = (item: PatientFakeRecord, findParameter: PatientFindParameter): boolean =>
	item.id === findParameter.alias ||
	item.alias === findParameter.alias;

const filterPredicate = (_: PatientDto, filter: PatientFilterDto): boolean => {
	let result = true;

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result;
	}

	return result;
};

// eslint-disable
const compareFn = (item1: PatientDto, item2: PatientDto, sorting: string): number => {
	if (sorting === 'medication.id asc') {

		return item1.id > item2.id ? 1 : item1.id < item2.id ? -1 : 0;
	}

	return 0;
};
// eslint-enable

const mapFromCreateParameterToRecord = (
	language: string,
	userId: string,
	parameter: PatientCreateParameter
): PatientFakeRecord => {
	const uid = Guid.newGuid();

	return {
		id: uid,
		alias: uid,
		userId,
		createdOn: (new Date()).toISOString(),
		updatedOn: undefined,
		givenName: setLocalizableEntity(parameter.givenName, language),
		additionalName: setLocalizableEntity(parameter.additionalName, language),
		familyName: setLocalizableEntity(parameter.familyName, language),
		picture: isValueDefined(parameter.pictureId) ?
			{
				id: '00000001-0000-0000-0000-000000000001',
				createdOn: (new Date()).toISOString(),
				updatedOn: undefined,
				downloadUrl: 'https://fakeimg.pl/200x200',
				fileName: 'fake-img',
				lengthInBytes: 1,
				fileExtension: undefined,
				fileType: 'userAvatar'
			} :
			undefined,
		populationId: parameter.populationId,
		genderId: parameter.genderId,
		birthday: parameter.birthday,
		bloodTypeId: parameter.bloodTypeId,
		height: parameter.height,
		weight: parameter.weight,
		email: parameter.email,
		phoneNumber: parameter.phoneNumber,
		address: setLocalizableEntity(parameter.address, language),
		languageIds: parameter.languageIds,
		preferredLanguageId: parameter.preferredLanguageId,
		allergyIntolerances: parameter.allergyIntolerances,
		seriousPathologyIds: parameter.seriousPathologyIds,
		familyDiseaseIds: parameter.familyDiseaseIds,
		regularlyTakenMedicationIds: parameter.regularlyTakenMedicationIds,
		doctors: parameter.doctors?.map(item => {
			return {
				fullName: setLocalizableEntity(item.fullName, language),
				contacts: setLocalizableEntity(item.contacts, language)
			}
		}),
		emergencyContacts: parameter.emergencyContacts.map(item => {
			return {
				fullName: setLocalizableEntity(item.fullName, language),
				data: item.data
			}
		}),
		insurances: parameter.insurances.map(item => {
			return {
				company: setLocalizableEntity(item.company, language),
				code: setLocalizableEntity(item.code, language),
			}
		}),
		anamnesis: parameter.anamnesis,
		lifestyle: parameter.lifestyle,
		isDeletionRequested: false,
		deletionRequestedOn: undefined
	};
};

const applyUpdateParameter = (
	language: string,
	record: PatientFakeRecord,
	parameter: PatientUpdateParameter
): void => {
	if (isValueDefined(parameter)) {
		record.givenName = setLocalizableEntity(parameter.givenName, language);
		record.additionalName = setLocalizableEntity(parameter.additionalName, language);
		record.familyName = setLocalizableEntity(parameter.familyName, language);

		if (isValueDefined(parameter.pictureId)) {
			record.picture = {
				id: parameter.pictureId!,
				createdOn: (new Date()).toISOString(),
				updatedOn: undefined,
				downloadUrl: 'https://fakeimg.pl/200x200',
				fileName: 'fake-img',
				lengthInBytes: 1,
				fileExtension: undefined,
				fileType: 'userAvatar'
			};
		} else {
			record.picture = undefined;
		}

		record.genderId = parameter.genderId;
		record.birthday = parameter.birthday;
		record.bloodTypeId = parameter.bloodTypeId;
		record.height = parameter.height;
		record.weight = parameter.weight;

		record.email = parameter.email;
		record.phoneNumber = parameter.phoneNumber;
		record.address = setLocalizableEntity(parameter.address, language);
		record.languageIds = parameter.languageIds;
		record.preferredLanguageId = parameter.preferredLanguageId;

		record.allergyIntolerances = parameter.allergyIntolerances;
		record.seriousPathologyIds = parameter.seriousPathologyIds;
		record.familyDiseaseIds = parameter.familyDiseaseIds;
		record.regularlyTakenMedicationIds = parameter.regularlyTakenMedicationIds;
		record.doctors = parameter.doctors?.map(item => {
			return {
				fullName: setLocalizableEntity(item.fullName, language),
				contacts: setLocalizableEntity(item.contacts, language)
			}
		});
		record.emergencyContacts = parameter.emergencyContacts.map(item => {
			return {
				fullName: setLocalizableEntity(item.fullName, language),
				data: item.data
			}
		});
		record.insurances = parameter.insurances.map(item => {
			return {
				company: setLocalizableEntity(item.company, language),
				code: setLocalizableEntity(item.code, language),
			}
		});

		record.anamnesis = parameter.anamnesis;
		record.lifestyle = parameter.lifestyle;
	}
};

interface PatientFakeRecord {
	id: string;
	alias: string;
	userId: string;
	createdOn: string;
	updatedOn?: string;

	givenName: FakeLocalizableEntity;
	additionalName?: FakeLocalizableEntity;
	familyName: FakeLocalizableEntity;

	picture?: UploadedFileDto;
	populationId: string;
	genderId: string;
	birthday: string;
	bloodTypeId?: string;
	height?: number;
	weight?: number;

	email: string;
	phoneNumber?: string;
	address?: FakeLocalizableEntity;
	languageIds: Array<string>;
	preferredLanguageId: string;

	allergyIntolerances: Array<{
		allergyId: string,
		criticalityId: string,
		statusId: string,
		verificationStatusId: string,
		lastOccurrence?: string
	}>;
	seriousPathologyIds: Array<string>;
	familyDiseaseIds: Array<string>;
	regularlyTakenMedicationIds: Array<string>;
	doctors: Array<{ fullName: FakeLocalizableEntity; contacts: FakeLocalizableEntity; }>;
	emergencyContacts: Array<{ fullName: FakeLocalizableEntity; data: string; }>;
	insurances: Array<{ company: FakeLocalizableEntity; code: FakeLocalizableEntity; }>;

	anamnesis: {
		hasHealthIssuesWithLiver?: boolean;
		hasHealthIssuesWithKidney?: boolean;
		hasHealthIssuesWithHeart?: boolean;
		hasHealthIssuesWithStomach?: boolean;
		hasHealthIssuesWithNervousSystem?: boolean;
		hasHealthIssuesWithImmuneSystem?: boolean;
		hasHealthIssuesWithEndocrine?: boolean;
		hasHealthIssuesWithCancer?: boolean;
		hasHealthIssuesWithEye?: boolean;
		hasHealthIssuesWithEar?: boolean;
		hasHealthIssuesWithMuscles?: boolean;
		hasHealthIssuesWithGeneticConditions?: boolean;
	};
	lifestyle: {
		isPregnant?: boolean;
		isActiveLifestyle?: boolean;
		isAlcohol?: boolean;
		isOnHealthyDiet?: boolean;
		isSmoking?: boolean;
		isUnderHighLevelOfStress?: boolean;
		isUsingNutritionalSupplements?: boolean;
	};

	isDeletionRequested: boolean;
	deletionRequestedOn?: string;
}

// PatientFakeRecord (initial data) has id mask 00000000-0000-0000-0002-************
const initialData: Array<PatientFakeRecord> = [
	{
		id: '00000000-0000-0000-0002-000000000001',
		alias: 'john.hopkins',
		userId: '00000000-0000-0000-0001-000000000001',
		createdOn: '2020-12-10T00:00:00Z',
		updatedOn: '2021-01-30T00:00:00Z',
		givenName: {
			en: 'John',
			uk: 'Джон'
		},
		additionalName: {
			en: 'James',
			uk: 'Джеймс'
		},
		familyName: {
			en: 'Hopkins',
			uk: 'Гопкінс'
		},
		picture: {
			id: '00000001-0000-0000-0000-000000000001',
			createdOn: '2021-05-08',
			updatedOn: undefined,
			downloadUrl: 'https://apixstoragewesteu.blob.core.windows.net/temp/apixmed-john-hopkins.jpg',
			fileName: 'photo',
			lengthInBytes: 398,
			fileExtension: {
				id: 'jpg',
				displayText: undefined,
				description: undefined,
				fileBlobIconType: undefined
			},
			fileType: 'userAvatar'
		},
		populationId: 'european',
		genderId: 'male',
		birthday: '1980-08-08T00:00:00Z',
		bloodTypeId: 'a+',
		height: 180,
		weight: 80,
		email: 'john.j.hopkins@email.com',
		phoneNumber: '+1 222 333 4444',
		address: {
			en: '813 Howard Street, Oswego NY 13126, USA',
			uk: '813 Вул. Говарда, Освего, Штат Нью-Йорк 13126, США'
		},
		languageIds: ['en', 'uk'],
		preferredLanguageId: 'en',
		allergyIntolerances: [
			{
				allergyId: 'peanut-allergy',
				criticalityId: 'high',
				statusId: 'active',
				verificationStatusId: 'confirmed',
				lastOccurrence: '2021-06-30'
			},
			{
				allergyId: 'penicillin-allergy',
				criticalityId: 'low',
				statusId: 'active',
				verificationStatusId: 'unconfirmed',
				lastOccurrence: undefined
			}
		],
		seriousPathologyIds: [
			'bronchial-asthma',
			'osteoarthritis'
		],
		familyDiseaseIds: [
			'00000000-0000-0001-0001-100000000001',
			'00000000-0000-0001-0001-200000000001',
			'00000000-0000-0001-0001-200000000003',
			'00000000-0000-0001-0001-200000000004'
		],
		regularlyTakenMedicationIds: [
			'dexlansoprazole',
			'aspirin',
			'albuterol'
		],
		doctors: [
			{
				fullName: {
					en: 'Dr. Gregory House',
					uk: 'Доктор Грегорі Хаус'
				},
				contacts: {
					en: 'St. Mary\'s General Hospital, 350 Boulevard, Passaic, NJ 07055, United States; mobile: +1 222 333 4444',
					uk: 'Лікарня Св. Марії, Пассаїк буль. 350, Штат Нью-Йорк 07055, США; моб.: +1 222 333 4444'
				}
			}
		],
		emergencyContacts: [
			{
				fullName: {
					en: 'Mrs. Jane Hopkins',
					uk: 'Джейн Гопкінс'
				},
				data: '+1 222 777 4444'
			}
		],
		insurances: [
			{
				company: {
					en: 'American National Insurance Company',
					uk: 'Американська національна страхова компанія'
				},
				code: {
					en: 'NAIC 722511-56734',
					uk: 'NAIC 722511-56734'
				}
			}
		],
		anamnesis: {
			hasHealthIssuesWithLiver: false,
			hasHealthIssuesWithKidney: false,
			hasHealthIssuesWithHeart: true,
			hasHealthIssuesWithStomach: false,
			hasHealthIssuesWithNervousSystem: false,
			hasHealthIssuesWithImmuneSystem: false,
			hasHealthIssuesWithEndocrine: true,
			hasHealthIssuesWithCancer: false,
			hasHealthIssuesWithEye: false,
			hasHealthIssuesWithEar: false,
			hasHealthIssuesWithMuscles: false,
			hasHealthIssuesWithGeneticConditions: true
		},
		lifestyle: {
			isPregnant: false,
			isActiveLifestyle: true,
			isAlcohol: false,
			isOnHealthyDiet: true,
			isSmoking: false,
			isUnderHighLevelOfStress: false,
			isUsingNutritionalSupplements: false
		},
		isDeletionRequested: false,
		deletionRequestedOn: undefined
	},
	{
		id: '00000000-0000-0000-0002-000000000002',
		alias: 'henry.hopkins',
		userId: '00000000-0000-0000-0001-000000000001',
		createdOn: '2021-03-10T00:00:00Z',
		updatedOn: '2021-06-30T00:00:00Z',
		givenName: {
			en: 'Henry',
			uk: 'Генрі'
		},
		additionalName: {
			en: 'John',
			uk: 'Джон'
		},
		familyName: {
			en: 'Hopkins',
			uk: 'Гопкінс'
		},
		picture: undefined,
		populationId: 'european',
		genderId: 'male',
		birthday: '2001-04-12T00:00:00Z',
		bloodTypeId: 'a+',
		height: 186,
		weight: 83,
		email: 'hrhopkins@email.com',
		phoneNumber: undefined,
		address: undefined,
		languageIds: ['en'],
		preferredLanguageId: 'en',
		allergyIntolerances: [],
		seriousPathologyIds: [],
		familyDiseaseIds: [],
		regularlyTakenMedicationIds: [],
		doctors: [],
		emergencyContacts: [],
		insurances: [],
		anamnesis: {
			hasHealthIssuesWithLiver: false,
			hasHealthIssuesWithKidney: false,
			hasHealthIssuesWithHeart: false,
			hasHealthIssuesWithStomach: false,
			hasHealthIssuesWithNervousSystem: false,
			hasHealthIssuesWithImmuneSystem: false,
			hasHealthIssuesWithEndocrine: true,
			hasHealthIssuesWithCancer: false,
			hasHealthIssuesWithEye: false,
			hasHealthIssuesWithEar: false,
			hasHealthIssuesWithMuscles: false,
			hasHealthIssuesWithGeneticConditions: false
		},
		lifestyle: {
			isPregnant: false,
			isActiveLifestyle: true,
			isAlcohol: false,
			isOnHealthyDiet: false,
			isSmoking: false,
			isUnderHighLevelOfStress: false,
			isUsingNutritionalSupplements: false
		},
		isDeletionRequested: false,
		deletionRequestedOn: undefined
	},
	{
		id: '00000000-0000-0000-0002-000000000003',
		alias: 'james.hopkins',
		userId: '00000000-0000-0000-0001-000000000001',
		createdOn: '2021-03-10T00:00:00Z',
		updatedOn: '2021-06-30T00:00:00Z',
		givenName: {
			en: 'James',
			uk: 'Джеймс'
		},
		additionalName: {
			en: 'Archibald',
			uk: 'Арчібальд'
		},
		familyName: {
			en: 'Hopkins',
			uk: 'Гопкінс'
		},
		picture: undefined,
		populationId: 'european',
		genderId: 'male',
		birthday: '1938-10-23T00:00:00Z',
		bloodTypeId: 'b-',
		height: 172,
		weight: 89,
		email: 'jahopkins@email.com',
		phoneNumber: undefined,
		address: undefined,
		languageIds: ['en'],
		preferredLanguageId: 'en',
		allergyIntolerances: [],
		seriousPathologyIds: [],
		familyDiseaseIds: [],
		regularlyTakenMedicationIds: [],
		doctors: [],
		emergencyContacts: [],
		insurances: [],
		anamnesis: {
			hasHealthIssuesWithLiver: true,
			hasHealthIssuesWithKidney: false,
			hasHealthIssuesWithHeart: true,
			hasHealthIssuesWithStomach: true,
			hasHealthIssuesWithNervousSystem: false,
			hasHealthIssuesWithImmuneSystem: false,
			hasHealthIssuesWithEndocrine: true,
			hasHealthIssuesWithCancer: false,
			hasHealthIssuesWithEye: false,
			hasHealthIssuesWithEar: false,
			hasHealthIssuesWithMuscles: false,
			hasHealthIssuesWithGeneticConditions: false
		},
		lifestyle: {
			isPregnant: false,
			isActiveLifestyle: false,
			isAlcohol: true,
			isOnHealthyDiet: false,
			isSmoking: true,
			isUnderHighLevelOfStress: false,
			isUsingNutritionalSupplements: true
		},
		isDeletionRequested: true,
		deletionRequestedOn: '2022-07-01'
	}
];
