import { Observable } from 'rxjs';
import { Result } from '../../../../../core/shared';
import { EmptyCommandResult, EmptyResultOfEmptyCommand } from '../../../../../modules/core/models/commands/command-result';
import { FailureModel } from '../../../shared';
import { LoginViaFacebookCommand } from '../models/login-via-facebook.command';
import { LoginViaGoogleCommand } from '../models/login-via-google.command';
import { LoginCommand } from '../models/login.command';

export abstract class LoginCommandHandlerService {

	public abstract handleLogin(command: LoginCommand): Observable<Result<EmptyCommandResult<LoginCommand>, FailureModel>>;

	public abstract handleLoginViaGoogle(command: LoginViaGoogleCommand): Observable<Result<EmptyCommandResult<LoginViaGoogleCommand>, FailureModel>>;

	public abstract handleLoginViaFacebook(command: LoginViaFacebookCommand): Observable<Result<EmptyCommandResult<LoginViaFacebookCommand>, FailureModel>>;

	public abstract refreshToken(): Observable<Result<EmptyResultOfEmptyCommand, FailureModel>>;

}
