export class TreatmentCheckerReportDeleteBulkCommand {

	public readonly ids: Array<string>;

	constructor(
		ids: Array<string>
	) {
		// TODO: Guard check on defined
		this.ids = ids;
	}

}
