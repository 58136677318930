import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { ComboboxModel } from '@nmn-domain/shared';

export class VaccineFilterModel {

	public readonly ids?: Array<string>;
	public readonly ignoreIds?: Array<string>;
	public readonly searchPattern?: string;
	public readonly fromDiseaseIds?: Array<string>;
	public readonly ignoreFromDiseaseIds?: Array<string>;

	constructor(
		ids: Array<string> | undefined,
		ignoreIds: Array<string> | undefined,
		searchPattern: string | undefined,
		fromDiseaseIds: Array<string> | undefined,
		ignoreFromDiseaseIds: Array<string> | undefined
	) {
		this.ids = ids;
		this.ignoreIds = ignoreIds;
		this.searchPattern = searchPattern;
		this.fromDiseaseIds = fromDiseaseIds;
		this.ignoreFromDiseaseIds = ignoreFromDiseaseIds;
	}

	public static createForVaccineAutocomplete(
		searchPattern: string,
		selectedItem: ComboboxModel,
		fromDiseaseIds?: Array<string>
	): VaccineFilterModel {
		return new VaccineFilterModel(
			undefined,
			isStringDefinedAndNotEmpty(selectedItem?.id) ? [selectedItem.id] : undefined,
			searchPattern,
			fromDiseaseIds,
			undefined
		);
	}

}
