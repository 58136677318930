import { TranslocoService } from '@ngneat/transloco';
import { AllergyIntoleranceStatusFilterDto, AllergyIntoleranceStatusFindParameterDto } from '@nmn-communication/allergy-intolerance-statuses';
import { DescribedComboboxDto } from '@nmn-communication/shared';
import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../databases/fake.database';
import { getPagedCollectionWithoutDtoFilter } from '../../databases/fake.utils';
import { FakeDescribedComboboxDto, mapFakeDescriptedComboboxDtoToDesriptedComboboxDto } from '../../models/comboboxes/fake-described-combobox.dto';
import { FakeLocalizableEntity } from '../../models/fake-localizable-entity';
import { setTranslation } from '../../utils/localize';

export class AllergyIntoleranceStatusFakeTable {

	private readonly data: Array<AllergyIntoleranceStatusFakeRecord>;

	constructor(
		_: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.data = [...initialData];
	}

	public getAsCombobox(
		parameter: AllergyIntoleranceStatusFindParameterDto
	): DescribedComboboxDto<string> {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record was not found');
		}

		return record;
	}

	public findAsCombobox(
		parameter: AllergyIntoleranceStatusFindParameterDto
	): DescribedComboboxDto<string> {
		const record = this.data
			.find((item: AllergyIntoleranceStatusFakeRecord) => findPredicate(item, parameter));

		const comboboxRecord = mapFromRecordToFakeDescribedComboboxDto(record!);
		return mapFakeDescriptedComboboxDtoToDesriptedComboboxDto(
			this.translocoService,
			comboboxRecord!);
	}

	public getComboboxesPagedCollection(
		filter: AllergyIntoleranceStatusFilterDto
	): Array<DescribedComboboxDto<string>> {
		const pagedCollection = getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			filterPredicateForRecord,
			mapFromRecordToFakeDescribedComboboxDto,
			compareFn
		).items;

		return pagedCollection.map((item) => mapFakeDescriptedComboboxDtoToDesriptedComboboxDto(this.translocoService, item));
	}

}

const mapFromRecordToFakeDescribedComboboxDto = (
	record: AllergyIntoleranceStatusFakeRecord
): FakeDescribedComboboxDto<string> => (
	isValueDefined(record) ?
		{
			id: record!.id,
			displayText: record!.name,
			description: record!.description
		} :
		undefined!
);

const findPredicate = (
	item: AllergyIntoleranceStatusFakeRecord,
	findParameter: AllergyIntoleranceStatusFindParameterDto
): boolean =>
	item.id === findParameter.id;

const filterPredicateForRecord = (
	item: AllergyIntoleranceStatusFakeRecord,
	filter: AllergyIntoleranceStatusFilterDto,
	translocoService: TranslocoService
): boolean => {
	let result = true;

	if (result && isArrayDefinedAndNotEmpty(filter.ids)) {
		result = result && filter.ids!.some(id => item.id === id);
	}

	if (result && isArrayDefinedAndNotEmpty(filter.ignoreIds)) {
		result = result && filter.ignoreIds!.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result &&
			setTranslation(translocoService, item.name)
				.toLowerCase()
				.indexOf(filter.searchPattern!.toLowerCase()) >= 0;
	}

	return result;
};

// eslint-disable
// eslint-disable-next-line complexity
const compareFn = (
	item1: FakeDescribedComboboxDto<string>,
	item2: FakeDescribedComboboxDto<string>,
	sorting: string
): number => {
	if (sorting === 'displayText asc') {
		return item1.displayText > item2.displayText ? 1 : item1.displayText < item2.displayText ? -1 : 0;
	} else if (sorting === 'displayText desc') {
		return item1.displayText < item2.displayText ? 1 : item1.displayText > item2.displayText ? -1 : 0;
	}

	return 0;
};
// eslint-enable

interface AllergyIntoleranceStatusFakeRecord {
	id: string;
	name: FakeLocalizableEntity;
	description: FakeLocalizableEntity;
	order: number;
}

const initialData: Array<AllergyIntoleranceStatusFakeRecord> = [
	{
		id: 'active',
		name: {
			en: 'Active',
			uk: 'Активний'
		},
		description: {
			en: 'The subject is currently experiencing, or is at risk of, a reaction to the identified substance.',
			uk: 'Суб\'єкт зараз відчуває або ризикує реакцією на ідентифіковану речовину.'
		},
		order: 1
	},
	{
		id: 'inactive',
		name: {
			en: 'Inactive',
			uk: 'Неактивний'
		},
		description: {
			en: 'The subject is no longer at risk of a reaction to the identified substance.',
			uk: 'У суб’єкта більше немає ризику реакції на ідентифіковану речовину.'
		},
		order: 2
	},
	{
		id: 'resolved',
		name: {
			en: 'Resolved',
			uk: 'Вирішено'
		},
		description: {
			en: 'A reaction to the identified substance has been clinically reassessed by testing or re-exposure and is considered no longer to be present. Re-exposure could be accidental, unplanned, or outside of any clinical setting.',
			uk: 'Реакція на ідентифіковану речовину була клінічно оцінена шляхом тестування або повторного впливу, і вважається, що вона більше не існує. Повторне опромінення може бути випадковим, незапланованим або поза межами клінічних умов.'
		},
		order: 3
	}
];
