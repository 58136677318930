import { TranslocoService } from '@ngneat/transloco';
import { PopulationComboboxDto, PopulationFilterDto } from '@nmn-communication/populations';
import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../databases/fake.database';
import { getPagedCollectionWithoutDtoFilter } from '../../databases/fake.utils';
import { FakeComboboxDto } from '../../models/comboboxes/fake-combox.dto';
import { mapFakeDescriptedComboboxDtoToDesriptedComboboxDto } from '../../models/comboboxes/fake-described-combobox.dto';
import { FakeLocalizableEntity } from '../../models/fake-localizable-entity';
import { setTranslation } from '../../utils/localize';

export class PopulationFakeTable {

	private readonly data: Array<PopulationFakeRecord>;

	constructor(
		_: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.data = [...initialData];
	}

	public getAsCombobox(parameter: { id: string }): PopulationComboboxDto {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record was not found');
		}

		return record;
	}

	public findAsCombobox(parameter: { id: string }): PopulationComboboxDto {
		const record = this.data
			.find((item: PopulationFakeRecord) => findPredicate(item, parameter));

		const comboboxRecord = mapFromRecordToFakeDescribedComboboxDto(record!);
		return mapFakeDescriptedComboboxDtoToDesriptedComboboxDto(this.translocoService, comboboxRecord!);
	}

	public getComboboxesPagedCollection(filter: PopulationFilterDto): Array<PopulationComboboxDto> {
		const pagedCollection = getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			filterPredicateForRecord,
			mapFromRecordToFakeDescribedComboboxDto,
			compareFn
		).items;

		return pagedCollection.map((item) => mapFakeDescriptedComboboxDtoToDesriptedComboboxDto(this.translocoService, item));
	}

}

const mapFromRecordToFakeDescribedComboboxDto = (record: PopulationFakeRecord): FakeComboboxDto<string> => (
	isValueDefined(record) ? { id: record!.id, displayText: record!.name } : undefined!
);

const findPredicate = (item: PopulationFakeRecord, findParameter: { id: string }): boolean =>
	item.id === findParameter.id;

const filterPredicateForRecord = (
	item: PopulationFakeRecord,
	filter: PopulationFilterDto,
	translocoService: TranslocoService
): boolean => {
	let result = true;

	if (result && isArrayDefinedAndNotEmpty(filter.ids)) {
		result = result && filter.ids!.some(id => item.id === id);
	}

	if (result && isArrayDefinedAndNotEmpty(filter.ignoreIds)) {
		result = result && filter.ignoreIds!.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result &&
			setTranslation(translocoService, item.name)
				.toLowerCase()
				.indexOf(filter.searchPattern!.toLowerCase()) >= 0;
	}

	return result;
};

// eslint-disable
// eslint-disable-next-line complexity
const compareFn = (item1: FakeComboboxDto<string>, item2: FakeComboboxDto<string>, sorting: string): number => {
	if (sorting === 'displayText asc') {
		return item1.displayText > item2.displayText ? 1 : item1.displayText < item2.displayText ? -1 : 0;
	} else if (sorting === 'displayText desc') {
		return item1.displayText < item2.displayText ? 1 : item1.displayText > item2.displayText ? -1 : 0;
	}

	return 0;
};
// eslint-enable

interface PopulationFakeRecord {
	id: string;
	name: FakeLocalizableEntity;
}

const initialData: Array<PopulationFakeRecord> = [
	{
		id: 'european',
		name: {
			en: 'European',
			uk: 'Європейська'
		}
	},
	{
		id: 'east-asian',
		name: {
			en: 'East Asian',
			uk: 'East Asian'
		}
	},
	{
		id: 'central-south-asian',
		name: {
			en: 'Central South Asian',
			uk: 'Central South Asian'
		}
	},
	{
		id: 'african-american-afro-caribbean',
		name: {
			en: 'African American/Afro-Caribbean',
			uk: 'African American/Afro-Caribbean'
		}
	}
];
