import { TranslocoService } from '@ngneat/transloco';
import { PagedCollectionDto, PageOptionsDto, PersonalizedMedicinePubmedCitationDto, PubmedCitationDto } from '@nmn-communication/shared';
import {
	PatientTreatmentCheckerAnalyzeParameterDto, TreatmentCheckerAnalyzeParameterDto, TreatmentCheckerDiseaseTreatmentGuidelineDto, TreatmentCheckerPersonalizedMedicineGuidelineDto,
	TreatmentCheckerPersonalizedMedicineRecommendationDto, TreatmentCheckerReportCautionDto, TreatmentCheckerReportDto, TreatmentCheckerReportFilterDto,
	TreatmentCheckerReportFindParameterDto, TreatmentCheckerReportMedicationPgxLevelTestingDto, TreatmentCheckerReportMedicationPropertiesDto, TreatmentCheckerReportShortDto,
	TreatmentCheckerReportTreatmentInfoAnamnesisDto, TreatmentCheckerReportTreatmentInfoLifestyleDto
} from '@nmn-communication/treatment-checker';
import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import {
	TreatmentCheckerMedicationReportSideEffectFrequencyType, TreatmentCheckerReportCautionSeverity, TreatmentCheckerReportCautionType,
	TreatmentCheckerReportDiseaseReportInfectiousType, TreatmentCheckerReportInteractionCriticalityType, TreatmentCheckerReportInteractionType,
	TreatmentCheckerReportMedicationPgxLevelTestingAnnotation, TreatmentCheckerReportMedicationPgxLevelTestingOrganization, TreatmentCheckerReportMedicationPgxLevelTestingType,
	TreatmentCheckerReportMedicationPropertyDrugType, TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType
} from '@nmn-domain/treatment-checker';
import { FakeDatabase } from '../databases/fake.database';
import { getPagedCollectionWithoutItemFilter } from '../databases/fake.utils';
import { FakeComboboxDto, mapFakeComboboxDtoToComboboxDto } from '../models/comboboxes/fake-combox.dto';
import { FakeLocalizableEntity } from '../models/fake-localizable-entity';
import { FakeTreatmentCheckerReportMedicationSideEffectDto, mapFakeTreatmentCheckerReportMedicationSideEffectDto } from '../models/fake-treatment-checker-report-medication-side-effect.dto';
import { setTranslation } from '../utils/localize';

export class TreatmentCheckerReportFakeTable {

	private readonly database: FakeDatabase;
	private readonly data: Array<TreatmentCheckerReportFakeRecord>;

	constructor(
		database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.database = database;
		this.data = [...initialData];
	}

	public getPagedCollection(
		pageOptions: PageOptionsDto<TreatmentCheckerReportFilterDto>
	): PagedCollectionDto<TreatmentCheckerReportShortDto, TreatmentCheckerReportFilterDto> {
		return getPagedCollectionWithoutItemFilter(
			this.translocoService,
			this.data,
			pageOptions,
			this.mapFromRecordToShortDto.bind(this),
			filterPredicateForShortDto,
			compareFn);
	}

	public get(parameter: TreatmentCheckerReportFindParameterDto, userId: string | undefined): TreatmentCheckerReportDto {
		const record = this.data.find((item: TreatmentCheckerReportFakeRecord) => findPredicate(item, parameter));

		if (!isValueDefined(record)) {
			throw new Error('Record was not found.');
		}

		const patientId = isStringDefinedAndNotEmpty(userId) ? this.database.usersTable.getCurrent(userId).user.activePatientId : undefined;

		return this.mapFromRecordToDto(record!, patientId);
	}

	public createForPatient(_: PatientTreatmentCheckerAnalyzeParameterDto): string {
		// TODO: implement this
		const record = this.data[0];

		return record.id;
	}

	public createForAnonymous(_: TreatmentCheckerAnalyzeParameterDto): string {
		// TODO: implement this
		const record = this.data[0];

		return record.id;
	}

	public delete(parameter: TreatmentCheckerReportFindParameterDto): void {
		const index = this.data
			.findIndex((item: TreatmentCheckerReportFakeRecord) => findPredicate(item, parameter));

		if (index >= 0) {
			this.data.splice(index, 1);
		}
	}

	private mapFromRecordToDto(record: TreatmentCheckerReportFakeRecord, patientIdForGeneticTests: string | undefined): TreatmentCheckerReportDto {
		const medicationReports = record.medications
			.map(medicationReport => {
				return {
					medication: this.database.medicationsTable.getAsCombobox({ id: medicationReport.medicationId }),
					medicationProperties: medicationReport.medicationProperties,
					knownTradeNames: medicationReport.knownTradeNames.map(item => mapFakeComboboxDtoToComboboxDto(this.translocoService, item)),
					synonyms: medicationReport.synonyms.map(item => mapFakeComboboxDtoToComboboxDto(this.translocoService, item)),
					sideEffects: medicationReport.sideEffects.map(item => mapFakeTreatmentCheckerReportMedicationSideEffectDto(this.translocoService, item)),
					personalizedMedicineRecommendations: this.getPersonalizedMedicineRecommendations(medicationReport.medicationId, patientIdForGeneticTests),
					pgxLevelTestings: medicationReport.pgxLevelTestings.map(item => mapPgxLevelTesting(this.translocoService, item)),
					personalizedMedicineGuidelines: medicationReport.personalizedMedicineGuidelines.map(item => mapPersonalizedMedicineGuideline(this.translocoService, item)),
					pubmedCitations: {
						personalizedMedicineCitations: medicationReport.pubmedCitations.personalizedMedicineCitations.map(item => this.mapPersonalizedMedicineCitation(item)),
						otherCitations: medicationReport.pubmedCitations.otherCitations.map(item => this.mapCitation(item))
					}
				};
			})
			.filter(isValueDefined);
		const personalizedMedicineRecommendationCautions: Array<TreatmentCheckerReportCautionDto> = medicationReports
			.filter(medicationReport => isArrayDefinedAndNotEmpty(medicationReport.personalizedMedicineRecommendations))
			.flatMap(medicationReport => medicationReport.personalizedMedicineRecommendations)
			.map(personalizedMedicineRecommendation => ({
				type: TreatmentCheckerReportCautionType.PersonalizedMedicineIsAvailableByGeneticTest,
				severity: TreatmentCheckerReportCautionSeverity.SuperInfo,
				parameters: [
					personalizedMedicineRecommendation.medication.displayText,
					personalizedMedicineRecommendation.gene.displayText,
					personalizedMedicineRecommendation.pharmacogenomicTestResult.displayText
				]
			}));

		return {
			id: record.id,
			createdOn: record.createdOn,
			treatmentInfo: {
				diseases: record.treatmentInfo.diseaseIds.map(diseaseId => this.database.diseasesTable.getAsCombobox({ id: diseaseId })),
				medications: record.treatmentInfo.medicationIds.map(medicationId => this.database.medicationsTable.getAsCombobox({ id: medicationId })),
				gender: this.database.gendersTable.getAsCombobox({ id: record.treatmentInfo.genderId }),
				population: isStringDefinedAndNotEmpty(record.treatmentInfo.populationId) ? this.database.populationTable.findAsCombobox({ id: record.treatmentInfo.populationId }) : undefined,
				age: record.treatmentInfo.age,
				height: record.treatmentInfo.height,
				weight: record.treatmentInfo.weight,
				anamnesis: record.treatmentInfo.anamnesis,
				lifestyle: record.treatmentInfo.lifestyle
			},
			cautions: personalizedMedicineRecommendationCautions.concat(record.cautions),
			diseases: record.diseases
				.map(diseaseReport => {
					return {
						disease: this.database.diseasesTable.getAsCombobox({ id: diseaseReport.diseaseId }),
						infectiousType: diseaseReport.infectiousType,
						isHeritable: diseaseReport.isHeritable,
						heritabilityRate: diseaseReport.heritabilityRate,
						frequency: diseaseReport.frequency,
						isGeneticTestingAvailable: diseaseReport.isGeneticTestingAvailable,
						comorbidities: diseaseReport.comorbidities.map(item => mapFakeComboboxDtoToComboboxDto(this.translocoService, item)),
						synonyms: diseaseReport.synonyms.map(item => mapFakeComboboxDtoToComboboxDto(this.translocoService, item)),
						affectedOrgans: diseaseReport.affectedOrgans.map(item => mapFakeComboboxDtoToComboboxDto(this.translocoService, item)),
						personalizedMedicineGuidelines: diseaseReport.personalizedMedicineGuidelines.map(item => mapPersonalizedMedicineGuideline(this.translocoService, item)),
						diseaseTreatmentGuidelines: diseaseReport.diseaseTreatmentGuidelines.map(item => mapDiseaseTreatmentGuideline(this.translocoService, item)),
					};
				})
				.filter(isValueDefined),
			interactions: record.interactions
				.map(interactionReport => {
					return {
						type: interactionReport.type,
						criticality: interactionReport.criticality,
						description: setTranslation(this.translocoService, interactionReport.description),
						entity1: interactionReport.entity1Type === 'medication' ?
							this.database.medicationsTable.getAsCombobox({ id: interactionReport.entity1Id }) :
							interactionReport.entity1Type === 'food' ?
								this.database.foodTable.getAsCombobox({ id: interactionReport.entity1Id }) :
								undefined,
						entity2: interactionReport.entity2Type === 'medication' ?
							this.database.medicationsTable.getAsCombobox({ id: interactionReport.entity2Id }) :
							interactionReport.entity2Type === 'food' ?
								this.database.foodTable.getAsCombobox({ id: interactionReport.entity2Id }) :
								undefined
					};
				})
				.filter(isValueDefined),
			medications: medicationReports
		};
	}

	private getPersonalizedMedicineRecommendations(medicationId: string, patientId: string | undefined): Array<TreatmentCheckerPersonalizedMedicineRecommendationDto> {
		if (!isStringDefinedAndNotEmpty(patientId)) {
			return [];
		}

		const patientGeneticTests = this.database.geneticTestsTable.getGeneticCard({ patientId })?.geneticTests ?? [];

		return personalizedMedicineRecommendations
			.filter(personalizedMedicineRecommendation => personalizedMedicineRecommendation.medicationId === medicationId)
			.map(personalizedMedicineRecommendation => {
				const geneticTest = patientGeneticTests.find(geneticTest =>
					geneticTest.gene.id === personalizedMedicineRecommendation.geneId &&
					geneticTest.geneticTestResults.find(geneticTestResult =>
						(isValueDefined(geneticTestResult.diplotype) ? geneticTestResult.diplotype : geneticTestResult.haplotype)
							?.pharmacogenomicTestResult?.id === personalizedMedicineRecommendation.pharmacogenomicTestResultId));
				const geneticTestResult = geneticTest.geneticTestResults.find(geneticTestResult =>
					(isValueDefined(geneticTestResult.diplotype) ? geneticTestResult.diplotype : geneticTestResult.haplotype)
						?.pharmacogenomicTestResult?.id === personalizedMedicineRecommendation.pharmacogenomicTestResultId);

				return isValueDefined(geneticTest) ?
					{
						medication: this.database.medicationsTable.getAsCombobox({ id: personalizedMedicineRecommendation.medicationId }),
						gene: this.database.genesTable.getAsCombobox({ id: personalizedMedicineRecommendation.geneId }),
						source: {
							link: personalizedMedicineRecommendation.source.link,
							displayText: setTranslation(this.translocoService, personalizedMedicineRecommendation.source.displayText)
						},
						geneticTestId: geneticTest.id,
						pharmacogenomicTestResult: (isValueDefined(geneticTestResult.diplotype) ? geneticTestResult.diplotype : geneticTestResult.haplotype).pharmacogenomicTestResult,
						implication: setTranslation(this.translocoService, personalizedMedicineRecommendation.implication),
						drugRecommendation: setTranslation(this.translocoService, personalizedMedicineRecommendation.drugRecommendation),
						comment: setTranslation(this.translocoService, personalizedMedicineRecommendation.comment),
						population: personalizedMedicineRecommendation.population,
						classification: personalizedMedicineRecommendation.classification,
						activityScore: personalizedMedicineRecommendation.activityScore
					} :
					undefined;
			})
			.filter(isValueDefined);
	}

	private mapFromRecordToShortDto(record: TreatmentCheckerReportFakeRecord): TreatmentCheckerReportShortDto {
		return {
			id: record.id,
			createdOn: record.createdOn,
			cautions: record.cautions,
			treatmentInfo: {
				diseases: record.treatmentInfo.diseaseIds.map(diseaseId => this.database.diseasesTable.getAsCombobox({ id: diseaseId })),
				medications: record.treatmentInfo.medicationIds.map(medicationId => this.database.medicationsTable.getAsCombobox({ id: medicationId })),
				gender: this.database.gendersTable.getAsCombobox({ id: record.treatmentInfo.genderId }),
				age: record.treatmentInfo.age,
				height: record.treatmentInfo.height,
				weight: record.treatmentInfo.weight,
				anamnesis: record.treatmentInfo.anamnesis,
				lifestyle: record.treatmentInfo.lifestyle
			}
		};
	}

	private mapPersonalizedMedicineCitation(record: any): PersonalizedMedicinePubmedCitationDto {
		return {
			pubmedId: record.pubmedId,
			gene: this.database.genesTable.getAsCombobox({ id: record.geneId }),
			source: { link: record.source?.link, displayText: record.source?.displayText },
			authors: record.authors,
			journal: record.journal,
			year: record.year,
		};
	}

	private mapCitation(record: any): PubmedCitationDto {
		return {
			pubmedId: record.pubmedId,
			source: { link: record.source?.link, displayText: record.source?.displayText },
			authors: record.authors,
			journal: record.journal,
			year: record.year,
		};
	}

}

const mapPersonalizedMedicineGuideline = (
	translocoService: TranslocoService,
	fakeRecord: any
): TreatmentCheckerPersonalizedMedicineGuidelineDto => {
	return {
		id: fakeRecord.id,
		displayText: setTranslation(translocoService, fakeRecord.displayText),
		sources: fakeRecord?.sources.map(item => {
			return {
				displayText: setTranslation(translocoService, item.displayText),
				link: item.link
			};
		})
	};
};

const mapPgxLevelTesting = (translocoService: TranslocoService, fakeRecord: any): TreatmentCheckerReportMedicationPgxLevelTestingDto => {
	return {
		id: fakeRecord.id,
		type: fakeRecord.type,
		organization: fakeRecord.organization,
		source: {
			displayText: setTranslation(translocoService, fakeRecord.source.displayText),
			link: fakeRecord.source.link
		},
		annotations: fakeRecord.annotations,
		affectedSubgroups: fakeRecord.affectedSubgroups
	};
};

const mapDiseaseTreatmentGuideline = (translocoService: TranslocoService, fakeRecord: any): TreatmentCheckerDiseaseTreatmentGuidelineDto => {
	return {
		id: fakeRecord.id,
		displayCode: fakeRecord.displayCode,
		source: {
			displayText: isValueDefined(fakeRecord.source.displayText) ? setTranslation(translocoService, fakeRecord.source.displayText) : undefined,
			link: fakeRecord.source.link
		},
		target: mapFakeComboboxDtoToComboboxDto(translocoService, fakeRecord.target)
	};
};

const findPredicate = (item: TreatmentCheckerReportFakeRecord, findParameter: TreatmentCheckerReportFindParameterDto): boolean =>
	item.id === findParameter.id;

const filterPredicateForShortDto = (_: TreatmentCheckerReportShortDto, __: TreatmentCheckerReportFilterDto): boolean => true;

const compareFn = (item1: TreatmentCheckerReportShortDto, item2: TreatmentCheckerReportShortDto, _: string): number => {
	// eslint-disable-next-line
	return item1.createdOn < item2.createdOn ? 1 : item1.createdOn > item2.createdOn ? -1 : 0;
};

interface PersonalizedMedicineRecommendationFakeRecord {
	medicationId: string;
	geneId: string;
	pharmacogenomicTestResultId: string;
	source: {
		link: string;
		displayText: FakeLocalizableEntity;
	};
	implication?: FakeLocalizableEntity;
	drugRecommendation: FakeLocalizableEntity;
	comment?: FakeLocalizableEntity;
	population?: string;
	classification?: string;
	activityScore?: number;
}

interface TreatmentCheckerReportFakeRecord {
	id: string;
	createdOn: string;
	treatmentInfo: {
		age: number;
		populationId: string;
		genderId: string;
		height: number;
		weight: number;
		diseaseIds: Array<string>;
		medicationIds: Array<string>;
		lifestyle: TreatmentCheckerReportTreatmentInfoLifestyleDto;
		anamnesis: TreatmentCheckerReportTreatmentInfoAnamnesisDto
	};
	cautions: Array<TreatmentCheckerReportCautionDto>;
	diseases: Array<{
		diseaseId: string;
		infectiousType: string;
		isHeritable?: boolean;
		heritabilityRate?: number;
		frequency?: number;
		isGeneticTestingAvailable: boolean;
		comorbidities: Array<FakeComboboxDto<string>>;
		synonyms: Array<FakeComboboxDto<string>>;
		affectedOrgans: Array<FakeComboboxDto<string>>;
		personalizedMedicineGuidelines: Array<{
			sources: Array<{
				link: string;
				displayText: FakeLocalizableEntity;
			}>
		}>;
		diseaseTreatmentGuidelines: Array<{
			id: string;
			displayCode: string;
			target: FakeComboboxDto<string>;
			source: {
				link: string;
				displayText?: FakeLocalizableEntity;
			};

		}>
	}>;
	interactions: Array<{
		type: string;
		criticality: string;
		description?: FakeLocalizableEntity;
		entity1Id: string;
		entity1Type: string;
		entity2Id: string;
		entity2Type: string;
	}>;
	medications: Array<{
		medicationId: string;
		medicationProperties: TreatmentCheckerReportMedicationPropertiesDto;
		knownTradeNames: Array<FakeComboboxDto<string>>;
		synonyms: Array<FakeComboboxDto<string>>;
		sideEffects: Array<FakeTreatmentCheckerReportMedicationSideEffectDto>;
		pgxLevelTestings: Array<{
			id: string;
			type: string;
			organization: string;
			source: { link: string; displayText: FakeLocalizableEntity; };
			annotations: Array<string>;
			affectedSubgroups: Array<{
				geneDisplayText: string;
				name: string;
				description: string;
			}>;
		}>;
		personalizedMedicineGuidelines: Array<{
			sources: Array<{
				link: string;
				displayText: FakeLocalizableEntity;
			}>
		}>;
		pubmedCitations: {
			personalizedMedicineCitations: Array<{
				pubmedId: string;
				geneId: string,
				source: {
					link: string;
					displayText: string;
				};
				authors: Array<string>;
				journal: string;
				year: number;
			}>,
			otherCitations: Array<{
				pubmedId: string;
				source: {
					link: string;
					displayText: string;
				};
				authors: Array<string>;
				journal: string;
				year: number;
			}>
		};
	}>;
}

const astmaPersonalizedMedicineGuidelines = [
	{
		id: 'GINA Clinical guideline',
		displayText: {
			en: 'GINA Clinical guideline',
			uk: 'Клінічна настанова GINA'
		},
		sources: [
			{
				displayText: {
					en: 'GINA',
					uk: 'GINA'
				},
				link: 'https://ginasthma.org/pocket-guide-for-asthma-management-and-prevention'
			}
		]
	}
];

const astmaDiseaseTreatmentGuidelines = [
	{
		id: 'CDC Clinical guideline for Astma',
		displayCode: 'CDC',
		target: {
			id: 'bronchial-asthma',
			displayText: {
				en: 'Bronchial asthma',
				uk: 'Бронхіальна астма'
			}
		},
		source: {
			displayText: {
				en: 'CDC.GOV',
				uk: 'CDC.GOV'
			},
			link: 'https://www.cdc.gov/asthma'
		}
	}
];

const astmaFakeDiseaseTreatmentGuidelines = [
	{
		id: 'CDC Clinical guideline for Astma',
		displayCode: 'CDC',
		target: {
			id: 'bronchial-asthma',
			displayText: {
				en: 'Bronchial asthma',
				uk: 'Бронхіальна астма'
			}
		},
		source: {
			displayText: {
				en: 'CDC.GOV',
				uk: 'CDC.GOV'
			},
			link: 'https://www.cdc.gov/asthma'
		}
	},
	{
		id: 'CDC Clinical guideline for Heartdisease',
		displayCode: 'CDC',
		target: {
			id: 'heartdisease',
			displayText: {
				en: 'Heart disease',
				uk: 'Хвороба серця'
			}
		},
		source: {
			displayText: undefined,
			link: 'https://www.cdc.gov/heartdisease'
		}
	}
];

const albuterolPgxLevelTestings = [
	{
		id: 'Annotation of FDA Label for abacavir and HLA-B',
		type: TreatmentCheckerReportMedicationPgxLevelTestingType.TestingRequired,
		organization: TreatmentCheckerReportMedicationPgxLevelTestingOrganization.Fda,
		source: {
			link: 'https://www.pharmgkb.org/labelAnnotation/PA166104833',
			displayText: {
				en: 'Annotation of FDA Label for abacavir and HLA-B',
				uk: 'Annotation of FDA Label for abacavir and HLA-B'
			}
		},
		annotations: [
			TreatmentCheckerReportMedicationPgxLevelTestingAnnotation.AlternateDrug,
			TreatmentCheckerReportMedicationPgxLevelTestingAnnotation.PrescribingInfo,
			TreatmentCheckerReportMedicationPgxLevelTestingAnnotation.FdaBiomarker
		],
		affectedSubgroups: []
	},
	{
		id: 'Annotation of EMA Label for abacavir and HLA-B',
		type: TreatmentCheckerReportMedicationPgxLevelTestingType.TestingRequired,
		organization: TreatmentCheckerReportMedicationPgxLevelTestingOrganization.Ema,
		source: {
			link: 'https://www.pharmgkb.org/labelAnnotation/PA166104896',
			displayText: {
				en: 'Annotation of EMA Label for abacavir and HLA-B',
				uk: 'Annotation of EMA Label for abacavir and HLA-B'
			}
		},
		annotations: [
			TreatmentCheckerReportMedicationPgxLevelTestingAnnotation.AlternateDrug,
			TreatmentCheckerReportMedicationPgxLevelTestingAnnotation.PrescribingInfo
		],
		affectedSubgroups: [
			{
				geneDisplayText: 'HLA-B',
				name: '*57:01 allele positive',
				description: 'Results in higher adverse reaction risk (hypersensitivity reactions). Do not use abacavir in patients positive for HLA-B*57:01.'
			},
			{
				geneDisplayText: 'CYP2C19',
				name: 'poor metabolizers',
				description: 'Results in higher systemic concentrations and may result in higher adverse reaction risk. Dosage adjustment is recommended. Refer to FDA labeling for specific dosing recommendations.'
			}
		]
	},
	{
		id: 'Annotation of Swissmedic Label for abacavir and HLA-B',
		type: TreatmentCheckerReportMedicationPgxLevelTestingType.TestingRequired,
		organization: TreatmentCheckerReportMedicationPgxLevelTestingOrganization.Swissmedic,
		source: {
			link: 'https://www.pharmgkb.org/labelAnnotation/PA166183563',
			displayText: {
				en: 'Annotation of Swissmedic Label for abacavir and HLA-B',
				uk: 'Annotation of Swissmedic Label for abacavir and HLA-B'
			}
		},
		annotations: [
			TreatmentCheckerReportMedicationPgxLevelTestingAnnotation.AlternateDrug,
			TreatmentCheckerReportMedicationPgxLevelTestingAnnotation.PrescribingInfo
		],
		affectedSubgroups: []
	},
	{
		id: 'Annotation of FDA Label for acetaminophen',
		type: TreatmentCheckerReportMedicationPgxLevelTestingType.ActionablePgx,
		organization: TreatmentCheckerReportMedicationPgxLevelTestingOrganization.Fda,
		source: {
			link: 'https://www.pharmgkb.org/labelAnnotation/PA166246301',
			displayText: {
				en: 'Annotation of FDA Label for acetaminophen / caffeine / dihydrocodeine and CYP2D6',
				uk: 'Annotation of FDA Label for acetaminophen / caffeine / dihydrocodeine and CYP2D6'
			}
		},
		annotations: [
			TreatmentCheckerReportMedicationPgxLevelTestingAnnotation.AlternateDrug,
			TreatmentCheckerReportMedicationPgxLevelTestingAnnotation.PrescribingInfo,
			TreatmentCheckerReportMedicationPgxLevelTestingAnnotation.Pediatric
		],
		affectedSubgroups: []
	},
	{
		id: 'Annotation of HCSC Label for allopurinol and HLA-B',
		type: TreatmentCheckerReportMedicationPgxLevelTestingType.TestingRecommended,
		organization: TreatmentCheckerReportMedicationPgxLevelTestingOrganization.Hcsc,
		source: {
			link: 'https://www.pharmgkb.org/labelAnnotation/PA166286161',
			displayText: {
				en: 'Annotation of HCSC Label for allopurinol and HLA-B',
				uk: 'Annotation of HCSC Label for allopurinol and HLA-B'
			}
		},
		annotations: [
			TreatmentCheckerReportMedicationPgxLevelTestingAnnotation.PrescribingInfo
		],
		affectedSubgroups: []
	},
	{
		id: 'Annotation of PMDA Label for allopurinol and HLA-B',
		type: TreatmentCheckerReportMedicationPgxLevelTestingType.InformativePgx,
		organization: TreatmentCheckerReportMedicationPgxLevelTestingOrganization.Pmda,
		source: {
			link: 'https://www.pharmgkb.org/labelAnnotation/PA166160668',
			displayText: {
				en: 'Annotation of PMDA Label for allopurinol and HLA-B',
				uk: 'Annotation of PMDA Label for allopurinol and HLA-B'
			}
		},
		annotations: [],
		affectedSubgroups: []
	},
	{
		id: 'Undefined',
		type: TreatmentCheckerReportMedicationPgxLevelTestingType.Undefined,
		organization: TreatmentCheckerReportMedicationPgxLevelTestingOrganization.Undefined,
		source: {
			link: '',
			displayText: {
				en: 'Undefined',
				uk: 'Undefined'
			}
		},
		annotations: [],
		affectedSubgroups: []
	}
];

const albuterolPersonalizedMedicineGuidelines = [
	{
		id: 'Find more annotations',

		displayText: {
			en: 'Find more annotations at',
			uk: 'Дізнатись більше на сайті'
		},
		sources: [
			{
				displayText: {
					en: 'PharmGKB',
					uk: 'PharmGKB'
				},
				link: 'https://www.pharmgkb.org/chemical/PA448068/automatedAnnotation'
			}
		]
	}
];

const albuterolPubmedPersonalizedMedicineCitations = [
	{
		pubmedId: '25691813',
		geneId: 'CYP3A4',
		source: {
			link: 'https://pubmed.ncbi.nlm.nih.gov/25691813',
			displayText: 'Asthma pharmacogenetics and the development of genetic profiles for personalized medicine',
		},
		authors: [
			'Victor E Ortega',
			'Deborah A Meyers',
			'Eugene R Bleecker'
		],
		journal: 'Pharmacogenomics and Personalized Medicine',
		year: 2015
	},
	{
		pubmedId: '32986886',
		geneId: 'ADRB2',
		source: {
			link: 'https://pubmed.ncbi.nlm.nih.gov/32986886',
			displayText: 'A meta-analysis of the influence of ADRB2 genetic polymorphisms on albuterol (salbutamol) therapy in patients with asthma',
		},
		authors: [
			'Keiko Hikino',
			'Shinobu Kobayashi',
			'Erika Ota',
			'Taisei Mushiroda',
			'Kevin Y Urayama',
			'Tohru Kobayashi'
		],
		journal: 'British Journal of Clinical Pharmacology',
		year: 2020
	},
	{
		pubmedId: '22792082',
		geneId: 'SPATS2L',
		source: {
			link: 'https://pubmed.ncbi.nlm.nih.gov/22792082',
			displayText: 'Genome-wide association analysis in asthma subjects identifies SPATS2L as a novel bronchodilator response gene',
		},
		authors: [
			'Blanca E Himes', 'Xiaofeng Jiang', 'Ruoxi Hu', 'Ann C Wu', 'Jessica A Lasky-Su', 'Barbara J Klanderman', 'John Ziniti', 'Jody Senter-Sylvia', 'John J Lima', 'Charles G Irvin',
			'Stephen P Peters', 'Deborah A Meyers', 'Eugene R Bleecker', 'Michiaki Kubo', 'Mayumi Tamari', 'Yusuke Nakamura', 'Stanley J Szefler', 'Robert F Lemanske Jr', 'Robert S Zeiger',
			'Robert C Strunk', 'Fernando D Martinez', 'John P Hanrahan', 'Gerard H Koppelman', 'Dirkje S Postma', 'Maartje A E Nieuwenhuis', 'Judith M Vonk', 'Reynold A Panettieri Jr',
			'Amy Markezich', 'Elliot Israel', 'Vincent J Carey', 'Kelan G Tantisira', 'Augusto A Litonjua', 'Quan Lu', 'Scott T Weiss'
		],
		journal: 'PloS genetics',
		year: 2012
	}
];

const metoprololPubmedPersonalizedMedicineCitations = [
	{
		pubmedId: '21412232',
		geneId: 'CYP3A4',
		source: {
			link: 'https://pubmed.ncbi.nlm.nih.gov/21412232/',
			displayText: 'Pharmacogenetics: from bench to byte--an update of guidelines',
		},
		authors: [
			'Swen J J',
			'Nijenhuis M',
			'de Boer A',
			'Grandia L',
			'Maitland-van der Zee A H',
			'Mulder H',
			'Rongen G A P J M',
			'van Schaik R H N',
			'Schalekamp T',
			'Touw D J',
			'van der Weide J',
			'Wilffert B',
			'Deneer V H M',
			'Guchelaar H-J'
		],
		journal: 'Clinical pharmacology and therapeutics',
		year: 2011
	}
];


// TreatmentCheckerReportFakeRecord (initial data) has id mask 00000000-1111-0000-0000-************
const initialData: Array<TreatmentCheckerReportFakeRecord> = [
	{
		id: '00000000-1111-0000-0000-000000000001',
		createdOn: '2022-02-19T12:47:23',
		treatmentInfo: {
			age: 40,
			populationId: 'european',
			genderId: 'male',
			height: 182,
			weight: 82,
			diseaseIds: ['bronchial-asthma'],
			medicationIds: ['albuterol', 'metoprolol', 'serevent-diskus', 'advil', 'aspirin', 'actovegin'],
			anamnesis: {
				hasHealthIssuesWithStomach: true
			},
			lifestyle: {
				isSmoking: true,
				isActiveLifestyle: true
			}
		},
		cautions: [
			{
				type: TreatmentCheckerReportCautionType.PersonalizedMedicineIsAvailable,
				severity: TreatmentCheckerReportCautionSeverity.SuperInfo,
				parameters: ['Albuterol', 'Metoprolol', 'Serevent Diskus']
			},
			{
				type: TreatmentCheckerReportCautionType.DecreaseEffectiveness,
				severity: TreatmentCheckerReportCautionSeverity.Error,
				parameters: ['Advil', 'Aspirin']
			},
			{
				type: TreatmentCheckerReportCautionType.IsNotPartOfAnyDiseaseTreatment,
				severity: TreatmentCheckerReportCautionSeverity.Warning,
				parameters: ['Advil', 'Metoprolol']
			},
			{
				type: TreatmentCheckerReportCautionType.IsNotApprovedMedication,
				severity: TreatmentCheckerReportCautionSeverity.Warning,
				parameters: ['Actovegin']
			},
			{
				type: TreatmentCheckerReportCautionType.TreatmentGuidelinesAvailable,
				severity: TreatmentCheckerReportCautionSeverity.Info,
				parameters: ['Bronchial asthma']
			}
		],
		diseases: [
			{
				diseaseId: 'bronchial-asthma',
				infectiousType: TreatmentCheckerReportDiseaseReportInfectiousType.NonInfectious,
				isHeritable: true,
				heritabilityRate: 12,
				frequency: 8,
				isGeneticTestingAvailable: true,
				comorbidities: [
					{
						id: 'rhinitis',
						displayText: {
							en: 'rhinitis',
							uk: 'риніт'
						}
					},
					{
						id: 'sinusitis',
						displayText: {
							en: 'sinusitis',
							uk: 'синусит'
						}
					},
					{
						id: 'gastroesophageal reflux disease',
						displayText: {
							en: 'gastroesophageal reflux disease',
							uk: 'гастроезофагеальна рефлюксна хвороба'
						}
					},
					{
						id: 'obstructive sleep apnea',
						displayText: {
							en: 'obstructive sleep apnea',
							uk: 'обструктивне апное сну'
						}
					}
				],
				synonyms: [
					{
						id: 'asthma',
						displayText: {
							en: 'Asthma',
							uk: 'Астма'
						}
					},
					{
						id: 'asthma-attack',
						displayText: {
							en: 'Asthma attack',
							uk: 'Приступ астми'
						}
					},
					{
						id: 'respiratory-disorder',
						displayText: {
							en: 'Respiratory disorder',
							uk: 'Розлад дихання'
						}
					},
					{
						id: 'respiratory-illness',
						displayText: {
							en: 'Respiratory illness',
							uk: 'Захворювання органів дихання'
						}
					},
					{
						id: 'respiratory-disease',
						displayText: {
							en: 'Respiratory disease',
							uk: 'Захворювання органів дихання'
						}
					},
					{
						id: 'mucolytic',
						displayText: {
							en: 'Mucolytic',
							uk: 'Муколітична хвороба'
						}
					}
				],
				affectedOrgans: [],
				personalizedMedicineGuidelines: astmaPersonalizedMedicineGuidelines,
				diseaseTreatmentGuidelines: astmaDiseaseTreatmentGuidelines
			}
		],
		interactions: [
			{
				type: TreatmentCheckerReportInteractionType.MedicationWithMedication,
				criticality: TreatmentCheckerReportInteractionCriticalityType.High,
				description: {
					en: 'Consult your doctor before taking aspirin and Advil together. Taking aspirin to prevent heart attacks or strokes and taking advil frequently or regularly may reduce its effectiveness. In addition, combining these medications may increase your risk of developing gastrointestinal ulcers and bleeding. You may need a dose adjustment or more frequent monitoring by your doctor to safely use both medications.',
					uk: 'Проконсультуйтеся з лікарем, перш ніж приймати аспірин і Advil разом. Прийом аспірину для профілактики інфарктів або інсультів, а також частий або регулярний прийом адвілу може знизити його ефективність. Крім того, комбінування цих ліків може збільшити ризик розвитку виразки шлунково-кишкового тракту та кровотечі. Вам може знадобитися коригування дози або більш частий контроль лікаря, щоб безпечно використовувати обидва препарати.'
				},
				entity1Id: 'advil',
				entity1Type: 'medication',
				entity2Id: 'aspirin',
				entity2Type: 'medication'
			},
			{
				type: TreatmentCheckerReportInteractionType.MedicationWithAlcohol,
				criticality: TreatmentCheckerReportInteractionCriticalityType.High,
				description: {
					en: 'Ask your doctor before using Advil together with ethanol. Do not drink alcohol while taking advil. Alcohol can increase your risk of stomach bleeding caused by advil.',
					uk: 'Проконсультуйтеся зі своїм лікарем перед використанням Адвілу разом з етанолом. Не вживайте алкоголь під час прийому Адвілу. Алкоголь може збільшити ризик шлункової кровотечі, викликаної Адвілом.'
				},
				entity1Id: 'advil',
				entity1Type: 'medication',
				entity2Id: 'alcohol-ethanol',
				entity2Type: 'food'
			},
			{
				type: TreatmentCheckerReportInteractionType.MedicationWithAlcohol,
				criticality: TreatmentCheckerReportInteractionCriticalityType.High,
				description: {
					en: 'Do not drink alcohol while taking \'Aspirin\'.',
					uk: 'Не вживайте алкоголь під час прийому \'Аспірину\'.'
				},
				entity1Id: 'aspirin',
				entity1Type: 'medication',
				entity2Id: 'alcohol',
				entity2Type: 'food'
			},
			{
				type: TreatmentCheckerReportInteractionType.MedicationWithMedication,
				criticality: TreatmentCheckerReportInteractionCriticalityType.Low,
				description: {
					en: 'Using albuterol together with salmeterol may increase cardiovascular side effects such as elevations in heart rate and blood pressure or irregular heart rhythm. Talk to your doctor if you have any questions or concerns, particularly if you have a history of high blood pressure, arrhythmia, or heart disease. Your doctor may be able to prescribe alternatives that do not interact, or you may need a dose adjustment or more frequent monitoring to safely use both medications. It is important to tell your doctor about all other medications you use, including vitamins and herbs. Do not stop using any medications without first talking to your doctor.',
					uk: 'Використання альбутеролу разом із сальметеролом може посилити серцево-судинні побічні ефекти, такі як підвищення частоти серцевих скорочень і артеріального тиску або нерегулярний серцевий ритм. Поговоріть зі своїм лікарем, якщо у вас виникнуть запитання чи проблеми, особливо якщо у вас в анамнезі є високий кров’яний тиск, аритмія або захворювання серця. Ваш лікар може призначити альтернативи, які не взаємодіють, або вам може знадобитися коригування дози або більш частий контроль, щоб безпечно використовувати обидва препарати. Важливо повідомити лікаря про всі інші ліки, які ви використовуєте, включаючи вітаміни та трави. Не припиняйте використовувати будь-які ліки, попередньо не порадившись зі своїм лікарем.'
				},
				entity1Id: 'albuterol',
				entity1Type: 'medication',
				entity2Id: 'salmeterol',
				entity2Type: 'medication'
			}
		],
		medications: [
			{
				medicationId: 'albuterol',
				medicationProperties: {
					drugTypes: [TreatmentCheckerReportMedicationPropertyDrugType.Drug],
					isHerbal: undefined,
					isPersonalizedMedicineAvailable: true,
					isIncompartibleWithPregnancy: undefined,
					relyOnFoodIntakeTypes: [TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.TakeWithWater],
					isEfficacyUnproven: false
				},
				knownTradeNames: [
					{
						id: 'albuterol',
						displayText: {
							en: 'Albuterol',
							uk: 'Альбутамол'
						}
					},
					{
						id: 'accuneb',
						displayText: {
							en: 'AccuNeb',
							uk: 'AccuNeb'
						}
					},
					{
						id: 'ventolin',
						displayText: {
							en: 'Ventolin',
							uk: 'Вентолін'
						}
					},
					{
						id: 'vospireer',
						displayText: {
							en: 'VoSpire ER',
							uk: 'VoSpire ER'
						}
					}
				],
				synonyms: [],
				sideEffects: [
					{
						id: 'tremor-fine',
						displayText: {
							en: 'Tremor fine',
							uk: 'Тремор нормальний'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.AlmostAlways
					},
					{
						id: 'exacerbation-of-asthma',
						displayText: {
							en: 'Exacerbation of asthma',
							uk: 'Загострення астми'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryCommon
					},
					{
						id: 'excitement',
						displayText: {
							en: 'Excitement',
							uk: 'Хвилювання'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryCommon
					},
					{
						id: 'shakiness',
						displayText: {
							en: 'Shakiness',
							uk: 'Запаморочення'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryRare
					},
					{
						id: 'inflammation',
						displayText: {
							en: 'Inflammation ',
							uk: 'Запалення '
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryRare
					},
					{
						id: 'headache',
						displayText: {
							en: 'Headache',
							uk: 'Головний біль'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.Undefined
					},
					{
						id: 'nervousness',
						displayText: {
							en: 'Nervousness',
							uk: 'Нервозність'
						}
					}
				],
				pgxLevelTestings: albuterolPgxLevelTestings,
				personalizedMedicineGuidelines: albuterolPersonalizedMedicineGuidelines,
				pubmedCitations: {
					personalizedMedicineCitations: albuterolPubmedPersonalizedMedicineCitations,
					otherCitations: []
				}
			},
			{
				medicationId: 'metoprolol',
				medicationProperties: {
					drugTypes: [TreatmentCheckerReportMedicationPropertyDrugType.Drug],
					isHerbal: undefined,
					isPersonalizedMedicineAvailable: true,
					isIncompartibleWithPregnancy: undefined,
					relyOnFoodIntakeTypes: [TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.TakeWithWater],
					isEfficacyUnproven: false,
					hasPGxRecommendation: {
						percent: 47,
						population: { id: 'european', displayText: 'European' },
						region: { id: 'nld', displayText: 'Netherlands' }
					}
				},
				knownTradeNames: [
					{
						id: 'metoprolol',
						displayText: {
							en: 'Metoprolol',
							uk: 'Метопролол'
						}
					}
				],
				synonyms: [],
				sideEffects: [
					{
						id: 'excitement',
						displayText: {
							en: 'Excitement',
							uk: 'Хвилювання'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryCommon
					},
					{
						id: 'headache',
						displayText: {
							en: 'Headache',
							uk: 'Головний біль'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.Undefined
					},
					{
						id: 'nervousness',
						displayText: {
							en: 'Nervousness',
							uk: 'Нервозність'
						}
					}
				],
				pgxLevelTestings: [],
				personalizedMedicineGuidelines: [],
				pubmedCitations: {
					personalizedMedicineCitations: metoprololPubmedPersonalizedMedicineCitations,
					otherCitations: []
				}
			},
			{
				medicationId: 'serevent-diskus',
				medicationProperties: {
					drugTypes: [TreatmentCheckerReportMedicationPropertyDrugType.Drug],
					isHerbal: undefined,
					isPersonalizedMedicineAvailable: false,
					isIncompartibleWithPregnancy: undefined,
					relyOnFoodIntakeTypes: [TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.TakeWithMeal],
					isEfficacyUnproven: false
				},
				knownTradeNames: [
					{
						id: 'Serevent-diskhaler-disk',
						displayText: {
							en: 'Serevent Diskhaler Disk',
							uk: 'Serevent Diskhaler'
						}
					},
					{
						id: 'serevent-diskus',
						displayText: {
							en: 'Serevent Diskus',
							uk: 'Серевент дискус'
						}
					},
					{
						id: 'severent',
						displayText: {
							en: 'Severent',
							uk: 'Северент'
						}
					},
					{
						id: 'severent-diskhaler',
						displayText: {
							en: 'Severent Diskhaler',
							uk: 'Северент Дисхалер'
						}
					},
					{
						id: 'severent-diskus',
						displayText: {
							en: 'Severent Diskus',
							uk: 'Сильний дискус'
						}
					}
				],
				synonyms: [],
				sideEffects: [
					{
						id: 'headache',
						displayText: {
							en: 'Headache',
							uk: 'Головний біль'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.AlmostAlways
					},
					{
						id: 'musculoskeletal-pain',
						displayText: {
							en: 'Musculoskeletal pain',
							uk: 'Опорно-руховий біль'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.AlmostAlways
					},
					{
						id: 'sinus-congestion',
						displayText: {
							en: 'Sinus congestion',
							uk: 'Закладеність пазухи'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.AlmostAlways
					},
					{
						id: 'hypersensitivity',
						displayText: {
							en: 'Hypersensitivity',
							uk: 'Підвищена чутливість'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryCommon
					},
					{
						id: 'throat-irritation',
						displayText: {
							en: 'Throat irritation',
							uk: 'Подразнення горла'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryCommon
					},
					{
						id: 'bronchitis',
						displayText: {
							en: 'Bronchitis',
							uk: 'Бронхіт'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryCommon
					},
					{
						id: 'cough',
						displayText: {
							en: 'Cough ',
							uk: 'Кашель '
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryRare
					}
				],
				pgxLevelTestings: [],
				personalizedMedicineGuidelines: [],
				pubmedCitations: {
					personalizedMedicineCitations: [],
					otherCitations: []
				}
			},
			{
				medicationId: 'advil',
				medicationProperties: {
					drugTypes: [TreatmentCheckerReportMedicationPropertyDrugType.Drug],
					isHerbal: undefined,
					isPersonalizedMedicineAvailable: false,
					isIncompartibleWithPregnancy: undefined,
					relyOnFoodIntakeTypes: [TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.TakeWithMeal],
					isEfficacyUnproven: false
				},
				knownTradeNames: [
					{
						id: 'addaprin',
						displayText: {
							en: 'Addaprin',
							uk: 'Аддапрін'
						}
					},
					{
						id: 'advil',
						displayText: {
							en: 'Advil',
							uk: 'Адвіл'
						}
					},
					{
						id: 'a-g-profen',
						displayText: {
							en: 'A-G Profen',
							uk: 'A-G Профен'
						}
					},
					{
						id: 'bufen',
						displayText: {
							en: 'Bufen',
							uk: 'Буфен'
						}
					},
					{
						id: 'genpril',
						displayText: {
							en: 'Genpril',
							uk: 'Генприл'
						}
					},
					{
						id: 'haltran',
						displayText: {
							en: 'Haltran',
							uk: 'Галтран'
						}
					},
					{
						id: 'ibu',
						displayText: {
							en: 'Ibu',
							uk: 'Ібу'
						}
					},
					{
						id: 'ibu-2',
						displayText: {
							en: 'Ibu-2',
							uk: 'Ібу-2'
						}
					},
					{
						id: 'ibu-200',
						displayText: {
							en: 'Ibu-200',
							uk: 'Ібу-200'
						}
					},
					{
						id: 'ibu-4',
						displayText: {
							en: 'Ibu-4',
							uk: 'Ібу-4'
						}
					},
					{
						id: 'ibu-6',
						displayText: {
							en: 'Ibu-6',
							uk: 'Ібу-6'
						}
					},
					{
						id: 'ibu-8',
						displayText: {
							en: 'Ibu-8',
							uk: 'Ібу-8'
						}
					},
					{
						id: 'ibuprohm',
						displayText: {
							en: 'Ibuprohm',
							uk: 'Ібупром'
						}
					},
					{
						id: 'ibu-tab',
						displayText: {
							en: 'Ibu-Tab',
							uk: 'Ібу-таб'
						}
					},
					{
						id: 'i-prin',
						displayText: {
							en: 'I-Prin',
							uk: 'I-Прин'
						}
					},
					{
						id: 'midol',
						displayText: {
							en: 'Midol',
							uk: 'Мідол'
						}
					},
					{
						id: 'motrin',
						displayText: {
							en: 'Motrin',
							uk: 'Мотрин'
						}
					},
					{
						id: 'nuprin',
						displayText: {
							en: 'Nuprin',
							uk: 'Нупрін'
						}
					},
					{
						id: 'proprinal',
						displayText: {
							en: 'Proprinal',
							uk: 'Пропринал'
						}
					},
					{
						id: 'q-profen',
						displayText: {
							en: 'Q-Profen',
							uk: 'Q-Профен'
						}
					}
				],
				synonyms: [],
				sideEffects: [
					{
						id: 'nausea',
						displayText: {
							en: 'Nausea',
							uk: 'Нудота'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.AlmostAlways
					},
					{
						id: 'apnoea',
						displayText: {
							en: 'Apnoea',
							uk: 'Апное'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.AlmostAlways
					},
					{
						id: 'gastrointestinal-disorder',
						displayText: {
							en: 'Gastrointestinal disorder',
							uk: 'Шлунково-кишковий розлад'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.AlmostAlways
					},
					{
						id: 'constipation',
						displayText: {
							en: 'Constipation',
							uk: 'Запор'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.AlmostAlways
					},
					{
						id: 'vomiting',
						displayText: {
							en: 'Vomiting',
							uk: 'Блювання'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryRare
					}
				],
				pgxLevelTestings: [],
				personalizedMedicineGuidelines: [],
				pubmedCitations: {
					personalizedMedicineCitations: [],
					otherCitations: []
				}
			},
			{
				medicationId: 'aspirin',
				medicationProperties: {
					drugTypes: [TreatmentCheckerReportMedicationPropertyDrugType.Drug],
					isHerbal: undefined,
					isPersonalizedMedicineAvailable: false,
					isIncompartibleWithPregnancy: undefined,
					relyOnFoodIntakeTypes: [TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.TakeWithMeal],
					isEfficacyUnproven: false
				},
				knownTradeNames: [
					{
						id: 'ascriptin',
						displayText: {
							en: 'Ascriptin',
							uk: 'Аскриптин'
						}
					},
					{
						id: 'aspergum',
						displayText: {
							en: 'Aspergum',
							uk: 'Аспергум'
						}
					},
					{
						id: 'aspirtab',
						displayText: {
							en: 'Aspirtab',
							uk: 'Аспіртаб'
						}
					},
					{
						id: 'bayer',
						displayText: {
							en: 'Bayer',
							uk: 'Байер'
						}
					},
					{
						id: 'easprin',
						displayText: {
							en: 'Easprin',
							uk: 'Іспрін'
						}
					},
					{
						id: 'ecotrin',
						displayText: {
							en: 'Ecotrin',
							uk: 'Екотрин'
						}
					},
					{
						id: 'ecpirin',
						displayText: {
							en: 'Ecpirin',
							uk: 'Екпірін'
						}
					},
					{
						id: 'entercote',
						displayText: {
							en: 'Entercote',
							uk: 'Ентеркот'
						}
					},
					{
						id: 'genacote',
						displayText: {
							en: 'Genacote',
							uk: 'Генакот'
						}
					},
					{
						id: 'halfprin',
						displayText: {
							en: 'Halfprin',
							uk: 'Гальприн'
						}
					},
					{
						id: 'ninoprin',
						displayText: {
							en: 'Ninoprin',
							uk: 'Нінопрін'
						}
					},
					{
						id: 'norwich-aspirin',
						displayText: {
							en: 'Norwich Aspirin',
							uk: 'Аспірин Норвіч'
						}
					}
				],
				synonyms: [],
				sideEffects: [
					{
						id: 'upset-stomach',
						displayText: {
							en: 'Upset stomach',
							uk: 'Розлад шлунка'
						}, frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryRare
					},
					{
						id: 'heartburn',
						displayText: {
							en: 'Heartburn',
							uk: 'Печія'
						}, frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryRare
					}
				],
				pgxLevelTestings: [],
				personalizedMedicineGuidelines: [],
				pubmedCitations: {
					personalizedMedicineCitations: [],
					otherCitations: []
				}
			},
			{
				medicationId: 'actovegin',
				medicationProperties: {
					drugTypes: [],
					isHerbal: undefined,
					isPersonalizedMedicineAvailable: false,
					isIncompartibleWithPregnancy: undefined,
					relyOnFoodIntakeTypes: [],
					isEfficacyUnproven: true
				},
				knownTradeNames: [
					{
						id: 'actovegin-forte',
						displayText: {
							en: 'Actovegin FORTE',
							uk: 'Актовегін ФОРТЕ'
						}
					},
					{
						id: 'actovegin-a',
						displayText: {
							en: 'Actovegin A',
							uk: 'Актовегін А'
						}
					},
					{
						id: 'actovegin-plus',
						displayText: {
							en: 'Actovegin Plus',
							uk: 'Актовегін Плюс'
						}
					}
				],
				synonyms: [],
				sideEffects: [],
				pgxLevelTestings: [],
				personalizedMedicineGuidelines: [],
				pubmedCitations: {
					personalizedMedicineCitations: [],
					otherCitations: []
				}
			}
		]
	},
	{
		id: '00000000-1111-0000-0000-000000000002',
		createdOn: '2022-02-17T16:13:17',
		treatmentInfo: {
			age: 40,
			populationId: 'european',
			genderId: 'male',
			height: 182,
			weight: 82,
			diseaseIds: ['bronchial-asthma'],
			medicationIds: ['albuterol', 'advil', 'aspirin'],
			anamnesis: {},
			lifestyle: {}
		},
		cautions: [
			{
				type: TreatmentCheckerReportCautionType.PersonalizedMedicineIsAvailable,
				severity: TreatmentCheckerReportCautionSeverity.SuperInfo,
				parameters: ['Albuterol']
			},
			{
				type: TreatmentCheckerReportCautionType.DecreaseEffectiveness,
				severity: TreatmentCheckerReportCautionSeverity.Error,
				parameters: ['Advil', 'Aspirin']
			},
			{
				type: TreatmentCheckerReportCautionType.IsNotPartOfAnyDiseaseTreatment,
				severity: TreatmentCheckerReportCautionSeverity.Warning,
				parameters: ['Advil']
			},
			{
				type: TreatmentCheckerReportCautionType.TreatmentGuidelinesAvailable,
				severity: TreatmentCheckerReportCautionSeverity.Info,
				parameters: ['Bronchial asthma', 'Heart disease']
			}
		],
		diseases: [
			{
				diseaseId: 'bronchial-asthma',
				infectiousType: TreatmentCheckerReportDiseaseReportInfectiousType.NonInfectious,
				isHeritable: true,
				heritabilityRate: 12,
				frequency: 8,
				isGeneticTestingAvailable: true,
				comorbidities: [
					{
						id: 'rhinitis',
						displayText: {
							en: 'rhinitis',
							uk: 'риніт'
						}
					},
					{
						id: 'sinusitis',
						displayText: {
							en: 'sinusitis',
							uk: 'синусит'
						}
					},
					{
						id: 'gastroesophageal reflux disease',
						displayText: {
							en: 'gastroesophageal reflux disease',
							uk: 'гастроезофагеальна рефлюксна хвороба'
						}
					},
					{
						id: 'obstructive sleep apnea',
						displayText: {
							en: 'obstructive sleep apnea',
							uk: 'обструктивне апное сну'
						}
					}
				],
				synonyms: [
					{
						id: 'asthma',
						displayText: {
							en: 'Asthma',
							uk: 'Астма'
						}
					},
					{
						id: 'asthma-attack',
						displayText: {
							en: 'Asthma attack',
							uk: 'Приступ астми'
						}
					},
					{
						id: 'respiratory-disorder',
						displayText: {
							en: 'Respiratory disorder',
							uk: 'Розлад дихання'
						}
					},
					{
						id: 'respiratory-illness',
						displayText: {
							en: 'Respiratory illness',
							uk: 'Захворювання органів дихання'
						}
					},
					{
						id: 'respiratory-disease',
						displayText: {
							en: 'Respiratory disease',
							uk: 'Захворювання органів дихання'
						}
					},
					{
						id: 'mucolytic',
						displayText: {
							en: 'Mucolytic',
							uk: 'Муколітична хвороба'
						}
					}
				],
				affectedOrgans: [],
				personalizedMedicineGuidelines: astmaPersonalizedMedicineGuidelines,
				diseaseTreatmentGuidelines: astmaFakeDiseaseTreatmentGuidelines
			}
		],
		interactions: [
			{
				type: TreatmentCheckerReportInteractionType.MedicationWithMedication,
				criticality: TreatmentCheckerReportInteractionCriticalityType.High,
				description: {
					en: 'Consult your doctor before taking aspirin and Advil together. Taking aspirin to prevent heart attacks or strokes and taking Advil frequently or regularly may reduce its effectiveness. In addition, combining these medications may increase your risk of developing gastrointestinal ulcers and bleeding. You may need a dose adjustment or more frequent monitoring by your doctor to safely use both medications.',
					uk: 'Проконсультуйтеся з лікарем, перш ніж приймати аспірин і Адвілом разом. Прийом аспірину для запобігання серцевих нападів або інсультів, а також частий або регулярний прийом Адвілу може знизити його ефективність. Крім того, комбінування цих ліків може збільшити ризик розвитку виразки шлунково-кишкового тракту та кровотечі. Вам може знадобитися коригування дози або більш частий контроль лікаря, щоб безпечно використовувати обидва препарати.'
				},
				entity1Id: 'advil',
				entity1Type: 'medication',
				entity2Id: 'aspirin',
				entity2Type: 'medication'
			},
			{
				type: TreatmentCheckerReportInteractionType.MedicationWithAlcohol,
				criticality: TreatmentCheckerReportInteractionCriticalityType.High,
				description: {
					en: 'Ask your doctor before using Advil together with ethanol. Do not drink alcohol while taking Advil. Alcohol can increase your risk of stomach bleeding caused by Advil.',
					uk: 'Проконсультуйтесь зі своїм лікарем перед використанням Адвілу разом з етанолом. Не вживайте алкоголь під час прийому Адвілу. Алкоголь може збільшити ризик шлункової кровотечі, викликаної Адвілом.'
				},
				entity1Id: 'advil',
				entity1Type: 'medication',
				entity2Id: 'alcohol-ethanol',
				entity2Type: 'food'
			},
			{
				type: TreatmentCheckerReportInteractionType.MedicationWithAlcohol,
				criticality: TreatmentCheckerReportInteractionCriticalityType.High,
				description: {
					en: 'Do not drink alcohol while taking \'Aspirin\'.',
					uk: 'Не вживайте алкоголь під час прийому \'Аспірину\'.'
				},
				entity1Id: 'aspirin',
				entity1Type: 'medication',
				entity2Id: 'alcohol',
				entity2Type: 'food'
			}
		],
		medications: [
			{
				medicationId: 'albuterol',
				medicationProperties: {
					drugTypes: [TreatmentCheckerReportMedicationPropertyDrugType.Drug],
					isHerbal: undefined,
					isPersonalizedMedicineAvailable: true,
					isIncompartibleWithPregnancy: undefined,
					relyOnFoodIntakeTypes: [TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.TakeWithWater],
					isEfficacyUnproven: false
				},
				knownTradeNames: [
					{
						id: 'albuterol',
						displayText: {
							en: 'Albuterol',
							uk: 'Альбутамол'
						}
					},
					{
						id: 'accuneb',
						displayText: {
							en: 'AccuNeb',
							uk: 'AccuNeb'
						}
					},
					{
						id: 'ventolin',
						displayText: {
							en: 'Ventolin',
							uk: 'Ventolin'
						}
					},
					{
						id: 'vospireer',
						displayText: {
							en: 'VoSpire ER',
							uk: 'VoSpire ER'
						}
					}
				],
				synonyms: [],
				sideEffects: [
					{
						id: 'tremor-fine',
						displayText: {
							en: 'Tremor fine',
							uk: 'Тремор'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.AlmostAlways
					},
					{
						id: 'exacerbation-of-asthma',
						displayText: {
							en: 'Exacerbation of asthma',
							uk: 'Загострення астми'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryCommon
					},
					{
						id: 'excitement',
						displayText: {
							en: 'Excitement',
							uk: 'Хвилювання'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryCommon
					},
					{
						id: 'shakiness',
						displayText: {
							en: 'Shakiness',
							uk: 'Запаморочення'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryCommon
					},
					{
						id: 'inflammation',
						displayText: {
							en: 'Inflammation ',
							uk: 'Запалення '
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryRare
					},
					{
						id: 'headache',
						displayText: {
							en: 'Headache',
							uk: 'Головний біль'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryRare
					},
					{
						id: 'nervousness',
						displayText: {
							en: 'Nervousness',
							uk: 'Нервозність'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryRare
					}
				],
				pgxLevelTestings: albuterolPgxLevelTestings,
				personalizedMedicineGuidelines: albuterolPersonalizedMedicineGuidelines,
				pubmedCitations: {
					personalizedMedicineCitations: albuterolPubmedPersonalizedMedicineCitations,
					otherCitations: []
				}
			},
			{
				medicationId: 'advil',
				medicationProperties: {
					drugTypes: [TreatmentCheckerReportMedicationPropertyDrugType.Drug],
					isHerbal: undefined,
					isPersonalizedMedicineAvailable: false,
					isIncompartibleWithPregnancy: undefined,
					relyOnFoodIntakeTypes: [TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.TakeWithMeal],
					isEfficacyUnproven: false
				},
				knownTradeNames: [
					{
						id: 'addaprin',
						displayText: {
							en: 'Addaprin',
							uk: 'Аддапрін'
						}
					},
					{
						id: 'advil',
						displayText: {
							en: 'Advil',
							uk: 'Адвіл'
						}
					},
					{
						id: 'a-g-profen',
						displayText: {
							en: 'A-G Profen',
							uk: 'A-G Профен'
						}
					},
					{
						id: 'bufen',
						displayText: {
							en: 'Bufen',
							uk: 'Буфен'
						}
					},
					{
						id: 'genpril',
						displayText: {
							en: 'Genpril',
							uk: 'Генприл'
						}
					},
					{
						id: 'haltran',
						displayText: {
							en: 'Haltran',
							uk: 'Галтран'
						}
					},
					{
						id: 'ibu',
						displayText: {
							en: 'Ibu',
							uk: 'Ібу'
						}
					},
					{
						id: 'ibu-2',
						displayText: {
							en: 'Ibu-2',
							uk: 'Ібу-2'
						}
					},
					{
						id: 'ibu-200',
						displayText: {
							en: 'Ibu-200',
							uk: 'Ібу-200'
						}
					},
					{
						id: 'ibu-4',
						displayText: {
							en: 'Ibu-4',
							uk: 'Ібу-4'
						}
					},
					{
						id: 'ibu-6',
						displayText: {
							en: 'Ibu-6',
							uk: 'Ібу-6'
						}
					},
					{
						id: 'ibu-8',
						displayText: {
							en: 'Ibu-8',
							uk: 'Ібу-8'
						}
					},
					{
						id: 'ibuprohm',
						displayText: {
							en: 'Ibuprohm',
							uk: 'Ібупром'
						}
					},
					{
						id: 'ibu-tab',
						displayText: {
							en: 'Ibu-Tab',
							uk: 'Ібу-таб'
						}
					},
					{
						id: 'i-prin',
						displayText: {
							en: 'I-Prin',
							uk: 'I-Прин'
						}
					},
					{
						id: 'midol',
						displayText: {
							en: 'Midol',
							uk: 'Мідол'
						}
					},
					{
						id: 'motrin',
						displayText: {
							en: 'Motrin',
							uk: 'Мотрин'
						}
					},
					{
						id: 'nuprin',
						displayText: {
							en: 'Nuprin',
							uk: 'Нупрін'
						}
					},
					{
						id: 'proprinal',
						displayText: {
							en: 'Proprinal',
							uk: 'Пропринал'
						}
					},
					{
						id: 'q-profen',
						displayText: {
							en: 'Q-Profen',
							uk: 'Q-Профен'
						}
					}
				],
				synonyms: [],
				sideEffects: [
					{
						id: 'nausea',
						displayText: {
							en: 'Nausea',
							uk: 'Нудота'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.AlmostAlways
					},
					{
						id: 'apnoea',
						displayText: {
							en: 'Apnoea',
							uk: 'Апное'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.AlmostAlways
					},
					{
						id: 'gastrointestinal-disorder',
						displayText: {
							en: 'Gastrointestinal disorder',
							uk: 'Шлунково-кишковий розлад'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.AlmostAlways
					},
					{
						id: 'constipation',
						displayText: {
							en: 'Constipation',
							uk: 'Запор'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.AlmostAlways
					},
					{
						id: 'vomiting',
						displayText: {
							en: 'Vomiting',
							uk: 'Блювання'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryRare
					}
				],
				pgxLevelTestings: [],
				personalizedMedicineGuidelines: [],
				pubmedCitations: {
					personalizedMedicineCitations: [],
					otherCitations: []
				}
			},
			{
				medicationId: 'aspirin',
				medicationProperties: {
					drugTypes: [TreatmentCheckerReportMedicationPropertyDrugType.Drug],
					isHerbal: undefined,
					isPersonalizedMedicineAvailable: false,
					isIncompartibleWithPregnancy: undefined,
					relyOnFoodIntakeTypes: [TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.TakeWithMeal],
					isEfficacyUnproven: false
				},
				knownTradeNames: [
					{
						id: 'ascriptin',
						displayText: {
							en: 'Ascriptin',
							uk: 'Аскриптин'
						}
					},
					{
						id: 'aspergum',
						displayText: {
							en: 'Aspergum',
							uk: 'Аспергум'
						}
					},
					{
						id: 'aspirtab',
						displayText: {
							en: 'Aspirtab',
							uk: 'Аспіртаб'
						}
					},
					{
						id: 'bayer',
						displayText: {
							en: 'Bayer',
							uk: 'Байер'
						}
					},
					{
						id: 'easprin',
						displayText: {
							en: 'Easprin',
							uk: 'Іспрін'
						}
					},
					{
						id: 'ecotrin',
						displayText: {
							en: 'Ecotrin',
							uk: 'Екотрин'
						}
					},
					{
						id: 'ecpirin',
						displayText: {
							en: 'Ecpirin',
							uk: 'Екпірін'
						}
					},
					{
						id: 'entercote',
						displayText: {
							en: 'Entercote',
							uk: 'Ентеркот'
						}
					},
					{
						id: 'genacote',
						displayText: {
							en: 'Genacote',
							uk: 'Генакот'
						}
					},
					{
						id: 'halfprin',
						displayText: {
							en: 'Halfprin',
							uk: 'Гальфрин'
						}
					},
					{
						id: 'ninoprin',
						displayText: {
							en: 'Ninoprin',
							uk: 'Нінопрін'
						}
					},
					{
						id: 'norwich-aspirin',
						displayText: {
							en: 'Norwich Aspirin',
							uk: 'Аспірин Норвіч'
						}
					}
				],
				synonyms: [],
				sideEffects: [
					{
						id: 'upset-stomach',
						displayText: {
							en: 'Upset stomach',
							uk: 'Розлад шлунка'
						}, frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryRare
					},
					{
						id: 'heartburn',
						displayText: {
							en: 'Heartburn',
							uk: 'Печія'
						}, frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryRare
					}
				],
				pgxLevelTestings: [],
				personalizedMedicineGuidelines: [],
				pubmedCitations: {
					personalizedMedicineCitations: [],
					otherCitations: []
				}
			}
		]
	},
	{
		id: '00000000-1111-0000-0000-000000000003',
		createdOn: '2022-01-24T14:52:49',
		treatmentInfo: {
			age: 40,
			populationId: 'european',
			genderId: 'male',
			height: 182,
			weight: 82,
			diseaseIds: ['bronchial-asthma'],
			medicationIds: ['cinqair', 'advil', 'vitamin-d'],
			anamnesis: {},
			lifestyle: {}
		},
		cautions: [
			{
				type: TreatmentCheckerReportCautionType.IsNotPartOfAnyDiseaseTreatment,
				severity: TreatmentCheckerReportCautionSeverity.Warning,
				parameters: ['Advil']
			},
			{
				type: TreatmentCheckerReportCautionType.TreatmentGuidelinesAvailable,
				severity: TreatmentCheckerReportCautionSeverity.Info,
				parameters: ['Bronchial asthma', 'Heart disease']
			}
		],
		diseases: [
			{
				diseaseId: 'bronchial-asthma',
				infectiousType: TreatmentCheckerReportDiseaseReportInfectiousType.NonInfectious,
				isHeritable: true,
				heritabilityRate: 12,
				frequency: 8,
				isGeneticTestingAvailable: true,
				comorbidities: [
					{
						id: 'rhinitis',
						displayText: {
							en: 'rhinitis',
							uk: 'риніт'
						}
					},
					{
						id: 'sinusitis',
						displayText: {
							en: 'sinusitis',
							uk: 'синусит'
						}
					},
					{
						id: 'gastroesophageal reflux disease',
						displayText: {
							en: 'gastroesophageal reflux disease',
							uk: 'гастроезофагеальна рефлюксна хвороба'
						}
					},
					{
						id: 'obstructive sleep apnea',
						displayText: {
							en: 'obstructive sleep apnea',
							uk: 'обструктивне апное сну'
						}
					}
				],
				synonyms: [
					{
						id: 'asthma',
						displayText: {
							en: 'Asthma',
							uk: 'Астма'
						}
					},
					{
						id: 'asthma-attack',
						displayText: {
							en: 'Asthma attack',
							uk: 'Приступ астми'
						}
					},
					{
						id: 'respiratory-disorder',
						displayText: {
							en: 'Respiratory disorder',
							uk: 'Розлад дихання'
						}
					},
					{
						id: 'respiratory-illness',
						displayText: {
							en: 'Respiratory illness',
							uk: 'Захворювання органів дихання'
						}
					},
					{
						id: 'respiratory-disease',
						displayText: {
							en: 'Respiratory disease',
							uk: 'Захворювання органів дихання'
						}
					},
					{
						id: 'mucolytic',
						displayText: {
							en: 'Mucolytic',
							uk: 'Муколітична хворба'
						}
					}
				],
				affectedOrgans: [],
				personalizedMedicineGuidelines: astmaPersonalizedMedicineGuidelines,
				diseaseTreatmentGuidelines: astmaFakeDiseaseTreatmentGuidelines
			}
		],
		interactions: [
			{
				type: TreatmentCheckerReportInteractionType.MedicationWithAlcohol,
				criticality: TreatmentCheckerReportInteractionCriticalityType.High,
				description: {
					en: 'Ask your doctor before using Advil together with ethanol. Do not drink alcohol while taking Advil. Alcohol can increase your risk of stomach bleeding caused by Advil.',
					uk: 'Проконсультуйтесь зі своїм лікарем перед використанням Адвілу разом з етанолом. Не вживайте алкоголь під час прийому Адвілу. Алкоголь може збільшити ризик шлункової кровотечі, викликаної Адвілом.'
				},
				entity1Id: 'advil',
				entity1Type: 'medication',
				entity2Id: 'alcohol-ethanol',
				entity2Type: 'food'
			}
		],
		medications: [
			{
				medicationId: 'cinqair',
				medicationProperties: {
					drugTypes: [TreatmentCheckerReportMedicationPropertyDrugType.Drug],
					isHerbal: undefined,
					isPersonalizedMedicineAvailable: false,
					isIncompartibleWithPregnancy: undefined,
					relyOnFoodIntakeTypes: [TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.TakeWithMeal],
					isEfficacyUnproven: false
				},
				knownTradeNames: [
					{
						id: 'cinqair',
						displayText: {
							en: 'Cinqair',
							uk: 'Цинкар'
						}
					}
				],
				synonyms: [],
				sideEffects: [
					{
						id: 'throat-pain',
						displayText: {
							en: 'Throat pain',
							uk: 'Біль у горлі'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryRare
					},
					{
						id: 'mouth-pain',
						displayText: {
							en: 'Mouth pain',
							uk: 'Біль у ротовій порожнині'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryRare
					}
				],
				pgxLevelTestings: [],
				personalizedMedicineGuidelines: [],
				pubmedCitations: {
					personalizedMedicineCitations: [],
					otherCitations: []
				}
			},
			{
				medicationId: 'advil',
				medicationProperties: {
					drugTypes: [TreatmentCheckerReportMedicationPropertyDrugType.Drug],
					isHerbal: undefined,
					isPersonalizedMedicineAvailable: false,
					isIncompartibleWithPregnancy: undefined,
					relyOnFoodIntakeTypes: [TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.TakeWithMeal],
					isEfficacyUnproven: false
				},
				knownTradeNames: [
					{
						id: 'addaprin',
						displayText: {
							en: 'Addaprin',
							uk: 'Аддапрін'
						}
					},
					{
						id: 'advil',
						displayText: {
							en: 'Advil',
							uk: 'Адвіл'
						}
					},
					{
						id: 'a-g-profen',
						displayText: {
							en: 'A-G Profen',
							uk: 'A-G Профен'
						}
					},
					{
						id: 'bufen',
						displayText: {
							en: 'Bufen',
							uk: 'Буфен'
						}
					},
					{
						id: 'genpril',
						displayText: {
							en: 'Genpril',
							uk: 'Генприл'
						}
					},
					{
						id: 'haltran',
						displayText: {
							en: 'Haltran',
							uk: 'Галтран'
						}
					},
					{
						id: 'ibu',
						displayText: {
							en: 'Ibu',
							uk: 'Ібу'
						}
					},
					{
						id: 'ibu-2',
						displayText: {
							en: 'Ibu-2',
							uk: 'Ібу-2'
						}
					},
					{
						id: 'ibu-200',
						displayText: {
							en: 'Ibu-200',
							uk: 'Ібу-200'
						}
					},
					{
						id: 'ibu-4',
						displayText: {
							en: 'Ibu-4',
							uk: 'Ібу-4'
						}
					},
					{
						id: 'ibu-6',
						displayText: {
							en: 'Ibu-6',
							uk: 'Ібу-6'
						}
					},
					{
						id: 'ibu-8',
						displayText: {
							en: 'Ibu-8',
							uk: 'Ібу-8'
						}
					},
					{
						id: 'ibuprohm',
						displayText: {
							en: 'Ibuprohm',
							uk: 'Ібупром'
						}
					},
					{
						id: 'ibu-tab',
						displayText: {
							en: 'Ibu-Tab',
							uk: 'Ібу-таб'
						}
					},
					{
						id: 'i-prin',
						displayText: {
							en: 'I-Prin',
							uk: 'I-Прин'
						}
					},
					{
						id: 'midol',
						displayText: {
							en: 'Midol',
							uk: 'Мідол'
						}
					},
					{
						id: 'motrin',
						displayText: {
							en: 'Motrin',
							uk: 'Мотрин'
						}
					},
					{
						id: 'nuprin',
						displayText: {
							en: 'Nuprin',
							uk: 'Нупрін'
						}
					},
					{
						id: 'proprinal',
						displayText: {
							en: 'Proprinal',
							uk: 'Пропринал'
						}
					},
					{
						id: 'q-profen',
						displayText: {
							en: 'Q-Profen',
							uk: 'Q-Профен'
						}
					}
				],
				synonyms: [],
				sideEffects: [
					{
						id: 'nausea',
						displayText: {
							en: 'Nausea',
							uk: 'Нудота'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.AlmostAlways
					},
					{
						id: 'apnoea',
						displayText: {
							en: 'Apnoea',
							uk: 'Апное'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.AlmostAlways
					},
					{
						id: 'gastrointestinal-disorder',
						displayText: {
							en: 'Gastrointestinal disorder',
							uk: 'Шлунково-кишковий розлад'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.AlmostAlways
					},
					{
						id: 'constipation',
						displayText: {
							en: 'Constipation',
							uk: 'Запор'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.AlmostAlways
					},
					{
						id: 'vomiting',
						displayText: {
							en: 'Vomiting',
							uk: 'Блювання'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryRare
					}
				],
				pgxLevelTestings: [],
				personalizedMedicineGuidelines: [],
				pubmedCitations: {
					personalizedMedicineCitations: [],
					otherCitations: []
				}
			},
			{
				medicationId: 'vitamin-d',
				medicationProperties: {
					drugTypes: [TreatmentCheckerReportMedicationPropertyDrugType.DietarySupplement],
					isHerbal: undefined,
					isPersonalizedMedicineAvailable: false,
					isIncompartibleWithPregnancy: undefined,
					relyOnFoodIntakeTypes: [TreatmentCheckerReportMedicationPropertyRelyOnFoodIntakeType.TakeWithMeal],
					isEfficacyUnproven: false
				},
				knownTradeNames: [
					{
						id: 'calciferol',
						displayText: {
							en: 'Calciferol',
							uk: 'Кальциферол'
						}
					},
					{
						id: 'drisdol',
						displayText: {
							en: 'Drisdol',
							uk: 'Дрісдол'
						}
					}
				],
				synonyms: [],
				sideEffects: [
					{
						id: 'upset-stomach',
						displayText: {
							en: 'Upset stomach',
							uk: 'Розлад шлунка'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryRare
					},
					{
						id: 'heartburn',
						displayText: {
							en: 'Heartburn',
							uk: 'Печія'
						},
						frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType.VeryRare
					}
				],
				pgxLevelTestings: [],
				personalizedMedicineGuidelines: [],
				pubmedCitations: {
					personalizedMedicineCitations: [],
					otherCitations: []
				}
			}
		]
	}
];

const personalizedMedicineRecommendations: Array<PersonalizedMedicineRecommendationFakeRecord> = [
	{
		medicationId: 'albuterol',
		geneId: 'SPATS2L',
		pharmacogenomicTestResultId: 'IncreasedFunction',
		source: {
			link: 'https://pubmed.ncbi.nlm.nih.gov/22792082/',
			displayText: {
				en: 'Genome-wide association analysis in asthma subjects identifies SPATS2L as a novel bronchodilator response gene',
				uk: 'Genome-wide association analysis in asthma subjects identifies SPATS2L as a novel bronchodilator response gene'
			}
		},
		implication: {
			en: 'Increased metabolism of salbutamol',
			uk: 'Increased metabolism of salbutamol'
		},
		drugRecommendation: {
			en: 'Carefully examine the patient response while increasing the dose of salbutamol',
			uk: 'Carefully examine the patient response while increasing the dose of salbutamol'
		},
		comment: {
			en: 'Genotype TT in rs295137 site is associated with increased response to salbutamol in people with Asthma as compared to genotypes CC + CT.',
			uk: 'Genotype TT in rs295137 site is associated with increased response to salbutamol in people with Asthma as compared to genotypes CC + CT.'
		},
		population: 'general',
		classification: 'moderate',
		activityScore: undefined
	}
];
