import { GoogleAnalyticsEvent } from '../../models/google-analytics-event.enum';

export class GaEventTrackingVaccinationCardService {

	constructor(
		private readonly trackEvent: (event: GoogleAnalyticsEvent, data?: any, completeFn?: () => void) => void
	) { }

	public vaccinationCreated(completeFn?: () => void): void {
		this.trackEvent(GoogleAnalyticsEvent.VaccinationCreated, {}, completeFn);
	}

	public vaccinationDeleted(completeFn?: () => void): void {
		this.trackEvent(GoogleAnalyticsEvent.VaccinationDeleted, {}, completeFn);
	}

}
