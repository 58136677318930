import { Injector, Provider } from '@angular/core';
import { GenderClient } from '@nmn-communication/genders';
import { genderClientFactory } from './factories/gender.client.factory';

export const genderProviders: Array<Provider> = [
	{
		provide: GenderClient,
		useFactory: genderClientFactory,
		deps: [Injector]
	}
];
