import { Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { DocumentTypeFakeClientConfiguration } from '../configurations/document-type.fake-client.configuration';
import { DocumentTypeFakeClient } from '../services/document-type.fake-client';

export class DocumentTypeFakeClientBuilder {

	private configuration: DocumentTypeFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(
		configuration: DocumentTypeFakeClientConfiguration
	): DocumentTypeFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): DocumentTypeFakeClient {
		return new DocumentTypeFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
