import { NgModule } from '@angular/core';
import { UserConfigurationDirective } from '../components/user-configuration.directive';
import { declarations } from './configurations/declarations';
import { imports } from './configurations/imports';
import { providers } from './configurations/providers';

@NgModule({
	declarations,
	imports,
	providers,
	exports: [
		UserConfigurationDirective
	]
})
export class UserConfigurationModule { }
