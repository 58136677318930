import {
	GeneticFileParseRequestClient, GeneticFileParseRequestCreateParameterDto, GeneticFileParseRequestDto,
	GeneticFileParseRequestFilterDto, GeneticFileParseRequestFindParameterDto, GeneticFileParseRequestUpdateParameterDto
} from '@nmn-communication/genetic-file-parse-requests';
import { Failure, PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { wrapActionCallAsFakeClientResponse, wrapFuncCallAsFakeClientResponse } from '../../../../modules/fakes/databases/fake.utils';
import { GeneticFileParseRequestFakeClientConfiguration } from '../configurations/genetic-file-parse-request.fake-client.configuration';

export class GeneticFileParseRequestFakeClient extends GeneticFileParseRequestClient {

	constructor(
		private readonly configuration: GeneticFileParseRequestFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulk(
		filterParameter: PageOptionsDto<GeneticFileParseRequestFilterDto>
	): Observable<Result<PagedCollectionDto<GeneticFileParseRequestDto, GeneticFileParseRequestFilterDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.geneticFileParseRequestsTable.getPagedCollection(filterParameter),
			this.configuration.serverDelayMs
		);
	}

	public get(
		findParameter: GeneticFileParseRequestFindParameterDto
	): Observable<Result<GeneticFileParseRequestDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.geneticFileParseRequestsTable.get(findParameter),
			this.configuration.serverDelayMs
		);
	}

	public create(
		parameter: GeneticFileParseRequestCreateParameterDto
	): Observable<Result<GeneticFileParseRequestDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.geneticFileParseRequestsTable.create(parameter),
			this.configuration.serverDelayMs
		);
	}

	public update(
		findParameter: GeneticFileParseRequestFindParameterDto,
		parameter: GeneticFileParseRequestUpdateParameterDto
	): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => this.fakeDatabase.geneticFileParseRequestsTable.update(findParameter, parameter),
			this.configuration.serverDelayMs
		);
	}

}
