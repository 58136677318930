import { toGB, toKB, toMB } from '@nmn-core/utils';
import { FileType } from './file-type.enum';
import { UploadedFileExtensionComboboxModel } from './uploaded-file-extension.combobox.model';

export class UploadedFileModel {

	public readonly id: string;
	public readonly downloadUrl: string;
	public readonly name: string;
	public readonly extension: UploadedFileExtensionComboboxModel;
	public readonly sizeInBytes: number;
	public readonly createdOn: Date;
	public readonly updatedOn: Date;
	public readonly fileType: FileType;

	public get sizeInGB(): number {
		return toGB(this.sizeInBytes);
	}

	public get sizeInMB(): number {
		return toMB(this.sizeInBytes);
	}

	public get sizeInKB(): number {
		return toKB(this.sizeInBytes);
	}

	constructor(
		id: string,
		downloadUrl: string,
		name: string,
		extension: UploadedFileExtensionComboboxModel,
		sizeInBytes: number,
		createdOn: Date,
		updatedOn: Date,
		fileType: FileType
	) {
		// TODO: Guard check on defined

		this.id = id;
		this.downloadUrl = downloadUrl;
		this.name = name;
		this.extension = extension;
		this.sizeInBytes = sizeInBytes;
		this.createdOn = createdOn;
		this.updatedOn = updatedOn;
		this.fileType = fileType;
	}

}
