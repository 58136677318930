import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HealthIssueModel } from '../models/health-issue.model';

export abstract class HealthIssueResolver implements Resolve<HealthIssueModel> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	): Observable<HealthIssueModel> | HealthIssueModel;

}
