import { isValueDefined } from '@nmn-core/utils';
import { NmnMatTableUserConfiguration } from '../component-configurations/nmn-mat-table-user.configuration';

export class TakenMedicationsPageConfiguration {

	public readonly takenMedicationsTableUserConfiguration: NmnMatTableUserConfiguration;

	protected constructor(
		takenMedicationsTableUserConfiguration: NmnMatTableUserConfiguration
	) {
		this.takenMedicationsTableUserConfiguration = takenMedicationsTableUserConfiguration;
	}

	public static createWithParameters(
		config?: TakenMedicationsPageConfiguration
	): TakenMedicationsPageConfiguration {
		if (!isValueDefined(config)) {
			TakenMedicationsPageConfiguration.createDefault();
		}

		return new TakenMedicationsPageConfiguration(
			isValueDefined(config?.takenMedicationsTableUserConfiguration) ?
				NmnMatTableUserConfiguration.createWithParameters(config.takenMedicationsTableUserConfiguration) :
				NmnMatTableUserConfiguration.createDefault()
		);
	}

	public static createDefault(
	): TakenMedicationsPageConfiguration {
		return new TakenMedicationsPageConfiguration(
			NmnMatTableUserConfiguration.createWithParameters(
				new NmnMatTableUserConfiguration(undefined, undefined, undefined, 'desc', 'medicationAdmStatusId')
			)
		);
	}

}
