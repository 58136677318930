import { Injector, Provider } from '@angular/core';
import { DocumentTypeClient } from '@nmn-communication/document-types';
import { documentTypeClientFactory } from './factories/document-type.client.factory';

export const documentTypeProviders: Array<Provider> = [
	{
		provide: DocumentTypeClient,
		useFactory: documentTypeClientFactory,
		deps: [Injector]
	}
];
