import { DescribedComboboxModel } from '../../shared/comboboxes/described-combobox.model';
import { MedicationAdmRouteType } from './medication-adm-route-type.enum';

export class MedicationAdmRouteDescribedComboboxModel extends DescribedComboboxModel {

	public readonly type: MedicationAdmRouteType;

	constructor(
		id: string,
		displayText: string,
		description: string,
		type: MedicationAdmRouteType
	) {

		super(id, displayText, description);

		// TODO: Guard check on defined
		this.type = type;

	}

}
