export class AttachNativeLoginDialogFormInModel {

	public readonly password?: string;
	public readonly confirmPassword?: string;

	private constructor(
		password: string | undefined,
		confirmPassword: string | undefined
	) {
		this.password = password;
		this.confirmPassword = confirmPassword;
	}

	public static createEmpty = (): AttachNativeLoginDialogFormInModel => {
		return new AttachNativeLoginDialogFormInModel(undefined, undefined);
	}

}
