import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { HealthIssueGetQuery, HealthIssueModel, HealthIssueQueryHandlerService, HealthIssueResolver } from '@nmn-domain/health-issues';
import { RouteParameter } from '@nmn-middleware/navigation';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HealthIssueViaQueryHandlerResolver extends HealthIssueResolver {

	constructor(
		private readonly healthIssueQueryHandler: HealthIssueQueryHandlerService
	) {
		super();
	}

	public resolve(activatedRoute: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<HealthIssueModel> | HealthIssueModel {
		const patientId = activatedRoute.paramMap.get(RouteParameter.PatientAlias);
		const healthIssueId = activatedRoute.paramMap.get(RouteParameter.HealthIssueId);

		if (!isStringDefinedAndNotEmpty(healthIssueId) || !isStringDefinedAndNotEmpty(patientId)) {
			return undefined;
		}

		return this.healthIssueQueryHandler
			.get(HealthIssueGetQuery.createById(healthIssueId, patientId))
			.pipe(
				map(result => result.successOrDefault(() => undefined))
			);
	}

}
