import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FailureModel } from '../../shared/failures/failure.model';
import { GeneVariantComboboxModel } from '../models/gene-variant-combobox.model';
import { GeneVariantFilterModel } from '../models/gene-variant-filter.model';

export abstract class GeneVariantQueryHandlerService {

	public abstract getAsComboboxes(query: GeneVariantFilterModel): Observable<Result<Array<GeneVariantComboboxModel>, FailureModel>>;

}
