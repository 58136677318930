export class HealthIssueGetQuery {

	public readonly id: string;
	public readonly patientId: string;

	private constructor(
		id: string,
		patientId: string
	) {
		// TODO: add null check
		this.id = id;
		this.patientId = patientId;
	}

	public static createById(id: string, patientId: string): HealthIssueGetQuery {
		return new HealthIssueGetQuery(id, patientId);
	}

}
