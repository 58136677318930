export const getCurrentBrowser = (): string => {
	const userAgent = navigator.userAgent;
	let match = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
	let tem: Array<string>;

	if (/trident/i.test(match[1])) {
		tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];

		return 'IE ' + (tem[1] || '');
	}

	if (match[1] === 'Chrome') {
		tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);

		if (tem != null) {
			return tem.slice(1).join(' ').replace('OPR', 'Opera');
		}
	}

	match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, '-?'];

	if ((tem = userAgent.match(/version\/(\d+)/i)) != null) {
		match.splice(1, 1, tem[1]);
	}

	return match.join(' ');
};

export const getCurrentBrowserTimezone = (): string => {
	return Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
};

export const getCurrentBrowserTimezoneOffset = (): number => {
	return new Date()?.getTimezoneOffset();
};
