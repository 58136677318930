
import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { DocumentHttpClientConfiguration } from '../configurations/document.http-client.configuration';

export class DocumentHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<DocumentHttpClientConfiguration> {

	public build(): DocumentHttpClientConfiguration {
		return new DocumentHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
