import { ContactsClient, ContactsDto, SendMessageParameterDto } from '@nmn-communication/contacts';
import { Failure } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { wrapActionCallAsFakeClientResponse } from 'app/modules/fakes/databases/fake.utils';
import { Observable, of } from 'rxjs';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { ContactsFakeClientConfiguration } from '../configurations/contacts.fake-client.configuration';
import { contactsData } from './contacts.http-client';

export class ContactsFakeClient extends ContactsClient {

	constructor(
		private readonly configuration: ContactsFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public sendMessage(_: SendMessageParameterDto): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => { },
			this.configuration.serverDelayMs);
	}

	public getContacts(): Observable<Result<ContactsDto, Failure>> {
		// TODO: NOT IMPLEMENTED

		return of(Result.success<ContactsDto, Failure>(contactsData));
	}

}
