import { DescribedComboboxModel } from '../../shared/comboboxes/described-combobox.model';
import { MedicationBodySiteType } from './medication-body-site-type.enum';

export class MedicationBodySiteDescribedComboboxModel extends DescribedComboboxModel {

	public readonly type: MedicationBodySiteType;

	constructor(
		id: string,
		displayText: string,
		description: string,
		type: MedicationBodySiteType
	) {

		super(id, displayText, description);

		// TODO: Guard check on defined
		this.type = type;

	}

}
