import { isValueDefined } from '@nmn-core/utils';

export enum DiseaseType {

	Undefined = 'undefined',

	CertainInfectiousAndParasiticDiseases = 'infectious',
	Neoplasms = 'neoplasm',
	DiseasesOfTheBloodAndBloodFormingOrgansAndCertainDisordersInvolvingTheImmuneMechanism = 'Blood',
	EndocrineNutritionalAndMetabolicDiseases = 'endocrine',
	MentalAndBehaviouralDisorders = 'mental',
	DiseasesOfTheNervousSystem = 'nerve',
	DiseasesOfTheEyeAndAdnexa = 'eye',
	DiseasesOfTheEarAndMastoidProcess = 'ear',
	DiseasesOfTheCirculatorySystem = 'circulatory',
	DiseasesOfTheRespiratorySystem = 'respiratory',
	DiseasesOfTheDigestiveSystem = 'digestive',
	DiseasesOfTheSkinAndSubcutaneousTissueher = 'skin',
	DiseasesOfTheMusculoskeletalSystemAndConnectiveTissue = 'muscle',
	DiseasesOfTheGenitourinarySystem = 'genitourinary',
	PregnancyChildbirthAndThePuerperium = 'pregnancy',
	CertainConditionsOriginatingInThePerinatalPeriod = 'perinatal',
	CongenitalMalformationsDeformationsAndChromosomalAbnormalities = 'congenital',
	SymptomsSignsAndAbnormalClinicalAndLaboratoryFindingsNotElsewhereClassified = 'symptoms',
	InjuryPoisoningAndCertainOtherConsequencesOfExternalCauses = 'injury',
	ExternalCausesOfMorbidityAndMortality = 'morbidity',
	FactorsInfluencingHealthStatusAndContactWithHealthServices = 'other',
	CodesForSpecialPurposes = 'specific'

}

export const mapDiseaseTypeFromString = (stringValue: string): DiseaseType => {
	const enumValue = Object
		.keys(DiseaseType)
		.find(x => DiseaseType[x] === stringValue);

	return isValueDefined(enumValue) ? stringValue as DiseaseType : DiseaseType.Undefined;
};
