import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';

export class DoctorModel {

	public readonly fullName?: string;
	public readonly contacts?: string;

	public get isFullNameDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.fullName);
	}

	public get isContactsDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.contacts);
	}

	constructor(
		fullName: string | undefined,
		contacts: string | undefined
	) {
		this.fullName = fullName;
		this.contacts = contacts;
	}

}
