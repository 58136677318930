import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { BloodTypeHttpClientConfiguration } from '../configurations/blood-type.http-client.configuration';

export class BloodTypeHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<BloodTypeHttpClientConfiguration> {

	public build(): BloodTypeHttpClientConfiguration {
		return new BloodTypeHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
