export class FailureTextDirectoryModel {

	public readonly failureHint: string;

	constructor(
		failureHint: string
	) {
		// TODO: Guard
		this.failureHint = failureHint;
	}

}
