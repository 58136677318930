import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FailureModel } from '../../shared/failures/failure.model';
import { MedicationAdmStatusDescribedComboboxModel, MedicationAdmStatusFilterModel } from '../index';

export abstract class MedicationAdmStatusQueryHandlerService {

	public abstract getAsDescribedComboboxes(
		query: MedicationAdmStatusFilterModel
	): Observable<Result<Array<MedicationAdmStatusDescribedComboboxModel>, FailureModel>>;

}
