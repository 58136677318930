import { TranslocoService } from '@ngneat/transloco';
import { DocumentTypeDescribedComboboxDto } from '@nmn-communication/document-types';
import { isValueDefined } from '@nmn-core/utils';
import { setTranslation } from '../../utils/localize';
import { FakeDescribedComboboxDto } from './fake-described-combobox.dto';

export interface FakeDocumentTypeDescribedComboboxDto extends FakeDescribedComboboxDto<string> {

}

export const mapFakeDocumentTypeDescriptedComboboxDtoToDocumentTypeDescriptedComboboxDto = (
	translocoService: TranslocoService,
	fakeDescribedComboboxDto: FakeDocumentTypeDescribedComboboxDto
): DocumentTypeDescribedComboboxDto => {
	if (!isValueDefined(fakeDescribedComboboxDto)) {
		return undefined;
	}

	return {
		id: fakeDescribedComboboxDto.id,
		displayText: setTranslation(translocoService, fakeDescribedComboboxDto.displayText),
		description: setTranslation(translocoService, fakeDescribedComboboxDto.description)
	};

};
