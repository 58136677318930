import { isValueDefined } from '@nmn-core/utils';
import { ServerConfigurationFakeClientConfiguration } from '../configurations/server-configuration-fake-client.configuration';

export class ServerConfigurationFakeClientConfigurationBuilder {

	private readonly serverDelayMs: { min: number, max: number } = { min: 0, max: 0 };

	public withServerDelay(
		serverDelayMs: { min: number, max: number }
	): ServerConfigurationFakeClientConfigurationBuilder {

		if (isValueDefined(serverDelayMs) &&
			isValueDefined(serverDelayMs.min) &&
			isValueDefined(serverDelayMs.max) &&
			serverDelayMs.min <= serverDelayMs.max) {

			this.serverDelayMs.min = serverDelayMs.min;
			this.serverDelayMs.max = serverDelayMs.max;

		}

		return this;

	}

	public build(): ServerConfigurationFakeClientConfiguration {
		return new ServerConfigurationFakeClientConfiguration(this.serverDelayMs);
	}

}
