import { Injector, Provider } from '@angular/core';
import { MedicationClient } from '@nmn-communication/medications';
import { medicationClientFactory } from './factories/medication.client.factory';

export const medicationProviders: Array<Provider> = [
	{
		provide: MedicationClient,
		useFactory: medicationClientFactory,
		deps: [Injector]
	}
];
