import { Injectable } from '@angular/core';
import { GeneticTestClient } from '@nmn-communication/genetic-tests';
import { Result } from '@nmn-core/shared';
import { GeneticCardGetQuery, GeneticCardModel, GeneticTestFilterModel, GeneticTestGetQuery, GeneticTestModel, GeneticTestQueryHandlerService } from '@nmn-domain/genetic-tests';
import { FailureModel, PagedCollectionModel, PageOptionsQuery } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapPageOptionsQueryToDto } from '../../shared/factories/pagination.factory';
import {
	mapFilterModelToDto,
	mapGeneticCardDtoToModel,
	mapGeneticCardGetQueryToParameter,
	mapGeneticTestDtoToModel,
	mapGeneticTestGetQueryToParameter,
	mapGeneticTestPagedCollectionDtoToModel
} from '../factories/genetic-test.factory';

@Injectable()
export class GeneticTestQueryHandlerViaClientService extends GeneticTestQueryHandlerService {

	constructor(
		private readonly client: GeneticTestClient
	) {
		super();
	}

	public handleGeneticCardGetQuery(query: GeneticCardGetQuery): Observable<Result<GeneticCardModel, FailureModel>> {
		return this.client
			.getGeneticCard(mapGeneticCardGetQueryToParameter(query))
			.pipe(
				map(result => result.map(mapGeneticCardDtoToModel, mapFailureToFailureModel))
			);

	}

	public handleGetQuery(query: GeneticTestGetQuery): Observable<Result<GeneticTestModel, FailureModel>> {
		return this.client
			.get(mapGeneticTestGetQueryToParameter(query))
			.pipe(
				map(result => result.map(mapGeneticTestDtoToModel, mapFailureToFailureModel))
			);
	}

	public getPagedCollection(query: PageOptionsQuery<GeneticTestFilterModel>): Observable<Result<PagedCollectionModel<GeneticTestModel, GeneticTestFilterModel>, FailureModel>> {
		return this.client
			.getBulk(mapPageOptionsQueryToDto(query, mapFilterModelToDto))
			.pipe(
				map(result => result.map(mapGeneticTestPagedCollectionDtoToModel, mapFailureToFailureModel))
			);
	}

}
