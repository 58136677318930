import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { TreatmentCheckerHttpClientConfiguration } from '../configurations/treatment-checker-http-client.configuration';

export class TreatmentCheckerHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<TreatmentCheckerHttpClientConfiguration> {

	public build(): TreatmentCheckerHttpClientConfiguration {
		return new TreatmentCheckerHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
