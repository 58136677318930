import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MedicationAdmStatusesResolver, MedicationAdmStatusFilterModel, MedicationAdmStatusQueryHandlerService } from '@nmn-domain/medication-adm-statuses';
import { DescribedComboboxModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MedicationAdmStatusesViaQueryHandlerResolver extends MedicationAdmStatusesResolver {

	constructor(
		private readonly queryHandlerService: MedicationAdmStatusQueryHandlerService
	) {
		super();
	}

	public resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<Array<DescribedComboboxModel>> | Array<DescribedComboboxModel> {
		return this.queryHandlerService
			.getAsDescribedComboboxes(MedicationAdmStatusFilterModel.createEmpty())
			.pipe(map(result => result.successOrDefault(() => [])));
	}

}
