import { Injector, Provider } from '@angular/core';
import { ContactsClient } from '@nmn-communication/contacts';
import { contactsClientFactory } from './factories/contacts.client.factory';

export const contactsProviders: Array<Provider> = [
	{
		provide: ContactsClient,
		useFactory: contactsClientFactory,
		deps: [Injector]
	}
];
