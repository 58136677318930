import { InformationBlockModel } from '../../../../../modules/core/models/shared/information-block.model';

export class GeneticTestCardInformationBlockModel extends InformationBlockModel {

	public readonly count: number;

	constructor(
		fillingUpPercentage: number,
		count: number,
	) {

		super(fillingUpPercentage);

		// TODO: add Guard check

		this.count = count;

	}
}
