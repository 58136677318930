import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { GeneticTestHttpClientConfiguration } from '../configurations/genetic-test.http-client.configuration';

export class GeneticTestHttpClientConfigurationBuilder extends HttpClientConfigurationBuilder<GeneticTestHttpClientConfiguration> {

	public build(): GeneticTestHttpClientConfiguration {
		return new GeneticTestHttpClientConfiguration(this.baseUrl, this.resourceRelativePath, this.resourceTypeVersion);
	}

}
