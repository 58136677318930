import { AllergyIntoleranceStatusFilterDto } from '@nmn-communication/allergy-intolerance-statuses';
import { AllergyIntoleranceStatusFilterModel } from '@nmn-domain/allergy-intolerance-statuses';

export const mapFilterModelToDto = (model: AllergyIntoleranceStatusFilterModel): AllergyIntoleranceStatusFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern
	}
);

export const mapFilterDtoToModel = (dto: AllergyIntoleranceStatusFilterDto): AllergyIntoleranceStatusFilterModel => (
	new AllergyIntoleranceStatusFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern
	)
);
