import { DiseaseComboboxModel } from '@nmn-domain/diseases';
import { VaccineDescribedComboboxModel } from '@nmn-domain/vaccines';
import { VaccinationModel } from '../vaccination.model';

export class VaccinationFormInModel {

	public readonly patientId: string;
	public readonly vaccinationDate?: Date;
	public readonly vaccine?: VaccineDescribedComboboxModel;
	public readonly vaccinatedFromDiseases: Array<DiseaseComboboxModel>;
	public readonly comment?: string;
	public readonly numberInSeriesOfDoses: number;
	public readonly memberStateOfVaccination?: string;
	public readonly certificateIssuer?: string;
	public readonly certificateUid?: string;
	public readonly expirationDate?: Date;

	private constructor(
		patientId: string,
		vaccinationDate: Date | undefined,
		vaccine: VaccineDescribedComboboxModel | undefined,
		vaccinatedFromDiseases: Array<DiseaseComboboxModel>,
		comment: string | undefined,
		numberInSeriesOfDoses: number,
		memberStateOfVaccination: string | undefined,
		certificateIssuer: string | undefined,
		certificateUid: string | undefined,
		expirationDate: Date | undefined
	) {
		// TODO: Guard.isDefined

		this.patientId = patientId;
		this.vaccinationDate = vaccinationDate;
		this.vaccine = vaccine;
		this.vaccinatedFromDiseases = vaccinatedFromDiseases;
		this.comment = comment;
		this.numberInSeriesOfDoses = numberInSeriesOfDoses;
		this.memberStateOfVaccination = memberStateOfVaccination;
		this.certificateIssuer = certificateIssuer;
		this.certificateUid = certificateUid;
		this.expirationDate = expirationDate;
	}

	public static createFromExistingModel = (model: VaccinationModel): VaccinationFormInModel => {
		// TODO: Guard.isDefined(model);

		return new VaccinationFormInModel(
			model.patientId,
			model.vaccinationDate,
			model.vaccine,
			model.vaccinatedFromDiseases,
			model.comment,
			model.numberInSeriesOfDoses,
			model.memberStateOfVaccination,
			model.certificateIssuer,
			model.certificateUid,
			model.expirationDate
		);
	}

	public static createEmpty = (patientId: string): VaccinationFormInModel => {
		// TODO: Guard.isDefined(patientId);

		return new VaccinationFormInModel(
			patientId,
			undefined,
			undefined,
			[],
			undefined,
			1,
			undefined,
			undefined,
			undefined,
			undefined
		);
	}

}
