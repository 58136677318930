import { ComboboxModel } from '@nmn-domain/shared';

export class LocaleComboboxModel extends ComboboxModel {

	constructor(
		id: string,
		displayText: string
	) {
		super(id, displayText);
	}

}
