import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
	GeneticFileParseRequestClient, GeneticFileParseRequestCreateParameterDto, GeneticFileParseRequestDto,
	GeneticFileParseRequestFilterDto, GeneticFileParseRequestFindParameterDto, GeneticFileParseRequestUpdateParameterDto
} from '@nmn-communication/genetic-file-parse-requests';
import { Failure, PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { constructHttpParams, injectParameters } from '@nmn-core/utils';
import { Observable } from 'rxjs';
import {
	createEmptyResultFromResponse,
	createFailedEmptyResultFromErrorResponse,
	createFailedResultFromErrorResponse,
	createResultFromResponse
} from '../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { GeneticFileParseRequestHttpClientConfiguration } from '../configurations/genetic-file-parse-request.http-client.configuration';

export class GeneticFileParseRequestHttpClient extends GeneticFileParseRequestClient {

	constructor(
		private readonly configuration: GeneticFileParseRequestHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public getBulk(
		filterParameter: PageOptionsDto<GeneticFileParseRequestFilterDto>
	): Observable<Result<PagedCollectionDto<GeneticFileParseRequestDto, GeneticFileParseRequestFilterDto>, Failure>> {
		const parameters = {
			patientId: filterParameter.filter.patientId
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`,
			parameters);
		const params = constructHttpParams(filterParameter);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<PagedCollectionDto<GeneticFileParseRequestDto, GeneticFileParseRequestFilterDto>>(url, { params, headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public get(
		findParameter: GeneticFileParseRequestFindParameterDto
	): Observable<Result<GeneticFileParseRequestDto, Failure>> {
		const parameters = {
			patientId: findParameter.patientId
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/${findParameter.id}`,
			parameters);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<GeneticFileParseRequestDto>(url, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public create(
		parameter: GeneticFileParseRequestCreateParameterDto
	): Observable<Result<GeneticFileParseRequestDto, Failure>> {
		const parameters = {
			patientId: parameter.patientId
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`,
			parameters);
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post<GeneticFileParseRequestDto>(url, payload, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

	public update(
		findParameter: GeneticFileParseRequestFindParameterDto,
		parameter: GeneticFileParseRequestUpdateParameterDto
	): Observable<EmptyResult<Failure>> {
		const parameters = {
			patientId: findParameter.patientId
		};
		const url = injectParameters(
			`${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/${findParameter.id}`,
			parameters);
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put<PagedCollectionDto<GeneticFileParseRequestDto, GeneticFileParseRequestFilterDto>>(url, payload, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

}
