import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { MedicationRateQuantityTypeHttpClientConfiguration } from '../configurations/medication-rate-quantity-type.http-client.configuration';
import { MedicationRateQuantityTypeHttpClient } from '../services/medication-rate-quantity-type.http-client';

export class MedicationRateQuantityTypeHttpClientBuilder
	extends HttpClientBuilder<MedicationRateQuantityTypeHttpClient, MedicationRateQuantityTypeHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): MedicationRateQuantityTypeHttpClient {
		return new MedicationRateQuantityTypeHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
