import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { GeneticTestResultFormOutModel } from '../genetic-test-form/genetic-test-result-form.out-model';

export class GeneticTestResultCreateCommand {

	public readonly variantId: string;
	public readonly haplotypeId?: string;
	public readonly diplotypeId?: string;
	public readonly pharmacogenomicTestResultCustom?: string;

	private constructor(
		variantId: string,
		haplotypeId: string | undefined,
		diplotypeId: string | undefined,
		pharmacogenomicTestResultCustom: string | undefined
	) {
		//TODO: Guard check on defined
		this.variantId = variantId;

		this.haplotypeId = haplotypeId;
		this.diplotypeId = diplotypeId;
		this.pharmacogenomicTestResultCustom = pharmacogenomicTestResultCustom;
	}

	public static createFromFormOutModel = (model: GeneticTestResultFormOutModel): GeneticTestResultCreateCommand => {
		// TODO: Guard.isDefined(model);

		return new GeneticTestResultCreateCommand(
			isStringDefinedAndNotEmpty(model.diplotypeId) ? model.diplotypeId : model.haplotypeId,
			model.haplotypeId,
			model.diplotypeId,
			model.pharmacogenomicTestResultCustom
		);
	}

}
