export class PatientGeneralInfoFormOutModel {

	public readonly picture: string;
	public readonly givenName: string;
	public readonly additionalName: string;
	public readonly familyName: string;
	public readonly populationId: string;
	public readonly genderId: string;
	public readonly isPregnant?: boolean;
	public readonly birthday: Date;
	public readonly phoneNumber: string;
	public readonly email: string;
	public readonly address: string;
	public readonly languageIds: Array<string>;
	public readonly preferredLanguageId: string;

	constructor(
		picture: string | undefined,
		givenName: string,
		additionalName: string | undefined,
		familyName: string,
		populationId: string,
		genderId: string,
		isPregnant: boolean | undefined,
		birthday: Date,
		email: string,
		phoneNumber: string | undefined,
		address: string | undefined,
		languageIds: Array<string>,
		preferredLanguageId: string
	) {
		this.picture = picture;
		this.givenName = givenName;
		this.additionalName = additionalName;
		this.familyName = familyName;
		this.populationId = populationId;
		this.genderId = genderId;
		this.isPregnant = isPregnant;
		this.birthday = birthday;
		this.email = email;
		this.phoneNumber = phoneNumber;
		this.address = address;
		this.languageIds = languageIds;
		this.preferredLanguageId = preferredLanguageId;
	}

}
