import { isArrayDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { TreatmentCheckerReportCautionModel } from './report-parts/treatment-checker-report-caution.model';
import { TreatmentCheckerReportDiseaseReportModel } from './report-parts/treatment-checker-report-disease-report.model';
import { TreatmentCheckerReportInteractionReportModel } from './report-parts/treatment-checker-report-interaction-report.model';
import { TreatmentCheckerReportMedicationReportModel } from './report-parts/treatment-checker-report-medication-report.model';
import { TreatmentCheckerReportTreatmentInfoModel } from './report-parts/treatment-checker-report-treatment-info.model';

export class TreatmentCheckerReportModel {

	public readonly id: string;
	public readonly createdOn: Date;
	public readonly treatmentInfo: TreatmentCheckerReportTreatmentInfoModel;
	public readonly cautions: Array<TreatmentCheckerReportCautionModel>;
	public readonly diseaseReports: Array<TreatmentCheckerReportDiseaseReportModel>;
	public readonly medicationReports: Array<TreatmentCheckerReportMedicationReportModel>;
	public readonly interactionReports: Array<TreatmentCheckerReportInteractionReportModel>;

	public get isTreatmentInfoDefined(): boolean {
		return isValueDefined(this.treatmentInfo);
	}

	public get isCautionsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.cautions);
	}

	public get isDiseaseReportsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.diseaseReports);
	}

	public get isMedicationReportsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.medicationReports);
	}

	public get isInteractionReportsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.interactionReports);
	}

	constructor(
		id: string,
		createdOn: Date,
		treatmentInfo: TreatmentCheckerReportTreatmentInfoModel,
		cautions: Array<TreatmentCheckerReportCautionModel>,
		diseaseReports: Array<TreatmentCheckerReportDiseaseReportModel>,
		interactionReports: Array<TreatmentCheckerReportInteractionReportModel>,
		medicationReports: Array<TreatmentCheckerReportMedicationReportModel>
	) {
		// TODO: Guard check on defined

		this.id = id;
		this.createdOn = createdOn;
		this.treatmentInfo = treatmentInfo;
		this.cautions = cautions;
		this.diseaseReports = diseaseReports;
		this.interactionReports = interactionReports;
		this.medicationReports = medicationReports;
	}

}
