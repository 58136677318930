import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { MedicationAdmStatusDescribedComboboxDto } from '../models/medication-adm-status-described-combobox.dto';
import { MedicationAdmStatusFilterDto } from '../models/medication-adm-status-filter.dto';

export abstract class MedicationAdmStatusClient {

	public abstract getBulkAsComboboxes(
		filter: MedicationAdmStatusFilterDto
	): Observable<Result<Array<MedicationAdmStatusDescribedComboboxDto>, Failure>>;

}
