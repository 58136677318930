import { DoctorEncounterComboboxModel } from '../../../doctor-encounters';
import { HealthIssueComboboxModel } from '../../../health-issues';
import { DescribedComboboxModel } from '../../../shared';
import { TakenMedicationDescribedComboboxModel } from '../../../taken-medications';
import { DocumentAttachmentModel, DocumentModel } from '../document.model';

export class DocumentFormInModel {

	public readonly patientId: string;
	public readonly name: string | undefined;
	public readonly description: string | undefined;
	public readonly type: DescribedComboboxModel | undefined;
	public readonly attachments: Array<DocumentAttachmentModel>;
	public readonly relatedEncounters: Array<DoctorEncounterComboboxModel>;
	public readonly relatedTakenMedications: Array<TakenMedicationDescribedComboboxModel>;
	public readonly relatedHealthIssues: Array<HealthIssueComboboxModel>;

	private constructor(
		patientId: string,
		name: string | undefined,
		description: string | undefined,
		type: DescribedComboboxModel | undefined,
		attachments: Array<DocumentAttachmentModel>,
		relatedEncounters: Array<DoctorEncounterComboboxModel>,
		relatedTakenMedications: Array<TakenMedicationDescribedComboboxModel>,
		relatedHealthIssues: Array<HealthIssueComboboxModel>
	) {
		// TODO: Guard check on defined: patientId and relations

		this.patientId = patientId;
		this.name = name;
		this.description = description;
		this.type = type;
		this.attachments = attachments;
		this.relatedEncounters = relatedEncounters;
		this.relatedTakenMedications = relatedTakenMedications;
		this.relatedHealthIssues = relatedHealthIssues;
	}

	public static createEmpty = (patientId: string): DocumentFormInModel => {
		// TODO: Guard.isDefined(patientId);

		return new DocumentFormInModel(
			patientId,
			undefined,
			undefined,
			undefined,
			[],
			[],
			[],
			[]
		);
	}

	public static createFromExistingModel(model: DocumentModel): DocumentFormInModel {
		return new DocumentFormInModel(
			model.patientId,
			model.name,
			model.description,
			model.type,
			model.attachments,
			model.relatedEncounters,
			model.relatedTakenMedications,
			model.relatedHealthIssues
		);
	}

}
