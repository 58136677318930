import { PricingPlanType, SubscriptionType } from '@nmn-domain/subscriptions';

export class UserSubscriptionUpdateCommand {

	public readonly userId: string;
	public readonly isCancelled: boolean;
	public readonly subscriptionId: SubscriptionType;
	public readonly pricingPlanId: PricingPlanType;

	private constructor(
		userId: string,
		isCancelled: boolean,
		subscriptionId: SubscriptionType,
		pricingPlanId: PricingPlanType
	) {

		// TODO: Guard check on defined
		this.userId = userId;
		this.isCancelled = isCancelled;
		this.subscriptionId = subscriptionId;
		this.pricingPlanId = pricingPlanId;
	}

	public static create(
		userId: string,
		isCancelled: boolean,
		subscriptionId: SubscriptionType,
		pricingPlanId: PricingPlanType
	): UserSubscriptionUpdateCommand {
		return new UserSubscriptionUpdateCommand(
			userId,
			isCancelled,
			subscriptionId,
			pricingPlanId
		);
	}

}
