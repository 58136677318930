import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { MedicationHttpClientConfiguration } from '../configurations/medication.http-client.configuration';
import { MedicationHttpClient } from '../services/medication.http-client';

export class MedicationHttpClientBuilder
	extends HttpClientBuilder<MedicationHttpClient, MedicationHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): MedicationHttpClient {
		return new MedicationHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
