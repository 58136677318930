import { Injectable } from '@angular/core';
import { DocumentClient } from '@nmn-communication/documents';
import { Result } from '@nmn-core/shared';
import {
	DocumentCreateCommand, DocumentDeleteAttachmentCommand, DocumentDeleteBulkCommand,
	DocumentDeleteCommand, DocumentsCommandHandlerService, DocumentUpdateCommand
} from '@nmn-domain/documents';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommandResult, EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapCreateCommandToParameter, mapUpdateCommandToParameter } from '../factories/document.factory';

@Injectable()
export class DocumentsCommandHandlerViaClientService extends DocumentsCommandHandlerService {

	constructor(
		private readonly client: DocumentClient
	) {
		super();
	}

	public handleCreateCommand(command: DocumentCreateCommand): Observable<Result<CommandResult<DocumentCreateCommand, string>, FailureModel>> {
		return this.client
			.create(mapCreateCommandToParameter(command))
			.pipe(
				map(result => result.map(success => new CommandResult(command, success), mapFailureToFailureModel))
			);
	}

	public handleDeleteBulkCommand(command: DocumentDeleteBulkCommand): Observable<Result<EmptyCommandResult<DocumentDeleteBulkCommand>, FailureModel>> {
		return this.client
			.deleteBulk({ ids: command.ids, patientId: command.patientId })
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

	public handleDeleteCommand(command: DocumentDeleteCommand): Observable<Result<EmptyCommandResult<DocumentDeleteCommand>, FailureModel>> {
		return this.client
			.delete({ id: command.id, patientId: command.patientId })
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

	public handleDeleteAttachmentCommand(command: DocumentDeleteAttachmentCommand): Observable<Result<EmptyCommandResult<DocumentDeleteAttachmentCommand>, FailureModel>> {
		return this.client
			.deleteAttachment({ id: command.id, patientId: command.patientId }, { attachmentId: command.attachmentId })
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

	public handleUpdateCommand(command: DocumentUpdateCommand): Observable<Result<EmptyCommandResult<DocumentUpdateCommand>, FailureModel>> {
		return this.client
			.update({ id: command.id, patientId: command.patientId }, mapUpdateCommandToParameter(command))
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

}
