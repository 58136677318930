import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { ComboboxModel } from '@nmn-domain/shared';

export class GeneVariantFilterModel {

	public readonly ids?: Array<string>;
	public readonly ignoreIds?: Array<string>;
	public readonly searchPattern?: string;
	public readonly geneId: string;
	public readonly allele1?: string;

	constructor(
		ids: Array<string> | undefined,
		ignoreIds: Array<string> | undefined,
		searchPattern: string | undefined,
		geneId: string | undefined,
		allele1: string | undefined
	) {
		this.ids = ids;
		this.ignoreIds = ignoreIds;
		this.searchPattern = searchPattern;
		this.geneId = geneId;
		this.allele1 = allele1;
	}

	public static createForGeneVariantAutocomplete(
		searchPattern: string,
		selectedItem: ComboboxModel,
		geneId: string,
		allele1: string | undefined,
		ignoreIds: Array<string> | undefined
	): GeneVariantFilterModel {
		const excludeIds = isStringDefinedAndNotEmpty(selectedItem?.id) ? isArrayDefinedAndNotEmpty(ignoreIds) ? [...ignoreIds, selectedItem.id] : [selectedItem.id] : ignoreIds;

		return new GeneVariantFilterModel(undefined, excludeIds, searchPattern, geneId, allele1);
	}

	public static createForGeneticVariantsAutocomplete(
		searchPattern: string,
		ignoreIds: Array<string>,
		geneId: string,
		allele1: string | undefined
	): GeneVariantFilterModel {
		return new GeneVariantFilterModel(undefined, ignoreIds, searchPattern, geneId, allele1);
	}

}
