import { MedicationBodySiteClient, MedicationBodySiteDescribedComboboxDto, MedicationBodySiteFilterDto } from '@nmn-communication/medication-body-sites';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { wrapFuncCallAsFakeClientResponse } from '../../../../modules/fakes/databases/fake.utils';
import { MedicationBodySiteFakeClientConfiguration } from '../configurations/medication-body-site.fake-client.configuration';

export class MedicationBodySiteFakeClient extends MedicationBodySiteClient {

	constructor(
		private readonly configuration: MedicationBodySiteFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulkAsComboboxes(filter: MedicationBodySiteFilterDto): Observable<Result<Array<MedicationBodySiteDescribedComboboxDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.medicationBodySitesTable.getComboboxesPagedCollection(filter),
			this.configuration.serverDelayMs
		);
	}

}
