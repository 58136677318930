import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { Translation, translocoConfig, TranslocoLoader, TranslocoModule, TRANSLOCO_CONFIG, TRANSLOCO_LOADER } from '@ngneat/transloco';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {

	constructor(private readonly http: HttpClient) { }

	public getTranslation(lang: string): Observable<Translation> {
		return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
	}
}

export class AvailableLanguage {

	public readonly value: string;
	public readonly displayText: string;

	constructor(
		value: string,
		displayText: string
	) {
		this.value = value;
		this.displayText = displayText;
	}

}

export const availableLanguages = [
	new AvailableLanguage('en', 'EN | English'),
	new AvailableLanguage('uk', 'UK | Українська')
];

export const defaultAvailableLanguage = availableLanguages[0];

@NgModule({
	exports: [TranslocoModule],
	providers: [
		{
			provide: TRANSLOCO_CONFIG,
			useValue: translocoConfig({
				availableLangs: availableLanguages.map(language => language.value),
				defaultLang: availableLanguages[0].value,
				// Remove this option if your application doesn't support changing language in runtime.
				reRenderOnLangChange: true,
				// This enables logging to console
				prodMode: true // environment.production
			})
		},
		{
			provide: TRANSLOCO_LOADER,
			useClass: TranslocoHttpLoader
		}
	]
})
export class TranslocoRootModule { }
