import { MedicationAdmRouteClient, MedicationAdmRouteDescribedComboboxDto, MedicationAdmRouteFilterDto } from '@nmn-communication/medication-adm-routes';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { wrapFuncCallAsFakeClientResponse } from '../../../../modules/fakes/databases/fake.utils';
import { MedicationAdmRouteFakeClientConfiguration } from '../configurations/medication-adm-route.fake-client.configuration';

export class MedicationAdmRouteFakeClient extends MedicationAdmRouteClient {

	constructor(
		private readonly configuration: MedicationAdmRouteFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulkAsComboboxes(filter: MedicationAdmRouteFilterDto): Observable<Result<Array<MedicationAdmRouteDescribedComboboxDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.medicationAdmRoutesTable.getComboboxesPagedCollection(filter),
			this.configuration.serverDelayMs
		);
	}

}
