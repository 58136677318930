import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { ComboboxModel } from '@nmn-domain/shared';

export class RegionFilterModel {

	public readonly ids?: Array<string>;
	public readonly ignoreIds?: Array<string>;
	public readonly searchPattern?: string;

	constructor(
		ids: Array<string> | undefined,
		ignoreIds: Array<string> | undefined,
		searchPattern: string | undefined
	) {
		this.ids = ids;
		this.ignoreIds = ignoreIds;
		this.searchPattern = searchPattern;
	}

	public static createEmpty(): RegionFilterModel {
		return new RegionFilterModel(undefined, undefined, undefined);
	}

	public static createForPredefinedList(regionIds: Array<string>): RegionFilterModel {
		return new RegionFilterModel(regionIds, undefined, undefined);
	}

	public static createForAutocomplete(searchPattern: string, selectedItem: ComboboxModel): RegionFilterModel {
		return new RegionFilterModel(
			undefined,
			isStringDefinedAndNotEmpty(selectedItem?.id) ? [selectedItem.id] : undefined,
			searchPattern
		);
	}

}
