import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';

export class SourceModel {

	public readonly displayText?: string;
	public readonly link: string;

	public get isDisplayTextDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.displayText);
	}

	public constructor(
		displayText: string | undefined,
		link: string
	) {
		this.displayText = displayText;

		// TODO: Guard check on defined
		this.link = link;
	}

}
