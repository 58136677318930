import { Result } from '@nmn-core/shared';
import { UserSubscriptionGetCommand } from '@nmn-domain/accounts';
import { EmptyCommandResult, FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';

export abstract class UserSubscriptionQueryHandlerService {

	public abstract get(command: UserSubscriptionGetCommand): Observable<Result<EmptyCommandResult<UserSubscriptionGetCommand>, FailureModel>>;

}
