import { Injector } from '@angular/core';
import { LocalStorageService } from '@nmn-core/application-storages';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { FileUploaderFakeClientConfiguration } from '../configurations/file-uploader-fake-client.configuration';
import { FileUploaderFakeClient } from '../services/file-uploader.fake-client';

export class FileUploaderFakeClientBuilder {

	private configuration: FileUploaderFakeClientConfiguration;

	constructor(private readonly injector: Injector) { }

	public withConfiguration(
		configuration: FileUploaderFakeClientConfiguration
	): FileUploaderFakeClientBuilder {

		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;

	}

	public build(): FileUploaderFakeClient {

		return new FileUploaderFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase),
			this.injector.get(LocalStorageService));

	}

}
