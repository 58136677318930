import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServerConfigurationClient, ServerConfigurationDto } from '@nmn-communication/server-configuration';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { createFailedResultFromErrorResponse, createResultFromResponse } from '../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { ServerConfigurationHttpClientConfiguration } from '../configurations/server-configuration.http-client.configuration';

export class ServerConfigurationHttpClient extends ServerConfigurationClient {

	constructor(
		private readonly configuration: ServerConfigurationHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public get(): Observable<Result<ServerConfigurationDto, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<ServerConfigurationDto>(url, { headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

}
