import { Injector, Provider } from '@angular/core';
import { MedicationRateQuantityTypeClient } from '@nmn-communication/medication-rate-quantity-types';
import { MedicationRateQuantityTypeClientFactory } from './factories/medication-rate-quantity-type.client.factory';

export const medicationRateQuantityTypeProviders: Array<Provider> = [
	{
		provide: MedicationRateQuantityTypeClient,
		useFactory: MedicationRateQuantityTypeClientFactory,
		deps: [Injector]
	}
];
