import { TranslocoService } from '@ngneat/transloco';
import { MedicationComboboxDto, MedicationFilterDto, MedicationFindParameterDto } from '@nmn-communication/medications';
import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { MedicationType } from '@nmn-domain/medications';
import { FakeDatabase } from '../../databases/fake.database';
import { getPagedCollectionWithoutDtoFilter } from '../../databases/fake.utils';
import { FakeDescribedComboboxDto, mapFakeDescriptedComboboxDtoToDesriptedComboboxDto } from '../../models/comboboxes/fake-described-combobox.dto';
import { FakeMedicationComboboxDto, mapFakeMedicationComboboxDtoToComboboxDto } from '../../models/comboboxes/fake-medication-described-combobox.dto';
import { FakeLocalizableEntity } from '../../models/fake-localizable-entity';
import { setTranslation } from '../../utils/localize';

export class MedicationFakeTable {

	private readonly data: Array<MedicationFakeRecord>;

	constructor(
		private readonly database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.data = [...initialData];
	}

	public getAsCombobox(parameter: MedicationFindParameterDto): MedicationComboboxDto {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record was not found');
		}

		return record;
	}

	public findAsCombobox(parameter: MedicationFindParameterDto): MedicationComboboxDto {
		const record = this.data
			.find((item: MedicationFakeRecord) => findPredicate(item, parameter));
		const comboboxRecord = this.mapFromRecordToDescribedComboboxDto(record);

		return mapFakeMedicationComboboxDtoToComboboxDto(
			this.translocoService,
			comboboxRecord
		);
	}

	public getComboboxesPagedCollection(filter: MedicationFilterDto): Array<MedicationComboboxDto> {
		const pagedCollection = getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			filterPredicateForRecord,
			this.mapFromRecordToDescribedComboboxDto.bind(this),
			compareFn
		).items;

		return pagedCollection
			.map(item => mapFakeMedicationComboboxDtoToComboboxDto(this.translocoService, item));
	}

	public mapFromRecordToDescribedComboboxDto = (
		record: MedicationFakeRecord
	): FakeMedicationComboboxDto => (
		isValueDefined(record) ?
			{
				id: record.id,
				displayText: record.name,
				description: record.description,
				substances: record.activeSubstances.map(item => mapFakeDescriptedComboboxDtoToDesriptedComboboxDto(this.translocoService, item)),
				type: record.type.toString()
			} :
			undefined
	);

}
const findPredicate = (item: MedicationFakeRecord, findParameter: MedicationFindParameterDto): boolean =>
	item.id === findParameter.id;

const filterPredicateForRecord = (
	item: MedicationFakeRecord,
	filter: MedicationFilterDto,
	translocoService: TranslocoService
): boolean => {
	let result = true;

	if (result && isArrayDefinedAndNotEmpty(filter.ids)) {
		result = result && filter.ids.some(id => item.id === id);
	}

	if (result && isArrayDefinedAndNotEmpty(filter.ignoreIds)) {
		result = result && filter.ignoreIds.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result &&
			setTranslation(translocoService, item.name)
				.toLowerCase()
				.indexOf(filter.searchPattern.toLowerCase()) >= 0;
	}

	return result;
};

/* eslint-disable  */
/* eslint-disable complexity */
const compareFn = (
	item1: FakeMedicationComboboxDto,
	item2: FakeMedicationComboboxDto,
	sorting: string
): number => {
	if (sorting === 'displayText asc') {
		return item1.displayText > item2.displayText ? 1 : item1.displayText < item2.displayText ? -1 : 0;
	} else if (sorting === 'displayText desc') {
		return item1.displayText < item2.displayText ? 1 : item1.displayText > item2.displayText ? -1 : 0;
	}

	return 0;
};
/* eslint-enable complexity */
/* eslint-enable */

interface MedicationFakeRecord {
	id: string;
	name: FakeLocalizableEntity;
	description: FakeLocalizableEntity;
	activeSubstances: Array<FakeDescribedComboboxDto<string>>;
	type: MedicationType;
	order: number;
	createdOn: string;
	updatedOn?: string;
}

const initialData: Array<MedicationFakeRecord> = [
	{
		id: 'atorvastatin',
		name: {
			en: 'Atorvastatin',
			uk: 'Аторвастатин'
		},
		description: {
			en: 'HMG CoA reductase inhibitors',
			uk: 'Інгібітори ГМГ-КоА-редуктази'
		},
		type: MedicationType.CardiovascularSystem,
		activeSubstances: [
			{
				id: '1',
				displayText: {
					en: 'atorvastatin calcium trihydrate'
				}
			}
		],
		order: 1,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'levothyroxine',
		name: {
			en: 'Levothyroxine',
			uk: 'Левотироксин'
		},
		description: {
			en: 'Natural and synthetic thyroid hormones',
			uk: 'Природні та синтетичні гормони щитовидної залози'
		},
		type: MedicationType.SystemicHormonalPreparations,
		activeSubstances: [
			{
				id: '2',
				displayText: {
					en: 'L-thyroxine'
				}
			}
		],
		order: 2,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'lisinopril',
		name: {
			en: 'Lisinopril',
			uk: 'Лізиноприл'
		},
		description: {
			en: 'ACE inhibitors, plain',
			uk: 'Інгібітори АПФ, прості'
		},
		type: MedicationType.CardiovascularSystem,
		activeSubstances: [
			{
				id: '3',
				displayText: {
					en: 'lisinopril'
				}
			}
		],
		order: 3,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'metformin',
		name: {
			en: 'Metformin',
			uk: 'Метформін'
		},
		description: {
			en: 'Biguanides',
			uk: 'Бігуаніди'
		},
		type: MedicationType.AlimentaryTractAndMetabolism,
		activeSubstances: [
			{
				id: '4',
				displayText: {
					en: 'metformin hydrochloride'
				}
			}
		],
		order: 4,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'metoprolol',
		name: {
			en: 'Metoprolol',
			uk: 'Метопролол'
		},
		description: {
			en: 'Beta blocking agents, selective',
			uk: 'Бета-блокатори, селективні'
		},
		type: MedicationType.CardiovascularSystem,
		activeSubstances: [
			{
				id: '5',
				displayText: {
					en: 'metoprolol tartrate'
				}
			}
		],
		order: 5,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'amlodipine',
		name: {
			en: 'Amlodipine',
			uk: 'Амлодипін'
		},
		description: {
			en: 'Dihydropyridine derivatives',
			uk: 'Похідні дигідропіридину'
		},
		type: MedicationType.CardiovascularSystem,
		activeSubstances: [
			{
				id: '6',
				displayText: {
					en: 'amlodipine besylate'
				}
			}
		],
		order: 6,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'albuterol',
		name: {
			en: 'Albuterol',
			uk: 'Альбутамол'
		},
		description: {
			en: 'Selective beta-2-adrenoreceptor agonists',
			uk: 'Селективні агоністи бета-2-адренорецепторів'
		},
		type: MedicationType.RespiratorySystem,
		activeSubstances: [
			{
				id: '7',
				displayText: {
					en: 'salbutamol sulfate'
				}
			}
		],
		order: 7,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'omeprazole',
		name: {
			en: 'Omeprazole',
			uk: 'Омепразол'
		},
		description: {
			en: 'Proton pump inhibitors',
			uk: 'Інгібітори протонної помпи'
		},
		type: MedicationType.AlimentaryTractAndMetabolism,
		activeSubstances: [
			{
				id: '8',
				displayText: {
					en: 'omeprazole'
				}
			}
		],
		order: 8,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'losartan',
		name: {
			en: 'Losartan',
			uk: 'Лозартан'
		},
		description: {
			en: 'Angiotensin II receptor blockers (ARBs), plain',
			uk: 'Блокатори рецепторів ангіотензину II (БРА), звичайні'
		},
		type: MedicationType.CardiovascularSystem,
		activeSubstances: [
			{
				id: '9',
				displayText: {
					en: 'losartan potassium/hydrochlorothiazide'
				}
			}
		],
		order: 9,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'gabapentin',
		name: {
			en: 'Gabapentin',
			uk: 'Габапентин'
		},
		description: {
			en: 'Other antiepileptics',
			uk: 'Інші протиепілептичні засоби'
		},
		type: MedicationType.NervousSystem,
		activeSubstances: [
			{
				id: '10',
				displayText: {
					en: 'gabapentin'
				}
			}
		],
		order: 10,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'hydrochlorothiazide',
		name: {
			en: 'Hydrochlorothiazide',
			uk: 'Гідрохлортіазид'
		},
		description: {
			en: 'Thiazides, plain',
			uk: 'Тіазиди, звичайні'
		},
		type: MedicationType.CardiovascularSystem,
		activeSubstances: [
			{
				id: '11',
				displayText: {
					en: 'losartan potassium/hydrochlorothiazide'
				}
			}
		],
		order: 11,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'sertraline',
		name: {
			en: 'Sertraline',
			uk: 'Сертралін'
		},
		description: {
			en: 'Selective serotonin reuptake inhibitors',
			uk: 'Селективні інгібітори зворотного захоплення серотоніну'
		},
		type: MedicationType.NervousSystem,
		activeSubstances: [
			{
				id: '12',
				displayText: {
					en: 'sertraline hydrochloride'
				}
			}
		],
		order: 12,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'simvastatin',
		name: {
			en: 'Simvastatin',
			uk: 'Симвастатин'
		},
		description: {
			en: 'HMG CoA reductase inhibitors',
			uk: 'Інгібітори ГМГ-КоА-редуктази'
		},
		type: MedicationType.CardiovascularSystem,
		activeSubstances: [
			{
				id: '13',
				displayText: {
					en: 'simvastatin'
				}
			}
		],
		order: 13,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'montelukast',
		name: {
			en: 'Montelukast',
			uk: 'Монтелукаст'
		},
		description: {
			en: 'Leukotriene receptor antagonists',
			uk: 'Антагоністи лейкотрієнових рецепторів'
		},
		type: MedicationType.RespiratorySystem,
		activeSubstances: [
			{
				id: '14',
				displayText: {
					en: 'montelukast sodium'
				}
			}
		],
		order: 14,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'hydrocodone',
		name: {
			en: 'Hydrocodone',
			uk: 'Гідрокодон'
		},
		description: {
			en: 'Opium alkaloids and derivatives',
			uk: 'Алкалоїди та похідні опію'
		},
		type: MedicationType.RespiratorySystem,
		activeSubstances: [
			{
				id: '15',
				displayText: {
					en: 'hydrocodone'
				}
			}
		],
		order: 15,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'pantoprazole',
		name: {
			en: 'Pantoprazole',
			uk: 'Пантопразол'
		},
		description: {
			en: 'Proton pump inhibitors',
			uk: 'Інгібітори протонної помпи'
		},
		type: MedicationType.AlimentaryTractAndMetabolism,
		activeSubstances: [
			{
				id: '16',
				displayText: {
					en: 'pantoprazole'
				}
			}
		],
		order: 16,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'furosemide',
		name: {
			en: 'Furosemide',
			uk: 'Фуросемід'
		},
		description: {
			en: 'Sulfonamides, plain',
			uk: 'Сульфаніламіди, звичайні'
		},
		type: MedicationType.CardiovascularSystem,
		activeSubstances: [
			{
				id: '17',
				displayText: {
					en: 'furosemide'
				}
			}
		],
		order: 17,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'fluticasone',
		name: {
			en: 'Fluticasone',
			uk: 'Флутиказон'
		},
		description: {
			en: 'Corticosteroids, potent (group III)',
			uk: 'Кортикостероїди сильнодіючі (III група)'
		},
		type: MedicationType.Dermatologicals,
		activeSubstances: [
			{
				id: '18',
				displayText: {
					en: 'fluticasone furoate'
				}
			}
		],
		order: 18,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'escitalopram',
		name: {
			en: 'Escitalopram',
			uk: 'Есциталопрам'
		},
		description: {
			en: 'Selective serotonin reuptake inhibitors',
			uk: 'Селективні інгібітори зворотного захоплення серотоніну'
		},
		type: MedicationType.NervousSystem,
		activeSubstances: [
			{
				id: '19',
				displayText: {
					en: 'escitalopram'
				}
			}
		],
		order: 19,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'fluoxetine',
		name: {
			en: 'Fluoxetine',
			uk: 'Флуоксетин'
		},
		description: {
			en: 'Selective serotonin reuptake inhibitors',
			uk: 'Селективні інгібітори зворотного захоплення серотоніну'
		},
		type: MedicationType.NervousSystem,
		activeSubstances: [
			{
				id: '20',
				displayText: {
					en: 'fluoxetine hydrochloride'
				}
			}
		],
		order: 20,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'rosuvastatin',
		name: {
			en: 'Rosuvastatin',
			uk: 'Розувастатин'
		},
		description: {
			en: 'HMG CoA reductase inhibitors',
			uk: 'Інгібітори ГМГ-КоА-редуктази'
		},
		type: MedicationType.CardiovascularSystem,
		activeSubstances: [
			{
				id: '21',
				displayText: {
					en: 'rosuvastatin calcium'
				}
			}
		],
		order: 21,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'bupropion',
		name: {
			en: 'Bupropion',
			uk: 'Бупропіон'
		},
		description: {
			en: 'Other antidepressants',
			uk: 'Інші антидепресанти'
		},
		type: MedicationType.NervousSystem,
		activeSubstances: [
			{
				id: '22',
				displayText: {
					en: 'bupropion'
				}
			}
		],
		order: 22,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'amoxicillin',
		name: {
			en: 'Amoxicillin',
			uk: 'Амоксицилін'
		},
		description: {
			en: 'Penicillins with extended spectrum',
			uk: 'Пеніциліни розширеного спектру дії'
		},
		type: MedicationType.AntiinfectivesForSystemicUse,
		activeSubstances: [
			{
				id: '23',
				displayText: {
					en: 'amoxicillin trihydrate'
				}
			}
		],
		order: 23,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'trazodone',
		name: {
			en: 'Trazodone',
			uk: 'Тразодон'
		},
		description: {
			en: 'Other antidepressants',
			uk: 'Інші антидепресанти'
		},
		type: MedicationType.NervousSystem,
		activeSubstances: [
			{
				id: '24',
				displayText: {
					en: 'trazodone hydrochloride'
				}
			}
		],
		order: 24,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'duloxetine',
		name: {
			en: 'Duloxetine',
			uk: 'Дулоксетин'
		},
		description: {
			en: 'Other antidepressants',
			uk: 'Інші антидепресанти'
		},
		type: MedicationType.NervousSystem,
		activeSubstances: [
			{
				id: '25',
				displayText: {
					en: 'duloxetine'
				}
			}
		],
		order: 25,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'prednisone',
		name: {
			en: 'Prednisone',
			uk: 'Преднізон'
		},
		description: {
			en: 'Corticosteroids acting locally',
			uk: 'Кортикостероїди місцевої дії'
		},
		type: MedicationType.AlimentaryTractAndMetabolism,
		activeSubstances: [
			{
				id: '26',
				displayText: {
					en: 'prednisone'
				}
			}
		],
		order: 26,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'tamsulosin',
		name: {
			en: 'Tamsulosin',
			uk: 'Тамсулозин'
		},
		description: {
			en: 'Alpha-adrenoreceptor antagonists',
			uk: 'Антагоністи альфа-адренорецепторів'
		},
		type: MedicationType.GenitoUrinarySystemAndSexHormones,
		activeSubstances: [
			{
				id: '27',
				displayText: {
					en: 'tamsulosin'
				}
			}
		],
		order: 27,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'advil',
		name: {
			en: 'Advil',
			uk: 'Адвіл'
		},
		description: {
			en: 'Propionic acid derivatives',
			uk: 'Похідні пропіонової кислоти'
		},
		type: MedicationType.MusculoSkeletalSystem,
		activeSubstances: [
			{
				id: '28',
				displayText: {
					en: 'ibuprofen'
				}
			}
		],
		order: 28,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'citalopram',
		name: {
			en: 'Citalopram',
			uk: 'Циталопрам'
		},
		description: {
			en: 'Selective serotonin reuptake inhibitors',
			uk: 'Селективні інгібітори зворотного захоплення серотоніну'
		},
		type: MedicationType.NervousSystem,
		activeSubstances: [
			{
				id: '29',
				displayText: {
					en: 'citalopram hydrobromide'
				}
			}
		],
		order: 29,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'meloxicam',
		name: {
			en: 'Meloxicam',
			uk: 'Мелоксикам'
		},
		description: {
			en: 'Oxicams',
			uk: 'Оксикам'
		},
		type: MedicationType.MusculoSkeletalSystem,
		activeSubstances: [
			{
				id: '30',
				displayText: {
					en: 'meloxicam'
				}
			}
		],
		order: 30,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'pravastatin',
		name: {
			en: 'Pravastatin',
			uk: 'Правастатин'
		},
		description: {
			en: 'HMG CoA reductase inhibitors',
			uk: 'Інгібітори ГМГ-КоА-редуктази'
		},
		type: MedicationType.CardiovascularSystem,
		activeSubstances: [
			{
				id: '31',
				displayText: {
					en: 'pravastatin sodium'
				}
			}
		],
		order: 31,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'carvedilol',
		name: {
			en: 'Carvedilol',
			uk: 'Карведилол'
		},
		description: {
			en: 'Alpha and beta blocking agents',
			uk: 'Альфа- і бета-блокатори'
		},
		type: MedicationType.CardiovascularSystem,
		activeSubstances: [
			{
				id: '32',
				displayText: {
					en: 'carvedilol'
				}
			}
		],
		order: 32,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'potassium',
		name: {
			en: 'Potassium',
			uk: 'Калій'
		},
		description: {
			en: 'Potassium',
			uk: 'Калій'
		},
		type: MedicationType.AlimentaryTractAndMetabolism,
		activeSubstances: [
			{
				id: '33',
				displayText: {
					en: 'potassium chloride'
				}
			}
		],
		order: 33,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'tramadol',
		name: {
			en: 'Tramadol',
			uk: 'Трамадол'
		},
		description: {
			en: 'Other opioids',
			uk: 'Інші опіоїди'
		},
		type: MedicationType.NervousSystem,
		activeSubstances: [
			{
				id: '34',
				displayText: {
					en: 'tramadol hydrochloride'
				}
			}
		],
		order: 34,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'clopidogrel',
		name: {
			en: 'Clopidogrel',
			uk: 'Клопідогрель'
		},
		description: {
			en: 'Platelet aggregation inhibitors excl. heparin',
			uk: 'Інгібітори агрегації тромбоцитів за винятком гепарин'
		},
		type: MedicationType.BloodAndBloodFormingOrgans,
		activeSubstances: [
			{
				id: '35',
				displayText: {
					en: 'clopidogrel'
				}
			}
		],
		order: 35,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'insulin-glargine',
		name: {
			en: 'Insulin-glargine',
			uk: 'Інсулін-гларгін'
		},
		description: {
			en: 'Insulins and analogues for injection, long-acting',
			uk: 'Інсуліни та аналоги для ін\'єкцій тривалої дії'
		},
		type: MedicationType.AlimentaryTractAndMetabolism,
		activeSubstances: [
			{
				id: '36',
				displayText: {
					en: 'insulin glargine'
				}
			}
		],
		order: 36,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'atenolol',
		name: {
			en: 'Atenolol',
			uk: 'Атенолол'
		},
		description: {
			en: 'Beta blocking agents, selective',
			uk: 'Бета-блокатори, селективні'
		},
		type: MedicationType.CardiovascularSystem,
		activeSubstances: [
			{
				id: '37',
				displayText: {
					en: 'atenolol'
				}
			}
		],
		order: 37,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'venlafaxine',
		name: {
			en: 'Venlafaxine',
			uk: 'Венлафаксин'
		},
		description: {
			en: 'Other antidepressants',
			uk: 'Інші антидепресанти'
		},
		type: MedicationType.NervousSystem,
		activeSubstances: [
			{
				id: '38',
				displayText: {
					en: 'venlafaxine hydrochloride'
				}
			}
		],
		order: 38,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'alprazolam',
		name: {
			en: 'Alprazolam',
			uk: 'Алпразолам'
		},
		description: {
			en: 'Benzodiazepine derivatives',
			uk: 'Похідні бензодіазепіну'
		},
		type: MedicationType.NervousSystem,
		activeSubstances: [
			{
				id: '39',
				displayText: {
					en: 'alprazolam'
				}
			}
		],
		order: 39,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'allopurinol',
		name: {
			en: 'Allopurinol',
			uk: 'Алопуринол'
		},
		description: {
			en: 'Preparations inhibiting uric acid production',
			uk: 'Препарати, що пригнічують утворення сечової кислоти'
		},
		type: MedicationType.MusculoSkeletalSystem,
		activeSubstances: [
			{
				id: '40',
				displayText: {
					en: 'allopurinol'
				}
			}
		],
		order: 40,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'cyclobenzaprine',
		name: {
			en: 'Cyclobenzaprine',
			uk: 'Циклобензапрін'
		},
		description: {
			en: 'Other centrally acting agents',
			uk: 'Інші агенти центральної дії'
		},
		type: MedicationType.MusculoSkeletalSystem,
		activeSubstances: [
			{
				id: '41',
				displayText: {
					en: 'cyclobenzaprine hydrochloride'
				}
			}
		],
		order: 41,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'clonazepam',
		name: {
			en: 'Clonazepam',
			uk: 'Клоназепам'
		},
		description: {
			en: 'Benzodiazepine derivatives',
			uk: 'Похідні бензодіазепіну'
		},
		type: MedicationType.NervousSystem,
		activeSubstances: [
			{
				id: '42',
				displayText: {
					en: 'clonazepam'
				}
			}
		],
		order: 42,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'aciclovir',
		name: {
			en: 'Aciclovir',
			uk: 'Ацикловір'
		},
		description: {
			en: 'Nucleosides and nucleotides excl. reverse transcriptase inhibitors',
			uk: 'Нуклеозиди та нуклеотиди без інгібітори зворотної транскриптази'
		},
		type: MedicationType.AntiinfectivesForSystemicUse,
		activeSubstances: [
			{
				id: '43',
				displayText: {
					en: 'aciclovir'
				}
			}
		],
		order: 43,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'loperamide',
		name: {
			en: 'Loperamide',
			uk: 'Лоперамід'
		},
		description: {
			en: 'Antipropulsives',
			uk: 'Антипропульсивні засоби'
		},
		type: MedicationType.AlimentaryTractAndMetabolism,
		activeSubstances: [
			{
				id: '44',
				displayText: {
					en: 'loperamide hydrochloride'
				}
			}
		],
		order: 44,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'dexlansoprazole',
		name: {
			en: 'Dexlansoprazole',
			uk: 'Декслансопразол'
		},
		description: {
			en: 'Proton pump inhibitors',
			uk: 'Інгібітори протонної помпи'
		},
		type: MedicationType.AlimentaryTractAndMetabolism,
		activeSubstances: [
			{
				id: '45',
				displayText: {
					en: 'dexlansoprazole'
				}
			}
		],
		order: 45,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'celecoxib',
		name: {
			en: 'Celecoxib',
			uk: 'Целекоксиб'
		},
		description: {
			en: 'Coxibs',
			uk: 'Коксиби'
		},
		type: MedicationType.MusculoSkeletalSystem,
		activeSubstances: [
			{
				id: '46',
				displayText: {
					en: 'celecoxib'
				}
			}
		],
		order: 46,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'mylanta',
		name: {
			en: 'Mylanta',
			uk: 'Міланта'
		},
		description: {
			en: 'Antacids with antiflatulents',
			uk: 'Антациди з протиздуттям'
		},
		type: MedicationType.AlimentaryTractAndMetabolism,
		activeSubstances: [
			{
				id: '47',
				displayText: {
					en: 'aluminum hydroxide'
				}
			},
			{
				id: '48',
				displayText: {
					en: 'antacid magnesium hydroxide'
				}
			},
			{
				id: '49',
				displayText: {
					en: 'simethicone'
				}
			}
		],
		order: 47,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'salmeterol',
		name: {
			en: 'Salmeterol',
			uk: 'Сальметерол'
		},
		description: {
			en: 'Selective beta-2-adrenoreceptor agonists',
			uk: 'Селективні агоністи бета-2-адренорецепторів'
		},
		type: MedicationType.RespiratorySystem,
		activeSubstances: [
			{
				id: '51',
				displayText: {
					en: 'salmeterol xinafoate'
				}
			}
		],
		order: 48,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'aspirin',
		name: {
			en: 'Aspirin',
			uk: 'Аспірин'
		},
		description: {
			en: 'Aspirin is a salicylate used to treat pain, fever, inflammation, migraines, and reducing the risk of major adverse cardiovascular events.',
			uk: 'Аспірин — це саліцилат, який використовується для лікування болю, лихоманки, запалення, мігрені та зниження ризику серйозних серцево-судинних подій..'
		},
		type: MedicationType.Undefined,
		activeSubstances: [
			{
				id: '52',
				displayText: {
					en: 'acetylsalicylic acid'
				}
			}
		],
		order: 1001,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'levofloxacin',
		name: {
			en: 'Levofloxacin',
			uk: 'Левофлоксацин'
		},
		description: {
			en: 'Levofloxacin',
			uk: 'Левофлоксацин'
		},
		type: MedicationType.Undefined,
		activeSubstances: [
			{
				id: '53',
				displayText: {
					en: 'levofloxacin'
				}
			}
		],
		order: 1003,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'imodium',
		name: {
			en: 'Imodium',
			uk: 'Імодіум'
		},
		description: {
			en: 'Imodium',
			uk: 'Імодіум'
		},
		type: MedicationType.Undefined,
		activeSubstances: [
			{
				id: '54',
				displayText: {
					en: 'imodium'
				}
			}
		],
		order: 1004,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'acyclovir',
		name: {
			en: 'Acyclovir',
			uk: 'Ацикловір'
		},
		description: {
			en: 'Acyclovir',
			uk: 'Ацикловір'
		},
		type: MedicationType.Undefined,
		activeSubstances: [
			{
				id: '55',
				displayText: {
					en: 'acyclovir'
				}
			}
		],
		order: 1006,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'acetaminophen',
		name: {
			en: 'Acetaminophen',
			uk: 'Ацетамінофен'
		},
		description: {
			en: 'Acetaminophen',
			uk: 'Ацетамінофен'
		},
		type: MedicationType.Undefined,
		activeSubstances: [
			{
				id: '56',
				displayText: {
					en: 'acetaminophen'
				}
			}
		],
		order: 1007,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'takra-vasti',
		name: {
			en: 'Takra Vasti',
			uk: 'Такра Васті'
		},
		description: {
			en: 'Takra Vasti',
			uk: 'Такра Васті'
		},
		type: MedicationType.Undefined,
		activeSubstances: [],
		order: 2001,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'actovegin',
		name: {
			en: 'Actovegin',
			uk: 'Актовегін'
		},
		description: {
			en: 'Actovegin',
			uk: 'Актовегін'
		},
		type: MedicationType.Undefined,
		activeSubstances: [],
		order: 3001,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'serevent-diskus',
		name: {
			en: 'Serevent Diskus',
			uk: 'Серевент дискус'
		},
		description: {
			en: 'Selective beta-2-adrenoreceptor agonists',
			uk: 'Селективні агоністи бета-2-адренорецепторів'
		},
		type: MedicationType.RespiratorySystem,
		activeSubstances: [
			{
				id: '57',
				displayText: {
					en: 'salmeterol'
				}
			}
		],
		order: 3002,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'cinqair',
		name: {
			en: 'Cinqair',
			uk: 'Цінквар'
		},
		description: {
			en: 'Cinqair (reslizumab) injection is an interleukin-5 antagonist monoclonal antibody (IgG4 kappa) indicated for add-on maintenance treatment of patients with severe asthma aged 18 years and older, and with an eosinophilic phenotype.',
			uk: 'Cinqair (reslizumab) для ін’єкцій – це моноклональне антитіло до антагоніста інтерлейкіну-5 (IgG4 каппа), призначене для додаткового підтримуючого лікування пацієнтів з тяжкою астмою віком від 18 років і з еозинофільним фенотипом.'
		},
		type: MedicationType.Undefined,
		activeSubstances: [
			{
				id: '58',
				displayText: {
					en: 'reslizumab'
				}
			}
		],
		order: 3003,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'vitamin-d',
		name: {
			en: 'Vitamin D',
			uk: 'Вітамін D'
		},
		description: {
			en: 'Vitamin D is a fat-soluble vitamin indicated for use in the treatment of hypoparathyroidism, refractory rickets, also known as vitamin D resistant rickets, and familial hypophosphatemia.',
			uk: 'Вітамін D — це жиророзчинний вітамін, призначений для лікування гіпопаратиреозу, рефрактерного рахіту, також відомого як стійкий до вітаміну D рахіт, і сімейної гіпофосфатемії.'
		},
		type: MedicationType.Undefined,
		activeSubstances: [
			{
				id: '59',
				displayText: {
					en: 'Vitamin D'
				}
			}
		],
		order: 3004,
		createdOn: '2022-06-25T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'metoprolol',
		name: {
			en: 'Metoprolol',
			uk: 'Метопролол'
		},
		description: {
			en: 'Metoprolol, sold under the brand name Lopressor among others, is a medication used to treat high blood pressure, chest pain due to poor blood flow to the heart, and a number of conditions involving an abnormally fast heart rate. It is also used to prevent further heart problems after myocardial infarction and to prevent headaches in those with migraines. It is a selective β1 receptor blocker medication. It is taken by mouth or is given intravenously.',
			uk: 'Метопролол — кардіоселективний блокатор β1-адренорецепторів. Не чинить мембраностабілізуючої дії і не має внутрішньої симпатоміметичної активності.'
		},
		type: MedicationType.CardiovascularSystem,
		activeSubstances: [
			{
				id: 'metoprolol',
				displayText: {
					en: 'Metoprolol',
					uk: 'Метопролол'
				}
			}
		],
		order: 4000,
		createdOn: '2023-12-27T12:00:00Z',
		updatedOn: undefined
	}
];

// eslint-disable-next-line max-lines
