import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { HealthComplaintHttpClientConfiguration } from '../configurations/health-complaint.http-client.configuration';

export class HealthComplaintHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<HealthComplaintHttpClientConfiguration> {

	public build(): HealthComplaintHttpClientConfiguration {
		return new HealthComplaintHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
