import { PharmacogenomicTestResultComboboxDto } from '@nmn-communication/pharmacogenomic-test-results';
import { mapPharmacogenomicTestResultCategoryFromString, PharmacogenomicTestResultComboboxModel } from '@nmn-domain/pharmacogenomic-test-results';

export const mapPharmacogenomicTestResultComboboxDtoToModel = (dto: PharmacogenomicTestResultComboboxDto): PharmacogenomicTestResultComboboxModel => (
	new PharmacogenomicTestResultComboboxModel(
		dto.id,
		dto.displayText,
		dto.description,
		mapPharmacogenomicTestResultCategoryFromString(dto.category)
	)
);
