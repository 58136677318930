export class VaccinationCardGetQuery {

	public patientId: string;

	private constructor(
		patientId: string
	) {

		// TODO: Guard add defined check

		this.patientId = patientId;
	}

	public static createByPatientId(patientId: string): VaccinationCardGetQuery {
		return new VaccinationCardGetQuery(patientId);
	}

}
