import { Provider } from '@angular/core';
import { GenderQueryHandlerService, GendersResolver } from '@nmn-domain/genders';
import { GendersViaQueryHandlerResolver } from './resolvers/genders.via-query-handler.resolver';
import { GenderQueryHandlerViaClientService } from './services/gender.query-handler-via-client.service';

export const genderProviders: Array<Provider> = [
	{
		provide: GenderQueryHandlerService,
		useClass: GenderQueryHandlerViaClientService
	},
	{
		provide: GendersResolver,
		useClass: GendersViaQueryHandlerResolver
	}
];
