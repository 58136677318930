import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocaleComboboxModel, LocaleFilterModel, LocaleQueryHandlerService, LocalesResolver } from '@nmn-domain/locales';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LocalesViaQueryHandlerResolver extends LocalesResolver {

	constructor(
		private readonly localeQueryHandlerService: LocaleQueryHandlerService
	) {
		super();
	}

	public resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<Array<LocaleComboboxModel>> | Array<LocaleComboboxModel> {
		return this.localeQueryHandlerService
			.getAsComboboxes(LocaleFilterModel.createEmpty())
			.pipe(map(result => result.successOrDefault(() => [])));
	}

}
