import { LoginDto } from '@nmn-communication/accounts/login/models/login.dto';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { UserSubscriptionGetParameter } from '../models/user-subscription.get-parameter copy';
import { UserSubscriptionUpdateParameter } from '../models/user-subscription.update-parameter';

export abstract class UserSubscriptionClient {

	public abstract get(parameter: UserSubscriptionGetParameter): Observable<Result<LoginDto, Failure>>;

	public abstract update(parameter: UserSubscriptionUpdateParameter): Observable<Result<LoginDto, Failure>>;

}
