import { Failure, PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import {
	PatientTreatmentCheckerAnalyzeParameterDto, TreatmentCheckerAnalyzeParameterDto, TreatmentCheckerClient, TreatmentCheckerReportDeleteBulkParameterDto,
	TreatmentCheckerReportDto, TreatmentCheckerReportFilterDto, TreatmentCheckerReportFindParameterDto, TreatmentCheckerReportShortDto
} from '@nmn-communication/treatment-checker';
import { LocalStorageService } from '@nmn-core/application-storages';
import { EmptyResult, Result } from '@nmn-core/shared';
import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { wrapActionCallAsFakeClientResponse, wrapFuncCallAsFakeClientResponse } from '../../../../modules/fakes/databases/fake.utils';
import { TreatmentCheckerFakeClientConfiguration } from '../configurations/treatment-checker-fake-client.configuration';

export class TreatmentCheckerFakeClient extends TreatmentCheckerClient {

	constructor(
		private readonly configuration: TreatmentCheckerFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase,
		private readonly localStorage: LocalStorageService
	) {
		super();
	}

	public checkTreatmentForPatient(parameter: PatientTreatmentCheckerAnalyzeParameterDto): Observable<Result<string, Failure>> {
		wrapActionCallAsFakeClientResponse(
			// TODO: remove usage of localstorage in fakes
			() => {
				const userId = this.localStorage.getLastUserId();
				if (isStringDefinedAndNotEmpty(userId)) {
					this.fakeDatabase.userSubscriptionFakeTable.incrementTreatmentCheckerUsed(userId);
				}
			},
			{ min: 0, max: 0 }
		);

		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.treatmentCheckerReportsTable.createForPatient(parameter),
			{
				min: this.configuration.serverDelayMs.min * 3,
				max: this.configuration.serverDelayMs.max * 3
			}
		);
	}
	public checkTreatmentForAnonymous(parameter: TreatmentCheckerAnalyzeParameterDto): Observable<Result<string, Failure>> {
		wrapActionCallAsFakeClientResponse(
			// TODO: remove usage of localstorage in fakes
			() => {
				const userId = this.localStorage.getLastUserId();
				if (isStringDefinedAndNotEmpty(userId)) {
					this.fakeDatabase.userSubscriptionFakeTable.incrementTreatmentCheckerUsed(userId);
				}
			},
			{ min: 0, max: 0 }
		);

		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.treatmentCheckerReportsTable.createForAnonymous(parameter),
			{
				min: this.configuration.serverDelayMs.min * 3,
				max: this.configuration.serverDelayMs.max * 3
			}
		);
	}

	public getReportsPage(
		parameter: PageOptionsDto<TreatmentCheckerReportFilterDto>
	): Observable<Result<PagedCollectionDto<TreatmentCheckerReportShortDto, TreatmentCheckerReportFilterDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.treatmentCheckerReportsTable.getPagedCollection(parameter),
			this.configuration.serverDelayMs);
	}

	public getReport(parameter: TreatmentCheckerReportFindParameterDto): Observable<Result<TreatmentCheckerReportDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			// TODO: remove usage of localstorage in fakes
			() => this.fakeDatabase.treatmentCheckerReportsTable.get(parameter, this.localStorage.getLastUserId()),
			this.configuration.serverDelayMs);
	}

	public delete(parameter: TreatmentCheckerReportFindParameterDto): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.treatmentCheckerReportsTable.delete(parameter);
			},
			this.configuration.serverDelayMs);
	}

	public deleteBulk(parameter: TreatmentCheckerReportDeleteBulkParameterDto): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				for (const id of parameter.ids) {
					this.fakeDatabase.treatmentCheckerReportsTable.delete({ id });
				}
			},
			this.configuration.serverDelayMs);
	}

}
