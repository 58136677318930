import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { InitiateEmailConfirmationFormInModel } from '../models/initiate-email-confirmation-form/initiate-email-confirmation-form.in-model';

export abstract class InitiateEmailConfirmationFormInModelResolver {

	public abstract resolve(
		_: ActivatedRouteSnapshot,
		__: RouterStateSnapshot
	): Observable<InitiateEmailConfirmationFormInModel> | InitiateEmailConfirmationFormInModel;

}
