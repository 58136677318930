import { Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { GenderFakeClientConfiguration } from '../configurations/gender.fake-client.configuration';
import { GenderFakeClient } from '../services/gender.fake-client';

export class GenderFakeClientBuilder {

	private configuration: GenderFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(
		configuration: GenderFakeClientConfiguration
	): GenderFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): GenderFakeClient {
		return new GenderFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
