import { Injector, Provider } from '@angular/core';
import { MedicationAdmStatusClient } from '@nmn-communication/medication-adm-statuses';
import { MedicationAdmStatusClientFactory } from './factories/medication-adm-status.client.factory';

export const medicationAdmStatusProviders: Array<Provider> = [
	{
		provide: MedicationAdmStatusClient,
		useFactory: MedicationAdmStatusClientFactory,
		deps: [Injector]
	}
];
