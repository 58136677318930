import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfirmEmailParameter, EmailConfirmationClient, InitiateEmailConfirmationParameter } from '@nmn-communication/accounts';
import { Failure } from '@nmn-communication/shared';
import { EmptyResult } from '@nmn-core/shared';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EmailConfirmationHttpClientConfiguration } from '../configurations/email-confirmation.http-client.configuration';

export class EmailConfirmationHttpClient extends EmailConfirmationClient {

	constructor(
		private readonly configuration: EmailConfirmationHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public confirmEmail(parameter: ConfirmEmailParameter): Observable<EmptyResult<Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/confirm`;
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post(url, payload, { headers })
			.pipe(
				map(_ => EmptyResult.success<Failure>()),
				catchError((error: any) => {
					// TODO: pass this error to Failure
					console.log('confirm email error', error);

					return of(EmptyResult.failure<Failure>(Failure.createForErrorWithDefinedMessage('failures.general.initiateEmailConfirmationFailed')));
				})
			);
	}

	public initiateEmailConfirmation(parameter: InitiateEmailConfirmationParameter): Observable<EmptyResult<Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/send`;
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post(url, payload, { headers })
			.pipe(
				map(_ => EmptyResult.success<Failure>()),
				catchError((error: any) => {
					// TODO: pass this error to Failure
					console.log('initiate email confirmation error', error);

					return of(EmptyResult.failure<Failure>(Failure.createForErrorWithDefinedMessage('failures.general.confirmEmailFailed')));
				})
			);
	}

}
