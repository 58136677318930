import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { CommandResult, EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { FailureModel } from '../../shared/failures/failure.model';
import { TakenMedicationCreateCommand } from '../models/commands/taken-medication.create.command';
import { TakenMedicationDeleteBulkCommand } from '../models/commands/taken-medication.delete-bulk.command';
import { TakenMedicationDeleteCommand } from '../models/commands/taken-medication.delete.command';
import { TakenMedicationFailCourseCommand } from '../models/commands/taken-medication.fail-course.command';
import { TakenMedicationFinishCourseBulkCommand } from '../models/commands/taken-medication.finish-course-bulk.command';
import { TakenMedicationFinishCourseCommand } from '../models/commands/taken-medication.finish-course.command';
import { TakenMedicationUpdateCommand } from '../models/commands/taken-medication.update.command';

export abstract class TakenMedicationCommandHandlerService {

	public abstract handleCreateCommand(
		command: TakenMedicationCreateCommand
	): Observable<Result<CommandResult<TakenMedicationCreateCommand, string>, FailureModel>>;

	public abstract handleUpdateCommand(
		command: TakenMedicationUpdateCommand
	): Observable<Result<EmptyCommandResult<TakenMedicationUpdateCommand>, FailureModel>>;

	public abstract handleDeleteCommand(
		command: TakenMedicationDeleteCommand
	): Observable<Result<EmptyCommandResult<TakenMedicationDeleteCommand>, FailureModel>>;

	public abstract handleDeleteBulkCommand(
		command: TakenMedicationDeleteBulkCommand
	): Observable<Result<EmptyCommandResult<TakenMedicationDeleteBulkCommand>, FailureModel>>;

	public abstract handleFinishCourseCommand(
		command: TakenMedicationFinishCourseCommand
	): Observable<Result<EmptyCommandResult<TakenMedicationFinishCourseCommand>, FailureModel>>;

	public abstract handleFinishCourseBulkCommand(
		command: TakenMedicationFinishCourseBulkCommand
	): Observable<Result<EmptyCommandResult<TakenMedicationFinishCourseBulkCommand>, FailureModel>>;

	public abstract handleFailCourseCommand(
		command: TakenMedicationFailCourseCommand
	): Observable<Result<EmptyCommandResult<TakenMedicationFailCourseCommand>, FailureModel>>;

}
