import { Injectable } from '@angular/core';
import { VaccinationClient } from '@nmn-communication/vaccination';
import { Result } from '@nmn-core/shared';
import { FailureModel } from '@nmn-domain/shared';
import { VaccinationCardGetQuery, VaccinationCardModel, VaccinationGetQuery, VaccinationModel, VaccinationQueryHandlerService } from '@nmn-domain/vaccination';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import {
	mapVaccinationCardDtoToModel,
	mapVaccinationCardGetQueryToParameter,
	mapVaccinationDtoToModel,
	mapVaccinationGetQueryToParameter
} from '../factories/vaccination.factory';

@Injectable()
export class VaccinationQueryHandlerViaClientService extends VaccinationQueryHandlerService {

	constructor(
		private readonly client: VaccinationClient
	) {
		super();
	}

	public handleVaccinationCardGetQuery(query: VaccinationCardGetQuery): Observable<Result<VaccinationCardModel, FailureModel>> {
		return this.client
			.getVaccinationCard(mapVaccinationCardGetQueryToParameter(query))
			.pipe(map(result => result.map(mapVaccinationCardDtoToModel, mapFailureToFailureModel)));
	}

	public handleGetQuery(query: VaccinationGetQuery): Observable<Result<VaccinationModel, FailureModel>> {
		return this.client
			.get(mapVaccinationGetQueryToParameter(query))
			.pipe(map(result => result.map(mapVaccinationDtoToModel, mapFailureToFailureModel)));
	}

}
