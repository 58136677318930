import { Injector, Provider } from '@angular/core';
import { UserSubscriptionClient } from '@nmn-communication/accounts';
import { userSubscriptionClientFactory } from './factories/user-subscription.client.factory';

export const accountsUserSubscriptionProviders: Array<Provider> = [
	{
		provide: UserSubscriptionClient,
		useFactory: userSubscriptionClientFactory,
		deps: [Injector]
	}
];
