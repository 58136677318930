import { Injector } from '@angular/core';
import { DiseaseClient } from '@nmn-communication/diseases';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { DiseaseFakeClientBuilder } from '../builders/disease.fake-client.builder';
import { DiseaseFakeClientConfigurationBuilder } from '../builders/disease.fake-client.configuration.builder';
import { DiseaseHttpClientBuilder } from '../builders/disease.http-client.builder';
import { DiseaseHttpClientConfigurationBuilder } from '../builders/disease.http-client.configuration.builder';
import { DiseaseFakeClient } from '../services/disease.fake-client';
import { DiseaseHttpClient } from '../services/disease.http-client';

export const diseaseClientFactory = (injector: Injector): DiseaseClient => {
	const useFake = environment?.api?.resources?.diseases?.useFake ||
		!isValueDefined(environment?.api?.resources?.diseases?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		diseaseFakeClientFactory(injector) :
		diseaseHttpClientFactory(injector);
};

export const diseaseFakeClientFactory = (
	injector: Injector
): DiseaseFakeClient => {
	const configuration = new DiseaseFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.diseases.serverDelayMs)
		.build();

	return new DiseaseFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const diseaseHttpClientFactory = (
	injector: Injector
): DiseaseHttpClient => {
	const configuration = new DiseaseHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.diseases?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.diseases.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.diseases?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new DiseaseHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
