import { MedicationAdmStatusClient, MedicationAdmStatusDescribedComboboxDto, MedicationAdmStatusFilterDto } from '@nmn-communication/medication-adm-statuses';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { wrapFuncCallAsFakeClientResponse } from '../../../../modules/fakes/databases/fake.utils';
import { MedicationAdmStatusFakeClientConfiguration } from '../configurations/medication-adm-status.fake-client.configuration';

export class MedicationAdmStatusFakeClient extends MedicationAdmStatusClient {

	constructor(
		private readonly configuration: MedicationAdmStatusFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulkAsComboboxes(filter: MedicationAdmStatusFilterDto): Observable<Result<Array<MedicationAdmStatusDescribedComboboxDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.medicationAdmStatusesTable.getComboboxesPagedCollection(filter),
			this.configuration.serverDelayMs
		);
	}

}
