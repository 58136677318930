import { AccountPageRestrictionsConfiguration } from './business-restrictions/accounts-page-restrictions.configuration';
import { DocuemntsPageRestrictionsConfiguration } from './business-restrictions/documents-page-restrictions.configuration';
import { EncountersPageRestrictionsConfiguration } from './business-restrictions/encounters-page-restrictions.configuration';
import { GeneticCardPageRestrictionsConfiguration } from './business-restrictions/genetic-card-page-restrictions.configuration';
import { PatientsPageRestrictionsConfiguration } from './business-restrictions/patients-page-restrictions.configuration';
import { TakenMedicationsPageRestrictionsConfiguration } from './business-restrictions/taken-medications-page-restrictions.configuration';
import { TreatmentCheckerPageRestrictionsConfiguration } from './business-restrictions/treatment-checker-page-restrictions.configuration';
import { VaccinationPageRestrictionsConfiguration } from './business-restrictions/vaccination-page-restrictions.configuration';

export class BusinessRestrictionsConfiguration {

	public readonly accountPageRestrictionsConfiguration: AccountPageRestrictionsConfiguration;
	public readonly docuemntsPageRestrictionsConfiguration: DocuemntsPageRestrictionsConfiguration;
	public readonly encountersPageRestrictionsConfiguration: EncountersPageRestrictionsConfiguration;
	public readonly geneticCardPageRestrictionsConfiguration: GeneticCardPageRestrictionsConfiguration;
	public readonly patientsPageRestrictionsConfiguration: PatientsPageRestrictionsConfiguration;
	public readonly takenMedicationsPageRestrictionsConfiguration: TakenMedicationsPageRestrictionsConfiguration;
	public readonly treatmentCheckerPageRestrictionsConfiguration: TreatmentCheckerPageRestrictionsConfiguration;
	public readonly vaccinationPageRestrictionsConfiguration: VaccinationPageRestrictionsConfiguration;

	constructor(
		accountPageRestrictionsConfiguration: AccountPageRestrictionsConfiguration,
		docuemntsPageRestrictionsConfiguration: DocuemntsPageRestrictionsConfiguration,
		encountersPageRestrictionsConfiguration: EncountersPageRestrictionsConfiguration,
		geneticCardPageRestrictionsConfiguration: GeneticCardPageRestrictionsConfiguration,
		patientsPageRestrictionsConfiguration: PatientsPageRestrictionsConfiguration,
		takenMedicationsPageRestrictionsConfiguration: TakenMedicationsPageRestrictionsConfiguration,
		treatmentCheckerPageRestrictionsConfiguration: TreatmentCheckerPageRestrictionsConfiguration,
		vaccinationPageRestrictionsConfiguration: VaccinationPageRestrictionsConfiguration
	) {
		this.accountPageRestrictionsConfiguration = accountPageRestrictionsConfiguration;
		this.docuemntsPageRestrictionsConfiguration = docuemntsPageRestrictionsConfiguration;
		this.encountersPageRestrictionsConfiguration = encountersPageRestrictionsConfiguration;
		this.geneticCardPageRestrictionsConfiguration = geneticCardPageRestrictionsConfiguration;
		this.patientsPageRestrictionsConfiguration = patientsPageRestrictionsConfiguration;
		this.takenMedicationsPageRestrictionsConfiguration = takenMedicationsPageRestrictionsConfiguration;
		this.treatmentCheckerPageRestrictionsConfiguration = treatmentCheckerPageRestrictionsConfiguration;
		this.vaccinationPageRestrictionsConfiguration = vaccinationPageRestrictionsConfiguration;
	}

	public static createDefault(): BusinessRestrictionsConfiguration {
		return new BusinessRestrictionsConfiguration(
			AccountPageRestrictionsConfiguration.createDefault(),
			DocuemntsPageRestrictionsConfiguration.createDefault(),
			EncountersPageRestrictionsConfiguration.createDefault(),
			GeneticCardPageRestrictionsConfiguration.createDefault(),
			PatientsPageRestrictionsConfiguration.createDefault(),
			TakenMedicationsPageRestrictionsConfiguration.createDefault(),
			TreatmentCheckerPageRestrictionsConfiguration.createDefault(),
			VaccinationPageRestrictionsConfiguration.createDefault()
		);
	}

}
