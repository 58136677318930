import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FailureModel } from '../../shared/failures/failure.model';
import { HealthComplaintComboboxModel } from '../models/health-complaint-combobox.model';
import { HealthComplaintFilterModel } from '../models/health-complaint-filter.model';

export abstract class HealthComplaintQueryHandlerService {

	public abstract getAsDescribedComboboxes(
		query: HealthComplaintFilterModel
	): Observable<Result<Array<HealthComplaintComboboxModel>, FailureModel>>;

}
