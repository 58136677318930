import { HttpClientConfigurationBuilder } from '../../../../../communication/implementation/shared/builders/http-client.configuration.builder';
import { UserConfigurationHttpClientConfiguration } from '../configurations/user-configuration.http-client.configuration';

export class UserConfigurationHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<UserConfigurationHttpClientConfiguration> {

	public build(): UserConfigurationHttpClientConfiguration {
		return new UserConfigurationHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
