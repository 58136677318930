import { Provider } from '@angular/core';
import { CurrentUserConfigurationCommandHandlerService } from '@nmn-domain/accounts/user-configuration/services/current-user-configuration.command-handler.service';
import { CurrentUserConfigurationQueryHandlerService } from '@nmn-domain/accounts/user-configuration/services/current-user-configuration.query-handler.service';
import { CurrentUserConfigurationCommandHandlerViaClientService } from './services/current-user-configuration.command-handler-via-client.service';
import { CurrentUserConfigurationQueryHandlerViaClientService } from './services/current-user-configuration.query-handler-via-client.service';

export const accountsUserConfigurationProviders: Array<Provider> = [
	{
		provide: CurrentUserConfigurationCommandHandlerService,
		useClass: CurrentUserConfigurationCommandHandlerViaClientService
	},
	{
		provide: CurrentUserConfigurationQueryHandlerService,
		useClass: CurrentUserConfigurationQueryHandlerViaClientService
	}
];
