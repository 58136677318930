import { Result } from '@nmn-core/shared';
import { EmptyCommandResult, FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { FileUploadCancelUpdateCommand } from '../models/commands/file-upload-cancel.update.command';
import { FileUploadCreateCommand } from '../models/commands/file-upload.create.command';
import { UploadedFileModel } from '../models/uploaded-file.model';

export abstract class FileUploaderCommandHandlerService {

	public abstract upload(command: FileUploadCreateCommand): Observable<Result<UploadedFileModel, FailureModel>>;

	public abstract cancelUpload(command: FileUploadCancelUpdateCommand): Observable<Result<EmptyCommandResult<FileUploadCancelUpdateCommand>, FailureModel>>;

}
