import { isValueDefined } from '@nmn-core/utils';

export enum MedicationType {

	Undefined = 'undefined',
	AlimentaryTractAndMetabolism = 'alimentaryTractAndMetabolism',
	BloodAndBloodFormingOrgans = 'bloodAndBloodFormingOrgans',
	CardiovascularSystem = 'cardiovascularSystem',
	Dermatologicals = 'dermatologicals',
	GenitoUrinarySystemAndSexHormones = 'genitoUrinarySystemAndSexHormones',
	SystemicHormonalPreparations = 'systemicHormonalPreparations',
	AntiinfectivesForSystemicUse = 'antiinfectivesForSystemicUse',
	AntineoplasticAndImmunomodulatingAgents = 'antineoplasticAndImmunomodulatingAgents',
	MusculoSkeletalSystem = 'musculoSkeletalSystem',
	NervousSystem = 'nervousSystem',
	AntiparasiticProductsInsecticidesAndRepellents = 'antiparasiticProductsInsecticidesAndRepellents',
	RespiratorySystem = 'respiratorySystem',
	SensoryOrgans = 'sensoryOrgans',
	Various = 'various'

}

export const mapMedicationTypeFromString = (stringValue: string): MedicationType => {
	const enumValue = Object
		.keys(MedicationType)
		.find(x => MedicationType[x] === stringValue);

	return isValueDefined(enumValue) ? stringValue as MedicationType : MedicationType.Undefined;
};
