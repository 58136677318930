import { Injectable } from '@angular/core';
import { LanguageClient } from '@nmn-communication/languages';
import { Result } from '@nmn-core/shared';
import { LanguageFilterModel, LanguageQueryHandlerService } from '@nmn-domain/languages';
import { ComboboxModel, FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapComboboxDtoToComboboxModel } from '../../shared/factories/combobox.factory';
import { mapDtoArrayResultToModelArrayResult } from '../../shared/factories/pagination.factory';
import { mapFilterModelToDto } from '../factories/language.factory';

@Injectable()
export class LanguageQueryHandlerViaClientService extends LanguageQueryHandlerService {

	constructor(
		private readonly client: LanguageClient
	) {
		super();
	}

	public getAsComboboxes(filter: LanguageFilterModel): Observable<Result<Array<ComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapComboboxDtoToComboboxModel))
			);
	}

}
