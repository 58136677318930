import { AzureStorageClient, AzureStorageCreateParameterDto, AzureStorageInitedUploadDto } from '@nmn-communication/azure-file-storage';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';

export class AzureStorageFakeClient extends AzureStorageClient {

	public initBlobUpload(
		parameter: AzureStorageCreateParameterDto
	): Observable<Result<AzureStorageInitedUploadDto, Failure>> {
		const dto: AzureStorageInitedUploadDto = {
			data: undefined
		};

		of(undefined)
			.pipe(delay(1000))
			.subscribe(_ => {
				parameter.onProgress(
					{
						loadedBytes: parameter.file.size * 0.3
					});
			});

		of(undefined)
			.pipe(delay(2000))
			.subscribe(_ => {
				parameter.onProgress(
					{
						loadedBytes: parameter.file.size * 0.5
					});
			});

		of(undefined)
			.pipe(delay(3000))
			.subscribe(_ => {
				parameter.onProgress(
					{
						loadedBytes: parameter.file.size * 0.7
					});
			});

		of(undefined)
			.pipe(delay(4000))
			.subscribe(_ => {
				parameter.onProgress(
					{
						loadedBytes: parameter.file.size * 0.9
					});
			});

		of(undefined)
			.pipe(delay(5000))
			.subscribe(_ => {
				parameter.onProgress(
					{
						loadedBytes: parameter.file.size * 1
					});
			});

		return of(undefined)
			.pipe(
				delay(6000),
				map(_ => Result.success<AzureStorageInitedUploadDto, Failure>(dto)));
	}

}
