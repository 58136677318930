import { Injector } from '@angular/core';
import { LocalStorageService } from '@nmn-core/application-storages';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { TreatmentCheckerFakeClientConfiguration } from '../configurations/treatment-checker-fake-client.configuration';
import { TreatmentCheckerFakeClient } from '../services/treatment-checker.fake-client';

export class TreatmentCheckerFakeClientBuilder {

	private configuration: TreatmentCheckerFakeClientConfiguration;

	constructor(private readonly injector: Injector) { }

	public withConfiguration(
		configuration: TreatmentCheckerFakeClientConfiguration
	): TreatmentCheckerFakeClientBuilder {

		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;

	}

	public build(): TreatmentCheckerFakeClient {

		return new TreatmentCheckerFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase),
			this.injector.get(LocalStorageService));

	}

}
