import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DoctorEncounterStatus, DoctorEncounterStatusesResolver } from '@nmn-domain/doctor-encounters';
import { ComboboxModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';

@Injectable()
export class DoctorEncounterStatusesViaEnumResolver extends DoctorEncounterStatusesResolver {

	public resolve(
		__: ActivatedRouteSnapshot,
		_: RouterStateSnapshot
	): Observable<Array<ComboboxModel>> | Array<ComboboxModel> {
		return Object
			.keys(DoctorEncounterStatus)
			.map(enumValue => new ComboboxModel(DoctorEncounterStatus[enumValue], DoctorEncounterStatus[enumValue]));
	}

}
