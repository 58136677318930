import { Provider } from '@angular/core';
import { TimezoneQueryHandlerService, TimezonesResolver } from '@nmn-domain/timezones';
import { TimezonesViaQueryHandlerResolver } from './resolvers/timezones.via-query-handler.resolver';
import { TimezoneQueryHandlerViaClientService } from './services/timezone.query-handler-via-client.service';

export const timezoneProviders: Array<Provider> = [
	{
		provide: TimezoneQueryHandlerService,
		useClass: TimezoneQueryHandlerViaClientService
	},
	{
		provide: TimezonesResolver,
		useClass: TimezonesViaQueryHandlerResolver
	}
];
