import { Injector, Provider } from '@angular/core';
import { HealthIssueSeverityClient } from '@nmn-communication/health-issue-severities';
import { healthIssueSeverityClientFactory } from './factories/health-issue-severity.client.factory';

export const healthIssueSeverityProviders: Array<Provider> = [
	{
		provide: HealthIssueSeverityClient,
		useFactory: healthIssueSeverityClientFactory,
		deps: [Injector]
	}
];
