import { isValueDefined } from '@nmn-core/utils';

export enum HealthIssueStatus {

	Active = 'active',
	Recurrence = 'recurrence',
	Relapse = 'relapse',
	Inactive = 'inactive',
	Remission = 'remission',
	Resolved = 'resolved'

}

export const mapHealthIssueStatusFromString = (stringValue: string): HealthIssueStatus => {
	const enumValue = Object
		.keys(HealthIssueStatus)
		.find(x => HealthIssueStatus[x] === stringValue);

	return isValueDefined(enumValue) ? stringValue as HealthIssueStatus : HealthIssueStatus.Active;
};
