import { DocumentFormOutModel } from '../../../documents';
import { TakenMedicationDescribedComboboxModel } from '../taken-medication-described-combobox.model';

export class TakenMedicationFormOutModel {

	// Is used to be able to show model as an inline view
	public readonly comboboxView: TakenMedicationDescribedComboboxModel;

	public readonly id: string | undefined;
	public readonly takingPeriod: { from: Date | undefined; to: Date | undefined; };
	public readonly comment: string | undefined;
	public readonly dosage: { value: number | undefined; typeId: string | undefined };
	public readonly rate: { value: number | undefined; typeId: string | undefined };
	public readonly patientId: string;
	public readonly medicationId: string;
	public readonly healthIssueId: string | undefined;
	public readonly encounterId: string | undefined;
	public readonly medicationAdmStatusId: string | undefined;
	public readonly medicationAdmRouteId: string | undefined;
	public readonly medicationBodySiteId: string | undefined;
	public readonly documentIds: Array<string>;
	public readonly documents: Array<DocumentFormOutModel>;
	public readonly typeOfMedicationId: string;

	constructor(
		comboboxView: TakenMedicationDescribedComboboxModel,
		id: string | undefined,
		takingPeriod: { from: Date | undefined, to: Date | undefined },
		comment: string | undefined,
		dosage: { value: number | undefined, typeId: string | undefined },
		rate: { value: number | undefined, typeId: string | undefined },
		patientId: string,
		medicationId: string,
		healthIssueId: string | undefined,
		encounterId: string | undefined,
		medicationAdmStatusId: string | undefined,
		medicationAdmRouteId: string | undefined,
		medicationBodySiteId: string | undefined,
		documentIds: Array<string>,
		documents: Array<DocumentFormOutModel>,
		typeOfMedicationId: string
	) {
		// TODO: Guard check on defined
		this.id = id;
		this.takingPeriod = takingPeriod;
		this.comment = comment;
		this.dosage = dosage;
		this.rate = rate;
		this.patientId = patientId;
		this.medicationId = medicationId;
		this.healthIssueId = healthIssueId;
		this.encounterId = encounterId;
		this.medicationAdmStatusId = medicationAdmStatusId;
		this.medicationAdmRouteId = medicationAdmRouteId;
		this.medicationBodySiteId = medicationBodySiteId;
		this.documentIds = documentIds;
		this.comboboxView = comboboxView;
		this.documents = documents;
		this.typeOfMedicationId = typeOfMedicationId;
	}

}
