import { PatientModel } from '@nmn-domain/patients';

export class TreatmentCheckerAnamnesisFormInModel {

	public readonly hasHealthIssuesWithLiver?: boolean;
	public readonly hasHealthIssuesWithKidney?: boolean;
	public readonly hasHealthIssuesWithHeart?: boolean;
	public readonly hasHealthIssuesWithStomach?: boolean;
	public readonly hasHealthIssuesWithNervousSystem?: boolean;
	public readonly hasHealthIssuesWithImmuneSystem?: boolean;
	public readonly hasHealthIssuesWithEndocrine?: boolean;
	public readonly hasHealthIssuesWithCancer?: boolean;
	public readonly hasHealthIssuesWithEye?: boolean;
	public readonly hasHealthIssuesWithEar?: boolean;
	public readonly hasHealthIssuesWithMuscles?: boolean;
	public readonly hasHealthIssuesWithGeneticConditions?: boolean;

	private constructor(
		hasHealthIssuesWithLiver: boolean | undefined,
		hasHealthIssuesWithKidney: boolean | undefined,
		hasHealthIssuesWithHeart: boolean | undefined,
		hasHealthIssuesWithStomach: boolean | undefined,
		hasHealthIssuesWithNervousSystem: boolean | undefined,
		hasHealthIssuesWithImmuneSystem: boolean | undefined,
		hasHealthIssuesWithEndocrine: boolean | undefined,
		hasHealthIssuesWithCancer: boolean | undefined,
		hasHealthIssuesWithEye: boolean | undefined,
		hasHealthIssuesWithEar: boolean | undefined,
		hasHealthIssuesWithMuscles: boolean | undefined,
		hasHealthIssuesWithGeneticConditions: boolean | undefined
	) {
		this.hasHealthIssuesWithLiver = hasHealthIssuesWithLiver;
		this.hasHealthIssuesWithKidney = hasHealthIssuesWithKidney;
		this.hasHealthIssuesWithHeart = hasHealthIssuesWithHeart;
		this.hasHealthIssuesWithStomach = hasHealthIssuesWithStomach;
		this.hasHealthIssuesWithNervousSystem = hasHealthIssuesWithNervousSystem;
		this.hasHealthIssuesWithImmuneSystem = hasHealthIssuesWithImmuneSystem;
		this.hasHealthIssuesWithEndocrine = hasHealthIssuesWithEndocrine;
		this.hasHealthIssuesWithCancer = hasHealthIssuesWithCancer;
		this.hasHealthIssuesWithEye = hasHealthIssuesWithEye;
		this.hasHealthIssuesWithEar = hasHealthIssuesWithEar;
		this.hasHealthIssuesWithMuscles = hasHealthIssuesWithMuscles;
		this.hasHealthIssuesWithGeneticConditions = hasHealthIssuesWithGeneticConditions;
	}

	public static createEmpty = (): TreatmentCheckerAnamnesisFormInModel => {
		return new TreatmentCheckerAnamnesisFormInModel(
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined
		);
	}

	public static createByPatient = (patient: PatientModel): TreatmentCheckerAnamnesisFormInModel => {
		return new TreatmentCheckerAnamnesisFormInModel(
			patient.anamnesis.hasHealthIssuesWithLiver,
			patient.anamnesis.hasHealthIssuesWithKidney,
			patient.anamnesis.hasHealthIssuesWithHeart,
			patient.anamnesis.hasHealthIssuesWithStomach,
			patient.anamnesis.hasHealthIssuesWithNervousSystem,
			patient.anamnesis.hasHealthIssuesWithImmuneSystem,
			patient.anamnesis.hasHealthIssuesWithEndocrine,
			patient.anamnesis.hasHealthIssuesWithCancer,
			patient.anamnesis.hasHealthIssuesWithEye,
			patient.anamnesis.hasHealthIssuesWithEar,
			patient.anamnesis.hasHealthIssuesWithMuscles,
			patient.anamnesis.hasHealthIssuesWithGeneticConditions
		);
	}

	public static createForPredefinedData = (
		hasHealthIssuesWithLiver: boolean | undefined,
		hasHealthIssuesWithKidney: boolean | undefined,
		hasHealthIssuesWithHeart: boolean | undefined,
		hasHealthIssuesWithStomach: boolean | undefined,
		hasHealthIssuesWithNervousSystem: boolean | undefined,
		hasHealthIssuesWithImmuneSystem: boolean | undefined,
		hasHealthIssuesWithEndocrine: boolean | undefined,
		hasHealthIssuesWithCancer: boolean | undefined,
		hasHealthIssuesWithEye: boolean | undefined,
		hasHealthIssuesWithEar: boolean | undefined,
		hasHealthIssuesWithMuscles: boolean | undefined,
		hasHealthIssuesWithGeneticConditions: boolean | undefined
	): TreatmentCheckerAnamnesisFormInModel => {
		return new TreatmentCheckerAnamnesisFormInModel(
			hasHealthIssuesWithLiver,
			hasHealthIssuesWithKidney,
			hasHealthIssuesWithHeart,
			hasHealthIssuesWithStomach,
			hasHealthIssuesWithNervousSystem,
			hasHealthIssuesWithImmuneSystem,
			hasHealthIssuesWithEndocrine,
			hasHealthIssuesWithCancer,
			hasHealthIssuesWithEye,
			hasHealthIssuesWithEar,
			hasHealthIssuesWithMuscles,
			hasHealthIssuesWithGeneticConditions
		);
	}

}
