import { isArrayDefinedAndNotEmpty } from '@nmn-core/utils';
import { TreatmentCheckerReportCautionModel } from './report-parts/treatment-checker-report-caution.model';
import { TreatmentCheckerReportTreatmentInfoModel } from './report-parts/treatment-checker-report-treatment-info.model';

export class TreatmentCheckerReportShortModel {

	public readonly id: string;
	public readonly createdOn: Date;
	public readonly treatmentInfo: TreatmentCheckerReportTreatmentInfoModel;
	public readonly cautions: Array<TreatmentCheckerReportCautionModel>;

	public get isCautionsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.cautions);
	}

	public get isDiseasesDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.treatmentInfo?.diseases);
	}

	public get isMedicationsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.treatmentInfo?.medications);
	}

	public get diseasesAsString(): string {
		return this.treatmentInfo?.diseases
			.map(combobox => combobox.displayText)
			.join(', ');
	}

	public get medicationsAsString(): string {
		return this.treatmentInfo?.medications
			.map(combobox => combobox.displayText)
			.join(', ');
	}

	public get mainDisease(): string {
		return this.isDiseasesDefined ? this.treatmentInfo.diseases[0].displayText : '';
	}

	public get hasMultiDiseases(): boolean {
		return this.isDiseasesDefined && this.treatmentInfo.diseases.length > 1;
	}

	public get otherDiseasesCount(): string {
		return this.isDiseasesDefined && (this.treatmentInfo.diseases.length > 1) ?
			`${this.treatmentInfo.diseases.length - 1}` :
			'';
	}

	public get mainMedication(): string {
		return this.isMedicationsDefined ? this.treatmentInfo.medications[0].displayText : '';
	}

	public get hasMultiMedications(): boolean {
		return this.isMedicationsDefined && this.treatmentInfo.medications.length > 1;
	}

	public get otherMedicationsCount(): string {
		return this.isMedicationsDefined && (this.treatmentInfo.medications.length > 1) ?
			`${this.treatmentInfo.medications.length - 1}` :
			'';
	}

	constructor(
		id: string,
		createdOn: Date,
		treatmentInfo: TreatmentCheckerReportTreatmentInfoModel,
		cautions: Array<TreatmentCheckerReportCautionModel>
	) {
		// TODO: Guard check on defined

		this.id = id;
		this.createdOn = createdOn;
		this.cautions = cautions;
		this.treatmentInfo = treatmentInfo;
	}

}
