import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneticTestFormConfiguration } from '../models/genetic-test-form/genetic-test-form.configuration';

export abstract class GeneticTestFormConfigurationResolver implements Resolve<GeneticTestFormConfiguration> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		routerState: RouterStateSnapshot
	): Observable<GeneticTestFormConfiguration> | Promise<GeneticTestFormConfiguration> | GeneticTestFormConfiguration;

}
