import { Injector, Provider } from '@angular/core';
import { LanguageClient } from '@nmn-communication/languages';
import { languageClientFactory } from './factories/language.client.factory';

export const languageProviders: Array<Provider> = [
	{
		provide: LanguageClient,
		useFactory: languageClientFactory,
		deps: [Injector]
	}
];
