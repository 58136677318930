import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { DiseaseComboboxDto } from '../models/disease-combobox.dto';
import { DiseaseFilterDto } from '../models/disease-filter.dto';

export abstract class DiseaseClient {

	public abstract getBulkAsComboboxes(filter: DiseaseFilterDto): Observable<Result<Array<DiseaseComboboxDto>, Failure>>;

}
