import { HealthIssueCreateParameterDto, HealthIssueUpdateParameterDto } from '@nmn-communication/health-issues';
import { mapDateToServerFormat } from '@nmn-core/utils';
import { HealthIssueCreateCommand, HealthIssueUpdateCommand } from '@nmn-domain/health-issues';
import { mapCreateCommandToParameter as mapDoctorEncounterCreateCommandToParameter } from '../../doctor-encounters/factories/doctor-encounter-command.factory';
import { mapCreateCommandToParameter as mapDocumentCreateCommandToParameter } from '../../documents/factories/document.factory';
import { mapCreateCommandToCreateParameter as mapTakenMedicationCreateCommandToParameter } from '../../taken-medications/factories/taken-medication.factory';

export const mapCreateCommandToParameter = (command: HealthIssueCreateCommand): HealthIssueCreateParameterDto => (
	{
		name: command.name,
		comment: command.comment,
		dateStart: mapDateToServerFormat(command.dateStart),
		dateEnd: mapDateToServerFormat(command.dateEnd),
		status: command.status,
		severityId: Number(command.severityId),
		patientId: command.patientId,
		healthComplaintIds: command.healthComplaintIds,
		encounterIds: command.encounterIds,
		encounters: command.doctorEncounters.map(mapDoctorEncounterCreateCommandToParameter),
		takenMedicationIds: command.takenMedicationIds,
		takenMedications: command.takenMedications.map(mapTakenMedicationCreateCommandToParameter),
		documentIds: command.documentIds,
		documents: command.documents.map(mapDocumentCreateCommandToParameter)
	}
);

export const mapUpdateCommandToParameter = (command: HealthIssueUpdateCommand): HealthIssueUpdateParameterDto => (
	{
		name: command.name,
		comment: command.comment,
		dateStart: mapDateToServerFormat(command.dateStart),
		dateEnd: mapDateToServerFormat(command.dateEnd),
		status: command.status,
		severityId: Number(command.severityId),
		patientId: command.patientId,
		healthComplaintIds: command.healthComplaintIds,
		encounterIds: command.encounterIds,
		encounters: command.doctorEncounters.map(mapDoctorEncounterCreateCommandToParameter),
		takenMedicationIds: command.takenMedicationIds,
		takenMedications: command.takenMedications.map(mapTakenMedicationCreateCommandToParameter),
		documentIds: command.documentIds,
		documents: command.documents.map(mapDocumentCreateCommandToParameter)
	}
);
