import { isArrayDefinedAndNotEmpty } from '@nmn-core/utils';
import { DiseaseComboboxModel } from '@nmn-domain/diseases';
import { MedicationComboboxModel } from '@nmn-domain/medications';
import { PopulationComboboxModel } from '@nmn-domain/populations';
import { DescribedComboboxModel } from '@nmn-domain/shared';
import { QueryParameterValueForChecker } from '@nmn-middleware/navigation';
import { TreatmentCheckerReportAnamnesisModel } from './treatment-checker-report-anamnesis.model';
import { TreatmentCheckerReportLifestyleModel } from './treatment-checker-report-lifestyle.model';

export class TreatmentCheckerReportTreatmentInfoModel {

	public readonly population?: PopulationComboboxModel;
	public readonly gender?: DescribedComboboxModel;
	public readonly age?: number;
	public readonly weight?: number;
	public readonly height?: number;
	public readonly diseases: Array<DiseaseComboboxModel>;
	public readonly medications: Array<MedicationComboboxModel>;
	public readonly anamnesis: TreatmentCheckerReportAnamnesisModel;
	public readonly lifestyle: TreatmentCheckerReportLifestyleModel;

	public get isDiseasesDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.diseases);
	}

	public get isMedicationsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.medications);
	}

	constructor(
		population: PopulationComboboxModel | undefined,
		gender: DescribedComboboxModel | undefined,
		age: number | undefined,
		weight: number | undefined,
		height: number | undefined,
		diseases: Array<DiseaseComboboxModel>,
		medications: Array<MedicationComboboxModel>,
		anamnesis: TreatmentCheckerReportAnamnesisModel,
		lifestyle: TreatmentCheckerReportLifestyleModel
	) {
		// TODO: Guard.IsDefined

		this.population = population;
		this.gender = gender;
		this.age = age;
		this.weight = weight;
		this.height = height;
		this.diseases = diseases;
		this.medications = medications;
		this.anamnesis = anamnesis;
		this.lifestyle = lifestyle;
	}

	public transformToTreatmentCheckerQueryParams(): any {
		const queryParams = {};

		queryParams[QueryParameterValueForChecker.PopulationId] = this.population?.id;
		queryParams[QueryParameterValueForChecker.GenderId] = this.gender?.id;
		queryParams[QueryParameterValueForChecker.Age] = JSON.stringify(this.age);
		queryParams[QueryParameterValueForChecker.Weight] = JSON.stringify(this.weight);
		queryParams[QueryParameterValueForChecker.Height] = JSON.stringify(this.height);
		queryParams[QueryParameterValueForChecker.Lifestyle] = this.lifestyle.getCode();
		queryParams[QueryParameterValueForChecker.Anamnesis] = this.anamnesis.getCode();
		queryParams[QueryParameterValueForChecker.DiseaseIds] = JSON.stringify(this.diseases.map(x => x.id));
		queryParams[QueryParameterValueForChecker.MedicationIds] = JSON.stringify(this.medications.map(x => x.id));

		return queryParams;
	}

}
