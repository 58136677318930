import { Injector } from '@angular/core';
import { LocalStorageService } from '../../../../../core/application-storages';
import { FakeDatabase } from '../../../../../modules/fakes/databases/fake.database';
import { isValueDefined } from '@nmn-core/utils';
import { UserProfileFakeClientConfiguration } from '../configurations/user-profile.fake-client.configuration';
import { UserProfileFakeClient } from '../services/user-profile.fake-client';

export class UserProfileFakeClientBuilder {

	private configuration: UserProfileFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(configuration: UserProfileFakeClientConfiguration): UserProfileFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): UserProfileFakeClient {
		return new UserProfileFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase),
			this.injector.get(LocalStorageService)
		);
	}

}
