export abstract class HttpClientConfiguration {

	public readonly baseUrl: string;
	public readonly resourceRelativePath: string;
	public readonly resourceTypeVersion: string;

	constructor(
		baseUrl: string,
		resourceRelativePath: string,
		resourceTypeVersion: string
	) {
		this.baseUrl = baseUrl;
		this.resourceRelativePath = resourceRelativePath;
		this.resourceTypeVersion = resourceTypeVersion;
	}

}
