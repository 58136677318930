import { TranslocoService } from '@ngneat/transloco';
import { GeneVariantComboboxDto, GeneVariantFilterDto } from '@nmn-communication/gene-variants';
import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { PharmacogenomicTestResultCategory } from '@nmn-domain/pharmacogenomic-test-results';
import { FakeDatabase } from '../../databases/fake.database';
import { getPagedCollectionWithoutDtoFilter } from '../../databases/fake.utils';
import { FakeGeneVariantComboboxDto, mapFakeGeneVariantComboboxToComboboxDto } from '../../models/comboboxes/fake-gene-variant-combobox.dto';
import { FakeLocalizableEntity } from '../../models/fake-localizable-entity';
import { setTranslation } from '../../utils/localize';

export class GeneVariantFakeTable {

	private readonly data: Array<GeneVariantFakeRecord>;

	constructor(
		private readonly database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.data = [...initialData];
	}

	public getAsCombobox(parameter: { id: string }): GeneVariantComboboxDto {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record was not found');
		}

		return record;
	}

	public findAsCombobox(parameter: { id: string }): GeneVariantComboboxDto {
		const record = this.data.find((item: GeneVariantFakeRecord) => findPredicate(item, parameter));

		const comboboxRecord = this.mapFromRecordToDescribedComboboxDto(record!);

		return mapFakeGeneVariantComboboxToComboboxDto(this.translocoService, comboboxRecord!);
	}

	public getComboboxesPagedCollection(filter: GeneVariantFilterDto): Array<GeneVariantComboboxDto> {
		const pagedCollection = getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			filterPredicateForRecord,
			this.mapFromRecordToDescribedComboboxDto,
			compareFn
		).items;

		return pagedCollection.map(item => mapFakeGeneVariantComboboxToComboboxDto(this.translocoService, item));
	}

	private mapFromRecordToDescribedComboboxDto = (record: GeneVariantFakeRecord): FakeGeneVariantComboboxDto => {
		return isValueDefined(record) ?
			{
				id: record.id,
				displayText: record.name,
				description: record.description,
				gene: this.database.genesTable.getAsCombobox({ id: record.geneId }),
				allele1: record.allele1,
				allele2: record.allele2,
				pharmacogenomicTestResult: record.pharmacogenomicTestResult
			} :
			undefined!;
	}

}

const findPredicate = (item: GeneVariantFakeRecord, findParameter: { id: string }): boolean =>
	item.id === findParameter.id;

const filterPredicateForRecord = (item: GeneVariantFakeRecord, filter: GeneVariantFilterDto, translocoService: TranslocoService): boolean => {
	let result = true;

	if (result && isStringDefinedAndNotEmpty(filter.geneId)) {
		result = result && item.geneId === filter.geneId;
	}

	if (result && isArrayDefinedAndNotEmpty(filter.ids)) {
		result = result && filter.ids!.some(id => item.id === id);
	}

	if (result && isArrayDefinedAndNotEmpty(filter.ignoreIds)) {
		result = result && filter.ignoreIds!.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result &&
			setTranslation(translocoService, item.name)
				.toLowerCase()
				.indexOf(filter.searchPattern!.toLowerCase()) >= 0;
	}

	if (result && isStringDefinedAndNotEmpty(filter.allele1)) {
		result = result && filter.allele1 === item.allele1 && isStringDefinedAndNotEmpty(item.allele2);
	} else {
		result = result && !isStringDefinedAndNotEmpty(item.allele2);
	}

	return result;
};

/* eslint-disable  */
/* eslint-disable complexity */
const compareFn = (item1: FakeGeneVariantComboboxDto, item2: FakeGeneVariantComboboxDto, sorting: string): number => {
	if (sorting === 'displayText asc') {
		return item1.displayText > item2.displayText ? 1 : item1.displayText < item2.displayText ? -1 : 0;
	} else if (sorting === 'displayText desc') {
		return item1.displayText < item2.displayText ? 1 : item1.displayText > item2.displayText ? -1 : 0;
	}

	return 0;
};
/* eslint-enable complexity */
/* eslint-enable */

interface GeneVariantFakeRecord {
	id: string;
	geneId: string;
	allele1: string;
	allele2?: string;
	name: FakeLocalizableEntity;
	description: FakeLocalizableEntity;
	pharmacogenomicTestResult: {
		id: string;
		displayText: FakeLocalizableEntity;
		description?: FakeLocalizableEntity;
		category: string;
	};
}

const initialData: Array<GeneVariantFakeRecord> = [
	{
		id: 'ADRB2*1',
		geneId: 'ADRB2',
		name: { en: '*1', uk: '*1' },
		description: { en: '-', uk: '-' },
		allele1: '*1',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'RapidMetabolizer',
			displayText: { en: 'Rapid metabolizer', uk: 'Rapid metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerRapid
		}
	},
	{
		id: 'ADRB2*2',
		geneId: 'ADRB2',
		name: { en: '*2', uk: '*2' },
		description: { en: '-', uk: '-' },
		allele1: '*2',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'LikelyPoorMetabolizer',
			displayText: { en: 'Likely poor metabolizer', uk: 'Likely poor metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerPoor
		}
	},
	{
		id: 'ADRB2*1*1',
		geneId: 'ADRB2',
		name: { en: '*1/*1', uk: '*1/*1' },
		description: { en: '-', uk: '-' },
		allele1: '*1',
		allele2: '*1',
		pharmacogenomicTestResult: {
			id: 'UltraRapidMetabolizer',
			displayText: { en: 'Ultra rapid metabolizer', uk: 'Ultra rapid metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerUltrarapid
		}
	},
	{
		id: 'ADRB2*2*1',
		geneId: 'ADRB2',
		name: { en: '*2/*1', uk: '*2/*1' },
		description: { en: '-', uk: '-' },
		allele1: '*2',
		allele2: '*1',
		pharmacogenomicTestResult: {
			id: 'LikelyPoorMetabolizer',
			displayText: { en: 'Likely poor metabolizer', uk: 'Likely poor metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerPoor
		}
	},
	{
		id: 'ADRB2*2*2',
		geneId: 'ADRB2',
		name: { en: '*2/*2', uk: '*2/*2' },
		description: { en: '-', uk: '-' },
		allele1: '*2',
		allele2: '*2',
		pharmacogenomicTestResult: {
			id: 'PoorMetabolizer',
			displayText: { en: 'Poor metabolizer', uk: 'Poor metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerPoor
		}
	},
	{
		id: 'CYP2B6*1',
		geneId: 'CYP2B6',
		name: { en: '*1', uk: '*1' },
		description: { en: '-', uk: '-' },
		allele1: '*1',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'NormalMetabolizer',
			displayText: { en: 'Normal metabolizer', uk: 'Normal metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Normal
		}
	},
	{
		id: 'CYP2B6*2',
		geneId: 'CYP2B6',
		name: { en: '*2', uk: '*2' },
		description: { en: '-', uk: '-' },
		allele1: '*2',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'UltrarapidMetabolizer',
			displayText: { en: 'Ultrarapid metabolizer', uk: 'Ultrarapid metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerUltrarapid
		}
	},
	{
		id: 'CYP2B6*2*2',
		geneId: 'CYP2B6',
		name: { en: '*2/*2', uk: '*2/*2' },
		description: { en: '-', uk: '-' },
		allele1: '*2',
		allele2: '*2',
		pharmacogenomicTestResult: {
			id: 'NormalMetabolizer',
			displayText: { en: 'Normal metabolizer', uk: 'Normal metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Normal
		}
	},
	{
		id: 'CYP2C8*1',
		geneId: 'CYP2C8',
		name: { en: '*1', uk: '*1' },
		description: { en: '-', uk: '-' },
		allele1: '*1',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'DescreasedFunction',
			displayText: { en: 'Decreased function', uk: 'Decreased function' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Descreased
		}
	},
	{
		id: 'CYP2C8*2',
		geneId: 'CYP2C8',
		name: { en: '*2', uk: '*2' },
		description: { en: '-', uk: '-' },
		allele1: '*2',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'Normal',
			displayText: { en: 'Normal', uk: 'Normal' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Normal
		}
	},
	{
		id: 'CYP2C8*1*1',
		geneId: 'CYP2C8',
		name: { en: '*1/*1', uk: '*1/*1' },
		description: { en: '-', uk: '-' },
		allele1: '*1',
		allele2: '*1',
		pharmacogenomicTestResult: {
			id: 'NormalFunction',
			displayText: { en: 'Normal function', uk: 'Normal function' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Normal
		}
	},
	{
		id: 'CYP2C8*2*1',
		geneId: 'CYP2C8',
		name: { en: '*2/*1', uk: '*2/*1' },
		description: { en: '-', uk: '-' },
		allele1: '*2',
		allele2: '*1',
		pharmacogenomicTestResult: {
			id: 'Normal',
			displayText: { en: 'Normal', uk: 'Normal' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Normal
		}
	},
	{
		id: 'CYP2C9*1',
		geneId: 'CYP2C9',
		name: { en: '*1', uk: '*1' },
		description: { en: '-', uk: '-' },
		allele1: '*1',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'DescreasedFunction',
			displayText: { en: 'Decreased function', uk: 'Decreased function' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Descreased
		}
	},
	{
		id: 'CYP2C9*2',
		geneId: 'CYP2C9',
		name: { en: '*2', uk: '*2' },
		description: { en: '-', uk: '-' },
		allele1: '*2',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'DescreasedFunction',
			displayText: { en: 'Decreased function', uk: 'Decreased function' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Descreased
		}
	},
	{
		id: 'CYP2C9*9',
		geneId: 'CYP2C9',
		name: { en: '*9', uk: '*9' },
		description: { en: '-', uk: '-' },
		allele1: '*9',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'IntermediateMetabolizer',
			displayText: { en: 'Intermediate metabolizer', uk: 'Intermediate metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerIntermediate
		}
	},
	{
		id: 'CYP2C9*9*10',
		geneId: 'CYP2C9',
		name: { en: '*9/*10', uk: '*9/*10' },
		description: { en: '-', uk: '-' },
		allele1: '*9',
		allele2: '*10',
		pharmacogenomicTestResult: {
			id: 'UncertainSusceptibility',
			displayText: { en: 'Uncertain susceptibility', uk: 'Uncertain susceptibility' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Uncertain
		}
	},
	{
		id: 'CYP2C9*10',
		geneId: 'CYP2C9',
		name: { en: '*10', uk: '*10' },
		description: { en: '-', uk: '-' },
		allele1: '*10',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'UncertainSusceptibility',
			displayText: { en: 'Uncertain susceptibility', uk: 'Uncertain susceptibility' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Uncertain
		}
	},
	{
		id: 'CYP2C9*10*10',
		geneId: 'CYP2C9',
		name: { en: '*10/*10', uk: '*10/*10' },
		description: { en: '-', uk: '-' },
		allele1: '*10',
		allele2: '*10',
		pharmacogenomicTestResult: {
			id: 'UncertainSusceptibility',
			displayText: { en: 'Uncertain susceptibility', uk: 'Uncertain susceptibility' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Uncertain
		}
	},
	{
		id: 'CYP2C19*1',
		geneId: 'CYP2C19',
		name: { en: '*1', uk: '*1' },
		description: { en: '-', uk: '-' },
		allele1: '*1',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'Normal',
			displayText: { en: 'Normal', uk: 'Normal' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Normal
		}
	},
	{
		id: 'CYP2C19*2',
		geneId: 'CYP2C19',
		name: { en: '*2', uk: '*2' },
		description: { en: '-', uk: '-' },
		allele1: '*2',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'Intermediate',
			displayText: { en: 'Intermediate', uk: 'Intermediate' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerIntermediate
		}
	},
	{
		id: 'CYP2C19*2*1',
		geneId: 'CYP2C19',
		name: { en: '*2/*1', uk: '*2/*1' },
		description: { en: '-', uk: '-' },
		allele1: '*2',
		allele2: '*1',
		pharmacogenomicTestResult: {
			id: 'IntermediateMetabolizer',
			displayText: { en: 'Intermediate metabolizer', uk: 'Intermediate metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerIntermediate
		}
	},
	{
		id: 'CYP2C19*2*2',
		geneId: 'CYP2C19',
		name: { en: '*2/*2', uk: '*2/*2' },
		description: { en: '-', uk: '-' },
		allele1: '*2',
		allele2: '*2',
		pharmacogenomicTestResult: {
			id: 'RapidMetabolizer',
			displayText: { en: 'Rapid metabolizer', uk: 'Rapid metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerRapid
		}
	},
	{
		id: 'CYP2D6*1',
		geneId: 'CYP2D6',
		name: { en: '*1', uk: '*1' },
		description: { en: '-', uk: '-' },
		allele1: '*1',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'RapidMetabolizer',
			displayText: { en: 'Rapid metabolizer', uk: 'Rapid metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerRapid
		}
	},
	{
		id: 'CYP2D6*2',
		geneId: 'CYP2D6',
		name: { en: '*2', uk: '*2' },
		description: { en: '-', uk: '-' },
		allele1: '*2',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'PoorMetabolizer',
			displayText: { en: 'Poor metabolizer', uk: 'Poor metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerPoor
		}
	},
	{
		id: 'CYP2D6*10',
		geneId: 'CYP2D6',
		name: { en: '*10', uk: '*10' },
		description: { en: '-', uk: '-' },
		allele1: '*10',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'DescreasedFunction',
			displayText: { en: 'Decreased function', uk: 'Decreased function' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Descreased
		}
	},
	{
		id: 'CYP2D6*10*2',
		geneId: 'CYP2D6',
		name: { en: '*10/*2', uk: '*10/*2' },
		description: { en: '-', uk: '-' },
		allele1: '*10',
		allele2: '*2',
		pharmacogenomicTestResult: {
			id: 'NormalMetabolizer',
			displayText: { en: 'Normal metabolizer', uk: 'Normal metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Normal
		}
	},
	{
		id: 'CYP3A4*1',
		geneId: 'CYP3A4',
		name: { en: '*1', uk: '*1' },
		description: { en: '-', uk: '-' },
		allele1: '*1',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'RapidMetabolizer',
			displayText: { en: 'Rapid metabolizer', uk: 'Rapid metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerRapid
		}
	},
	{
		id: 'CYP3A4*2',
		geneId: 'CYP3A4',
		name: { en: '*2', uk: '*2' },
		description: { en: '-', uk: '-' },
		allele1: '*2',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'PoorMetabolizer',
			displayText: { en: 'Poor metabolizer', uk: 'Poor metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerPoor
		}
	},
	{
		id: 'CYP3A5*1',
		geneId: 'CYP3A5',
		name: { en: '*1', uk: '*1' },
		description: { en: '-', uk: '-' },
		allele1: '*1',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'NormalMetabolizer',
			displayText: { en: 'Normal metabolizer', uk: 'Normal metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Normal
		}
	},
	{
		id: 'CYP3A5*2',
		geneId: 'CYP3A5',
		name: { en: '*2', uk: '*2' },
		description: { en: '-', uk: '-' },
		allele1: '*2',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'PoorMetabolizer',
			displayText: { en: 'Poor metabolizer', uk: 'Poor metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerPoor
		}
	},
	{
		id: 'HTR2C*1',
		geneId: 'HTR2C',
		name: { en: '*1', uk: '*1' },
		description: { en: '-', uk: '-' },
		allele1: '*1',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'RapidMetabolizer',
			displayText: { en: 'Rapid metabolizer', uk: 'Rapid metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerRapid
		}
	},
	{
		id: 'HTR2C*2',
		geneId: 'HTR2C',
		name: { en: '*2', uk: '*2' },
		description: { en: '-', uk: '-' },
		allele1: '*2',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'PoorMetabolizer',
			displayText: { en: 'Poor metabolizer', uk: 'Poor metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerPoor
		}
	},
	{
		id: 'SLCO1B1*1',
		geneId: 'SLCO1B1',
		name: { en: '*1', uk: '*1' },
		description: { en: '-', uk: '-' },
		allele1: '*1',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'RapidMetabolizer',
			displayText: { en: 'Rapid metabolizer', uk: 'Rapid metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerRapid
		}
	},
	{
		id: 'SLCO1B1*2',
		geneId: 'SLCO1B1',
		name: { en: '*2', uk: '*2' },
		description: { en: '-', uk: '-' },
		allele1: '*2',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'PoorMetabolizer',
			displayText: { en: 'Poor metabolizer', uk: 'Poor metabolizer' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.MetabolizerPoor
		}
	},
	{
		id: 'SLCO1B1*14',
		geneId: 'SLCO1B1',
		name: { en: '*14', uk: '*14' },
		description: { en: '-', uk: '-' },
		allele1: '*14',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'IncreasedFunction',
			displayText: { en: 'Increased function', uk: 'Increased function' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Increased
		}
	},
	{
		id: 'SLCO1B1*20',
		geneId: 'SLCO1B1',
		name: { en: '*20', uk: '*20' },
		description: { en: '-', uk: '-' },
		allele1: '*20',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'IncreasedFunction',
			displayText: { en: 'Increased function', uk: 'Increased function' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Increased
		}
	},
	{
		id: 'SPATS2LT',
		geneId: 'SPATS2L',
		name: { en: 'T', uk: 'T' },
		description: { en: '-', uk: '-' },
		allele1: 'T',
		allele2: undefined,
		pharmacogenomicTestResult: {
			id: 'IncreasedFunction',
			displayText: { en: 'Increased function', uk: 'Increased function' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Increased
		}
	},
	{
		id: 'SPATS2LTT',
		geneId: 'SPATS2L',
		name: { en: 'T/T', uk: 'T/T' },
		description: { en: '-', uk: '-' },
		allele1: 'T',
		allele2: 'T',
		pharmacogenomicTestResult: {
			id: 'IncreasedFunction',
			displayText: { en: 'Increased function', uk: 'Increased function' },
			description: { en: '-', uk: '-' },
			category: PharmacogenomicTestResultCategory.Increased
		}
	}
];
