import { Injector } from '@angular/core';
import { TreatmentCheckerClient } from '@nmn-communication/treatment-checker';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { TreatmentCheckerFakeClientConfigurationBuilder } from '../builders/treatment-checker-fake-client-configuration.builder';
import { TreatmentCheckerFakeClientBuilder } from '../builders/treatment-checker-fake-client.builder';
import { TreatmentCheckerHttpClientConfigurationBuilder } from '../builders/treatment-checker-http-client-configuration.builder';
import { TreatmentCheckerHttpClientBuilder } from '../builders/treatment-checker-http-client.builder';
import { TreatmentCheckerFakeClient } from '../services/treatment-checker.fake-client';
import { TreatmentCheckerHttpClient } from '../services/treatment-checker.http-client';

export const treatmentCheckerClientFactory = (injector: Injector): TreatmentCheckerClient => {
	const useFake = environment?.api?.resources?.treatmentChecker?.useFake ||
		!isValueDefined(environment?.api?.resources?.treatmentChecker?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		treatmentCheckerFakeClientFactory(injector) :
		treatmentCheckerHttpClientFactory(injector);
};

export const treatmentCheckerFakeClientFactory = (
	injector: Injector
): TreatmentCheckerFakeClient => {
	const configuration = new TreatmentCheckerFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.treatmentChecker.serverDelayMs)
		.build();

	return new TreatmentCheckerFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const treatmentCheckerHttpClientFactory = (
	injector: Injector
): TreatmentCheckerHttpClient => {
	const configuration = new TreatmentCheckerHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.treatmentChecker?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.treatmentChecker.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.treatmentChecker?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new TreatmentCheckerHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
