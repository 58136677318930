import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { DescribedComboboxModel } from '../../shared/comboboxes/described-combobox.model';
export class VaccineDescribedComboboxModel extends DescribedComboboxModel {

	public readonly forDiseases: Array<string>;
	public readonly manufacturer?: string;
	public readonly countOfDoses: number;
	public readonly type: string;

	public get isManufacturerDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.manufacturer);
	}

	public get hasMultipleDoses(): boolean {
		return this.countOfDoses > 1;
	}

	public get mainDisease(): string | undefined {
		return this.forDiseasesCount > 0 ? this.forDiseases[0] : undefined;
	}

	public get altDisease(): string | undefined {
		return this.forDiseasesCount > 1 ? this.forDiseases[1] : undefined;
	}

	public get forDiseasesCount(): number {
		return isArrayDefinedAndNotEmpty(this.forDiseases) ? this.forDiseases.length : 0;
	}

	constructor(
		id: string,
		displayText: string,
		description: string,
		countOfDoses: number,
		manufacturer: string | undefined,
		type: string,
		forDiseases: Array<string>
	) {

		super(id, displayText, description);

		// TODO: Guard check on defined
		this.countOfDoses = countOfDoses;
		this.manufacturer = manufacturer;
		this.type = type;
		this.forDiseases = forDiseases;

	}

}
