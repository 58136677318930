export class PatientInsuranceFormOutModel {

	public readonly insuranceCode: string;
	public readonly insuranceCompany: string;

	public constructor(
		insuranceCode: string | undefined,
		insuranceCompany: string | undefined
	) {
		this.insuranceCode = insuranceCode;
		this.insuranceCompany = insuranceCompany;
	}

}
