export class GeneticTestResultFormOutModel {

	public readonly haplotypeId?: string;
	public readonly diplotypeId?: string;
	public readonly pharmacogenomicTestResultCustom?: string;

	constructor(
		haplotypeId: string | undefined,
		diplotypeId: string | undefined,
		pharmacogenomicTestResultCustom: string | undefined
	) {
		this.haplotypeId = haplotypeId;
		this.diplotypeId = diplotypeId;
		this.pharmacogenomicTestResultCustom = pharmacogenomicTestResultCustom;
	}

}
