import { RegionClient, RegionComboboxDto, RegionFilterDto } from '@nmn-communication/regions';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { FakeDatabase } from 'app/modules/fakes/databases/fake.database';
import { wrapFuncCallAsFakeClientResponse } from 'app/modules/fakes/databases/fake.utils';
import { Observable } from 'rxjs';
import { RegionFakeClientConfiguration } from '../configurations/region.fake-client.configuration';

export class RegionFakeClient extends RegionClient {

	constructor(
		private readonly configuration: RegionFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulkAsComboboxes(filter: RegionFilterDto): Observable<Result<Array<RegionComboboxDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.regionsTable.getComboboxesPagedCollection(filter),
			this.configuration.serverDelayMs
		);
	}

}
