import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { accountsConfirmEmailProviders } from './implementation/accounts/confirm-email/provider';
import { accountsLoginProviders } from './implementation/accounts/login/providers';
import { accountsRegistrationProviders } from './implementation/accounts/registration/providers';
import { accountsResetPasswordProviders } from './implementation/accounts/reset-password/providers';
import { accountsUserProfileProviders } from './implementation/accounts/user-accounts/providers';
import { accountsUserConfigurationProviders } from './implementation/accounts/user-configuration/providers';
import { accountsUserSubscriptionProviders } from './implementation/accounts/user-subscription/providers';
import { allergyProviders } from './implementation/allergies/providers';
import { allergyIntoleranceCriticalityProviders } from './implementation/allergy-intolerance-criticalities/providers';
import { allergyIntoleranceStatusProviders } from './implementation/allergy-intolerance-statuses/providers';
import { allergyIntoleranceVerificationStatusProviders } from './implementation/allergy-intolerance-verification-statuses/providers';
import { azureStorageProviders } from './implementation/azure-file-storage/providers';
import { bloodTypeProviders } from './implementation/blood-types/providers';
import { contactsProviders } from './implementation/contacts/providers';
import { diseaseProviders } from './implementation/diseases/providers';
import { doctorEncounterProviders } from './implementation/doctor-encounters/providers';
import { documentTypeProviders } from './implementation/document-types/providers';
import { documentProviders } from './implementation/documents/providers';
import { fileUploaderProviders } from './implementation/file-uploader/providers';
import { genderProviders } from './implementation/genders/providers';
import { geneVariantProviders } from './implementation/gene-variants/providers';
import { geneProviders } from './implementation/genes/providers';
import { geneticFileParseRequestProviders } from './implementation/genetic-file-parse-requests/providers';
import { geneticTestProviders } from './implementation/genetic-tests/providers';
import { healthComplaintProviders } from './implementation/health-complaints/providers';
import { healthIssueSeverityProviders } from './implementation/health-issue-severities/providers';
import { healthIssueProviders } from './implementation/health-issues/providers';
import { languageProviders } from './implementation/languages/providers';
import { medicationAdmRouteProviders } from './implementation/medication-adm-routes/providers';
import { medicationAdmStatusProviders } from './implementation/medication-adm-statuses/providers';
import { medicationBodySiteProviders } from './implementation/medication-body-sites/providers';
import { medicationDosageTypeProviders } from './implementation/medication-dosage-types/providers';
import { medicationRateQuantityTypeProviders } from './implementation/medication-rate-quantity-types/providers';
import { medicationProviders } from './implementation/medications/providers';
import { patientProviders } from './implementation/patients/providers';
import { populationProviders } from './implementation/populations/providers';
import { regionProviders } from './implementation/regions/providers';
import { serverConfigurationProviders } from './implementation/server-configuration/providers';
import { takenMedicationProviders } from './implementation/taken-medications/providers';
import { timezoneProviders } from './implementation/timezones/providers';
import { treatmentCheckerProviders } from './implementation/treatment-checker/providers';
import { typeOfMedicationProviders } from './implementation/types-of-medication/providers';
import { vaccinationProviders } from './implementation/vaccination/providers';
import { vaccineProviders } from './implementation/vaccines/providers';

@NgModule({
	declarations: [],
	providers: [
		...accountsConfirmEmailProviders,
		...accountsLoginProviders,
		...accountsRegistrationProviders,
		...accountsResetPasswordProviders,
		...accountsUserProfileProviders,
		...accountsUserConfigurationProviders,
		...accountsUserSubscriptionProviders,
		...allergyProviders,
		...allergyIntoleranceCriticalityProviders,
		...allergyIntoleranceStatusProviders,
		...allergyIntoleranceVerificationStatusProviders,
		...azureStorageProviders,
		...bloodTypeProviders,
		...contactsProviders,
		...diseaseProviders,
		...documentTypeProviders,
		...documentProviders,
		...doctorEncounterProviders,
		...geneticFileParseRequestProviders,
		...genderProviders,
		...geneProviders,
		...geneVariantProviders,
		...geneticTestProviders,
		...healthComplaintProviders,
		...healthIssueSeverityProviders,
		...healthIssueProviders,
		...languageProviders,
		...medicationAdmStatusProviders,
		...medicationAdmRouteProviders,
		...medicationBodySiteProviders,
		...medicationDosageTypeProviders,
		...medicationRateQuantityTypeProviders,
		...medicationProviders,
		...patientProviders,
		...populationProviders,
		...regionProviders,
		...serverConfigurationProviders,
		...fileUploaderProviders,
		...takenMedicationProviders,
		...timezoneProviders,
		...treatmentCheckerProviders,
		...typeOfMedicationProviders,
		...vaccinationProviders,
		...vaccineProviders
	],
	imports: [
		CommonModule
	]
})
export class CommunicationModule { }
