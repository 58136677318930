export interface DocumentAttachmentSelectSettings {

	attachIcon: string;
	attachIconColor: string;
	attachIconFontSize: string;
	clearIcon: string;
	dragAreaHighlightColor: string;
	i18nKeys: {
		errorExtension_allowedExtensions: string;
		errorAttachmentSize_currentSize_maxSize: string;
		errorGeneral: string;
	};
	showAttachmentDisplayText: boolean;
	uploadAreaBorder: string;

}

export const DocumentAttachmentSelectSettingsDefault: DocumentAttachmentSelectSettings = {

	attachIcon: 'attach_file',
	attachIconColor: '',
	attachIconFontSize: '24px',
	clearIcon: 'cancel',
	dragAreaHighlightColor: 'rgba(0,0,0,.12)',
	i18nKeys: {
		errorExtension_allowedExtensions: 'shared.fileUploader.errors.attachmentExtension_allowedExtensions',
		errorAttachmentSize_currentSize_maxSize: 'shared.fileUploader.errors.attachmentSize_currentSize_maxSize',
		errorGeneral: 'shared.fileUploader.errors.general'
	},
	showAttachmentDisplayText: true,
	uploadAreaBorder: 'rgba(0,0,0,.12) 4px dashed'

};
