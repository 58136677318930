import { Injectable } from '@angular/core';
import { LoginDto } from '@nmn-communication/accounts';
import { PatientClient } from '@nmn-communication/patients';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { PatientCommandHandlerService, PatientCreateCommand, PatientDeleteCommand, PatientRevokeDeleteCommand, PatientUpdateCommand } from '@nmn-domain/patients';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CommandResult, EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { StorageService, updateSubscriptionsAfterLogin, UserConfigurationStorageService } from '../../../../services';
import { mapLoginDtoToModel } from '../../accounts/login/factories/login.factory';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import {
	mapCreateCommandToParameter,
	mapPatientDeleteCommandToDeleteParameter,
	mapPatientRevokeDeleteCommandToDeleteParameter,
	mapUpdateCommandToFindParameter,
	mapUpdateCommandToParameter
} from '../factories/patient.factory';

@Injectable()
export class PatientCommandHandlerViaClientService extends PatientCommandHandlerService {

	constructor(
		private readonly client: PatientClient,
		private readonly storage: StorageService,
		private readonly userConfigurationStorage: UserConfigurationStorageService
	) {
		super();
	}

	public create(command: PatientCreateCommand): Observable<Result<CommandResult<PatientCreateCommand, string>, FailureModel>> {
		return this.client
			.create(mapCreateCommandToParameter(command))
			.pipe(
				map(result => result.map(success => new CommandResult(command, success), mapFailureToFailureModel))
			);
	}

	public update(command: PatientUpdateCommand): Observable<Result<EmptyCommandResult<PatientUpdateCommand>, FailureModel>> {
		return this.client
			.update(mapUpdateCommandToFindParameter(command), mapUpdateCommandToParameter(command))
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

	public delete(command: PatientDeleteCommand): Observable<Result<EmptyCommandResult<PatientDeleteCommand>, FailureModel>> {
		return this.client
			.delete(mapPatientDeleteCommandToDeleteParameter(command))
			.pipe(
				tap(this.updateStorageInfoViaResult.bind(this)),
				map(result => result.map(() => new EmptyCommandResult(command), mapFailureToFailureModel))
			);
	}

	public revokeDelete(command: PatientRevokeDeleteCommand): Observable<Result<EmptyCommandResult<PatientRevokeDeleteCommand>, FailureModel>> {
		return this.client
			.revokeDelete(mapPatientRevokeDeleteCommandToDeleteParameter(command))
			.pipe(
				tap(this.updateStorageInfoViaResult.bind(this)),
				map(result => result.map(() => new EmptyCommandResult(command), mapFailureToFailureModel))
			);
	}

	private updateStorageInfoViaResult(result: Result<LoginDto, Failure>): void {
		result
			.mapOnSuccess(mapLoginDtoToModel)
			.bindOnSuccess(success => {
				updateSubscriptionsAfterLogin(success, this.storage, this.userConfigurationStorage);
			});
	}

}
