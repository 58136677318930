import { Injectable } from '@angular/core';
import { FileUploaderClient } from '@nmn-communication/file-uploader';
import { Result } from '@nmn-core/shared';
import { isValueDefined, toMB } from '@nmn-core/utils';
import { FileUploadCancelUpdateCommand, FileUploadCreateCommand, FileUploaderCommandHandlerService, UploadedFileModel } from '@nmn-domain/file-uploader';
import { EmptyCommandResult, FailureModel } from '@nmn-domain/shared';
import { FailureLocalizationModel } from '@nmn-domain/shared/failures/failure-localization-parameters.model';
import { FailureWrapperModel } from '@nmn-domain/shared/failures/failure-wrapper.model';
import { i18nKeysForImageCropperDialog } from 'app/modules/shared/image-uploader/models/settings/image-cropper-dialog.settings';
import { ServerConfigurationStorageService, StorageService } from 'app/services';
import { AzureStorageInModel, AzureStorageServiceUploadService } from 'app/services/azure-storage';
import { Observable, of, zip } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapUploadCreateCommandToUploadFileParameter, mapUploadedFileDtoToModel } from '../factories/file-uploader.factory';

@Injectable()
export class FileUploaderCommandHandlerViaClientService extends FileUploaderCommandHandlerService {

	constructor(
		private readonly client: FileUploaderClient,
		private readonly serverConfigurationStorage: ServerConfigurationStorageService,
		private readonly azureStorageServiceUploadService: AzureStorageServiceUploadService,
		private readonly userProfileStorage: StorageService
	) {
		super();
	}

	public upload(command: FileUploadCreateCommand): Observable<Result<UploadedFileModel, FailureModel>> {
		// TODO: Will be error in case of changing server configuration inside pipe.
		return zip(this.serverConfigurationStorage.serverConfiguration$, this.userProfileStorage.userFileStorage$)
			.pipe(
				mergeMap(result => {
					const serverConfig = result[0];
					if (
						isValueDefined(command.fileExtension) &&
						serverConfig.fileUpload.restrictedExtensions.indexOf(command.fileExtension) > 0
					) {
						return of(
							Result.failure<UploadedFileModel, FailureModel>(
								FailureModel.createFileUploadIssue(
									FailureWrapperModel.createEmpty(),
									FailureLocalizationModel.createFromValue(
										i18nKeysForImageCropperDialog.errorImageExtensionNotSupported_extension,
										{
											extension: command.fileExtension
										}
									)
								)
							)
						);
					}

					if (command.file.size > serverConfig.fileUpload.fileMaxSize) {
						return of(
							Result.failure<UploadedFileModel, FailureModel>(
								FailureModel.createFileUploadIssue(
									FailureWrapperModel.createEmpty(),
									FailureLocalizationModel.createFromValue(
										i18nKeysForImageCropperDialog.errorImageUploadSize_currentSize_maxSize,
										{
											currentSize: Math.round(toMB(command.file.size)),
											maxSize: Math.round(toMB(serverConfig.fileUpload.fileMaxSize))
										}
									)
								)
							)
						);
					}

					return this.client
						.initiateUploadFile(mapUploadCreateCommandToUploadFileParameter(command))
						.pipe(
							map(mapResult => mapResult.map(mapUploadedFileDtoToModel, mapFailureToFailureModel)),
							mergeMap(success => {
								const initiateSuccess = success.successOrDefault(undefined);
								this.azureStorageServiceUploadService
									.initiateUpload(
										new AzureStorageInModel(
											initiateSuccess.id,
											initiateSuccess.downloadUrl,
											command.file,
											initiateSuccess.name,
											initiateSuccess.extension,
											initiateSuccess.sizeInBytes,
											initiateSuccess.createdOn,
											initiateSuccess.updatedOn,
											initiateSuccess.fileType
										)
									);

								return this.azureStorageServiceUploadService
									.getCompletion(initiateSuccess.id)
									.pipe(
										switchMap(completionModel => {
											if (!completionModel.error) {
												return this.client
													.completeUploadFile({ blobId: completionModel.blobId })
													.pipe(
														map(mapResult => mapResult.map(
															mapUploadedFileDtoToModel,
															failureEnvelope => FailureModel.createWithAppendedMetadata(
																mapFailureToFailureModel(failureEnvelope),
																[{ blobId: initiateSuccess.id }]
															)
														))
													);
											}

											const undefinedUploadFailure = FailureModel.createFileUploadIssue(
												FailureWrapperModel.createFromValue(completionModel),
												FailureLocalizationModel.createFromValue('shared.forms.failureHints.fileUpload'),
												[{ blobId: initiateSuccess.id }]
											);

											return of(Result.failure<UploadedFileModel, FailureModel>(undefinedUploadFailure));
										})
									);
							})
						);
				})
			);
	}

	public cancelUpload(command: FileUploadCancelUpdateCommand): Observable<Result<EmptyCommandResult<FileUploadCancelUpdateCommand>, FailureModel>> {
		this.azureStorageServiceUploadService.cancelUpload(command.blobId);

		return this.client
			.cancelUploadFile({ blobId: command.blobId })
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

}
