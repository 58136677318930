export interface InformationBlock {

	fillingUpPercentage: number;

}

export const isInformationBlockFilledUp = (informationBlock: InformationBlock): boolean =>
	informationBlock.fillingUpPercentage === 100;

export abstract class InformationBlockModel implements InformationBlock {

	public readonly fillingUpPercentage: number;

	constructor(
		fillingUpPercentage: number
	) {

		// TODO: add Guard check for InformationBlock

		this.fillingUpPercentage = fillingUpPercentage;

	}

}
