import { HttpClientConfigurationBuilder } from '../../../shared/builders/http-client.configuration.builder';
import { LoginHttpClientConfiguration } from '../configurations/login.http-client.configuration';

export class LoginHttpClientConfigurationBuilder extends HttpClientConfigurationBuilder<LoginHttpClientConfiguration> {

	public build(): LoginHttpClientConfiguration {
		return new LoginHttpClientConfiguration(this.baseUrl, this.resourceRelativePath, this.resourceTypeVersion);
	}

}
