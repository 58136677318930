export const toKB = (bytesLength: number) => {
	return bytesLength / 1024;
};

export const toMB = (bytesLength: number) => {
	return toKB(bytesLength) / 1024;
};

export const toGB = (bytesLength: number) => {
	return toMB(bytesLength) / 1024;
};
