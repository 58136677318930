import { Provider } from '@angular/core';
import { RegistrationCommandHandlerService } from '@nmn-domain/accounts/registration/services/registration.command-handler.service';
import { RegistrationCommandHandlerViaClientService } from './services/registration.command-handler-via-client.service';

export const accountsRegistrationProviders: Array<Provider> = [
	{
		provide: RegistrationCommandHandlerService,
		useClass: RegistrationCommandHandlerViaClientService
	}
];
