import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { OrganizationIdResolver } from '@nmn-domain/organizations';
import { RouteParameter } from '@nmn-middleware/navigation';
import { Observable } from 'rxjs';

@Injectable()
export class OrganizationIdViaActivatedRouteResolver implements OrganizationIdResolver {

	public resolve(activatedRoute: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<string> | string {
		const organizationId = activatedRoute.paramMap.get(RouteParameter.OrganizationId);

		if (!isStringDefinedAndNotEmpty(organizationId)) {
			return undefined;
		}

		return organizationId;
	}

}
