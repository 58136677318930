export interface ImageCropperDialogSettings {

	i18nKeys: {
		title: string;
		rotateRight: string;
		rotateLeft: string;
		flipVertical: string;
		flipHorizontal: string;
		submitBtnText: string;
		cancelBtnText: string;
		errorImageUploadSize_currentSize_maxSize: string;
		errorImageUploadLoading: string;
		errorImageUploadGeneral: string;
		errorImageExtensionNotSupported_extension: string;
	};

	maintainAspectRatio: boolean;
	aspectRatio: number;
	resizeToWidth: number;
	resizeToHeight: number;
	autoCrop: boolean;
	roundCropper: boolean;
}

export const i18nKeysForImageCropperDialog = {
	title: 'modules.shared.imageUploader.imageCropperDialog.title',
	submitBtnText: 'modules.shared.imageUploader.imageCropperDialog.maintenance.submitBtn.title',
	cancelBtnText: 'modules.shared.imageUploader.imageCropperDialog.maintenance.cancelBtn.title',
	rotateRight: 'modules.shared.imageUploader.imageCropperDialog.maintenance.rotateRightBtn.title',
	rotateLeft: 'modules.shared.imageUploader.imageCropperDialog.maintenance.rotateLeftBtn.title',
	flipVertical: 'modules.shared.imageUploader.imageCropperDialog.maintenance.flipVerticalBtn.title',
	flipHorizontal: 'modules.shared.imageUploader.imageCropperDialog.maintenance.flipHorizontalBtn.title',
	errorImageUploadSize_currentSize_maxSize: 'modules.shared.imageUploader.imageCropperDialog.error.size_currentSize_maxSize',
	errorImageUploadGeneral: 'modules.shared.imageUploader.imageCropperDialog.error.general',
	errorImageUploadLoading: 'modules.shared.imageUploader.imageCropperDialog.error.loading',
	errorImageExtensionNotSupported_extension: 'modules.shared.imageUploader.imageCropperDialog.error.extensionNotSupported_extension'
};

export const defaultImageCropperDialogSettings: ImageCropperDialogSettings = {

	i18nKeys: i18nKeysForImageCropperDialog,
	maintainAspectRatio: true,
	aspectRatio: 1,
	resizeToWidth: 200,
	resizeToHeight: 200,
	autoCrop: false,
	roundCropper: false

};

export const defaultRoundImageCropperDialogSettings: ImageCropperDialogSettings = {

	i18nKeys: i18nKeysForImageCropperDialog,
	maintainAspectRatio: true,
	aspectRatio: 1,
	resizeToWidth: 200,
	resizeToHeight: 200,
	autoCrop: false,
	roundCropper: true

};
