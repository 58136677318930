import { isValueDefined } from '@nmn-core/utils';

export enum HealthComplaintType {

	Undefined = 'undefined',

	Disease = 'disease',
	Symptom = 'symptom'

}

export const mapHealthComplaintTypeFromString = (stringValue: string): HealthComplaintType => {
	const enumValue = Object
		.keys(HealthComplaintType)
		.find(x => HealthComplaintType[x] === stringValue);

	return isValueDefined(enumValue) ? stringValue as HealthComplaintType : HealthComplaintType.Undefined;
};
