import { diffDateInDays, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { UserSubscriptionModel } from '@nmn-domain/accounts';
import { UploadedImageModel } from '@nmn-domain/file-uploader';
import { SubscriptionType } from '@nmn-domain/subscriptions';

export class UserProfileModel {

	public readonly id: string;
	public readonly email: string;
	public readonly isEmailNotificationsEnabled: boolean;
	public readonly isPreferedBrowserTimezone: boolean;
	public readonly picture?: UploadedImageModel;
	public readonly locale: string;
	public readonly timezone: string;
	public readonly createdOn: Date;
	public readonly isDeletionRequested: boolean;
	public readonly deletionRequestedOn?: Date;
	public readonly activePatientId?: string;
	public readonly activePatientAlias?: string;
	public readonly patients: Array<PatientProfileShortModel>;
	public readonly isRegularAuthAttachedValue: boolean;
	public readonly isGoogleAuthAttachedValue: boolean;
	public readonly isFacebookAuthAttachedValue: boolean;
	public readonly userSubscriptionModel: UserSubscriptionModel;

	public get orderedPatientProfiles(): Array<PatientProfileShortModel> {
		return isValueDefined(this.activePatientProfile) ?
			[
				this.activePatientProfile,
				...this.notActivePatientProfiles,
				...this.inDeletionProcessPatientProfiles
			] :
			[
				...this.notActivePatientProfiles,
				...this.inDeletionProcessPatientProfiles
			];
	}

	public get isRegularAuthAttached(): boolean {
		return this.isRegularAuthAttachedValue;
	}

	public get isGoogleAuthAttached(): boolean {
		return this.isGoogleAuthAttachedValue;
	}

	public get isFacebookAuthAttached(): boolean {
		return this.isFacebookAuthAttachedValue;
	}

	public get activePatientProfile(): PatientProfileShortModel {
		return this.patients.find(patient => patient.id === this.activePatientId);
	}

	public get notActivePatientProfiles(): Array<PatientProfileShortModel> {
		return this.patients.filter(patient => patient.id !== this.activePatientId && !patient.isDeletionRequested);
	}

	public get inDeletionProcessPatientProfiles(): Array<PatientProfileShortModel> {
		return this.patients.filter(patient => patient.id !== this.activePatientId && patient.isDeletionRequested);
	}

	public get daysFromRegistration(): number {
		return diffDateInDays(this.createdOn, new Date());
	}

	public get subscriptionType(): SubscriptionType {
		return this.userSubscriptionModel.subscription.subscriptionType;
	}

	public get isBasicSubscription(): boolean {
		return this.userSubscriptionModel.subscription.subscriptionType === SubscriptionType.Basic;
	}

	public get isPremiumSubscription(): boolean {
		return this.userSubscriptionModel.subscription.subscriptionType === SubscriptionType.Premium;
	}

	public get subscriptionDisplayText(): string {
		return this.userSubscriptionModel.subscriptionDisplayText;
	}

	public get isEmailShownAsMask(): boolean {
		return this.email.length > 20;
	}

	public get emailAsMask(): string {
		return this.isEmailShownAsMask ? `${this.email.substring(0, 9)}...${this.email.substring(this.email.length - 9)}` : this.email;
	}

	constructor(
		id: string,
		email: string,
		isEmailNotificationsEnabled: boolean,
		isPreferedBrowserTimezone: boolean,
		picture: UploadedImageModel | undefined,
		locale: string,
		timezone: string,
		createdOn: Date,
		isDeletionRequested: boolean,
		deletionRequestedOn: Date | undefined,
		activePatientId: string | undefined,
		activePatientAlias: string | undefined,
		patients: Array<PatientProfileShortModel>,
		isRegularAuthAttached: boolean,
		isGoogleAuthAttached: boolean,
		isFacebookAuthAttached: boolean,
		userSubscriptionModel: UserSubscriptionModel
	) {

		// TODO: Guard check on defined

		this.id = id;
		this.email = email;
		this.isEmailNotificationsEnabled = isEmailNotificationsEnabled;
		this.isPreferedBrowserTimezone = isPreferedBrowserTimezone;
		this.picture = picture;
		this.locale = locale;
		this.timezone = timezone;
		this.createdOn = createdOn;
		this.isDeletionRequested = isDeletionRequested;
		this.deletionRequestedOn = deletionRequestedOn;
		this.activePatientId = activePatientId;
		this.activePatientAlias = activePatientAlias;
		this.patients = patients;
		this.isRegularAuthAttachedValue = isRegularAuthAttached;
		this.isGoogleAuthAttachedValue = isGoogleAuthAttached;
		this.isFacebookAuthAttachedValue = isFacebookAuthAttached;
		this.userSubscriptionModel = userSubscriptionModel;
	}

}

export class PatientProfileShortModel {

	public readonly id: string;
	public readonly alias: string;
	public readonly userId: string;
	public readonly email: string;
	public readonly givenName: string;
	public readonly additionalName: string;
	public readonly familyName: string;
	public readonly picture?: UploadedImageModel;
	public readonly isDeletionRequested: boolean;
	public readonly deletionRequestedOn?: Date;

	public get shortName(): string {
		return `${this.givenName} ${this.familyName}`;
	}

	public get fullName(): string {
		return isStringDefinedAndNotEmpty(this.additionalName) ?
			`${this.givenName} ${this.additionalName} ${this.familyName}` :
			this.shortName;
	}

	constructor(
		id: string,
		alias: string,
		userId: string,
		email: string,
		givenName: string,
		additionalName: string | undefined,
		familyName: string,
		picture: UploadedImageModel | undefined,
		isDeletionRequested: boolean,
		deletionRequestedOn: Date | undefined
	) {

		// TODO: Guard check on defined

		this.id = id;
		this.alias = alias;
		this.userId = userId;
		this.email = email;
		this.givenName = givenName;
		this.additionalName = additionalName;
		this.familyName = familyName;
		this.picture = picture;
		this.isDeletionRequested = isDeletionRequested;
		this.deletionRequestedOn = deletionRequestedOn;
	}

}

export class UserFileStorageProfileModel {

	public readonly usedStorageCapacity: number;

	constructor(
		usedStorageCapacity: number
	) {
		// TODO: Guard check on defined

		this.usedStorageCapacity = usedStorageCapacity;
	}

}
