import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export const addIcons = (matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) => {
	// Here you can add icons and register them as mat-icon

	// Shared icons
	matIconRegistry.addSvgIcon('diagnosis', domSanitizer.bypassSecurityTrustResourceUrl('../assets/google-mat-icons/diagnosis.svg'));
	matIconRegistry.addSvgIcon('clinical_notes', domSanitizer.bypassSecurityTrustResourceUrl('../assets/google-mat-icons/clinical_notes.svg'));

	// Icons for treatment checker form
	matIconRegistry.addSvgIcon('treatment_checker_cancer', domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-mat-icons/treatment-checker/form/cancer.svg'));
	matIconRegistry.addSvgIcon('treatment_checker_ear', domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-mat-icons/treatment-checker/form/ear.svg'));
	matIconRegistry.addSvgIcon('treatment_checker_endocrine', domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-mat-icons/treatment-checker/form/endocrine.svg'));
	matIconRegistry.addSvgIcon('treatment_checker_eye', domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-mat-icons/treatment-checker/form/eye.svg'));
	matIconRegistry.addSvgIcon('treatment_checker_genetic_conditions', domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-mat-icons/treatment-checker/form/genetic-conditions.svg'));
	matIconRegistry.addSvgIcon('treatment_checker_heart', domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-mat-icons/treatment-checker/form/heart.svg'));
	matIconRegistry.addSvgIcon('treatment_checker_immune_system', domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-mat-icons/treatment-checker/form/immune-system.svg'));
	matIconRegistry.addSvgIcon('treatment_checker_kidney', domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-mat-icons/treatment-checker/form/kidney.svg'));
	matIconRegistry.addSvgIcon('treatment_checker_liver', domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-mat-icons/treatment-checker/form/liver.svg'));
	matIconRegistry.addSvgIcon('treatment_checker_muscles', domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-mat-icons/treatment-checker/form/muscles.svg'));
	matIconRegistry.addSvgIcon('treatment_checker_nervous_system', domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-mat-icons/treatment-checker/form/nervous-system.svg'));
	matIconRegistry.addSvgIcon('treatment_checker_stomach', domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-mat-icons/treatment-checker/form/stomach.svg'));

	// Icons for patient treatment checker form
	matIconRegistry.addSvgIcon('patient_treatment_checker_description_icon', domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-mat-icons/treatment-checker/patient-form/description_icon.svg'));
	matIconRegistry.addSvgIcon('patient_treatment_checker_redirect_icon', domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-mat-icons/treatment-checker/patient-form/redirect_icon.svg'));

	// Icons for treatment checker about
	matIconRegistry.addSvgIcon('treatment_checker_about_support', domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-mat-icons/treatment-checker/about/support.svg'));
	matIconRegistry.addSvgIcon('treatment_checker_about_treatment', domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-mat-icons/treatment-checker/about/treatment.svg'));
	matIconRegistry.addSvgIcon('treatment_checker_about_warnings', domSanitizer.bypassSecurityTrustResourceUrl('../assets/custom-mat-icons/treatment-checker/about/warnings.svg'));

};
