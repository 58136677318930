import { AllergyIntoleranceVerificationStatusFilterDto } from '@nmn-communication/allergy-intolerance-verification-statuses';
import { AllergyIntoleranceVerificationStatusFilterModel } from '@nmn-domain/allergy-intolerance-verification-statuses';

export const mapFilterModelToDto = (model: AllergyIntoleranceVerificationStatusFilterModel): AllergyIntoleranceVerificationStatusFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern
	}
);

export const mapFilterDtoToModel = (dto: AllergyIntoleranceVerificationStatusFilterDto): AllergyIntoleranceVerificationStatusFilterModel => (
	new AllergyIntoleranceVerificationStatusFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern
	)
);
