import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { MonitoringService } from '../../../services';

@Injectable()
export class ProfilingInterceptor implements HttpInterceptor {

	constructor(
		private readonly monitoringService: MonitoringService
	) {
	}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (environment?.enableProfiling !== true) {
			return next.handle(request);
		}

		const started = Date.now();
		let ok: string;

		return next
			.handle(request)
			.pipe(
				tap({
					next: (event: HttpEvent<any>) => ok = event instanceof HttpResponse ? 'succeeded' : '',
					error: (error: HttpErrorResponse) => {
						this.monitoringService.logException(error);
						ok = 'failed';
					}
				}),
				finalize(() => {
					if (environment?.enableProfiling === true) {
						const elapsed = Date.now() - started;
						const msg = `%c${request.method} "${request.urlWithParams}" %c${ok}%c in ${elapsed} ms.`;
						console.log(msg, 'color: grey', ok === 'failed' ? 'color: red' : 'color: green', 'color: grey');
					}
				})
			);
	}

}
