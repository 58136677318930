
import { GeneticFileParseRequestModel } from '../genetic-file-parse-request.model';

export class GeneticFileParseRequestFormInModel {

	public readonly patientId: string;
	public readonly status: string;

	private constructor(
		patientId: string
	) {
		// TODO: Guard.isDefined

		this.patientId = patientId;
	}

	public static createFromExistingModel = (model: GeneticFileParseRequestModel): GeneticFileParseRequestFormInModel => {
		// TODO: Guard.isDefined(model);

		return new GeneticFileParseRequestFormInModel(
			model.patientId
		);
	}

	public static createEmpty = (patientId: string): GeneticFileParseRequestFormInModel => {
		// TODO: Guard.isDefined(patientId);

		return new GeneticFileParseRequestFormInModel(
			patientId
		);
	}

}
