import { PatientModel } from '@nmn-domain/patients/models/patient.model';
import { TreatmentCheckerAnamnesisFormInModel } from '../treatment-checker-anamnesis-form/treatment-checker-anamnesis-form.in-model';
import { TreatmentCheckerTreatmentInfoFormInModel } from '../treatment-checker-treatment-info-form/treatment-checker-treatment-info-form.in-model';
import { TreatmentCheckerYourDataFormInModel } from '../treatment-checker-your-data-form/treatment-checker-your-data-form.in-model';

export class TreatmentCheckerFormInModel {

	public readonly targetStepIndex?: number;
	public readonly anamnesisFormInModel: TreatmentCheckerAnamnesisFormInModel;
	public readonly treatmentInfoFormInModel: TreatmentCheckerTreatmentInfoFormInModel;
	public readonly yourDataFormInModel: TreatmentCheckerYourDataFormInModel;

	private constructor(
		targetStepIndex: number | undefined,
		anamnesisFormInModel: TreatmentCheckerAnamnesisFormInModel,
		treatmentInfoFormInModel: TreatmentCheckerTreatmentInfoFormInModel,
		yourDataFormInModel: TreatmentCheckerYourDataFormInModel
	) {
		this.targetStepIndex = targetStepIndex;
		this.anamnesisFormInModel = anamnesisFormInModel;
		this.treatmentInfoFormInModel = treatmentInfoFormInModel;
		this.yourDataFormInModel = yourDataFormInModel;
	}

	public static createEmpty = (): TreatmentCheckerFormInModel => {
		return new TreatmentCheckerFormInModel(
			0,
			TreatmentCheckerAnamnesisFormInModel.createEmpty(),
			TreatmentCheckerTreatmentInfoFormInModel.createEmpty(),
			TreatmentCheckerYourDataFormInModel.createEmpty()
		);
	}

	public static createByPatient = (patient: PatientModel): TreatmentCheckerFormInModel => {
		return new TreatmentCheckerFormInModel(
			0,
			TreatmentCheckerAnamnesisFormInModel.createByPatient(patient),
			TreatmentCheckerTreatmentInfoFormInModel.createByPatient(patient),
			TreatmentCheckerYourDataFormInModel.createEmpty()
		);
	}

	public static createForPredefinedData = (
		anamnesisFormInModel: TreatmentCheckerAnamnesisFormInModel,
		treatmentInfoFormInModel: TreatmentCheckerTreatmentInfoFormInModel,
		yourDataFormInModel: TreatmentCheckerYourDataFormInModel
	): TreatmentCheckerFormInModel => {
		return new TreatmentCheckerFormInModel(0, anamnesisFormInModel, treatmentInfoFormInModel, yourDataFormInModel);
	}

	public static createForPredefinedDataAndTargetStep = (targetStepIndex: number | undefined, predefinedData: TreatmentCheckerFormInModel): TreatmentCheckerFormInModel => {
		return new TreatmentCheckerFormInModel(
			targetStepIndex,
			predefinedData.anamnesisFormInModel,
			predefinedData.treatmentInfoFormInModel,
			predefinedData.yourDataFormInModel
		);
	}

}
