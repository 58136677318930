import { Injector, Provider } from '@angular/core';
import { EmailConfirmationClient } from '@nmn-communication/accounts';
import { emailConfirmationClientFactory } from './factories/email-confirmation.client.factory';

export const accountsConfirmEmailProviders: Array<Provider> = [
	{
		provide: EmailConfirmationClient,
		useFactory: emailConfirmationClientFactory,
		deps: [Injector]
	}
];
