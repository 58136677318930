export class GeneticFileParseRequestCreateCommand {

	public readonly patientId: string;
	public readonly blobId: string;

	constructor(
		patientId: string,
		blobId: string
	) {
		// TODO: Guard check on defined

		this.patientId = patientId;
		this.blobId = blobId;
	}

}
