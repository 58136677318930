import { firstOrUndefined, onlyUnique, isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { GaCurrency } from '../models/ga-currency.enum';
import { GaAddToCartDto } from '../models/ecommerce/ga-add-to-cart.dto';
import { GaItemDto } from '../models/ecommerce/ga-item.dto';
import { GaPurchaseDto } from '../models/ecommerce/ga-purchase.dto';

// items

export const buildPremiumMonthlySubscriptionSinglePurchaseItem = (): GaItemDto => {
	return buildSubscriptionSinglePurchaseItem('premium_monthly', 'Premium Monthly', GaCurrency.Eur, 9.99, 0);
}

export const buildPremiumSemiAnnualSubscriptionSinglePurchaseItem = (): GaItemDto => {
	return buildSubscriptionSinglePurchaseItem('premium_semiannual', 'Premium Semi-Annual', GaCurrency.Eur, 53.94, 6);
}

export const buildPremiumAnnualSubscriptionSinglePurchaseItem = (): GaItemDto => {
	return buildSubscriptionSinglePurchaseItem('premium_annual', 'Premium Annual', GaCurrency.Eur, 89.88, 30);
}

export const buildCorporateSubscriptionSinglePurchaseItem = (): GaItemDto => {
	return buildSubscriptionSinglePurchaseItem('corporate', 'Corporate', GaCurrency.Eur, 0, 0);
}

// events

export const buildAddToCart = (items: Array<GaItemDto>): GaAddToCartDto => {
	//TODO: Guard.isArrayDefinedAndNotEmpty(items)
	//TODO: Guard.containsSingleItem(items.map(item => item.currency).filter(onlyUnique).filter(isStringDefinedAndNotEmpty))

	return {
		value: items.reduce((sum, item) => sum + item.price, 0),
		currency: firstOrUndefined(items.map(item => item.currency).filter(onlyUnique).filter(isStringDefinedAndNotEmpty)),
		items: items
	};
}

export const buildRemoveFromCart = (items: Array<GaItemDto>): GaAddToCartDto => {
	//TODO: Guard.isArrayDefinedAndNotEmpty(items)
	//TODO: Guard.containsSingleItem(items.map(item => item.currency).filter(onlyUnique).filter(isStringDefinedAndNotEmpty))

	return {
		value: items.reduce((sum, item) => sum + item.price, 0),
		currency: firstOrUndefined(items.map(item => item.currency).filter(onlyUnique).filter(isStringDefinedAndNotEmpty)),
		items: items
	};
}

export const buildPurchase = (transactionId: string, items: Array<GaItemDto>): GaPurchaseDto => {
	//TODO: Guard.isDefinedAndNotWhitespace(transactionId)
	//TODO: Guard.isArrayDefinedAndNotEmpty(items)
	//TODO: Guard.containsSingleItem(items.map(item => item.currency).filter(onlyUnique).filter(isStringDefinedAndNotEmpty))

	return {
		transaction_id: transactionId,
		value: items.reduce((sum, item) => sum + item.price, 0),
		currency: firstOrUndefined(items.map(item => item.currency).filter(onlyUnique).filter(isStringDefinedAndNotEmpty)),
		tax: 0,
		shipping: 0,
		coupon: undefined,
		items: items
	};
}

// maintenance

const buildSubscriptionSinglePurchaseItem = (
	subscriptionId: string,
	subscriptionName: string,
	currency: string,
	price: number,
	discount: number
): GaItemDto => {
	return buildPurchaseItem(
		`subscription_${subscriptionId}`,
		`Subscription \'${subscriptionName}\'`,
		'Subscription',
		subscriptionName,
		currency,
		price,
		discount,
		1,
		0
	);
}

const buildPurchaseItem = (
	itemId: string, itemName: string, itemCategory: string, itemSubCategory: string,
	currency: string, itemPrice: number, discount: number,
	quantity: number, itemIndexInPurchase: number
): GaItemDto => {
	//TODO: Guard.isDefinedAndNotWhitespace(itemId)
	//TODO: Guard.isNumberDefinedAndNonNegative(itemPrice)

	return {
		item_id: itemId,
		item_name: itemName,
		currency: currency,
		price: itemPrice,
		discount: discount,
		coupon: undefined,
		quantity: quantity,
		index: itemIndexInPurchase,
		affiliation: "Apixmed APP",
		item_brand: "Apixmed",
		item_category: itemCategory,
		item_category2: itemSubCategory,
		item_category3: undefined,
		item_category4: undefined,
		item_category5: undefined,
		item_list_id: undefined,
		item_list_name: undefined,
		item_variant: undefined,
		location_id: undefined
	};
}
