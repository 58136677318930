import { isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { GeneVariantComboboxModel } from '@nmn-domain/gene-variants';
import { PharmacogenomicTestResultComboboxModel } from '@nmn-domain/pharmacogenomic-test-results';

export class GeneticTestResultModel {

	public readonly haplotype: GeneVariantComboboxModel;
	public readonly diplotype?: GeneVariantComboboxModel;
	public readonly pharmacogenomicTestResultCustom?: string;

	public get isCustomPharmacogenomicTestResult(): boolean {
		return isStringDefinedAndNotEmpty(this.pharmacogenomicTestResultCustom);
	}

	public get isHaplotype(): boolean {
		return !this.isDiplotype;
	}

	public get isDiplotype(): boolean {
		return isValueDefined(this.diplotype);
	}

	public get variantDisplayText(): string {
		return this.isDiplotype ? `${this.diplotype.allele1}/${this.diplotype.allele2}` : this.haplotype.allele1;
	}

	public get pharmacogenomicTestResult(): PharmacogenomicTestResultComboboxModel {
		return this.isDiplotype ? this.diplotype.pharmacogenomicTestResult : this.haplotype.pharmacogenomicTestResult;
	}

	constructor(
		haplotype: GeneVariantComboboxModel,
		diplotype: GeneVariantComboboxModel | undefined,
		pharmacogenomicTestResultCustom: string | undefined
	) {
		// TODO: Guard check on defined
		this.haplotype = haplotype;

		this.diplotype = diplotype;
		this.pharmacogenomicTestResultCustom = pharmacogenomicTestResultCustom;
	}

}
