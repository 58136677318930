import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../../../../communication/implementation/shared/builders/http-client.builder';
import { EmailConfirmationHttpClientConfiguration } from '../configurations/email-confirmation.http-client.configuration';
import { EmailConfirmationHttpClient } from '../services/email-confirmation.http-client';

export class EmailConfirmationHttpClientBuilder
	extends HttpClientBuilder<EmailConfirmationHttpClient, EmailConfirmationHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): EmailConfirmationHttpClient {
		return new EmailConfirmationHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
