import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { DescribedComboboxModel, FailureModel } from '../../shared';
import { AllergyFilterModel } from '../models/allergy-filter.model';


export abstract class AllergyQueryHandlerService {

	public abstract getAsDescribedComboboxes(query: AllergyFilterModel): Observable<Result<Array<DescribedComboboxModel>, FailureModel>>;

}
