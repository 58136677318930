import { isValueDefined } from '@nmn-core/utils';
import { EmailConfirmationFakeClientConfiguration } from '../configurations/email-confirmation.fake-client.configuration';

export class EmailConfirmationFakeClientConfigurationBuilder {

	private readonly serverDelayMs: { min: number, max: number } = { min: 0, max: 0 };

	public withServerDelay(
		serverDelayMs: { min: number, max: number }
	): EmailConfirmationFakeClientConfigurationBuilder {
		if (
			isValueDefined(serverDelayMs) &&
			isValueDefined(serverDelayMs.min) &&
			isValueDefined(serverDelayMs.max) &&
			serverDelayMs.min <= serverDelayMs.max
		) {
			this.serverDelayMs.min = serverDelayMs.min;
			this.serverDelayMs.max = serverDelayMs.max;
		}

		return this;
	}

	public build(): EmailConfirmationFakeClientConfiguration {
		return new EmailConfirmationFakeClientConfiguration(this.serverDelayMs);
	}

}
