import { Injector, Provider } from '@angular/core';
import { TypeOfMedicationClient } from '@nmn-communication/types-of-medication';
import { typeOfMedicationClientFactory } from './factories/type-of-medication.client.factory';

export const typeOfMedicationProviders: Array<Provider> = [
	{
		provide: TypeOfMedicationClient,
		useFactory: typeOfMedicationClientFactory,
		deps: [Injector]
	}
];
