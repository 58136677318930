import { BlobServiceClient } from '@azure/storage-blob';
import { AzureStorageClient, AzureStorageClientFactory, AzureStorageClientFactoryCreateParameterDto } from '@nmn-communication/azure-file-storage';
import { AzureStorageHttpClient } from '../services/azure-storage.http-client';

export class AzureStorageClientHttpFactory extends AzureStorageClientFactory {

	public create(
		parameter: AzureStorageClientFactoryCreateParameterDto
	): AzureStorageClient {
		return new AzureStorageHttpClient(
			new BlobServiceClient(`${parameter.storageUrl}${parameter.sasToken}`)
				.getContainerClient(parameter.container)
				.getBlockBlobClient(parameter.blobName)
		);
	}

}
