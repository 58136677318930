import { TranslocoService } from '@ngneat/transloco';
import { DiseaseComboboxDto } from '@nmn-communication/diseases';
import { isValueDefined } from '@nmn-core/utils';
import { setTranslation } from '../../utils/localize';
import { FakeComboboxDto, mapFakeComboboxDtoToComboboxDto } from './fake-combox.dto';
import { FakeDescribedComboboxDto } from './fake-described-combobox.dto';

export interface FakeDiseaseComboboxDto extends FakeDescribedComboboxDto<string> {

	type: string;
	icd10: string;
	icd10Group?: string;
	group?: FakeComboboxDto<string>;

}

export const mapFakeDiseaseDescriptedComboboxDtoToDiseasesComboboxDto = (
	translocoService: TranslocoService,
	fakeDescribedComboboxDto: FakeDiseaseComboboxDto
): DiseaseComboboxDto => {
	if (!isValueDefined(fakeDescribedComboboxDto)) {
		return undefined;
	}

	return {
		id: fakeDescribedComboboxDto.id,
		displayText: setTranslation(translocoService, fakeDescribedComboboxDto.displayText),
		description: setTranslation(translocoService, fakeDescribedComboboxDto.description),
		type: fakeDescribedComboboxDto.type,
		icd10: fakeDescribedComboboxDto.icd10,
		icd10Group: fakeDescribedComboboxDto.icd10Group,
		group: mapFakeComboboxDtoToComboboxDto(translocoService, fakeDescribedComboboxDto.group)
	};
};
