import { Injector } from '@angular/core';
import { MedicationRateQuantityTypeClient } from '@nmn-communication/medication-rate-quantity-types';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { MedicationRateQuantityTypeFakeClientBuilder } from '../builders/medication-rate-quantity-type.fake-client.builder';
import { MedicationRateQuantityTypeFakeClientConfigurationBuilder } from '../builders/medication-rate-quantity-type.fake-client.configuration.builder';
import { MedicationRateQuantityTypeHttpClientBuilder } from '../builders/medication-rate-quantity-type.http-client.builder';
import { MedicationRateQuantityTypeHttpClientConfigurationBuilder } from '../builders/medication-rate-quantity-type.http-client.configuration.builder';
import { MedicationRateQuantityTypeFakeClient } from '../services/medication-rate-quantity-type.fake-client';
import { MedicationRateQuantityTypeHttpClient } from '../services/medication-rate-quantity-type.http-client';

export const MedicationRateQuantityTypeClientFactory = (injector: Injector): MedicationRateQuantityTypeClient => {
	const useFake = environment?.api?.resources?.medicationRateQuantityTypes?.useFake ||
		!isValueDefined(environment?.api?.resources?.medicationRateQuantityTypes?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		MedicationRateQuantityTypeFakeClientFactory(injector) :
		MedicationRateQuantityTypeHttpClientFactory(injector);
};

export const MedicationRateQuantityTypeFakeClientFactory = (injector: Injector): MedicationRateQuantityTypeFakeClient => {
	const configuration = new MedicationRateQuantityTypeFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.medicationRateQuantityTypes.serverDelayMs)
		.build();

	return new MedicationRateQuantityTypeFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const MedicationRateQuantityTypeHttpClientFactory = (injector: Injector): MedicationRateQuantityTypeHttpClient => {
	const configuration = new MedicationRateQuantityTypeHttpClientConfigurationBuilder()
		.withBaseUrl(environment?.api?.resources?.medicationRateQuantityTypes?.baseUrl ?? environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.medicationRateQuantityTypes.resourceRelativePath)
		.withResourceTypeVersion(environment?.api?.resources?.medicationRateQuantityTypes?.resourceTypeVersion ?? environment?.api?.defaultResourceTypeVersion)
		.build();

	return new MedicationRateQuantityTypeHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
