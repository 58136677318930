export class CurrentUserConfigurationGetCommand {

	public readonly patientAlias?: string;

	private constructor(
		patientAlias?: string
	) {

		// TODO: Guard check on defined
		this.patientAlias = patientAlias;
	}

	public static create(
		patientAlias?: string
	): CurrentUserConfigurationGetCommand {
		return new CurrentUserConfigurationGetCommand(
			patientAlias
		);
	}

}
