import { TranslocoService } from '@ngneat/transloco';
import { TypeOfMedicationComboboxDto, TypeOfMedicationFilterDto } from '@nmn-communication/types-of-medication';
import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../databases/fake.database';
import { getPagedCollectionWithoutDtoFilter } from '../../databases/fake.utils';
import { FakeLocalizableEntity } from '../../models/fake-localizable-entity';
import { setTranslation } from '../../utils/localize';

export class TypeOfMedicationFakeTable {

	private readonly data: Array<TypeOfMedicationFakeRecord>;

	constructor(
		_: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.data = [...initialData];
	}

	public getAsCombobox(parameter: { id: string }): TypeOfMedicationComboboxDto {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record was not found');
		}

		return record;
	}

	public findAsCombobox(parameter: { id: string }): TypeOfMedicationComboboxDto {
		const record = this.data.find((item: TypeOfMedicationFakeRecord) => findPredicate(item, parameter));

		return this.mapFromRecordToComboboxDto(record);
	}

	public getComboboxesPagedCollection(
		filter: TypeOfMedicationFilterDto
	): Array<TypeOfMedicationComboboxDto> {
		return getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			filterPredicateForRecord,
			this.mapFromRecordToComboboxDto,
			compareFn
		).items;
	}

	private readonly mapFromRecordToComboboxDto = (
		record: TypeOfMedicationFakeRecord
	): TypeOfMedicationComboboxDto => (
		{
			id: record.id,
			displayText: setTranslation(this.translocoService, record.name),
			description: setTranslation(this.translocoService, record.name)
		}
	)

}

const findPredicate = (
	item: TypeOfMedicationFakeRecord,
	findParameter: { id: string }
): boolean =>
	item.id === findParameter.id;

const filterPredicateForRecord = (
	item: TypeOfMedicationFakeRecord,
	filter: TypeOfMedicationFilterDto,
	translocoService: TranslocoService
): boolean => {
	let result = true;

	if (result && isArrayDefinedAndNotEmpty(filter.ids)) {
		result = result && filter.ids.some(id => item.id === id);
	}

	if (result && isArrayDefinedAndNotEmpty(filter.ignoreIds)) {
		result = result && filter.ignoreIds.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result &&
			setTranslation(translocoService, item.name)
				.toLowerCase()
				.indexOf(filter.searchPattern.toLowerCase()) >= 0;
	}

	return result;
};

// eslint-disable
// eslint-disable-next-line complexity
const compareFn = (
	item1: TypeOfMedicationComboboxDto,
	item2: TypeOfMedicationComboboxDto,
	sorting: string
): number => {
	if (sorting === 'displayText asc') {
		return item1.displayText > item2.displayText ? 1 : item1.displayText < item2.displayText ? -1 : 0;
	} else if (sorting === 'displayText desc') {
		return item1.displayText < item2.displayText ? 1 : item1.displayText > item2.displayText ? -1 : 0;
	}

	return 0;
};
// eslint-enable

interface TypeOfMedicationFakeRecord {
	id: string;
	name: FakeLocalizableEntity;
	description: FakeLocalizableEntity;
	order: number;
}

const initialData: Array<TypeOfMedicationFakeRecord> = [
	{
		id: 'Aerosol',
		name: {
			en: 'Aerosol',
			uk: 'Aerosol'
		},
		description: {
			en: 'fake description',
			uk: 'fake description'
		},
		order: 1
	},
	{
		id: 'Buccal tablet',
		name: {
			en: 'Buccal tablet',
			uk: 'Buccal tablet'
		},
		description: {
			en: 'fake description',
			uk: 'fake description'
		},
		order: 2
	},
	{
		id: 'Cachet',
		name: {
			en: 'Cachet',
			uk: 'Cachet'
		},
		description: {
			en: 'fake description',
			uk: 'fake description'
		},
		order: 3
	},
	{
		id: 'Capsule, hard',
		name: {
			en: 'Capsule, hard',
			uk: 'Capsule, hard'
		},
		description: {
			en: 'fake description',
			uk: 'fake description'
		},
		order: 4
	},
	{
		id: 'Chewable tablet',
		name: {
			en: 'Chewable tablet',
			uk: 'Chewable tablet'
		},
		description: {
			en: 'fake description',
			uk: 'fake description'
		},
		order: 5
	},
	{
		id: 'Coated tablet',
		name: {
			en: 'Coated tablet',
			uk: 'Coated tablet'
		},
		description: {
			en: 'fake description',
			uk: 'fake description'
		},
		order: 6
	},
	{
		id: 'Collodion',
		name: {
			en: 'Collodion',
			uk: 'Collodion'
		},
		description: {
			en: 'fake description',
			uk: 'fake description'
		},
		order: 7
	},
	{
		id: 'Cream',
		name: {
			en: 'Cream',
			uk: 'Cream'
		},
		description: {
			en: 'fake description',
			uk: 'fake description'
		},
		order: 8
	},
	{
		id: 'Dispersible tablet',
		name: {
			en: 'Dispersible tablet',
			uk: 'Dispersible tablet'
		},
		description: {
			en: 'fake description',
			uk: 'fake description'
		},
		order: 9
	},
	{
		id: 'Ear cream',
		name: {
			en: 'Ear cream',
			uk: 'Ear cream'
		},
		description: {
			en: 'fake description',
			uk: 'fake description'
		},
		order: 10
	}
];
