import { Failure, PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { isArrayDefinedAndNotEmpty } from '@nmn-core/utils';
import { FailureModel, PagedCollectionModel, PageOptionsQuery } from '@nmn-domain/shared';
import { mapFailureToFailureModel } from './failure-handling.factory';

export const mapPageOptionsQueryToDto = <TFilterModel, TFilterDto>(
	query: PageOptionsQuery<TFilterModel>,
	filterMappingFactory: (filterModel: TFilterModel) => TFilterDto
): PageOptionsDto<TFilterDto> => (
	{
		indexFrom: query.indexFrom,
		pageIndex: query.pageIndex,
		pageSize: query.pageSize,
		sorting: query.sorting,
		filter: filterMappingFactory(query.filter)
	}
);

export const mapPagedCollectionDtoToModel = <TDto, TFilterDto, TModel, TFilterModel>(
	pagedCollectionDto: PagedCollectionDto<TDto, TFilterDto>,
	dtoToModelMappingFactory: (dto: TDto) => TModel,
	filterDtoToModelMappingFactory: (filterDto: TFilterDto) => TFilterModel
): PagedCollectionModel<TModel, TFilterModel> => (
	new PagedCollectionModel(
		pagedCollectionDto.sorting,
		filterDtoToModelMappingFactory(pagedCollectionDto.filter),
		pagedCollectionDto.pageIndex,
		pagedCollectionDto.pageSize,
		pagedCollectionDto.indexFrom,
		isArrayDefinedAndNotEmpty(pagedCollectionDto.items) ? pagedCollectionDto.items.map(dtoToModelMappingFactory) : [],
		pagedCollectionDto.hasNextPage,
		pagedCollectionDto.hasPreviousPage,
		pagedCollectionDto.nextPage,
		pagedCollectionDto.previousPage,
		pagedCollectionDto.totalCount,
		pagedCollectionDto.totalPages
	)
);

export const mapDtoArrayResultToModelArrayResult = <TDto, TModel>(
	result: Result<Array<TDto>, Failure>,
	mapDtoToModel: (dto: TDto) => TModel
): Result<Array<TModel>, FailureModel> =>
	result.map(array => isArrayDefinedAndNotEmpty(array) ? array.map(mapDtoToModel) : [], mapFailureToFailureModel);
