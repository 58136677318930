import { Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { ContactsFakeClientConfiguration } from '../configurations/contacts.fake-client.configuration';
import { ContactsFakeClient } from '../services/contacts.fake-client';

export class ContactsFakeClientBuilder {

	private configuration: ContactsFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(
		configuration: ContactsFakeClientConfiguration
	): ContactsFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): ContactsFakeClient {
		return new ContactsFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
