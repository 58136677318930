import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HealthIssueStatus, HealthIssueStatusesResolver } from '@nmn-domain/health-issues';
import { ComboboxModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';

@Injectable()
export class HealthIssueStatusesViaEnumResolver extends HealthIssueStatusesResolver {

	public resolve(
		__: ActivatedRouteSnapshot,
		_: RouterStateSnapshot
	): Observable<Array<ComboboxModel>> | Array<ComboboxModel> {
		return Object
			.keys(HealthIssueStatus)
			.map(enumValue => new ComboboxModel(HealthIssueStatus[enumValue], HealthIssueStatus[enumValue]));
	}

}
