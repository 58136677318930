import { TranslocoService } from '@ngneat/transloco';
import { DiseaseComboboxDto } from '@nmn-communication/diseases';
import { VaccineDescribedComboboxDto, VaccineFilterDto, VaccineFindParameterDto } from '@nmn-communication/vaccines';
import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../databases/fake.database';
import { getPagedCollectionWithoutDtoFilter } from '../../databases/fake.utils';
import { FakeVaccineDescribedComboboxDto, mapFakeVaccineDescriptedComboboxDtoToVaccinesDescriptedComboboxDto } from '../../models/comboboxes/fake-vaccine-described-combobox.dto';
import { FakeLocalizableEntity } from '../../models/fake-localizable-entity';
import { setTranslation } from '../../utils/localize';

export class VaccineFakeTable {

	private readonly data: Array<VaccineFakeRecord>;

	constructor(
		private readonly database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.data = [...initialData];
	}

	public getAsCombobox(parameter: VaccineFindParameterDto): VaccineDescribedComboboxDto {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record was not found');
		}

		return record;
	}

	public findAsCombobox(parameter: VaccineFindParameterDto): VaccineDescribedComboboxDto {
		const record = this.data
			.find((item: VaccineFakeRecord) => findPredicate(item, parameter));

		const comboboxRecord = this.mapFromRecordToFakeDescribedComboboxDto(record!);
		return mapFakeVaccineDescriptedComboboxDtoToVaccinesDescriptedComboboxDto(this.translocoService, comboboxRecord!);
	}

	public findFromDiseasesForVaccineAsComboboxes(
		parameter: VaccineFindParameterDto
	): Array<DiseaseComboboxDto> {
		const diseaseIds = this.data
			.find((item: VaccineFakeRecord) => findPredicate(item, parameter))?.forDiseaseIds;

		return isArrayDefinedAndNotEmpty(diseaseIds) ?
			this.database.diseasesTable.getComboboxesPagedCollection({ ids: diseaseIds }) :
			[];
	}

	public getComboboxesCollection(
		filter: VaccineFilterDto
	): Array<VaccineDescribedComboboxDto> {
		const pagedCollection = getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			filterPredicateForRecord,
			this.mapFromRecordToFakeDescribedComboboxDto.bind(this),
			compareFn
		).items;

		return pagedCollection.map((item) => mapFakeVaccineDescriptedComboboxDtoToVaccinesDescriptedComboboxDto(this.translocoService, item));
	}

	public hasVaccineForDisease(diseaseId: string): boolean {
		return this.data.findIndex(
			record => record.forDiseaseIds.findIndex(forDiseaseId => forDiseaseId === diseaseId) >= 0) >= 0;
	}

	private mapFromRecordToFakeDescribedComboboxDto(record: VaccineFakeRecord): FakeVaccineDescribedComboboxDto {
		const forDiseases = this.database.diseasesTable
			.getComboboxesPagedCollection({ ids: record.forDiseaseIds })
			.map(combobox => combobox.displayText);

		return isValueDefined(record) ?
			{
				id: record!.id,
				displayText: record!.name,
				description: record!.description,
				manufacturer: record!.manufacturer,
				countOfDoses: record!.countOfDoses,
				type: record!.type,
				forDiseases: forDiseases
			} :
			undefined!;
	}

}

const findPredicate = (item: VaccineFakeRecord, findParameter: VaccineFindParameterDto): boolean =>
	item.id === findParameter.id;

const filterPredicateForRecord = (
	item: VaccineFakeRecord,
	filter: VaccineFilterDto,
	translocoService: TranslocoService,
): boolean => {
	let result = true;

	if (result && isArrayDefinedAndNotEmpty(filter.ids)) {
		result = result && filter.ids!.some(id => item.id === id);
	}

	if (result && isArrayDefinedAndNotEmpty(filter.ignoreIds)) {
		result = result && filter.ignoreIds!.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result &&
			setTranslation(translocoService, item.name)
				.toLowerCase()
				.indexOf(filter.searchPattern!.toLowerCase()) >= 0;
	}

	if (result && isArrayDefinedAndNotEmpty(filter.fromDiseaseIds)) {
		result = result && filter.fromDiseaseIds!.every(fromDiseaseId => isValueDefined(item.forDiseaseIds.find(diseaseId => diseaseId === fromDiseaseId)));
	}

	if (result && isArrayDefinedAndNotEmpty(filter.ignoreFromDiseaseIds)) {
		result = result && filter.ignoreFromDiseaseIds!.every(fromDiseaseId => !isValueDefined(item.forDiseaseIds.find(diseaseId => diseaseId === fromDiseaseId)));
	}

	return result;
};

/* eslint-disable  */
/* eslint-disable complexity */
const compareFn = (
	item1: FakeVaccineDescribedComboboxDto,
	item2: FakeVaccineDescribedComboboxDto,
	sorting: string
): number => {
	if (sorting === 'displayText asc') {
		return item1.displayText > item2.displayText ? 1 : item1.displayText < item2.displayText ? -1 : 0;
	} else if (sorting === 'displayText desc') {
		return item1.displayText < item2.displayText ? 1 : item1.displayText > item2.displayText ? -1 : 0;
	}

	return 0;
};
/* eslint-enable complexity */
/* eslint-enable */

interface VaccineFakeRecord {
	id: string;
	name: FakeLocalizableEntity;
	description?: FakeLocalizableEntity;
	forDiseaseIds: Array<string>;
	manufacturer?: FakeLocalizableEntity;
	countOfDoses: number;
	type: string;
	order: number;
	createdOn: string;
	updatedOn?: string;
}

const initialData: Array<VaccineFakeRecord> = [
	{
		id: 'BioThrax',
		name: {
			en: 'BioThrax',
			uk: 'БіоТракс'
		},
		description: undefined,
		forDiseaseIds: ['Anthrax'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'AVA',
		order: 1,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Dukoral',
		name: {
			en: 'Dukoral',
			uk: 'Дукорал'
		},
		description: undefined,
		forDiseaseIds: ['Cholera'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'Vaxchora',
		order: 2,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'ShanChol',
		name: {
			en: 'ShanChol',
			uk: 'ShanChol'
		},
		description: undefined,
		forDiseaseIds: ['Cholera'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'Vaxchora',
		order: 3,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Euvichol-Plus',
		name: {
			en: 'Euvichol-Plus',
			uk: 'Евіхол-Плюс'
		},
		description: undefined,
		forDiseaseIds: ['Cholera'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'Vaxchora',
		order: 4,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Euvichol',
		name: {
			en: 'Euvichol',
			uk: 'Евіхол'
		},
		description: undefined,
		forDiseaseIds: ['Cholera'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'Vaxchora',
		order: 5,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Vaxchora',
		name: {
			en: 'Vaxchora',
			uk: 'Вакшора'
		},
		description: undefined,
		forDiseaseIds: ['Cholera'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'Vaxchora',
		order: 6,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Havrix',
		name: {
			en: 'Havrix',
			uk: 'Гаврікс'
		},
		description: undefined,
		forDiseaseIds: ['Hepatitis A'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'HepA',
		order: 7,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Vaqta',
		name: {
			en: 'Vaqta',
			uk: 'Вакта'
		},
		description: undefined,
		forDiseaseIds: ['Hepatitis A'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'HepA',
		order: 8,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Twinrix',
		name: {
			en: 'Twinrix',
			uk: 'Твінрікс'
		},
		description: undefined,
		forDiseaseIds: ['Hepatitis A', 'Hepatitis B'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'HepA-HepB',
		order: 9,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Engerix-B',
		name: {
			en: 'Engerix-B',
			uk: 'Енджерікс-Б'
		},
		description: undefined,
		forDiseaseIds: ['Hepatitis B'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'HepB',
		order: 10,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Recombivax HB',
		name: {
			en: 'Recombivax HB',
			uk: 'Рекомбівакс НВ'
		},
		description: undefined,
		forDiseaseIds: ['Hepatitis B'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'HepB',
		order: 11,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Heplisav-B',
		name: {
			en: 'Heplisav-B',
			uk: 'Геплісав-Б'
		},
		description: undefined,
		forDiseaseIds: ['Hepatitis B'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'HepB',
		order: 12,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Pediarix',
		name: {
			en: 'Pediarix',
			uk: 'Педіарікс'
		},
		description: undefined,
		forDiseaseIds: ['Hepatitis B', 'Polio', 'Tetanus'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'DTaP-HepB-IPV',
		order: 13,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'ActHIB',
		name: {
			en: 'ActHIB',
			uk: 'ActHIB'
		},
		description: undefined,
		forDiseaseIds: ['Haemophilus influenzae type b (Hib)'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'Hib',
		order: 14,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'PedvaxHIB',
		name: {
			en: 'PedvaxHIB',
			uk: 'PedvaxHIB'
		},
		description: undefined,
		forDiseaseIds: ['Haemophilus influenzae type b (Hib)'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'Hib',
		order: 15,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Hiberix',
		name: {
			en: 'Hiberix',
			uk: 'Гіберікс'
		},
		description: undefined,
		forDiseaseIds: ['Haemophilus influenzae type b (Hib)'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'Hib',
		order: 16,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Pentacel',
		name: {
			en: 'Pentacel',
			uk: 'Пентакель'
		},
		description: undefined,
		forDiseaseIds: ['Haemophilus influenzae type b (Hib)', 'Polio', 'Tetanus'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'DTaP-IPV/Hib',
		order: 17,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'M-M-R II',
		name: {
			en: 'M-M-R II',
			uk: 'M-M-R II'
		},
		description: undefined,
		forDiseaseIds: ['Measles', 'Mumps', 'Rubella'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'MMR',
		order: 18,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'ProQuad',
		name: {
			en: 'ProQuad',
			uk: 'ПроКвад'
		},
		description: undefined,
		forDiseaseIds: ['Measles', 'Mumps', 'Rubella', 'Varicella'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'MMRV',
		order: 19,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'ACAM2000',
		name: {
			en: 'ACAM2000',
			uk: 'ACAM2000'
		},
		description: undefined,
		forDiseaseIds: ['Smallpox'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'Vaccinia',
		order: 20,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Daptacel',
		name: {
			en: 'Daptacel',
			uk: 'Даптацел'
		},
		description: undefined,
		forDiseaseIds: ['Tetanus'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'DTaP',
		order: 21,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Infanrix',
		name: {
			en: 'Infanrix',
			uk: 'Інфанрікс'
		},
		description: undefined,
		forDiseaseIds: ['Tetanus'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'DTaP',
		order: 22,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Tenivac',
		name: {
			en: 'Tenivac',
			uk: 'Тенівак'
		},
		description: undefined,
		forDiseaseIds: ['Tetanus'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'Td',
		order: 23,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Decavac',
		name: {
			en: 'Decavac',
			uk: 'Декавак'
		},
		description: undefined,
		forDiseaseIds: ['Tetanus'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'Td',
		order: 24,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'DT',
		name: {
			en: 'DT',
			uk: 'DT'
		},
		description: undefined,
		forDiseaseIds: ['Tetanus'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'Td',
		order: 25,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Adacel',
		name: {
			en: 'Adacel',
			uk: 'Адацель'
		},
		description: undefined,
		forDiseaseIds: ['Tetanus'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'Tdap',
		order: 26,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Boostrix',
		name: {
			en: 'Boostrix',
			uk: 'Бустрикс'
		},
		description: undefined,
		forDiseaseIds: ['Tetanus'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'Tdap',
		order: 27,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Kinrix',
		name: {
			en: 'Kinrix',
			uk: 'Кінрікс'
		},
		description: undefined,
		forDiseaseIds: ['Tetanus', 'Polio'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'DTaP-IPV',
		order: 28,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Quadracel',
		name: {
			en: 'Quadracel',
			uk: 'Квадрацель'
		},
		description: undefined,
		forDiseaseIds: ['Tetanus', 'Polio'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'DTaP-IPV',
		order: 29,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'menactra',
		name: {
			en: 'Menactra',
			uk: 'Менактра'
		},
		description: {
			en: 'Menactra is indicated for active immunization to prevent invasive meningococcal disease caused by Neisseria meningitidis serogroups A, C, Y, and W-135. Menactra is approved for use in individuals 9 months through 55 years of age. Menactra does not prevent N meningitidis serogroup B disease.\nA single booster dose of Menactra may be given to individuals 15-55 years of age at continued risk for meningococcal disease if at least 4 years have elapsed since the prior dose.',
			uk: 'Менактра показана для активної імунізації для запобігання інвазивним менінгококовим захворюванням, спричиненим Neisseria meningitidis серогруп A, C, Y та W-135. Менактра схвалена для використання особами віком від 9 місяців до 55 років. Менактра не запобігає захворюванню N-менінгітом серогрупи В.\nОднакова бустерна доза Менактри може бути призначена особам віком 15-55 років із постійним ризиком менінгококової інфекції, якщо минуло щонайменше 4 роки після попередньої дози.'
		},
		forDiseaseIds: ['meningococcal-disease'],
		countOfDoses: 1,
		manufacturer: {
			en: 'Sanofi Pasteur',
			uk: 'Sanofi Pasteur'
		},
		type: 'MenACWY',
		order: 30,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Menveo',
		name: {
			en: 'Menveo',
			uk: 'Менвео'
		},
		description: undefined,
		forDiseaseIds: ['Meningococcal infection'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'MenACWY',
		order: 31,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Bexsero',
		name: {
			en: 'Bexsero',
			uk: 'Бекссеро'
		},
		description: undefined,
		forDiseaseIds: ['Meningococcal infection'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'MenB',
		order: 32,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Trumenba',
		name: {
			en: 'Trumenba',
			uk: 'Труменба'
		},
		description: undefined,
		forDiseaseIds: ['Meningococcal infection'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'MenB',
		order: 33,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Prevnar13',
		name: {
			en: 'Prevnar13',
			uk: 'Превнар13'
		},
		description: undefined,
		forDiseaseIds: ['Pneumococcal infection'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'PCV13',
		order: 34,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Pneumovax 23',
		name: {
			en: 'Pneumovax 23',
			uk: 'Пневмовакс 23'
		},
		description: undefined,
		forDiseaseIds: ['Pneumococcal infection'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'PPSV23',
		order: 35,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Ipol',
		name: {
			en: 'Ipol',
			uk: 'Іполь'
		},
		description: undefined,
		forDiseaseIds: ['Polio'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'Polio',
		order: 36,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'BCG',
		name: {
			en: 'BCG',
			uk: 'БЦЖ'
		},
		description: undefined,
		forDiseaseIds: ['Tuberculosis'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'BCG',
		order: 37,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Varivax',
		name: {
			en: 'Varivax',
			uk: 'Варівакс'
		},
		description: undefined,
		forDiseaseIds: ['Varicella'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'VAR',
		order: 38,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Afluria Quadrivalent',
		name: {
			en: 'Afluria Quadrivalent',
			uk: 'Афлурія чотиривалентний'
		},
		description: undefined,
		forDiseaseIds: ['influenza'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'IIV4',
		order: 39,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Fluarix Quadrivalent',
		name: {
			en: 'Fluarix Quadrivalent',
			uk: 'Флюарікс чотиривалентний'
		},
		description: undefined,
		forDiseaseIds: ['influenza'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'IIV4',
		order: 40,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'FluLaval Quadrivalent',
		name: {
			en: 'FluLaval Quadrivalent',
			uk: 'ФлюЛавал чотиривалентний'
		},
		description: undefined,
		forDiseaseIds: ['influenza'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'IIV4',
		order: 41,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Fluzone Quadrivalent',
		name: {
			en: 'Fluzone Quadrivalent',
			uk: 'Флюзоне чотиривалентний'
		},
		description: undefined,
		forDiseaseIds: ['influenza'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'IIV4',
		order: 42,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'flucelvax-quadrivalent',
		name: {
			en: 'Flucelvax Quadrivalent',
			uk: 'Flucelvax чотиривалентний'
		},
		description: {
			en: 'FLUCELVAX QUADRIVALENT (Influenza Vaccine) is an inactivated vaccine indicated for active immunization for the prevention of influenza disease caused by influenza virus subtypes A and types B contained in the vaccine. FLUCELVAX QUADRIVALENT is approved for use in persons 6 months of age and older.',
			uk: 'ФЛУЦЕЛВАКС КВАДРІВАЛЕНТ (вакцина проти грипу) — це інактивована вакцина, призначена для активної імунізації з метою профілактики захворювання на грип, спричиненого вірусами грипу субтипів А та типів В, які містяться у вакцині. FLUCELVAX QUADRIVALENT схвалений для використання особами віком від 6 місяців.'
		},
		forDiseaseIds: ['influenza'],
		countOfDoses: 1,
		manufacturer: {
			en: 'Seqirus, Inc.',
			uk: 'Seqirus, Inc.'
		},
		type: 'IIV4 (ccIIV4)',
		order: 43,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Fluzone High-Dose Quadrivalent',
		name: {
			en: 'Fluzone High-Dose Quadrivalent',
			uk: 'Флюзоне високодозова чотиривалентна'
		},
		description: undefined,
		forDiseaseIds: ['influenza'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'IIV4 (HD-IIV4)',
		order: 44,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Fluad Quadrivalent',
		name: {
			en: 'Fluad Quadrivalent',
			uk: 'Флюад чотиривалентна'
		},
		description: undefined,
		forDiseaseIds: ['influenza'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'IIV4 (aIIV4)',
		order: 45,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Fluad',
		name: {
			en: 'Fluad',
			uk: 'Флюад'
		},
		description: undefined,
		forDiseaseIds: ['influenza'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'IIV3 (aIIV3)',
		order: 46,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'Flublok Quadrivalent',
		name: {
			en: 'Flublok Quadrivalent',
			uk: 'Флюбок чотиривалентний'
		},
		description: undefined,
		forDiseaseIds: ['influenza'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'RIV4',
		order: 47,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'FluMist Quadrivalent',
		name: {
			en: 'FluMist Quadrivalent',
			uk: 'ФлюМіст чотиривалентний'
		},
		description: undefined,
		forDiseaseIds: ['influenza'],
		countOfDoses: 1,
		manufacturer: undefined,
		type: 'LAIV4',
		order: 48,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	},
	{
		id: 'spikevax',
		name: {
			en: 'Spikevax',
			uk: 'Спайквакс'
		},
		description: {
			en: 'Spikevax (COVID-19 Vaccine, mRNA) is a vaccine for preventing coronavirus disease 2019 (COVID-19) in people aged 6 years and older. Spikevax contains elasomeran, a messenger RNA (mRNA) molecule with instructions for producing a protein from SARS-CoV-2, the virus that causes COVID-19.',
			uk: 'Спайквакс (COVID-19 Vaccine, мРНК) – це вакцина для профілактики коронавірусної хвороби 2019 (COVID-19) у людей віком від 6 років. Спайквакс містить елазомеран, молекулу месенджерної РНК (мРНК) з інструкціями для виробництва білка з SARS-CoV-2, вірусу, який викликає COVID-19.'
		},
		forDiseaseIds: ['COVID-19'],
		countOfDoses: 2,
		manufacturer: {
			en: 'ModernaTx, Inc',
			uk: 'ModernaTx, Inc'
		},
		type: 'SARS-CoV-2',
		order: 49,
		createdOn: '2021-01-01T12:00:00Z',
		updatedOn: undefined
	}
];

// eslint-disable-next-line max-lines
