import { Injectable } from '@angular/core';
import { DocumentClient } from '@nmn-communication/documents';
import { Result } from '@nmn-core/shared';
import { DocumentDescribedComboboxModel, DocumentFilterModel, DocumentGetQuery, DocumentModel, DocumentsQueryHandlerService } from '@nmn-domain/documents';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	mapDocumentDescribedComboboxDtoToModel,
	mapDocumentPagedCollectionDtoToModel,
	mapDtoToModel,
	mapFilterModelToDto
} from '../../../../domain/implementations/documents/factories/document.factory';
import { PageOptionsQuery } from '../../../../modules/core/models/pagination/page-options.query';
import { PagedCollectionModel } from '../../../../modules/core/models/pagination/paged-collection.model';
import { mapPageOptionsQueryToDto } from '../../../../modules/core/utils/pagination/pagination.factory.utils';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapDtoArrayResultToModelArrayResult } from '../../shared/factories/pagination.factory';

@Injectable()
export class DocumentsQueryHandlerViaClientService extends DocumentsQueryHandlerService {

	constructor(
		private readonly client: DocumentClient
	) {
		super();
	}

	public getAsDescribedComboboxes(filter: DocumentFilterModel): Observable<Result<Array<DocumentDescribedComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapDocumentDescribedComboboxDtoToModel))
			);
	}

	public getPagedCollection(query: PageOptionsQuery<DocumentFilterModel>): Observable<Result<PagedCollectionModel<DocumentModel, DocumentFilterModel>, FailureModel>> {
		return this.client
			.getBulk(mapPageOptionsQueryToDto(query, mapFilterModelToDto))
			.pipe(
				map(result => result.map(mapDocumentPagedCollectionDtoToModel, mapFailureToFailureModel))
			);
	}

	public get(query: DocumentGetQuery): Observable<Result<DocumentModel, FailureModel>> {
		return this.client
			.get({ id: query.id, patientId: query.patientId })
			.pipe(
				map(result => result.map(mapDtoToModel, mapFailureToFailureModel))
			);
	}

}
