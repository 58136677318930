import { TranslocoService } from '@ngneat/transloco';
import { HealthComplaintComboboxDto, HealthComplaintFilterDto } from '@nmn-communication/health-complaints';
import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { DiseaseType } from '@nmn-domain/diseases';
import { HealthComplaintType } from '@nmn-domain/health-complaints';
import { FakeDatabase } from '../../databases/fake.database';
import { getPagedCollectionWithoutDtoFilter } from '../../databases/fake.utils';
import { FakeLocalizableEntity } from '../../models/fake-localizable-entity';
import { setTranslation } from '../../utils/localize';

export class HealthComplaintFakeTable {

	private readonly data: Array<HealthComplaintFakeRecord>;

	constructor(
		private readonly database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.data = [...initialData];
	}

	public getAsCombobox(parameter: { id: string }): HealthComplaintComboboxDto {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record not found');
		}

		return record;
	}

	public findAsCombobox(parameter: { id: string }): HealthComplaintComboboxDto {
		const record = this.data
			.find((item: HealthComplaintFakeRecord) => findPredicate(item, parameter));

		return isValueDefined(record) ? this.mapFromRecordToDescribedComboboxDto(record!) : undefined;
	}

	public getComboboxesPagedCollection(filter: HealthComplaintFilterDto): Array<HealthComplaintComboboxDto> {
		const pagedCollection = getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			this.filterPredicateForRecord.bind(this),
			this.mapFromRecordToDescribedComboboxDto.bind(this),
			compareFn
		).items;

		return pagedCollection;
	}

	private filterPredicateForRecord(
		item: HealthComplaintFakeRecord,
		filter: HealthComplaintFilterDto,
		translocoService: TranslocoService
	): boolean {
		let result = true;

		if (result && isArrayDefinedAndNotEmpty(filter.ids)) {
			result = result && filter.ids.some(id => item.id === id);
		}

		if (result && isArrayDefinedAndNotEmpty(filter.ignoreIds)) {
			result = result && filter.ignoreIds.every(ignoreId => item.id !== ignoreId);
		}

		if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
			result = result &&
				setTranslation(translocoService, item.name)
					?.toLowerCase()
					?.indexOf(filter.searchPattern.toLowerCase()) >= 0;
		}

		return result;
	}

	private mapFromRecordToDescribedComboboxDto(record: HealthComplaintFakeRecord): HealthComplaintComboboxDto {
		return {
			id: record.id,
			displayText: setTranslation(this.translocoService, record.name),
			description: setTranslation(this.translocoService, record.description),
			complaintType: record.complaintType,
			type: record.type
		};
	}

}

const findPredicate = (item: HealthComplaintFakeRecord, findParameter: { id: string }): boolean =>
	item.id === findParameter.id;

/* eslint-disable  */
/* eslint-disable complexity */
const compareFn = (
	item1: HealthComplaintComboboxDto,
	item2: HealthComplaintComboboxDto,
	sorting: string
): number => {
	if (sorting === 'displayText asc') {
		return item1.displayText > item2.displayText ? 1 : item1.displayText < item2.displayText ? -1 : 0;
	} else if (sorting === 'displayText desc') {
		return item1.displayText < item2.displayText ? 1 : item1.displayText > item2.displayText ? -1 : 0;
	}

	return 0;
};
/* eslint-enable complexity */
/* eslint-enable */

interface HealthComplaintFakeRecord {
	id: string;
	name: FakeLocalizableEntity;
	description: FakeLocalizableEntity;
	complaintType: HealthComplaintType;
	type: DiseaseType | undefined;
}

const initialData: Array<HealthComplaintFakeRecord> = [
	{
		id: 'headache',
		name: { en: 'Headache', uk: 'Головний біль' },
		description: { en: 'Headache description', uk: 'Головний біль (опис)' },
		complaintType: HealthComplaintType.Symptom,
		type: undefined
	},
	{
		id: 'weakness',
		name: { en: 'Weakness', uk: 'Слабкість' },
		description: { en: 'Weakness description', uk: 'Слабкість (опис)' },
		complaintType: HealthComplaintType.Symptom,
		type: undefined
	},
	{
		id: 'pneumonia',
		name: { en: 'Pneumonia', uk: 'Пневмонія' },
		description: {
			en: 'A disorder characterized by inflammation focally or diffusely affecting the lung parenchyma.',
			uk: 'Захворювання, що характеризується вогнищевим або дифузним запаленням легеневої паренхіми.'
		},
		complaintType: HealthComplaintType.Disease,
		type: DiseaseType.DiseasesOfTheRespiratorySystem
	},
	{
		id: 'acute bronchitis',
		name: { en: 'Acute bronchitis', uk: 'Гострий бронхіт' },
		description: {
			en: 'Sudden inflammation of the tracheobronchial tree, which comprises the trachea, or windpipe, and the bronchi; typically associated with a viral upper respiratory tract infection, such as the common cold, and is usually mild.',
			uk: 'Раптове запалення трахеобронхіального дерева, яке включає трахею, або трахею, і бронхи; як правило, пов’язаний із вірусною інфекцією верхніх дихальних шляхів, наприклад звичайною застудою, і зазвичай легкий.'
		},
		complaintType: HealthComplaintType.Disease,
		type: DiseaseType.DiseasesOfTheRespiratorySystem
	}
];

// eslint-disable-next-line max-lines
