import { HealthComplaintClient, HealthComplaintComboboxDto, HealthComplaintFilterDto } from '@nmn-communication/health-complaints';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { wrapFuncCallAsFakeClientResponse } from '../../../../modules/fakes/databases/fake.utils';
import { HealthComplaintFakeClientConfiguration } from '../configurations/health-complaint.fake-client.configuration';

export class HealthComplaintFakeClient extends HealthComplaintClient {

	constructor(
		private readonly configuration: HealthComplaintFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulkAsComboboxes(
		filter: HealthComplaintFilterDto
	): Observable<Result<Array<HealthComplaintComboboxDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.healthComplaintTable.getComboboxesPagedCollection(filter),
			this.configuration.serverDelayMs);
	}

}
