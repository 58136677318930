import { MedicationBodySiteDescribedComboboxDto, MedicationBodySiteFilterDto } from '@nmn-communication/medication-body-sites';
import { mapMedicationBodySiteTypeFromString, MedicationBodySiteDescribedComboboxModel, MedicationBodySiteFilterModel } from '@nmn-domain/medication-body-sites';

export const mapFilterModelToDto = (model: MedicationBodySiteFilterModel): MedicationBodySiteFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern
	}
);

export const mapFilterDtoToModel = (dto: MedicationBodySiteFilterDto): MedicationBodySiteFilterModel => (
	new MedicationBodySiteFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern
	)
);

export const mapMedicationBodySiteDescribedComboboxDtoToModel = (dto: MedicationBodySiteDescribedComboboxDto): MedicationBodySiteDescribedComboboxModel => (
	new MedicationBodySiteDescribedComboboxModel(
		dto.id,
		dto.displayText,
		dto.description,
		mapMedicationBodySiteTypeFromString(dto.type)
	)
);
