import { ServerConfigurationDto } from '@nmn-communication/server-configuration';
import {
	AccountPageRestrictionsConfiguration, BusinessRestrictionsConfiguration, DocuemntsPageRestrictionsConfiguration, DocumentUploadConfiguration,
	EncountersPageRestrictionsConfiguration, FacebookServicesConfiguration, FileUploadConfiguration, GeneticCardPageRestrictionsConfiguration,
	GeneticFileUploadConfiguration, GoogleServicesConfiguration, ImageUploadConfiguration, PatientsPageRestrictionsConfiguration, ServerConfigurationModel,
	StorageServicesConfiguration, TakenMedicationsPageRestrictionsConfiguration, TreatmentCheckerPageRestrictionsConfiguration, VaccinationPageRestrictionsConfiguration
} from '@nmn-domain/server-configuration';

export const mapDtoToModel = (dto: ServerConfigurationDto): ServerConfigurationModel =>
	new ServerConfigurationModel(
		dto.serverVersion,
		dto.serverEnvironment,
		new GoogleServicesConfiguration(dto.googleServices?.googleOAuthClientId),
		new FacebookServicesConfiguration(dto.facebookServices?.facebookAuthClientId, dto.facebookServices?.facebookAuthSdkNonce),
		new StorageServicesConfiguration(dto.storageServices?.storage),
		new FileUploadConfiguration(
			dto.businessRestrictions?.fileUploadRestrictions?.restrictedExtensions,
			dto.businessRestrictions?.fileUploadRestrictions?.fileMaxSize,
			dto.businessRestrictions?.fileUploadRestrictions?.storageCapacity,
			new ImageUploadConfiguration(
				dto.businessRestrictions?.fileUploadRestrictions?.imageUploadConfiguration?.allowedExtensions,
				dto.businessRestrictions?.fileUploadRestrictions?.imageUploadConfiguration?.maxSize,
				dto.businessRestrictions?.fileUploadRestrictions?.imageUploadConfiguration?.fileExtension,
				dto.businessRestrictions?.fileUploadRestrictions?.imageUploadConfiguration?.imageQuality
			),
			new DocumentUploadConfiguration(
				dto.businessRestrictions?.fileUploadRestrictions?.documentUploadConfiguration.allowedExtensions,
				dto.businessRestrictions?.fileUploadRestrictions?.documentUploadConfiguration.maxSize
			),
			new GeneticFileUploadConfiguration(
				dto.businessRestrictions?.fileUploadRestrictions?.geneticFileUploadConfiguration.allowedExtensions,
				dto.businessRestrictions?.fileUploadRestrictions?.geneticFileUploadConfiguration.maxSize
			)
		),
		new BusinessRestrictionsConfiguration(
			// TODO: Implement account restrictions
			new AccountPageRestrictionsConfiguration(),
			new DocuemntsPageRestrictionsConfiguration(),
			new EncountersPageRestrictionsConfiguration(),
			new GeneticCardPageRestrictionsConfiguration(),
			new PatientsPageRestrictionsConfiguration(),
			new TakenMedicationsPageRestrictionsConfiguration(),
			new TreatmentCheckerPageRestrictionsConfiguration(),
			new VaccinationPageRestrictionsConfiguration()
		)
	);
