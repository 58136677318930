import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { DocumentDescribedComboboxModel } from './document-described-combobox.model';

export class DocumentFilterModel {

	public readonly ids?: Array<string>;
	public readonly ignoreIds?: Array<string>;
	public readonly documentTypeIds?: Array<string>;
	public readonly patientIds?: Array<string>;
	public readonly searchPattern?: string;

	constructor(
		ids: Array<string> | undefined,
		ignoreIds: Array<string> | undefined,
		documentTypeIds: Array<string> | undefined,
		patientIds: Array<string> | undefined,
		searchPattern: string | undefined
	) {

		this.ids = ids;
		this.ignoreIds = ignoreIds;
		this.documentTypeIds = documentTypeIds;
		this.patientIds = patientIds;
		this.searchPattern = searchPattern;

	}

	public static createForDocumentsAutocomplete(searchPattern: string, ignoreIds: Array<string>, patientId: string): DocumentFilterModel {
		return new DocumentFilterModel(undefined, ignoreIds, undefined, [patientId], searchPattern);
	}

	public static createForDocumentAutocomplete(
		searchPattern: string,
		selectedItem: DocumentDescribedComboboxModel,
		patientId: string
	): DocumentFilterModel {
		return new DocumentFilterModel(
			undefined,
			isStringDefinedAndNotEmpty(selectedItem?.id) ? [selectedItem.id] : undefined,
			undefined,
			[patientId],
			searchPattern
		);
	}

}
