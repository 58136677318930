import { Injector, Provider } from '@angular/core';
import { RegistrationClient } from '@nmn-communication/accounts';
import { registrationClientFactory } from './factories/registration.client.factory';

export const accountsRegistrationProviders: Array<Provider> = [
	{
		provide: RegistrationClient,
		useFactory: registrationClientFactory,
		deps: [Injector]
	}
];
