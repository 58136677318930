import { Injector } from '@angular/core';
import { MedicationClient } from '@nmn-communication/medications';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { MedicationFakeClientBuilder } from '../builders/medication.fake-client.builder';
import { MedicationFakeClientConfigurationBuilder } from '../builders/medication.fake-client.configuration.builder';
import { MedicationHttpClientBuilder } from '../builders/medication.http-client.builder';
import { MedicationHttpClientConfigurationBuilder } from '../builders/medication.http-client.configuration.builder';
import { MedicationFakeClient } from '../services/medication.fake-client';
import { MedicationHttpClient } from '../services/medication.http-client';

export const medicationClientFactory = (injector: Injector): MedicationClient => {
	const useFake = environment?.api?.resources?.medications?.useFake ||
		!isValueDefined(environment?.api?.resources?.medications?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		medicationFakeClientFactory(injector) :
		medicationHttpClientFactory(injector);
};

export const medicationFakeClientFactory = (injector: Injector): MedicationFakeClient => {
	const configuration = new MedicationFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.medications.serverDelayMs)
		.build();

	return new MedicationFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const medicationHttpClientFactory = (injector: Injector): MedicationHttpClient => {
	const configuration = new MedicationHttpClientConfigurationBuilder()
		.withBaseUrl(environment?.api?.resources?.medications?.baseUrl ?? environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.medications.resourceRelativePath)
		.withResourceTypeVersion(environment?.api?.resources?.medications?.resourceTypeVersion ?? environment?.api?.defaultResourceTypeVersion)
		.build();

	return new MedicationHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
