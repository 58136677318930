import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { VaccineHttpClientConfiguration } from '../configurations/vaccine.http-client.configuration';
import { VaccineHttpClient } from '../services/vaccine.http-client';

export class VaccineHttpClientBuilder
	extends HttpClientBuilder<VaccineHttpClient, VaccineHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): VaccineHttpClient {
		return new VaccineHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
