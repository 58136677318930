import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { ContactsHttpClientConfiguration } from '../configurations/contacts.http-client.configuration';

export class ContactsHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<ContactsHttpClientConfiguration> {

	public build(): ContactsHttpClientConfiguration {
		return new ContactsHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
