import { Provider } from '@angular/core';
import { EmailConfirmationCommandHandlerService, InitiateEmailConfirmationFormInModelResolver } from '@nmn-domain/accounts';
import { InitiateEmailConfirmationFormInModelViaRouteResolver } from './resolvers/initiate-email-confirmation-form-in-model-via-route.resolver';
import { EmailConfirmationCommandHandlerViaClientService } from './services/email-configuration.command-handler-via-client.service';

export const accountsConfirmEmailProviders: Array<Provider> = [
	{
		provide: EmailConfirmationCommandHandlerService,
		useClass: EmailConfirmationCommandHandlerViaClientService
	},
	{
		provide: InitiateEmailConfirmationFormInModelResolver,
		useClass: InitiateEmailConfirmationFormInModelViaRouteResolver
	}
];
