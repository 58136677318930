export class LoginCommand {

	public readonly login: string;
	public readonly password: string;
	public readonly isPersistent: boolean;

	constructor(
		login: string,
		password: string,
		isPersistent: boolean
	) {
		// TODO: Guard.NotNull

		this.login = login;
		this.password = password;
		this.isPersistent = isPersistent;

	}

}
