import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { PagedCollectionModel, PageOptionsQuery } from '../../shared';
import { FailureModel } from '../../shared/failures/failure.model';
import { DoctorEncounterComboboxModel } from '../models/doctor-encounter-combobox.model';
import { DoctorEncounterFilterModel } from '../models/doctor-encounter-filter.model';
import { DoctorEncounterModel } from '../models/doctor-encounter.model';
import { DoctorEncounterGetQuery } from '../models/queries/doctor-encounter.get.query';

export abstract class DoctorEncounterQueryHandlerService {

	public abstract getAsDescribedComboboxes(
		query: DoctorEncounterFilterModel
	): Observable<Result<Array<DoctorEncounterComboboxModel>, FailureModel>>;

	public abstract getPagedCollection(
		query: PageOptionsQuery<DoctorEncounterFilterModel>
	): Observable<Result<PagedCollectionModel<DoctorEncounterModel, DoctorEncounterFilterModel>, FailureModel>>;

	public abstract get(
		query: DoctorEncounterGetQuery
	): Observable<Result<DoctorEncounterModel, FailureModel>>;

}
