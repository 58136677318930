export class UserProfileFormFailure {

	public readonly isValidationFailure: boolean;

	private constructor(
		isValidationFailure: boolean
	) {
		this.isValidationFailure = isValidationFailure;
	}

	public static createAsValidationFailure(): UserProfileFormFailure {
		return new UserProfileFormFailure(true);
	}

}
