import { HttpClientConfigurationBuilder } from '../../../../../communication/implementation/shared/builders/http-client.configuration.builder';
import { ResetPasswordHttpClientConfiguration } from '../configurations/reset-password.rest.http-client.configuration';

export class ResetPasswordHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<ResetPasswordHttpClientConfiguration> {

	public build(): ResetPasswordHttpClientConfiguration {
		return new ResetPasswordHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
