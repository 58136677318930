import { Provider } from '@angular/core';
import { OrganizationIdResolver } from '@nmn-domain/organizations';
import { OrganizationIdViaActivatedRouteResolver } from './resolvers/organization-id.via-activated-route.resolver';

export const organizationProviders: Array<Provider> = [
	{
		provide: OrganizationIdResolver,
		useClass: OrganizationIdViaActivatedRouteResolver
	}
];
