import { Injectable } from '@angular/core';
import { TreatmentCheckerClient } from '@nmn-communication/treatment-checker/services/treatment-checker.client';
import { Result } from '@nmn-core/shared';
import { FailureModel, PagedCollectionModel, PageOptionsQuery } from '@nmn-domain/shared';
import {
	TreatmentCheckerQueryHandlerService, TreatmentCheckerReportFilterModel, TreatmentCheckerReportGetQuery, TreatmentCheckerReportModel, TreatmentCheckerReportShortModel
} from '@nmn-domain/treatment-checker';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapPageOptionsQueryToDto } from '../../shared/factories/pagination.factory';
import {
	mapFilterModelToDto, mapGetQueryToParameterDto, mapTreatmentCheckerReportDtoToModel, mapTreatmentCheckerReportPagedCollectionDtoToModel
} from '../factories/treatment-checker.factory';

@Injectable()
export class TreatmentCheckerQueryHandlerViaClientService extends TreatmentCheckerQueryHandlerService {

	constructor(
		private readonly client: TreatmentCheckerClient
	) {
		super();
	}

	public getReportsPage(
		query: PageOptionsQuery<TreatmentCheckerReportFilterModel>
	): Observable<Result<PagedCollectionModel<TreatmentCheckerReportShortModel, TreatmentCheckerReportFilterModel>, FailureModel>> {
		return this.client
			.getReportsPage(mapPageOptionsQueryToDto(query, mapFilterModelToDto))
			.pipe(map(result => result.map(mapTreatmentCheckerReportPagedCollectionDtoToModel, mapFailureToFailureModel)));
	}

	public getReport(query: TreatmentCheckerReportGetQuery): Observable<Result<TreatmentCheckerReportModel, FailureModel>> {
		return this.client
			.getReport(mapGetQueryToParameterDto(query))
			.pipe(map(result => result.map(mapTreatmentCheckerReportDtoToModel, mapFailureToFailureModel)));
	}

}
