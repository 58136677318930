import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { HealthIssueSeverityHttpClientConfiguration } from '../configurations/health-issue-severity.http-client.configuration';
import { HealthIssueSeverityHttpClient } from '../services/health-issue-severity.http-client';

export class HealthIssueSeverityHttpClientBuilder
	extends HttpClientBuilder<HealthIssueSeverityHttpClient, HealthIssueSeverityHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): HealthIssueSeverityHttpClient {
		return new HealthIssueSeverityHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
