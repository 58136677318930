import { Injectable } from '@angular/core';
import { TreatmentCheckerClient } from '@nmn-communication/treatment-checker/services/treatment-checker.client';
import { Result } from '@nmn-core/shared';
import { FailureModel } from '@nmn-domain/shared';
import {
	PatientTreatmentCheckerAnalyzeCommand, TreatmentCheckerAnalyzeCommand, TreatmentCheckerCommandHandlerService,
	TreatmentCheckerReportDeleteBulkCommand, TreatmentCheckerReportDeleteCommand
} from '@nmn-domain/treatment-checker';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommandResult, EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import {
	mapDeleteBulkCheckTreatmentReportCommandToParameterDto, mapDeleteCheckTreatmentReportCommandToParameterDto,
	mapPatientTreatmentCheckerAnalyzeCommandToParameterDto, mapTreatmentCheckerAnalyzeCommandToParameterDto
} from '../factories/treatment-checker.factory';

@Injectable()
export class TreatmentCheckerCommandHandlerViaClientService extends TreatmentCheckerCommandHandlerService {

	public constructor(
		private readonly client: TreatmentCheckerClient
	) {
		super();
	}

	public handlePatientTreatmentCheckerAnalyzeCommand(
		command: PatientTreatmentCheckerAnalyzeCommand
	): Observable<Result<CommandResult<PatientTreatmentCheckerAnalyzeCommand, string>, FailureModel>> {
		return this.client
			.checkTreatmentForPatient(mapPatientTreatmentCheckerAnalyzeCommandToParameterDto(command))
			.pipe(
				map(result => result.map(success => new CommandResult(command, success), mapFailureToFailureModel))
			);
	}

	public handleTreatmentCheckerAnalyzeCommand(
		command: TreatmentCheckerAnalyzeCommand
	): Observable<Result<CommandResult<TreatmentCheckerAnalyzeCommand, string>, FailureModel>> {
		return this.client
			.checkTreatmentForAnonymous(mapTreatmentCheckerAnalyzeCommandToParameterDto(command))
			.pipe(
				map(result => result.map(success => new CommandResult(command, success), mapFailureToFailureModel))
			);
	}

	public handleTreatmentCheckerReportDeleteCommand(
		command: TreatmentCheckerReportDeleteCommand
	): Observable<Result<EmptyCommandResult<TreatmentCheckerReportDeleteCommand>, FailureModel>> {
		return this.client
			.delete(mapDeleteCheckTreatmentReportCommandToParameterDto(command))
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

	public handleTreatmentCheckerReportDeleteBulkCommand(
		command: TreatmentCheckerReportDeleteBulkCommand
	): Observable<Result<EmptyCommandResult<TreatmentCheckerReportDeleteBulkCommand>, FailureModel>> {
		return this.client
			.deleteBulk(mapDeleteBulkCheckTreatmentReportCommandToParameterDto(command))
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

}
