import { Provider } from '@angular/core';
import { MedicationQueryHandlerService } from '@nmn-domain/medications';
import { MedicationQueryHandlerViaClientService } from './services/medication.query-handler-via-client.service';

export const medicationProviders: Array<Provider> = [
	{
		provide: MedicationQueryHandlerService,
		useClass: MedicationQueryHandlerViaClientService
	}
];
