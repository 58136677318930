import { GeneComboboxModel } from '@nmn-domain/genes';
import { GeneticTestModel } from '../genetic-test.model';
import { GeneticTestResultFormInModel } from './genetic-test-result-form.in-model';

export class GeneticTestFormInModel {

	public readonly patientId: string;
	public readonly date: Date;
	public readonly gene: GeneComboboxModel;
	public readonly geneticTestResults: Array<GeneticTestResultFormInModel>;
	public readonly comment: string | undefined;
	public readonly certificateIssuer: string | undefined;
	public readonly certificateUid: string | undefined;

	private constructor(
		patientId: string,
		date: Date | undefined,
		gene: GeneComboboxModel | undefined,
		geneticTestResults: Array<GeneticTestResultFormInModel>,
		comment: string | undefined,
		certificateIssuer: string | undefined,
		certificateUid: string | undefined
	) {
		// TODO: Guard.isDefined

		this.patientId = patientId;
		this.date = date;
		this.gene = gene;
		this.geneticTestResults = geneticTestResults;
		this.comment = comment;
		this.certificateIssuer = certificateIssuer;
		this.certificateUid = certificateUid;
	}

	public static createFromExistingModel = (model: GeneticTestModel): GeneticTestFormInModel => {
		// TODO: Guard.isDefined(model);

		return new GeneticTestFormInModel(
			model.patientId,
			model.date,
			model.gene,
			model.geneticTestResults.map(GeneticTestResultFormInModel.createFromExistingModel),
			model.comment,
			model.certificateIssuer,
			model.certificateUid
		);
	}

	public static createEmpty = (patientId: string): GeneticTestFormInModel => {
		// TODO: Guard.isDefined(patientId);

		return new GeneticTestFormInModel(
			patientId,
			new Date(),
			undefined,
			[],
			undefined,
			undefined,
			undefined
		);
	}

}
