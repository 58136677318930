export class GeneticFileParseRequestGetQuery {

	public id: string;
	public patientId: string;
	public isActive: boolean;

	private constructor(
		id: string,
		patientId: string,
		isActive: boolean
	) {

		// TODO: Guard add defined check

		this.id = id;
		this.patientId = patientId;
		this.isActive = isActive;
	}

	public static create(id: string, patientId: string): GeneticFileParseRequestGetQuery {
		return new GeneticFileParseRequestGetQuery(id, patientId, true);
	}

}
