import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { DescribedComboboxModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';

export abstract class MedicationBodySitesResolver implements Resolve<Array<DescribedComboboxModel>> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	): Observable<Array<DescribedComboboxModel>> | Array<DescribedComboboxModel>;

}
