import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { DescribedComboboxDto, Failure } from '../../shared';
import { AllergyIntoleranceCriticalityFilterDto } from '../models/allergy-intolerance-criticality-filter.dto';

export abstract class AllergyIntoleranceCriticalityClient {

	public abstract getBulkAsComboboxes(filter: AllergyIntoleranceCriticalityFilterDto): Observable<Result<Array<DescribedComboboxDto<string>>, Failure>>;

}
