export class InitiateResetPasswordFormInModel {

	public readonly email?: string;

	private constructor(
		email: string | undefined
	) {
		this.email = email;
	}

	public static createEmpty = (): InitiateResetPasswordFormInModel => {
		return new InitiateResetPasswordFormInModel(undefined);
	}

	public static createByEmail = (email: string): InitiateResetPasswordFormInModel => {
		// TODO: Guard.IsDefined(email);
		return new InitiateResetPasswordFormInModel(email);
	}

}
