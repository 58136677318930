import { isStringDefinedAndNotEmpty } from './string.utils';
import { isValueDefined } from './value.utils';

export const mapDateToServerFormat = (date: Date | undefined): string | undefined =>
	date?.toISOString();

export const mapDateToServerFormatSafely = (date: Date): string => {
	// TODO: add Guard check
	if (!isValueDefined(date)) {
		throw new Error('Date is not defined.');
	}

	return date.toISOString();
};

export const mapDateInServerFormatToDate = (dateAsString: string | undefined): Date | undefined =>
	isValueDefined(dateAsString) ?
		mapDateInServerFormatToDateSafely(dateAsString) :
		undefined;

export const mapDateInServerFormatToDateSafely = (dateAsString: string): Date => {
	// TODO: add Guard check
	if (!isStringDefinedAndNotEmpty(dateAsString)) {
		throw new Error('Date in string format is invalid.');
	}

	return new Date(dateAsString);
};

export const diffDateInDays = (date1: Date, date2: Date): number => {
	// TODO: add null check
	return Math.ceil(diffDateInMs(date1, date2) / (1000 * 3600 * 24));
};

export const diffDateInMs = (date1: Date, date2: Date): number => {
	// TODO: add null check
	return Math.abs(date2.getTime() - date1.getTime());
};

export const ageFromDateOfBirthday = (dateOfBirth: Date): number => {
	// TODO: dateOfBirth add defined check
	const today = new Date();
	let age = today.getFullYear() - dateOfBirth.getFullYear();
	const monthDiff = today.getMonth() - dateOfBirth.getMonth();

	if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())) {
		age--;
	}

	return age;
};
