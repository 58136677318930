import { Injector, Provider } from '@angular/core';
import { UserProfileClient } from '@nmn-communication/accounts';
import { userProfileClientFactory } from './factories/user-profile.client.factory';

export const accountsUserProfileProviders: Array<Provider> = [
	{
		provide: UserProfileClient,
		useFactory: userProfileClientFactory,
		deps: [Injector]
	}
];
