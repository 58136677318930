import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CompleteResetPasswordParameter, InitiateResetPasswordParameter, ResetPasswordClient } from '@nmn-communication/accounts';
import { Failure } from '@nmn-communication/shared';
import { EmptyResult } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { createEmptyResultFromResponse, createFailedEmptyResultFromErrorResponse } from '../../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { ResetPasswordHttpClientConfiguration } from '../configurations/reset-password.rest.http-client.configuration';

export class ResetPasswordHttpClient extends ResetPasswordClient {

	constructor(
		private readonly configuration: ResetPasswordHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public completeResetPassword(parameter: CompleteResetPasswordParameter): Observable<EmptyResult<Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/resetPassword`;
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.put(url, payload, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

	public initiateResetPassword(parameter: InitiateResetPasswordParameter): Observable<EmptyResult<Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}/createResetPasswordToken`;
		const payload = parameter;
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.post(url, payload, { headers })
			.pipe(
				createEmptyResultFromResponse(),
				createFailedEmptyResultFromErrorResponse()
			);
	}

}
