import { isValueDefined } from '@nmn-core/utils';
import { FileUploaderFakeClientConfiguration } from '../configurations/file-uploader-fake-client.configuration';

export class FileUploaderFakeClientConfigurationBuilder {

	private readonly serverDelayMs: { min: number, max: number } = { min: 0, max: 0 };

	public withServerDelay(
		serverDelayMs: { min: number, max: number }
	): FileUploaderFakeClientConfigurationBuilder {

		if (isValueDefined(serverDelayMs) &&
			isValueDefined(serverDelayMs.min) &&
			isValueDefined(serverDelayMs.max) &&
			serverDelayMs.min <= serverDelayMs.max) {

			this.serverDelayMs.min = serverDelayMs.min;
			this.serverDelayMs.max = serverDelayMs.max;

		}

		return this;

	}

	public build(): FileUploaderFakeClientConfiguration {
		return new FileUploaderFakeClientConfiguration(
			this.serverDelayMs);
	}

}
