import { CompleteResetPasswordParameter, InitiateResetPasswordParameter, ResetPasswordClient } from '@nmn-communication/accounts';
import { Failure } from '@nmn-communication/shared';
import { EmptyResult } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../../modules/fakes/databases/fake.database';
import { wrapActionCallAsFakeClientResponse } from '../../../../../modules/fakes/databases/fake.utils';
import { ResetPasswordFakeClientConfiguration } from '../configurations/reset-password.rest.fake-client.configuration';

export class ResetPasswordFakeClient extends ResetPasswordClient {

	constructor(
		private readonly configuration: ResetPasswordFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public completeResetPassword(parameter: CompleteResetPasswordParameter): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.resetPasswordTokenTable.use(parameter);
				this.fakeDatabase.usersTable.applyResetPassword(parameter);
			},
			this.configuration.serverDelayMs);
	}

	public initiateResetPassword(parameter: InitiateResetPasswordParameter): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				const userId = this.fakeDatabase.usersTable.getIdByEmail(parameter.email, true);
				this.fakeDatabase.resetPasswordTokenTable.create(userId);
			},
			this.configuration.serverDelayMs);
	}

}
