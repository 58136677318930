import { Result } from '@nmn-core/shared';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { TimezoneComboboxModel } from '../models/timezone-combobox.model';
import { TimezoneFilterModel } from '../models/timezone-filter.model';

export abstract class TimezoneQueryHandlerService {

	public abstract getAsComboboxes(query: TimezoneFilterModel): Observable<Result<Array<TimezoneComboboxModel>, FailureModel>>;

}
