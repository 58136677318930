import { Provider } from '@angular/core';
import { AllergyIntoleranceVerificationStatusesResolver, AllergyIntoleranceVerificationStatusQueryHandlerService } from '@nmn-domain/allergy-intolerance-verification-statuses';
import { AllergyIntoleranceVerificationStatusesViaQueryHandlerResolver } from './resolvers/allergy-intolerance-verification-statuses.via-query-handler.resolver';
import { AllergyIntoleranceVerificationStatusQueryHandlerViaClientService } from './services/allergy-intolerance-verification-status.query-handler-via-client.service';

export const allergyIntoleranceVerificationStatusProviders: Array<Provider> = [
	{
		provide: AllergyIntoleranceVerificationStatusQueryHandlerService,
		useClass: AllergyIntoleranceVerificationStatusQueryHandlerViaClientService
	},
	{
		provide: AllergyIntoleranceVerificationStatusesResolver,
		useClass: AllergyIntoleranceVerificationStatusesViaQueryHandlerResolver
	}
];
