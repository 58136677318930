import { ValueRestrictionOptional } from '../../../../../modules/core/configurations/restrictions/value-restriction-optional';

export interface DocumentFormConfiguration {
	restrictions: {
		description: {
			length: ValueRestrictionOptional<number>;
			controlRowsCount: number;
		};
		healthIssues: {
			isEnabled: boolean;
		};
		doctorEncounters: {
			isEnabled: boolean;
		};
		takenMedications: {
			isEnabled: boolean;
		};
	};
}

export const defaultDocumentFormConfiguration: DocumentFormConfiguration = {
	restrictions: {
		description: {
			length: {
				max: 3000,
				min: 0
			},
			controlRowsCount: 5
		},
		healthIssues: {
			isEnabled: true
		},
		doctorEncounters: {
			isEnabled: true
		},
		takenMedications: {
			isEnabled: true
		}
	}
};

export const forRelatedToHealthIssueDocumentFormConfiguration: DocumentFormConfiguration = {
	restrictions: {
		...defaultDocumentFormConfiguration.restrictions,
		healthIssues: {
			isEnabled: false
		}
	}
};

export const forRelatedToDoctorEncounterDocumentFormConfiguration: DocumentFormConfiguration = {
	restrictions: {
		...defaultDocumentFormConfiguration.restrictions,
		doctorEncounters: {
			isEnabled: false
		}
	}
};

export const forRelatedToTakenMedicationDocumentFormConfiguration: DocumentFormConfiguration = {
	restrictions: {
		...defaultDocumentFormConfiguration.restrictions,
		takenMedications: {
			isEnabled: false
		}
	}
};
