import { booleanToNumber, isStringDefinedAndNotEmpty, numberToBoolean } from '@nmn-core/utils';

export class TreatmentCheckerReportAnamnesisModel {

	public readonly hasHealthIssuesWithLiver?: boolean;
	public readonly hasHealthIssuesWithKidney?: boolean;
	public readonly hasHealthIssuesWithHeart?: boolean;
	public readonly hasHealthIssuesWithStomach?: boolean;
	public readonly hasHealthIssuesWithNervousSystem?: boolean;
	public readonly hasHealthIssuesWithImmuneSystem?: boolean;
	public readonly hasHealthIssuesWithEndocrine?: boolean;
	public readonly hasHealthIssuesWithCancer?: boolean;
	public readonly hasHealthIssuesWithEye?: boolean;
	public readonly hasHealthIssuesWithEar?: boolean;
	public readonly hasHealthIssuesWithMuscles?: boolean;
	public readonly hasHealthIssuesWithGeneticConditions?: boolean;

	constructor(
		hasHealthIssuesWithLiver: boolean | undefined,
		hasHealthIssuesWithKidney: boolean | undefined,
		hasHealthIssuesWithHeart: boolean | undefined,
		hasHealthIssuesWithStomach: boolean | undefined,
		hasHealthIssuesWithNervousSystem: boolean | undefined,
		hasHealthIssuesWithImmuneSystem: boolean | undefined,
		hasHealthIssuesWithEndocrine: boolean | undefined,
		hasHealthIssuesWithCancer: boolean | undefined,
		hasHealthIssuesWithEye: boolean | undefined,
		hasHealthIssuesWithEar: boolean | undefined,
		hasHealthIssuesWithMuscles: boolean | undefined,
		hasHealthIssuesWithGeneticConditions: boolean | undefined
	) {
		this.hasHealthIssuesWithLiver = hasHealthIssuesWithLiver;
		this.hasHealthIssuesWithKidney = hasHealthIssuesWithKidney;
		this.hasHealthIssuesWithHeart = hasHealthIssuesWithHeart;
		this.hasHealthIssuesWithStomach = hasHealthIssuesWithStomach;
		this.hasHealthIssuesWithNervousSystem = hasHealthIssuesWithNervousSystem;
		this.hasHealthIssuesWithImmuneSystem = hasHealthIssuesWithImmuneSystem;
		this.hasHealthIssuesWithEndocrine = hasHealthIssuesWithEndocrine;
		this.hasHealthIssuesWithCancer = hasHealthIssuesWithCancer;
		this.hasHealthIssuesWithEye = hasHealthIssuesWithEye;
		this.hasHealthIssuesWithEar = hasHealthIssuesWithEar;
		this.hasHealthIssuesWithMuscles = hasHealthIssuesWithMuscles;
		this.hasHealthIssuesWithGeneticConditions = hasHealthIssuesWithGeneticConditions;
	}

	public getCode(): string {
		const liverCode = booleanToNumber(this.hasHealthIssuesWithLiver) ?? undefinedBooleanCode;
		const kidneyCode = booleanToNumber(this.hasHealthIssuesWithKidney) ?? undefinedBooleanCode;
		const heartCode = booleanToNumber(this.hasHealthIssuesWithHeart) ?? undefinedBooleanCode;
		const stomachCode = booleanToNumber(this.hasHealthIssuesWithStomach) ?? undefinedBooleanCode;
		const nervousCode = booleanToNumber(this.hasHealthIssuesWithNervousSystem) ?? undefinedBooleanCode;
		const immuneCode = booleanToNumber(this.hasHealthIssuesWithImmuneSystem) ?? undefinedBooleanCode;
		const endocrineCode = booleanToNumber(this.hasHealthIssuesWithEndocrine) ?? undefinedBooleanCode;
		const cancerCode = booleanToNumber(this.hasHealthIssuesWithCancer) ?? undefinedBooleanCode;
		const eyeCode = booleanToNumber(this.hasHealthIssuesWithEye) ?? undefinedBooleanCode;
		const earCode = booleanToNumber(this.hasHealthIssuesWithEar) ?? undefinedBooleanCode;
		const musclesCode = booleanToNumber(this.hasHealthIssuesWithMuscles) ?? undefinedBooleanCode;
		const geneticCode = booleanToNumber(this.hasHealthIssuesWithGeneticConditions) ?? undefinedBooleanCode;

		return `${liverCode}${kidneyCode}${heartCode}${stomachCode}${nervousCode}${immuneCode}${endocrineCode}${cancerCode}${eyeCode}${earCode}${musclesCode}${geneticCode}`;
	}

	public static createEmpty(): TreatmentCheckerReportAnamnesisModel {
		return new TreatmentCheckerReportAnamnesisModel(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
	}

	public static createByCode(code: string): TreatmentCheckerReportAnamnesisModel {
		return isStringDefinedAndNotEmpty(code) && code.length >= 12 ?
			new TreatmentCheckerReportAnamnesisModel(
				TreatmentCheckerReportAnamnesisModel.decodeProperty(code[0]),
				TreatmentCheckerReportAnamnesisModel.decodeProperty(code[1]),
				TreatmentCheckerReportAnamnesisModel.decodeProperty(code[2]),
				TreatmentCheckerReportAnamnesisModel.decodeProperty(code[3]),
				TreatmentCheckerReportAnamnesisModel.decodeProperty(code[4]),
				TreatmentCheckerReportAnamnesisModel.decodeProperty(code[5]),
				TreatmentCheckerReportAnamnesisModel.decodeProperty(code[6]),
				TreatmentCheckerReportAnamnesisModel.decodeProperty(code[7]),
				TreatmentCheckerReportAnamnesisModel.decodeProperty(code[8]),
				TreatmentCheckerReportAnamnesisModel.decodeProperty(code[9]),
				TreatmentCheckerReportAnamnesisModel.decodeProperty(code[10]),
				TreatmentCheckerReportAnamnesisModel.decodeProperty(code[11])
			) :
			TreatmentCheckerReportAnamnesisModel.createEmpty();
	}


	private static decodeProperty(codedSymbol: string): boolean | undefined {
		return codedSymbol.length != 1 || codedSymbol === undefinedBooleanCode ? undefined : numberToBoolean(Number(codedSymbol));
	}

}

const undefinedBooleanCode = '2';
