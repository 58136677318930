export class InitiateEmailConfirmationFormInModel {

	public readonly email?: string;

	private constructor(
		email: string | undefined
	) {
		this.email = email;
	}

	public static createEmpty = (): InitiateEmailConfirmationFormInModel => {
		return new InitiateEmailConfirmationFormInModel(undefined);
	}

	public static createByEmail = (email: string): InitiateEmailConfirmationFormInModel => {
		// TODO: Guard.IsDefined(email);
		return new InitiateEmailConfirmationFormInModel(email);
	}

}
