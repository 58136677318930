import { Injector, Provider } from '@angular/core';
import { DoctorEncounterClient } from '@nmn-communication/doctor-encounters';
import { doctorEncounterClientFactory } from './factories/doctor-encounter.client.factory';

export const doctorEncounterProviders: Array<Provider> = [
	{
		provide: DoctorEncounterClient,
		useFactory: doctorEncounterClientFactory,
		deps: [Injector]
	}
];
