import { Provider } from '@angular/core';
import { GeneVariantQueryHandlerService } from '@nmn-domain/gene-variants';
import { GeneVariantQueryHandlerViaClientService } from './services/gene-variant.query-handler-via-client.service';

export const geneVariantProviders: Array<Provider> = [
	{
		provide: GeneVariantQueryHandlerService,
		useClass: GeneVariantQueryHandlerViaClientService
	}
];
