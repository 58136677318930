import { ClinicModel } from '../../../clinics/models/clinic.model';
import { DiseaseComboboxModel } from '../../../diseases/models/disease-combobox.model';
import { DoctorModel } from '../../../doctors/models/doctor.model';
import { DocumentDescribedComboboxModel, DocumentFormOutModel } from '../../../documents';
import { HealthIssueComboboxModel } from '../../../health-issues';
import { TakenMedicationDescribedComboboxModel, TakenMedicationFormOutModel } from '../../../taken-medications';
import { DoctorEncounterStatus } from '../doctor-encounter-status.enum';
import { DoctorEncounterModel } from '../doctor-encounter.model';

export class DoctorEncounterFormInModel {

	public readonly patientId: string;
	public readonly diseases: Array<DiseaseComboboxModel>;
	public readonly comment?: string;
	public readonly date?: Date;
	public readonly status?: DoctorEncounterStatus;
	public readonly doctor?: DoctorModel;
	public readonly clinic?: ClinicModel;
	public readonly healthIssue?: HealthIssueComboboxModel;
	public readonly takenMedications: Array<TakenMedicationDescribedComboboxModel>;
	public readonly newTakenMedications: Array<TakenMedicationFormOutModel>;
	public readonly documents: Array<DocumentDescribedComboboxModel>;
	public readonly newDocuments: Array<DocumentFormOutModel>;

	constructor(
		patientId: string,
		diseases: Array<DiseaseComboboxModel>,
		comment: string | undefined,
		date: Date | undefined,
		status: DoctorEncounterStatus | undefined,
		doctor: DoctorModel | undefined,
		clinic: ClinicModel | undefined,
		healthIssue: HealthIssueComboboxModel | undefined,
		takenMedications: Array<TakenMedicationDescribedComboboxModel>,
		newTakenMedications: Array<TakenMedicationFormOutModel>,
		documents: Array<DocumentDescribedComboboxModel>,
		newDocuments: Array<DocumentFormOutModel>
	) {
		// TODO: Guard check on defined
		this.patientId = patientId;
		this.diseases = diseases;
		this.comment = comment;
		this.date = date;
		this.status = status;
		this.doctor = doctor;
		this.clinic = clinic;
		this.healthIssue = healthIssue;
		this.takenMedications = takenMedications;
		this.newTakenMedications = newTakenMedications;
		this.documents = documents;
		this.newDocuments = newDocuments;
	}

	public static createEmpty = (patientId: string): DoctorEncounterFormInModel => {
		return new DoctorEncounterFormInModel(
			patientId,
			[],
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			[],
			[],
			[],
			[]
		);
	}

	public static createFromExistingModel(model: DoctorEncounterModel): DoctorEncounterFormInModel {
		return new DoctorEncounterFormInModel(
			model.patientId,
			model.diseases,
			model.comment,
			model.date,
			model.status,
			model.doctor,
			model.clinic,
			model.healthIssue,
			model.takenMedications,
			[],
			model.documents,
			[]
		);
	}

}
