<div mat-dialog-title>
	<span class="nmn-page-title">{{ dialogSettings.title | transloco }}</span>
</div>

<div mat-dialog-content fxLayout="row wrap">
	<div class="nmn-form">
		<div class="nmn-form-content-section">
			<span class="nmn-translated-text">{{ dialogSettings.message | transloco }}</span>
		</div>
	</div>
</div>

<div mat-dialog-actions fxLayout="row-reverse wrap">
	<button cdkFocusInitial mat-button color="primary" type="button" (click)="confirm()">{{ dialogSettings.confirmBtnTitle | transloco}}</button>
</div>