import { Injector } from '@angular/core';
import { BloodTypeClient } from '@nmn-communication/blood-types';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { BloodTypeFakeClientBuilder } from '../builders/blood-type.fake-client.builder';
import { BloodTypeFakeClientConfigurationBuilder } from '../builders/blood-type.fake-client.configuration.builder';
import { BloodTypeHttpClientBuilder } from '../builders/blood-type.http-client.builder';
import { BloodTypeHttpClientConfigurationBuilder } from '../builders/blood-type.http-client.configuration.builder';
import { BloodTypeFakeClient } from '../services/blood-type.fake-client';
import { BloodTypeHttpClient } from '../services/blood-type.http-client';

export const bloodTypeClientFactory = (injector: Injector): BloodTypeClient => {
	const useFake = environment?.api?.resources?.bloodTypes?.useFake ||
		!isValueDefined(environment?.api?.resources?.bloodTypes?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		bloodTypeFakeClientFactory(injector) :
		bloodTypeHttpClientFactory(injector);
};

export const bloodTypeFakeClientFactory = (
	injector: Injector
): BloodTypeFakeClient => {
	const configuration = new BloodTypeFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.bloodTypes.serverDelayMs)
		.build();

	return new BloodTypeFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const bloodTypeHttpClientFactory = (
	injector: Injector
): BloodTypeHttpClient => {
	const configuration = new BloodTypeHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.bloodTypes?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.bloodTypes.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.bloodTypes?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new BloodTypeHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
