import { Provider } from '@angular/core';
import { DocumentResolver, DocumentsCommandHandlerService, DocumentsQueryHandlerService } from '@nmn-domain/documents';
import { DocumentViaQueryHandlerResolver } from './resolvers/document.via-query-handler.resolver';
import { DocumentsCommandHandlerViaClientService } from './services/documents.command-handler-via-client.service';
import { DocumentsQueryHandlerViaClientService } from './services/documents.query-handler-via-client.service';

export const documentProviders: Array<Provider> = [
	{
		provide: DocumentsCommandHandlerService,
		useClass: DocumentsCommandHandlerViaClientService
	},
	{
		provide: DocumentsQueryHandlerService,
		useClass: DocumentsQueryHandlerViaClientService
	},
	{
		provide: DocumentResolver,
		useClass: DocumentViaQueryHandlerResolver
	}
];
