import { Injector } from '@angular/core';
import { GenderClient } from '@nmn-communication/genders';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { GenderFakeClientBuilder } from '../builders/gender.fake-client.builder';
import { GenderFakeClientConfigurationBuilder } from '../builders/gender.fake-client.configuration.builder';
import { GenderHttpClientBuilder } from '../builders/gender.http-client.builder';
import { GenderHttpClientConfigurationBuilder } from '../builders/gender.http-client.configuration.builder';
import { GenderFakeClient } from '../services/gender.fake-client';
import { GenderHttpClient } from '../services/gender.http-client';

export const genderClientFactory = (injector: Injector): GenderClient => {
	const useFake = environment?.api?.resources?.genders?.useFake ||
		!isValueDefined(environment?.api?.resources?.genders?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		genderFakeClientFactory(injector) :
		genderHttpClientFactory(injector);
};

export const genderFakeClientFactory = (
	injector: Injector
): GenderFakeClient => {
	const configuration = new GenderFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.genders.serverDelayMs)
		.build();

	return new GenderFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const genderHttpClientFactory = (
	injector: Injector
): GenderHttpClient => {
	const configuration = new GenderHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.genders?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.genders.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.genders?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new GenderHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};
