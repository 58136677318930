import { isValueDefined } from '@nmn-core/utils';

export enum TreatmentCheckerReportInteractionCriticalityType {

	Undefined = 'undefined',

	Low = 'low',
	High = 'high'

}

export const mapTreatmentCheckerReportInteractionCriticalityTypeFromString = (
	stringValue: string
): TreatmentCheckerReportInteractionCriticalityType => {
	const enumValue = Object
		.keys(TreatmentCheckerReportInteractionCriticalityType)
		.find(x => TreatmentCheckerReportInteractionCriticalityType[x] === stringValue);

	return isValueDefined(enumValue) ?
		stringValue as TreatmentCheckerReportInteractionCriticalityType :
		TreatmentCheckerReportInteractionCriticalityType.Undefined;
};
