import { Injectable } from '@angular/core';
import { ActivationEnd, NavigationEnd } from '@angular/router';
import { ApplicationTitleService } from '@nmn-background/application-title';
import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable()
export class GoogleAnalyticsConfigurationService {

	private segmentsStore = [];

	constructor(
		private readonly googleAnalyticsService: GoogleAnalyticsService,
		private readonly applicationTitleService: ApplicationTitleService
	) { }

	public applyPagePath(): void {
		this.updateConfig(undefined, this.applicationTitleService.currentApplicationTitleWithHiddenParameters);
	}

	public updateConfigByEvent(event: any): void {
		if (event instanceof NavigationEnd) {
			const path = this.segmentsStore
				.reverse()
				.flatMap(segments => segments)
				.filter(segment => isStringDefinedAndNotEmpty(segment))
				.map(item => !item.startsWith(':') ? item : `${item.substring(1)}`)
				.join('/');
			this.updateConfig(`/${path}`, this.applicationTitleService.currentApplicationTitleWithHiddenParameters);
			this.segmentsStore = [];
		}

		if (event instanceof ActivationEnd) {
			this.segmentsStore.push(event.snapshot.routeConfig.path.split('/'));
		}
	}

	private updateConfig(pagePath: string | undefined, pageTitle: string | undefined): void {
		const config = {};

		if (isStringDefinedAndNotEmpty(pagePath)) {
			config['page_path'] = pagePath;
		}

		if (isStringDefinedAndNotEmpty(pageTitle)) {
			config['page_title'] = pageTitle;
		}

		this.googleAnalyticsService.updateConfig(config);
	}

}
