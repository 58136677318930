import { ComboboxModel } from '@nmn-domain/shared';
import { TreatmentCheckerMedicationReportSideEffectFrequencyType } from './treatment-checker-report-medication-side-effect.frequency-type.enum';

export class TreatmentCheckerReportMedicationSideEffectComboboxModel extends ComboboxModel {

	public readonly frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType;

	constructor(
		id: string,
		displayText: string,
		frequency: TreatmentCheckerMedicationReportSideEffectFrequencyType
	) {
		super(id, displayText);

		// TODO: Guard check on defined
		this.frequency = frequency;
	}

}
