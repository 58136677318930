import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { DescribedComboboxModel } from '../../shared/comboboxes/described-combobox.model';

export class GeneComboboxModel extends DescribedComboboxModel {

	public readonly fullName?: string;

	public get isFullNameDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.fullName);
	}

	constructor(
		id: string,
		displayText: string,
		description: string,
		fullName: string | undefined
	) {
		super(id, displayText, description);

		this.fullName = fullName;
	}

}
