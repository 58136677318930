import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { PagedCollectionDto, PageOptionsDto } from '../../shared';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { PatientTreatmentCheckerAnalyzeParameterDto } from '../models/patient-treatment-checker-analyze.parameter.dto';
import { TreatmentCheckerAnalyzeParameterDto } from '../models/treatment-checker-analyze.parameter.dto';
import { TreatmentCheckerReportDeleteBulkParameterDto } from '../models/treatment-checker-report-delete-bulk-parameter.dto';
import { TreatmentCheckerReportFilterDto } from '../models/treatment-checker-report-filter.dto';
import { TreatmentCheckerReportFindParameterDto } from '../models/treatment-checker-report-find-parameter.dto';
import { TreatmentCheckerReportShortDto } from '../models/treatment-checker-report-short.dto';
import { TreatmentCheckerReportDto } from '../models/treatment-checker-report.dto';

export abstract class TreatmentCheckerClient {

	public abstract checkTreatmentForPatient(
		parameter: PatientTreatmentCheckerAnalyzeParameterDto
	): Observable<Result<string, Failure>>;

	public abstract checkTreatmentForAnonymous(
		parameter: TreatmentCheckerAnalyzeParameterDto
	): Observable<Result<string, Failure>>;

	public abstract getReportsPage(
		parameter: PageOptionsDto<TreatmentCheckerReportFilterDto>
	): Observable<Result<PagedCollectionDto<TreatmentCheckerReportShortDto, TreatmentCheckerReportFilterDto>, Failure>>;

	public abstract getReport(
		parameter: TreatmentCheckerReportFindParameterDto
	): Observable<Result<TreatmentCheckerReportDto, Failure>>;

	public abstract delete(
		parameter: TreatmentCheckerReportFindParameterDto
	): Observable<EmptyResult<Failure>>;

	public abstract deleteBulk(
		parameter: TreatmentCheckerReportDeleteBulkParameterDto
	): Observable<EmptyResult<Failure>>;

}
