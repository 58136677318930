import { RegionComboboxDto, RegionFilterDto } from '@nmn-communication/regions';
import { RegionComboboxModel, RegionFilterModel } from '@nmn-domain/regions';
import { mapPopulationComboboxDtoToModel } from '../../populations/factories/population.factory';
import { mapComboboxDtoToComboboxModel } from '../../shared/factories/combobox.factory';

export const mapRegionFilterModelToDto = (model: RegionFilterModel): RegionFilterDto => (
	{
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern
	}
);

export const mapRegionFilterDtoToModel = (dto: RegionFilterDto): RegionFilterModel => (
	new RegionFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern
	)
);

export const mapRegionComboboxDtoToModel = (dto: RegionComboboxDto): RegionComboboxModel => (
	new RegionComboboxModel(
		dto.id,
		dto.displayText,
		mapComboboxDtoToComboboxModel(dto.regionGroup),
		mapPopulationComboboxDtoToModel(dto.defaultPopulation)
	)
);
