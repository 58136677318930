import { Provider } from '@angular/core';
import { CookieStorageRepositoryService } from './repositories/cookie-storage.repository.service';
import { LocalStorageRepositoryService } from './repositories/local-storage.repository.service';
import { SessionStorageRepositoryService } from './repositories/session-storage.repository.service';
import { CookieStorageService } from './services/cookie-storage.service';
import { LocalStorageService } from './services/local-storage.service';
import { SessionStorageService } from './services/session-storage.service';

export const applicationStoragesProviders: Array<Provider> = [
	CookieStorageRepositoryService,
	CookieStorageService,
	LocalStorageRepositoryService,
	LocalStorageService,
	SessionStorageRepositoryService,
	SessionStorageService
];
