export class GeneticTestGetQuery {

	public id: string;
	public patientId: string;

	private constructor(
		id: string,
		patientId: string
	) {

		// TODO: Guard add defined check

		this.id = id;
		this.patientId = patientId;
	}

	public static createId(id: string, patientId: string): GeneticTestGetQuery {
		return new GeneticTestGetQuery(id, patientId);
	}

}
