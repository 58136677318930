import { ServerConfigurationDto } from '@nmn-communication/server-configuration';
import {
	documentUploadAllowedExtensionsDefault, documentUploadMaxSizeDefault, fileUploadMaxSizeDefault, fileUploadRestrictedExtensionsDefault,
	geneticFileUploadAllowedExtensionsDefault, geneticFileUploadMaxSizeDefault, imageUploadAllowedExtensionsDefault, imageUploadFileExtensionDefault,
	imageUploadIageQualityDefault, imageUploadMaxSizeDefault, storageCapacityDefault
} from '@nmn-domain/server-configuration';
import { FakeDatabase } from '../databases/fake.database';

export class ServerConfigurationFakeTable {

	private readonly database: FakeDatabase;

	constructor(
		database: FakeDatabase
	) {
		this.database = database;
	}

	public get(): ServerConfigurationDto {
		return {
			serverVersion: '0.0.0',
			serverEnvironment: 'DEVELOPMENT',
			businessRestrictions:
			{
				accountRestrictions: {
					passwordRestrictions: {
						requiredLength: 7,
						requiredUniqueChars: 1,
						requireNonAlphanumeric: true,
						requireLowercase: true,
						requireUppercase: true,
						requireDigit: true
					}
				},
				fileUploadRestrictions: {
					fileMaxSize: fileUploadMaxSizeDefault,
					restrictedExtensions: fileUploadRestrictedExtensionsDefault,
					storageCapacity: storageCapacityDefault,
					imageUploadConfiguration: {
						allowedExtensions: imageUploadAllowedExtensionsDefault,
						maxSize: imageUploadMaxSizeDefault,
						fileExtension: imageUploadFileExtensionDefault,
						imageQuality: imageUploadIageQualityDefault
					},
					documentUploadConfiguration: {
						allowedExtensions: documentUploadAllowedExtensionsDefault,
						maxSize: documentUploadMaxSizeDefault
					},
					geneticFileUploadConfiguration: {
						allowedExtensions: geneticFileUploadAllowedExtensionsDefault,
						maxSize: geneticFileUploadMaxSizeDefault
					}
				}
			},
			googleServices: {
				googleOAuthClientId: '963057069321-gkmjoaaqeaqgjaja2jrje475db4dtl8u.apps.googleusercontent.com'
			},
			facebookServices: {
				facebookAuthClientId: undefined,
				facebookAuthSdkNonce: undefined
			},
			storageServices: {
				storage: 'test-storage'
			}
		};
	}

}
