import { Provider } from '@angular/core';
import { MedicationBodySiteQueryHandlerService, MedicationBodySitesResolver } from '@nmn-domain/medication-body-sites';
import { MedicationBodySiteQueryHandlerViaClientService, MedicationBodySitesViaQueryHandlerResolver } from './index';

export const medicationBodySiteProviders: Array<Provider> = [
	{
		provide: MedicationBodySiteQueryHandlerService,
		useClass: MedicationBodySiteQueryHandlerViaClientService
	},
	{
		provide: MedicationBodySitesResolver,
		useClass: MedicationBodySitesViaQueryHandlerResolver
	}
];
