import { isArrayDefinedAndNotEmpty } from '@nmn-core/utils';
import { DiseaseComboboxModel } from '@nmn-domain/diseases';
import { MedicationComboboxModel } from '@nmn-domain/medications';
import { PatientModel } from '@nmn-domain/patients/models/patient.model';

export class PatientTreatmentCheckerFormInModel {

	public readonly patientId: string;
	public readonly diseases: Array<DiseaseComboboxModel>;
	public readonly medications: Array<MedicationComboboxModel>;
	public readonly seriousPathologies: Array<DiseaseComboboxModel>;

	private constructor(
		patientId: string,
		diseases: Array<DiseaseComboboxModel>,
		medications: Array<MedicationComboboxModel>,
		seriousPathologies: Array<DiseaseComboboxModel>,
	) {
		this.patientId = patientId;
		this.diseases = diseases;
		this.medications = medications;
		this.seriousPathologies = seriousPathologies;
	}

	public static createByPatient = (patient: PatientModel): PatientTreatmentCheckerFormInModel => {
		return new PatientTreatmentCheckerFormInModel(patient.id, [], [], patient.seriousPathologies);
	}

	public static createForPredefinedData = (
		patientId: string,
		diseases: Array<DiseaseComboboxModel>,
		medications: Array<MedicationComboboxModel>
	): PatientTreatmentCheckerFormInModel => {
		return new PatientTreatmentCheckerFormInModel(patientId, diseases, medications, []);
	}

	public get keyDataIsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.medications) || isArrayDefinedAndNotEmpty(this.diseases);
	}

}
