import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ComboboxModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';

export abstract class LanguagesResolver implements Resolve<Array<ComboboxModel>> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	): Observable<Array<ComboboxModel>> | Array<ComboboxModel>;

}
