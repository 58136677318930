import { DescribedComboboxModel } from '../../shared/comboboxes/described-combobox.model';
import { DocumentIconType } from './document-icon-type.enum';

export class DocumentTypeDescribedComboboxModel extends DescribedComboboxModel {

	public readonly iconCode: DocumentIconType;

	constructor(
		id: string,
		displayText: string,
		description: string,
		iconCode: DocumentIconType
	) {

		super(id, displayText, description);

		// TODO: Guard check on defined
		this.iconCode = iconCode;

	}

}
