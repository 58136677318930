import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { ComboboxModel } from '../../shared/comboboxes/combobox.model';

export class LanguageFilterModel {

	public readonly ids?: Array<string>;
	public readonly ignoreIds?: Array<string>;
	public readonly searchPattern?: string;

	constructor(
		ids: Array<string> | undefined,
		ignoreIds: Array<string> | undefined,
		searchPattern: string | undefined
	) {
		this.ids = ids;
		this.ignoreIds = ignoreIds;
		this.searchPattern = searchPattern;
	}

	public static createEmpty(): LanguageFilterModel {
		return new LanguageFilterModel(undefined, undefined, undefined);
	}

	public static createForMultiAutocomplete(
		searchPattern: string,
		ignoreIds: Array<string>
	): LanguageFilterModel {
		return new LanguageFilterModel(undefined, ignoreIds, searchPattern);
	}

	public static createForAutocomplete(
		searchPattern: string,
		selectedItem: ComboboxModel
	): LanguageFilterModel {
		return new LanguageFilterModel(
			undefined,
			isStringDefinedAndNotEmpty(selectedItem?.id) ? [selectedItem.id] : undefined,
			searchPattern);
	}

}
