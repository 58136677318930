export enum PricingPlanType {

	// For premium
	Annual = 'Annual',
	SemiAnnual = 'SemiAnnual',
	Monthly = 'Monthly',

	// For corporate
	Custom = 'Custom'

}
