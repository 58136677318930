import { isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { GeneComboboxModel } from '@nmn-domain/genes';
import { GeneticTestCreationTypeEnum } from './genetic-test-creation-type.enum';
import { GeneticTestResultModel } from './genetic-test-result.model';

export class GeneticTestModel {

	public readonly id: string;
	public readonly patientId: string;
	public readonly date: Date;
	public readonly gene: GeneComboboxModel;
	public readonly geneticTestResults: Array<GeneticTestResultModel>;
	public readonly comment: string | undefined;
	public readonly certificateIssuer: string | undefined;
	public readonly certificateUid: string | undefined;
	public readonly createdOn: Date;
	public readonly updatedOn: Date | undefined;
	public readonly geneticTestCreationType: GeneticTestCreationTypeEnum;

	public get isCreatedByImport(): boolean {
		return isValueDefined(this.geneticTestCreationType) &&
			this.geneticTestCreationType === GeneticTestCreationTypeEnum.GeneticFile;
	}

	public get year(): number {
		return this.date.getFullYear();
	}

	public get isCommentDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.comment);
	}

	public get isCertificateDefined(): boolean {
		return this.isCertificateIssuerDefined || this.isCertificateUidDefined;
	}

	public get isCertificateIssuerDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.certificateIssuer);
	}

	public get isCertificateUidDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.certificateUid);
	}

	public get lastModifiedOn(): Date {
		return isValueDefined(this.updatedOn) ? this.updatedOn : this.createdOn;
	}

	constructor(
		id: string,
		patientId: string,
		date: Date,
		gene: GeneComboboxModel,
		geneticTestResults: Array<GeneticTestResultModel>,
		comment: string | undefined,
		certificateIssuer: string | undefined,
		certificateUid: string | undefined,
		createdOn: Date,
		updatedOn: Date | undefined,
		geneticTestCreationType: GeneticTestCreationTypeEnum
	) {
		// TODO: Guard check on defined

		this.id = id;
		this.patientId = patientId;
		this.date = date;
		this.gene = gene;
		this.geneticTestResults = geneticTestResults.sort((item1, item2) => (item1.variantDisplayText < item2.variantDisplayText ? -1 : 1));
		this.comment = comment;
		this.certificateIssuer = certificateIssuer;
		this.certificateUid = certificateUid;
		this.createdOn = createdOn;
		this.updatedOn = updatedOn;
		this.geneticTestCreationType = geneticTestCreationType;
	}

}
