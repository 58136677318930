import { DefaultCookiePath } from '../constants';
import { RemoveCookieBehaviorSettingsModel } from '../models/cookie-behavior-settings.model';

export class RemoveCookieBehaviorSettingsBuilder {

	private path: string | undefined;
	private domain: string | undefined;
	private secure: boolean | undefined;
	private sameSite: 'Lax' | 'None' | 'Strict' | undefined;

	constructor() {
		this.reset();
	}

	public reset(): RemoveCookieBehaviorSettingsBuilder {
		return this
			.withPathDefault()
			.withDomainSameOnly()
			.withModeSecure()
			.allowUsageForTheSameSiteAndForTheSafeMethodsOnly();
	}

	public build(): RemoveCookieBehaviorSettingsModel {
		return new RemoveCookieBehaviorSettingsModel(this.path, this.domain, this.secure, this.sameSite);
	}

	public withPath(path: string): RemoveCookieBehaviorSettingsBuilder {
		this.path = path;

		return this;
	}

	public withPathDefault(): RemoveCookieBehaviorSettingsBuilder {
		this.path = DefaultCookiePath;

		return this;
	}

	public withDomain(domain: string): RemoveCookieBehaviorSettingsBuilder {
		this.domain = domain;

		return this;
	}

	public withDomainSameOnly(): RemoveCookieBehaviorSettingsBuilder {
		this.domain = undefined;

		return this;
	}

	public withModeSecure(): RemoveCookieBehaviorSettingsBuilder {
		this.secure = true;

		return this;
	}

	public withModeUnsecure(): RemoveCookieBehaviorSettingsBuilder {
		this.secure = false;

		return this;
	}

	public allowUsageOnlyForTheSameSite(): RemoveCookieBehaviorSettingsBuilder {
		this.sameSite = 'Strict';

		return this;
	}

	public allowUsageForTheSameSiteAndForTheSafeMethodsOnly(): RemoveCookieBehaviorSettingsBuilder {
		this.sameSite = 'Lax';

		return this;
	}

	public allowUsageAll(): RemoveCookieBehaviorSettingsBuilder {
		this.sameSite = 'None';

		return this;
	}

}
