import { Injector, Provider } from '@angular/core';
import { FileUploaderClient } from '@nmn-communication/file-uploader';
import { fileUploaderClientFactory } from './factories/file-uploader-client.factory';

export const fileUploaderProviders: Array<Provider> = [
	{
		provide: FileUploaderClient,
		useFactory: fileUploaderClientFactory,
		deps: [Injector]
	}
];
