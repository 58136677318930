import { TranslocoService } from '@ngneat/transloco';
import { MedicationComboboxDto } from '@nmn-communication/medications';
import { DescribedComboboxDto } from '@nmn-communication/shared';
import { isValueDefined } from '@nmn-core/utils';
import { setTranslation } from '../../utils/localize';
import { FakeDescribedComboboxDto } from './fake-described-combobox.dto';

export interface FakeMedicationComboboxDto extends FakeDescribedComboboxDto<string> {

	substances: Array<DescribedComboboxDto<string>>;
	type: string;

}

export const mapFakeMedicationComboboxDtoToComboboxDto = (
	translocoService: TranslocoService,
	fakeDescribedComboboxDto: FakeMedicationComboboxDto
): MedicationComboboxDto => {
	if (!isValueDefined(fakeDescribedComboboxDto)) {
		return undefined;
	}

	return {
		id: fakeDescribedComboboxDto.id,
		displayText: setTranslation(translocoService, fakeDescribedComboboxDto.displayText),
		description: setTranslation(translocoService, fakeDescribedComboboxDto.description),
		substances: fakeDescribedComboboxDto.substances,
		type: fakeDescribedComboboxDto.type
	};
};
