export class VaccinationUpdateCommand {

	public readonly id: string;
	public readonly patientId: string;
	public readonly vaccinationDate: Date;
	public readonly vaccineId: string;
	public readonly comment: string | undefined;
	public readonly numberInSeriesOfDoses: number;
	public readonly memberStateOfVaccination: string | undefined;
	public readonly certificateIssuer: string | undefined;
	public readonly certificateUid: string | undefined;
	public readonly expirationDate: Date | undefined;

	constructor(
		id: string,
		patientId: string,
		vaccinationDate: Date,
		vaccineId: string,
		comment: string | undefined,
		numberInSeriesOfDoses: number,
		memberStateOfVaccination: string | undefined,
		certificateIssuer: string | undefined,
		certificateUid: string | undefined,
		expirationDate: Date | undefined
	) {
		//TODO: Guard check on defined

		this.id = id;
		this.patientId = patientId;
		this.vaccinationDate = vaccinationDate;
		this.vaccineId = vaccineId;
		this.comment = comment;
		this.numberInSeriesOfDoses = numberInSeriesOfDoses;
		this.memberStateOfVaccination = memberStateOfVaccination;
		this.certificateIssuer = certificateIssuer;
		this.certificateUid = certificateUid;
		this.expirationDate = expirationDate;
	}

}
