import { TranslocoService } from '@ngneat/transloco';
import { Failure } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { isValueDefined } from '@nmn-core/utils';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { PagedCollectionDto, PageOptionsDto } from '../../core/clients';

export const getPagedCollection = <TItem, TDto, TFilter>(
	translocoService: TranslocoService,
	data: Array<TItem>,
	pageOptions: PageOptionsDto<TFilter>,
	filterPredicateForItem: (item: TItem, filter: TFilter, translocoService: TranslocoService) => boolean,
	mapToDto: (item: TItem) => TDto,
	filterPredicateForDto: (item: TDto, filter: TFilter) => boolean,
	compareFn: (item1: TDto, item2: TDto, sortning: string) => number
): PagedCollectionDto<TDto, TFilter> => {

	const pageSize = isValueDefined(pageOptions.pageSize) ? pageOptions.pageSize : 50;
	const indexFrom = isValueDefined(pageOptions.indexFrom) ? pageOptions.indexFrom : 0;
	const pageIndex = isValueDefined(pageOptions.pageIndex) ? pageOptions.pageIndex : indexFrom;

	const sortedFilteredItems = data
		.filter((item: TItem) => filterPredicateForItem(item, pageOptions.filter, translocoService))
		.map(mapToDto)
		.filter((item: TDto) => filterPredicateForDto(item, pageOptions.filter))
		.sort((item1: TDto, item2: TDto) => compareFn(item1, item2, pageOptions.sorting));
	const totalCount = sortedFilteredItems.length;
	const totalPages = (totalCount % pageSize === 0 ? 0 : 1);
	const absolutePageIndex = calculateAbsolutePageIndex(indexFrom, pageIndex, pageSize, totalCount);
	const normalizedPageIndex = absolutePageIndex + indexFrom;
	const hasNextPage = absolutePageIndex + 1 < totalPages;
	const hasPreviousPage = absolutePageIndex > 0;
	const pageOfItems = sortedFilteredItems
		.filter((_, index) => index >= absolutePageIndex * pageSize && index < (absolutePageIndex + 1) * pageSize);

	return {
		filter: pageOptions.filter,
		sorting: pageOptions.sorting,
		indexFrom,
		pageIndex: normalizedPageIndex,
		pageSize,
		items: pageOfItems,
		totalCount,
		totalPages,
		hasNextPage,
		hasPreviousPage,
		nextPage: hasNextPage ? (indexFrom < normalizedPageIndex ? normalizedPageIndex - 1 : indexFrom) : undefined,
		previousPage: hasPreviousPage ? (indexFrom < normalizedPageIndex ? normalizedPageIndex - 1 : indexFrom) : undefined
	};
};

export const getPagedCollectionWithoutFilter = <TItem, TDto, TFilter>(
	translocoService: TranslocoService,
	data: Array<TItem>,
	pageOptions: PageOptionsDto<TFilter>,
	mapToDto: (item: TItem) => TDto,
	compareFn: (item1: TDto, item2: TDto, sortning: string) => number
): PagedCollectionDto<TDto, TFilter> =>
	getPagedCollection(
		translocoService,
		data,
		pageOptions,
		(_: TItem, __: TFilter) => true,
		mapToDto,
		(_: TDto, __: TFilter) => true,
		compareFn
	);

export const getPagedCollectionWithoutItemFilter = <TItem, TDto, TFilter>(
	translocoService: TranslocoService,
	data: Array<TItem>,
	pageOptions: PageOptionsDto<TFilter>,
	mapToDto: (item: TItem) => TDto,
	filterPredicateForDto: (item: TDto, filter: TFilter) => boolean,
	compareFn: (item1: TDto, item2: TDto, sortning: string) => number
): PagedCollectionDto<TDto, TFilter> =>
	getPagedCollection(
		translocoService,
		data,
		pageOptions,
		(_: TItem, __: TFilter) => true,
		mapToDto,
		filterPredicateForDto,
		compareFn
	);

export const getPagedCollectionWithoutDtoFilter = <TItem, TDto, TFilter>(
	translocoService: TranslocoService,
	data: Array<TItem>,
	pageOptions: PageOptionsDto<TFilter>,
	filterPredicateForItem: (item: TItem, filter: TFilter, translocoService: TranslocoService) => boolean,
	mapToDto: (item: TItem) => TDto,
	compareFn: (item1: TDto, item2: TDto, sortning: string) => number
): PagedCollectionDto<TDto, TFilter> =>
	getPagedCollection(
		translocoService,
		data,
		pageOptions,
		filterPredicateForItem,
		mapToDto,
		(_: TDto, __: TFilter) => true,
		compareFn
	);

const calculateAbsolutePageIndex = (
	indexFrom: number,
	pageIndex: number,
	pageSize: number,
	totalCount: number
): number => {
	const totalPages = Math.floor(totalCount / pageSize) + (totalCount % pageSize === 0 ? 0 : 1);

	return totalPages > 0 ?
		Math.abs(pageIndex - indexFrom + totalPages) % totalPages :
		0;
};

export const wrapFuncCallAsFakeClientResponse = <TSuccess>(
	func: () => TSuccess,
	serverDelayMs?: { min: number, max: number }
): Observable<Result<TSuccess, Failure>> =>
	wrapObjectAsFakeClientResponse(wrapFuncCallAsResult(func), serverDelayMs);

export const wrapActionCallAsFakeClientResponse = (
	action: () => void,
	serverDelayMs?: { min: number, max: number }
): Observable<EmptyResult<Failure>> =>
	wrapObjectAsFakeClientResponse(wrapActionCallAsEmptyResult(action), serverDelayMs);

export const wrapFuncCallAsResult = <TSuccess>(func: () => TSuccess): Result<TSuccess, Failure> => {
	let result: Result<TSuccess, Failure>;

	try {
		result = Result.success<TSuccess, Failure>(func());
	} catch (error) {
		return Result.failure<TSuccess, Failure>(Failure.createUndefined(error));
	}

	return result;
};

export const wrapActionCallAsEmptyResult = (action: () => void): EmptyResult<Failure> => {
	let result: EmptyResult<Failure>;

	try {
		action();
		result = EmptyResult.success<Failure>();
	} catch (error) {
		return EmptyResult.failure<Failure>(Failure.createUndefined(error));
	}

	return result;
};

export const wrapObjectAsFakeClientResponse = <T>(
	obj: T,
	serverDelayMs?: { min: number, max: number }
): Observable<T> => {
	const delayMs = isValueDefined(serverDelayMs) &&
		isValueDefined(serverDelayMs.min) &&
		isValueDefined(serverDelayMs.max) ?
		Math.floor(Math.random() * (serverDelayMs.max - serverDelayMs.min + 1)) + serverDelayMs.min :
		0;

	return of(obj)
		.pipe(delay(delayMs));
};
