import { Provider } from '@angular/core';
import { RegionQueryHandlerService, RegionsResolver } from '@nmn-domain/regions';
import { RegionsViaQueryHandlerResolver } from './resolvers/regions.via-query-handler.resolver';
import { RegionQueryHandlerViaClientService } from './services/region.query-handler-via-client.service';

export const regionProviders: Array<Provider> = [
	{
		provide: RegionQueryHandlerService,
		useClass: RegionQueryHandlerViaClientService
	},
	{
		provide: RegionsResolver,
		useClass: RegionsViaQueryHandlerResolver
	}
];
