import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DocumentTypeClient, DocumentTypeDescribedComboboxDto, DocumentTypeFilterDto } from '@nmn-communication/document-types';
import { Failure } from '@nmn-communication/shared';
import { Result } from '@nmn-core/shared';
import { constructHttpParams } from '@nmn-core/utils';
import { Observable } from 'rxjs';
import { createFailedResultFromErrorResponse, createResultFromResponse } from '../../../../modules/shared/error-handling/utils/http-client-default-handler.utils';
import { DocumentTypeHttpClientConfiguration } from '../configurations/document-type.http-client.configuration';

export class DocumentTypeHttpClient extends DocumentTypeClient {

	constructor(
		private readonly configuration: DocumentTypeHttpClientConfiguration,
		private readonly httpClient: HttpClient
	) {
		super();
	}

	public getBulkAsComboboxes(
		filter: DocumentTypeFilterDto
	): Observable<Result<Array<DocumentTypeDescribedComboboxDto>, Failure>> {
		const url = `${this.configuration.baseUrl}/${this.configuration.resourceRelativePath}`;
		const params = constructHttpParams(filter);
		const headers = new HttpHeaders()
			.set('api-version', this.configuration.resourceTypeVersion);

		return this.httpClient
			.get<Array<DocumentTypeDescribedComboboxDto>>(url, { params, headers })
			.pipe(
				createResultFromResponse(),
				createFailedResultFromErrorResponse()
			);
	}

}
