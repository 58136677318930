import { Injector, Provider } from '@angular/core';
import { PopulationClient } from '@nmn-communication/populations';
import { populationClientFactory } from './factories/population.client.factory';

export const populationProviders: Array<Provider> = [
	{
		provide: PopulationClient,
		useFactory: populationClientFactory,
		deps: [Injector]
	}
];
