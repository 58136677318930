import { PagedCollectionDto } from '@nmn-communication/shared';
import {
	TakenMedicationCreateParameterDto, TakenMedicationDto, TakenMedicationFailCourseParameterDto, TakenMedicationFilterDto,
	TakenMedicationFinishCourseBulkParameterDto, TakenMedicationFinishCourseParameterDto, TakenMedicationUpdateParameterDto
} from '@nmn-communication/taken-medications';
import { isValueDefined, mapDateInServerFormatToDate, mapDateToServerFormat } from '@nmn-core/utils';
import { PagedCollectionModel } from '@nmn-domain/shared';
import {
	TakenMedicationCreateCommand, TakenMedicationFailCourseCommand, TakenMedicationFilterModel, TakenMedicationFinishCourseBulkCommand,
	TakenMedicationFinishCourseCommand, TakenMedicationModel, TakenMedicationUpdateCommand
} from '@nmn-domain/taken-medications';
import { mapDoctorEncounterComboboxDtoToModel } from '../../doctor-encounters/factories/doctor-encounter-combobox.factory';
import { mapCreateCommandToParameter as mapDocumentCreateCommandToParameter, mapDocumentDescribedComboboxDtoToModel } from '../../documents/factories/document.factory';
import { mapHealthIssueComboboxDtoToModel } from '../../health-issues/factories/health-issue-combobox.factory';
import { mapMedicationAdmRouteDescribedComboboxDtoToModel } from '../../medication-adm-routes';
import { mapMedicationAdmStatusDescribedComboboxDtoToModel } from '../../medication-adm-statuses';
import { mapMedicationBodySiteDescribedComboboxDtoToModel } from '../../medication-body-sites';
import { mapMedicationComboboxDtoToModel } from '../../medications/factories/medication.factory';
import { mapDescribedComboboxDtoToDescribedComboboxModel } from '../../shared/factories/combobox.factory';
import { mapPagedCollectionDtoToModel } from '../../shared/factories/pagination.factory';
import { mapTypeOfMedicationComboboxDtoToModel } from '../../types-of-medication/factories/type-of-medication.factory';

export const mapDtoToModel = (dto: TakenMedicationDto): TakenMedicationModel => (
	new TakenMedicationModel(
		dto.id,
		mapDateInServerFormatToDate(dto.from),
		mapDateInServerFormatToDate(dto.to),
		dto.comment,
		dto.dosageValue,
		dto.rateValue,
		dto.patientId,
		isValueDefined(dto.encounter) ? mapDoctorEncounterComboboxDtoToModel(dto.encounter) : undefined,
		isValueDefined(dto.healthIssue) ? mapHealthIssueComboboxDtoToModel(dto.healthIssue) : undefined,
		mapMedicationComboboxDtoToModel(dto.medication),
		isValueDefined(dto.medicationAdmRoute) ? mapMedicationAdmRouteDescribedComboboxDtoToModel(dto.medicationAdmRoute) : undefined,
		mapMedicationAdmStatusDescribedComboboxDtoToModel(dto.medicationAdmStatus),
		isValueDefined(dto.medicationDosage) ? mapDescribedComboboxDtoToDescribedComboboxModel(dto.medicationDosage) : undefined,
		isValueDefined(dto.medicationBodySite) ? mapMedicationBodySiteDescribedComboboxDtoToModel(dto.medicationBodySite) : undefined,
		isValueDefined(dto.medicationRateQuantityType) ? mapDescribedComboboxDtoToDescribedComboboxModel(dto.medicationRateQuantityType) : undefined,
		dto.isIncompatibleWithAlcohol,
		dto.isEfficacyUnproven,
		dto.isIncompatibleWithOtherTakenMedication,
		dto.incompatibleTakenMedications,
		dto.documents?.map(mapDocumentDescribedComboboxDtoToModel) || [],
		mapTypeOfMedicationComboboxDtoToModel(dto.typeOfMedication)
	)
);

export const mapFilterModelToDto = (model: TakenMedicationFilterModel): TakenMedicationFilterDto => (
	{
		...model
	}
);

export const mapFilterDtoToModel = (dto: TakenMedicationFilterDto): TakenMedicationFilterModel => (
	new TakenMedicationFilterModel(
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern,
		dto.patientIds,
		dto.onlyDetachedOrAttachedToHealthIssueId,
		dto.isIncompatibleWithOtherTakenMedication,
		dto.isEfficacyUnproven,
		dto.isIncompatibleWithAlcohol)
);

export const mapTakenMedicationPagedCollectionDtoToModel = (
	pagedCollectionDto: PagedCollectionDto<TakenMedicationDto, TakenMedicationFilterDto>
): PagedCollectionModel<TakenMedicationModel, TakenMedicationFilterModel> =>
	mapPagedCollectionDtoToModel(pagedCollectionDto, mapDtoToModel, mapFilterDtoToModel);

export const mapCreateCommandToCreateParameter = (
	command: TakenMedicationCreateCommand
): TakenMedicationCreateParameterDto => (
	{
		from: mapDateToServerFormat(command.from),
		to: mapDateToServerFormat(command.to),
		comment: command.comment,
		rateValue: command.rateValue,
		dosageValue: command.dosageValue,
		patientId: command.patientId,
		medicationId: command.medicationId,
		encounterId: command.encounterId,
		healthIssueId: command.healthIssueId,
		medicationAdmStatusId: command.medicationAdmStatusId,
		medicationDosageId: command.medicationDosageId,
		medicationAdmRouteId: command.medicationAdmRouteId,
		medicationBodySiteId: command.medicationBodySiteId,
		medicationRateQuantityTypeId: command.medicationRateQuantityTypeId,
		documentIds: command.documentIds,
		documents: command.documents.map(mapDocumentCreateCommandToParameter),
		typeOfMedicationId: command.typeOfMedicationId
	}
);

export const mapUpdateCommandToUpdateParameter = (
	command: TakenMedicationUpdateCommand
): TakenMedicationUpdateParameterDto => (
	{
		id: command.id,
		from: mapDateToServerFormat(command.from),
		to: mapDateToServerFormat(command.to),
		comment: command.comment,
		dosageValue: command.dosageValue,
		rateValue: command.rateValue,
		patientId: command.patientId,
		medicationId: command.medicationId,
		encounterId: command.encounterId,
		healthIssueId: command.healthIssueId,
		medicationAdmStatusId: command.medicationAdmStatusId,
		medicationDosageId: command.medicationDosageId,
		medicationAdmRouteId: command.medicationAdmRouteId,
		medicationBodySiteId: command.medicationBodySiteId,
		medicationRateQuantityTypeId: command.medicationRateQuantityTypeId,
		documentIds: command.documentIds,
		documents: command.documents.map(mapDocumentCreateCommandToParameter),
		typeOfMedicationId: command.typeOfMedicationId
	}
);

export const mapFinishCourseCommandToFinishCourseParameter = (
	command: TakenMedicationFinishCourseCommand
): TakenMedicationFinishCourseParameterDto => (
	{
		id: command.id,
		patientId: command.patientId
	}
);

export const mapFinishCourseBulkCommandToFinishCourseBulkParameter = (
	command: TakenMedicationFinishCourseBulkCommand
): TakenMedicationFinishCourseBulkParameterDto => (
	{
		patientId: command.patientId,
		candidates: command.candidates
	}
);

export const mapFailCourseCommandToFailCourseParameter = (
	command: TakenMedicationFailCourseCommand
): TakenMedicationFailCourseParameterDto => (
	{
		id: command.id,
		patientId: command.patientId
	}
);
