import { isValueDefined } from '@nmn-core/utils';

export enum TreatmentCheckerMedicationReportSideEffectFrequencyType {

	Undefined = 'undefined',

	VeryRare = 'veryRare', // <= 0.1%
	Rare = 'rare', // <= 1 %
	Uncommon = 'uncommon', // <= 10 %
	Common = 'common', // <= 25 %
	VeryCommon = 'veryCommon', // <= 50 %
	Often = 'often', // <= 75 %
	AlmostAlways = 'almostAlways' // <= 100 %

}

export const mapTreatmentCheckerMedicationReportSideEffectFrequencyTypeFromString = (
	stringValue: string
): TreatmentCheckerMedicationReportSideEffectFrequencyType => {
	const enumValue = Object
		.keys(TreatmentCheckerMedicationReportSideEffectFrequencyType)
		.find(x => TreatmentCheckerMedicationReportSideEffectFrequencyType[x] === stringValue);

	return isValueDefined(enumValue) ?
		stringValue as TreatmentCheckerMedicationReportSideEffectFrequencyType :
		TreatmentCheckerMedicationReportSideEffectFrequencyType.Undefined;
};
