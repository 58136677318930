export class PatientContactsFormOutModel {

	public readonly familyDoctorFullName: string;
	public readonly familyDoctorContacts: string;
	public readonly emergencyContactFullName: string;
	public readonly emergencyContactData: string;

	public constructor(
		familyDoctorFullName: string | undefined,
		familyDoctorContacts: string | undefined,
		emergencyContactFullName: string | undefined,
		emergencyContactData: string | undefined
	) {
		this.familyDoctorFullName = familyDoctorFullName;
		this.familyDoctorContacts = familyDoctorContacts;
		this.emergencyContactFullName = emergencyContactFullName;
		this.emergencyContactData = emergencyContactData;
	}

}
