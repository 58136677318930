import { DoctorEncounterComboboxDto } from '@nmn-communication/doctor-encounters';
import { mapDateInServerFormatToDate } from '@nmn-core/utils';
import { DoctorEncounterComboboxModel, mapDoctorEncounterStatusFromString } from '@nmn-domain/doctor-encounters';
import { mapDiseaseComboboxDtoToModel } from '../../diseases/factories/disease.factory';

export const mapDoctorEncounterComboboxDtoToModel = (dto: DoctorEncounterComboboxDto): DoctorEncounterComboboxModel => (
	new DoctorEncounterComboboxModel(
		dto.id,
		dto.displayText,
		dto.description,
		dto.diseases.map(mapDiseaseComboboxDtoToModel),
		mapDateInServerFormatToDate(dto.date),
		mapDoctorEncounterStatusFromString(dto.status)
	)
);
