import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { GeneHttpClientConfiguration } from '../configurations/gene.http-client.configuration';

export class GeneHttpClientConfigurationBuilder extends HttpClientConfigurationBuilder<GeneHttpClientConfiguration> {

	public build(): GeneHttpClientConfiguration {
		return new GeneHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion
		);
	}

}
