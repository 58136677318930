import { LoginDto, LoginParameter, LoginViaFacebookParameter, LoginViaGoogleParameter, TokenInfoDto } from '@nmn-communication/accounts';
import { LoginViaFacebookCommand } from '@nmn-domain/accounts';
import { LoginViaGoogleCommand } from '@nmn-domain/accounts/login/models/login-via-google.command';
import { LoginCommand } from '@nmn-domain/accounts/login/models/login.command';
import { LoginModel } from '@nmn-domain/accounts/login/models/login.model';
import { TokenInfoModel } from '@nmn-domain/accounts/login/models/token-info.model';
import { mapDtoToUserStorageModel, mapUserProfileDtoToModel } from '../../user-accounts/factories/user-profile.factory';
import { mapUserConfigurationDtoToModel } from '../../user-configuration/factories/user-configuration.factory';

export const mapLoginCommandToParameter = (command: LoginCommand): LoginParameter => (
	{
		login: command.login,
		password: command.password,
		isPersistent: command.isPersistent
	}
);

export const mapLoginViaGoogleCommandToParameter = (command: LoginViaGoogleCommand): LoginViaGoogleParameter => (
	{
		idToken: command.idToken
	}
);

export const mapLoginViaFacebookCommandToParameter = (command: LoginViaFacebookCommand): LoginViaFacebookParameter => (
	{
		accessToken: command.accessToken
	}
);

export const mapLoginDtoToModel = (dto: LoginDto): LoginModel => (
	new LoginModel(
		mapTokenInfoDtoToModel(dto.tokenInfo),
		mapUserProfileDtoToModel(dto.user),
		mapUserConfigurationDtoToModel(dto.user.userConfiguration),
		mapDtoToUserStorageModel(dto.user)
	)
);
export const mapTokenInfoDtoToModel = (dto: TokenInfoDto): TokenInfoModel => (
	new TokenInfoModel(
		dto.accessToken,
		dto.identityToken,
		dto.refreshToken,
		dto.tokenType
	)
);
