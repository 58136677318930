import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { isNumberDefined, isValueDefined } from '@nmn-core/utils';
import { Observable, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Is used to customize module preloading.
 *
 * Also hides preloader after first module (page) loaded.
 *
 * Define 'shouldPreload' and 'loadAfterMs' in 'data' of route (module should be defined as lazy loaded) to customize loading.
 */
@Injectable()
export class PreloadingStrategyService implements PreloadingStrategy {

	private readonly preloaderQuerySelector = '#nmn-preloader';

	private preloaderRef: Element;
	private preloaderParentRef: Element;
	private animationPlayer: AnimationPlayer;
	private isPreloaderDisabled = false;

	constructor(
		private readonly builder: AnimationBuilder
	) { }

	public preload(route: Route, load: () => Observable<any>): Observable<any> {
		this.disablePreloader();
		const shouldPreload = route.data?.shouldPreload;

		if (shouldPreload) {
			const delayInMs = route.data?.loadAfterMs;

			if (isNumberDefined(delayInMs) && delayInMs > 0) {
				return timer(delayInMs).pipe(map(load));
			}

			return load();
		}

		return of(undefined);
	}

	public disablePreloader(): void {
		if (!this.isPreloaderDisabled) {
			setTimeout(
				() => {
					this.initPreloader();

					if (
						isValueDefined(this.preloaderParentRef) &&
						isValueDefined(this.preloaderRef) &&
						this.preloaderParentRef.contains(this.preloaderRef)
					) {
						this.removePreloaderWithAnimation();
					}
				},
				500
			);
			this.isPreloaderDisabled = true;
		}
	}

	private removePreloaderWithAnimation(): void {
		if (isValueDefined(this.animationPlayer)) {
			this.animationPlayer.pause();
			this.animationPlayer.destroy();
		}

		this.animationPlayer = this.builder
			.build([
				style({ opacity: '*' }),
				animate('0.5s ease-out', style({ opacity: 0 }))
			])
			.create(this.preloaderRef);

		this.animationPlayer.onDone(() => {
			if (
				isValueDefined(this.preloaderParentRef) &&
				isValueDefined(this.preloaderRef) &&
				this.preloaderParentRef.contains(this.preloaderRef)
			) {
				this.preloaderParentRef.removeChild(this.preloaderRef);
			}
		});

		this.animationPlayer.play();
	}

	private initPreloader(): void {
		if (!isValueDefined(this.preloaderRef) || !isValueDefined(this.preloaderParentRef)) {
			this.preloaderRef = document.querySelector(this.preloaderQuerySelector);
			this.preloaderParentRef = this.preloaderRef?.parentElement;
		}
	}

}
