import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserIdResolver } from '@nmn-domain/accounts';
import { Observable } from 'rxjs';

@Injectable()
export class UserIdFromActivatedRouteResolver implements UserIdResolver {

	public resolve(
		route: ActivatedRouteSnapshot,
		_: RouterStateSnapshot
	): Observable<string> | Promise<string> | string {
		const userId = route.queryParamMap.get('userId');

		// TODO: validate user id and nullify if not valid

		return userId;
	}

}
