import { isValueDefined } from '@nmn-core/utils';

export class NmnMatExpansionListUserConfiguration {

	public isExpanded: boolean;

	constructor(
		isExpanded: boolean
	) {
		this.isExpanded = isExpanded;
	}

	public static createWithParameters(
		config?: NmnMatExpansionListUserConfiguration
	): NmnMatExpansionListUserConfiguration {
		if (!isValueDefined(config)) {
			return new NmnMatExpansionListUserConfiguration(
				userConfigurationIsExpandedDefault
			);
		}

		return new NmnMatExpansionListUserConfiguration(
			isValueDefined(config?.isExpanded) ?
				config!.isExpanded :
				userConfigurationIsExpandedDefault
		);
	}

	public static createDefault(): NmnMatExpansionListUserConfiguration {
		return new NmnMatExpansionListUserConfiguration(
			userConfigurationIsExpandedDefault);
	}

}

const userConfigurationIsExpandedDefault = false;
