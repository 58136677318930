import { Injector, Provider } from '@angular/core';
import { AllergyIntoleranceCriticalityClient } from '@nmn-communication/allergy-intolerance-criticalities';
import { allergyIntoleranceCriticalityClientFactory } from './factories/allergy-intolerance-criticality.client.factory';

export const allergyIntoleranceCriticalityProviders: Array<Provider> = [
	{
		provide: AllergyIntoleranceCriticalityClient,
		useFactory: allergyIntoleranceCriticalityClientFactory,
		deps: [Injector]
	}
];
