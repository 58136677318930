import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { VaccineHttpClientConfiguration } from '../configurations/vaccine.http-client.configuration';

export class VaccineHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<VaccineHttpClientConfiguration> {

	public build(): VaccineHttpClientConfiguration {
		return new VaccineHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
