import { UploadedImageModel } from '@nmn-domain/file-uploader';
import { UserProfileModel } from '../user-profile.model';

export class UserProfileFormInModel {

	public readonly username?: string;
	public readonly isEmailNotificationsEnabled?: boolean;
	public readonly isPreferedBrowserTimezone?: boolean;
	public readonly picture?: UploadedImageModel;
	public readonly locale?: string;
	public readonly timezone?: string;

	private constructor(
		username: string | undefined,
		isEmailNotificationsEnabled: boolean | undefined,
		isPreferedBrowserTimezone: boolean | undefined,
		picture: UploadedImageModel | undefined,
		locale: string | undefined,
		timezone: string | undefined
	) {
		this.username = username;
		this.isEmailNotificationsEnabled = isEmailNotificationsEnabled;
		this.isPreferedBrowserTimezone = isPreferedBrowserTimezone;
		this.picture = picture;
		this.locale = locale;
		this.timezone = timezone;
	}

	public static createFromExistingModel(model: UserProfileModel): UserProfileFormInModel {
		return new UserProfileFormInModel(
			model.email,
			model.isEmailNotificationsEnabled,
			model.isPreferedBrowserTimezone,
			model.picture,
			model.locale,
			model.timezone
		);
	}

}
