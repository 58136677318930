import { Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { VaccinationFakeClientConfiguration } from '../configurations/vaccination.fake-client.configuration';
import { VaccinationFakeClient } from '../services/vaccination.fake-client';

export class VaccinationFakeClientBuilder {

	private configuration: VaccinationFakeClientConfiguration;

	constructor(private readonly injector: Injector) { }

	public withConfiguration(
		configuration: VaccinationFakeClientConfiguration
	): VaccinationFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): VaccinationFakeClient {
		return new VaccinationFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
